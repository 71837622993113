import React from "react";
import SystemInfoAdressesList from './system-info-adresses';
import SystemInfoAdressItem from './system-info-adresses-view';

export const SystemInfoAdresses = () => {
    const [view, setView] = React.useState({id: 0, point: 'main'})
    
    return(
        <>
            { view.point === 'main' && <SystemInfoAdressesList setView={setView} />}
            { view.point === 'view' && <SystemInfoAdressItem setView={setView} view={view} />}
        </>
    )
} 

export default SystemInfoAdresses;