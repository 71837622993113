import React from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {ExampleText} from '../../components/example-text';

const CreateUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: '20px',
    width: '100%',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },   
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

export const CreateUserForm = (props: any) => {
    const {onClose, getChangeValue} = props;

    const [values, setValuess] = React.useState({
        status: 1,
        username: '',
        bindToIp: '',
        email: '',
        emailConfirmed: false,
        roleNote: '',
        password: '',
    });

    const handleChangeForm = (prop) => (e) => {
        setValuess(prev => {
            return {
                ...prev, 
                [prop]: e.target.value,
            }
        })
    }   

    const handleChangeFormCheckbox = (prop) => (e) => {
        setValuess(prev => {
            return {
                ...prev, 
                [prop]: !prev[prop],
            }
        })
    }

    const handleCreate = () => {
        getChangeValue(values)
    }

    return (
        <CreateUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Create user
                </Title>

                <StyledFormControl fullWidth>
                    <InputLabel size='small'>Status</InputLabel>
                    <Select
                        value={values.status}
                        label="Status"
                        onChange={handleChangeForm('status')}
                        size="small"
                    >
                        <StyledMenuItem value={1}>Active</StyledMenuItem>
                        <StyledMenuItem value={0}>Inactive</StyledMenuItem>
                        <StyledMenuItem value={-1}>Banned</StyledMenuItem>
                    </Select>
                </StyledFormControl>

                <Input 
                    label="Note" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={values.roleNote}
                    onChange={handleChangeForm('roleNote')}
                ></Input>

                <Input 
                    label="Login *" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={values.username}
                    onChange={handleChangeForm('username')}
                ></Input>

                <Input 
                    label="Password *" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    onChange={handleChangeForm('password')}
                ></Input>

                <Input 
                    label="Repeat password *" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    onChange={handleChangeForm('password')}
                ></Input>

                <Input 
                    label="Bind to IP" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={values.bindToIp}
                    onChange={handleChangeForm('bindToIp')}
                ></Input>
                <ExampleText>For example: 123.34.56.78, 168.111.192.12</ExampleText>

                <Input 
                    label="E-mail" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={values.email}
                    onChange={handleChangeForm('email')}
                ></Input>

                <FormControlLabel 
                    control={
                        <Checkbox
                            value={values.emailConfirmed}
                            onChange={handleChangeFormCheckbox('emailConfirmed')}
                            sx={{
                            color: '#38AA72',
                                '&.Mui-checked': {
                                    color: '#38AA72',
                                },
                            }}
                        />
                    } 
                    label="E-mail confirmed" 
                    sx={{
                        marginTop: '8px',
                    }}
                />

            </WrapFields>
            <ButtonsContainer>
                <Button onClick={handleCreate} sx={{marginRight: '12px'}}>CREATE</Button>
                <Button onClick={onClose} variant="outlined">CANCEL</Button>
            </ButtonsContainer>
        </CreateUserFormContainer>
    );
};
