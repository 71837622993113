import React from 'react';
import { styled } from "@mui/material/styles";

const MigrationInfoList = styled('div')(({theme}) => (`
    margin-top: 26px;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    padding: 9px 16px 9px;
    color: ${theme.palette.text.primary};
`));

const ListItem = styled('div')(({theme}) => (`
    margin-bottom: 10px;
    word-break: break-all;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    &:last-child{
        margin-bottom: 0px;
    }
`));

interface Props {
    info: string[],
}

export const MigrationInfoEl: React.FC<Props> = ({info}) => {

    return (
        <MigrationInfoList>
            {
                info.map((item, i) => {
                    return <ListItem key={Math.random()}>{item}</ListItem>
                })
            }
        </MigrationInfoList>
    )
}