export namespace actionTypes {
    export const FETCH_IP_START = 'FETCH_IP_START';
    export const FETCH_IP_SUCCESS = 'FETCH_IP_SUCCESS';
    export const FETCH_IP_FAILURE = 'FETCH_IP_FAILURE';
};

export const initialState = {
	isError: false,
    isLoading: false,
	ipData: '11.11.11.11',
	errorMessage: '',
};

export const ipAddress = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.FETCH_IP_START:
			return {
				...state,
				isLoading: true
			};
		case actionTypes.FETCH_IP_SUCCESS:
			return {
				...state,
				isLoading: false,
				ipData: payload,
				errorMessage: ''
			};
		case actionTypes.FETCH_IP_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};