import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/payment_system_catalog';

const ApiInfo = {
	ApiInfoList: (params = {}) => {
		const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';
		return baseApi.get(`${path}${query}`)
	},
	CurrencyList: () => {
		return baseApi.get(`${path}/currency`);
	},
	MethodsList: () => {
		return baseApi.get(`${path}/payment-method`);
	}
}

export default ApiInfo;