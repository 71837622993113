import React, {useState, useEffect} from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {createNodeRequest, getNodesTreeRequest, updateNodeRequest, setActiveNodeRequest} from '../../actions/nodeManagement';
import {generatePublicKeyRequest, generatePrivateKeyRequest, clearKeys} from '../../actions/merchants';

const BrandFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const StyledCheckbox = styled(Checkbox)({
    color: '#979799',
    '&.Mui-checked': {
        color: '#38AA72',
    }
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => (`
    color: ${theme.palette.text.primary};
`));

const GenerateKeyButtons = styled('div')({
    display: 'flex'
});

const KeyButton = styled('div')({
    color: '#38AA72',
    fontSize: '12px',
    fontWeight: '500',
    cursor: 'pointer',
    marginRight: '20px',
    marginTop: '12px'
});

const BrandForm = ({onClose, createNodeRequest, activeNode, isEdit, updateNodeRequest, handleOpenSnackbar, generatePublicKeyRequest, generatePrivateKeyRequest, privateBackKey, publicBackKey, clearKeys, setActiveNodeRequest}: any) => {
    const [name, setName] = useState(isEdit ? activeNode.name : '');
    const handleSetName = (event) => {
        setName(event.target.value)
    };

    const [domain, setDomain] = useState(isEdit ? activeNode.domain : '');
    const handleSetDomain = (event) => {
        setDomain(event.target.value)
    };

    const [publicKey, setPublicKey] = useState(isEdit ? activeNode.merchant.publicKey : '');
    const handleSetPublicKey = (event) => {
        setPublicKey(event.target.value)
    };

    const [privateKey, setPrivateKey] = useState(isEdit ? activeNode.merchant.privateKey : '');
    const handleSetPrivateKey = (event) => {
        setPrivateKey(event.target.value)
    };

    const [callbackUrl, setCallbackUrl] = useState(isEdit ? activeNode.merchant.callbackUrl : '');
    const handleSetCallbackUrl = (event) => {
        setCallbackUrl(event.target.value)
    };

    const [successUrl, setSuccessUrl] = useState(isEdit ? activeNode.merchant.successUrl : '');
    const handleSetSuccessUrl = (event) => {
        setSuccessUrl(event.target.value)
    };

    const [errorUrl, setErrorUrl] = useState(isEdit ? activeNode.merchant.errorUrl : '');
    const handleSetErrorUrl = (event) => {
        setErrorUrl(event.target.value)
    };

    const [allowIp, setAllowIp] = useState(isEdit ? activeNode.merchant.allowIp : '');
    const handleSetAllowIp = (event) => {
        setAllowIp(event.target.value)
    };

    const [note, setNote] = useState(isEdit ? activeNode.note : '');
    const handleSetNote = (event) => {
        setNote(event.target.value)
    };

    const [isCheck, setIsCheck] = useState(isEdit ? Boolean(activeNode.merchant.isCheck) : false);
    const handleSetIsCheck = () => {
        setIsCheck(!isCheck);
    };

    useEffect(() => {
        if (privateBackKey) {
            setPrivateKey(privateBackKey);
        };
    }, [privateBackKey]);

    useEffect(() => {
        if (publicBackKey) {
            setPublicKey(publicBackKey);
        };
    }, [publicBackKey]);

    const handleGeneratePrivateKey = () => {
        generatePrivateKeyRequest();
    };

    const handleGeneratePublicKey = () => {
        generatePublicKeyRequest();
    };

    const handleSubmit = () => {
        const data = {
            parentId: isEdit ? activeNode.parentId : activeNode.id,
            name,
            domain,
            merchant: {
                publicKey,
                privateKey,
                callbackUrl,
                successUrl,
                errorUrl,
                allowIp,
                isCheck: Number(isCheck)
            },
            note
        };

        if (isEdit) {
            updateNodeRequest(activeNode.id, JSON.stringify(data)).then(() => handleOpenSnackbar('Brand was successfully updated', 'Brand not updated')).then(() => setActiveNodeRequest(activeNode.id));
        } else {
            createNodeRequest('brand', JSON.stringify(data)).then(() => handleOpenSnackbar('Brand was successfully created', 'Brand not created'));
        }

        clearKeys();
        onClose();
    };

    const handleClose = () => {
        clearKeys();
        onClose();
    };

    const isFormValid = () => {
        const regex = /^(https?:\/\/)?[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
        return isEdit ? 
            ((name !== activeNode.name) && name) ||
            ((domain !== activeNode.domain) && regex.test(domain)) ||
            ((publicKey !== activeNode.merchant.publicKey) && publicKey) ||
            ((privateKey !== activeNode.merchant.privateKey) && privateKey) ||
            (callbackUrl !== activeNode.merchant.callbackUrl) ||
            (successUrl !== activeNode.merchant.successUrl) ||
            (errorUrl !== activeNode.merchant.errorUrl) ||
            (allowIp !== activeNode.merchant.allowIp)  ||
            (note !== activeNode.note) ||
            (isCheck !== Boolean(activeNode.merchant.isCheck))
            : (name && regex.test(domain) && publicKey && privateKey);
    };

    return (
        <BrandFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={handleClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>{isEdit ? 'Edit brand' : 'Add brand'}</Title>
                {!isEdit && <Input label="Root folder *" sx={{marginTop: '20px'}} value={`${activeNode.id}. ${activeNode.name}`} disabled></Input>}
                <Input label="Name *" sx={{marginTop: '20px'}} onChange={handleSetName} value={name}></Input>
                <Input label="Domain *" sx={{marginTop: '20px'}} onChange={handleSetDomain} value={domain}></Input>
                <Input label="Public key *" sx={{marginTop: '20px'}} onChange={handleSetPublicKey} value={publicKey} disabled></Input>
                <Input label="Private key *" sx={{marginTop: '20px'}} onChange={handleSetPrivateKey} value={privateKey} disabled></Input>
                <GenerateKeyButtons>
                    <KeyButton onClick={handleGeneratePublicKey}>GENERATE PUBLIC KEY</KeyButton>
                    <KeyButton onClick={handleGeneratePrivateKey}>GENERATE PRIVATE KEY</KeyButton>
                    <KeyButton onClick={() => {navigator.clipboard.writeText(`Name - ${name} Public key - ${publicKey} Private key - ${privateKey}`)}}>COPY KEYS</KeyButton>
                </GenerateKeyButtons>
                <Input label="Callback url" sx={{marginTop: '20px'}} onChange={handleSetCallbackUrl} value={callbackUrl}></Input>
                <Input label="Success url" sx={{marginTop: '20px'}} onChange={handleSetSuccessUrl} value={successUrl}></Input>
                <Input label="Error url" sx={{marginTop: '20px'}} onChange={handleSetErrorUrl} value={errorUrl}></Input>
                <Input label="Allowed IP" sx={{marginTop: '20px'}} onChange={handleSetAllowIp} value={allowIp}></Input>
                <Input label="Note" sx={{marginTop: '20px'}} onChange={handleSetNote} value={note}></Input>
                <StyledFormControlLabel
                    label="Check transactions of merchant"
                    control={<StyledCheckbox checked={isCheck} onChange={handleSetIsCheck}/>}
                />
            </WrapFields>
            <ButtonsContainer>
                <Button sx={{marginRight: '12px'}} disabled={!isFormValid()} onClick={handleSubmit}>{isEdit ? 'UPDATE' : 'CREATE'}</Button>
                <Button variant="outlined" onClick={handleClose}>CANCEL</Button>
            </ButtonsContainer>
        </BrandFormContainer>
    )
};

export default compose(
    connect((state: RootState | any) => ({
        activeNode: state.nodeManagement.activeNode,
        privateBackKey: state.merchants.privateKey,
        publicBackKey: state.merchants.publicKey
    }), {createNodeRequest, getNodesTreeRequest, updateNodeRequest, generatePublicKeyRequest, generatePrivateKeyRequest, clearKeys, setActiveNodeRequest})
)(BrandForm);