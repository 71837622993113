import React, { useState} from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Button} from '../../components/button';
import {Input} from '../../components/input';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import {SwitchComponent} from '../../components/switch';


const CreatePaymentFormContainer = styled('div')(({ theme }) => (`
    height: 100%;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    background: ${theme.palette.background.paper};
    position: relative;
    padding: 32px 32px 0px 32px;
    align-content: space-between;
    display: flex;
    flex-wrap: wrap;
`));

const CloseSvgWrapper = styled('div')(`
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
`);

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const ButtonsContainer = styled('div')(`
    padding: 20px 0 32px 0;
`);

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const StyledFormControl = styled(FormControl)(({ theme }) => (`
    margin-top: 20px;
    width: 100%;
    & label.Mui-focused{
        color: #38AA72;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
                border-color: #38AA72;
        }
    }
`));

const timeOptions = [
    {title: '1 Hour', value: 3600000},
    {title: '12 Hours', value: 43200000},
    {title: '1 Day', value: 86400000},
    {title: '3 Day', value: 259200000},
    {title: '1 Week', value: 604800016},
    {title: '10 Days', value: 864000000},
    {title: '2 Weeks', value: 1209600033},
    {title: '30 Days', value: 2592000000},
]

export const CreateNewPaymentSystemGlobal = (props: any) => {

    const [values, setValues] = useState({
        name: "",
        code: "",
        active: 0,
        logo: "",
        callbackUrl: "",
        preset: "",
        transactionLifetime: 3600000,
        transactions: [],
        userAddress: "",
        logoAbsoluteUrl: "",
    });

    const {onClose, onSave} = props;

    const handleClose = () => onClose();

    const handleChange = (prop) => (e) => {
        setValues((prev) => ({...prev, [prop]: e.target.value}))
    }

    const handleChangeActive = () => {
        setValues(prev => ({...prev, active: Number(!prev.active)}));
    };

    const handleCreate = () => {
        onSave(values);
        onClose();
    }

    const isDisabled = () => {
        if(!values.name || !values.code) return true
        return false
    }

    return (
        <CreatePaymentFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={handleClose}/>
            </CloseSvgWrapper>

            <WrapFields>

                    <Input
                        type='text'
                        label="Name*"
                        value={values.name}
                        onChange={(e) => handleChange('name')(e)}
                        sx={{marginTop: '20px'}}
                    />

                    <Input
                        type='text'
                        label="Payment system code*"
                        value={values.code}
                        onChange={(e) => handleChange('code')(e)}
                        sx={{marginTop: '20px'}}
                    />

                    <Input
                        type='text'
                        label="Logo"
                        value={values.logo}
                        onChange={(e) => handleChange('logo')(e)}
                        sx={{marginTop: '20px'}}
                    />                    
                    
                    <Input
                        type='text'
                        label="Callback URL"
                        value={values.callbackUrl}
                        onChange={(e) => handleChange('callbackUrl')(e)}
                        sx={{marginTop: '20px'}}
                    />

                    <StyledFormControl fullWidth>
                        <InputLabel size="small">Transaction lifetime</InputLabel>
                        <Select
                            value={values.transactionLifetime}
                            label="Transaction lifetime"
                            onChange={(e) => handleChange('transactionLifetime')(e)}
                            size='small'
                            disabled={false}
                        >
                            {
                                timeOptions.map((option, index) => {
                                    return <StyledMenuItem key={index} value={option.value}>{option.title}</StyledMenuItem>
                                })
                            }
                        </Select>
                    </StyledFormControl>

                    <SwitchComponent 
                        checked={Boolean(Number(values.active))}
                        onChange={handleChangeActive}
                        sx={{marginTop: '20px'}}
                        label={`Is Active*`}
                    />

            </WrapFields>

            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    disabled={isDisabled()}
                    onClick={handleCreate}
                >
                    Create
                </Button>
                <Button 
                    variant="outlined"
                    onClick={handleClose}
                >CANCEL
                </Button>
            </ButtonsContainer>

        </CreatePaymentFormContainer>
    )
}


