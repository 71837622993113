import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/user_addresses';

const SystemInfoAdresses = {
	getSystemInfoAdresses: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';

		return baseApi.get(`${path}${query}`)
	},
	getSystemInfoAdressItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
    // ...
}

export default SystemInfoAdresses;