// @ts-nocheck
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from '../../components/button';
import Snackbar from '@mui/material/Snackbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {FolderSvg} from '../../components/svg/folder-svg';
import {Tree} from 'react-arborist';
import useResizeObserver from "use-resize-observer";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TechnicalForm from "./technical-form";
import ClientForm from "./client-form";
import BrandForm from './brand-form';
import {connect} from 'react-redux';
import {compose} from "redux";
import {getNodesTreeRequest, setActiveNodeRequest, deleteNodeRequest, switchRESTAccessRequest, getApiKeyRequest} from '../../actions/nodeManagement';
import {useTreeModel} from '../../hooks/useTreeModel';
import {AccountBalanceSvg} from '../../components/svg/account-balance-svg';
import {MoreSvg} from '../../components/svg/more-svg';
import { Menu as ReactMenu, MenuItem as ReactMenuItem, MenuButton } from '@szhsin/react-menu';
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import {Loader} from '../../components/loaders/loader';
import Transfer from './transfer';
import {menuSelector, menuItemSelector} from "@szhsin/react-menu/style-utils";
import { InfoPopup } from "../../components/info-popup";
import {Link} from 'react-router-dom';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const NodeManagementPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const NodeManagementPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const NodeManagementPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 768px)': {
        width: '100%',
        marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root': {
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
        }
    }
}));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const TreeItemContainer = styled('div')(({ selected, theme }) => ({
    border: `1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'}`,
    borderBottom: 'none !important',
    paddingLeft: '40px',
    background: selected ? theme.palette.action.selected : 'transparent',
    color: theme.palette.text.primary,
    width: 'calc(100% - 44px) !important',

    ':last-child': {
        borderBottom: `1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'} !important`,
    }
}));

const TreeItemRowContents = styled('div')({
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const TreeItemLabel = styled('div')({
    fontSize: '14px',
    fontWeight: '400'
});

const TreeItemIcon = styled('div')({
    marginRight: '8px',
    marginLeft: '10px'
});

const ButtonsBlock = styled('div')({
    display: 'flex',
});

const LeftBlock = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
});

const StyledMenuButton = styled(MenuButton)({
    height: '30px',
    border: '1px solid #38AA72',
    backgroundColor: 'transparent',
    fontSize: '12px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
});

const StyledReactMenu = styled(ReactMenu)(({ theme }) => (`
    ${menuSelector.name} {
        background: ${theme.palette.background.paper};
    }

    ${menuItemSelector.name} {
        background: ${theme.palette.background.paper};
        color: ${theme.palette.text.primary};
        :hover {
            background: ${theme.palette.action.selected}
        }
    }
`));

const IconWrapper = styled('div')({
    position: 'absolute',
    left: '-18px'
});

const NodeManagementPageHeaderRight = styled('div')({
    display: 'flex',
    gap: '12px'
});

const StyledLink = styled(Link)(({ theme }) => (`
    text-decoration: none;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

const MaybeToggleButton = ({ toggle, isOpen, isFolder, isSelected }: any) => {
    if (isFolder) {
        const Icon = isOpen ? ExpandMoreIcon : ChevronRightIcon;
        return (
            <IconWrapper>
                <Icon onClick={toggle}/>
            </IconWrapper>
        );
    } else {
        return <div className="spacer" />;
    }
};

type Props = {
    setActiveNodeRequest?: (id: number) => void;
    deleteNodeRequest?: (id: number) => void;
    getNodesTreeRequest?: () => void;
    isLoading: boolean;
    nodesTree: {id: number, name: string; parentId: number; type: number; verified: number; children: nodesTree;}[];
    switchRESTAccessRequest: () => void;
    error?: boolean;
    getApiKeyRequest: () => void;
    userId: number;
    routsUser: Array<any>
};

const NodeManagementMain = ({
    deleteNodeRequest,
    getNodesTreeRequest,
    isLoading,
    nodesTree,
    setActiveNodeRequest,
    switchRESTAccessRequest,
    getApiKeyRequest,
    apiKey,
    error,
    RESTAccessMessage,
    userId,
    errorMessage,
    routsUser,
    userRoles,
    isSuperadmin,
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [anchorElActionMore, setAnchorElActionMore] = React.useState<null | HTMLElement>(null);
    const isActionMoreMenuOpen = Boolean(anchorElActionMore);
    const handleActionMoreMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElActionMore(event.currentTarget);
    };
    const handleActionMoreMenuClose = () => {
        setAnchorElActionMore(null);
    };

    const [isEdit, setIsEdit] = useState(false);
    
    const [openTechnicalForm, setOpenTechnicalForm] = React.useState(false);
    const handleOpenTechnicalForm = (isEdit) => {
        setIsEdit(isEdit);
        setOpenTechnicalForm(true);
    };
    const handleCloseTechnicalForm = () => setOpenTechnicalForm(false);

    const [openClientForm, setOpenClientForm] = React.useState(false);
    const handleOpenClientForm = (isEdit) => {
        setIsEdit(isEdit);
        setOpenClientForm(true);
    };
    const handleCloseClientForm = () => setOpenClientForm(false);

    const [openBrandForm, setOpenBrandForm] = React.useState(false);
    const handleOpenBrandForm = (isEdit) => {
        setIsEdit(isEdit);
        setOpenBrandForm(true);
    };
    const handleCloseBrandForm = () => setOpenBrandForm(false);

    const [selectedId, setSelectedId] = useState('');

    const [openTransfer, setOpenTransfer] = React.useState(false);
    const handleOpenTransfer = () => {
        setOpenTransfer(true);
    };
    const handleCloseTransfer = () => setOpenTransfer(false);

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarText, setSnackbarText] = React.useState({successText: '', errorText: ''});

    const handleOpenSnackbar = (successText, errorText) => {
        setSnackbarText({successText, errorText});
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        if(routsUser.some(rout => rout.child.includes('/node/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            getNodesTreeRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {data = {}, onToggle} = useTreeModel(nodesTree[0] || {});

    const handleSelectNode = (id) => {
        setActiveNodeRequest(id);
        setSelectedId(id);
    };

    const handleDeleteNode = (id) => {        
        deleteNodeRequest(id).then(() => handleOpenSnackbar("Node was deleted", "Node not deleted"));
    };

    const handleEditNode = (type, isEdit) => {        
        if (type === 1) {
            handleOpenBrandForm(isEdit);
        } else if (type === 2) {
            handleOpenClientForm(isEdit);
        } else if (type === 3) {
            handleOpenTechnicalForm(isEdit)
        }
    };

    const handleCreateNodeInRoot = (type) => {
        setActiveNodeRequest(1).then(() => {
            if (type === 1) {
                handleOpenBrandForm(false);
            } else if (type === 2) {
                handleOpenClientForm(false);
            } else if (type === 3) {
                handleOpenTechnicalForm(false)
            }
        });
    }

    const handleCopyRootKeys = () => {
        getApiKeyRequest()
        .then(() => navigator.clipboard.writeText(apiKey))
        .then(() => handleOpenSnackbar("Keys was copied"))
    };

    const handleSwitchRESTAccess = () => {
        switchRESTAccessRequest().then(() => handleOpenSnackbar(RESTAccessMessage))
    };

    const accessedRole = routsUser.some(rout => rout.child.includes('/node/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleCreate = routsUser.some(rout => rout.child === '/node/create') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleUpdate = routsUser.some(rout => rout.child === '/node/update') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleDelete = routsUser.some(rout => rout.child === '/node/delete') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleView = routsUser.some(rout => rout.child === '/node/view') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    
    return (
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole &&
                <>
                    <NodeManagementPageHeader>
                        <NodeManagementPageHeaderLeft>
                            <NodeManagementPageHeaderText>Node management</NodeManagementPageHeaderText>
                        </NodeManagementPageHeaderLeft>
                        <NodeManagementPageHeaderRight>
                            {accessedRoleCreate && 
                                <>
                                    <Button onClick={handleMenuClick}>Create <ExpandMoreIcon /></Button>
                                    <Menu
                                        MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={isMenuOpen}
                                        onClose={handleMenuClose}
                                        PaperProps={{
                                            style: {
                                                width: '200px',
                                            },
                                        }}
                                    >
                                        <StyledMenuItem onClick={() => handleCreateNodeInRoot(1)}>
                                            Brand in Root
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={() => handleCreateNodeInRoot(2)}>
                                            Client in Root
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={() => handleCreateNodeInRoot(3)}>
                                            Technical in Root
                                        </StyledMenuItem>
                                    </Menu>
                                </>
                            }
                            {accessedRoleUpdate &&
                                <>
                                    {!!isSuperadmin && <Button variant="outlined" onClick={handleActionMoreMenuClick}><MoreSvg size="20px" fill="#38AA72"/></Button>}
                                    <Menu
                                        MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorElActionMore}
                                        open={isActionMoreMenuOpen}
                                        onClose={handleActionMoreMenuClose}
                                        PaperProps={{
                                            style: {
                                                width: '200px',
                                            },
                                        }}
                                    >
                                        <StyledMenuItem onClick={handleSwitchRESTAccess}>
                                            Close REST Access
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={handleCopyRootKeys}>
                                            Copy Root keys
                                        </StyledMenuItem>
                                    </Menu>
                                </>
                            }
                        </NodeManagementPageHeaderRight>
                    </NodeManagementPageHeader>
                    <Modal
                        open={openTechnicalForm}
                        onClose={handleCloseTechnicalForm}
                    >
                        <StyledBoxPopup>
                            <TechnicalForm onClose={handleCloseTechnicalForm} isEdit={isEdit} handleOpenSnackbar={handleOpenSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Modal
                        open={openClientForm}
                        onClose={handleCloseClientForm}
                    >
                        <StyledBoxPopup>
                            <ClientForm onClose={handleCloseClientForm} isEdit={isEdit} handleOpenSnackbar={handleOpenSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Modal
                        open={openBrandForm}
                        onClose={handleCloseBrandForm}
                    >
                        <StyledBoxPopup>
                            <BrandForm onClose={handleCloseBrandForm} isEdit={isEdit} handleOpenSnackbar={handleOpenSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Modal
                        open={openTransfer}
                        onClose={handleCloseTransfer}
                        id={data.id}
                    >
                        <Transfer onClose={handleCloseTransfer} id={selectedId} handleOpenSnackbar={handleOpenSnackbar}/>
                    </Modal>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                        {error ?
                        <InfoPopup
                            severity="error"
                            headerText={snackbarText.errorText}
                            infoText={errorMessage ? errorMessage : "Something went wrong. Please try again later"}
                        /> :
                        <InfoPopup
                            severity="success"
                            headerText={snackbarText.successText}
                        />}
                    </Snackbar>
                    <div ref={ref} style={{width: '100%', height: 'calc(100vh - 200px)'}}>
                        <Tree
                            width={width}
                            height={height}
                            data={data}
                            onToggle={onToggle}
                            rowHeight={42}
                            indent={32}
                            openByDefault={false}
                        >{({
                            innerRef,
                            data,
                            styles,
                            state,
                            handlers,
                            tree
                        }) => {
                            const folder = Array.isArray(data.children);
                            const isOpen = !!state.isOpen;
                            const name = data.name;
                                            
                            return (
                            <TreeItemContainer style={styles.row} ref={innerRef} onClick={() => handleSelectNode(data.id)} selected={state.isSelected}>
                                    <TreeItemRowContents className="row-contents" style={styles.indent}>
                                        <LeftBlock>
                                            <MaybeToggleButton
                                                toggle={handlers.toggle}
                                                isOpen={isOpen}
                                                isFolder={folder}
                                                isSelected={selectedId === data.id}
                                            />
                                            <TreeItemIcon>
                                                {data.type === 1 ? <AccountBalanceSvg size="24px"/> : <FolderSvg size="24px" />}
                                            </TreeItemIcon>
                                            <TreeItemLabel>
                                                {`${data.id}. ${name}`}
                                            </TreeItemLabel>
                                        </LeftBlock>
                                        {selectedId === data.id && 
                                            <>
                                                {(accessedRoleCreate || accessedRoleView || accessedRoleUpdate || accessedRoleDelete) &&
                                                    <ButtonsBlock>
                                                        {accessedRoleCreate &&
                                                            <>
                                                                {data.type !== 1 && <Button variant="outlined" size="small" sx={{marginRight: '10px'}} onClick={() => handleOpenBrandForm(false)}>ADD BRAND</Button>}
                                                                {data.type !== 1 && <Button variant="outlined" size="small" sx={{marginRight: '10px'}} onClick={() => handleOpenClientForm(false)}>ADD CLIENT</Button>}
                                                                {data.type !== 1 && <Button variant="outlined" size="small" sx={{marginRight: '10px'}} onClick={() => handleOpenTechnicalForm(false)}>ADD TECHNICAL</Button>}
                                                            </>
                                                        }
                                                        <StyledReactMenu
                                                            menuButton={<StyledMenuButton sx={{marginRight: '16px'}}><MoreSvg size="20px" fill="#38AA72"/></StyledMenuButton>}
                                                        >   
                                                            {accessedRoleView &&
                                                                <StyledLink to={`/node-management/details`}>
                                                                    <ReactMenuItem>Details</ReactMenuItem>
                                                                </StyledLink>
                                                            }
                                                            {accessedRoleUpdate && 
                                                                <>
                                                                    {data.type !== 100 && <ReactMenuItem onClick={() => handleEditNode(data.type, true)}>Edit</ReactMenuItem>}
                                                                    {data.type !== 100 && !!isSuperadmin && <ReactMenuItem onClick={() => handleOpenTransfer()}>Transfer</ReactMenuItem>}
                                                                </>
                                                            }
                                                            {accessedRoleDelete &&
                                                                <>
                                                                    {data.type !== 100 && <ReactMenuItem onClick={() => handleDeleteNode(data.id)}>Delete</ReactMenuItem>}
                                                                </>
                                                            }
                                                        </StyledReactMenu>
                                                    </ButtonsBlock>
                                                }
                                            </>
                                        }
                                    </TreeItemRowContents>
                            </TreeItemContainer>
                            );
                        }}</Tree>
                    </div>
                </>
            }    
            {isLoading && <Loader />}
        </PageContainer>
    )
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.nodeManagement.isLoading,
        nodesTree: state.nodeManagement.nodesTree,
        apiKey: state.nodeManagement.apiKey,
        error: state.nodeManagement.error,
        errorMessage: state.nodeManagement.errorMessage,
        RESTAccessMessage: state.nodeManagement.RESTAccessMessage,
        userId: state.currentUser.userData.id,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        getNodesTreeRequest, 
        deleteNodeRequest, 
        setActiveNodeRequest, 
        switchRESTAccessRequest, 
        getApiKeyRequest
    })
)(NodeManagementMain);
