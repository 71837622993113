import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button } from '../../components/button';

import { Loader } from '../../components/loaders/loader'
import { QueuesList } from './queues-list'
import { connect } from 'react-redux';
import { compose } from "redux";
import { queuesRequest } from '../../actions/queue'
import { useAppSelector } from "../../app/hooks";
import { RootState } from '../../store/configure-store';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => (`
    position: relative;
    min-height: 100% - 64px;
    box-sizing: border-box;
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const QueuePageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    @media(max-width: 768px){
        flex-wrap: wrap;
    }
    button{
        svg{
            margin-right: 8px;
        }
    }
`);

const QueuePageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 24px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
        padding-bottom: 16px;
    }
`));

const QueuePageHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 991px){
        width: 100%;
        margin-bottom: 16px;
    }
    @media(max-width: 768px){
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 12px;
        justify-content: space-between;
    }
`);

type PropTypes = {
    queuesRequest: () => void,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

 const QueuesPage: React.FC = (props: PropTypes) => {
    const {
        queuesRequest,
        routsUser, 
        userRoles, 
        isSuperadmin,
    } = props;
    const queueList = useAppSelector(state => state.queue.queues)
    const isLoading = useAppSelector(state => state.queue.isLoading)

    useEffect(() => {
        if(routsUser.some(rout => rout.child.includes('/site/test-redis')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            queuesRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleQueueInfo = () => { 
        console.log(queueList)
    };

    const accessedRole = routsUser.some(rout => rout.child.includes('/site/test-redis')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    return (
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole && 
                <>
                    <QueuePageHeader>
                        <QueuePageHeaderLeft>
                            <QueuePageHeaderText>Queues</QueuePageHeaderText>
                        </QueuePageHeaderLeft>
                        <Button onClick={handleQueueInfo}>
                            Queue info
                        </Button>
                    </QueuePageHeader>  
                    <QueuesList list={queueList}/>
                </>
            }

            {isLoading && <Loader/>}

        </PageContainer>
    )
}

export default compose(
    connect((state: RootState | any) => ({
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
     }), {
        queuesRequest
    }),
)(QueuesPage)