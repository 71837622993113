export namespace ActionTypes {
    export const FETCH_REQUEST_CLEANER_SUCCESS = 'FETCH_REQUEST_CLEANER_SUCCESS';
    export const FETCH_REQUEST_CLEANER_FAILURE = 'FETCH_REQUEST_CLEANER_FAILURE';
};

interface RequestCleaner {
	isError: boolean
}

export const initialState: RequestCleaner = {
	isError: false
};

export const requestCleaner = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_REQUEST_CLEANER_SUCCESS:
			return {
				...state,
				isError: false
			};
		case ActionTypes.FETCH_REQUEST_CLEANER_FAILURE:
			return {
				...state,
				isError: true
			};
		default:
			return state;
	}
};
