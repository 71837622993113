import React from 'react';
import { styled, useTheme } from "@mui/material/styles";
import { LeftLogoSvg } from '../../components/svg/left-logo-svg';
import {RightLogoSvg} from '../../components/svg/right-logo-svg';
import {ErrorSvg} from '../../components/svg/error-svg';

const PageContainer = styled('div')(({ theme }) => (`
    // height: 100%;
    // width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: ${theme.palette.background.paper};
    overflow: hidden;
    position: absolute;
`));

const PageContent = styled('div')(({ theme }) => (`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    margin-top: 140px;
    height: 266px;
`));

const LeftLogo = styled('div')(({ theme }) => (`
    position: absolute;
    left: -50px;
    bottom: -12px;

    @media(max-width: 768px) {
        left: -80px;
        bottom: 0px;
    };
`));

const RightLogo = styled('div')(({ theme }) => (`
    position: absolute;
    right: -50px;
    bottom: -12px;

    @media(max-width: 768px) {
        right: -80px;
        bottom: 0px;
    };
`));

const ErrorText = styled('div')(({ theme }) => (`
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    font-weight: 500;
    font-style: normal;
    margin-top: 24px;
    color: ${theme.palette.text.primary}
`));

export const NotFoundPage = () => {
    const theme = useTheme();

    return (
        <PageContainer>
            <PageContent>
                <ErrorSvg circleFill={theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white'}/>
                <ErrorText>
                    Page not found
                </ErrorText>
            </PageContent>
            <LeftLogo><LeftLogoSvg size={window.innerWidth > 768 ? '718px' : '320px'} /></LeftLogo>
            <RightLogo><RightLogoSvg size={window.innerWidth > 768 ? '718px' : '320px'}/></RightLogo>
        </PageContainer>
    );
};
