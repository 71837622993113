import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../../components/svg/arrow-back-svg";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../../store/configure-store';
import { Loader } from '../../../components/loaders/loader';

import {
    usersAllRequestItemRoles,
    usersAllRequestItemThree,
    usersAllRequestItem,
    usersAssigmentRoles,
} from '../../../actions/usersAll';
import {useParams, useNavigate} from 'react-router-dom';
import {FieldsList} from './fields-lists';
import { FieldsListPermissions } from './fields-lists-permission';


const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const StyledPaperTabs = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    box-sizing: border-box;
    border-bottom: none;
    margin-top: 30px;
    box-shadow: none;
    border: none;
    @media screen and (max-width: 768px){
        margin-top: 10px;
    }
`));

const StyledTabPanel = styled(TabPanel)(`
    padding: 0px 0px 30px 30px;
    margin-top: 24px;
`);

const StyledTab = styled(Tab)(({ theme }) => (`
    &.MuiTab-root{
        color: #979799;
    }
    &.Mui-selected{
        color: ${theme.palette.text.primary};
    }
`));

const StyledTabs = styled(Tabs)(`
    & .MuiTabs-indicator{
        background-color: #272F3D;
    },
`);

const WrapTabContentColumns = styled('div')(({ theme }) => (`
    &{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }
`));

const WrapTabContentItem = styled('div')(({ theme }) => (`
    &{
        // width: 33.33%;
        padding-right: 30px;
        box-sizing: border-box;
        @media screen and (max-width: 991px){
            width: 100%;
        }
    }
`));


export const UsersAllPermissionsDetails = (props) => {
    const {
        isLoading,
        usersAllRequestItemThree,
        usersAllRequestItem,
        infoUser,
        userRoles,
        usersAssigmentRoles,
    } = props;

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        usersAllRequestItemThree(id);
        usersAllRequestItem(id);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        usersAllRequestItemThree(id);
        usersAllRequestItem(id);
        // eslint-disable-next-line
    }, [id]);

    const [tabIndex, setTabIndex] = React.useState('1');
    const handleChangeTab = (event: React.SyntheticEvent, index: string) => {
        setTabIndex(index);
    };

    const goBack = () =>  navigate(-1);

    const [localitemRoleTree, setLocalitemRoleTree] = useState(userRoles);
    useEffect(() => {
        setLocalitemRoleTree(userRoles);
        // eslint-disable-next-line
    }, [userRoles]);

    const createDate = (data: any) => {
        const dateSend = [];
        const searchDateTrue = (arr) => {
            arr.forEach((item, index, arrInit) => {
                item.checked && dateSend.push({'name': item['code']}) 
            })
        }
        searchDateTrue(localitemRoleTree.roles);
        return dateSend
    }

    const handleSavePermissions = (data) => {
        usersAssigmentRoles(infoUser.id, createDate(data))
    }

    return (
        <PageContainer>
            <UserDetailsHeader>
                <UserDetailsHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer>
                            <ArrowBackSvg size="22px" onClick={goBack}/>
                        </ArrowContainer>
                        <UserDetailsHeaderText>
                            User {infoUser.username} permissions
                        </UserDetailsHeaderText>
                    </HeaderLeftContainer>
                </UserDetailsHeaderLeft>
            </UserDetailsHeader>
            <StyledPaperTabs>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <StyledTabs value={tabIndex} onChange={handleChangeTab} aria-label="lab API tabs example">
                                <StyledTab label="Roles" value="1" />
                                <StyledTab label="Permissions" value="2" />
                            </StyledTabs>
                        </Box>

                        <StyledTabPanel value="1">                        
                            <WrapTabContentColumns>
                                <WrapTabContentItem>
                                    <FieldsList 
                                        childFieldss={localitemRoleTree.roles} 
                                        onSaveFields={handleSavePermissions}
                                    />
                                </WrapTabContentItem>
                            </WrapTabContentColumns>
                        </StyledTabPanel>

                        <StyledTabPanel value="2">
                            <WrapTabContentColumns>
                                <WrapTabContentItem>
                                    <FieldsListPermissions 
                                        childFieldss={localitemRoleTree.permissions} 
                                        onSaveFields={handleSavePermissions}
                                    />
                                </WrapTabContentItem>
                            </WrapTabContentColumns>
                        </StyledTabPanel>

                    </TabContext>
                </Box>
            </StyledPaperTabs>

            { isLoading && <Loader/> }

        </PageContainer>
    )
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.usersAll.isLoading,
        error: state.usersAll.isError,
        infoUser: state.usersAll.usersItem,
        userRoles: state.usersAll.userItemmAssigment,
    }), {
        usersAllRequestItemRoles,
        usersAllRequestItemThree,
        usersAllRequestItem,
        usersAssigmentRoles,
    }),
)(UsersAllPermissionsDetails)