import { useClipboard } from 'use-clipboard-copy';
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {isJson} from '../../utils';

const ActionIcon = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    'path': {
        fill: theme.palette.mode === 'light' ? "#575D68": 'white',
    },
    '&:hover':{
        'path': {
            fill: '#38AA72',
        },
    }
}));

export function CopyClipboard({ data }) {
    const clipboard = useClipboard();
  
    const handleClick = () => {
        clipboard.copy(data.replace(/\\/g, ''))
    };
  
    return (
        <Tooltip title='Copy' placement="top">
            <ActionIcon onClick={handleClick}>
                <ContentPasteIcon sx={{ fontSize: 20 }}/>
            </ActionIcon>
        </Tooltip>
    );
}