import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import { Global, css } from '@emotion/react'
import { InfoOutlineSvg } from "../../components/svg/info-outline-svg";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';


const SetWebhookFormContainer = styled('div')(({ theme }) => ({
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
})

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

const FormInfoMessage = styled('div')({
    marginTop: '16px',
    gap: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
})

const FromInfoMessageText = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    wordBreak: 'break-all',

}));

const FromInfoMessageTextIcon = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    wordBreak: 'break-all',
    width: 'calc(100% - 40px)',
}));

type PropsType = {
    onClose: () => void, 
    getChangeValue: (value: {webhooklink: string}) => void;
}

export const SetWebHookForm: React.FC<PropsType> = (props) => {
    const {onClose, getChangeValue} = props;
    const [value, setValue] = useState({webhooklink: ''});

    const handleChangeForm = (prop) => (e) => {
        setValue(prev => ({...prev, [prop]: e.target.value}))
    }   
    
    const handleClickSet = () => {
        getChangeValue(value)
    }

    return (
        <SetWebhookFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>Set Webhook</Title>
                <div></div>
                <FormInfoMessage>
                    <InfoOutlineSvg size="24px"/>
                    <FromInfoMessageTextIcon>
                        In config file this param equal 'https://api.paygate.xim.hattiko.pw/telegram/bot'
                    </FromInfoMessageTextIcon>
                </FormInfoMessage>
                <Input label="Webhook link *" sx={{marginTop: '20px'}} 
                    onChange={handleChangeForm('webhooklink')}
                    value={value.webhooklink}
                />
                <FormInfoMessage>
                    <FromInfoMessageText>
                        Should be with SSL (https)
                    </FromInfoMessageText>
                </FormInfoMessage>
                <Global
                    styles={css`
                        & .MuiPickersDay-root.Mui-selected {
                            background-color: #38AA72 !important;
                        },
                    `}
                />
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleClickSet}
                >
                    <AssignmentTurnedInIcon sx={{marginRight:'8px',fontSize: '19px'}}/>
                    Set
                </Button>
            </ButtonsContainer>

        </SetWebhookFormContainer>
    )
}