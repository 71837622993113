export namespace ActionTypes {
    export const FETCH_QUEUE_START = 'FETCH_QUEUE_START';
    export const FETCH_QUEUE_SUCCESS = 'FETCH_QUEUE_SUCCESS';
    export const FETCH_QUEUE_FAILURE = 'FETCH_QUEUE_FAILURE';
    export const FETCH_QUEUE_FINISHED = 'FETCH_QUEUE_FINISHED';
};

interface QueueItem {
	delayed: number,
	done: number,
	name: string,
	reserved: number,
	waiting: number,
}

interface Queues {
	isError: boolean,
    isLoading: boolean,
	queues: QueueItem[],
	errorMessage: string,
}

export const initialState: Queues = {
	isError: false,
    isLoading: false,
	queues: [
		{
			delayed: 0,
			done: 0,
			name: 'default',
			reserved: 0,
			waiting: 0,
		}
	],
	errorMessage: ''
};

export const queue = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_QUEUE_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_QUEUE_SUCCESS:
			return {
				...state,
				queues: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_QUEUE_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
