import React from 'react';
import { styled } from "@mui/material/styles";

const LoaderPage = styled('div')(({ theme }) => (`
    backdrop-filter: blur(5px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 100;
    & .lds-ring{
        position: fixed;
        top: 40vh;
    }
`));

export const Loader: React.FC = () => {
    return (
        <LoaderPage>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </LoaderPage>
    )
}
