import React, {useContext, useState} from 'react';
import TimeZone from '../../components/timezoneHeader/timezonrHeader';
import { 
	AppBar,
	Toolbar,
	Box,
	Stack,
	IconButton,
	Typography,
	Avatar,
	Menu,
	MenuItem,
	Fade,
} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MenuIcon from '@mui/icons-material/Menu';
import {ThemeCtx} from '../../services/themes';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import {ChangePasswordForm} from './change-password-form';
import {changePasswordRequest} from '../../actions/usersAll';

// @ts-ignore
import logoImage from '../../images/logo_moneygrator.svg'
// @ts-ignore
import logoImageDarkMode from '../../images/logo_moneygrator_darkmode.svg'
// @ts-ignore
import logoImageMobile from '../../images/logo_moneygrator_mobile.svg'
// @ts-ignore
import logoImageMobileDarkMode from '../../images/logo_moneygrator_mobile_darkmode.svg'
import { useAppDispatch } from '../../app/hooks';
import {ActionTypes} from '../../reducers/authorization';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';

import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';

const BoxStyled = styled(Box)(({ theme }) => (`
	width: 100%;
`));

const AppBarStyled = styled(AppBar)(({ theme }) => (`	
	box-shadow: none;
	background-color: ${theme.palette.background.default};
	background-image: none;
	& > .MuiToolbar-root{
		padding-left: 8px;
		padding-right: 8px;
		@media (max-width: 767px) {
			flex-wrap: wrap;
			padding-left: 15px;
			padding-right: 15px;
		}
	}	
`));

const StyledMenu = styled(Menu)(({ theme }) => (`   
	& > .MuiPaper-root{
		border-radius: 0;
		& > ul{
			padding: 0 0;
			& > li {
				font-size: 14px;
				color: ${theme.palette.text.primary}
			}
		}
	}
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
	display: flex;
	align-items: center;
`));

const WrapSpanIcon = styled('span')(({ theme }) => (`
	& > svg{
		margin-right: 8px;
		margin-top: 5px;
	}
`));

const AvatarLogin = styled(Avatar)(({ theme }) => (`
	background-color: #404653;
	display: flex;
	align=items: center;
	width: 24px;
	height: 24px; 
	margin-right: 8px;
	color: #ffffff;
`));

// const TitleLogin = styled(Typography)(({ theme }) => (`
// 	color: ${theme.palette.text.primary};
// 	font-size: 14px;
// `));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const Header = ({
	mobileMenuCollapsedOpen, 
	user, 
	changePasswordRequest, 
	userId, 
	isError, 
	errorMessage,
	routsUser, 
	userRoles,
	isSuperadminCurrentUser,
}): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch()
	
	const handleLogOut = () => {
		navigate('/')
		dispatch({type: ActionTypes.LOG_OUT})
	}

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClickLoginMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseLoginMenu = () => { setAnchorEl(null); };
	const handleToggleMobileSidebar = () => { mobileMenuCollapsedOpen() }

	const {theme, setTheme} = useContext(ThemeCtx) 
	const changeTheme = () => { 
		setTheme((prevState) =>{
			localStorage.setItem( "localTheme", prevState ? "darkTheme" : "lightTheme" )
			return !prevState
		}) 
	}

	const [openChangePasswordForm, setOpenChangePasswordForm] = React.useState(false);
    const handleOpenChangePasswordForm = () => {
        setOpenChangePasswordForm(true);
    };
    const handleCloseChangePasswordForm = () => setOpenChangePasswordForm(false);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };

	const accessedRole = routsUser.some(rout => rout.child.includes('/user-management/auth/change-own-password')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadminCurrentUser;

	return (
		<BoxStyled sx={{ flexGrow: 1 }}>
			<AppBarStyled position="static" >
				<Toolbar>

					{window.screen.width < 767 && 
						<IconButton aria-label="menu User" size="large" onClick={handleToggleMobileSidebar}>
							<MenuIcon fontSize="large" />
						</IconButton>
					}

					{ 
						<Link to='/'>
							<img 
								src={(window.screen.width > 767) 	
									? theme 
										? logoImage 
										: logoImageDarkMode 
									: theme 
										? logoImageMobile 
										: logoImageMobileDarkMode
								} 
								alt={`Moneygrator`} 
							/> 
						</Link>
					}
					
					<Typography component="div" sx={{ flexGrow: 1 }}></Typography>    

					<TimeZone />

					<Stack direction="row" alignItems="center">
							
						<AvatarLogin />
								
						{/* <TitleLogin>
							{user}
						</TitleLogin> */}

						<IconButton 
							aria-label="fade-button"         
							id="fade-button"
							aria-controls={open ? 'fade-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClickLoginMenu}
						>
							<MoreVertIcon fontSize="medium" />
						</IconButton>

						<StyledMenu
							id="fade-menu"
							MenuListProps={{
								'aria-labelledby': 'fade-button',
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={handleCloseLoginMenu}
							TransitionComponent={Fade}
						>
							{accessedRole &&
								<StyledMenuItem onClick={handleOpenChangePasswordForm}>
									<WrapSpanIcon>
										<BorderColorIcon sx={{ fontSize: 20 }} />
									</WrapSpanIcon>                                
									Change password
								</StyledMenuItem>
							}
							<StyledMenuItem onClick={handleLogOut}>
								<WrapSpanIcon>
									<LogoutIcon sx={{ fontSize: 20 }} />
								</WrapSpanIcon> 
								Logout
							</StyledMenuItem>
						</StyledMenu>
					</Stack>

					<IconButton 
						aria-label="delete" 
						size="large" 
						onClick={ () => {changeTheme()} }			
					>
						{ theme ? <DarkModeOutlined fontSize="medium" /> : <LightModeIcon fontSize="medium" /> }
					</IconButton>

				</Toolbar>
			</AppBarStyled>
			<Modal
                open={openChangePasswordForm}
                onClose={handleCloseChangePasswordForm}
            >
                <StyledBoxPopup>
                    <ChangePasswordForm onClose={handleCloseChangePasswordForm} changePasswordRequest={changePasswordRequest} id={userId} handleOpenSnackbar={handleOpenSnackbar}/>
                </StyledBoxPopup>
            </Modal>
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                {isError ?
                <InfoPopup
                    severity="error"
                    headerText="Password has not changed"
					infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                /> :
                <InfoPopup
                    severity="success"
                    headerText="Password has been changed"
                />}
            </Snackbar>
		</BoxStyled>
	);
};


export default compose(
    connect((state: RootState) => ({
        user: state.currentUser.userData.username,
		userId: state.currentUser.userData.id,
		isError: state.usersAll.isError,
		errorMessage: state.usersAll.errorMessage,
		routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadminCurrentUser: state.currentUser.userData.superadmin,
    }), {changePasswordRequest}),
)(Header)