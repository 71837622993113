import {ActionTypes} from '../reducers/usersVisitLog';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_VISIT_LOG_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_VISIT_LOG_SUCCESS,
		payload: list
	};
};

export const requestItem = item => {
	return {
		type: ActionTypes.FETCH_ITEM_VISIT_LOG_SUCCESS,
		payload: item,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_VISIT_LOG_FAILURE,
		payload: error
	}
};

export const usersVisitLogRequest = data => dispatch => {
	dispatch(requestStart());
	return API.usersVisitLog
		.getList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};


export const usersVisitLogRequestItem = id => dispatch => {
	dispatch(requestStart());
	return API.usersVisitLog
		.getItem(id)
		.then(response => {
			dispatch(requestItem(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};
