import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';

const EditUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

type Props = {
    onClose: () => void;
    initialState: {name, code},
    sendChange: (body: {}) => any,
}

export const EditUserForm = (props: Props) => {
    const {
        onClose, 
        initialState,
        sendChange,
    } = props;

    const [values, setValues] = useState(initialState);

    const hendleChange = (key, e) => {
        setValues((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            }
        })
    }

    const handleSave = () => {
        sendChange(values);
        onClose();
    }

    return (
        <EditUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Editing permission group: {initialState.name}
                </Title>
                <Input 
                    value={values.name} 
                    label="Name *" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    onChange={(e) => hendleChange('name', e)}
                />
                <Input 
                    value={values.code} 
                    label="Code *" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    onChange={(e) => hendleChange('code', e)}
                />     
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleSave}
                >
                    SAVE
                </Button>
                <Button variant="outlined" onClick={onClose}>CANCEL</Button>
            </ButtonsContainer>
        </EditUserFormContainer>
    );
};
