import {ActionTypes} from '../reducers/hdbkRequestType';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_HDBK_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_HDBK_SUCCESS,
		payload: list
	};
};

export const requestItem = item => {
	return {
		type: ActionTypes.FETCH_HDBK_ITEM_SUCCESS,
		payload: item,
	};
};

export const requestDelete = id => {
	return {
		type: ActionTypes.FETCH_HDBK_DELETE,
		payload: id,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_HDBK_FAILURE,
		payload: error
	}
};

export const hdbkRequest = data => dispatch => {
	dispatch(requestStart());
	return API.systemInfoHdbkRequestsTypes
		.hdbkList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const hdbkItemRequest = id => dispatch => {
	dispatch(requestStart());
	return API.systemInfoHdbkRequestsTypes
		.hdbkItem(id)
		.then(response => {
			dispatch(requestItem(response.data));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const hdbkDelete = id => dispatch => {
	dispatch(requestStart());
	return API.systemInfoHdbkRequestsTypes
		.hdbkDelete(id)
		.then(response => {
			dispatch(hdbkRequest(''));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const hdbkCreate = (data) => dispatch => {
	dispatch(requestStart());
	return API.systemInfoHdbkRequestsTypes
		.hdbkCreate(data)
		.then(response => {
			dispatch(hdbkRequest(''));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const hdbkUpdate = (id, data) => dispatch => {
	dispatch(requestStart());
	return API.systemInfoHdbkRequestsTypes
		.hdbkUpdate(id, data)
		.then(response => {
			dispatch(hdbkItemRequest(data.id))
		})
		.catch(error => dispatch(requestFailure(error.message)));
};