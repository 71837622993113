import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/user_payment_systems';

const UserPaymentSystems = {
	getUserPaymentSystems: (params) => {
        const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';

        return baseApi.get(`${path}${query}`)
    },
    createUserPaymentSystem: (body) => {
        return baseApi.post(`${path}`, body)
    },
    getUserPaymentSystem: (id) => {
        return baseApi.get(`${path}/view/${id}`)
    },
    updateUserPaymentSystem: (id, body) => {
        return baseApi.patch(`${path}/${id}`, body);
    },
}

export default UserPaymentSystems;