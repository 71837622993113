import {ActionTypes} from '../reducers/migration';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_MIGRATION_START
});

export const requestSuccess = (migration) => {
	return {
		type: ActionTypes.FETCH_MIGRATION_SUCCESS,
		payload: migration
	};
};

export const requestCreate = (name) => {
	return {
		type: ActionTypes.FETCH_MIGRATION_CREATE,
		payload: name
	};
};


export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_MIGRATION_FAILURE,
		payload: error
	}
};

export const requestMigration = migration => dispatch => {
	dispatch(requestStart());

	return API.migrations
		.migratonList(migration)
		.then(res => {
			dispatch(requestSuccess(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const createMigration = name => dispatch => {
	dispatch(requestStart());

	return API.migrations
		.createMigration(name)
		.then(res => {
			dispatch(requestCreate(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};
