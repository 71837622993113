import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const MoreSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 11 22"
            onClick={onClick}
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M5.49984 7.33325C6.50817 7.33325 7.33317 6.50825 7.33317 5.49992C7.33317 4.49159 6.50817 3.66659 5.49984 3.66659C4.4915 3.66659 3.6665 4.49159 3.6665 5.49992C3.6665 6.50825 4.4915 7.33325 5.49984 7.33325ZM5.49984 9.16659C4.4915 9.16659 3.6665 9.99159 3.6665 10.9999C3.6665 12.0083 4.4915 12.8333 5.49984 12.8333C6.50817 12.8333 7.33317 12.0083 7.33317 10.9999C7.33317 9.99159 6.50817 9.16659 5.49984 9.16659ZM5.49984 14.6666C4.4915 14.6666 3.6665 15.4916 3.6665 16.4999C3.6665 17.5083 4.4915 18.3333 5.49984 18.3333C6.50817 18.3333 7.33317 17.5083 7.33317 16.4999C7.33317 15.4916 6.50817 14.6666 5.49984 14.6666Z" fill={fill}/>
        </WrapperTag>
    );
};

MoreSvg.defaultProps = {
    fill: '#575D68',
};
