import React from "react";
import MainPermissionGroupPage from './permission-group-main';
import DetailsPermissionGroupPage from './permission-group-details';
export const PermissionGroups = () => {
    const [view, setView] = React.useState({
        point: 'main',
        code: ''
    })

    return(
        <>
            { view.point === 'main' && <MainPermissionGroupPage setView={setView} />}
            { view.point === 'view' && <DetailsPermissionGroupPage setView={setView} view={view} />}
        </>
    )
} 

export default PermissionGroups;