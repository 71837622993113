import React from "react";
import MainRolesPage from './roles-main';
import DetailRolesPage from './role-details';
export const UsersPageOnBranch = () => {
    const [view, setView] = React.useState({
        point: 'main',
        id: ''
    })

    return(
        <>
            { view.point === 'main' && <MainRolesPage setView={setView} />}
            { view.point === 'view' && <DetailRolesPage setView={setView} view={view} />}
        </>
    )
} 

export default UsersPageOnBranch;