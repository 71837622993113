import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const WrapPagination = styled('div')(({ theme }) => ({
    height: '52px',
    boxSizing: 'border-box',
    borderTop: `1px solid ${ theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'}`,
    marginTop: '-1px',
    paddingRight: '2px',
    paddingLeft: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    zIndex: '33',
}));

const WrapButtons = styled('div')(({ theme }) => ({
    marginLeft: '20px',
    display: 'flex',
    flexWrap: 'wrap',
}));

const WrapCount = styled('div')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '14px',
}));

type Props = {
    page: number;
    rows: number;
    onPageChange: any;
    isLastPage?: boolean;
}

export const PaginationPPS = ({page, rows, onPageChange, isLastPage}: Props) => {
    const handleIncrPage = () => {
        onPageChange(page + 1)
    }

    const handleDecrPage = () => {
        page < 1 ? onPageChange(page) : onPageChange(page - 1)
    }

    return (
        <WrapPagination>
            <WrapCount>
                {`${(page*rows) + 1} - ${(page+1)*rows}`}
            </WrapCount>
            <WrapButtons>
                <IconButton 
                    disabled={page < 1 ? true : false}
                    onClick={handleDecrPage}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    onClick={handleIncrPage}
                    disabled={isLastPage}
                >
                    <ChevronRightIcon />
                </IconButton>
            </WrapButtons>
        </WrapPagination>
    );
};
