import React, {useState, useEffect} from 'react';
// import {Button} from '../../../components/button';
import {HasChieldRoutePermission} from './_has-chield-permission';
import {ChildPermissions} from '../../../reducers/permission'
// import {Input} from '../../../components/input';
import { styled } from "@mui/material/styles";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";


export const WrapSearchPanel = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '500px',
    paddingTop: '20px',
}));

export const ScrolledContainer = styled('div')(({ theme }) => (`
    width: 100%;
    padding-top: 30px;
    padding-right: 15px;
    position: relative;
    @media(max-width: 767px){
        width: 400px;
    }; 

    & .overlay-scroll-list{
        width: 100%;
        max-height: calc(100vh - 550px);
        @media(max-width: 767px){
            // max-height: calc(100vh - 370px - 56px);
        }; 
    }
`));

type Props = {
    childPermissions: Array<ChildPermissions>;
    onSavePermission: (data: Array<ChildPermissions>) => void;
}

export const PermissionChildList: React.FC<Props> = ({childPermissions, onSavePermission}) => {
    const childPermissionsModCheck = childPermissions.map(item => {
        item.checked = false
        return item;
    })

    const [permissionsState, setPermissionsState] = useState<Array<ChildPermissions>>(childPermissionsModCheck);

    const handleChange = (code) => {
        setPermissionsState(prev => {
            let changedState = [...prev];
            changedState.forEach(item => {
                if(item.code === code){
                    item.checked = !item.checked
                }
            })
            return changedState
        })
    }

    useEffect(() => {
        setPermissionsState(childPermissions)
    }, [childPermissions])

    // const handleSave = () => {
    //     onSavePermission(permissionsState) //...
    // }

    const filteredPermission = (key) => {
        return permissionsState.filter(el => el.name.toLowerCase().includes(key.toLowerCase()))
    }

    const [
        filter,  
        // setFilter
    ] = useState<string>('');
    
    // const handleChangeFilter = (e) => {
    //     setFilter(e.target.value)
    // }

    return <>
        {/* <WrapSearchPanel>
            <Input 
                placeholder='Filter'
                value={filter} 
                onChange={handleChangeFilter}
                sx={{
                    maxWidth: 'calc(100% - 100px)',
                    margin: '0px',
                }}
            />
            <Button 
                onClick={handleSave}
            >
                Save
            </Button>
        </WrapSearchPanel> */}
        <ScrolledContainer>
            <OverlayScrollbarsComponent
                className='overlay-scroll-list'
                options={{
                    scrollbars: { visibility: 'visible' } 
                }}
            >
                <HasChieldRoutePermission chahgeElement={handleChange} items={filteredPermission(filter)} />
            </OverlayScrollbarsComponent>
        </ScrolledContainer>
    </>
}