import React from 'react';
import { styled } from "@mui/material/styles";
import {DoneSvg} from '../svg/done-svg';
import {ErrorOutlineSvg} from '../svg/error-outline-svg';
import {InfoOutlineSvg} from '../svg/info-outline-svg';

interface Props {
    severity: string;
};

const InfoPopupContainer = styled('div')<Props>( ({severity}) => ({
    width: '500px',
    padding: '20px',
    background: severity === 'success' ? '#F1F8ED' : severity === 'error' ? '#FFE2E2' : '#E0F1FF',
    display: 'flex',
    boxShadow: '-2px 2px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px'
}));

const InfoPopupText = styled('div')({
    marginLeft: '12px',
    display: 'flex',
    flexDirection: 'column'
});

const InfoPopupHeader = styled('div')({
    fontWeight: '700',
    fontSize: '16px',
    color: '#272F3D',
    marginBottom: '5px'
});

const InfoPopupInfoText = styled('div')({
    fontWeight: '400',
    fontSize: '14px',
    color: '#272F3D'
});

const InfoPopupChildren = styled('div')({
    fontWeight: '400',
    fontSize: '14px',
    color: '#272F3D'
});

type InfoPopupProps = {
    severity: string;
    headerText: string;
    infoText?: string;
    children?: any;
    dataTestid?: string;
}

export const InfoPopup = React.forwardRef<HTMLDivElement, InfoPopupProps>(function InfoPopup(
    {severity, headerText, infoText, children, dataTestid},
    ref,
) {
    const Icon = severity === 'success' ? DoneSvg : severity === 'error' ? ErrorOutlineSvg : InfoOutlineSvg;

    return (
        <InfoPopupContainer 
            data-testid={dataTestid} 
            severity={severity} 
            ref={ref}
        >
            <Icon size="24px"/>
            <InfoPopupText>
                <InfoPopupHeader>
                    {headerText}
                </InfoPopupHeader>
                <InfoPopupInfoText>
                    {infoText}
                </InfoPopupInfoText>
                <InfoPopupChildren>
                    {children}
                </InfoPopupChildren>
            </InfoPopupText>
        </InfoPopupContainer>
    )
});

