export namespace ActionTypes {
    export const FETCH_PEMISSION_GROUPS_START = 'FETCH_PEMISSION_GROUPS_START';
    export const FETCH_PEMISSION_GROUPS_SUCCESS = 'FETCH_PEMISSION_GROUPS_SUCCESS';
    export const FETCH_PEMISSION_GROUPS_FAILURE = 'FETCH_PEMISSION_GROUPS_FAILURE';
	export const FETCH_ITEM_PEMISSION_GROUPS_SUCCESS = 'FETCH_ITEM_PEMISSION_GROUPS_SUCCESS';
};

export type PermissionGroupListItem = {
    "@id": string;
    "@type": string;
    code: string;
    name: string;
    createdAt: number;
    updatedAt: number;
}

export type PermissionGroupList = {
	'@context': string;
	'@id': string;
	'@type': string;
	'hydra:member': Array<PermissionGroupListItem>;
	'hydra:search': any;
	'hydra:totalItems': number;
} 

interface permissionGroupsType {
	isError: boolean,
    isLoading: boolean,
	permissionGroups: PermissionGroupList,
	permissionItemGroups: {
		code: string,
		name: string,
		createdAt: string | number,
		updatedAt: string | number,
	}
	errorMessage: string,
}

export const initialState: permissionGroupsType = {
	isError: false,
    isLoading: false,
	permissionGroups: {
		'@context': '',
		'@id': '',
		'@type': '',
		'hydra:member': [
			{
				"@id": '',
				"@type": '',
				code: '',
				name: '',
				createdAt: 1695289313,
				updatedAt: 1695289313,
			}
		],
		'hydra:search': {},
		'hydra:totalItems': 1,
	},
	permissionItemGroups: {
		code: '',
		name: '',
		createdAt: 1695282946,
		updatedAt: 1695282946,
	},
	errorMessage: '',
};

export const permissionGroups = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_PEMISSION_GROUPS_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_PEMISSION_GROUPS_SUCCESS:
			return {
				...state,
				permissionGroups: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_ITEM_PEMISSION_GROUPS_SUCCESS:
			return {
				...state,
				permissionItemGroups: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_PEMISSION_GROUPS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
