import React from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {ExampleText} from '../../components/example-text';

const EditUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: '20px',
    width: '100%',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },   
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

const filterStatusItems = [{title: 'Active', value: 1}, {title: 'Inactive', value: 0}, {title: 'Banned', value: -1}];

type Props = {
    onClose: () => void;
    userInit: any;
    onSave: (data: any) => void;
}

export const EditUserForm = (props: Props) => {
    const {
        onClose,
        userInit,
        onSave,
    } = props;

    const [userState, setUserState] = React.useState(userInit);
    const handleChangeField = (key, e) => {
        if(e.target.type === 'checkbox'){
            setUserState(prev => ({...prev, [key]: !prev.emailConfirmed}));
        } else {
            setUserState(prev => ({...prev, [key]: e.target.value}));
        }
    }

    const handleSave = () => {
        const data = {
            "username": userState.username,
            "status": userState.status,
            "bindToIp": userState.bindToIp,
            "email": userState.email,
            "emailConfirmed": userState.emailConfirmed ? 1 : 0,
            "roleNote": userState.roleNote,
        };
        onSave(data);
        onClose();
    }

    return (
        <EditUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Edit user {userInit.username}
                </Title>

                <StyledFormControl fullWidth>
                    <InputLabel size='small'>Status</InputLabel>
                    <Select
                        value={userState.status}
                        label="Status"
                        onChange={(e) => handleChangeField('status', e)}
                        size="small"
                    >
                        {
                            filterStatusItems.map((item)=>{
                                return <StyledMenuItem key={Math.random()} value={item.value}>{item.title}</StyledMenuItem>
                            })
                        }
                    </Select>
                </StyledFormControl>

                <Input 
                    label="Note" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={userState.roleNote}
                    onChange={(e) => handleChangeField('roleNote', e)}
                />

                <Input 
                    label="Login *" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={userState.username}
                    onChange={(e) => handleChangeField('username', e)}
                />

                <Input 
                    label="Bind to IP" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={userState.bindToIp}
                    onChange={(e) => handleChangeField('bindToIp', e)}
                />
                <ExampleText>For example: 123.34.56.78, 168.111.192.12</ExampleText>

                <Input 
                    label="E-mail" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={userState.email}
                    onChange={(e) => handleChangeField('email', e)}
                />
                    
                <FormControlLabel 
                    control={
                        <Checkbox
                            checked={Boolean(userState.emailConfirmed)}
                            onChange={(e) => handleChangeField('emailConfirmed', e)}
                            sx={{
                            color: '#38AA72',
                                '&.Mui-checked': {
                                    color: '#38AA72',
                                },
                            }}
                        />
                    } 
                    label="E-mail confirmed" 
                    sx={{
                        marginTop: '8px',
                    }}
                />

            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleSave}
                >
                    SAVE
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={onClose}
                >
                    CANCEL
                </Button>
            </ButtonsContainer>
        </EditUserFormContainer>
    );
};
