export namespace actionTypes {
    export const SET_HISTORY_OF_TRANSACTIONS_STATUSES = 'SET_HISTORY_OF_TRANSACTIONS_STATUSES';
    export const SET_HISTORY_OF_TRANSACTIONS_STATUSES_LOADING = 'SET_HISTORY_OF_TRANSACTIONS_STATUSES_LOADING';
}

export type itemStatusType = {
	'@id': string;
	'@type': string;
	id: number;
	status: string;
	transaction: string;
	updatedAt: string;
}

interface InitialType {
	historyTransStatuses: {
		'@context': string,
		'@id': string,
		'@type': string,
		'hydra:member': itemStatusType[],
		'hydra:search': any,
		'hydra:view': any,
	},
	isLoading: false,
}

const initialState: InitialType = {
	historyTransStatuses: {
		'@context': '',
		'@id': '',
		'@type': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:view': '',
	},
	isLoading: false,
};

export const historyTransStatus = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_HISTORY_OF_TRANSACTIONS_STATUSES_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
		case actionTypes.SET_HISTORY_OF_TRANSACTIONS_STATUSES:
			return { 
				...state, 
				historyTransStatuses: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default historyTransStatus;