import {actionTypes} from '../reducers/requestElasticSearch';
import API from '../services/api';
// import {hdbkRequest} from './systenInfoHdbkRequestTypes'

const requestStart = () => ({
    type: actionTypes.SET_REQUEST_ELASTIC_SEARCH_LOADING
});

const requestSuccess = (data) => ({
    type: actionTypes.SET_REQUEST_ELASTIC_SEARCH,
    payload: data
});

const requestTypes = (data) => ({
    type: actionTypes.SET_REQUEST_ELASTIC_SEARCH_TYPES,
    payload: data,
})

export const requestOptionsList = () => dispath => {
    return API.requestElasticSearch.getOptionsList()
    .then((response) => {
        dispath(requestTypes(response.data))
    })
}

export const requestElasticSearch = (params) => dispatch => {
    dispatch(requestStart())
    return API.requestElasticSearch.getList(params)
    .then(response => {
        dispatch(requestSuccess(response.data))
    })
    .catch(error => console.log(error));
};
