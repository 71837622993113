import React from 'react';
import {styled} from "@mui/material/styles";
import {DateRangePicker} from '../../components/date-range-picker';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Input} from '../../components/input';
import {SwitchComponent} from '../../components/switch'
import {Button} from '../../components/button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {EmptyDataSvg} from '../../components/svg/empty-data-svg';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {logsRequest} from '../../actions/logViewer';
import TablePagination from '@mui/material/TablePagination';
import {format} from 'date-fns';
import {Loader} from '../../components/loaders/loader';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
// import { AnyARecord } from 'dns';
import { AccessDeniedAlert } from '../../components/access-denied';

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const LogViewerPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap',
        marginBottom: '0px'
    }
});

const LogViewerPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const RangePickerWrapper = styled('div')({
    marginRight: '16px',
    width: '270px',
    maxWidth: '100%',
    marginTop: '20px',

    '@media(max-width: 768px)' : {
        marginRight: '0px',
        marginTop: '26px',
        marginBottom: '0px',
        width: '100%'
    }
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '220px',
    marginRight: '16px',
    marginTop: '20px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },

    '@media(max-width: 768px)' : {
        width: '100%',
        marginRight: '0px',
        marginTop: '20px'
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const StyledInput = styled(Input)({
    marginTop: '20px',
    marginBottom: '0px',
    width: '220px',
    maxWidth: '100%',

    '@media(max-width: 768px)' : {
        marginTop: '16px',
        marginRight: '0px',
        width: '100%'
    }
})

const LogViewerPageFirstRow = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',

    '@media(max-width: 768px)' : {
        flexDirection: 'column'
    }
});

const LogViewerPageSecondRow = styled('div')({
    display: 'flex',
    marginTop: '25px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    },
    '& .MuiFormControlLabel-label':{
        paddingLeft: '8px',
    }
});

const ButtonsContainer = styled('div')({
    display: 'flex'
});

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    // border-bottom: none;
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const TableIconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

const TableIcon = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 325px - 56px);
        @media(max-width: 1500px){
            max-height: calc(100vh - 385px - 56px);
        };
        @media(max-width: 980px){
            max-height: calc(100vh - 445px - 56px);
        }; 
        @media(max-width: 767px){
            max-height: calc(100vh - 560px - 56px);
        }; 
    }
`));

const leveleList = [1,2,3,4,5,6,7];
const facilityList = [
    {value: 'api', title: 'Api'},
    {value: 'backend', title: 'Backend'},
    {value: 'console', title: 'Console'},
    {value: 'frontend', title: 'Frontend'},
    {value: 'common', title: 'Common'},
];

type PropsType = {
    isLoading: boolean,
    logs: any[],
    logsCount: number,
    timezoneCurrent: string,
    logsRequest: (data: any) => void,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

type StateType = {
    facility: string,
    level: string,
    checked: boolean,
    page: number,
    rowsPerPage: number,
    startDate: string,
    endDate: string,
    clearDates: boolean,
    category: string,
    message: string,
}

class LogViewerPage extends React.Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);

        this.state = {
            facility: '',
            level: '',
            checked: true,
            page: 0,
            rowsPerPage: 20,
            startDate: '',
            endDate: '',
            clearDates: false,
            category: '',
            message: ''
        }
    };

    handleChangeFacility = (event: SelectChangeEvent) => {
        this.setState({facility: event.target.value as string})
    };

    handleChangeLevel = (event: SelectChangeEvent) => {
        this.setState({level: event.target.value as string})
    };
    
    handleChangeSwith = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({checked: event.target.checked});
    };

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    setDates = (startDate, endDate) => {
        this.setState({startDate: startDate, endDate: endDate, clearDates: false});

    };

    handleChange = prop => event => {
        //@ts-ignore
        this.setState({[prop]: event.target.value});
    };

    handleReset = () => {
        this.setState({startDate: '', endDate: '', clearDates: true, facility: '', level: '', category: '', message: ''}, () => {this.updateData()});
    }

    updateData = () => {
        const {logsRequest} = this.props;

        const {
            page,
            startDate,
            endDate,
            facility,
            level,
            category,
            message,
            checked
        } = this.state;

        const data = {
            page: page + 1,
            dateRange: startDate && endDate ? `${format(new Date(startDate), 'yyyy-MM-dd')} - ${format(new Date(endDate), 'yyyy-MM-dd')}` : '',
            facility: facility,
            level: level,
            category: category,
            message: message,
            excludeSystemLog: !checked
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        logsRequest(data);
    };

    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/site/log')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.timezoneCurrent !== this.props.timezoneCurrent){
            this.setState({page: 0}, () => {this.updateData()});
        };
    };

    render() {
        const {
            facility,
            checked,
            level,
            page,
            rowsPerPage,
            clearDates,
            category,
            message
        } = this.state;

        const {
            logsCount,
            logs,
            isLoading,
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        const accessedRole = routsUser.some(rout => rout.child.includes('/site/log')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole && 
                    <>
                        <LogViewerPageHeader>
                            <LogViewerPageHeaderText>
                                Log Viewer
                            </LogViewerPageHeaderText>
                        </LogViewerPageHeader>
                        <LogViewerPageFirstRow>
                            <RangePickerWrapper>
                                <DateRangePicker label="Date range" setDates={this.setDates} clearDates={clearDates}/>
                            </RangePickerWrapper>
                            <StyledFormControl size="small">
                                <InputLabel>Facility</InputLabel>
                                <Select
                                    value={facility}
                                    label="Facility"
                                    onChange={this.handleChangeFacility}
                                >
                                    {facilityList.map(item => <StyledMenuItem key={Math.random()} value={item.value}>{item.title}</StyledMenuItem>)}
                                </Select>
                            </StyledFormControl>
                            {checked && <StyledFormControl size="small">
                                <InputLabel>Level</InputLabel>
                                <Select
                                    value={level}
                                    label="Level"
                                    onChange={this.handleChangeLevel}
                                >
                                    {leveleList.map(item => <StyledMenuItem key={item} value={item}>{item}</StyledMenuItem>)}
                                </Select>
                            </StyledFormControl>}
                            {checked && <StyledInput sx={{marginRight: '16px'}} size="small" label="Category" value={category} onChange={this.handleChange('category')}></StyledInput>}
                            <StyledInput size="small" label="Message" value={message} onChange={this.handleChange('message')}></StyledInput>
                        </LogViewerPageFirstRow>
                        <LogViewerPageSecondRow>
                            <SwitchComponent
                                checked={checked}
                                sx={{marginTop: '10px'}} 
                                label=" Include System Log"
                                onChange={this.handleChangeSwith}
                            />
                            <ButtonsContainer>
                                <Button sx={{marginRight: '12px'}} onClick={this.updateData}>SEARCH</Button>
                                <Button variant='outlined' onClick={this.handleReset}>RESET</Button>
                            </ButtonsContainer>
                        </LogViewerPageSecondRow>
                        <StyledPaperTable>
                            <StyledTableContainer>

                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >

                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>
                                                <TableCell><div style={{width: '180px'}}>Date</div></TableCell>
                                                <TableCell><div style={{width: '120px'}}>Facility</div></TableCell>
                                                { checked && <TableCell>  <div style={{width: '120px'}}>Level</div></TableCell> }
                                                { checked && <TableCell> <div style={{width: '120px'}}>Category</div> </TableCell> }
                                                <TableCell>Message</TableCell>
                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {logs?.length ? logs.map(row => {
                                                return (
                                                    <TableRow key={Math.random()}>
                                                        <TableCell>
                                                            {row.created_at}
                                                        </TableCell>
                                                        <TableCell>{row.facility}</TableCell>
                                                        { checked && <TableCell>{row.level}</TableCell> }
                                                        { checked && <TableCell>{row.category}</TableCell> }
                                                        <TableCell>{row.message}</TableCell>
                                                    </TableRow>
                                                )
                                            }) :
                                                <TableRow>
                                                    <TableCell colSpan={6}>
                                                        <TableIconWrapper>
                                                            <TableIcon>
                                                                <EmptyDataSvg height="80px"/>
                                                                No results found.
                                                            </TableIcon>
                                                        </TableIconWrapper>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>

                                </OverlayScrollbarsComponent>

                            </StyledTableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={logsCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </StyledPaperTable>
                    </>
                }

                {isLoading && <Loader />}
            </PageContainer>
        );
    }
};

export default compose(
    connect((state: RootState) => ({
        isLoading: state.logViewer.isLoading,
        logs: state.logViewer.logs['hydra:member'],
        logsCount: state.logViewer.logs['hydra:totalItems'],
        timezoneCurrent: state.currentUser.userData.timezone,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {logsRequest})
)(LogViewerPage)