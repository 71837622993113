export namespace ActionTypes {
    export const GET_PAYMENT_SYSTEMS = 'GET_PAYMENT_SYSTEMS';
	export const CREATE_PAYMENT_SYSTEM_SUCCESS = 'CREATE_PAYMENT_SYSTEM_SUCCESS';
    export const CREATE_PAYMENT_SYSTEM_ERROR = 'CREATE_PAYMENT_SYSTEM_ERROR';
	export const GET_PAYMENT_SYSTEMS_LIST = 'GET_PAYMENT_SYSTEMS_LIST';
	export const GET_PAYMENT_SYSTEMS_LIST_FILTERED = 'GET_PAYMENT_SYSTEMS_LIST_FILTERED';
	export const GET_PAYMENT_SYSTEM = 'GET_PAYMENT_SYSTEM';
	export const CHANGE_PAYMENT_SYSTEM = 'CHANGE_PAYMENT_SYSTEM';
	export const SET_LOADING_PAYMENT_SYSTEMS = 'SET_LOADING_PAYMENT_SYSTEMS';
	export const GET_PAYMENT_SYSTEMS_AMOUNT = 'GET_PAYMENT_SYSTEMS_AMOUNT';
};

export const initialState = {
    error: false,
	isLoading: false,
    paymentSystems: {'hydra:member': [], 'hydra:totalItems': 0},
	paymentSystemsList: {'hydra:member': []},
	paymentSystemsListFiltered: {'hydra:member': []},
	paymentSystem: [],
	paymentSystemsAmount: 1,
};

export const paymentSystems = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.GET_PAYMENT_SYSTEMS:
			return {
				...state,
				paymentSystems: payload,
				isLoading: false
			};
		case ActionTypes.CREATE_PAYMENT_SYSTEM_SUCCESS:
			return {
				...state,
				error: false
			};
		case ActionTypes.CREATE_PAYMENT_SYSTEM_ERROR:
			return {
				...state,
				error: true
			};
		case ActionTypes.GET_PAYMENT_SYSTEMS_LIST:
			return {
				...state,
				paymentSystemsList: payload
			};
		case ActionTypes.GET_PAYMENT_SYSTEMS_LIST_FILTERED:
			return {
				...state,
				paymentSystemsListFiltered: payload
			};			
		case ActionTypes.GET_PAYMENT_SYSTEM:
			return {
				...state,
				paymentSystem: payload,
				isLoading: false
			};
		case ActionTypes.CHANGE_PAYMENT_SYSTEM:
			return {
				...state
			}
		case ActionTypes.SET_LOADING_PAYMENT_SYSTEMS:
			return {
				...state,
				isLoading: true
			}
		case ActionTypes.GET_PAYMENT_SYSTEMS_AMOUNT:
			return {
				...state,
				paymentSystemsAmount: payload,
				isLoading: false
			}
		default:
			return state;
	}
};