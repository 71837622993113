export namespace ActionTypes {
    export const FETCH_SYS_INFO_BUIER_LOG = 'FETCH_SYS_INFO_BUIER_LOG';
    export const FETCH_SYS_INFO_BUIER_LOG_LOADING = 'FETCH_SYS_INFO_BUIER_LOG_LOADING';
	export const FETCH_SYS_INFO_BUIER_LOG_FAILURE = 'FETCH_SYS_INFO_BUIER_LOG_FAILURE';
	export const FETCH_SYS_INFO_BUIER_LOG_ITEM = 'FETCH_SYS_INFO_BUIER_LOG_ITEM';
}

export type buierItem = {
	"@id": string,
	"@type": string,
	id: number,
	buyerId: number,
	remoteAddr: string,
	hostInfo: string,
	userAgent: string,
	acceptLanguage: string,
	redirectMethod: string,
	redirectTo: string,
	redirectData: string | [],
	browserHash: string,
	createdAt: number,
}

export type buierItemLog = {
    "@id": string,
    "@type": string,
    buyerId: number,
    browserHash: string,
}

type stateResponse = {
	'@context': string;
	'@id': string;
	'hydra:member': buierItem[];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

type stateBuierLog = {
	systemInfoBuyerLog: stateResponse;
	isLoading: boolean;
	systemInfoBuyerLogItem: buierItemLog[];
}

const initialState: stateBuierLog = {
	systemInfoBuyerLog: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 111,
		'hydra:view': '',
	},
	isLoading: false,
	systemInfoBuyerLogItem: [],
};

export const systemInfoBuierLog = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_SYS_INFO_BUIER_LOG_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
		case ActionTypes.FETCH_SYS_INFO_BUIER_LOG:
			return { 
				...state, 
				systemInfoBuyerLog: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_SYS_INFO_BUIER_LOG_ITEM:
			return { 
				...state, 
				systemInfoBuyerLogItem: payload,
				isLoading: false,
			};			
		default:
			return state;
	}
};

export default systemInfoBuierLog;