import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/users';
const pathRoles = 'api/rbac/assignments';
const pathUserThreeRoles = 'api/rbac/assignments/tree';
const pathassigment = 'api/rbac/assignments/user';

const UsersAll = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';
		return baseApi.get(`${path}${query}`)
	},
	getItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
	createItem: (body) => {
		return baseApi.post(`${path}`, body)
	},
	changePassword: (id, body) => {
		return baseApi.patch(`${path}/change_password/${id}`, body)
	},
	getUserPermissions: (id) => {
		return baseApi.get(`${pathRoles}/${id}`)
	},
	getUserPermissionsThree: (id) => {
		return baseApi.get(`${pathUserThreeRoles}/${id}`)
	},
	assigmentUserRoles: (userId, body) => {
		return baseApi.post(`${pathassigment}/${userId}/add`, body);
	},
	deleteUser: (id) => {
		return baseApi.delete(`${path}/${id}`)
	},
	updateUser: (id, data) => {
		return baseApi.patch(`${path}/${id}`, data)
	},
}

export default UsersAll;