export namespace actionTypes {
    export const GET_WALLETS = 'GET_WALLETS';
    export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
    export const CREATE_WALLET_ERROR = 'CREATE_WALLET_ERROR';
    export const SET_LOADING_WALLETS = 'SET_LOADING_WALLETS';
};

const initialState = {
	wallets: {
		'hydra:totalItems': 0,
		'hydra:member': []
	},
	isLoading: false,
    error: ''
};

export const wallets = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_WALLETS:
			return { 
				...state, 
				wallets: payload,
                isLoading: false,
			};
		case actionTypes.CREATE_WALLET_SUCCESS:
			return { 
				...state,
				isLoading: false,
                error: ''
			};
        case actionTypes.CREATE_WALLET_ERROR:
			return { 
				...state, 
				isLoading: false,
                error: payload
			};
        case actionTypes.SET_LOADING_WALLETS:
            return {
                ...state,
                isLoading: true
            }
		default:
			return state;
	}
};

export default wallets;