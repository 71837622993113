import React from "react";
import { styled } from "@mui/material/styles";


const StyledExampleText = styled('div')(({ theme }) => (`
    color: #999;
    font-size: 14px;
`))

export const ExampleText = ({children}) => {
    return <StyledExampleText>
        {children}
    </StyledExampleText>
}