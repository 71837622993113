import {actionTypes} from '../reducers/historyTransStatus';
import API from '../services/api';

const setHistoryTransStatusStart = () => ({
    type: actionTypes.SET_HISTORY_OF_TRANSACTIONS_STATUSES_LOADING
});

const setHistoryTransStatus = (data) => ({
    type: actionTypes.SET_HISTORY_OF_TRANSACTIONS_STATUSES,
    payload: data
});

export const historyTransStatusRequest = (params) => dipatch => {
    dipatch(setHistoryTransStatusStart())
    return API.historyTransStatus.getHistoryTransStatus(params)
    .then(response => {
        dipatch(setHistoryTransStatus(response.data))
    })
    .catch(error => console.log(error));
};
