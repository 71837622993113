import React from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';

const CreateUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const FormatCodeDescriptopn = styled('div')(({ theme }) => ({
    fontSize: '12px',
    color: theme.palette.text.primary,
    opacity:'.7',
}));

type Data = {
    description: string, 
    name: string,
}

type Props = {
    onClose: () => void;
    onCreate: (data: Data) => void;
}

export const CreateUserForm = (props: Props) => {
    const {
        onClose, 
        onCreate,
    } = props;

    const [value, setValue] = React.useState({description: '', code: ''});

    const handleChange = (key, e) => {
        setValue(prev => ({...prev, [key]: e.target.value,}));
    } 

    const handleCreate = () => {
        const data = {
            description: value.description, 
            name: value.code,
        };
        onCreate(data);
        onClose();
    }

    return (
        <CreateUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Role create
                </Title>
                <Input 
                    label="Description *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    value={value.description}
                    onChange={(e) => handleChange('description', e)}
                />
                <Input 
                    label="Code *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    value={value.code}
                    onChange={(e) => handleChange('code', e)}
                />
                <FormatCodeDescriptopn>Fornat Code: aaBb, aa_bb, aa-bb</FormatCodeDescriptopn>
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    onClick={handleCreate} 
                    sx={{marginRight: '12px'}}
                    disabled={!value.code || !value.description}
                >
                    CREATE
                </Button>
                <Button onClick={onClose} variant="outlined">CANCEL</Button>
            </ButtonsContainer>
        </CreateUserFormContainer>
    );
};
