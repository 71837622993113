import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const NotificationsSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 22 22"
            onClick={onClick}
        >
            <path d="M10.9998 20.1667C12.0082 20.1667 12.8332 19.3417 12.8332 18.3334H9.1665C9.1665 19.3417 9.98234 20.1667 10.9998 20.1667ZM16.4998 14.6667V10.0834C16.4998 7.26925 14.9965 4.91341 12.3748 4.29008V2.29175H9.62484V4.29008C6.994 4.91341 5.49984 7.26008 5.49984 10.0834V14.6667L3.6665 16.5001V17.4167H18.3332V16.5001L16.4998 14.6667Z" fill={fill}/>
        </WrapperTag>
    );
};

NotificationsSvg.defaultProps = {
    fill: '#C0C0C0',
};
