import WalletsPage from '../pages/wallets-page';
import { DepositPage } from '../pages/deposit-page';
import { WithdrawPage } from '../pages/withdraw-page';
import NodeManagementPage from '../pages/node-management/node-management-main';
import NodeManagementDetails from '../pages/node-management/node-management-details';
import ReportsPage from '../pages/reports-page';
import { PaymentSystemsPage } from '../pages/payment-systems-page';
import TestApiPage from '../pages/test-api-page';
import LogViewerPage from '../pages/log-viewer-page';
import BuildAPIDocks from '../pages/build-api-docs';
import QueuesPage from '../pages/queues';
import MigrationsPage from '../pages/migrations-page';
import HistoryTransactionsStatusesPage from '../pages/history-transactions-statuse';
import RepeatQueuePage from '../pages/repeat-queue';
import StatementsPage from '../pages/statements-page';
import SystemInfiBuyerLogs from '../pages/system-info-buyer-logs';
import SystemInfoTemporaryDeposit from '../pages/system-info-temporary-deposit-links';
import SystemInfoAdresses from '../pages/system-info-addresses';
import SystemInfoHdbkRequestTypes from '../pages/system-info-hdbk-requests-types';
import TelegramPage from '../pages/telegram-page';
import PPSSSettings from '../pages/settings-pps';
import RequestsCleaner from '../pages/requests-cleaner';
import UsersPageOnSelectedNode from '../pages/users-on-selected-node';
import UserPageOnSelectedNodeDetails from '../pages/users-on-selected-node/details/user-details';
import { UsersPageOnBranch } from '../pages/users-on-branch';
// import UserOnBranchDetails from '../pages/users-on-branch/user-details';
import UsersAll from '../pages/users-mega-all-users';
import Roles from '../pages/users-mega-roles';
import DetailRolesPage from '../pages/users-mega-roles/role-details';
import Permissions from '../pages/users-mega-permissions';
import PermissionDetailsPage from '../pages/users-mega-permissions/permissions-details';
import PermissionGroups from '../pages/users-mega-permission-groups';
import VisitLog from '../pages/users-mega-visit-log';
import ActionLog from '../pages/users-mega-action-log';
import RequestElasticSearch from '../pages/request-elastic-search';
import UsersAllDetails from '../pages/users-mega-all-users/details';
import UsersAllPermissionsDetails from '../pages/users-mega-all-users/details/user-permission';
import TransactionView from '../pages/deposit-page/view';
import TransactionShortView from '../pages/deposit-page/short-view';
import PaymentSystemGlobalMainPage from '../pages/payment-systems-global-page/main-payment-system-global';
import ChangePaynemtSystem from '../pages/payment-systems-page/change-payment-system'
import PaymentSystemGlobalConfigurePage from '../pages/payment-systems-global-page/configure-payment-system-global';
import MappingPageCascades from '../pages/mapping-page-cascades';
import MappingPageMulticcaunts from '../pages/mapping-page-multiaccounts';
import DashboardPage from '../pages/dashboard';
import ApiInfo from '../pages/api-info';

export type routesMaptypes = {
    path?: string,
    element: React.ReactElement,
    index?: true;
}

export const routesMap: Array<routesMaptypes> = [
    {
        path: '/dashboard',
        element: <DashboardPage />
    },
    {
        path: '/node-management',
        element: <NodeManagementPage />
    },
    {
        path: '/node-management/details',
        element: <NodeManagementDetails />,
    },
    {
        path: '/users/on-selected-node',
        element: <UsersPageOnSelectedNode />,
    },
    {
        path: '/user-on-selected-node/:id',
        element: <UserPageOnSelectedNodeDetails />,
    },
    {
        path: '/users/on-branch',
        element: <UsersPageOnBranch />,
    },
    {
        path: '/user-on-branch/:id',
        // element: <UserOnBranchDetails />,
        element: <UserPageOnSelectedNodeDetails />,
    },
    {
        path: '/users/all-users',
        element: <UsersAll />,
    },
    {
        path: '/user/:id',
        element: <UsersAllDetails />,
    },
    {
        path: '/user/user-permissions/:id',
        element: <UsersAllPermissionsDetails />,
    },
    {
        path: '/users/roles',
        element: <Roles />,
    },
    {
        path: '/users/role/:id',
        element: <DetailRolesPage />,
    },
    {
        path: '/users/permissions',
        element: <Permissions />,
    },
    {
        path: '/users/permission/:id',
        element: <PermissionDetailsPage />,
    },
    {
        path: '/users/permission-groups',
        element: <PermissionGroups />,
    },
    {
        path: '/users/visit-log',
        element: <VisitLog />,
    },
    {
        path: '/users/action-log',
        element: <ActionLog />,
    },
    {
        path: '/request/elastic-search',
        element: <RequestElasticSearch />,
    },
    {
        path: '/wallet',
        element: <WalletsPage />,
    },
    {
        path: '/payment-systems',
        element: <PaymentSystemsPage />,
    },
    {
        path: '/payment-system/:id',
        element: <ChangePaynemtSystem />,
    },
    {
        path: '/payment-systems-global',
        element: <PaymentSystemGlobalMainPage />,
    },
    {
        path: '/payment-systems-global/:id',
        element: <PaymentSystemGlobalConfigurePage />,
    },
    {
        path: '/mapping/cascades-0.1',
        element: <MappingPageCascades />,
    },
    {
        path: '/mapping/multiaccounts',
        element: <MappingPageMulticcaunts />,
    },
    {
        path: '/transactions/deposit',
        element: <DepositPage />,
    },
    {
        path: '/transactions/view/:id',
        element: <TransactionView />,
    },
    {
        path: '/transactions/short-view/:id',
        element: <TransactionShortView />,
    },
    {
        path: '/transactions/withdraw',
        element: <WithdrawPage />,
    },
    {
        path: '/statements/',
        element: <StatementsPage />,
    },
    {
        path: '/reports/report',
        element: <ReportsPage />,
    },
    {
        path: '/test-api',
        element: <TestApiPage />,
    },
    {
        path: '/system-info/buyer-logs',
        element: <SystemInfiBuyerLogs />,
    },
    {
        path: '/system-info/temporary-deposit-links',
        element: <SystemInfoTemporaryDeposit />,
    },
    {
        path: '/system-info/user-addresses',
        element: <SystemInfoAdresses />,
    },
    {
        path: '/system-info/hdbk-requests-types',
        element: <SystemInfoHdbkRequestTypes />,
    },
    {
        path: '/dev-junk/log-viewer',
        element: <LogViewerPage />,
    },
    {
        path: '/dev-junk/build-api-docs',
        element: <BuildAPIDocks />,
    },
    {
        path: '/dev-junk/migrations',
        element: <MigrationsPage />,
    },
    {
        path: '/dev-junk/queues',
        element: <QueuesPage />,
    },
    {
        path: '/dev-junk/history-transactions-status',
        element: <HistoryTransactionsStatusesPage />,
    },
    {
        path: '/dev-junk/repeat-queue',
        element: <RepeatQueuePage />,
    },
    {
        path: '/dev-junk/requests-cleaner',
        element: <RequestsCleaner />,
    },
    {
        path: '/settings/telegram',
        element: <TelegramPage />,
    },
    {
        path: '/settings/pps-settings',
        element: <PPSSSettings />,
    }, 
    {
        path: '/api-info',
        element: <ApiInfo />,
    },  
    {
        element: <DashboardPage />,
        index: true
    }
];
