import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/user_action_logs';

const UsersActionLog = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';
		return baseApi.get(`${path}${query}`)
	},
}

export default UsersActionLog;