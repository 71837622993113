import React from "react";
import MainSettingsPage from './main-settings';
import ViewSettingsPage from './view-settings';
import EditSettingsPage from './edit-settings';

export type view = {
    point: string, 
    id: number,
}

export const PPSSettings = () => {
    const [view, setView] = React.useState<view>({point: 'main', id: 0})

    return(
        <>
            { view.point === 'main' && <MainSettingsPage setView={setView} />}
            { view.point === 'view' && <ViewSettingsPage setView={setView} view={view} /> }
            { view.point === 'edit' && <EditSettingsPage setView={setView} view={view} /> }
        </>
    )
} 

export default PPSSettings;

