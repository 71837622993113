import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const StatusWaitingSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 12 12"
            onClick={onClick}
        >
            <path d="M7.549 6.317L6.5 5.7115V3.5C6.5 3.36739 6.44732 3.24021 6.35356 3.14645C6.25979 3.05268 6.13261 3 6 3C5.86739 3 5.74022 3.05268 5.64645 3.14645C5.55268 3.24021 5.5 3.36739 5.5 3.5V6C5.5 6.08777 5.52311 6.17399 5.56699 6.24999C5.61088 6.326 5.67399 6.38912 5.75 6.433L7.049 7.183C7.10589 7.21635 7.16881 7.23812 7.23414 7.24705C7.29947 7.25598 7.36593 7.2519 7.42968 7.23505C7.49343 7.21819 7.55321 7.1889 7.60559 7.14884C7.65797 7.10879 7.70191 7.05877 7.73488 7.00166C7.76786 6.94456 7.7892 6.8815 7.7977 6.81611C7.8062 6.75072 7.80168 6.68429 7.78441 6.62065C7.76713 6.55702 7.73744 6.49743 7.69704 6.44531C7.65663 6.3932 7.60633 6.34959 7.549 6.317ZM6 1C5.0111 1 4.0444 1.29324 3.22215 1.84265C2.39991 2.39206 1.75904 3.17295 1.3806 4.08658C1.00217 5.00021 0.90315 6.00555 1.09608 6.97545C1.289 7.94536 1.76521 8.83627 2.46447 9.53553C3.16373 10.2348 4.05465 10.711 5.02455 10.9039C5.99446 11.0969 6.99979 10.9978 7.91342 10.6194C8.82705 10.241 9.60794 9.6001 10.1573 8.77785C10.7068 7.95561 11 6.98891 11 6C10.9985 4.67436 10.4713 3.40344 9.53393 2.46607C8.59656 1.52871 7.32564 1.00146 6 1ZM6 10C5.20888 10 4.43552 9.7654 3.77772 9.32588C3.11992 8.88635 2.60723 8.26164 2.30448 7.53073C2.00173 6.79983 1.92252 5.99556 2.07686 5.21964C2.2312 4.44371 2.61216 3.73098 3.17158 3.17157C3.73099 2.61216 4.44372 2.2312 5.21964 2.07686C5.99557 1.92252 6.79983 2.00173 7.53074 2.30448C8.26164 2.60723 8.88635 3.11992 9.32588 3.77772C9.76541 4.43552 10 5.20887 10 6C9.99868 7.06046 9.57683 8.07711 8.82697 8.82697C8.07711 9.57682 7.06046 9.99868 6 10Z" fill={fill}/>
        </WrapperTag>
    );
};

StatusWaitingSvg.defaultProps = {
    fill: "#65626B",
};
