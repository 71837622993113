import React from "react";
import SystemInfiBuyerLogsMain from './system-info-buyer-main';
import SystemInfiBuyerLogItem from './system-info-buyer-log';

export const SystemInfiBuyerLogs = () => {
    const [view, setView] = React.useState({hash: '', point: 'main'})
    
    return(
        <>
            { view.point === 'main' && <SystemInfiBuyerLogsMain setView={setView} />}
            { view.point === 'view' && <SystemInfiBuyerLogItem setView={setView} view={view} />}
        </>
    )
} 

export default SystemInfiBuyerLogs;