export namespace actionTypes {
    export const SET_LOGS = 'SET_LOGS';
    export const SET_LOGS_LOADING = 'SET_LOGS_LOADING';
}

type LogsList = {
	'@context': string;
	'@id': string;
	'hydra:member': [];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

interface Logs {
	isError: boolean,
    isLoading: boolean,
	logs: LogsList,
	errorMessage: string,
	itemStatement: any,
}

export const initialState: Logs = {
	isError: false,
    isLoading: false,
	logs: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	errorMessage: '',
	itemStatement: [],
};

export const logViewer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_LOGS_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
		case actionTypes.SET_LOGS:
			return { 
				...state, 
				logs: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default logViewer;