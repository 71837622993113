export namespace ActionTypes {
    export const FETCH_MAPPING_MULTIACCOUNTS_START = 'FETCH_MAPPING_MULTIACCOUNTS_START';
    export const FETCH_MAPPING_MULTIACCOUNTS_SUCCESS = 'FETCH_MAPPING_MULTIACCOUNTS_SUCCESS';
    export const FETCH_MAPPING_MULTIACCOUNTS_FAILURE = 'FETCH_MAPPING_MULTIACCOUNTS_FAILURE';
    export const SET_MAPPING_MULTIACCOUNTS_SUCCESS = 'SET_MAPPING_MULTIACCOUNTS_SUCCESS';
};


// const ttttest = {
//     "deposit": {
//         "RUB": [
//             {
//                 "psname": "piastrix",
//                 "accounts": [
//                     {
//                         "name": "1",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sbp-rub",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "russian-terminals",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "alfaclick",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "promsvyazbank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sberonline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wechat",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tinkoff",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "2",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sbp-rub",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "russian-terminals",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "alfaclick",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "promsvyazbank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sberonline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wechat",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tinkoff",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "3",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sbp-rub",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "russian-terminals",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "alfaclick",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "promsvyazbank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sberonline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wechat",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tinkoff",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "4",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sbp-rub",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "russian-terminals",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "alfaclick",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "promsvyazbank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "sberonline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wechat",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tinkoff",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ],
//         "INR": [
//             {
//                 "psname": "paykassma",
//                 "accounts": [
//                     {
//                         "name": "1",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "paytm",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "phonepe",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-ib",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-hb",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-naap",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "neteller",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "skrill",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-ap",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-l",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-s",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-in",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-w",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-m",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "2",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "paytm",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "phonepe",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-ib",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-hb",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-naap",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "neteller",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "skrill",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-ap",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-l",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-s",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-in",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-w",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "upi-m",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ],
//         "UAH": [
//             {
//                 "psname": "kvitum",
//                 "accounts": [
//                     {
//                         "name": "12",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "2",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "12213",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "1241212",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             },
//             {
//                 "psname": "changecoins",
//                 "accounts": [
//                     {
//                         "name": "322",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "p24",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "228",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "p24",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ]
//     },
//     "withdraw": {
//         "RUB": [
//             {
//                 "psname": "piastrix",
//                 "accounts": [
//                     {
//                         "name": "1",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wex-code",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether-erc20",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "2",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wex-code",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether-erc20",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "3",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wex-code",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether-erc20",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "4",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-eur",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "card-usd",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "payeer",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "beeline",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "mts",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "megafon",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tele2",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "qiwi",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "yamoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "webmoney",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bank",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "adv-cash",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "wex-code",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "piastrix",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "bitcoin",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "ethereum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "tether-erc20",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ],
//         "INR": [
//             {
//                 "psname": "paykassma",
//                 "accounts": [
//                     {
//                         "name": "1",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "paytm",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "imps",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "neteller",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "skrill",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "imps-ib",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "2",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "paytm",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "imps",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "neteller",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "skrill",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             },
//                             {
//                                 "name": "imps-ib",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ],
//         "UAH": [
//             {
//                 "psname": "kvitum",
//                 "accounts": [
//                     {
//                         "name": "12",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "2",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "12213",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "1241212",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "kvitum",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             },
//             {
//                 "psname": "changecoins",
//                 "accounts": [
//                     {
//                         "name": "322",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     },
//                     {
//                         "name": "228",
//                         "type": "account",
//                         "status": "",
//                         "rules": {
//                             "sum_limit": 0
//                         },
//                         "methods": [
//                             {
//                                 "name": "card",
//                                 "type": "method",
//                                 "status": "",
//                                 "rules": {
//                                     "sum_limit": 0
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ]
//     },
//     "notification": {
//         "telegram": "122"
//     },
//     "reset_time": 3600,

// 	current_cascade: {
// 		"withdraw": {
// 			"RUB": [
// 				{
// 					"name": "piastrix",
// 					"type": "method",
// 					"status": "inactive",
// 					"rules": {
// 						"sum_limit": 1
// 					},
// 					"currency": "RUB",
// 					"psname": "piastrix",
// 					"accname": "1"
// 				},
// 				{
// 					"name": "2",
// 					"type": "account",
// 					"status": "inactive",
// 					"rules": {
// 						"sum_limit": 2
// 					},
// 					"currency": "RUB",
// 					"psname": "piastrix"
// 				}
// 			]
// 		}
// 	}
// }


export const initialState = {
	isError: false,
    isLoading: false,
    errorMessage: '',
    cascadeList: JSON.stringify({
		"deposit": {
			'cur2': [
				{   
					psname: 'ps',
					accounts: [
						{
							name: 'acc',
							type: 'account',
							status: '',
							rules: {
								"sum_limit": 0,
							},
							methods: [
								{
									name: 'meth',
									status: 'active',
									type: 'method',
									rules: {
										"sum_limit": 0,
									},
								},
							]
						},
					]    
				},           
			],
		},
		"withdraw": {
			'cur1': [
				{   
					psname: 'ps',
					accounts: [
						{
							name: 'acc',
							type: 'account',
							status: '',
							rules: {
								"sum_limit": 0,
							},
							methods: [
								{
									name: 'meth',
									status: 'active',
									type: 'method',
									rules: {
										"sum_limit": 0,
									},
								},
							]
						},
					]    
				},           
			],
		},
		"notification": {
			"telegram": 0,
		},
		'reset_time': 3600,
    })

	// cascadeList: JSON.stringify(ttttest),
};

export const mappingMultiaccounts = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_MAPPING_MULTIACCOUNTS_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_MAPPING_MULTIACCOUNTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				isError: false,
                errorMessage: '',
                cascadeList: payload,
                // cascadeList:  JSON.stringify(ttttest)
			};
		case ActionTypes.FETCH_MAPPING_MULTIACCOUNTS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		case ActionTypes.SET_MAPPING_MULTIACCOUNTS_SUCCESS:
			return { 
				...state,
                isError: false,
                errorMessage: ''
			};
		default:
			return state;
	}
};

