import {ActionTypes} from '../reducers/apiInfo';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_API_INFO_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_API_INFO_SUCCESS,
		payload: list
	};
};

export const requestSuccessCurrency = list => {
	return {
		type: ActionTypes.FETCH_API_INFO_CURRENCY_SUCCESS,
		payload: list
	};
};

export const requestSuccessMethods = list => {
	return {
		type: ActionTypes.FETCH_API_INFO_PSMETHOD_SUCCESS,
		payload: list
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_API_INFO_FAILURE,
		payload: error
	}
};

export const apiInfoFetchList = data => dispatch => {
	dispatch(requestStart());
	return API.apiInfo
		.ApiInfoList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const apiInfoFetchCurrencyList = () => dispatch => {
	return API.apiInfo
		.CurrencyList()
		.then(response => {
			dispatch(requestSuccessCurrency(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const apiInfoFetchPMMethodsList = () => dispatch => {
	return API.apiInfo
		.MethodsList()
		.then(response => {
			dispatch(requestSuccessMethods(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};