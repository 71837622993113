import React, {useEffect} from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Loader } from '../../components/loaders/loader';
import { 
    sysInfoAdressesItemRequest,
} from "../../actions/systemInfoAdresses";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import {format} from 'date-fns';
import { AdresseItem } from "../../reducers/systemInfoAddresses";

const PageContainer = styled('div')(({theme}) => (`  
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxView = styled(Box)(({theme}) => (`
    border-bottom: none;  
    .MuiTableContainer-root{
        border-radius: 0;
    }
    tr{
        &:last-child{
            td{
                border-bottom: none;
            }
        }
    }   
`));

const TableCellTitle = styled(TableCell)(({theme}) => (`
    font-weight: bold;
    width: 200px;
`));

const WrappBox = styled(Box)(({theme}) => (`
    margin-bottom: 40px;
    overflow: auto;
    border: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
`));

interface PropTypes {
    isLoading: boolean,
    dataItem: AdresseItem,
    sysInfoAdressesItemRequest: (id: number) => void,
    setView: any, 
    view: {id: number, point: string},
}

export const SystemInfoAdressItem = (props: PropTypes) => {

    const {
        sysInfoAdressesItemRequest,
        dataItem,
        isLoading,
        setView, 
        view,
    } = props

    useEffect(() => {
        sysInfoAdressesItemRequest(view.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = () => {
        setView(prev => ({...prev, point: 'main'}))
    }

    const isValidTime = (str) => {
        try {str.replace(/\+.*/, '')} catch (e) {return false}
        return true;
    }

    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            {dataItem.id}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
            </ShortViewHeader>

            <WrappBox>
                <BoxView sx={{ width: '100%', typography: 'body1' }}>
                    <TableContainer component={Paper} sx={{minWidth: '340px'}}>
                        <Table>   
                            <TableBody>
                                <TableRow>
                                    <TableCellTitle>Id</TableCellTitle>
                                    <TableCell>{dataItem.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>User Id</TableCellTitle>
                                    <TableCell>{dataItem.userId}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Brand Id</TableCellTitle>
                                    <TableCell>
                                        {`${dataItem.brand.id}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Payment System Id</TableCellTitle>
                                    <TableCell>
                                        {`${dataItem.paymentSystem.name}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Address</TableCellTitle>
                                    <TableCell sx={{wordBreak: 'break-all'}}>
                                        {dataItem.address}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Currency</TableCellTitle>
                                    <TableCell>
                                        {dataItem.currency}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Field</TableCellTitle>
                                    <TableCell>
                                        {dataItem.field ? dataItem.field : <span style={{color: '#c55', fontStyle: 'italic'}}>(not set)</span>}  
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Created At</TableCellTitle>
                                    <TableCell>
                                        {
                                            isValidTime(dataItem.createdAt) 
                                                ? format(new Date(`${(dataItem.createdAt).replace(/\+.*/, '')}.000Z`), 'yyyy-MM-dd HH:mm:ss') 
                                                : 'Invalid date'
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BoxView>
            </WrappBox>

            {isLoading && <Loader/>}

        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        isLoading: state.systemInfoAddresses.isLoading,
        dataItem: state.systemInfoAddresses.systemInfoAdressesItem,
    }), {
        sysInfoAdressesItemRequest,
    }),
)(SystemInfoAdressItem)