import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/front_users';
const pathDropdownAssigned = 'api/users/dropdown_list'

const UsersOnTheNode = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';
		return baseApi.get(`${path}${query}`)
	},
	getItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
	assigneItem: (id) => {
		return baseApi.get(`${path}/assign/${id}`)
	},
	createItem: (body) => {
		return baseApi.post(`${path}`, body)
	},
	revokeItem: (id) => {
		return baseApi.delete(`${path}/revoke/${id}`)
	},
	updateItem: (id, data) => {
		return baseApi.patch(`${path}/${id}`, data);
	},
	itemDelete: (id) => {
		return baseApi.delete(`${path}/${id}`)
	},
	dropdownAssigned: () => {
		return baseApi.get(`${pathDropdownAssigned}`);
	}
}

export default UsersOnTheNode;