import React from "react";
import { styled } from "@mui/material/styles";
// import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
// import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import { Loader } from '../../components/loaders/loader';
import Menu from '@mui/material/Menu';
import { TableFilter } from "../../components/table-filter";
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import Structure from '../../components/structure';
// import { DoneSvg } from '../../components/svg/done-svg';
// import { PlusSvg } from '../../components/svg/plus-svg';
import { Status } from '../../components/status';
import { 
    usersOnTheBranchRequest,
    usersDropdownAssignedNodesRequest,
} from "../../actions/usersOnTheBranch";
import {
    usersRolesRequest
} from '../../actions/usersRoles';
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { AccessDeniedAlert } from "../../components/access-denied";
import { Link } from 'react-router-dom';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";


const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UsersPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap'
    }
});

const UsersPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UsersPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width:991px)': {
        width: '100%',
        marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const UsersPageHeaderRight = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
});

// const DepositMainHeaderText = styled('div')(({ theme }) => ({
//     fontSize: '30px',
//     fontWeight: '500',
//     lineHeight: '38px',
//     marginRight: '24px',
//     color: theme.palette.text.primary,

//     '@media(max-width: 768px)' : {
//         fontSize: '20px',
//     }
// }));

// const DepositMainHeader = styled('div')({
//     display: 'flex',
//     marginBottom: '32px',

//     '@media(max-width: 768px)' : {
//         justifyContent: 'space-between',
//     }
// });

// const DepositMainSecondRow = styled('div')({
//     display: 'flex',
//     justifyContent: 'space-between',
//     marginBottom: '32px',

//     '@media(max-width: 1260px)' : {
//         flexWrap: 'wrap'
//     }
// });

// const DepositMainSecondRowRightBlock = styled('div')({
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '100%'
// });

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({
    // display: 'flex',
    // gap: '8px',
});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    color: ${theme.palette.text.primary};
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

// const DepositMainSecondRowLeftButtons = styled('div')({
//     '@media(max-width: 768px)' : {
//         '& button': {
//             boxSizing: 'border-box',
//             fontSize: '12px',
//             height: '30px',
//             padding: '0 12px',
//             marginRight: '11px',
//             '&:last-child':{
//                 marginRight: '0px',
//             }
//         }
//     }
// });

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

// const TableCellContent = styled('div')({
//     display: 'flex',
//     alignItems: 'center',
//     minWidth: '130px'
// });

// const StyledTableCell120 = styled(TableCell)(({ theme }) => (`
//     // min-width: 120px;
// `));

const TableCellContentWith200 = styled('div')(`
    display: flex;
    align-items: center;
    min-width: 225px;
`);

const TableCellContentWith400 = styled('div')(`
    display: flex;
    align-items: center;
    min-width: 425px;
`);

const IconWrapper = styled('div')({
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const StyledTableRow = styled(TableRow)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
`));

const LoginContent = styled(Link)(({theme}) => (`
    text-decoration: underline;
    cursor: pointer;
    color: ${theme.palette.text.primary};
`));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 190px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
        @media(max-width: 540px){
            max-height: calc(100vh - 250px - 56px - 35px);
        }; 
    }
`));

const filterItems = [{title: 'Active', value: 1}, {title: 'Inactive', value: 0}, {title: 'Banned', value: -1}];


export class UserOnBranchPage extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            anchorElRole: null,
            anchorElStatus: null,
            anchorElAssignments: null,
            page: 0,
            rowsPerPage: 20,
            username: '',
            statuses: [],
            roles: [],
            assignmentNodes: [],
            //...
        }
    };
    
    // handleClearFilters = () => {
    //     this.setState({
    //         page: 0,
    //         username: '',
    //         statuses: [],
    //         roles: [],
    //     }, () => this.updateData())
    // }

    handleRoleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElRole: event.currentTarget});
    };
    handleRoleMenuClose = () => {
        this.setState({anchorElRole: null});
    };

    handleChangeFilterRoles = (data) => {
        this.setState({roles: data}, () => {this.updateData()});
        this.handleRoleMenuClose();
    };

    handleAssignmentsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElAssignments: event.currentTarget});
    };
    handleAssignmentsMenuClose = () => {
        this.setState({anchorElAssignments: null});
    };

    handleChangeFilterAssignments = (data) => {
        this.setState({assignmentNodes: data}, () => {this.updateData()});
        this.handleAssignmentsMenuClose();
    }

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget})
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };
    handleChangeFilterStatuses = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
        this.handleStatusMenuClose();
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleClickOpenWindowDelete = () => {
        this.setState({openDeleteWindow: true})
    }
    handleClickCloseWindowDelete = () => {
        this.setState({openDeleteWindow: false})
    }
    handleOpenDeleteDialog = (id) => {
        this.setState({deleteId: id}, () => this.handleClickOpenWindowDelete())
    }

    onChangeFilter = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
    };

    componentDidMount() {
        const {
            routsUser, 
            userRoles,
            isSuperadmin,
            usersRolesRequest,
            usersDropdownAssignedNodesRequest,
        } = this.props;

        if(routsUser.some(rout => rout.child === '/front-user/on-branch') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            usersRolesRequest();
            usersDropdownAssignedNodesRequest();
            this.updateData();
        }
    }

    updateData = () => {
        const {
            page,
            statuses,
            username,
            roles,
            assignmentNodes,
            //...
        } = this.state;

        const {
            usersOnTheBranchRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            'status[]': statuses,
            'username': username,
            'roles[]': roles,
            'assignments[]': assignmentNodes,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        usersOnTheBranchRequest(data);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeNode.id !== this.props.activeNode.id) {
            this.setState({page: 0}, () => {this.updateData()});
        };
    };

    render() {

        const {
            isLoading,
            routsUser, 
            userRoles,
            isSuperadmin,
            users,
            listCount,
            rolesList,
            listAssigndNodes,
        } = this.props

        const {
            page,
            rowsPerPage,
            anchorElRole,
            anchorElStatus,
            anchorElAssignments,
            username,
            roles,
            statuses,
            assignmentNodes,
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child === '/front-user/on-branch') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        let rolesFilter = [];
        try {
            rolesFilter = rolesList.map((item) => {
                return {
                    title: item.description,
                    value: item.name,
                }
            })           
        } catch (error) {}

        let assignedFilter = [];
        try {
            assignedFilter = listAssigndNodes.map((item) => {
                return {
                    title: `[#${item.id}] ${item.name}`,
                    value: item.id,
                }
            })           
        } catch (error) {}

        return (
            <WrapperPage>
                <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole && 
                    <>
                        <UsersPageHeader>
                            <UsersPageHeaderLeft>
                                <UsersPageHeaderText>Users on the branch</UsersPageHeaderText>
                                <Structure/>
                            </UsersPageHeaderLeft>
                            <UsersPageHeaderRight>
                                {/* <Button 
                                    onClick={this.handleClearFilters}
                                    variant="outlined"
                                >
                                    Clear Filters
                                </Button> */}
                            </UsersPageHeaderRight>
                        </UsersPageHeader>

                        <StyledPaperTable>    

                            <StyledTableContainer>
                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >
                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>
                                            
                                                <TableCell>№</TableCell>

                                                <WithSortedSerachTableHeader 
                                                    label='Login' 
                                                    handleSearchSubmit={this.handleSearchSubmit('username')}
                                                    handleCancelSearch={this.handleCancelSearch('username')}
                                                    isActiveFilter={Boolean(username)}
                                                />

                                                <TableCellWithActiveClass
                                                    className={ roles.length > 0 ? 'activeFilterClass' : ''}
                                                >
                                                    <TableCellContentWith200>
                                                        Roles
                                                        <IconWrapper>
                                                            <FilterListSvg size="16px" onClick={this.handleRoleMenuClick}/>
                                                        </IconWrapper>
                                                        <StyledMenu
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorElRole}
                                                            open={Boolean(anchorElRole)}
                                                            onClose={this.handleRoleMenuClose}
                                                            PaperProps={{
                                                                style: {
                                                                    width: '210px',
                                                                },
                                                            }}
                                                        >
                                                            <TableFilter 
                                                                filterItems={rolesFilter}
                                                                initialData={roles}
                                                                onApplyFilter={this.handleChangeFilterRoles} 
                                                                onClearAll={this.handleChangeFilterRoles}
                                                            />
                                                        </StyledMenu>
                                                    </TableCellContentWith200>
                                                </TableCellWithActiveClass>

                                                <TableCellWithActiveClass
                                                    className={ assignmentNodes.length > 0 ? 'activeFilterClass' : ''}
                                                >
                                                    <TableCellContentWith400>
                                                        Assignments
                                                        <IconWrapper>
                                                            <FilterListSvg size="16px" onClick={this.handleAssignmentsMenuClick}/>
                                                        </IconWrapper>
                                                        <StyledMenu
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorElAssignments}
                                                            open={Boolean(anchorElAssignments)}
                                                            onClose={this.handleAssignmentsMenuClose}
                                                        >
                                                            <TableFilter 
                                                                nWidth='400px' 
                                                                initialData={assignmentNodes}
                                                                filterItems={assignedFilter}
                                                                withSearch
                                                                onApplyFilter={this.handleChangeFilterAssignments} 
                                                                onClearAll={this.handleChangeFilterAssignments}
                                                            />
                                                        </StyledMenu>
                                                    </TableCellContentWith400>
                                                </TableCellWithActiveClass>

                                                <TableCellWithActiveClass
                                                        className={ statuses.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContentWith200>
                                                            Status
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleStatusMenuClick}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElStatus}
                                                                open={Boolean(anchorElStatus)}
                                                                onClose={this.handleStatusMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter 
                                                                    filterItems={filterItems}
                                                                    initialData={statuses}
                                                                    onApplyFilter={this.handleChangeFilterStatuses} 
                                                                    onClearAll={this.handleChangeFilterStatuses}
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContentWith200>
                                                    </TableCellWithActiveClass>

                                                <ActionsHeaderCell>
                                                    <StickyBorder>Action</StickyBorder>
                                                </ActionsHeaderCell>

                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {(users || []).map(user => {
                                                return (
                                                    <StyledTableRow key={Math.random()}>
                                                        <TableCell>
                                                            1
                                                        </TableCell>
                                                        <TableCell>
                                                            <LoginContent 
                                                                to={`/user-on-branch/${user['@id'].replace(/[^0-9]/g,"")}`}
                                                            >
                                                                {user.username}
                                                            </LoginContent>
                                                        </TableCell>
                                                        <TableCell>
                                                            {   
                                                                user.roles?.length > 0 &&
                                                                    (user.roles || []).map(item => {
                                                                        return <div key={Math.random()}>{`${item} `}</div>
                                                                    })
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {   
                                                                user.nodes?.length > 0 &&
                                                                    user?.nodes?.map((item) => {
                                                                        return <div key={Math.random()}>{`[#${item['@id'].replace(/[^0-9]/g,"")}] ${item.name}`}</div>
                                                                    })
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                <Status status={user.status}/>
                                                            }
                                                        </TableCell>
                                                        <ActionsCell>
                                                            <StickyBorder>
                                                                <ActionsContent>     
                                                                    <Link
                                                                        to={`/user-on-branch/${user['@id'].replace(/[^0-9]/g,"")}`}
                                                                    >
                                                                        <ActionIcon>
                                                                            <RemoveRedEyeIcon />
                                                                        </ActionIcon>
                                                                    </Link>                                               
                                                                </ActionsContent>
                                                            </StickyBorder>
                                                        </ActionsCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </OverlayScrollbarsComponent>
                            </StyledTableContainer>
                           
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={listCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </StyledPaperTable>
                    </>
                }
                
                { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.usersOnTheBranch.isLoading, 
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
        users: state.usersOnTheBranch.users['hydra:member'],
        listCount: state.usersOnTheBranch.users['hydra:totalItems'],
        activeNode: state.nodeManagement.activeNode,
        rolesList: state.roles.roles['hydra:member'],
        listAssigndNodes: state.usersOnTheBranch.dropdownAssignNodes,
    }), {
        usersDropdownAssignedNodesRequest,
        usersOnTheBranchRequest,
        usersRolesRequest,
    }),
)(UserOnBranchPage)
