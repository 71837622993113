import {ActionTypes} from '../reducers/telegram';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_TELEGRAM_START,
});

export const requestSuccess = item => {
	return {
		type: ActionTypes.FETCH_TELEGRAM_SUCCESS,
		payload: item
	};
};

export const requestDelete = (id = '')=> {
	return {
		type: ActionTypes.FETCH_TELEGRAM_DELETE,
		payload: id,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_TELEGRAM_FAILURE,
		payload: error
	}
};

export const telegramRequest = () => dispatch => {
	dispatch(requestStart());
	return API.telegramWebhook
		.telegramItem()
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const telegramSetWebhook = (data) => dispatch => {
	dispatch(requestStart());
	return API.telegramWebhook
		.telegramSetWebhook(data)
		.then(response => {
			dispatch(telegramRequest())
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const telegramDelete = () => dispatch => {
	dispatch(requestStart());
	return API.telegramWebhook
		.telegramDeleteWebhook()
		.then(response => {
			dispatch(telegramRequest());
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};