import baseApi from './axiosParams';
import queryString from 'query-string';
// const pathMainPage = 'api/rbac/mapping/permissions';
const path = 'api/rbac/permissions';

const UsersPermissions = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';
		return baseApi.get(`${path}${query}`)
	},	
	getItem: (name) => {
		return baseApi.get(`${path}/${name}`);
	},
	getItemTree: (name) => {
		return baseApi.get(`${path}/tree/${name}`);
	},
	createItem: (body) => {
		return baseApi.post(`${path}`, body);
	},
	editItem: (name, body) => {
		return baseApi.patch(`${path}/${name}`, body);
	},	
	deleteItem: (name) => {
		return baseApi.delete(`${path}/${name}`);
	},
}

export default UsersPermissions;