import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Checkbox} from "@mui/material";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
// import {SearchSvg} from "../svg/search-svg";

const FilterContainer = styled('div')({
    // width: '210px',
});

const FilterButtons = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '0px 10px',
    '& > button': {
        height: '32px',
        padding: '0 12px',
    }
});

const ListContainer = styled('div')({
    maxHeight: '200px',
});

const ListItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',

    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
    }
}));

const StyledCheckbox = styled(Checkbox)({
    color: '#979799',
    '&.Mui-checked': {
        color: '#38AA72',
    }
});

const ListItemText = styled('div')({
    marginLeft: '10px'
});

type Props = {
    filterItems: {title: any, value: any}[];
    onApplyFilter?: (state: string | string[]) => void;
    onClearAll?: (state: string[]) => void;
    initialData?: any;
    nWidth?: string;
};

export const ColumnFilter = (props: Props) => {
    const {filterItems, onApplyFilter, initialData = [], nWidth = '210px'} = props;

    const [state, setState] = useState(initialData);

    useEffect(() => {
        setState(initialData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeCheckbox = (item) => (event) => {
        if (event.target.checked) {
            setState([...state, item]);
        } else {
            setState([...state.filter(i => i !== item)]);
        };
    };

    const onApply = () => {
        onApplyFilter(state);
    };

    return (
        <FilterContainer sx={{width: nWidth}}>
            <ListContainer>
                <List sx={{padding: '0px', maxHeight: '200px'}}>
                    <Scrollbars autoHeight>
                        {filterItems.map((item, index) => (
                            <ListItem sx={{padding: '0px'}} key={Math.random()}>
                                <ListItemContainer>
                                    <ListItemText>
                                        {item.title}
                                    </ListItemText>
                                    <StyledCheckbox 
                                        sx={{height: '40px'}} 
                                        onChange={onChangeCheckbox(item.value)} 
                                        checked={state.includes(item.value)}
                                        inputProps={{
                                            // @ts-ignore
                                            'data-testid': `checkboxFilterColumn_${index}`,
                                        }}
                                    />
                                </ListItemContainer>
                            </ListItem>
                        ))}
                    </Scrollbars>
                </List>
            </ListContainer>
            <FilterButtons>
                <Button 
                    onClick={onApply}
                    data-testid="buttonColumnFilterSave"
                >
                    SAVE
                </Button>
            </FilterButtons>
        </FilterContainer>
    );
};
