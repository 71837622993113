import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import { Global, css } from '@emotion/react'

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { TextArea } from '../../components/textarea'


const CreateWalletFormContainer = styled('div')(({ theme }) => ({
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
})

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginRight: '16px',
    marginTop: '8px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

export type ValuesCreate = {
    group: string, 
    name: string, 
    type: string, 
    value: string,
}

type PropsType = {
    onClose: () => void, 
    getChangeValue: (values: ValuesCreate) => void,
}

const optionSettings = [
    {value: 'integer', title: 'Number'},
    {value: 'array', title: 'Array'},
    {value: 'text', title: 'Text'},
    {value: 'bool', title: 'Boolean'},
]

export const CreateSettingForm: React.FC<PropsType> = (props) => {
    const {onClose, getChangeValue} = props;
    const [values, setValue] = useState<ValuesCreate>({group: '', name: '', type: '', value: ''});

    const handleChangeForm = (prop) => (e) => {
        setValue(prev => ({...prev, [prop]: e.target.value}))
    }   
    
    const handleClickCreate = () => {
        getChangeValue(values)
    }

    return (
        <CreateWalletFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>Create setting</Title>
                <Input label="Settings group *" sx={{marginTop: '20px'}} 
                    onChange={handleChangeForm('group')}
                    value={values.group}
                />
                <Input label="Name *" sx={{marginTop: '20px', marginBottom: '16px'}} 
                    value={values.name}
                    onChange={handleChangeForm('name')}
                />
                <StyledFormControl fullWidth size="small">
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={values.type}
                        label="Type"
                        sx={{marginBottom: '16px'}}
                        onChange={handleChangeForm('type')}
                    >
                        {optionSettings.map(itm => <StyledMenuItem key={Math.random()} value={itm.value}>{itm.title}</StyledMenuItem>)}
                    </Select>
                </StyledFormControl>
                <TextArea 
                    label="Setting Value" 
                    value={values.value} 
                    onChange={handleChangeForm('value')}
                />
                <Global
                    styles={css`
                        & .MuiPickersDay-root.Mui-selected {
                            background-color: #38AA72 !important;
                        },
                    `}
                />
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleClickCreate}
                >Create</Button>
            </ButtonsContainer>

        </CreateWalletFormContainer>
    )
}