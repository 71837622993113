export namespace ActionTypes {
    export const FETCH_SYS_INFO_ADDRESSES_SUCCESS = 'FETCH_SYS_INFO_ADDRESSES_SUCCESS';
    export const FETCH_SYS_INFO_ADDRESSES_LOADING = 'FETCH_SYS_INFO_ADDRESSES_LOADING';
	export const FETCH_SYS_INFO_ADDRESSES_FAILURE = 'FETCH_SYS_INFO_ADDRESSES_FAILURE';
	export const FETCH_SYS_INFO_ADDRESSES_ITEM = 'FETCH_SYS_INFO_ADDRESSES_ITEM';
}

export type AdresseItem = {
    "@context": string,
    "@id": string,
    "@type": string,
    id: number,
    userId: string,
    brand: {
        "@id": string,
        "@type": string,
        "id": number,
    },
    paymentSystem: {
        "@id": string,
        "@type": string,
        name: string,
    },
    address: string,
    currency: string,
    createdAt: string,
    field: string,
    callbackUrl: string
}

export type AdressesListItem = {
    "@id": string,
    "@type": string,
    id: number,
    userId: string,
    brand: {
        "@id": string,
        "@type": string,
        id: number,
    },
    paymentSystem: {
        "@id": string,
        "@type": string,
        name: string,
    },
    address: string,
    currency: string,
    createdAt: string,
    field: string,
    callbackUrl: string,
}

type AdressesList = {
	'@context': string;
	'@id': string;
	'hydra:member': Array<AdressesListItem>;
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

type stateUserAdresses = {
	systemInfoAdresses: AdressesList;
	isLoading: boolean;
	systemInfoAdressesItem: AdresseItem;
	errorMessage: string;
	isError: string;
}

const initialState: stateUserAdresses = {
	systemInfoAdresses: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	isLoading: false,
	systemInfoAdressesItem: {
		"@context": '',
		"@id": '',
		"@type": '',
		id: 1,
		userId: '',
		brand: {
			"@id": '',
			"@type": '',
			"id": 1,
		},
		paymentSystem: {
			"@id": '',
			"@type": '',
			name: '',
		},
		address: '',
		currency: '',
		createdAt: '',
		field: '',
		callbackUrl: ''
	},
	errorMessage: '',
	isError: '',
};

export const systemInfoAddresses = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_SYS_INFO_ADDRESSES_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
		case ActionTypes.FETCH_SYS_INFO_ADDRESSES_SUCCESS:
			return { 
				...state, 
				systemInfoAdresses: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_SYS_INFO_ADDRESSES_ITEM:
			return { 
				...state, 
				systemInfoAdressesItem: {
					...payload,
				},
				isLoading: false,
			};	
		case ActionTypes.FETCH_SYS_INFO_ADDRESSES_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};		
		default:
			return state;
	}
};

export default systemInfoAddresses;