import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Button} from '../../components/button';
// import {SwitchComponent} from '../../components/switch';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {Input} from '../../components/input';
import {SwitchComponent} from '../../components/switch';


const CreatePaymentFormContainer = styled('div')(({ theme }) => (`
    height: 100%;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    background: ${theme.palette.background.paper};
    position: relative;
    padding: 32px 32px 0px 32px;
    align-content: space-between;
    display: flex;
    flex-wrap: wrap;
`));

const CloseSvgWrapper = styled('div')(`
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
`);

const ButtonsContainer = styled('div')(`
    padding: 30px 0 32px 0;
`);

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const AccountTitle = styled('h4')(({ theme }) => (`
    color: ${theme.palette.text.primary};
    padding-bottom: 0px;
`));

type Props = {
    onClose: () => void;
    onCreate: (data: any) => void;
}


const CreateNewAccountForPaymentSystem = (props: Props) => {
    const {onClose, onCreate} = props;

    const [values, setValues] = useState({name: '', status: true})

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        let data = {...values}
        data.name = values.name.replace(/\s/g, '');
        onCreate(data);
        onClose();
    };

    const handChangeName = (e) => {
        setValues(prev => {
            return {
                ...prev,
                name: e.target.value,
            }
        });
    }

    const handChangeStatus = () => {
        setValues(prev => {
            return {
                ...prev,
                status: !prev.status,
            }
        });
    }

    return (
        <CreatePaymentFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={handleClose}/>
            </CloseSvgWrapper>

            <AccountTitle>Add new account ?</AccountTitle>

            <WrapFields>
                <Input 
                    sx={{marginTop: '20px'}}
                    label={`Name Account`}
                    value={values.name}
                    disabled={false}
                    onChange={(e) => handChangeName(e)}
                />

                <SwitchComponent 
                    checked={values.status}
                    onChange={() => handChangeStatus()}
                    sx={{marginTop: '20px'}}
                    label={`Active`}
                    disabled={false}
                />
            </WrapFields>

            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    disabled={false}
                    onClick={handleSubmit}
                >
                    Create
                </Button>
                <Button 
                    variant="outlined"
                    onClick={handleClose}
                >
                    CANCEL
                </Button>
            </ButtonsContainer>

        </CreatePaymentFormContainer>
    )
}

export default compose(
    connect((state: RootState) => ({
        //....
    }), {
        //....
    })
)(CreateNewAccountForPaymentSystem)

