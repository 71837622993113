export namespace ActionTypes {
    export const FETCH_API_INFO_START = 'FETCH_API_INFO_START';
    export const FETCH_API_INFO_SUCCESS = 'FETCH_API_INFO_SUCCESS';
    export const FETCH_API_INFO_FAILURE = 'FETCH_API_INFO_FAILURE';
    export const FETCH_API_INFO_CURRENCY_SUCCESS = 'FETCH_API_INFO_CURRENCY_SUCCESS';
    export const FETCH_API_INFO_PSMETHOD_SUCCESS = 'FETCH_API_INFO_PSMETHOD_SUCCESS';
};

export type APIInfoItem = any;

type APIInfoList = Array<APIInfoItem>;

interface APIInfo {
	isLoading: boolean,
	apiInfoList: APIInfoList,
	listCurrency: Array<any>,
	litPSMethod: Array<any>,
}

export const initialState: APIInfo = {
	isLoading: false,
	apiInfoList: [],
	listCurrency: [],
	litPSMethod: [],
}

export const apiInfo = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_API_INFO_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_API_INFO_SUCCESS:
			return {
				...state,
				apiInfoList: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_API_INFO_CURRENCY_SUCCESS:
			return {
				...state,
				listCurrency: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_API_INFO_PSMETHOD_SUCCESS:
			return {
				...state,
				litPSMethod: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_API_INFO_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
