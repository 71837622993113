import React, {useEffect, useState} from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../../components/svg/arrow-back-svg";
// import Structure from '../../../components/structure';
import {Button} from '../../../components/button';
import { EditSvg } from '../../../components/svg/edit-svg';
import {MoreSvg} from "../../../components/svg/more-svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import { EditUserForm } from './edit-user-form';
import Box from '@mui/material/Box';
import { NotifyModal } from '../../../components/notify-modal';
// import {Input} from '../../../components/input';
import {useParams, useNavigate} from 'react-router-dom';
import {
    usersOnTheNodeItemRequest,
    usersOnTheNodeItemUpdate,
    usersOnTheNodeItemDelete,
    userRequestItemThree,
} from "../../../actions/usersOnTheNode";
import {
    changePasswordRequest,
    usersAssigmentRoles,
} from "../../../actions/usersAll";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../../store/configure-store';
import { Loader } from '../../../components/loaders/loader';
import {ChangePasswordForm} from './user-change-password';
import Snackbar from '@mui/material/Snackbar';
import { InfoPopup } from "../../../components/info-popup";


const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px'
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UserDetailsHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px'

});

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root': {
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
        }
    }
}));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

// type Props = {
//     setView: (view: string) => void;
// };

export const UserPageOnSelectedNodeDetails = (props) => {
    const {
        user,
        isLoading,
        usersOnTheNodeItemRequest,
        usersOnTheNodeItemUpdate,
        usersOnTheNodeItemDelete,
        changePasswordRequest,
        isError,
        errorMessage,
        userRequestItemThree,
        userRolesThree,
    } = props;

    const {id} = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        usersOnTheNodeItemRequest(id);
        userRequestItemThree(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goBack = () => navigate(-1);

    const handleSaveEdit = (data) => {
        usersOnTheNodeItemUpdate(id, data).then(() => handleOpenSnackbarUpdate());
        handleCloseEdit();
    }

    const handleDeleteUser = () => {
        usersOnTheNodeItemDelete(id);
        goBack();
    }

    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const [changePasswordModal, setCheckPasswordModal] = useState(false);
    const handleChangePasswordModalOpen = () => setCheckPasswordModal(true);
    const handleCheckPasswordModalClose = () => {
        setCheckPasswordModal(false);
        handleMenuClose();
    };
    const handleSavePassword = (password) => {
        changePasswordRequest(user.id, JSON.stringify({'password': password})).then(() => handleOpenSnackbar());
        handleCheckPasswordModalClose();
    }

    const [deleteModal, setDeleteModal] = useState(false);
    const handleDeleteModalOpen = () => setDeleteModal(true);
    const handleDeleteModalClose = () => {
        setDeleteModal(false);
        handleMenuClose();
    };

    const [openSnackbarChangePassword, setOpenSnackbarChangePassword] = React.useState(false);
    const handleOpenSnackbar = () => setOpenSnackbarChangePassword(true);
    const handleCloseSnackbar = () => setOpenSnackbarChangePassword(false);

    const [openSnackbarUpdate, setOpenSnackbarUpdate] = React.useState(false);
    const handleOpenSnackbarUpdate = () => setOpenSnackbarUpdate(true);
    const handleCloseSnackbarUpdate = () => setOpenSnackbarUpdate(false);


    return (
        <PageContainer>
            <UserDetailsHeader>
                <UserDetailsHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer>
                            <ArrowBackSvg size="22px" onClick={goBack}/>
                        </ArrowContainer>
                        <UserDetailsHeaderText>
                            User {user.username}
                        </UserDetailsHeaderText>
                    </HeaderLeftContainer>
                    {/* <Structure/> */}
                </UserDetailsHeaderLeft>
                <UserDetailsHeaderRight>
                    <Button startIcon={ <EditSvg size="22px"/>} onClick={handleOpenEdit}>
                        EDIT
                    </Button>
                    <Button variant="outlined" onClick={handleMenuClick}>
                        <MoreSvg size="22px" fill="#38AA72"/>
                    </Button>
                </UserDetailsHeaderRight>
            </UserDetailsHeader>
            <StyledPaperTable>
                <TableContainer component={'div'}>
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                <TableCell>Info</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Login</TableCell>
                                <TableCell>{user.username}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Note</TableCell>
                                <TableCell>{user.roleNote}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Roles</TableCell>
                                <TableCell>
                                    {
                                        user.role?.length > 0 &&
                                            user.role?.map(item => {
                                                return <div key={Math.random()}>{`${item.name}  `}</div>
                                            })
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Assignments</TableCell>
                                <TableCell>
                                    {   
                                        user.nodes?.length > 0 &&
                                            user?.nodes?.map((item) => {
                                                return <div key={Math.random()}>{`[#${item['@id'].replace(/[^0-9]/g,"")}] ${item.name}`}</div>
                                            })
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Created</TableCell>
                                <TableCell>{user.createdAt}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledPaperTable>

            <Menu
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        width: '200px',
                    },
                }}
            >
                <StyledMenuItem onClick={handleChangePasswordModalOpen}>
                    Change password
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDeleteModalOpen}>
                    Delete
                </StyledMenuItem>
            </Menu>

            <Modal
                open={openEdit}
                onClose={handleCloseEdit}
            >
                <StyledBoxPopup>
                    <EditUserForm 
                        onClose={handleCloseEdit}
                        initialState={user}
                        onSave={handleSaveEdit}
                        roles={userRolesThree.roles}
                    />
                </StyledBoxPopup>
            </Modal>

            {/* No realize */}
            <ChangePasswordForm 
                userId={user.id}
                isOpen={changePasswordModal}
                handleClose={handleCheckPasswordModalClose}
                handleSavePassword={handleSavePassword}
            />

            <Modal 
                open={deleteModal}
                onClose={handleDeleteModalClose}
            >
                <>
                    <NotifyModal
                        title={`Delete user ${user.username} ?`}
                        confirmButtonText='YES, DELETE'
                        cancelButtonText='CANCEL'
                        onClose={handleDeleteModalClose}
                        onConfirm={handleDeleteUser}
                        onCancel={handleDeleteModalClose}
                        width="420px"
                        isDeleteButton
                    >
                        Are you sure you want to delete this user?
                    </NotifyModal>   
                </>          
            </Modal>

            <Snackbar 
                open={openSnackbarChangePassword} 
                autoHideDuration={3000} 
                onClose={handleCloseSnackbar} 
                anchorOrigin={{
                    vertical: "bottom", 
                    horizontal: "right",
                }}
            >
                {isError 
                    ? <InfoPopup
                        dataTestid='snakebarChangePassword'
                        severity="error"
                        headerText="Change password error"
                        infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                    />
                    : <InfoPopup
                        dataTestid='snakebarChangePassword'
                        severity="success"
                        headerText={`Password updated`}
                    />
                }
            </Snackbar>

            <Snackbar 
                open={openSnackbarUpdate} 
                autoHideDuration={3000} 
                onClose={handleCloseSnackbarUpdate} 
                anchorOrigin={{
                    vertical: "bottom", 
                    horizontal: "right",
                }}
            >
                {isError 
                    ? <InfoPopup
                        dataTestid='snakebarUpdate'
                        severity="error"
                        headerText="Update user data error"
                        infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                    />
                    : <InfoPopup
                        dataTestid='snakebarUpdate'
                        severity="success"
                        headerText={`User data updated`}
                    />
                }
            </Snackbar>

            { isLoading && <Loader/> }

        </PageContainer>
    )
}

// export default UserPageOnSelectedNodeDetails;
export default compose(
    connect((state: RootState) => ({
        isLoading: state.usersOnTheNode.isLoading,
        user: state.usersOnTheNode.itemUser,
        isError: state.usersOnTheNode.isError,
        errorMessage: state.usersOnTheNode.errorMessage,
        userRolesThree: state.usersOnTheNode.userItemsAssigment,
    }), {
        usersOnTheNodeItemRequest,
        usersOnTheNodeItemUpdate,
        usersOnTheNodeItemDelete,
        changePasswordRequest,
        userRequestItemThree,
        usersAssigmentRoles,
    }),
)(UserPageOnSelectedNodeDetails)