import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../../components/svg/close-svg';
import {Button} from '../../../components/button';
import {connect} from 'react-redux';
import {compose} from "redux";
import { InfoOutlineSvg } from "../../../components/svg/info-outline-svg";
// import {Input} from '../../../components/input';
// import {EditMappingFormItem} from './edit-mapping-form-rules-item';
// import {getTotalSeconds, convertSecondsToTimeString} from '../../../utils';

import {Input} from '../../../components/input';


const EditMappingFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const MethodsRuleBlock = styled('div')({
    paddingBottom: '16px',
    height: 'calc(100vh - 220px)',
    overflowY: 'auto',
});

const MethodsInfo = styled('div')({
    margin: '0px 0px 16px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
});

const MethodsInfoText = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
}));

const WrapEditMappingFormItem = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
}));

const TitleItem = styled('div')(({ theme }) => ({
    marginTop: '20px',
    paddingBottom: '16px',
    display: 'flex',
    alignItems: 'center',
}));

const MappingPageOrderItemInfoCircle = styled('div')(({ theme }) => ({
    width: '4px',
    height: '4px',
    borderRadius: '2px',
    background: theme.palette.text.primary,
    marginRight: '8px',
    marginLeft: '8px',
}));


const numberRegExp = /^[0-9]*$/;

const EditMappingForm = ({
    onClose, 
    cascadeListState, 
    setCascadeListState, 
    way, 
    currency, 
    setIsEdited, 

    itemEl,
    setEditListState,

    onSaveAll,
    methods,
}: any) => {

    const [methodsState, setMethods] = useState(methods);

    const handleChangeMethodItem = (itemIndex, event) => {
        const valItem = event.target.value;
        const isValidFormat = numberRegExp.test(valItem.replace(/\s/g, ''));
        if (isValidFormat) {
            const validValue = Number(String(valItem).replace(/\s/g, ''));
            setMethods(prev => {
                const changedState = [...prev]; 
                changedState.map((item, index) => {
                    if(itemIndex === index){
                        item.rules.sum_limit = validValue;
                        return item
                    }
                    return item
                })
                return changedState
            })
        }
    }

    const handleConfirm = () => {
        onSaveAll(methodsState)
        onClose();
    };

    return (
        <EditMappingFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>Change rules</Title>
                <MethodsRuleBlock>
                    <MethodsInfo>
                        <InfoOutlineSvg size="16px"/>
                        <MethodsInfoText>
                            Set the change Rule for accounts
                        </MethodsInfoText>
                    </MethodsInfo>

                    {(methodsState || []).map((item, index) => {
                        return  <WrapEditMappingFormItem key={index}>
                            <TitleItem>
                                {item?.type === 'account' 
                                    ? <>
                                        <div>{item?.psname}</div> 
                                        <MappingPageOrderItemInfoCircle/>
                                        <div>{item?.name}</div>
                                    </>
                                    : <>
                                        <div>{item?.psname}</div> 
                                        <MappingPageOrderItemInfoCircle/>
                                        <div>{item?.accname}</div>
                                        <MappingPageOrderItemInfoCircle/>
                                        <div>{item?.name}</div>
                                    </>
                                }
                            </TitleItem>

                            <Input
                                label="Sum"
                                value={Number(String(item.rules.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' ')}
                                onChange={(e) => handleChangeMethodItem(index, e)}
                                sx={{marginTop: '0px'}}
                            />
                        </WrapEditMappingFormItem>

                    })}
                
                </MethodsRuleBlock>
            </WrapFields>
            <ButtonsContainer>
                {/* <Button 
                    sx={{marginRight: '12px'}} 
                    variant="outlined" 
                    onClick={handleReset}
                >
                    RESET DEFAULTS
                </Button> */}
                <Button 
                    onClick={handleConfirm} 
                    // disabled={isValid()}
                >
                    CONFIRM
                </Button>
            </ButtonsContainer>

        </EditMappingFormContainer>
    )
};

export default compose(
    connect(null, {})
)(EditMappingForm);
