import React from 'react';
import styled from "@emotion/styled";
import { StatusErrorSvg } from '../svg/status-error-svg';
import { StatusOkSvg } from '../svg/status-ok-svg';

const BannedContainer = styled('span')({
    border: '1px solid #FFA39E',
    color: '#F5222D',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '0px 7px',
    borderRadius: '8px',
    height: '22px',
    width: 'fit-content'
});

const ActiveContainer = styled('span')({
    border: '1px solid #B7EB8F',
    color: '#52C41A',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    gap: '8px',
    padding: '0px 7px',
    borderRadius: '8px',
    height: '22px'
});

const InactiveContainer = styled('span')({
    border: '1px solid #FFD591',
    color: '#FA8C16',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    gap: '8px',
    padding: '0px 7px',
    borderRadius: '8px',
    height: '22px',
    justifyContent: 'space-between'
})



type Props = {
    status: string | number;
}

export const Status = ({status}: Props) => {
    let StatusContainer,
        statusTitle;

    if (status === -1) {
        StatusContainer = BannedContainer;
        statusTitle = 'banned';
    } else if (status === 1) {
        StatusContainer = ActiveContainer;
        statusTitle = 'active';
    } else if (status === 0) {
        StatusContainer = InactiveContainer;
        statusTitle = 'inactive';
    }

    return (
        <StatusContainer>
            {status === 1 ? <StatusOkSvg size="12px"/> : <StatusErrorSvg size="12px" fill={status === -1 ? '#F5222D' : '#FA8C16'}/>}
            {statusTitle}
        </StatusContainer>
    )
}