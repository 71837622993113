import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const CachedSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 22 22"
            onClick={onClick}
        >
            <path d="M17.4165 7.33341L13.7498 11.0001H16.4998C16.4998 14.0342 14.034 16.5001 10.9998 16.5001C10.074 16.5001 9.194 16.2709 8.43317 15.8584L7.09484 17.1967C8.22234 17.9117 9.56067 18.3334 10.9998 18.3334C15.0515 18.3334 18.3332 15.0517 18.3332 11.0001H21.0832L17.4165 7.33341ZM5.49984 11.0001C5.49984 7.96591 7.96567 5.50008 10.9998 5.50008C11.9257 5.50008 12.8057 5.72925 13.5665 6.14175L14.9048 4.80341C13.7773 4.08841 12.439 3.66675 10.9998 3.66675C6.94817 3.66675 3.6665 6.94841 3.6665 11.0001H0.916504L4.58317 14.6667L8.24984 11.0001H5.49984Z" fill={fill}/>
        </WrapperTag>
    );
};

CachedSvg.defaultProps = {
    fill: '#C0C0C0',
};
