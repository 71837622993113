import baseApi from './axiosParams';
const path = 'api/external/migrations/';

const Migration = {
	migratonList: (migration) => {
		return baseApi.get(`${path}${migration}`)
	},
    createMigration: (name) => {
		return baseApi.get(`${path}create/${name}`)
    }
}

export default Migration;