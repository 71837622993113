import {ActionTypes} from '../reducers/currencyList';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_CURRENCY_START
});

export const requestSuccess = data => {
	return {
		type: ActionTypes.FETCH_CURRENCY_SUCCESS,
		payload: data
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_CURRENCY_FAILURE,
		payload: error
	}
};

export const currencyListRequest = () => dispatch => {
	dispatch(requestStart());
    
	return API.currencyList
		.dropdownList()
		.then(res => {
			dispatch(requestSuccess(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};