import React, { useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import { EditSvg } from '../../components/svg/edit-svg';
import Paper from '@mui/material/Paper';
import { EditUserForm } from './edit-permissions-form';
import Box from '@mui/material/Box';
import { NotifyDialog } from '../../components/notify-dialog';
import Dialog from '@mui/material/Dialog';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    usersItemPermissionsRequest,
    usersItemPermissionsTreeRequest,
    userPermissionsEdit,
    usersItemPermissionsDelete,
} from '../../actions/usersPermissiions';
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { Loader } from '../../components/loaders/loader';

import {RoutesList} from './tab-lists/routs-list';
import {FieldsList} from './tab-lists/fields-lists';
import {PermissionChildList} from './tab-lists/permission-childs-lists';
// import {PermissionGroupListItem} from '../../reducers/permissionGroups';
// import {StateIndex} from './index';
// import {PermissionItem, PermissionThree} from '../../reducers/permission';
// import {DataPermission} from './edit-permissions-form';

import {useParams, useNavigate} from 'react-router-dom';


const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px'
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UserDetailsHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px'

});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledDialogEdit = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        margin: 15px;
        margin-top:0;
        margin-bottom:0;
        max-height: 100%;
        border-radius: 0;
    }
`));

const StyledDialogChangePassword = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
    }
`));

const StyledPaperTabs = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    box-sizing: border-box;
    border-bottom: none;
    margin-top: 30px;
    box-shadow: none;
    border: none;
    @media screen and (max-width: 768px){
        margin-top: 10px;
    }
`));

const StyledTabPanel = styled(TabPanel)(`
    padding: 0px 0px 30px 30px;
`);

const StyledTab = styled(Tab)(({ theme }) => (`
    &.MuiTab-root{
        color: #979799;
    }
    &.Mui-selected{
        color: ${theme.palette.text.primary};
    }
`));

const StyledTabs = styled(Tabs)(({ theme }) => (`
    & .MuiTabs-indicator{
        background-color: ${theme.palette.mode === 'dark' ? '#38AA72' : '#272F3D'} ;
    },
`));

const WrapTabContentColumns = styled('div')(({ theme }) => (`
    &{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }
`));

const WrapTabContentItem = styled('div')(({ theme }) => (`
    &{
        width: 100%;
        padding-right: 30px;
        box-sizing: border-box;
        margin-left: -30px;
        @media screen and (max-width: 991px){
            width: 100%;
        }
    }
`));

export type DataPermissionChild = {
    child?: Array<string>; 
}

// type PropsType = {
//     itemPermission: PermissionItem,
//     itemPermissionTree: PermissionThree,
//     isLoading: boolean,
//     dropdownGroup: Array<PermissionGroupListItem>,
//     usersItemPermissionsRequest: (key: string) => void,
//     userPermissionsEdit: (key: string, data: DataPermission | DataPermissionChild) => void,
//     usersItemPermissionsDelete: (key: string) => void,
//     usersItemPermissionsTreeRequest: (key: string) => void,
//     view: StateIndex,
//     setView: (state: any) => void,
// }

export const PermissionDetailsPage: React.FC<any> = (props) => {
    const {
        // view,
        // setView,
        isLoading,
        itemPermission,
        itemPermissionTree,
        usersItemPermissionsRequest,
        dropdownGroup,
        userPermissionsEdit,
        usersItemPermissionsDelete,
        usersItemPermissionsTreeRequest,
    } = props;

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        usersItemPermissionsRequest(id);
        usersItemPermissionsTreeRequest(id);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        usersItemPermissionsRequest(id);
        usersItemPermissionsTreeRequest(id);
        // eslint-disable-next-line
    }, [id])

    const [tabIndex, setTabIndex] = React.useState<string>('1');
    const handleChangeTab = (event: React.SyntheticEvent, index: string) => {
        setTabIndex(index);
    };

    const goBack = () =>  navigate(-1);

    const [open, setOpen] = React.useState<boolean>(false);
    const handlEditeOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    }
    const handleDeleteModalClose = () => setDeleteModalOpen(false);

    const handleEditSave = (data) => {
        userPermissionsEdit(itemPermission.name, data)
    }

    const handleDelete = () => {
        usersItemPermissionsDelete(itemPermission.name)
        goBack()
    }


    const createDatePermission = (data: any) => {
        const dateSend = {child: []}
        
        const searchDateTrue = (arr) => {
            arr.forEach((item, index, arrInit) => {
                item.checked && dateSend.child.push(item['@id']) 
                if(item.child && item.child.length){
                    searchDateTrue(item.child)
                }
            })
        }

        searchDateTrue(itemPermissionTree.childPermissions);
        searchDateTrue(itemPermissionTree.fields);
        searchDateTrue(itemPermissionTree.routes);

        return dateSend
    }

    const handleSaveListsPermissions = (data) => {
        userPermissionsEdit(itemPermission.name, createDatePermission(data))
    }

    return (
        <PageContainer>
            <UserDetailsHeader>
                <UserDetailsHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer>
                            <ArrowBackSvg size="22px" onClick={goBack}/>
                        </ArrowContainer>
                        <UserDetailsHeaderText>
                            Settings for permission: {itemPermission?.description}
                        </UserDetailsHeaderText>
                    </HeaderLeftContainer>
                </UserDetailsHeaderLeft>
                <UserDetailsHeaderRight>
                    <Button startIcon={ <EditSvg size="22px"/>} onClick={handlEditeOpen}>
                        EDIT
                    </Button>
                    <Button isDeleteButton={true} onClick={handleDeleteModalOpen}>
                        <DeleteIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        Delete
                    </Button>
                </UserDetailsHeaderRight>
            </UserDetailsHeader>

            <StyledPaperTabs>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <StyledTabs value={tabIndex} onChange={handleChangeTab} aria-label="lab API tabs example">
                                <StyledTab label="Child permissions" value="1" />
                                <StyledTab label="Fields" value="2" />
                                <StyledTab label="Routs" value="3" />
                            </StyledTabs>
                        </Box>

                        <StyledTabPanel value="1">
                            <WrapTabContentColumns>
                                <WrapTabContentItem>
                                    <PermissionChildList 
                                        childPermissions={itemPermissionTree.childPermissions} 
                                        onSavePermission={handleSaveListsPermissions}
                                    />
                                </WrapTabContentItem>
                            </WrapTabContentColumns>
                        </StyledTabPanel>
                        

                        <StyledTabPanel value="2">  
                            <WrapTabContentColumns>
                                <WrapTabContentItem>
                                    <FieldsList 
                                        childFieldss={itemPermissionTree.fields} 
                                        onSaveFields={handleSaveListsPermissions}
                                    />
                                </WrapTabContentItem>
                            </WrapTabContentColumns>
                        </StyledTabPanel>

                        <StyledTabPanel value="3"> 
                            <WrapTabContentColumns>
                                <WrapTabContentItem>
                                    <RoutesList 
                                        childRoutes={itemPermissionTree.routes}
                                        onSaveRoutes={handleSaveListsPermissions}
                                    />
                                </WrapTabContentItem>
                            </WrapTabContentColumns>
                        </StyledTabPanel>

                    </TabContext>
                </Box>
            </StyledPaperTabs>

            <StyledDialogEdit
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledBoxPopup>
                    <EditUserForm 
                        onClose={handleClose}
                        initValue={itemPermission}
                        editPermission={handleEditSave}
                        dropdownGroup={dropdownGroup}
                    />
                </StyledBoxPopup>
            </StyledDialogEdit>

            <StyledDialogChangePassword
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NotifyDialog
                    title={`Delete pemission ${itemPermission.description}`}
                    confirmButtonText='YES, DELETE'
                    cancelButtonText='CANCEL'
                    onClose={handleDeleteModalClose}
                    width="400px"
                    isDeleteButton
                    onCancel={handleDeleteModalClose}
                    onConfirm={handleDelete}
                >
                    Are you sure you want to delete permission {itemPermission.description}?
                </NotifyDialog>  
            </StyledDialogChangePassword>

            {isLoading && <Loader/>}

        </PageContainer>
    )
}


export default compose(
    connect((state: RootState) => ({
        itemPermission: state.permissions.permissionItem,
        itemPermissionTree: state.permissions.permissionTree,
        isLoading: state.permissions.isLoading,
        dropdownGroup: state.permissionGroups.permissionGroups['hydra:member'],
    }), {
        usersItemPermissionsRequest,
        userPermissionsEdit,
        usersItemPermissionsDelete,
        usersItemPermissionsTreeRequest,
    }),
)(PermissionDetailsPage)