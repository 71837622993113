import baseApi from './axiosParams';
import baseApiDownload from './axiosParamsDownload';

import queryString from 'query-string';
const path = 'api/reports';

const Report = {
	reportList: (params) => {
		const query = Object.keys(params).length
		? `?${queryString.stringify(params)}`
		: '';

		return baseApi.get(`${path}${query}`)
	},
	reportDownloadList: (params) => {
		const query = Object.keys(params).length
		? `?${queryString.stringify(params)}`
		: '';
		
		return baseApiDownload.get(`${path}/download${query}`)
	}
}

export default Report;