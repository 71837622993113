import {actionTypes} from '../reducers/ipAddress';
import API from '../services/api';

const ipStartRequest = () => ({
    type: actionTypes.FETCH_IP_START,
});

const ipSetData = (data) => ({
    type: actionTypes.FETCH_IP_SUCCESS,
    payload: data,
})

export const dasboardIP = () => dipatch => {
    dipatch(ipStartRequest())
    return API.ipAddress.getIp()
        .then(response => {
            dipatch(ipSetData(response.data))
        })
        .catch(error => console.log(error));
};