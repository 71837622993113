import {ActionTypes} from '../reducers/transactions';
import API from '../services/api';
import {format} from 'date-fns';

export const getTransactionsRequestStart = () => ({
	type: ActionTypes.FETCH_TRANSACTIONS_START,
});

export const getTransactionsRequestSuccess = data => {
	return {
		type: ActionTypes.FETCH_TRANSACTIONS_SUCCESS,
		payload: data
	};
};

export const getTransactionsRequestFailure = error => {
	return {
		type: ActionTypes.FETCH_TRANSACTIONS_FAILURE,
		payload: error
	}
};

export const getShortViewRequestStart = () => ({
	type: ActionTypes.FETCH_SHORT_VIEW_START,
});

export const getShortViewRequestSuccess = data => {
	return {
		type: ActionTypes.FETCH_SHORT_VIEW_SUCCESS,
		payload: data
	};
};

export const getShortViewRequestFailure = error => {
	return {
		type: ActionTypes.FETCH_SHORT_VIEW_FAILURE,
		payload: error
	}
};

export const getViewRequestStart = () => ({
	type: ActionTypes.FETCH_VIEW_START,
});

export const getViewRequestSuccess = data => {
	return {
		type: ActionTypes.FETCH_VIEW_SUCCESS,
		payload: data
	};
};

export const getViewRequestFailure = error => {
	return {
		type: ActionTypes.FETCH_VIEW_FAILURE,
		payload: error
	}
};

export const updateStatusesSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_STATUSES_SUCCESS,
        payload: data
    }
};

export const updateStatusesFailure = (error) => {
    return {
        type: ActionTypes.UPDATE_STATUSES_FAILURE,
        payload: error
    }
};

export const setNotifiesSuccess = (data) => {
    return {
        type: ActionTypes.SET_NOTIFIES_SUCCESS,
        payload: data
    }
};

export const setNotifiesFailure = (error) => {
    return {
        type: ActionTypes.SET_NOTIFIES_FAILURE,
        payload: error
    }
};

export const getTransactionRequestsSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_TRANSACTION_REQUESTS_SUCCESS,
        payload: data
    }
};

export const getTransactionRequestsFailure = (error) => {
    return {
        type: ActionTypes.FETCH_TRANSACTION_REQUESTS_FAILURE,
        payload: error
    }
};

export const getServerTime = data => {
    return {
        type: ActionTypes.GET_SERVER_TIME,
        payload: data
    }
};

export const getStatusesList = data => {
    return {
        type: ActionTypes.GET_STATUSES_LIST,
        payload: data
    }
};

export const getNotifyStatusesList = data => {
    return {
        type: ActionTypes.GET_NOTIFY_STATUSES_LIST,
        payload: data
    }
};

export const getPaymentMethodsList = data => {
    return {
        type: ActionTypes.GET_PAYMENT_METHODS_LIST,
        payload: data
    }
};

export const getTransactionsAmount = data => {
    return {
        type: ActionTypes.GET_TRANSACTIONS_AMOUNT,
        payload: data
    }
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_TRANSACTIONS_FAILURE,
		payload: error
	}
};

export const getTransactionsAmountRequest = () => dispatch => {
	dispatch(getTransactionsRequestStart());
	return API.transactions
		.getTransactionsAmount()
		.then(response => {
			dispatch(getTransactionsAmount(response.data));
		})
		.catch(error => dispatch(getTransactionsRequestFailure(error.toString())));
};

export const getTransactionsRequest = (params) => dispatch => {
	dispatch(getTransactionsRequestStart());
	return API.transactions
		.getTransactions(params)
		.then(response => {
			dispatch(getTransactionsRequestSuccess(response.data));
		})
		.catch(error => dispatch(getTransactionsRequestFailure(error.toString())));
};

export const getShortViewRequest = (id) => dispatch => {
	dispatch(getShortViewRequestStart());
	return API.transactions
		.getShortView(id)
		.then(response => {
			dispatch(getShortViewRequestSuccess(response.data))
		})
		.catch(error => dispatch(getShortViewRequestFailure(error.toString())));
};

export const getViewRequest = (id) => dispatch => {
	dispatch(getViewRequestStart());
	return API.transactions
		.getView(id)
		.then(response => {
			dispatch(getViewRequestSuccess(response.data));
		})
		.catch(error => dispatch(getViewRequestFailure(error.toString())));
};

export const updateStatusesRequest = (ids) => dispatch => {
    const statuses = [];
    
    return Promise.all(ids.map((id) => {
        return API.transactions
        .updateStatus(id)
        .then((response) => {
            statuses.push({id: id, status: response.data[0]})
        })
    }))
    .then(() => dispatch(updateStatusesSuccess(statuses)))
    .catch((error) => dispatch(updateStatusesFailure(error.message)));
};

export const setNotifiesRequest = (data) => dispatch => {
    const statuses = [];

    return Promise.all(data.map((item) => {
        return API.transactions
        .setNotify(item.id, item.data)
        .then((response) => {
            statuses.push({id: item.id, status: response.data.status})
        })
    }))
    .then(() => dispatch(setNotifiesSuccess(statuses)))
    .catch((error) => dispatch(setNotifiesFailure(error.message)));
};

export const getTransactionRequests = (data) => dispatch => {
    return API.transactions
    .getTransactionRequests(data)
    .then((response) => dispatch(getTransactionRequestsSuccess(response.data)))
    .catch(error => dispatch(getTransactionRequestsFailure(error.toString())));
};

export const getServerTimeRequest = () => dispatch => {
    return API.transactions
    .getServerTime()
    .then((response) => dispatch(getServerTime(response.data.date)))
    .catch(error => console.log(error))
};

export const getStatusesListRequest = () => dispatch => {
    return API.transactions
    .getStatusesList()
    .then(response => dispatch(getStatusesList(response.data)))
    .catch(error => console.log(error))
};

export const getNotifyStatusesListRequest = () => dispatch => {
    return API.transactions
    .getNotifyStatusesList()
    .then(response => dispatch(getNotifyStatusesList(response.data)))
    .catch(error => console.log(error))
};

export const getPaymentMethodsRequest = () => dispatch => {
    return API.transactions
    .getPaymentMethodsList()
    .then(response => dispatch(getPaymentMethodsList(response.data)))
    .catch(error => console.log(error))
};

export const transactionsDownload = data => dispatch => {
	return API.transactions
		.downloadTable(data)
		.then(res => {
			const blob = new Blob([res.data], { type: res.headers['content-type'] })
			const downloadUrl = window.URL.createObjectURL(blob)
			const linkUrl = document.createElement('a')
			// linkUrl.download = `Transactions ${format(new Date(), 'yyyy-MM-dd')}.xlsx`
			linkUrl.download = `Transactions ${format(new Date(), 'yyyy-MM-dd')}.csv`
			linkUrl.href = downloadUrl
			document.body.appendChild(linkUrl)
			linkUrl.click()
			document.body.removeChild(linkUrl)
			linkUrl.remove()
		})
		.catch(error => dispatch(requestFailure(error.toString())));
}