import React, {useState, useEffect} from 'react';
import {Button} from '../../../components/button';
import {HasChieldRoute} from './_has-chield-route';
import {ChildPermissions} from '../../../reducers/permission';
import {WrapSearchPanel, ScrolledContainer} from './permission-childs-lists';
import {Input} from '../../../components/input';
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

type Props = {
    childFieldss: Array<ChildPermissions>;
    onSaveFields: (data: Array<ChildPermissions>) => void;
}

export const FieldsList: React.FC<Props> = ({childFieldss, onSaveFields}) => {

    const [fieldsState, setFieldsState] = useState(childFieldss);

    const handleChange = (code) => {
        setFieldsState(prev => {
            let changedState = [...prev];
            changedState.forEach(item => {
                if(item.code === code){
                    item.checked = !item.checked
                }
            })
            return changedState
        })
    }

    useEffect(() => {
        setFieldsState(childFieldss)
    }, [childFieldss])

    const handleSave = () => {
        onSaveFields(fieldsState) //...
    }

    const [filter,  setFilter] = useState<string>('');
    const handleChangeFilter = (e) => {
        setFilter(e.target.value)
    }

    const filteredFields = (key) => {
        return childFieldss.filter(el => el.code.toLowerCase().includes(key.toLowerCase()))
    }

    return <>
        <WrapSearchPanel>
            <Input 
                placeholder='Filter'
                value={filter} 
                onChange={handleChangeFilter}
                sx={{
                    maxWidth: 'calc(100% - 100px)',
                    margin: '0px',
                }}
            />
            <Button 
                onClick={handleSave}
            >
                Save
            </Button>
        </WrapSearchPanel>
        <ScrolledContainer>
            <OverlayScrollbarsComponent
                className='overlay-scroll-list'
                options={{
                    scrollbars: { visibility: 'visible' } 
                }}
            >
                <HasChieldRoute chahgeElement={handleChange} items={filteredFields(filter)} />
            </OverlayScrollbarsComponent>
        </ScrolledContainer>
    </>
}