export namespace ActionTypes {
    export const FETCH_USERS_BRANCH_START = 'FETCH_USERS_BRANCH_START';
    export const FETCH_USERS_BRANCH_SUCCESS = 'FETCH_USERS_BRANCH_SUCCESS';
    export const FETCH_USERS_BRANCH_FAILURE = 'FETCH_USERS_BRANCH_FAILURE';
    export const FETCH_USERS_BRANCH_DROPDOWN_LIST = 'FETCH_USERS_BRANCH_DROPDOWN_LIST';
};

type UsersList = {
	'@context': string;
	'@id': string;
	'hydra:member': [];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

interface Users {
	isError: boolean,
    isLoading: boolean,
	users: UsersList,
	errorMessage: string,
	itemUser: any,
	dropdownAssignNodes: [],
}

export const initialState: Users = {
	isError: false,
    isLoading: false,
	users: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	errorMessage: '',
	itemUser: [],
	dropdownAssignNodes: []
};

export const usersOnTheBranch = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_USERS_BRANCH_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_USERS_BRANCH_SUCCESS:
			return {
				...state,
				users: payload,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};
		case ActionTypes.FETCH_USERS_BRANCH_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		case ActionTypes.FETCH_USERS_BRANCH_DROPDOWN_LIST:
			return {
				...state,
				dropdownAssignNodes: payload,
			};
		default:
			return state;
	}
};
