import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import { Loader } from '../../components/loaders/loader'
import { 
    settingPPSItemRequest,
    settingPPSItemUpdate,
} from "../../actions/settingsPPS";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { Input } from '../../components/input';
import { TextArea } from '../../components/textarea'

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import { ValuesCreate } from './form-create-setting';
import { SettingItem } from "../../reducers/settingsPPS"; 
import { view } from './index';

const PageContainer = styled('div')(({theme}) => (` 
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxEdit = styled(Box)(({theme}) => (`
    boeder: 1px solid red;
    padding: 24px;  
    margin-bottom: 50px;    
`));

const WrappBox = styled(Box)(({theme}) => (`
    padding-bottom: 40px;
`));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginRight: '16px',
    marginTop: '8px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

type PropsType = {
    settingPPSItemRequest: (id: number) => void,
    settingPPSItemUpdate: (id: number, data: ValuesCreate) => any,
    settingsInfo: SettingItem,
    isLoading: boolean,
    setView: any, 
    view: view,
    error: boolean,
    errorMessage: string,
}

const optionSettings = [
    {value: 'integer', title: 'Number'},
    {value: 'array', title: 'Array'},
    {value: 'text', title: 'Text'},
    {value: 'bool', title: 'Boolean'},
]

export const StatementsEdit = (props: PropsType) => {

    const {
        settingPPSItemRequest,
        settingPPSItemUpdate,
        settingsInfo,
        isLoading,
        setView, 
        view,
        error,
        errorMessage
    } = props

    const [values, setValues] = React.useState<ValuesCreate>({
        group: settingsInfo.group,
        name: settingsInfo.name,
        type: settingsInfo.type,
        value: settingsInfo.type === 'array' ? JSON.parse(settingsInfo.value).join(',') : settingsInfo.value
    });

    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);

    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    useEffect(() => {
        settingPPSItemRequest(view.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = (way) => () => {
        setView(prev => ({...prev, point: way}))
    }

    const handleChangeValue = (prop) => (e) => {
        setValues(prev => ({...prev, [prop]: e.target.value}))
    }

    const handleClickSave = () => {
        let {group, name, type, value} = values;

        if(type === 'array'){
            value = JSON.stringify(value.split(','))
        }

        const data = {
            "group": group,
            "name": name,
            "type": type,
            "value": value,
        }

        settingPPSItemUpdate(view.id, data).then(() => handleOpenSnackbar())
    }

    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation('view')}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            Update: {`${settingsInfo.group} - ${settingsInfo.name}`}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
                <ShortViewHeaderRight>
                    <Button onClick={handleClickSave}>
                        <SaveIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        save
                    </Button>
                    <Button 
                        onClick={handleClickNavigation('main')}
                        variant='outlined'
                    >
                        close
                    </Button>
                </ShortViewHeaderRight>
            </ShortViewHeader>

            <WrappBox>
                <BoxEdit component={Paper} sx={{ typography: 'body1' }}>
                    <Input 
                        label="ID" 
                        value={view.id} 
                        sx={{marginBottom: '16px'}}
                        disabled
                    />
                    <Input 
                        label="Settings group *" 
                        value={values.group} 
                        sx={{marginBottom: '16px'}}
                        onChange={handleChangeValue('group')}
                    />
                    <Input 
                        label="Name *" 
                        value={values.name} 
                        sx={{marginBottom: '16px'}}
                        onChange={handleChangeValue('name')}
                    />
                    <StyledFormControl fullWidth size="small">
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={values.type}
                            label="Type"
                            sx={{marginBottom: '16px'}}
                            onChange={handleChangeValue('type')}
                        >
                            {optionSettings.map(itm => <StyledMenuItem key={Math.random()} value={itm.value}>{itm.title}</StyledMenuItem>)}
                        </Select>
                    </StyledFormControl>
                    <TextArea 
                        label="Setting Value" 
                        value={values.value} 
                        onChange={handleChangeValue('value')}
                    />
                </BoxEdit>
            </WrappBox>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                {error ?
                <InfoPopup
                    severity="error"
                    headerText="Setting not edited"
                    infoText={errorMessage ? errorMessage : "Something went wrong. Please try again later"}
                /> :
                <InfoPopup
                    severity="success"
                    headerText="Setting was edited"
                />}
            </Snackbar>

            {isLoading && <Loader/>}

        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState | any) => ({
        settingsInfo: state.settingsPPS.settingItem,
        isLoading: state.settingsPPS.isLoading,
        error: state.settingsPPS.isError,
        errorMessage: state.settingsPPS.errorMessage,
    }), {
        settingPPSItemRequest,
        settingPPSItemUpdate,
    }),
)(StatementsEdit)