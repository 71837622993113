export namespace ActionTypes {
    export const GET_NOTIFY_SETTING = 'GET_NOTIFY_SETTING';
	export const CREATE_NOTIFY_SETTING_SUCCESS = 'CREATE_NOTIFY_SETTING_SUCCESS';
    export const CREATE_NOTIFY_SETTING_ERROR = 'CREATE_NOTIFY_SETTING_ERROR';
	export const UPDATE_NOTIFY_SETTING_SUCCESS = 'UPDATE_NOTIFY_SETTING_SUCCESS';
    export const UPDATE_NOTIFY_SETTING_ERROR = 'UPDATE_NOTIFY_SETTING_ERROR';
    export const SET_INITIAL_SETTING = 'SET_INITIAL_SETTING';
};

export const initialState = {
    error: false,
    notifySetting: {
        depositDspendNotify: 0,
        depositMispaidNotify: 0,
        depositSuccessNotify: 0,
        depositUnconfNotify: 0,
        emails: '',
        nofityUrl: '',
        phone: '',
        useEmail: '',
        useSms: 0,
        useTelegram: 0,
        useUrl: 0,
        username: '',
        withdrawNotify: 0
    },
	errorMessage: ''
};

export const notificationSettings = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.GET_NOTIFY_SETTING:
			return {
				...state,
				notifySetting: payload,
			};
		case ActionTypes.UPDATE_NOTIFY_SETTING_SUCCESS:
			return {
				...state,
				error: false,
				errorMessage: ''
			}
		case ActionTypes.UPDATE_NOTIFY_SETTING_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}
		case ActionTypes.CREATE_NOTIFY_SETTING_SUCCESS:
			return {
				...state,
				error: false,
				errorMessage: ''
			}
		case ActionTypes.CREATE_NOTIFY_SETTING_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}
        case ActionTypes.SET_INITIAL_SETTING:
            return initialState;
		default:
			return state;
	}
};