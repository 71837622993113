import React, {useEffect} from "react";
import {styled, useTheme} from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NotifyDialog } from '../../components/notify-dialog';
import {isJson} from '../../utils';
import { Loader } from '../../components/loaders/loader';
import { 
    statementItemRequest,
    statementDelete,
} from "../../actions/statements";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import Dialog from '@mui/material/Dialog';
import ReactJson from 'react-json-view';
import {Viev} from './index';
import {StatementItem} from '../../reducers/statements';

const PageContainer = styled('div')(({theme}) => (`    
    padding: 40px;
    position: relative;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxView = styled(Box)(({theme}) => (`
    // border: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;  
    .MuiTableContainer-root{
        border-radius: 0;
    }
    tr{
        &:last-child{
            td{
                border-bottom: none;
            }
        }
    }   
`));

const TableCellTitle = styled(TableCell)(({theme}) => (`
    font-weight: bold;
    min-width: 150px;
`));

const StyledDialog = styled(Dialog)(({ theme }) => (`
    &{
        .MuiDialog-paper{
            border-radius: 0;
        }
    }
`));

const WrappBox = styled(Box)(({theme}) => (`
    margin-bottom: 40px;
    overflow: auto;
    border: 1px solid rgba(224, 224, 224, 1);
`));

type PropsType = {
    statementItemRequest: (id: number) => void,
    statementDelete: (id: number) => void,
    statementInfo: StatementItem,
    isLoading: boolean,
    setView: any, 
    view: Viev,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

export const StatementsView: React.FC<PropsType> = (props) => {
    const [openDeleteWindow, setOpenDeleteWindow] = React.useState(false)
    const theme = useTheme();
    let bgJson = theme.palette.mode === 'dark' ? {backgroundColor: '#272930'} : null;
    const jsonTheme = theme.palette.mode === 'light' ? 'bright:inverted' : 'eighties';

    const {
        statementItemRequest,
        statementDelete,
        statementInfo,
        isLoading,
        setView, 
        view,
        routsUser, 
        userRoles, 
        isSuperadmin,
    } = props;

    useEffect(() => {
        statementItemRequest(view.idCurrentStatement)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = (way) => () => {
        setView((prev) => {
            return {
                ...prev,
                point: way,
            }
        })
    }

    const handleClickOpenWindowDelete = () => {
        setOpenDeleteWindow(true)
    }
    const handleClickCloseWindowDelete = () => {
        setOpenDeleteWindow(false)
    }

    const handleDelete = () => {
        statementDelete(view.idCurrentStatement)
        setView((prev) => {
            return {
                point: 'main',
                idCurrentStatement: null,
            }
        })
    }

    const accessedRoleEdit = routsUser.some(rout => rout.child.includes('/statement/update')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleDelete = routsUser.some(rout => rout.child.includes('/statement/delete')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    
    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation('main')}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            Statement {statementInfo.name ? statementInfo.name : `id: ${view.idCurrentStatement}`}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
                <ShortViewHeaderRight>
                    {accessedRoleEdit &&
                        <Button onClick={handleClickNavigation('edit')}>
                            <EditIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                            edit
                        </Button>
                    }
                    {accessedRoleDelete &&
                        <Button isDeleteButton={true} onClick={handleClickOpenWindowDelete}>
                            <DeleteIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                            delete
                        </Button>
                    }
                </ShortViewHeaderRight>
            </ShortViewHeader>

            <WrappBox>
                <BoxView sx={{ width: '100%', typography: 'body1' }}>
                    <TableContainer component={Paper} sx={{minWidth: '900px'}}>
                        <Table>   
                            <TableBody>
                                <TableRow>
                                    <TableCellTitle>ID</TableCellTitle>
                                    <TableCell>{view.idCurrentStatement}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Token</TableCellTitle>
                                    <TableCell>{statementInfo.token}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Done</TableCellTitle>
                                    <TableCell>{statementInfo?.status ? 'No' : 'Yes'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Payment Systems</TableCellTitle>
                                    <TableCell>
                                        {
                                            <ReactJson 
                                                src={ isJson(statementInfo.paymentSystems) 
                                                    ? JSON.parse(statementInfo.paymentSystems) 
                                                    : []}
                                                style={bgJson}
                                                theme={jsonTheme}       
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Name</TableCellTitle>
                                    <TableCell>{statementInfo.name && statementInfo.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Link</TableCellTitle>
                                    <TableCell>{statementInfo.link && statementInfo.link}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BoxView>
            </WrappBox>

            <StyledDialog
                open={openDeleteWindow}
                onClose={handleClickCloseWindowDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NotifyDialog
                    title={` Delete statement ${view.idCurrentStatement} ?`}
                    confirmButtonText='YES, DELETE'
                    cancelButtonText='CANCEL'
                    onClose={handleClickCloseWindowDelete}
                    width="400px"
                    isDeleteButton
                    onCancel={handleClickCloseWindowDelete}
                    onConfirm={handleDelete}
                >
                    Are you sure you want to delete this statement?
                </NotifyDialog>  
            </StyledDialog>

            {isLoading && <Loader/>}

        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        statementInfo: state.statements.itemStatement,
        isLoading: state.statements.isLoading, 
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        statementItemRequest,
        statementDelete,
    }),
)(StatementsView)