import React from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import TablePagination from '@mui/material/TablePagination';
import { Loader } from '../../components/loaders/loader';
import Menu from '@mui/material/Menu';
import { TableFilter } from "../../components/table-filter";
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import { PlusSvg } from '../../components/svg/plus-svg';
// import Structure from '../../components/structure';
// import { Status } from '../../components/status';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {CreatePermissionForm} from './create-permissions-form'
import Dialog from '@mui/material/Dialog';
import { 
    usersPermissionsRequest,
    userPermissionCreate,
} from "../../actions/usersPermissiions";
import {
    usersPermissionGroupsRequest,
} from '../../actions/usersPermissionGroups'
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import {PermissionGroupListItem} from '../../reducers/permissionGroups';
import {PermissionListItem} from '../../reducers/permission';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { AccessDeniedAlert } from "../../components/access-denied";
import { Link } from 'react-router-dom';

const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UsersPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap'
    }
});

const UsersPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UsersPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width:991px)': {
        width: '100%',
        marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const UsersPageHeaderRight = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

// const ActionIcon = styled('div')(({ theme }) => (`
//     cursor: pointer;
//     &:hover{
//       svg{
//         fill: #38AA72 !important;
//       }
//     }    
// `));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableRow = styled(TableRow)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
`));

// const RoleContent = styled('div')({
//     textDecoration: 'underline',
//     cursor: 'pointer'
// });

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledDialogEdit = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        margin: 15px;
        margin-top:0;
        margin-bottom:0;
        max-height: 100%;
        border-radius: 0;
    }
`));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}))

const TableCellContentWith400 = styled('div')(`
    display: flex;
    align-items: center;
    min-width: 425px;
`);

const IconWrapper = styled('div')({
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 195px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

const LoginContent = styled(Link)(({ theme }) => (`
    text-decoration: underline;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

type GetData = {
    page?: number,
    description: string, 
    name: string,
    'groupCode.code'?: string | Array<string>,
}

type CreateData = {
    name: string,
    description: string,
    group?: string,
}

type PropsType = {
    isLoading: boolean,
    permissionsList: Array<PermissionListItem>, 
    permissionGroupsList: Array<PermissionGroupListItem>,
    usersPermissionsRequest: (data: GetData) => void,
    userPermissionCreate: (data: CreateData) => void,
    usersPermissionGroupsRequest: () => Promise<any>,
    setView: any,
    listCount: number,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

type PropState = {
    anchorElGroup: null | any,
    isOpenCreateForm: boolean,
    page: number,
    rowsPerPage: number,
    description: string,
    name: string,
    groupFilter: Array<string>,
}

export class MainPermissionPage extends React.Component<PropsType, PropState> {
    constructor(props) {
        super(props);

        this.state = {
            anchorElGroup: null,
            page: 0,
            rowsPerPage: 20,
            description: '',
            name: '',
            groupFilter: [],
            isOpenCreateForm: false,
        }
    };

    handleGroupMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElGroup: event.currentTarget});
    };
    handleGroupMenuClose = () => {
        this.setState({anchorElGroup: null});
    };

    handleGroupMenuChange = (e) => {
        this.setState({groupFilter: e}, () => {this.updateData()});
        this.handleGroupMenuClose();
    }

    handleCreateFormOpen = () => this.setState({isOpenCreateForm: true});
    handleCreateFormClose = () => this.setState({isOpenCreateForm: false});

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleOpenInfoItem = (name) => {
        const {setView} = this.props
        setView((prev) => ({id: name, point: 'view'}))
    }

    handleCreatePermission = (data) => {
        const {userPermissionCreate} = this.props;
        userPermissionCreate(data)
    }

    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
            usersPermissionGroupsRequest,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/user-management/permission/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
            usersPermissionGroupsRequest();
        }
    }

    updateData = () => {

        const {
            page,
            description,
            name,
            groupFilter,
        } = this.state;

        const {
            usersPermissionsRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            description, 
            name,
            'groupCode.code': groupFilter,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        usersPermissionsRequest(data);
    }

    render() {

        const {
            isLoading,
            permissionsList,
            permissionGroupsList,
            listCount,
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        const {
            page,
            rowsPerPage,
            groupFilter,
            isOpenCreateForm,
            anchorElGroup,
            name,
            description,
        } = this.state;

        let permissionGroupFilter = [];

        try {
            permissionGroupFilter = permissionGroupsList.map((item) => {
                return {
                    title: item.name,
                    value: item.code,
                }
            })           
        } catch (error) {}

        let iteratorNumber = 1;

        const accessedRole = routsUser.some(rout => rout.child.includes('/user-management/permission/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <UsersPageHeader>
                                <UsersPageHeaderLeft>
                                    <UsersPageHeaderText>Permissions</UsersPageHeaderText>
                                    {/* <Structure/> */}
                                </UsersPageHeaderLeft>
                                <UsersPageHeaderRight>
                                    <Button 
                                        startIcon={<PlusSvg width="22px" />} 
                                        onClick={this.handleCreateFormOpen}
                                    >
                                        Create
                                    </Button>
                                </UsersPageHeaderRight>
                            </UsersPageHeader>
            
                            <StyledPaperTable>                               
                                <StyledTableContainer>
                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >
                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <TableCell>№</TableCell>
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Description' 
                                                        handleSearchSubmit={this.handleSearchSubmit('description')}
                                                        handleCancelSearch={this.handleCancelSearch('description')}
                                                        isActiveFilter={Boolean(description)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Code' 
                                                        handleSearchSubmit={this.handleSearchSubmit('name')}
                                                        handleCancelSearch={this.handleCancelSearch('name')}
                                                        isActiveFilter={Boolean(name)}
                                                    />
                
                                                    <TableCellWithActiveClass
                                                        className={ groupFilter.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContentWith400>
                                                            Group
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleGroupMenuClick}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElGroup}
                                                                open={Boolean(anchorElGroup)}
                                                                onClose={this.handleGroupMenuClose}
                                                            >
                                                                <TableFilter 
                                                                    nWidth='300px' 
                                                                    filterItems={permissionGroupFilter || [] }
                                                                    initialData={groupFilter}
                                                                    onApplyFilter={this.handleGroupMenuChange} 
                                                                    onClearAll={this.handleGroupMenuChange}
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContentWith400>
                                                    </TableCellWithActiveClass>

                                                    <ActionsHeaderCell>
                                                        <StickyBorder>Action</StickyBorder>
                                                    </ActionsHeaderCell>
                
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(permissionsList || []).map(row => {
                                                    return (
                                                        <StyledTableRow key={Math.random()}>
                                                            <TableCell>
                                                                {iteratorNumber++}
                                                            </TableCell>
                                                            <TableCell>
                                                                <LoginContent to={`/users/permission/${row.name}`}>
                                                                    {row.description}
                                                                </LoginContent>
                                                            </TableCell>
                                                            <TableCell> 
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell> 
                                                                {row.groupCode?.name}
                                                            </TableCell>
                                                            <ActionsCell>
                                                                <StickyBorder>
                                                                    <ActionsContent>  
                                                                        <Tooltip title='Details' placement="top">    
                                                                                <LoginContent 
                                                                                    to={`/users/permission/${row.name}`}
                                                                                >
                                                                                    <RemoveRedEyeIcon />
                                                                                </LoginContent>
                                                                        </Tooltip>    
                                                                    </ActionsContent>
                                                                </StickyBorder>
                                                            </ActionsCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>    
                                    </OverlayScrollbarsComponent>
                                </StyledTableContainer>   

                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={listCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />

                            </StyledPaperTable>

                            <StyledDialogEdit
                                open={isOpenCreateForm}
                                onClose={this.handleCreateFormClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <StyledBoxPopup>
                                    <CreatePermissionForm 
                                        onClose={this.handleCreateFormClose}
                                        onCreate={this.handleCreatePermission}
                                        // initialGroup={permissionGroupFilter}
                                    />
                                </StyledBoxPopup>
                            </StyledDialogEdit>
                        </>
                    }
                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.permissions.isLoading,
        permissionsList: state.permissions.permissions['hydra:member'], 
        permissionGroupsList: state.permissionGroups.permissionGroups['hydra:member'],
        listCount: state.permissions.permissions['hydra:totalItems'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        usersPermissionsRequest,
        userPermissionCreate,
        usersPermissionGroupsRequest,
    }),
)(MainPermissionPage)
