import React from "react";
import TableCell from '@mui/material/TableCell';
import { styled } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const TableCellWithSearch = styled('div')(`
    width: 100%;
    display: flex;
    align-items: center;
`);

const TableCellContent = styled('div')(`
    display: flex;
    align-items: center;
`);

const StyledTableCellContent = styled(TableCellContent)(({ theme }) => (`
    & {
        // min-width: 300px;
    }
`));

const StyledInput = styled('input')(({ theme }) => (`
    background: transparent;
    border: 1px solid ${theme.palette.text.primary};
    outline: none;
    font-size: 14px;
    width: 100%;
    color: ${theme.palette.text.primary};
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 26px;
    &::placeholder{
        color: ${theme.palette.text.primary};
    }
`));

const TableCellWrapWithSearch = styled(TableCell)(({theme})=>({
    paddingTop: '0',
    paddingBottom: '0',
    height: '56.5px',
    '&.activeFilterClass':{
        background: '#38AA72',
        '& input': {
            borderColor: '#fff',
            color: '#fff',
            '&::placeholder':{
                color: '#fff',
            }
        },
        '& button svg': {
            color: '#fff',
        }
    },
}))

type Props = {
    width?: string;
    widthMin?: string;
    label: string;
    sx?: any;
    handleSearchSubmit?: (value: any) => void;
    handleCancelSearch?: () => void;
    isActiveFilter?: boolean;
}

export const SerachTableHeader = ({handleSearchSubmit, handleCancelSearch, label, sx, width, widthMin, isActiveFilter}: Props) => {

    const [searchValue, setSearchValue] = React.useState("");
    const [isSearchNameField, setIsSearchNameField] = React.useState(false);

    const handleActivateSearch = () => {
        setIsSearchNameField((prevState) => {
            if (prevState === true) {
                handleCancelSearch();
            }
            return !prevState
        });
    };

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
    }

    const handleSubmit = () => {
        if(searchValue){
            handleSearchSubmit(searchValue)
            setSearchValue('')
        }
    };

    const handlePressEnter = (event) => {
        if (event.key === 'Enter' && searchValue) {
            handleSearchSubmit(searchValue)
            setSearchValue('')
        }
    };
    
    return (
        <TableCellWrapWithSearch 
            width={width ? width : '50%'} 
            sx={sx}
            className={isActiveFilter && 'activeFilterClass'}
        >
            <StyledTableCellContent 
                sx={
                    {   
                        minWidth: widthMin ? widthMin : width ? width : '300px'
                    }
                }
            >                                           
                <TableCellWithSearch>

                    {isSearchNameField 
                        ? <StyledInput 
                            placeholder="Search" 
                            value={searchValue} 
                            onChange={(e) => {
                                handleChangeSearchValue(e)
                            }}
                            onKeyDown={handlePressEnter}
                        /> 
                        : <span
                            data-testid={`labelTitle${label.replace(/[^a-zA-Zа-яА-Я]/g, '')}`}
                        >
                            {label}
                        </span>
                    }
                    
                    {isSearchNameField
                        ? <>                                                      
                            <IconButton
                                aria-label=""
                                onClick={handleSubmit}
                                sx={{marginLeft: '5px'}}
                            >
                                <SearchIcon 
                                    sx={{
                                        fontSize: '18px',
                                    }} 
                                />
                            </IconButton>
                            <IconButton
                                aria-label=""
                                onClick={handleActivateSearch}
                                sx={{
                                    marginLeft: '0px',
                                }}
                            >
                                <CloseIcon sx={{
                                    fontSize: '20px',
                                }} />
                            </IconButton>  
                        </>

                        : <IconButton
                            aria-label=""
                            onClick={handleActivateSearch}
                            sx={{marginLeft: '5px'}}
                            data-testid={`buttonSearchRow${label.replace(/[^a-zA-Zа-яА-Я]/g, '')}`}
                        >
                            <SearchIcon 
                                sx={{fontSize: '18px'}} 
                            />
                        </IconButton>
                    }
                    
                </TableCellWithSearch>                                
            </StyledTableCellContent>
        </TableCellWrapWithSearch>
    )
}