import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiPhoneNumber from "material-ui-phone-number";
import {getNotifySettingRequest, createNotifySettingRequest, updateNotifySettingRequest, setInitialSetting} from '../../actions/notificationSettings';
import { getNodeRequest } from "../../actions/nodeManagement";

const NotificationSettingsFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0'
});

const StyledCheckbox = styled(Checkbox)({
    color: '#979799',
    '&.Mui-checked': {
        color: '#38AA72',
    }
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => (`
    color: ${theme.palette.text.primary};
`));

const StyledPhoneInput = styled(MuiPhoneNumber)(({ theme }) => ({
    marginTop: '8px',
    marginBottom: '4px',
    width: '100%',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#38AA72',
        },
    },
}));


const NotificationSettingsForm = ({onClose, getNotifySettingRequest, createNotifySettingRequest, updateNotifySettingRequest, node, notifySetting, setInitialSetting, getNodeRequest, handleOpenSnackbar}: any) => {
    const id = node.dispatch?.split('/').pop();
    
    const [useEmail, setUseEmail] = useState(Boolean(notifySetting.useEmail));
    const handleSetUseEmail = () => {
        setUseEmail(!useEmail);
    };

    const [emails, setEmails] = useState(notifySetting.emails);
    const handleSetEmails = (event) => {
        setEmails(event.target.value)
    };

    const [useSms, setUseSms] = useState(Boolean(notifySetting.useSms));
    const handleSetUseSms = () => {
        setUseSms(!useSms);
    };

    const [phone, setPhone] = useState(notifySetting.phone);
    const handleOnChangePhone = (value: any) => {
        setPhone(value);
    };

    const [useTelegram, setUseTelegram] = useState(Boolean(notifySetting.useTelegram));
    const handleSetUseTelegram = () => {
        setUseTelegram(!useTelegram);
    };

    const [username, setUsername] = useState(notifySetting.username);
    const handleSetUsername = (event) => {
        setUsername(event.target.value)
    };

    const [useUrl, setUseUrl] = useState(Boolean(notifySetting.useUrl));
    const handleSetUseUrl = () => {
        setUseUrl(!useUrl);
    };

    const [notifyUrl, setNotifyUrl] = useState(notifySetting.nofityUrl);
    const handleSetNotifyUrl = (event) => {
        setNotifyUrl(event.target.value)
    };

    const [depositUnconfNotify, setDepositUnconfNotify] = useState(Boolean(notifySetting.depositUnconfNotify));
    const handleSetDepositUnconfNotify = () => {
        setDepositUnconfNotify(!depositUnconfNotify);
    };

    const [depositSuccessNotify, setDepositSuccessNotify] = useState(Boolean(notifySetting.depositSuccessNotify));
    const handleSetDepositSuccessNotify = () => {
        setDepositSuccessNotify(!depositSuccessNotify);
    };

    const [depositMispaidNotify, setDepositMispaidNotify] = useState(Boolean(notifySetting.depositMispaidNotify));
    const handleSetDepositMispaidNotify = () => {
        setDepositMispaidNotify(!depositMispaidNotify);
    };

    const [depositDspendNotify, setDepositDspendNotify] = useState(Boolean(notifySetting.depositDspendNotify));
    const handleSetDepositDspendNotify = () => {
        setDepositDspendNotify(!depositDspendNotify);
    };

    const [withdrawNotify, setWithdrawNotify] = useState(Boolean(notifySetting.withdrawNotify));
    const handleSetWithdrawNotify = () => {
        setWithdrawNotify(!withdrawNotify);
    };

    useEffect(() => {
        if (id) {
            getNotifySettingRequest(id);
        } else {
            setInitialSetting();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setUseEmail(Boolean(notifySetting.useEmail));
        setEmails(notifySetting.emails);
        setUseSms(Boolean(notifySetting.useSms));
        setPhone(notifySetting.phone);
        setUseTelegram(Boolean(notifySetting.useTelegram));
        setUsername(notifySetting.username);
        setUseUrl(Boolean(notifySetting.useUrl));
        setNotifyUrl(notifySetting.nofityUrl);
        setDepositUnconfNotify(Boolean(notifySetting.depositUnconfNotify));
        setDepositSuccessNotify(Boolean(notifySetting.depositSuccessNotify));
        setDepositMispaidNotify(Boolean(notifySetting.depositMispaidNotify));
        setDepositDspendNotify(Boolean(notifySetting.depositDspendNotify));
        setWithdrawNotify(Boolean(notifySetting.withdrawNotify));
    }, [notifySetting]);

    const handleSubmit = () => {
        const data = {
            brand: `api/nodes/${node.id}`,
            emails,
            phone: phone.replace(/\s+/g, ''),
            username,
            useEmail: Number(useEmail),
            useSms: Number(useSms),
            useTelegram: Number(useTelegram),
            useUrl: Number(useUrl),
            nofityUrl: notifyUrl,
            depositUnconfNotify: Number(depositUnconfNotify),
            depositSuccessNotify: Number(depositSuccessNotify),
            depositMispaidNotify: Number(depositMispaidNotify),
            depositDspendNotify: Number(depositDspendNotify),
            withdrawNotify: Number(withdrawNotify)
        };

        id ? updateNotifySettingRequest(id, JSON.stringify(data)).then(() => handleOpenSnackbar('Notify setting was updated', 'Notify setting not updated'))
        : createNotifySettingRequest(JSON.stringify(data)).then(() => handleOpenSnackbar('Notify setting was created', 'Notify setting not created'));
        
        if (!id) {
            setTimeout(() => getNodeRequest(node.id), 1000);
        };

        onClose();
    };

    const isFormValid = () => {
        return id ? 
            useEmail !== Boolean(notifySetting.useEmail) ||
            emails !== notifySetting.emails ||
            useSms !== Boolean(notifySetting.useSms) ||
            phone !== notifySetting.phone ||
            useTelegram !== Boolean(notifySetting.useTelegram) ||
            username !== notifySetting.username ||
            useUrl !== Boolean(notifySetting.useUrl) ||
            notifyUrl !== notifySetting.nofityUrl ||
            depositUnconfNotify !== Boolean(notifySetting.depositUnconfNotify) ||
            depositSuccessNotify !== Boolean(notifySetting.depositSuccessNotify) ||
            depositMispaidNotify !== Boolean(notifySetting.depositMispaidNotify) ||
            depositDspendNotify !== Boolean(notifySetting.depositDspendNotify) ||
            withdrawNotify !== Boolean(notifySetting.withdrawNotify)
            : true;
    }

    return (
        <NotificationSettingsFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>Notification settings</Title>
                <StyledFormControlLabel
                    label="Use email notification"
                    control={<StyledCheckbox checked={useEmail} onChange={handleSetUseEmail}/>}
                />
                <Input label="Emails" multiline rows={3} placeholder="email1@mail.com, email2@mail.com" onChange={handleSetEmails} value={emails}></Input>
                <StyledFormControlLabel
                    label="Use sms notification"
                    control={<StyledCheckbox checked={useSms} onChange={handleSetUseSms}/>}
                />
                <StyledPhoneInput defaultCountry="ua" variant="outlined" label="Phone number" onChange={handleOnChangePhone} value={phone} size="small"/>
                <StyledFormControlLabel
                    label="Use Telegram"
                    control={<StyledCheckbox checked={useTelegram} onChange={handleSetUseTelegram}/>}
                />
                <Input label="Username" value={username} onChange={handleSetUsername} placeholder="@username"/>
                <StyledFormControlLabel
                    label="Use link notifications"
                    control={<StyledCheckbox checked={useUrl} onChange={handleSetUseUrl}/>}
                />
                <Input label="Link for alert events" value={notifyUrl} onChange={handleSetNotifyUrl} placeholder="https://mysite.com/callback" sx={{marginBottom: '20px'}}/>
                <Title>Events:</Title>
                <StyledFormControlLabel
                    label="Deposit unconfirmed notification"
                    control={<StyledCheckbox checked={depositUnconfNotify} onChange={handleSetDepositUnconfNotify}/>}
                    sx={{width: '100%'}}
                />
                <StyledFormControlLabel
                    label="Deposit success notification"
                    control={<StyledCheckbox checked={depositSuccessNotify} onChange={handleSetDepositSuccessNotify}/>}
                    sx={{width: '100%'}}
                />
                <StyledFormControlLabel
                    label="Deposit mispaid notification"
                    control={<StyledCheckbox checked={depositMispaidNotify} onChange={handleSetDepositMispaidNotify}/>}
                    sx={{width: '100%'}}
                />
                <StyledFormControlLabel
                    label="Deposit double spend notification"
                    control={<StyledCheckbox checked={depositDspendNotify} onChange={handleSetDepositDspendNotify}/>}
                    sx={{width: '100%'}}
                />
                <StyledFormControlLabel
                    label="Withdraw notification"
                    control={<StyledCheckbox checked={withdrawNotify} onChange={handleSetWithdrawNotify}/>}
                    sx={{width: '100%'}}
                />
                
            </WrapFields>
            <ButtonsContainer>
                <Button sx={{marginRight: '12px'}} disabled={!isFormValid()} onClick={handleSubmit}>{id ? "Update" : "Create"}</Button>
                <Button variant="outlined" onClick={onClose}>CANCEL</Button>
            </ButtonsContainer>
        </NotificationSettingsFormContainer>
    )
};

export default compose(
    connect((state: RootState | any) => ({
        notifySetting: state.notificationSettings.notifySetting,
        node: state.nodeManagement.node
    }), {getNotifySettingRequest, createNotifySettingRequest, updateNotifySettingRequest, getNodeRequest, setInitialSetting})
)(NotificationSettingsForm);