import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import MuiPhoneNumber from "material-ui-phone-number";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Button} from '../../components/button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Global, css } from '@emotion/react'
import {connect} from 'react-redux';
import {compose} from "redux";
import {createWalletRequest} from '../../actions/wallets';
import {format} from 'date-fns';

const CreateWalletFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const StyledPhoneInput = styled(MuiPhoneNumber)(({ theme }) => ({
    marginTop: '20px',
    width: '100%',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#38AA72',
        },
    },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: '20px',
    width: '100%',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },   
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
})

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

const StyledLocalizationProvider = styled(LocalizationProvider)(({theme}) => `  
    & .MuiCalendarPicker-root{
        border: 1px solid red !important;
    }
`)

const CreateWalletForm = ({onClose, createWalletRequest, handleOpenSnackbar}: any) => {
    const [phone, setPhone] = useState('');
    const handleOnChangePhone = (value: any) => {
        setPhone(value);
    }

    const [paymentSystem, setPaymentSystem] = React.useState('');
    const handleChangePaymentSystem = (event: SelectChangeEvent) => {
        setPaymentSystem(event.target.value as string);
    };

    const [birthday, setBirthday] = React.useState<Date | null>(null);
    const handleChangeBirthday = (newValue: Date | null) => {
        setBirthday(newValue);
    };

    const [email, setEmail] = React.useState('');
    const handleSetEmail = (event) => {
        setEmail(event.target.value)
    };

    const [name, setName] = React.useState('');
    const handleSetName = (event) => {
        setName(event.target.value)
    };

    const [lastName, setLastName] = React.useState('');
    const handleSetLastName = (event) => {
        setLastName(event.target.value)
    };

    const [isConfirm, setIsConfirm] = React.useState(false);
    const handleBackToEdit = () => {
        setIsConfirm(false);
    };

    const handleConfirm = () => {
        if (!isConfirm) {
            setIsConfirm(true);
        } else {
            const data = {
                email,
                name,
                last_name: lastName,
                payment_system: paymentSystem,
                phone,
                birthday: format(new Date(birthday), 'yyyy-MM-dd')
            };

            createWalletRequest(JSON.stringify(data)).then(() => handleOpenSnackbar());
            onClose();
        };
    };

    return (
        <CreateWalletFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>{isConfirm ? 'Confirm Information' : 'Create wallet'}</Title>
                <StyledFormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">Payment system</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paymentSystem}
                        label="Payment system"
                        onChange={handleChangePaymentSystem}
                        size="small"
                        disabled={isConfirm}
                    >
                        <StyledMenuItem value="piastrix">Piastrix</StyledMenuItem>
                    </Select>
                </StyledFormControl>
                <Input label="Email *" sx={{marginTop: '20px'}} value={email} onChange={handleSetEmail} disabled={isConfirm} />
                <Input label="Name *" sx={{marginTop: '20px'}} value={name} onChange={handleSetName} disabled={isConfirm} />
                <Input label="Last Name *" sx={{marginTop: '20px'}} value={lastName} onChange={handleSetLastName} disabled={isConfirm} />
                <StyledPhoneInput defaultCountry="ua" variant="outlined" label="Phone" onChange={handleOnChangePhone} value={phone} size="small" disabled={isConfirm} />
                <StyledLocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Date of birth"
                        inputFormat="dd.MM.yyyy"
                        value={birthday}
                        onChange={handleChangeBirthday}
                        renderInput={(params) => <Input size="small" sx={{marginTop: '20px', width: '100%'}} {...params} />}
                        disabled={isConfirm}
                    />
                </StyledLocalizationProvider>
                <Global
                    styles={css`
                        & .MuiPickersDay-root.Mui-selected {
                            background-color: #38AA72 !important;
                        },
                    `}
                />
            </WrapFields>
            <ButtonsContainer>
                <Button sx={{marginRight: '12px'}} disabled={!paymentSystem || !email || !name || !lastName} onClick={handleConfirm}>{isConfirm ? 'SAVE' : 'CONTINUE'}</Button>
                <Button variant="outlined" onClick={isConfirm ? handleBackToEdit : onClose}>{isConfirm ? 'BACK' : 'CANCEL'}</Button>
            </ButtonsContainer>

        </CreateWalletFormContainer>
    )
};

export default compose(
    connect(null, {createWalletRequest})
)(CreateWalletForm)