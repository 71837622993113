import React, {Component} from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import { Loader } from '../../components/loaders/loader';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { 
    statementsRequest, 
    statementCreate,
} from "../../actions/statements";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import {StatementItem} from '../../reducers/statements';
import { AccessDeniedAlert } from "../../components/access-denied";


const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '24px',
    }
});

const DepositMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',

    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',

    }
});

const DepositMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({
    // display: 'flex',
    // gap: '8px',
});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

const DepositMainSecondRowLeftButtons = styled('div')({
    '@media(max-width: 768px)' : {
        '& button': {
            boxSizing: 'border-box',
            fontSize: '12px',
            height: '30px',
            padding: '0 12px',
            marginRight: '11px',
            '&:last-child':{
                marginRight: '0px',
            }
        }
    }
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const TableCellContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    minWidth: '130px'
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 260px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

type DataRequestType = {
    page: number,
    token: string,
    name: string,
    link: string,
}

type DataCreateType = {
    status: number,
    paymentSystems: string,
    name: string,
    link: string,
}

type PropsType = {
    statementsList: Array<StatementItem>, 
    statementsListCount: number, 
    isLoading: boolean, 
    statementsRequest: (data: DataRequestType) => void,
    statementCreate: (data: DataCreateType) => void,
    setView: any,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: any,
}

type StateProps = {
    page: number,
    rowsPerPage: number,
    token: string,
    name: string,
    link: string,
    clearDates: boolean,
    deleteId: string,
    openDeleteWindow: boolean,
}

export class MainStatementsPage extends Component<PropsType, StateProps> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            token: '',
            name: '',
            link: '',
            clearDates: false,
            deleteId: '',
            openDeleteWindow: false,
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleClickOpenWindowDelete = () => {
        this.setState({openDeleteWindow: true})
    }
    handleClickCloseWindowDelete = () => {
        this.setState({openDeleteWindow: false})
    }
    handleOpenDeleteDialog = (id) => {
        this.setState({deleteId: id}, () => this.handleClickOpenWindowDelete())
    }

    handleCreate = () => {
        const {statementCreate} = this.props
        const data = {
            "status": 1,
            "paymentSystems": "",
            "name": "",
            "link": ""
        }
        statementCreate(data)
    }

    handleOpenInfoItem = (id) => {
        const {setView} = this.props
        setView((prev) => {
            return {
                idCurrentStatement: id,
                point: 'view',
            }
        })
    }

    componentDidMount() {
        const {routsUser, userRoles, isSuperadmin} = this.props;
        if(routsUser.some(rout => rout.child.includes('/statement/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    updateData = () => {

        const {
            page,
            token,
            name,
            link,
        } = this.state;

        const {
            statementsRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            'token': token,
            'name': name,
            'link': link,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        statementsRequest(data);
    }

    render() {
        const linkStatement = 'https://master.frontend.paygate.xim.hattiko.pw/statement/index'

        const {
            isLoading,
            statementsList, 
            statementsListCount, 
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props

        const {
            page,
            rowsPerPage,
            token,
            link,
            name,
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child.includes('/statement/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleAction = routsUser.some(rout => rout.child.includes('/statement/view')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleCreate = routsUser.some(rout => rout.child.includes('/statement/create')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>Statements</DepositMainHeaderText>
                            </DepositMainHeader>
                            <DepositMainSecondRow>
                                <DepositMainSecondRowRightBlock>
                                    <DepositMainSecondRowLeftButtons>
                                        {accessedRoleCreate &&
                                            <Button sx={{marginRight: '16px'}} 
                                                onClick={this.handleCreate}
                                            >
                                                <AddIcon sx={{marginRight: '8px'}}/>
                                                Generate new
                                            </Button>
                                        }
                                    </DepositMainSecondRowLeftButtons>
                                </DepositMainSecondRowRightBlock>
                            </DepositMainSecondRow>
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <TableCell>Id</TableCell>
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Token' 
                                                        handleSearchSubmit={this.handleSearchSubmit('token')}
                                                        handleCancelSearch={this.handleCancelSearch('token')}
                                                        isActiveFilter={Boolean(token)}
                                                    />
                
                                                    <TableCell>
                                                        <TableCellContent>
                                                            Status
                                                        </TableCellContent>
                                                    </TableCell>
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Name' 
                                                        handleSearchSubmit={this.handleSearchSubmit('name')}
                                                        handleCancelSearch={this.handleCancelSearch('name')}
                                                        isActiveFilter={Boolean(name)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Link' 
                                                        handleSearchSubmit={this.handleSearchSubmit('link')}
                                                        handleCancelSearch={this.handleCancelSearch('link')}
                                                        isActiveFilter={Boolean(link)}
                                                    />
                
                                                    <TableCell sx={{minWidth: '120px'}}>Statement link</TableCell>

                                                    {accessedRoleAction &&
                                                        <ActionsHeaderCell>
                                                            <StickyBorder>Action</StickyBorder>
                                                        </ActionsHeaderCell>
                                                    }
                
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(statementsList || []).map(row => {
                                                    return (
                                                        <TableRow key={Math.random()}>
                                                            <TableCell>
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell sx={{minWidth: '120px'}}>
                                                                {row.token}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.status 
                                                                    ? <span>Active</span>
                                                                    : <span style={{color: 'green'}}>Done</span>
                                                                }
                                                            </TableCell>
                                                            <TableCell sx={{minWidth: '120px'}}>
                                                                {row.name
                                                                    ? row.name
                                                                    : <span style={{color: '#c55', fontStyle: 'italic'}}>(not set)</span>
                                                                }
                                                            </TableCell>
                                                            <TableCell sx={{minWidth: '120px'}}>
                                                                {row.link
                                                                    ? row.link
                                                                    : <span style={{color: '#c55', fontStyle: 'italic'}}>(not set)</span>
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <a 
                                                                    style={{color: '#3c8dbc'}}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    href={`${linkStatement}/${row.token}`}
                                                                >
                                                                    Statement link
                                                                </a>
                                                            </TableCell>

                                                            {accessedRoleAction &&
                                                                <ActionsCell>
                                                                    <StickyBorder>
                                                                        <ActionsContent>                                                    
                                                                                <ActionIcon 
                                                                                    onClick={() => {this.handleOpenInfoItem(row.id)}}
                                                                                >
                                                                                    <RemoveRedEyeIcon />
                                                                                </ActionIcon>
                                                                        </ActionsContent>
                                                                    </StickyBorder>
                                                                </ActionsCell>
                                                            }
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>                               
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={statementsListCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }

                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        statementsList: state.statements.statements['hydra:member'], 
        statementsListCount: state.statements.statements['hydra:totalItems'], 
        isLoading: state.statements.isLoading, 
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        statementsRequest,
        statementCreate,
    }),
)(MainStatementsPage)
