import React from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Loader } from '../../components/loaders/loader'
// import DoneAllIcon from '@mui/icons-material/DoneAll';
// import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { 
    settingsPPSRequest,
    settingPPSItemCreate,
} from "../../actions/settingsPPS";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import {CreateSettingForm} from './form-create-setting';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { PPSSettingsItem } from "../../reducers/settingsPPS";
import { ValuesCreate } from './form-create-setting';
import { AccessDeniedAlert } from "../../components/access-denied";

const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',
    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,
    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',
    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
    }
});

const DepositMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',
    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap'
    }
});

const DepositMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
    display: flex;
    align-items: center;
    justify-content: center;
`));

const ActionsContent = styled('div')({});

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    display: flex;
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

const DepositMainSecondRowLeftButtons = styled('div')({
    '@media(max-width: 768px)' : {
        '& button': {
            boxSizing: 'border-box',
            fontSize: '12px',
            height: '30px',
            padding: '0 12px',
            marginRight: '11px',
            '&:last-child':{
                marginRight: '0px',
            }
        }
    }
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const WrapSettingItem = styled('div')({
    paddingBottom: '40px',
});

const TitleSettingItem = styled('div')(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '20px',
    color: theme.palette.text.primary
}));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {    
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: auto;
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledModal = styled(Modal)(({ theme }) => (`
    & {    
        display: flex;
        padding: 10px;
        align-items: center;
    }
`));

interface PropsType {
    isLoading: boolean,
    settings: Array<PPSSettingsItem>,
    error: boolean,
    errorMessage: string,
    settingsPPSRequest: (data: {page: number}) => void,
    settingPPSItemCreate: (values: ValuesCreate) => any,
    setView: any,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: any,
}

interface StateType {
    openWindowCreate: boolean,
    openSnackbar: boolean,
    page: number,
    rowsPerPage: number,
}

export class MainSettingsPage extends React.Component<PropsType, StateType> {
    constructor(props) {
        super(props);

        this.state = {
            openWindowCreate: false,
            openSnackbar: false,
            page: 0,
            rowsPerPage: 20,
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleOpenInfoItem = (id: number) => {
        const {setView} = this.props
        setView(prev => ({ ...prev, id, point: 'view'}))
    }

    componentDidMount() {
        const {routsUser, userRoles, isSuperadmin} = this.props;
        if(routsUser.some(rout => rout.child.includes('/settings/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    groupBy = () => {
        const { settings } = this.props 
        return Array.from(new Set(settings.map(item => item.group)))
    }

    handleCloseWindowCreate = () => {
        this.setState({openWindowCreate: false});
    }
    handleOpenWindowCreate = () => {
        this.setState({openWindowCreate: true});
    }

    handleOpenSnackbar = () => {
        this.setState({openSnackbar: true});
    };

    handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        this.setState({openSnackbar: false});
    };

    handleCreate = (values) => {
        const {settingPPSItemCreate} = this.props
        this.handleCloseWindowCreate()

        if(values.type === 'array'){
            values.value = JSON.stringify(values.value.split(','))
        }
        
        settingPPSItemCreate(values).then(() => this.handleOpenSnackbar())
    }

    updateData = () => {

        const {
            page,
        } = this.state;

        const {
            settingsPPSRequest,
        } = this.props;

        const data = {
            'page': page + 1,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        settingsPPSRequest(data);
    }

    render() {
        
        const {
            isLoading,
            settings,
            error,
            errorMessage,
            routsUser,
            userRoles,
            isSuperadmin,
        } = this.props

        const {
            page,
            rowsPerPage,
            openWindowCreate,
            openSnackbar
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child.includes('/settings/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>PPS settings</DepositMainHeaderText>
                            </DepositMainHeader>
                            <DepositMainSecondRow>
                                <DepositMainSecondRowRightBlock>
                                    <DepositMainSecondRowLeftButtons>
                                        <Button sx={{marginRight: '16px'}} 
                                            onClick={this.handleOpenWindowCreate}
                                        >
                                            <AddIcon sx={{marginRight: '8px'}}/>
                                            New setting
                                        </Button>
                                    </DepositMainSecondRowLeftButtons>
                                </DepositMainSecondRowRightBlock>
                            </DepositMainSecondRow>
                            {
                                this.groupBy().map((groupItem: string) => {
                                    return(
                                        <WrapSettingItem key={Math.random()}>
                                            <TitleSettingItem>{groupItem}</TitleSettingItem>  
                                            <StyledPaperTable>                            
                                                <TableContainer component={'div'}>
                                                    <Table>
                                                        <StyledTableHead>
                                                            <TableRow>
                                                            
                                                                <TableCell sx={{width: '150px', minWidth: '150px',}}>
                                                                    <div>Params</div>
                                                                </TableCell> 
                    
                                                                <TableCell>
                                                                    <div style={{minWidth: '400px'}}>Value</div>
                                                                </TableCell> 
                    
                                                                <ActionsHeaderCell sx={{width: '115px', minWidth: '115px',}}>
                                                                    <StickyBorder>Action</StickyBorder>
                                                                </ActionsHeaderCell>
                            
                                                            </TableRow>
                                                        </StyledTableHead>
                                                        <TableBody>

                                                            {(settings || []).map(row => {
                                                                return (
                                                                    row.group === groupItem 
                                                                        ?  <TableRow key={Math.random()}>
                                                                                <TableCell>
                                                                                    {row.name}
                                                                                </TableCell>     
                                                                                <TableCell>
                                                                                    {
                                                                                        row.type === 'array' 
                                                                                            ? JSON.parse(row.value).join(', ')
                                                                                                : row.type === 'bool'
                                                                                                ? +row.value ? <CheckBoxIcon /> : <span style={{color: '#c55', fontStyle: 'italic'}}>(not set)</span>
                                                                                                    : row.type === 'integer' 
                                                                                                    ? row.value
                                                                                                        : row.type === 'text' 
                                                                                                        ? row.value
                                                                                                            : null
                                                                                    }
                                                                                </TableCell>
                                                                                <ActionsCell>
                                                                                    <StickyBorder>
                                                                                        <ActionsContent>                                                    
                                                                                            <ActionIcon 
                                                                                                onClick={() => {this.handleOpenInfoItem(row.id)}}
                                                                                            >
                                                                                                <RemoveRedEyeIcon />
                                                                                            </ActionIcon>
                                                                                        </ActionsContent>
                                                                                    </StickyBorder>
                                                                                </ActionsCell>
                                                                            </TableRow>
                                                                        : null
                                                                )
                                                            })}

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>                               
                                                <TablePagination
                                                    rowsPerPageOptions={[20]}
                                                    component="div"
                                                    count={settings.filter(itm => itm.group === groupItem).length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={this.handleChangePage}
                                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                />
                                            </StyledPaperTable>    
                                        </WrapSettingItem>   
                                    ) 
                                })
                            }
                            <StyledModal
                                open={openWindowCreate}
                                onClose={this.handleCloseWindowCreate}
                            >
                                <StyledBoxPopup>
                                    <CreateSettingForm 
                                        getChangeValue={this.handleCreate} 
                                        onClose={this.handleCloseWindowCreate}
                                    />
                                </StyledBoxPopup>
                            </StyledModal>
                            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                                {error ?
                                <InfoPopup
                                    severity="error"
                                    headerText="Setting not created"
                                    infoText={errorMessage ? errorMessage : "Something went wrong. Please try again later"}
                                /> :
                                <InfoPopup
                                    severity="success"
                                    headerText="Setting was created"
                                />}
                            </Snackbar>
                        </>
                    }

                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.settingsPPS.isLoading,
        settings: state.settingsPPS.settings,
        error: state.settingsPPS.isError,
        errorMessage: state.settingsPPS.errorMessage,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        settingsPPSRequest,
        settingPPSItemCreate,
    }),
)(MainSettingsPage)
