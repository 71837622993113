import {ActionTypes} from '../reducers/usersOnTheBranch';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_USERS_BRANCH_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_USERS_BRANCH_SUCCESS,
		payload: list
	};
};

export const dropdowmAssignNodes = list => {
	return {
		type: ActionTypes.FETCH_USERS_BRANCH_DROPDOWN_LIST,
		payload: list,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_USERS_BRANCH_FAILURE,
		payload: error
	}
};

export const usersOnTheBranchRequest = data => dispatch => {
	dispatch(requestStart());
	return API.usersOnTheBranch
		.getList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersDropdownAssignedNodesRequest = () => dispatch => {
	return API.usersOnTheBranch
		.dropdownAssignedNodes()
		.then(response => {
			dispatch(dropdowmAssignNodes(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};
