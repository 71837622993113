import {ActionTypes} from '../reducers/report';
import API from '../services/api';
import {format} from 'date-fns';

export const requestStart = () => ({
	type: ActionTypes.FETCH_REPORT_START
});

export const requestSuccess = (queueList) => {
	return {
		type: ActionTypes.FETCH_REPORT_SUCCESS,
		payload: queueList
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_REPORT_FAILURE,
		payload: error
	}
};

export const reportRequest = data => dispatch => {
	dispatch(requestStart());
	return API.report
		.reportList(data)
		.then(res => {
			dispatch(requestSuccess(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const reportsDownload = data => dispatch => {
	return API.report
		.reportDownloadList(data)
		.then(res => {
			const blob = new Blob([res.data], { type: res.headers['content-type'] })
			const downloadUrl = window.URL.createObjectURL(blob)
			const linkUrl = document.createElement('a')
			linkUrl.download = `Report ${format(new Date(), 'yyyy-MM-dd')}.xlsx`
			linkUrl.href = downloadUrl
			document.body.appendChild(linkUrl)
			linkUrl.click()
			document.body.removeChild(linkUrl)
			linkUrl.remove()
		})
		.catch(error => dispatch(requestFailure(error.toString())));
}
