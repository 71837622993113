import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const EmptyDataSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 85 80"
            onClick={onClick}
        >
            <path d="M35.6826 79.5925C55.3896 79.5925 71.3653 76.6075 71.3653 72.9252C71.3653 69.2429 55.3896 66.2578 35.6826 66.2578C15.9757 66.2578 0 69.2429 0 72.9252C0 76.6075 15.9757 79.5925 35.6826 79.5925Z" fill="#F5F5F7" fillOpacity="0.8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M64.2283 53.3383L51.6362 37.841C51.032 37.1115 50.1488 36.6699 49.2188 36.6699H22.1451C21.2157 36.6699 20.3325 37.1115 19.7283 37.841L7.13672 53.3383V61.4347H64.2288V53.3383H64.2283Z" fill="#AEB8C2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.8055 16.668H53.5597C54.118 16.668 54.6535 16.8898 55.0483 17.2846C55.4431 17.6794 55.6649 18.2149 55.6649 18.7732V67.9016C55.6649 68.46 55.4431 68.9955 55.0483 69.3903C54.6535 69.7851 54.118 70.0069 53.5597 70.0069H17.8055C17.2471 70.0069 16.7116 69.7851 16.3168 69.3903C15.922 68.9955 15.7002 68.46 15.7002 67.9016V18.7732C15.7002 18.2149 15.922 17.6794 16.3168 17.2846C16.7116 16.8898 17.2471 16.668 17.8055 16.668V16.668Z" fill="#F5F5F7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.4615 21.9062H48.9021C49.1812 21.9062 49.449 22.0172 49.6464 22.2146C49.8438 22.412 49.9547 22.6797 49.9547 22.9589V36.0941C49.9547 36.3733 49.8438 36.6411 49.6464 36.8385C49.449 37.0359 49.1812 37.1468 48.9021 37.1468H22.4615C22.1824 37.1468 21.9146 37.0359 21.7172 36.8385C21.5198 36.6411 21.4089 36.3733 21.4089 36.0941V22.9589C21.4089 22.6797 21.5198 22.412 21.7172 22.2146C21.9146 22.0172 22.1824 21.9062 22.4615 21.9062ZM22.5994 42.861H48.7642C49.0799 42.861 49.3827 42.9864 49.606 43.2097C49.8293 43.4329 49.9547 43.7358 49.9547 44.0515C49.9547 44.3673 49.8293 44.6701 49.606 44.8933C49.3827 45.1166 49.0799 45.242 48.7642 45.242H22.5994C22.2837 45.242 21.9809 45.1166 21.7576 44.8933C21.5343 44.6701 21.4089 44.3673 21.4089 44.0515C21.4089 43.7358 21.5343 43.4329 21.7576 43.2097C21.9809 42.9864 22.2837 42.861 22.5994 42.861ZM22.5994 49.052H48.7642C49.08 49.052 49.3829 49.1775 49.6062 49.4008C49.8295 49.6241 49.955 49.927 49.955 50.2428C49.955 50.5586 49.8295 50.8615 49.6062 51.0848C49.3829 51.3082 49.08 51.4336 48.7642 51.4336H22.5994C22.2836 51.4336 21.9807 51.3082 21.7574 51.0848C21.5341 50.8615 21.4086 50.5586 21.4086 50.2428C21.4086 49.927 21.5341 49.6241 21.7574 49.4008C21.9807 49.1775 22.2836 49.052 22.5994 49.052ZM64.1115 71.9478C63.7036 73.5641 62.271 74.7689 60.5668 74.7689H10.7968C9.09258 74.7689 7.65995 73.5636 7.25258 71.9478C7.17489 71.6398 7.13564 71.3234 7.13574 71.0057V53.3389H20.9873C22.5173 53.3389 23.7505 54.6273 23.7505 56.1915V56.2126C23.7505 57.7762 24.9978 59.0389 26.5278 59.0389H44.8357C46.3657 59.0389 47.6131 57.7647 47.6131 56.2005V56.1941C47.6131 54.6299 48.8463 53.3384 50.3763 53.3384H64.2278V71.0062C64.2278 71.331 64.1873 71.6462 64.1115 71.9478Z" fill="#DCE0E6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M65.8529 17.5221L62.2582 18.9168C62.1658 18.9528 62.0652 18.9619 61.9679 18.9431C61.8706 18.9244 61.7806 18.8786 61.7082 18.811C61.6358 18.7433 61.5839 18.6566 61.5586 18.5608C61.5333 18.465 61.5355 18.3641 61.565 18.2695L62.5845 15.0026C61.2219 13.4532 60.4219 11.5637 60.4219 9.52474C60.4219 4.26421 65.7471 0 72.3166 0C78.8845 0 84.2103 4.26421 84.2103 9.52474C84.2103 14.7853 78.885 19.0495 72.3161 19.0495C69.9329 19.0495 67.714 18.4884 65.8529 17.5221Z" fill="#DCE0E6"/>
            <path d="M77.0014 11.2444C77.8296 11.2444 78.5009 10.5811 78.5009 9.76283C78.5009 8.94458 77.8296 8.28125 77.0014 8.28125C76.1733 8.28125 75.502 8.94458 75.502 9.76283C75.502 10.5811 76.1733 11.2444 77.0014 11.2444Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M69.1298 11.0594H66.1309L67.6561 8.4668L69.1298 11.0594ZM71.004 8.4668H73.6277V11.0594H71.004V8.4668Z" fill="white"/>
        </WrapperTag>
    );
};
