import baseApi from './axiosParams';
const path = 'api/external/timezone';
const pathSetTimezone = 'api/users'

const Timezone = {
	getTimezone: () => {
		return baseApi.get(`${path}`)
	},
	setTimezone: (id, data) => {
		return baseApi.patch(`${pathSetTimezone}/${id}/timezone`, data)
	}
}

export default Timezone;