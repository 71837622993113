import React, {useState, useEffect} from 'react';
import {Button} from '../../../components/button';
import {HasChieldRoute} from './_has-chield-route';
import {ChildRoutes} from '../../../reducers/permission';
import {WrapSearchPanel, ScrolledContainer} from './permission-childs-lists';
import {Input} from '../../../components/input';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

type Props = {
    childRoutes: Array<ChildRoutes>;
    onSaveRoutes: (data: Array<ChildRoutes>) => void;
}

export const RoutesList: React.FC<Props> = ({childRoutes, onSaveRoutes}) => {

    const [routesState, setRoutesState] = useState(childRoutes);

    const handleChange = (code) => {
        const searchItem = (arr) => {
            arr.forEach(item => {
                if(item.code === code) item.checked = !item.checked;
                if(item.child && item.child.length > 0) searchItem(item.child);
            })
        }
        setRoutesState(prev => {
            let changedState = [...prev];
            searchItem(changedState)
            return changedState
        })        
    }

    useEffect(() => {
        setRoutesState(childRoutes)
    }, [childRoutes])

    const handleSave = () => {
        onSaveRoutes(routesState) //...
    }

    const [filter,  setFilter] = useState<string>('');
    const handleChangeFilter = (e) => {
        setFilter(e.target.value)
    }

    const flattedState = (state) => {
        const flatState = [];
        let cnt = 0;
        const searchChild = (st) => {
            st.forEach(el => {
                if(el.code){
                    el.cnt = cnt;
                    flatState.push(el);
                }
                if(el.child && el.child.length > 0){
                    cnt += 1;
                    searchChild(el.child);
                    cnt -= 1;
                }
            });
        }
        searchChild(state)
        return flatState
    }

    const filteredState = (key, state) => {
        return state.filter(el => el.code.toLowerCase().includes(key.toLowerCase()));
    }

    return <>
        <WrapSearchPanel>
            <Input 
                placeholder='Filter'
                value={filter} 
                onChange={handleChangeFilter}
                sx={{
                    maxWidth: 'calc(100% - 100px)',
                    margin: '0px',
                }}
            />
            <Button 
                onClick={handleSave}
            >
                Save
            </Button>
        </WrapSearchPanel>
        <ScrolledContainer>
            <OverlayScrollbarsComponent
                className='overlay-scroll-list'
                options={{
                    scrollbars: { visibility: 'visible' } 
                }}
            >
                <HasChieldRoute chahgeElement={handleChange} items={filteredState(filter, flattedState(routesState))} />
            </OverlayScrollbarsComponent>
        </ScrolledContainer>
    </>
}