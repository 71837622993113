import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import { Global, css } from '@emotion/react'

const CreateWalletFormContainer = styled('div')(({ theme }) => ({
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
})

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

type valueType = {
    id: string, 
    description: string
}

interface PropsType {
    onClose: () => void, 
    getChangeValue: (value: valueType) => void,
}

export const CreateHDBKTypeForm = (props: PropsType) => {
    const {onClose, getChangeValue} = props;
    const [value, setValue] = useState<valueType>({id: '', description: ''});

    const handleChangeForm = (prop) => (e) => {
        setValue(prev => ({...prev, [prop]: e.target.value}))
    }   
    
    const handleClickCreate = () => {
        getChangeValue(value)
    }

    return (
        <CreateWalletFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>Create Hdbk Requests Type</Title>
                <Input label="Id *" sx={{marginTop: '20px'}} 
                    onChange={handleChangeForm('id')}
                    value={value.id}
                />
                <Input label="Description *" sx={{marginTop: '20px'}} 
                    value={value.description}
                    onChange={handleChangeForm('description')}
                />
                <Global
                    styles={css`
                        & .MuiPickersDay-root.Mui-selected {
                            background-color: #38AA72 !important;
                        },
                    `}
                />
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleClickCreate}
                >Create</Button>
            </ButtonsContainer>

        </CreateWalletFormContainer>
    )
}