import React from "react";
import MainActionLogPage from './action-log-main';

export const ActionLog = () => {
    const [view, setView] = React.useState({
        point: 'main',
        id: ''
    })

    return(
        <>
            { view.point === 'main' && <MainActionLogPage setView={setView} />}
        </>
    )
} 

export default ActionLog;