export namespace ActionTypes {
    export const FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS = 'FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS';
    export const FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_LOADING = 'FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_LOADING';
	export const FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_FAILURE = 'FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_FAILURE';
	export const FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_ITEM = 'FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_ITEM';
}

export type itemTDLinks = {
    "@context": string,
    "@id": string,
    "@type": string,
    id: number,
    transaction: {
        "@id": string,
        "@type": string,
        id: string,
        refund: number,
        status: "Cancel" | "Pending" | "Success",
    },
    buyerId: number | string,
    slug: string,
    method: string,
    url: string,
    params: string | [],
    createdAt: number,
}

export type listTDLinksItem = {
    "@id": string,
    "@type": string,
    id: number,
    transaction: {
        "@id": string,
        "@type": string,
        id: string,
        refund: number,
        status: "Cancel" | "Pending" | "Success",
    },
    buyerId: string | number,
    slug: string,
    method: 'get' | 'post' | 'delete' | 'patch' | 'options',
    url: string,
    params: string | [],
    createdAt: number,
}

type listTDLinks = {
	'@context': string;
	'@id': string;
	'hydra:member': listTDLinksItem[];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

type stateTDLinks = {
	systemInfoTempDepositLogs: listTDLinks;
	isLoading: boolean;
	systemInfoTempDepositLogsItem: itemTDLinks;
}

const initialState: stateTDLinks = {
	systemInfoTempDepositLogs: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	isLoading: false,
	systemInfoTempDepositLogsItem: {
		"@context": '',
		"@id": '',
		"@type": '',
		id: 1,
		transaction: {
			"@id": '',
			"@type": '',
			id: '',
			refund: 1,
			status: "Cancel",
		},
		buyerId: '',
		slug: '',
		method: '',
		url: '',
		params: '',
		createdAt: 1559540259,
	},
};

export const systemInfoTempDepositLogs = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
		case ActionTypes.FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS:
			return { 
				...state, 
				systemInfoTempDepositLogs: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_ITEM:
			return { 
				...state, 
				systemInfoTempDepositLogsItem: {
					...payload,
				},
				isLoading: false,
			};			
		default:
			return state;
	}
};

export default systemInfoTempDepositLogs;