import baseApi from './axiosParams';
const path = 'authentication_token';

const Auth = {
	checkAuth: () => {
		return baseApi.get(`${path}`)
	},
    logIn: (body) => {
		return baseApi.post(`${path}`, body)
    }
    // ...
}

export default Auth;