import React, {useEffect} from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NotifyDialog } from '../../components/notify-dialog';
import { Loader } from '../../components/loaders/loader';
import { 
    settingPPSItemRequest,
    settingPPSItemRequestDelete,
} from "../../actions/settingsPPS";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import Dialog from '@mui/material/Dialog';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { view } from './index';
import { SettingItem } from "../../reducers/settingsPPS"; 

const PageContainer = styled('div')(({theme}) => (` 
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxView = styled(Box)(({theme}) => (`
    // border: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;  
    .MuiTableContainer-root{
        border-radius: 0;
    }
    tr{
        &:last-child{
            td{
                border-bottom: none;
            }
        }
    }   
`));

const TableCellTitle = styled(TableCell)(({theme}) => (`
    font-weight: bold;
    min-width: 150px;
    width: 250px;
`));

const StyledDialog = styled(Dialog)(({ theme }) => (`
    &{
        .MuiDialog-paper{
            border-radius: 0;
        }
    }
`));

const WrappBox = styled(Box)(({theme}) => (`
    margin-bottom: 40px;
    overflow: auto;
    border: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

interface PropsType {
    settingsInfo: SettingItem,
    isLoading: boolean,
    errorMessage: string,
    error: boolean,
    settingPPSItemRequest: (id: number) => void,
    settingPPSItemRequestDelete: (id: number) => any,
    setView: any,
    view: view,
}

export const ViewSettingsPage: React.FC<PropsType> = (props) => {
    const [openDeleteWindow, setOpenDeleteWindow] = React.useState(false)

    const {
        settingPPSItemRequestDelete,
        settingPPSItemRequest,
        settingsInfo,
        isLoading,
        setView,
        view,
        error,
        errorMessage
    } = props;

    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };

    useEffect(() => {
        settingPPSItemRequest(view.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = (way) => () => {
        setView((prev) => {
            return {
                ...prev,
                point: way,
            }
        })
    }

    const handleClickOpenWindowDelete = () => {
        setOpenDeleteWindow(true)
    }
    const handleClickCloseWindowDelete = () => {
        setOpenDeleteWindow(false)
    }

    const handleDelete = () => {
        settingPPSItemRequestDelete(view.id).then(() => handleOpenSnackbar())
        setView((prev) => {
            return {
                point: 'main',
                id: null,
            }
        })
    }

    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation('main')}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            Setting: {`${settingsInfo.group} - ${settingsInfo.name}`}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
                <ShortViewHeaderRight>
                    <Button onClick={handleClickNavigation('edit')}>
                        <EditIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        edit
                    </Button>
                    <Button isDeleteButton={true} onClick={handleClickOpenWindowDelete}>
                        <DeleteIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        delete
                    </Button>
                </ShortViewHeaderRight>
            </ShortViewHeader>

            <WrappBox>
                <BoxView sx={{ width: '100%', typography: 'body1' }}>
                    <TableContainer component={Paper} sx={{minWidth: '900px'}}>
                        <Table>   
                            <TableBody>
                                <TableRow>
                                    <TableCellTitle>ID</TableCellTitle>
                                    <TableCell>{view.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Settings group</TableCellTitle>
                                    <TableCell>{settingsInfo.group}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Name</TableCellTitle>
                                    <TableCell>{settingsInfo.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Type</TableCellTitle>
                                    <TableCell>{settingsInfo.type}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Setting Value</TableCellTitle>
                                    <TableCell>
                                        {
                                            settingsInfo.type === 'array' 
                                                ? JSON.parse(settingsInfo.value).join(', ')
                                                    : settingsInfo.type === 'bool'
                                                    ? settingsInfo.value ? <CheckBoxIcon /> : <span style={{color: '#c55', fontStyle: 'italic'}}>(not set)</span>
                                                        : settingsInfo.type === 'integer' 
                                                        ? +settingsInfo.value
                                                            : settingsInfo.type === 'text' 
                                                            ? settingsInfo.value
                                                            : null
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BoxView>
            </WrappBox>

            <StyledDialog
                open={openDeleteWindow}
                onClose={handleClickCloseWindowDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NotifyDialog
                    title={`Delete setting ${settingsInfo.group} - ${settingsInfo.name} ?`}
                    confirmButtonText='YES, DELETE'
                    cancelButtonText='CANCEL'
                    onClose={handleClickCloseWindowDelete}
                    width="400px"
                    isDeleteButton
                    onCancel={handleClickCloseWindowDelete}
                    onConfirm={handleDelete}
                >
                    Are you sure you want to delete this setting?
                </NotifyDialog>  
            </StyledDialog>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                {error ?
                <InfoPopup
                    severity="error"
                    headerText="Setting not deleted"
                    infoText={errorMessage ? errorMessage : "Something went wrong. Please try again later"}
                /> :
                <InfoPopup
                    severity="success"
                    headerText="Setting was deleted"
                />}
            </Snackbar>

            {isLoading && <Loader/>} 
            
        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        settingsInfo: state.settingsPPS.settingItem,
        isLoading: state.settingsPPS.isLoading,
        errorMessage: state.settingsPPS.errorMessage,
        error: state.settingsPPS.isError,
    }), {
        settingPPSItemRequest,
        settingPPSItemRequestDelete,
    }),
)(ViewSettingsPage)