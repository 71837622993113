import React from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import { Loader } from '../../components/loaders/loader';
import Menu from '@mui/material/Menu';
import { TableFilter } from "../../components/table-filter";
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import { PlusSvg } from '../../components/svg/plus-svg';
import { Status } from '../../components/status';
import Box from '@mui/material/Box';
import {ChangePasswordForm} from './user-change-password';
import {CreateUserForm} from './create-user-form'
import Dialog from '@mui/material/Dialog';
import {
    usersAllgRequest,
    createItemRequest,
    changePasswordRequest,
} from "../../actions/usersAll";
import {usersRolesRequest} from '../../actions/usersRoles';
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { StatusActive } from '../../components/status-active';
import { Link } from 'react-router-dom';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Tooltip from '@mui/material/Tooltip';
import { AccessDeniedAlert } from "../../components/access-denied";

const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px 16px 0 16px',
        height: '100%',
    }
}));

const UsersPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',   
    }
});

const UsersPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UsersPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width:991px)': {
        width: '100%',
        marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const UsersPageHeaderRight = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

// const StyledTableCell120= styled(TableCell)(({ theme }) => (`
//     min-width: 120px;
//     width: 120px;
// `));

const TableCellContentWith120 = styled('div')(`
    display: flex;
    align-items: center;
    min-width: 120px;
`);

const TableCellContentWith160 = styled('div')(`
    display: flex;
    align-items: center;
    min-width: 160px;
`);

const TableCellContentWith200 = styled('div')(`
    display: flex;
    align-items: center;
    min-width: 225px;
`);

const IconWrapper = styled('div')({
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const StyledTableRow = styled(TableRow)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
`));

const LoginContent = styled(Link)(({ theme }) => (`
    text-decoration: underline;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

const StyledButton = styled(Button)({
    whiteSpace: 'nowrap',
});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledDialogEdit = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        margin: 15px;
        margin-top:0;
        margin-bottom:0;
        max-height: 100%;
        border-radius: 0;
    }
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 190px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}))

const filterSuperadminItems = [{title: 'Yes', value: 1}, {title: 'No', value: 0},];

const filterEmailConfirmedItems = [{title: 'Yes', value: 1}, {title: 'No', value: 0},];

const filterStatusItems = [{title: 'Active', value: 1}, {title: 'Inactive', value: 0}, {title: 'Banned', value: -1}];

export class UsersAllPage extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            openSnackbar: false,
            anchorElSuperadmin: null,
            isOpenChangePassword: false,
            anchorElRole: null,
            anchorElStatus: null,
            anchorElAssignments: null,
            isOpenCreateForm: false,
            page: 0,
            rowsPerPage: 20,
            login: '',
            note: '',
            email: '',
            regisrtationIp: '',
            statuses: [],
            isSuperadmin: [],
            emailConfirmed: [],
            roles: [],
            userIdForPasswordChange: 0,
        }
    };
    
    handleClearFilters = () => {
        this.setState({
            page: 0,
            //...
        }, () => this.updateData())
    }

    handleRoleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElRole: event.currentTarget});
    };
    handleRoleMenuClose = () => {
        this.setState({anchorElRole: null});
    };
    onChangeFilterIsRoles = (data) => {
        this.setState({roles: data}, () => {this.updateData()});
        this.handleRoleMenuClose()
    }

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget})
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleSuperadminMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElSuperadmin: event.currentTarget})
    };
    handleSuperadminMenuClose = () => {
        this.setState({anchorElSuperadmin: null});
    };

    handleConfirmedMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElConfirmed: event.currentTarget})
    };
    handleConfirmedMenuClose = () => {
        this.setState({anchorElConfirmed: null});
    };

    handleCreateFormOpen = () => this.setState({isOpenCreateForm: true});
    handleCreateFormClose = () => this.setState({isOpenCreateForm: false});

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleClickOpenWindowDelete = () => {
        this.setState({openDeleteWindow: true})
    }
    handleClickCloseWindowDelete = () => {
        this.setState({openDeleteWindow: false})
    }
    handleOpenDeleteDialog = (id) => {
        this.setState({deleteId: id}, () => this.handleClickOpenWindowDelete())
    }
    onChangeFilter = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
    };

    handleOpenPermissionItem = (id: number) => {
        const {setView} = this.props
        setView((prev) => ({id: id, point: 'permission'}))
    }

    onChangeFilterStatuses = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
        this.handleStatusMenuClose()
    };

    onChangeFilterIsSuperadmin = (data) => {
        this.setState({isSuperadmin: data}, () => {this.updateData()});
        this.handleSuperadminMenuClose()
    };

    onChangeFilterIsConfirmedEmail = (data) => {
        this.setState({emailConfirmed: data}, () => {this.updateData()});
        this.handleConfirmedMenuClose()
    };

    handleOpenSnackbar = () => {
        this.setState({openSnackbar: true});
    };

    handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        this.setState({openSnackbar: false});
    };

    handleCreate = (values) => {
        const {createItemRequest} = this.props
        this.handleCreateFormClose()

        values.emailConfirmed ? values.emailConfirmed = 1 : values.emailConfirmed = 0
        
        createItemRequest(values).then(() => this.handleOpenSnackbar())
    }

    componentDidMount() {
        const {
            usersRolesRequest,
            routsUser, 
            userRoles,
            isSuperadminCurrentUser,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/user-management/user/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadminCurrentUser){
            this.updateData();
            usersRolesRequest('');
        }
    }

    updateData = () => {

        const {
            page,
            login,
            note,
            email,
            regisrtationIp,
            statuses,
            isSuperadmin,
            emailConfirmed,
            roles,
        } = this.state;

        const {
            usersAllgRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            'username': login,
            'roleNote': note,
            'email': email,
            'registrationIp': regisrtationIp,
            'status[]': statuses,
            'superadmin[]': isSuperadmin,
            'emailConfirmed[]': emailConfirmed,
            'role[]': roles,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });
        
        usersAllgRequest(data);
    }

    handleChangePasswordModalOpen = () => {
        this.setState({isOpenChangePassword: true})
    };
    handleCheckPasswordModalClose = () => {
        this.setState({isOpenChangePassword: false})
    };

    handleChangePassword = (userId) => {
        this.setState({userIdForPasswordChange: userId})
        this.handleChangePasswordModalOpen();
    }

    handleSavePassword = (id, data) => {
        const {changePasswordRequest} = this.props;
        changePasswordRequest(id, JSON.stringify({'password': data}));
        this.handleCheckPasswordModalClose();
        this.setState({userIdForPasswordChange: 0});
    }

    render() {

        const {
            isLoading,
            usersList,
            ListCount,
            error,
            errorMessage,
            rolesList,
            routsUser, 
            userRoles,
            isSuperadminCurrentUser,
            // changePasswordRequest,
        } = this.props

        const {
            page,
            rowsPerPage,
            anchorElSuperadmin,
            anchorElConfirmed,
            isOpenChangePassword,
            isOpenCreateForm,
            anchorElRole,
            anchorElStatus,
            statuses,
            isSuperadmin,
            emailConfirmed,
            openSnackbar,
            login,
            note,
            email,
            regisrtationIp,
            roles,
            // ....,
        } = this.state;

        let iteratorNumber = 1;

        let rolesFilter = [];
        try {
            rolesFilter = rolesList.map((item) => {
                return {
                    title: item.description,
                    value: item.name,
                }
            })           
        } catch (error) {}

        const accessedRole = routsUser.some(rout => rout.child.includes('/user-management/user/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadminCurrentUser;
        
        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <UsersPageHeader>
                                <UsersPageHeaderLeft>
                                    <UsersPageHeaderText>Users</UsersPageHeaderText>
                                </UsersPageHeaderLeft>
                                <UsersPageHeaderRight>
                                    <Button 
                                        startIcon={<PlusSvg width="22px" />} 
                                        onClick={this.handleCreateFormOpen}
                                    >
                                        Create
                                    </Button>
                                </UsersPageHeaderRight>
                            </UsersPageHeader>
                
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <TableCell>№</TableCell>


                                                    <TableCellWithActiveClass
                                                        className={ isSuperadmin.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContentWith120>
                                                            Superadmin
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleSuperadminMenuClick}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElSuperadmin}
                                                                open={Boolean(anchorElSuperadmin)}
                                                                onClose={this.handleSuperadminMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter 
                                                                    filterItems={filterSuperadminItems}
                                                                    initialData={isSuperadmin}
                                                                    onApplyFilter={this.onChangeFilterIsSuperadmin} 
                                                                    onClearAll={this.onChangeFilterIsSuperadmin}
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContentWith120>
                                                    </TableCellWithActiveClass>
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Login' 
                                                        handleSearchSubmit={this.handleSearchSubmit('login')}
                                                        handleCancelSearch={this.handleCancelSearch('login')}
                                                        isActiveFilter={Boolean(login)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Note' 
                                                        handleSearchSubmit={this.handleSearchSubmit('note')}
                                                        handleCancelSearch={this.handleCancelSearch('note')}
                                                        isActiveFilter={Boolean(note)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        label='E-mail' 
                                                        handleSearchSubmit={this.handleSearchSubmit('email')}
                                                        handleCancelSearch={this.handleCancelSearch('email')}
                                                        isActiveFilter={Boolean(email)}
                                                    />

                                                    <TableCellWithActiveClass
                                                        className={ emailConfirmed.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContentWith160>
                                                            Email confirmed
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleConfirmedMenuClick}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElConfirmed}
                                                                open={Boolean(anchorElConfirmed)}
                                                                onClose={this.handleConfirmedMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter 
                                                                    initialData={emailConfirmed}
                                                                    onApplyFilter={this.onChangeFilterIsConfirmedEmail} 
                                                                    onClearAll={this.onChangeFilterIsConfirmedEmail}
                                                                    filterItems={filterEmailConfirmedItems}
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContentWith160>
                                                    </TableCellWithActiveClass>

                                                    <TableCellWithActiveClass
                                                        className={ roles.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContentWith200>
                                                            Roles
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleRoleMenuClick}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElRole}
                                                                open={Boolean(anchorElRole)}
                                                                onClose={this.handleRoleMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter 
                                                                    initialData={roles}
                                                                    onApplyFilter={this.onChangeFilterIsRoles} 
                                                                    onClearAll={this.onChangeFilterIsRoles}
                                                                    filterItems={rolesFilter}
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContentWith200>
                                                    </TableCellWithActiveClass>

                                                    <WithSortedSerachTableHeader 
                                                        label='Registration IP' 
                                                        handleSearchSubmit={this.handleSearchSubmit('regisrtationIp')}
                                                        handleCancelSearch={this.handleCancelSearch('regisrtationIp')}
                                                        isActiveFilter={Boolean(regisrtationIp)}
                                                    />

                                                    <TableCell></TableCell>

                                                    <TableCell></TableCell>

                                                    <TableCellWithActiveClass
                                                        className={ statuses.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContentWith200>
                                                            Status
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleStatusMenuClick}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElStatus}
                                                                open={Boolean(anchorElStatus)}
                                                                onClose={this.handleStatusMenuClose}
                                                            >
                                                                <TableFilter 
                                                                    filterItems={filterStatusItems}
                                                                    initialData={statuses}
                                                                    onApplyFilter={this.onChangeFilterStatuses} 
                                                                    onClearAll={this.onChangeFilterStatuses}
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContentWith200>
                                                    </TableCellWithActiveClass>
                
                                                    <ActionsHeaderCell>
                                                        <StickyBorder>Action</StickyBorder>
                                                    </ActionsHeaderCell>
                
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(usersList || []).map(row => {
                                                    return (
                                                        <StyledTableRow key={Math.random()}>
                                                            <TableCell>
                                                                {
                                                                    (page*rowsPerPage) + iteratorNumber++
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <StatusActive active={row.superadmin} textOn='Yes' textOff='No'/>
                                                            </TableCell>
                                                            <TableCell>
                                                                <LoginContent 
                                                                    to={`/user/${row.id}`}
                                                                >
                                                                    {row.username}
                                                                </LoginContent>
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.roleNote ? row.roleNote : ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.email ? row.email : ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                <StatusActive active={row.emailConfirmed} textOn='Yes' textOff='No'/>
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    (row.role || []).map(item => {
                                                                        return <div key={Math.random()}>{`${item.name}  `}</div>
                                                                    })
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.registrationIp}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link to={`/user/user-permissions/${row.id}`}>
                                                                    <StyledButton 
                                                                        size='small'
                                                                        disabled={ row.status === 'banned' ? true : false }
                                                                    >
                                                                        Roles and permissions
                                                                    </StyledButton>
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <StyledButton 
                                                                    onClick={(e) => this.handleChangePassword(row.id)}
                                                                    size='small'
                                                                    disabled={ row.status === 'banned' ? true : false }
                                                                >
                                                                    Change password
                                                                </StyledButton>
                                                            </TableCell>
                                                            <TableCell>
                                                                {<Status status={row.status}/>}
                                                            </TableCell>
                                                            <ActionsCell>
                                                                <StickyBorder>
                                                                    <ActionsContent>   
                                                                        <Tooltip title='Details' placement="top">
                                                                            <ActionIcon>
                                                                                <LoginContent 
                                                                                    to={`/user/${row.id}`}
                                                                                >
                                                                                    <RemoveRedEyeIcon />
                                                                                </LoginContent>
                                                                            </ActionIcon>
                                                                        </Tooltip>
                                                                    </ActionsContent>
                                                                </StickyBorder>
                                                            </ActionsCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>                               
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={ListCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>

                            <ChangePasswordForm 
                                userId={this.state.userIdForPasswordChange}
                                isOpen={isOpenChangePassword}
                                handleClose={this.handleCheckPasswordModalClose}
                                handleSavePassword={this.handleSavePassword}
                            />

                            <StyledDialogEdit
                                open={isOpenCreateForm}
                                onClose={this.handleCreateFormClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <StyledBoxPopup>
                                    <CreateUserForm 
                                        getChangeValue={this.handleCreate}
                                        onClose={this.handleCreateFormClose}
                                    />
                                </StyledBoxPopup>
                            </StyledDialogEdit>

                            <Snackbar 
                                open={openSnackbar} 
                                autoHideDuration={3000} 
                                onClose={this.handleCloseSnackbar} 
                                anchorOrigin={{
                                    vertical: "bottom", 
                                    horizontal: "right"
                                }}
                            >
                                {error ?
                                    <InfoPopup
                                        severity="error"
                                        headerText="User not created"
                                        infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                                    /> :
                                    <InfoPopup
                                        severity="success"
                                        headerText={`User was created`}
                                    />
                                }
                            </Snackbar>
                        </>
                    }

                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.usersAll.isLoading,
        usersList: state.usersAll.usersList['hydra:member'],
        ListCount: state.usersAll.usersList['hydra:totalItems'],
        error: state.usersAll.isError,
        errorMessage: state.usersAll.errorMessage,
        rolesList: state.roles.roles['hydra:member'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadminCurrentUser: state.currentUser.userData.superadmin,
    }), {
        usersAllgRequest,
        createItemRequest,
        usersRolesRequest,
        changePasswordRequest,
    }),
)(UsersAllPage)
