import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const AccountBalanceSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 24 24"
            onClick={onClick}
        >
            <path d="M7 10H4V17H7V10Z" fill={fill}/>
            <path d="M13.5 10H10.5V17H13.5V10Z" fill={fill}/>
            <path d="M22 19H2V22H22V19Z" fill={fill}/>
            <path d="M20 10H17V17H20V10Z" fill={fill}/>
            <path d="M12 1L2 6V8H22V6L12 1Z" fill={fill}/>
        </WrapperTag>
    );
};

AccountBalanceSvg.defaultProps = {
    fill: '#575D68',
};
