import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../../components/svg/close-svg';
import {Input} from '../../../components/input';
import {Button} from '../../../components/button';
import { FieldsListPermissions } from './fields-lists-permission';


const EditUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const WrapTabContentColumns = styled('div')(({ theme }) => (`
    &{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }
`));

const WrapTabContentItem = styled('div')(({ theme }) => (`
    &{
        // padding-right: 30px;
        box-sizing: border-box;
        width: 100%;
        @media screen and (max-width: 991px){
            width: 100%;
        }
    }
`));


type UserItem = {
    '@context': string; 
    '@id': string;
    '@type': string;
    createdAt: string;
    nodes: [];
    roleNote: string;
    roles: [];
    username: string;
}

type Props = {
    onClose: () => void;
    initialState: UserItem;
    onSave: (data: any) => void;
    roles: Array<any>;
}

export const EditUserForm = (props: Props) => {
    const {onSave, onClose, initialState, roles} = props;

    const [values, setValues] = React.useState(initialState);

    const handleChangeInput = (key) => (event) => {
        setValues((prev) => {
            let value = event.target.value;
            return {...prev, [key]: value,}
        })
    }

    const [rolesState, setRolesState] = useState(roles.map(item => ({...item, name: item.code, description: item.name})));
    useEffect(() => {
        setRolesState(roles.map(item => ({...item, name: item.code, description: item.name})))
    }, [roles]);

    const handleChangeRoles = (data) => {
        setRolesState(prev => {
            let changedState = [...prev];
            changedState.forEach(item => {
                if(item.name === data){
                    item.checked = !item.checked
                }
            });
            return changedState;
        })
    }

    const handleSave = () => {
        let rolesTrue = []
        rolesState.forEach(item => {
            if(item.checked) rolesTrue.push(item.name)
        })
        const result = {
            roleNote: values.roleNote,
            username: values.username,
            roles: rolesTrue,
        }
        onSave(result);
    }

    return (
        <EditUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Edit user {initialState.username}
                </Title>

                <Input 
                    label="Login *" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={values.username}
                    onChange={(e) => handleChangeInput('username')(e)}
                />

                <Input 
                    label="Note" 
                    size="small" 
                    sx={{marginTop: '20px'}}
                    value={values.roleNote}
                    onChange={(e) => handleChangeInput('roleNote')(e)}
                />

                <WrapTabContentColumns>
                    <WrapTabContentItem>
                        <FieldsListPermissions 
                            childFieldss={rolesState} 
                            onChangeRoles={handleChangeRoles}
                        />
                    </WrapTabContentItem>
                </WrapTabContentColumns>

            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleSave}
                >
                    SAVE
                </Button>
                <Button 
                    variant="outlined"
                    onClick={onClose}
                >
                    CANCEL
                </Button>
            </ButtonsContainer>
        </EditUserFormContainer>
    );
};
