export namespace ActionTypes {
    export const FETCH_HDBK_START = 'FETCH_HDBK_START';
    export const FETCH_HDBK_SUCCESS = 'FETCH_HDBK_SUCCESS';
    export const FETCH_HDBK_DELETE = 'FETCH_HDBK_DELETE';
    export const FETCH_HDBK_FAILURE = 'FETCH_HDBK_FAILURE';
    export const FETCH_HDBK_ITEM_SUCCESS = 'FETCH_HDBK_ITEM_SUCCESS';
};

export type hdbkItem = {
    "@context": string,
    "@id": string,
    "@type": string,
    id: number,
    text: string,
    createdAt: string,
}

export type hdbkListItem = {
	"@id": string,
	"@type": string,
	id: number,
	text: string,
	createdAt: string,
}

type hdbkList = {
	'@context': string;
	'@id': string;
	'hydra:member': Array<hdbkListItem>;
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

interface Statements {
	isError: boolean,
    isLoading: boolean,
	hdbkList: hdbkList,
	errorMessage: string,
	hdbkItem: hdbkItem,
}

export const initialState: Statements = {
	isError: false,
    isLoading: false,
	hdbkList: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',	
	},
	errorMessage: '',
	hdbkItem: {
		"@context": '',
		"@id": '',
		"@type": '',
		id: 0,
		text: '',
		createdAt: '',
	}
};

export const hdbkRequestType = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_HDBK_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_HDBK_SUCCESS:
			return {
				...state,
				hdbkList: payload,
				isLoading: false,
				errorMessage: false,
				isError: false
			};
		case ActionTypes.FETCH_HDBK_ITEM_SUCCESS:
			return {
				...state,
				hdbkItem: payload,
				isLoading: false,
				errorMessage: false,
				isError: false
			};
		case ActionTypes.FETCH_HDBK_DELETE:
			return {
				...state,
			};			
		case ActionTypes.FETCH_HDBK_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
