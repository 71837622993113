import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles';

import { createContext } from "react";

import {darkTheme} from './dark'
import {lightTheme} from './light'

interface AppContextInterface {
	theme: boolean,
	setTheme: any,
}
  
export const ThemeCtx = createContext<AppContextInterface | null>(null)

if( !localStorage.getItem('localTheme') ){
	localStorage.setItem("localTheme", "lightTheme")
}

const ThemeApp = (props) => {

	const [theme, setTheme] = useState<boolean>( localStorage.getItem('localTheme') === "lightTheme" ? true : false )
	
	const themeAppContext: AppContextInterface = {
		theme: theme,
		setTheme: setTheme,
	};

	return (
		<ThemeCtx.Provider value={themeAppContext}>
			<ThemeProvider theme={ theme ? lightTheme : darkTheme}>	
				{props.children}
			</ThemeProvider>
		</ThemeCtx.Provider>
	);
}

export default ThemeApp;