import {ActionTypes} from '../reducers/systemInfoBuierLog';
import API from '../services/api';

const sysInfiBuyerLogStart = () => ({
    type: ActionTypes.FETCH_SYS_INFO_BUIER_LOG_LOADING
});

const fetchSysInfiBuyerLog = (data) => ({
    type: ActionTypes.FETCH_SYS_INFO_BUIER_LOG,
    payload: data
});

const fetchSysInfiBuyerLogItem = (data) => ({
    type: ActionTypes.FETCH_SYS_INFO_BUIER_LOG_ITEM,
    payload: data
});

const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_SYS_INFO_BUIER_LOG_FAILURE,
		payload: error
	}
};

export const sysInfiBuyerLogRequest = (params) => dispatch => {
    dispatch(sysInfiBuyerLogStart())
    return API.systemInfoBuyerLog.getSistemInfoBuyerLog(params)
    .then(response => {
        dispatch(fetchSysInfiBuyerLog(response.data))
    })
    .catch(error => dispatch(requestFailure(error.toString())));
};

export const sysInfiBuyerLogItemRequest = (params) => dispatch => {
    dispatch(sysInfiBuyerLogStart())
    return API.systemInfoBuyerLog.getSistemInfoBuyerItemLog(params)
    .then(response => {
        dispatch(fetchSysInfiBuyerLogItem(response.data))
    })
    .catch(error => dispatch(requestFailure(error.toString())));
};
