import React from 'react';
import styled from "@emotion/styled";
import { StatusErrorSvg } from '../svg/status-error-svg';
import { StatusOkSvg } from '../svg/status-ok-svg';

const ActiveOn = styled('span')({
    border: '1px solid #B7EB8F',
    backgroundColor: '#F6FFED',
    color: '#52C41A',
    fontSize: '12px',
    lineHeight: '12px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    gap: '8px',
    padding: '0px 7px',
    borderRadius: '8px',
    height: '22px'
});

const ActiveOff = styled('span')({
    border: '1px solid rgb(204, 85, 85)',
    backgroundColor: 'rgb(204, 85, 85, .1)',
    color: 'rgb(204, 85, 85)',
    fontSize: '12px',
    lineHeight: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '0px 7px',
    borderRadius: '8px',
    height: '22px',
    width: 'fit-content'
});

type Props = {
    active: boolean;
    textOn?: string,
    textOff?: string,
}

export const StatusActive = ({active, textOn = 'On', textOff = 'Off'}: Props) => {
    let StatusContainer;

    active ? StatusContainer = ActiveOn : StatusContainer = ActiveOff

    return (
        <StatusContainer>
            {active ? <StatusOkSvg size="12px" fill='#52C41A'/> : <StatusErrorSvg size="12px" fill='rgb(204, 85, 85)'/>} 
            {active ? textOn : textOff}           
        </StatusContainer>
    )
}