import {actionTypes} from '../reducers/logViewer';
import API from '../services/api';

const setLogsStart = () => ({
    type: actionTypes.SET_LOGS_LOADING
});

const setLogs = (data) => ({
    type: actionTypes.SET_LOGS,
    payload: data
});

export const logsRequest = (params) => dipatch => {
    dipatch(setLogsStart())
    return API.logViewer.getLogs(params)
    .then(response => {
        dipatch(setLogs(response.data))
    })
    .catch(error => console.log(error));
};
