import React, {Component} from "react";
import {styled} from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {DateRangePicker} from '../../components/date-range-picker';
import TablePagination from '@mui/material/TablePagination';
import {
    requestElasticSearch,
    requestOptionsList,
} from '../../actions/requestElasticSearch';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {Loader} from '../../components/loaders/loader';
// import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import {Input} from '../../components/input';
import {format} from 'date-fns';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {JsonDataComponent} from './json-data-component';
import { ElasticListItem } from "../../reducers/requestElasticSearch";
import { RequestTypesItem } from "../../reducers/requestElasticSearch";

import FilterList from './filter-dropdown-list';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => (`
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const QueuePageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    @media(max-width: 768px){
        flex-wrap: wrap;
        margin-bottom: 0px;
    }
    button{
        svg{
            margin-right: 8px;
        }
    }
`);

const QueuePageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 24px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const QueuePageHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 991px){
        width: 100%;
    }
    @media(max-width: 768px){
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
    }
`);

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default}; 
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const StyledTableCellNoBreack = styled(TableCell)(({ theme }) => (`
    word-break: break-all;
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    // border-bottom: none;
`));

// const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
//     &:hover{
//         background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
//     }
//     &.Mui-selected{
//         background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
//         &:hover{
//             background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
//         };
//         &.Mui-focusVisible{
//             background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
//         };
//     }
// `));

const RangePickerWrapper = styled('div')(`
    width: calc(25% - 16px*3/4);
    margin-top: 16px;
    @media(max-width: 1200px){
        width: calc(50% - 16px/2);
    }  
    @media(max-width: 768px){
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
    }    
`);

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    boxSizing: 'border-box',
    width: 'calc(100%/4 - 16px*3/4)',
    marginTop: '16px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },
    '@media(max-width: 1200px)' : {
        width: 'calc(50% - 16px/2)',
    },
    '@media(max-width: 1450px)' : {
        maxWidth: '100%',
    },
    '@media(max-width: 768px)' : {
        width: '100%',
    }
}));

const StyledInput = styled(Input)({
    marginTop: '16px',
    marginBottom: '0',
    width: 'calc(100%/4 - 16px*3/4)',
    '@media(max-width: 1200px)' : {
        width: 'calc(50% - 16px/2)',
    },
    '@media(max-width: 768px)' : {
        width: '100%',
        marginTop: '16px',
        marginRight: '0px',
    }
})

const PageFirstRow = styled('div')({
    marginTop: '24px',
    marginBottom: '32px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

const WrapButton = styled('div')(`
    width: 100%;
    margin-top: 16px;
`);

const Wrap200Width = styled('div')(`
    min-width: 200px;
`);

const Wrap170Width = styled('div')(`
    width: 170px;
`);

const Wrap100Width = styled('div')(`
    width: 100px;
`);

// const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
//     .MuiPaper-root{
//         border-radius: 0;
//         box-sizing: border-box;
//         box-shadow: none;
//     }
// `));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 325px - 56px);
        @media(max-width: 1200px){
            max-height: calc(100vh - 385px - 56px);
        }; 
        @media(max-width: 767px){
            max-height: calc(100vh - 495px - 56px);
        }; 
    }
    .copy-to-clipboard-container{
        display: none !important;
    }
`));

type dataType = {
    page: number,
    type?: string,
    data?: string,
    transactionId?: string,
    dateRange?: string,
}

interface PporType {
    isLoading: boolean,
    listElasticRequest: Array<ElasticListItem>,
    elasticPageCount: number,
    typesRequest: Array<RequestTypesItem>,
    requestElasticSearch: (data: dataType) => void,
    requestOptionsList: () => void,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: any,
}

interface StateType {
    page: number,
    rowsPerPage: number,
    type: string,
    transaction_id: string,
    data: string,
    startDate: string,
    endDate: string,
    clearDates: boolean,
    currentType: string,
}


export class RequestElasticSearch extends Component<PporType, StateType> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            type: '',
            transaction_id: '',
            data: '',
            //...
            startDate: '',
            endDate: '',
            clearDates: true,
            currentType: 'Type',
        }
    };

    handleChangeFilterType = (item) => {
        this.setState({currentType: item.text, type: item.id});
    }

    handleChangeFilterData = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({data: event.target.value})
    };

    handleChangeFilterTransactionId = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({transaction_id: event.target.value})
    };

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({page: newPage}, () => this.updateData())
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    setDates = (startDate, endDate) => {
        this.setState({startDate: startDate, endDate: endDate, clearDates: false});
    };

    onConfirmSelectedDates = () => {
        this.updateData();
    };

    onResetSelectedDates = () => {
        this.setState({
            startDate: '', 
            endDate: '', 
            clearDates: true,
            type: '',
            data: '',
            transaction_id: '',
            currentType: 'Type',
        }, () => {this.updateData()});
    };

    componentDidMount() {
        const {requestOptionsList, routsUser, userRoles, isSuperadmin} = this.props;
        if(routsUser.some(rout => rout.child.includes('/requests/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            requestOptionsList()
            this.updateData();
        }
    }

    updateData = () => {
        const {
            page,
            type,
            transaction_id,
            data,
            startDate,
            endDate,
        } = this.state;

        const {
            requestElasticSearch,
        } = this.props;

        const dataQuery = {
            'page': page + 1,
            'type': type,
            'data': data,
            'transactionId': transaction_id,
            dateRange: startDate && endDate ? `${format(new Date(startDate), 'yyyy-MM-dd')} - ${format(new Date(endDate), 'yyyy-MM-dd')}` : '',
        };

        Object.keys(dataQuery).forEach(key => {
            if (!dataQuery[key]) delete dataQuery[key];
        });

        requestElasticSearch(dataQuery);
    }

    render() {

        const {
            isLoading,
            typesRequest,
            elasticPageCount,
            listElasticRequest,
            routsUser,
            userRoles,
            isSuperadmin,
        } = this.props;
    
        const {
            page,
            rowsPerPage,
            clearDates,
            // type,
            data,
            transaction_id,
            currentType,
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child.includes('/requests/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole && 
                <>
                    <QueuePageHeader>
                        <QueuePageHeaderLeft>
                            <QueuePageHeaderText>
                                Requests
                            </QueuePageHeaderText>
                        </QueuePageHeaderLeft>
                    </QueuePageHeader>  

                    <PageFirstRow>
                        <RangePickerWrapper>
                            <DateRangePicker label="Created At" setDates={this.setDates} clearDates={clearDates}/>
                        </RangePickerWrapper>

                        <StyledFormControl>
                            <FilterList 
                                currentValue={currentType}
                                list={typesRequest}
                                changeCurrentValue={this.handleChangeFilterType}
                                defaultText={'Type'}
                            />
                        </StyledFormControl>

                        <StyledInput size="small" label="Transaction ID" value={transaction_id} onChange={this.handleChangeFilterTransactionId}></StyledInput>

                        <StyledInput size="small" label="Data" value={data} onChange={this.handleChangeFilterData}></StyledInput>

                        <WrapButton>
                            <Button sx={{marginRight: '12px'}} onClick={this.onConfirmSelectedDates}>search</Button>
                            <Button variant="outlined" onClick={this.onResetSelectedDates}>reset</Button>
                        </WrapButton>
                    </PageFirstRow>

                    <StyledTableContainer>
                        <StyledPaperTable>
                            <TableContainer component={Paper}>

                            <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >

                                <Table>
                                    <StyledTableHead>
                                        <TableRow>

                                            <TableCell>
                                                <Wrap170Width>
                                                    Created At
                                                </Wrap170Width>
                                            </TableCell>

                                            <TableCell>
                                                <Wrap170Width>
                                                    Inserted At
                                                </Wrap170Width>
                                            </TableCell>

                                            <TableCell>
                                                <Wrap100Width>
                                                    Type
                                                </Wrap100Width>
                                            </TableCell>

                                            {/* <TableCell>
                                                <Wrap200Width>
                                                    (??prefix)
                                                </Wrap200Width>
                                            </TableCell> */}

                                            <TableCell>
                                                <Wrap200Width>
                                                    Transaction ID
                                                </Wrap200Width>
                                            </TableCell>

                                            <TableCell>
                                                Data
                                            </TableCell>

                                        </TableRow>
                                    </StyledTableHead>
                                    <TableBody>
                                        {(listElasticRequest || []).map(row => {
                                            return (
                                                <TableRow key={Math.random()}>
                                                    <StyledTableCellNoBreack>
                                                        {format(Date.parse(row.createdAt), 'yyyy-MM-dd HH:mm:ss')}
                                                    </StyledTableCellNoBreack>                                        
                                                    <StyledTableCellNoBreack>
                                                        {format(row.insertedAt*1000, 'yyyy-MM-dd HH:mm:ss')}
                                                    </StyledTableCellNoBreack>                                        
                                                    <TableCell>
                                                        <div style={{width: '250px'}}>
                                                            {row.type}
                                                        </div>
                                                    </TableCell>    
                                                    {/* <TableCell>
                                                        (??prefix)
                                                    </TableCell>    */}
                                                    <TableCell>
                                                        {row.transaction_id}
                                                    </TableCell>    
                                                    <TableCell
                                                        sx={{minWidth: '400px'}}
                                                    >
                                                        <JsonDataComponent data={row.data}/>
                                                    </TableCell>                                      
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>

                            </OverlayScrollbarsComponent>

                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={elasticPageCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />  
                        </StyledPaperTable>      
                    </StyledTableContainer>  
                </>
            }
            
            {isLoading && <Loader />}

        </PageContainer>
        )
    }
}



export default compose(
    connect((state: RootState) => ({
        isLoading: state.requestElasticSearch.isLoading,
        listElasticRequest: state.requestElasticSearch.requestElasticSearch['hydra:member'],
        elasticPageCount: state.requestElasticSearch.requestElasticSearch['hydra:totalItems'],
        typesRequest: state.requestElasticSearch.requesrTypes['hydra:member'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        requestElasticSearch,
        requestOptionsList,
    })
)(RequestElasticSearch)
