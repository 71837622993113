export namespace ActionTypes {
    export const FETCH_MAPPING_START = 'FETCH_MAPPING_START';
    export const FETCH_MAPPING_SUCCESS = 'FETCH_MAPPING_SUCCESS';
    export const FETCH_MAPPING_FAILURE = 'FETCH_MAPPING_FAILURE';
    export const SET_MAPPING_SUCCESS = 'SET_MAPPING_SUCCESS';
};

export const initialState = {
	isError: false,
    isLoading: false,
    errorMessage: '',
    cascadeList: JSON.stringify({
        deposit: {
            UAH: {methods: [], rules: {}}
        },
        withdraw: {
            UAH: {methods: [], rules: {}}
        }
    })
};

export const mappingCsacades = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_MAPPING_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_MAPPING_SUCCESS:
			return {
				...state,
				isLoading: false,
				isError: false,
                errorMessage: '',
                cascadeList: payload
			};
		case ActionTypes.FETCH_MAPPING_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		case ActionTypes.SET_MAPPING_SUCCESS:
			return { 
				...state,
                isError: false,
                errorMessage: ''
			};
		default:
			return state;
	}
};
