import React from "react";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import {SerachTableHeader} from '../../components/search-table-header';
import { Loader } from '../../components/loaders/loader';
import {usersActionLogRequest} from "../../actions/usersActionLog";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { dateWithTimeZone } from '../../utils';
import { Link } from 'react-router-dom';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { AccessDeniedAlert } from "../../components/access-denied";


const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px 16px 0 16px',
        height: '100%'
    }
}));

const UsersPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',
    }
});

const UsersPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UsersPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width:991px)': {
        width: '100%',
        // marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            // border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableRow = styled(TableRow)(({ theme }) => (`
    &:hover{
        // background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
`));

const LoginContent = styled(Link)(({ theme }) => (`
    text-decoration: underline;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

const LongText = styled('div')(({ theme }) => (`
    color: ${theme.palette.text.primary};
    word-break: break-all;
    min-width: 550px;
    text-align: center;
`)); 

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 190px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 195px - 56px);
        }; 
    }
`));

export class ActionLogPage extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            anchorElSuperadmin: null,
            isOpenChangePassword: false,
            anchorElRole: null,
            anchorElStatus: null,
            anchorElAssignments: null,
            isOpenCreateForm: false,
            page: 0,
            rowsPerPage: 20,
            userId: '',
            action: '',
            ip: '',
            createdAt: '',
        }
    };

    handleRoleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElRole: event.currentTarget});
    };
    handleRoleMenuClose = () => {
        this.setState({anchorElRole: null});
    };

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget})
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleSuperadminMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElSuperadmin: event.currentTarget})
    };
    handleSuperadminMenuClose = () => {
        this.setState({anchorElSuperadmin: null});
    };

    handleConfirmedMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElConfirmed: event.currentTarget})
    };
    handleConfirmedMenuClose = () => {
        this.setState({anchorElConfirmed: null});
    };

    handleChangePasswordModalOpen = () => {
        this.setState({isOpenChangePassword: true})
    };
    handleCheckPasswordModalClose = () => {
        this.setState({isOpenChangePassword: false})
    };

    handleCreateFormOpen = () => this.setState({isOpenCreateForm: true});
    handleCreateFormClose = () => this.setState({isOpenCreateForm: false});

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };


    isValidTime = (str) => {
        try {new Date(Date.parse(`${str}`)).toISOString()} catch (e) {return false}
        return true;
    }
    isValidIdUser = (str) => {
        try {
            let idUser = str.replace(/[^0-9]/g,"");
            return idUser
        } 
        catch (e) {
            return '1'
        }
    }

    dateWithTimeZone = (timeOffset, time) => {
        let respDate = new Date(Date.parse(`${time}`));
        let utc = respDate.getTime() + respDate.getTimezoneOffset()*60000;
        let newDate = new Date(utc + (timeOffset ? timeOffset : 0)*3600000 ).toLocaleString();
        return newDate
    }

    handleSearchSubmit = (prop) => (value) => {
        this.setState({
                [prop]: prop === 'createdAt' 
                    ? this.isValidTime(value) 
                        ? `${new Date(Date.parse(`${value}`)).toISOString()}` 
                        : ''
                    : value, 
                page: 0
            }, () => {this.updateData()}
        );
    };
    handleCancelSearch = (prop) => () => {
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleClickOpenWindowDelete = () => {
        this.setState({openDeleteWindow: true})
    }
    handleClickCloseWindowDelete = () => {
        this.setState({openDeleteWindow: false})
    }
    handleOpenDeleteDialog = (id) => {
        this.setState({deleteId: id}, () => this.handleClickOpenWindowDelete())
    }
    onChangeFilter = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
    };

    handleOpenInfoItem = (id) => {
        const {setView} = this.props
        setView((prev) => {
            return {
                id: id,
                point: 'view',
            }
        })
    }

    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/user-management/user-action-log/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    updateData = () => {

        const {
            page,
            userId,
            action,
            ip,
            createdAt,
        } = this.state;

        const {
            usersActionLogRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            'user.username': userId,
            'action': action,
            'ip': ip,
            // 'createdAt[before]': createdAt,
            // 'createdAt[strictly_before]': createdAt,
            'createdAt[after]': createdAt,
            // 'createdAt[strictly_after]': createdAt,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });
        
        usersActionLogRequest(data)
    }

    render() {

        const {
            isLoading,
            listCount,
            listActions,
            timezoneCurrent,
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props

        const {
            page,
            rowsPerPage,
            userId,
            action,
            createdAt,
            ip,
        } = this.state;

        let iteratorNumber = 1;

        const accessedRole = routsUser.some(rout => rout.child.includes('/user-management/user-action-log/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole && 
                        <>
                            <UsersPageHeader>
                                <UsersPageHeaderLeft>
                                    <UsersPageHeaderText>Action log</UsersPageHeaderText>
                                </UsersPageHeaderLeft>
                            </UsersPageHeader>

                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <TableCell>№</TableCell>
                
                                                    <WithSortedSerachTableHeader 
                                                        width='200px'
                                                        label='User Id' 
                                                        handleSearchSubmit={this.handleSearchSubmit('userId')}
                                                        handleCancelSearch={this.handleCancelSearch('userId')}
                                                        isActiveFilter={Boolean(userId)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Action' 
                                                        handleSearchSubmit={this.handleSearchSubmit('action')}
                                                        handleCancelSearch={this.handleCancelSearch('action')}
                                                        isActiveFilter={Boolean(action)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        width='200px'
                                                        label='IP' 
                                                        handleSearchSubmit={this.handleSearchSubmit('ip')}
                                                        handleCancelSearch={this.handleCancelSearch('ip')}
                                                        isActiveFilter={Boolean(ip)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        width='200px'
                                                        label='Created At' 
                                                        handleSearchSubmit={this.handleSearchSubmit('createdAt')}
                                                        handleCancelSearch={this.handleCancelSearch('createdAt')}
                                                        isActiveFilter={Boolean(createdAt)}
                                                    />
                
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(listActions || []).map(row => {
                                                    return (
                                                        <StyledTableRow key={Math.random()}>
                                                            <TableCell>
                                                                {
                                                                    (page*rowsPerPage) + iteratorNumber++
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <LoginContent 
                                                                    to={`/user/${row?.user?.id}`}  
                                                                >
                                                                    {row?.user?.username}
                                                                </LoginContent>
                                                            </TableCell>
                                                            <TableCell>
                                                                <LongText>{row.action}</LongText>
                                                            </TableCell>
                                                            <TableCell>
                                                                <a  target='_blank' 
                                                                    rel="noreferrer"
                                                                    style={{color: '#3c8dbc'}}
                                                                    href={`https://ipinfo.io/${row.ip}`}
                                                                >
                                                                    {row.ip}
                                                                </a>
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    this.isValidTime(row.createdAt) ? dateWithTimeZone(timezoneCurrent, row.createdAt) : ''
                                                                }
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>                               
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={listCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }
                    
                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.actionsLogs.isLoading, 
        listCount: state.actionsLogs.actionsLog['hydra:totalItems'],
        listActions: state.actionsLogs.actionsLog['hydra:member'],
        timezoneCurrent: state.timeZone.currentTimeZone.offset,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        usersActionLogRequest,
    }),
)(ActionLogPage)
