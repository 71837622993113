import baseApi from './axiosParams';
import queryString from 'query-string';
// const pathMainPage = 'api/rbac/mapping/roles';
const path = 'api/rbac/roles';

const UsersRoles = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';
		return baseApi.get(`${path}${query}`)
	},
	createItem: (body) => {
		return baseApi.post(`${path}`, body);
	},
	editItem: (data) => {
		let body = {description: data['description']}
		return baseApi.patch(`${path}/${data.currentRole}`, body);
	},
	deleteItem: (name) => {
		return baseApi.delete(`${path}/${name}`);
	},
	getItem: (name) => {
		return baseApi.get(`${path}/${name}`);
	},
	getItemTree: (name) => {
		return baseApi.get(`${path}/tree/${name}`);
	},
	assignPermission: (name, body) => {
		return baseApi.post(`${path}/${name}/permissions`, body);
	}
}

export default UsersRoles;