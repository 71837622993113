import React from 'react';
import { styled } from '@mui/material/styles';
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {useNavigate} from 'react-router-dom';

const WrappAccessAlert = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
}))

const StyledAlert = styled('div')(({theme}) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center',
});

const StyledInfo = styled('div')(({theme}) => ({
    width: '100%',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '28px',
    marginLeft: '46px',
    paddingTop: '10px',
    color: theme.palette.text.primary,
}))

export const AccessDeniedAlert = () => {
    const navigate = useNavigate();

    return (
        <WrappAccessAlert>                        
            <ArrowContainer>
                <ArrowBackSvg size="22px" onClick={() => navigate(-1)}/>
            </ArrowContainer>
            <StyledAlert>ACCEESS DENIED</StyledAlert>
            <StyledInfo>Access to this page is denied for your role.</StyledInfo>
        </WrappAccessAlert>
    )
};