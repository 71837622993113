import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/buyer_logs';

const SystemInfoBuyerLog = {
	getSistemInfoBuyerLog: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';

		return baseApi.get(`${path}${query}`)
	},
	getSistemInfoBuyerItemLog: (hash) => {
		return baseApi.get(`${path}/${hash}`)
	},
    // ...
}

export default SystemInfoBuyerLog;