import {ActionTypes} from '../reducers/usersOnTheNode';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_USERS_NODE_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_USERS_NODE_SUCCESS,
		payload: list
	};
};

export const requestItem = item => {
	return {
		type: ActionTypes.FETCH_USER_NODE_ITEM_SUCCESS,
		payload: item,
	};
};

export const dropdowmAssign = list => {
	return {
		type: ActionTypes.FETCH_USER_NODE_DROPDOWN_LIST,
		payload: list,
	};
};

export const requestDelete = id => {
	return {
		type: ActionTypes.FETCH_USER_NODE_DELETE,
		payload: id,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_USERS_NODE_FAILURE,
		payload: error
	}
};

export const requestItemRolesThree = roles => {
	return {
		type: ActionTypes.FETCH_USER_ASSIGMENTS_SUCCESS,
		payload: roles,
	};
};

export const requestItemRolesThreeFailure = err => {
	return {
		type: ActionTypes.FETCH_USERS_ASSIGMENTS_FAILURE,
		payload: err,
	};
};

export const requestRevokeSuccess = () => {
	return {
		type: ActionTypes.FETCH_USERS_REVOKE_SUCCESS,
	};
};

export const requestRevokeFailure = error => {
	return {
		type: ActionTypes.FETCH_USERS_REVOKE_FAILURE,
		payload: error
	}
};

export const usersOnTheNodeRequest = data => dispatch => {
	dispatch(requestStart());
	return API.useresOnTheNode
		.getList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersOnTheNodeCreate = (data) => dispatch => {
	dispatch(requestStart());
	return API.useresOnTheNode
		.createItem(data)
		.then(response => {
			dispatch(usersOnTheNodeRequest({page: 1}));
			dispatch(usersDropdownAssignedRequest());
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersOnTheNodeAssigne = (id) => dispatch => {
	dispatch(requestStart());
	return API.useresOnTheNode
		.assigneItem(id)
		.then(response => {
			dispatch(usersOnTheNodeRequest({page: 1}));
		})
		.catch(error => {
			dispatch(requestFailure(error.response.data['hydra:description']));
		});
};

export const usersOnTheNodeRevoke = (id) => dispatch => {
	dispatch(requestStart());
	return API.useresOnTheNode
		.revokeItem(id)
		.then(response => {
			dispatch(requestRevokeSuccess());
			dispatch(usersOnTheNodeRequest({page: 1}));
		})
		.catch(error => {
			dispatch(requestRevokeFailure(error.response.data['hydra:description']));
		});
};

export const usersOnTheNodeItemRequest = id => dispatch => {
	dispatch(requestStart());
	return API.useresOnTheNode
		.getItem(id)
		.then(response => {
			dispatch(requestItem(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersDropdownAssignedRequest = () => dispatch => {
	return API.useresOnTheNode
		.dropdownAssigned()
		.then(response => {
			dispatch(dropdowmAssign(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersOnTheNodeItemDelete = id => dispatch => {
	dispatch(requestStart());
	return API.useresOnTheNode
		.itemDelete(id)
		.then(response => {
			dispatch(usersOnTheNodeRequest({page: 1}));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const userRequestItemThree = id => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.getUserPermissionsThree(id)
		.then(response => {
			dispatch(requestItemRolesThree(response.data));
		})
		.catch(error => dispatch(requestItemRolesThreeFailure(error.message)));
};

export const usersOnTheNodeItemUpdate = (id, data) => dispatch => {
	dispatch(requestStart());
	return API.useresOnTheNode
		.updateItem(id, data)
		.then(response => {
			dispatch(requestItem(response.data))
			dispatch(userRequestItemThree(id))
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};