import baseApi from './axiosParams';
const path = 'api/blockchain_configs';

const BlockchainConfig = {
	getBlockchainConfig: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
    createBlockchainConfig: (body) => {
		return baseApi.post(`${path}`, body)
    },
    updateBlockchainConfig: (id, body) => {
        return baseApi.patch(`${path}/${id}`, body)
    }
}

export default BlockchainConfig;