import {ActionTypes} from '../reducers/paymentSystems';
import API from '../services/api';

export const getPaymentSystems = data => {
    return {
        type: ActionTypes.GET_PAYMENT_SYSTEMS,
        payload: data
    };
};

export const createPaymentSystemSuccess = () => {
    return {
        type: ActionTypes.CREATE_PAYMENT_SYSTEM_SUCCESS
    }
};

export const createPaymentSystemError = () => {
    return {
        type: ActionTypes.CREATE_PAYMENT_SYSTEM_ERROR
    }
};

export const getPaymentSystemsList = (data) => {
    return {
        type: ActionTypes.GET_PAYMENT_SYSTEMS_LIST,
        payload: data
    }
};

export const getPaymentSystemsListFiltered = (data) => {
    return {
        type: ActionTypes.GET_PAYMENT_SYSTEMS_LIST_FILTERED,
        payload: data
    }
};

export const getPaymentSystem = (data) => {
    return {
        type: ActionTypes.GET_PAYMENT_SYSTEM,
        payload: data
    }
};

export const changePaymentSystem = () => {
    return {
        type: ActionTypes.CHANGE_PAYMENT_SYSTEM
    }
};

export const setLoading = () => {
    return {
        type: ActionTypes.SET_LOADING_PAYMENT_SYSTEMS
    }
};

export const getPaymentSystemAmount = (data) => {
    return {
        type: ActionTypes.GET_PAYMENT_SYSTEMS_AMOUNT,
        payload: data
    }
};

export const getPaymentSystemsAmountt = () => dispatch => {
    dispatch(setLoading());
    return API.paymentSystems
        .getPaymentSystemsAmount()
        .then(response => {
            dispatch(getPaymentSystemAmount(response.data))
        })
        .catch(error => console.log(error))
};

export const getPaymentSystemsRequest = (data) => dispatch => {
    dispatch(setLoading());
    
    return API.paymentSystems
    .getPaymentSystems(data)
    .then(response => {
        dispatch(getPaymentSystems(response.data))
    })
    .catch(error => console.log(error))
};

export const createPaymentSystemRequest = (data) => dispatch => {
    return API.paymentSystems
    .createPaymentSystem(data)
    .then(() => dispatch(createPaymentSystemSuccess()))
    .catch(() => dispatch(createPaymentSystemError()));
};

export const getPaymentSystemsListRequest = () => dispatch => {
    return API.paymentSystems
    .getPaymentSystemsList()
    .then((response) => dispatch(getPaymentSystemsList(response.data)))
    .catch(error => console.log(error))
};

export const getPaymentSystemsListFilteredRequest = () => dispatch => {
    return API.paymentSystems
    .getPaymentSystemsListFiltered()
    .then((response) => dispatch(getPaymentSystemsListFiltered(response.data)))
    .catch(error => console.log(error))
};

export const getPaymentSystemRequest = (id) => dispatch => {
    dispatch(setLoading());

    return API.paymentSystems
    .getPaymentSystem(id)
    .then(response => dispatch(getPaymentSystem(response.data)))
    .catch(() => dispatch(getPaymentSystem([])))
};

export const changePaymentSystemRequest = (id, body) => dispatch => {
    return API.paymentSystems
    .changePaymentSystem(id, body)
    .then(() => dispatch(changePaymentSystem()))
    .then(() => dispatch(getPaymentSystemsRequest('')))
    .catch(error => console.log(error))
};
