import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Checkbox} from "@mui/material";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import {SearchSvg} from "../svg/search-svg";

const FilterContainer = styled('div')({
    // width: '210px',
});

const FilterButtons = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '0px 10px',
    '& > button': {
        height: '32px',
        padding: '0 12px',
    }
});

const ListContainer = styled('div')({
    maxHeight: '200px',
});

const ListItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',

    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
    }
}));

const StyledCheckbox = styled(Checkbox)({
    color: '#979799',
    '&.Mui-checked': {
        color: '#38AA72',
    }
});

const ListItemText = styled('div')({
    marginLeft: '10px'
});

const StyledInput = styled('input')(({ theme }) => ({
    width: '100%',
    marginRight: '20px',
    boxSizing: 'border-box',
    height: '36px',
    paddingLeft: '20px',
    paddingRight: '38px',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : '#C0C0C0'}`,
    borderRadius: '4px',
    background: 'transparent',
    color: theme.palette.text.primary,
    outline: 'none !important',
    '&::placeholder': {
        opacity: '1',
        color: theme.palette.text.primary,
    },
    '&:focus': {
        borderColor: '#38AA72',
    }
}));

const InputWrapper = styled('div')({
    position: 'relative',
    margin: '8px 8px 8px 8px'
});

const InputIcon = styled('div')({
    padding: '4px',
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
});

type Props = {
    filterItems: {title: any, value: any}[];
    withSearch?: boolean;
    onApplyFilter?: (state: string | string[]) => void;
    onClearAll?: (state: string[]) => void;
    initialData?: string[];
    nWidth?: string;
    clearAll?: boolean;
    label?: string;
};

export const TableFilter = (props: Props) => {
    const {filterItems, withSearch, onApplyFilter, onClearAll, initialData = [], nWidth = '210px', clearAll, label = ''} = props;

    const [state, setState] = useState([]);


    const [searchValue, setSearchValue] = useState('');

    const handleChangeSearchValue = event => {
        setSearchValue(event.target.value);
    };

    useEffect(() => {
        setState(initialData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (clearAll) {
            setState([]);
        }
    }, [clearAll]);

    const onChangeCheckbox = (item) => (event) => {
        if (event.target.checked) {
            setState([...state, item]);
        } else {
            setState([...state.filter(i => i !== item)]);
        };
    };

    const onApply = () => {
        onApplyFilter(state);
    };

    const onClear = () => {
        setState([]);
        onClearAll([]);
    };

    return (
        <FilterContainer sx={{width: nWidth}}>
            {withSearch && (
                <InputWrapper>
                    <StyledInput placeholder="Search" value={searchValue} onChange={handleChangeSearchValue}/>
                    <InputIcon>
                        <SearchSvg size="22px"/>
                    </InputIcon>
                </InputWrapper>
            )}
            <ListContainer>
                <List sx={{padding: '0px', maxHeight: '200px'}}>
                    <Scrollbars autoHeight>
                        {filterItems.filter(item => item.title.toUpperCase().includes(searchValue.toUpperCase())).map(item => (
                            <ListItem sx={{padding: '0px'}} key={item.value}>
                                <ListItemContainer>
                                    <ListItemText>
                                        {item.title}
                                    </ListItemText>
                                    <StyledCheckbox sx={{height: '40px'}} onChange={onChangeCheckbox(item.value)} checked={state.includes(item.value)}/>
                                </ListItemContainer>
                            </ListItem>
                        ))}
                    </Scrollbars>
                </List>
            </ListContainer>
            <FilterButtons>
                <Button 
                    disabled={Boolean(!state.length)} 
                    onClick={onApply}
                    data-testid={`buttonApply_${label.replace(/[^a-zA-Zа-яА-Я]/g, '')}`}
                >
                    APPLY
                </Button>
                <Button 
                    onClick={onClear} 
                    variant="outlined"
                    data-testid={`buttonClearAll_${label.replace(/[^a-zA-Zа-яА-Я]/g, '')}`}
                >
                    CLEAR ALL
                </Button>
            </FilterButtons>
        </FilterContainer>
    );
};
