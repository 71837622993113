import React from "react";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import SvgIcon from '@mui/material/SvgIcon';

const TableCellContent = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
`);

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default}; 
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
        border-bottom: none;
        box-sizing: border-box;
        box-shadow: none;
    }
`));

const WithSortedTableCellContent = styled(TableCellContent)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    width: 120px;
    min-width: 100px;
    box-sizing: border-box;
`));

// const SortButtons = styled('div')(({ theme }) => (`
//     position: absolute;
//     right: 0;
//     top: -10px;
// `));

// const SortButtonTop = styled('div')(({ theme }) => (`
//     & svg{ 
//         cursor: pointer;
//         bottom: -5px;
//         position: relative;
//         width: 12px;
//         height: 12px;
//     }
// `));

// const SortButtonBottom = styled('div')(({ theme }) => (`
//     & svg{ 
//         cursor: pointer;
//         position: relative;
//         top: -5px;
//         width: 12px;
//         height: 12px;
//     }
// `));

const StyledTableCellInfo = styled(TableCell)(({ theme }) => (`
    word-break: break-all;
`));

// type QueuesList = {
//     name: string,
//     waiting: any,
//     reserved: any,
//     delayed: any,
//     done: any,
// }

// type Props = {
//     list: QueuesList[],
// }

export const QueuesList = (props) => {

    const {list} = props

    return (
        <StyledTableContainer>
            <TableContainer component={Paper}>
                <Table>
                    <StyledTableHead>
                        <TableRow>
                            <TableCell>
                                <TableCellContent sx={{minWidth: '300px'}}>
                                    Queues
                                </TableCellContent>
                            </TableCell>

                            <TableCell width='120px'>
                                <WithSortedTableCellContent>
                                    Waiting
                                    {/* <SortButtons>
                                        <SortButtonTop>
                                            <SvgIcon viewBox="0 0 9 2">
                                                <path d="M0.666504 4.66602L4.6665 0.666016L8.6665 4.66602H0.666504Z" fill="#979799"/>
                                            </SvgIcon>
                                        </SortButtonTop>
                                        <SortButtonBottom>
                                            <SvgIcon viewBox="0 0 9 9">
                                                <path d="M0.666504 0.666016L4.6665 4.66602L8.6665 0.666016H0.666504Z" fill="#979799"/>
                                            </SvgIcon>
                                        </SortButtonBottom>
                                    </SortButtons> */}
                                </WithSortedTableCellContent>
                            </TableCell>

                            <TableCell width='120px'>
                                <WithSortedTableCellContent>
                                    Delayed
                                </WithSortedTableCellContent>
                            </TableCell>

                            <TableCell width='120px'>
                                <WithSortedTableCellContent>
                                    Reserved
                                </WithSortedTableCellContent>
                            </TableCell>

                            <TableCell width='120px'>
                                <WithSortedTableCellContent>
                                    Done
                                </WithSortedTableCellContent>
                            </TableCell>

                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {list.map(row => {
                            return (
                                <TableRow key={Math.random()}>
                                    <StyledTableCellInfo>
                                        {row.name}
                                    </StyledTableCellInfo>                                        
                                    <TableCell>
                                        {row.waiting}
                                    </TableCell>                                        
                                    <TableCell>
                                        {row.delayed}
                                    </TableCell>                                        
                                    <TableCell>
                                        {row.reserved}
                                    </TableCell>                                        
                                    <TableCell>
                                        {row.done}
                                    </TableCell>                                        
                                </TableRow>
                            )
                        })}
                    </TableBody>  
                </Table>
            </TableContainer>            
        </StyledTableContainer> 
    )
}

