import React from "react";
import TableCell from '@mui/material/TableCell';
import { styled } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { DateRangePicker } from '../date-range-picker'

const TableCellWithRange = styled('div')(`
    width: 100%;
    display: flex;
    align-items: center;
`);

const TableCellContent = styled('div')(`
    display: flex;
    align-items: center;
`);

const StyledTableCellContent = styled(TableCellContent)(({ theme }) => (`
    & {
        min-width: 330px;    
    }
`));

const TableCellWrapWithRange = styled(TableCell)(({theme})=>(`
    padding-top: 0;
    padding-bottom: 0;
    &.activeFilter{
        background: #38AA72;
        .MuiOutlinedInput-root{
            border: 1px solid #fff;
        }
    }
`))

type Date = {
    startDate: string;
    endDate: string;
    clearDates: boolean;
}

type Props = {
    width?: string;
    label: string;
    sx?: any;
    handleSearchSubmit: (value: Date) => void;
    handleCancelSearch: () => void;
    isActiveFilter: boolean;
    
}

export const DateRangeTableHeader = ( {handleSearchSubmit, handleCancelSearch, label, sx, width, isActiveFilter}: Props ) => {

    const [isSearchNameField, setIsSearchNameField] = React.useState(false);
    const [dateValue, setDateValue] = React.useState({startDate: '', endDate: '', clearDates: false});

    const handleActivateSearch = () => {
        setIsSearchNameField((prevState) => {
            if (prevState === true) {
                handleCancelSearch();
            }
            return !prevState
        });
    };

    const handleSubmit = () => {
        if(dateValue.startDate && dateValue.endDate){
            handleSearchSubmit(dateValue)
        }
    }

    const setDates = (startDate = '', endDate = '') => {
        setDateValue({startDate: startDate, endDate: endDate, clearDates: false});
    };
    
    return (
        <TableCellWrapWithRange
            className={isActiveFilter ? 'activeFilter' : ''}
        >
            <StyledTableCellContent>    
                <TableCellWithRange>
                    
                    {isSearchNameField 
                        ? <DateRangePicker setDates={setDates} clearDates={dateValue.clearDates}/>
                        : `${label}`
                    }
                    
                    {isSearchNameField
                        ? <>                                                      
                            <IconButton
                                aria-label=""
                                onClick={handleSubmit}
                                sx={{marginLeft: '5px'}}
                            >
                                <SearchIcon sx={{fontSize: '18px'}} />
                            </IconButton>
                            <IconButton
                                aria-label=""
                                onClick={handleActivateSearch}
                                sx={{marginLeft: '0px'}}
                            >
                                <CloseIcon sx={{fontSize: '20px'}} />
                            </IconButton>  
                        </>

                        : <IconButton
                            aria-label=""
                            onClick={handleActivateSearch}
                            sx={{marginLeft: '5px'}}
                        >
                            <SearchIcon sx={{fontSize: '18px'}} />
                        </IconButton>
                    }
                </TableCellWithRange>
            </StyledTableCellContent>
        </TableCellWrapWithRange>
    )
}