import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const EditSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 22 22"
            onClick={onClick}
        >
            <path d="M2.75 15.8126V19.2501H6.1875L16.3258 9.11176L12.8883 5.67426L2.75 15.8126ZM18.9842 6.45343C19.3417 6.09593 19.3417 5.51843 18.9842 5.16093L16.8392 3.01593C16.4817 2.65843 15.9042 2.65843 15.5467 3.01593L13.8692 4.69343L17.3067 8.13093L18.9842 6.45343Z" fill={fill}/>
        </WrapperTag>
    );
};

EditSvg.defaultProps = {
    fill: 'white',
};
