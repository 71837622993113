import React, {useEffect} from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Loader } from '../../components/loaders/loader';
import { 
    hdbkItemRequest,
    hdbkDelete,
} from "../../actions/systenInfoHdbkRequestTypes";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from '../../components/button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import { NotifyDialog } from '../../components/notify-dialog';
import { format } from 'date-fns';
import { hdbkItem } from "../../reducers/hdbkRequestType";

const PageContainer = styled('div')(({theme}) => (`  
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxView = styled(Box)(({theme}) => (`
    // border: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;  
    .MuiTableContainer-root{
        border-radius: 0;
    }
    tr{
        &:last-child{
            td{
                border-bottom: none;
            }
        }
    }   
`));

const TableCellTitle = styled(TableCell)(({theme}) => (`
    font-weight: bold;
    width: 200px;
`));

const WrappBox = styled(Box)(({theme}) => (`
    overflow: auto;
    border: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
`));

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const StyledDialog = styled(Dialog)(({ theme }) => (`
    &{
        .MuiDialog-paper{
            border-radius: 0;
        }
    }
`));

interface PropsType {
    isLoading: boolean,
    dataItem: hdbkItem,
    error: boolean,
    errorMessage: string,
    hdbkItemRequest: (id: number) => void,
    hdbkDelete: (id: number) => any,
    setView: any, 
    view: {id: number, point: string},
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

export const SystemInfoAdressItem = (props: PropsType) => {

    const [openDeleteWindow, setOpenDeleteWindow] = React.useState(false)

    const {
        hdbkItemRequest,
        hdbkDelete,
        dataItem,
        isLoading,
        setView, 
        view,
        error,
        errorMessage,
        routsUser, 
        userRoles, 
        isSuperadmin,
    } = props;

    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {return}

        setOpenSnackbar(false);
    };

    useEffect(() => {
        hdbkItemRequest(view.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = (way) => () => {
        setView(prev => ({...prev, point: way}))
    }

    const handleClickOpenWindowDelete = () => {
        setOpenDeleteWindow(true)
    }
    const handleClickCloseWindowDelete = () => {
        setOpenDeleteWindow(false)
    }

    const handleDelete = () => {
        hdbkDelete(view.id).then(() => handleOpenSnackbar());
        setView(prev => ({ point: 'main', id: null}))
    }

    const isValidTime = (str: string) => {
        try {str.replace(/\+.*/, '')} catch (e) {return false}
        return true;
    }

    const accessedRoleEdite = routsUser.some(rout => rout.child === '/hdbk-requests-type/update') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleDelete = routsUser.some(rout => rout.child === '/hdbk-requests-type/delete') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    
    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation('main')}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            Hdbk Request Types: {dataItem.id}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
                <ShortViewHeaderRight>
                    {accessedRoleEdite &&
                        <Button onClick={handleClickNavigation('edit')}>
                            <EditIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                            edit
                        </Button>
                    }
                    {accessedRoleDelete &&
                        <Button isDeleteButton={true} onClick={handleClickOpenWindowDelete}>
                            <DeleteIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                            delete
                        </Button>
                    }
                </ShortViewHeaderRight>
            </ShortViewHeader>

            <WrappBox>
                <BoxView sx={{ width: '100%', typography: 'body1' }}>
                    <TableContainer component={Paper} sx={{minWidth: '340px'}}>
                        <Table>   
                            <TableBody>
                                <TableRow>
                                    <TableCellTitle>Id</TableCellTitle>
                                    <TableCell>{dataItem.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Description</TableCellTitle>
                                    <TableCell>{dataItem.text}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Created</TableCellTitle>
                                    <TableCell>
                                        {
                                            isValidTime(dataItem.createdAt) 
                                                ? format(new Date(`${(dataItem.createdAt).replace(/\+.*/, '')}.000Z`), 'yyyy-MM-dd HH:mm:ss')
                                                : 'Invalid date'
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BoxView>
            </WrappBox>

            <StyledDialog
                open={openDeleteWindow}
                onClose={handleClickCloseWindowDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NotifyDialog
                    title={`Hdbk Request Types: ${view.id} ?`}
                    confirmButtonText='YES, DELETE'
                    cancelButtonText='CANCEL'
                    onClose={handleClickCloseWindowDelete}
                    width="400px"
                    isDeleteButton
                    onCancel={handleClickCloseWindowDelete}
                    onConfirm={handleDelete}
                >
                    Are you sure you want to delete Hdbk Request Types ?
                </NotifyDialog>  
            </StyledDialog>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                {error ?
                <InfoPopup
                    severity="error"
                    headerText="Hdbk request type not deleted"
                    infoText={errorMessage ? errorMessage : "Something went wrong. Please try again later"}
                /> :
                <InfoPopup
                    severity="success"
                    headerText="Hdbk request type was deleted"
                />}
            </Snackbar>

            {isLoading && <Loader/>}

        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        isLoading: state.hdbkRequestType.isLoading,
        dataItem: state.hdbkRequestType.hdbkItem,
        error: state.hdbkRequestType.isError,
        errorMessage: state.hdbkRequestType.errorMessage,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        hdbkItemRequest,
        hdbkDelete,
    }),
)(SystemInfoAdressItem)