import {ActionTypes} from '../reducers/nodeManagement';
import API from '../services/api';

export const getNodes = data => {
    return {
        type: ActionTypes.GET_NODES_TREE,
        payload: data
    };
};

export const getNode = data => {
    return {
        type: ActionTypes.GET_NODE,
        payload: data
    };
};

export const setLoading = () => {
    return {
        type: ActionTypes.SET_LOADING
    };
};

export const setActiveNode = (data) => {
    return {
        type: ActionTypes.SET_ACTIVE_NODE,
        payload: data
    }
};

export const createNodeSuccess = () => {
    return {
        type: ActionTypes.CREATE_NODE_SUCCESS
    }
};

export const createNodeError = (error) => {
    return {
        type: ActionTypes.CREATE_NODE_ERROR,
        payload: error
    }
};

export const updateNodeSuccess = () => {
    return {
        type: ActionTypes.UPDATE_NODE_SUCCESS
    }
};

export const updateNodeError = (error) => {
    return {
        type: ActionTypes.UPDATE_NODE_ERROR,
        payload: error
    }
};

export const deleteNodeSuccess = () => {
    return {
        type: ActionTypes.DELETE_NODE_SUCCESS
    }
};

export const deleteNodeError = (error) => {
    return {
        type: ActionTypes.DELETE_NODE_ERROR,
        payload: error
    }
};

export const verifyDomainSuccess = (data) => {
    return {
        type: ActionTypes.VERIFY_DOMAIN_SUCCESS,
        payload: data
    }
};

export const verifyDomainError = (error) => {
    return {
        type: ActionTypes.VERIFY_DOMAIN_ERROR,
        payload: error
    }
};

export const switchRESTAccess = (data) => {
    return {
        type: ActionTypes.SWITCH_REST_ACCESS,
        payload: data[0]
    }
};

export const getApiKey = (data) => {
    return {
        type: ActionTypes.GET_API_KEY,
        payload: data
    }
}

export const getBrandsAmount = (data) => {
    return {
        type: ActionTypes.GET_BRANDS,
        payload: data
    }
}

export const getClientsAmount = (data) => {
    return {
        type: ActionTypes.GET_CLIENTS,
        payload: data
    }
}

export const getNodesTreeRequest = () => dispatch => {
    dispatch(setLoading());
    return API.nodeManagement
    .getNodesTree()
    .then(response => {
        dispatch(getNodes(response.data))
    })
    .catch(error => console.log(error))
};

export const getNodeRequest = (id) => dispatch => {
    dispatch(setLoading());
    return API.nodeManagement
    .getNode(id)
    .then(response => {
        dispatch(getNode(response.data))
    })
    .catch(error => console.log(error))
};

export const createNodeRequest = (type, data) => dispatch => {
    return API.nodeManagement.createNode(type, data)
    .then(() => dispatch(createNodeSuccess()))
    .then(() => dispatch(getNodesTreeRequest()))
    .catch((error) => dispatch(createNodeError(error.message)));
};

export const updateNodeRequest = (id, data) => dispatch => {
    return API.nodeManagement
    .updateNode(id, data)
    .then(() => dispatch(updateNodeSuccess()))
    .then(() => dispatch(getNodesTreeRequest()))
    .catch((error) => dispatch(updateNodeError(error.message)));
};

export const setActiveNodeRequest = (id) => dispatch => {
    // dispatch(setLoading());
    return API.nodeManagement
    .setActiveNode(id)
    .then(response => {
        dispatch(setActiveNode(response.data))
    })
    .catch(error => console.log(error))
};

export const deleteNodeRequest = (id) => dispatch => {
    return API.nodeManagement
    .deleteNode(id)
    .then(() => dispatch(deleteNodeSuccess()))
    .then(() => dispatch(getNodesTreeRequest()))
    .catch((error) => {
        dispatch(deleteNodeError(error.message));
        return error.message;
    })
};

export const verifyDomainRequest = (id) => dispatch => {
    return API.nodeManagement
    .verifyDomain(id)
    .then((response) => dispatch(verifyDomainSuccess(response.data)))
    .catch((error) => verifyDomainError(error.message))
};

export const switchRESTAccessRequest = () => dispatch => {
    return API.nodeManagement
    .switchRESTAccess()
    .then((res) => dispatch(switchRESTAccess(res.data)))
    .catch(error => console.log(error))
};

export const getApiKeyRequest = () => dispatch => {
    return API.nodeManagement
    .getApiKey()
    .then((response) => dispatch(getApiKey(response.data)))
    .catch(error => console.log(error))
};




export const getClientsRequest = () => dispatch => {
    return API.nodeManagement
        .getClientsAmount()
        .then((response) => {
            dispatch(getClientsAmount(response.data));
        })
        .catch(error => console.log(error))
}

export const getBrandsRequest = () => dispatch => {
    return API.nodeManagement
        .getBrandsAmount()
        .then((response) => {
            dispatch(getBrandsAmount(response.data));
        })
        .catch(error => console.log(error))
}