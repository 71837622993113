import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Loader } from '../../components/loaders/loader'
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { buierItemLog } from "../../reducers/systemInfoBuierLog";
import { 
    sysInfiBuyerLogItemRequest,
} from "../../actions/systemInfoBuyerLog";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';


const PageContainer = styled('div')(({theme}) => (`
    position: relative;
    min-height: 100% - 64px;
    box-sizing: border-box;
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            // border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

interface PropsTypes {
    listItems: buierItemLog[],
    isLoading: boolean,
    sysInfiBuyerLogItemRequest: any,
    setView: any, 
    view: {
        hash: string, 
        point: string
    },
}

 const SystemInfiBuyerLogItem = (props: PropsTypes) => {

    const {
        sysInfiBuyerLogItemRequest,
        isLoading,
        listItems,
        setView, 
        view
    } = props

    useEffect(() => {
        sysInfiBuyerLogItemRequest(view.hash)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hancleClickBack = () => {
        setView(prev => ({...prev, point: 'main'}))
    }

    return (
        <PageContainer>
            <DepositMainHeader>
                <ArrowContainer  onClick={hancleClickBack}>
                    <ArrowBackSvg size="22px"/>
                </ArrowContainer>
                <DepositMainHeaderText>Buyer logs</DepositMainHeaderText>
            </DepositMainHeader>
            <StyledPaperTable>                               
                <TableContainer component={'div'}>
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                <TableCell sx={{minWidth: '100px'}}>Buyer id</TableCell>
                                <TableCell>Hash of buyer browser</TableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {(listItems || []).map(row => {
                                return (
                                    <TableRow key={Math.random()}>
                                        <TableCell>
                                            {row.buyerId}
                                        </TableCell>
                                        <TableCell>
                                            {row.browserHash}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>  
            </StyledPaperTable>
            { isLoading && <Loader/> }
        </PageContainer>
    )
}

export default compose(
    connect((state: RootState) => ({
        listItems: state.systemInfoBuierLog.systemInfoBuyerLogItem['hydra:member'],
        isLoading: state.systemInfoBuierLog.isLoading,
    }), {
        sysInfiBuyerLogItemRequest
    }),
)(SystemInfiBuyerLogItem)
