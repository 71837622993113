import React from "react";
import MainPermissionPage from './permissions-main';
import PermissionDetailsPage from './permissions-details';

export type StateIndex = {
    point: string, 
    id: string
}

export const UsersPermissionPage = () => {
    const [view, setView] = React.useState<StateIndex>({ point: 'main', id: '' })

    return(
        <>
            { view.point === 'main' && <MainPermissionPage setView={setView} />}
            { view.point === 'view' && <PermissionDetailsPage setView={setView} view={view} />}
        </>
    )
} 

export default UsersPermissionPage;