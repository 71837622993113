export namespace ActionTypes {
    export const FETCH_MAPPING_ROLES_START = 'FETCH_MAPPING_ROLES_START';
    export const FETCH_MAPPING_ROLES_SUCCESS = 'FETCH_MAPPING_ROLES_SUCCESS';
    export const FETCH_MAPPING_ROLES_FAILURE = 'FETCH_MAPPING_ROLES_FAILURE';
    export const FETCH_ITEM_ROLES_FAILURE = 'FETCH_ITEM_ROLES_FAILURE';
	export const FETCH_ITEM_TREE_ROLES_SUCCESS = 'FETCH_ITEM_TREE_ROLES_SUCCESS';
};

interface TypeRoles {
	isError: boolean,
    isLoading: boolean,
	roles: [],
	roleItem: {},
	roleTree: any,
	errorMessage: string,
}

export const initialState: TypeRoles = {
	isError: false,
    isLoading: false,
	roles: [],
	roleItem: {},
	roleTree: {
		childPermissions: [
			{
				'@id': '',
				checked: false,
				code: '',
				name: '',
				owner_child_role: '',
			}
		],
		childRoles: [
			{
				'@id': '',
				checked: false,
				code: '',
				name: '',
			}
		]
	},
	errorMessage: '',
};

export const roles = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_MAPPING_ROLES_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_MAPPING_ROLES_SUCCESS:
			return {
				...state,
				roles: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_ITEM_ROLES_FAILURE:
			return {
				...state,
				roleItem: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_ITEM_TREE_ROLES_SUCCESS:
			return {
				...state,
				roleTree: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_MAPPING_ROLES_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
