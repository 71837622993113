import {ActionTypes} from '../reducers/authorization';
import {ActionCurrentUserTypes} from '../reducers/currentUser';
import {temezoneListRequest} from './timezone'
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_LOGIN_START
});

export const requestSuccess = userInfo => {
	return {
		type: ActionTypes.FETCH_LOGIN_SUCCESS,
		payload: userInfo
	};
};

export const requestFailure = error => {
	localStorage.removeItem('token')
	
	return {
		type: ActionTypes.FETCH_LOGIN_FAILURE,
		payload: error
	}
};

export const currentUserGet = (currentUser) => {
	return {
		type: ActionCurrentUserTypes.FETCH_CURRENT_USER_SUCCESS,
		payload: currentUser,
	}
}

export const currentUserRequest = () => dispatch => {
	return API.currentUser
		.currentUser()
		.then(response => {
            dispatch(currentUserGet(response.data))
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const loginRequest = data => dispatch => {
	dispatch(requestStart());
    
	return API.login
		.logIn(data)
		.then(response => {
			localStorage.setItem('token', response.data.token);

            dispatch(currentUserRequest());
			dispatch(requestSuccess(response.data.data));
			dispatch(temezoneListRequest());
		})
		.catch(error => dispatch(requestFailure(error.message)));
};
