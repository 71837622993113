import {ActionTypes} from '../reducers/statements';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_STATEMENTS_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_STATEMENTS_SUCCESS,
		payload: list
	};
};

export const requestItem = item => {
	return {
		type: ActionTypes.FETCH_STATEMENTS_ITEM_SUCCESS,
		payload: item,
	};
};

export const requestDelete = id => {
	return {
		type: ActionTypes.FETCH_STATEMENTS_DELETE,
		payload: id,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_STATEMENTS_FAILURE,
		payload: error
	}
};

export const statementsRequest = data => dispatch => {
	dispatch(requestStart());
	return API.statements
		.statementsList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const statementItemRequest = id => dispatch => {
	dispatch(requestStart());
	return API.statements
		.statementItem(id)
		.then(response => {
			dispatch(requestItem(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const statementDelete = id => dispatch => {
	dispatch(requestStart());
	return API.statements
		.statementDelete(id)
		.then(response => {
			dispatch(statementsRequest(''));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const statementCreate = (data) => dispatch => {
	dispatch(requestStart());
	return API.statements
		.statementCreate(data)
		.then(response => {
			dispatch(statementsRequest(''));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const statementUpdate = (id, data) => dispatch => {
	dispatch(requestStart());
	return API.statements
		.statementUpdate(id, data)
		.then(response => {
			dispatch(statementItemRequest(id))
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};