import {ActionTypes} from '../reducers/userPaymentSystems';
import API from '../services/api';

export const setLoading = () => ({
	type: ActionTypes.SET_LOADING
});

export const getUserPaymentSystems = (data) => {
	return {
		type: ActionTypes.GET_USER_PAYMENT_SYSTEMS,
		payload: data
	};
};

export const createUserPaymentSystem = (data) => {
    return {
        type: ActionTypes.CREATE_USER_PAYMENT_SYSTEM,
        payload: data
    }
};

export const getUserPaymentSystem = (data) => {
    return {
        type: ActionTypes.GET_USER_PAYMENT_SYSTEM,
        payload: data
    }
}

export const errorPaymentSystem = (err) => {
    return {
        type: ActionTypes.ERROR_USER_PAYMENT_SYSTEM,
        payload: err
    }
}

export const getUserPaymentSystemsRequest = (query) => dispatch => {
	dispatch(setLoading());
    
	return API.userPaymentSystems
		.getUserPaymentSystems(query)
		.then(res => {
			dispatch(getUserPaymentSystems(res.data));
		})
		.catch(error => console.log(error));
};

export const createUserPaymentSystemRequest = (data) => dispatch => {
    return API.userPaymentSystems
        .createUserPaymentSystem(data)
        .then(res => dispatch(createUserPaymentSystem(res.data)))
        .catch(error => console.log(error));
};

export const getUserPaymentSystemRequest = (id) => dispatch => {
    dispatch(setLoading());
    return API.userPaymentSystems
    .getUserPaymentSystem(id)
    .then(res => {
        dispatch(getUserPaymentSystem(res.data))
    })
    .catch(error => dispatch(errorPaymentSystem(error)))
};

export const updateUserPaymentSystem = (id, body) => dispatch => {
    // dispatch(setLoading());
    return API.userPaymentSystems
        .updateUserPaymentSystem(id, body)
        // .then(res => {
        //     dispatch(getUserPaymentSystem(res.data))
        // })
        // .catch(error => console.log(error))
};

export const changeFieldCredentials = (attribute, value) => {
    return {
        type: ActionTypes.CHANGE_FIELD_CREDENTIALS_USER_PAYMENT_SYSTEM,
        payload: {attribute, value}
    }
}

//Mode modal
export const changeFieldCredentialsModeModal = (itemCurrency, item, val) => {
    return {
        type: ActionTypes.CHANGE_FIELD_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM,
        payload: {itemCurrency, item, val}
    }
}
export const changeActiveFieldCredentialsModeModal = (activeSystem, val) => {
    return {
        type: ActionTypes.CHANGE_FIELD_ACTIVE_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM,
        payload: {activeSystem, val}
    }
}

export const changeFieldCurrencies = (attribute, value) => {
    return {
        type: ActionTypes.CHANGE_FIELD_CURRENCIES_USER_PAYMENT_SYSTEM,
        payload: {attribute, value}
    }
}




// Update PS with accounys
export const updateUserPaymentSystemWithAccounts = (id, body) => dispatch => {
    const idPS = id;

    // console.log('send', id)
    dispatch(setLoading());
    return API.userPaymentSystems
        .updateUserPaymentSystem(id, body)
        .then(res => {
            // console.log('reSP afterUpdate')
            dispatch(getUserPaymentSystemRequest(idPS))
            dispatch(setLoading());
        })
        .catch(error => console.log(error))
};
