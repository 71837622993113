import {ActionTypes} from '../reducers/roles';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_MAPPING_ROLES_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_MAPPING_ROLES_SUCCESS,
		payload: list
	};
};

export const requestItemSuccess = item => {
	return {
		type: ActionTypes.FETCH_ITEM_ROLES_FAILURE,
		payload: item
	};
};

export const requestItemTreeSuccess = item => {
	return {
		type: ActionTypes.FETCH_ITEM_TREE_ROLES_SUCCESS,
		payload: item
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_MAPPING_ROLES_FAILURE,
		payload: error
	}
};

export const usersRolesRequest = data => dispatch => {
	dispatch(requestStart());
	return API.usersRoles
		.getList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const userRoleCreate = data => dispatch => {
	dispatch(requestStart());
	return API.usersRoles
		.createItem(data)
		.then(response => {
			dispatch(usersRolesRequest(""));
		})
}

export const userRoleEdit = data => dispatch => {
	dispatch(requestStart());
	return API.usersRoles
		.editItem(data)
		.then(response => {
			dispatch(usersItemRolesRequest(data.name));
		})
}

export const userRoleDelete = data => dispatch => {
	dispatch(requestStart());
	return API.usersRoles
		.deleteItem(data)
		.then(response => {
			dispatch(usersRolesRequest(""));
		})
}

export const usersItemRolesRequest = role => dispatch => {
	dispatch(requestStart());
	return API.usersRoles
		.getItem(role)
		.then(response => {
			dispatch(requestItemSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersItemTreeRolesRequest = role => dispatch => {
	dispatch(requestStart());
	return API.usersRoles
		.getItemTree(role)
		.then(response => {
			dispatch(requestItemTreeSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const assignPermissionForRole = (name, body) => dispatch => {
	dispatch(requestStart());
	return API.usersRoles
		.assignPermission(name, body)
		.then(response => {
			dispatch(usersItemTreeRolesRequest(name));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};