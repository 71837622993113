import {actionTypes} from '../reducers/merchants';
import API from '../services/api';


export const getPublicKey = (data) => {
	return {
		type: actionTypes.GENERATE_PUBLIC_KEY,
		payload: data
	};
};

export const getPrivateKey = (data) => {
	return {
		type: actionTypes.GENERATE_PRIVATE_KEY,
		payload: data
	};
};

export const generatePublicKeyRequest = () => dispatch => {
	return API.merchants
		.generateKey('public')
		.then(res => {
			dispatch(getPublicKey(res.data));
		})
		.catch(error => console.log(error));
};

export const generatePrivateKeyRequest = () => dispatch => {
	return API.merchants
		.generateKey('private')
		.then(res => {
			dispatch(getPrivateKey(res.data));
		})
		.catch(error => console.log(error));
};

export const clearKeys = () => dispatch => {
	return dispatch({
		type: actionTypes.CLEAR_KEYS
	})
};
