import React from "react";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import {DateRangeTableHeader} from '../../components/data-range-picker-table-header';
import { Loader } from '../../components/loaders/loader';
import {
    usersVisitLogRequest
} from "../../actions/usersVisitLog";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { Link } from 'react-router-dom';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Tooltip from '@mui/material/Tooltip';
import { AccessDeniedAlert } from "../../components/access-denied";

const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px 16px 0 16px',
        height: '100%'
    }
}));

const UsersPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',
    }
});

const UsersPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UsersPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width:991px)': {
        width: '100%',
        // marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableRow = styled(TableRow)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
`));

const LoginContent = styled(Link)(({ theme }) => (`
    text-decoration: underline;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 195px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 195px - 56px);
        }; 
    }
`));

export class UserOnBranchPage extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            user: '',
            ip: '',
            os: '',
            browser: '',
            startDate: '',
            endDate: '',
            visitTime: '',
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleOpenInfoItem = (id) => {
        const {setView} = this.props
        setView((prev) => {
            return {
                id: id,
                point: 'view',
            }
        })
    }

    handleSearchDateRange = (date) => {
        let visit = `${date.startDate.getFullYear()}-${date.startDate.getMonth()}-${date.startDate.getDate()} - ${date.endDate.getFullYear()}-${date.endDate.getMonth()}-${date.endDate.getDate()}`;
        this.setState({visitTime: visit, page: 0}, () => {this.updateData()});
    }
    handleCanselhDateRange = () => {
        this.setState({startDate: '', endDate: '', page: 0, visitTime: ''}, () => {this.updateData()});
    }
    
    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/user-management/user-visit-log/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    updateData = () => {

        const {
            page,
            user,
            language,
            os,
            ip,
            browser,
            visitTime,
        } = this.state;

        const {
            usersVisitLogRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            'user.username': user,
            'language': language,
            'os': os,
            'ip': ip,
            'browser': browser,
            'visitTime': visitTime,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        usersVisitLogRequest(data);
    }

    render() {

        const {
            isLoading,
            ListCount,
            listVisits,
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props

        const {
            page,
            rowsPerPage,
            user,
            language,
            os,
            browser,
            ip,
            visitTime,
        } = this.state;

        let iteratorNumber = 1;

        const accessedRole = routsUser.some(rout => rout.child.includes('/user-management/user-visit-log/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleView = routsUser.some(rout => rout.child === '/user-management/user-visit-log/view') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <UsersPageHeader>
                                <UsersPageHeaderLeft>
                                    <UsersPageHeaderText>Visit log</UsersPageHeaderText>
                                </UsersPageHeaderLeft>
                            </UsersPageHeader>
            
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <TableCell>№</TableCell>
                
                                                    <WithSortedSerachTableHeader 
                                                        label='User' 
                                                        handleSearchSubmit={this.handleSearchSubmit('user')}
                                                        handleCancelSearch={this.handleCancelSearch('user')}
                                                        isActiveFilter={Boolean(user)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Language' 
                                                        handleSearchSubmit={this.handleSearchSubmit('language')}
                                                        handleCancelSearch={this.handleCancelSearch('language')}
                                                        isActiveFilter={Boolean(language)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        label='OS' 
                                                        handleSearchSubmit={this.handleSearchSubmit('os')}
                                                        handleCancelSearch={this.handleCancelSearch('os')}
                                                        isActiveFilter={Boolean(os)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        label='Browser' 
                                                        handleSearchSubmit={this.handleSearchSubmit('browser')}
                                                        handleCancelSearch={this.handleCancelSearch('browser')}
                                                        isActiveFilter={Boolean(browser)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        label='IP' 
                                                        handleSearchSubmit={this.handleSearchSubmit('ip')}
                                                        handleCancelSearch={this.handleCancelSearch('ip')}
                                                        isActiveFilter={Boolean(ip)}
                                                    />

                                                    <DateRangeTableHeader 
                                                        label='Visit Time' 
                                                        handleSearchSubmit={this.handleSearchDateRange}
                                                        handleCancelSearch={this.handleCanselhDateRange}
                                                        isActiveFilter={Boolean(visitTime)}
                                                    />

                                                    {accessedRoleView &&
                                                        <ActionsHeaderCell>
                                                            <StickyBorder>Action</StickyBorder>
                                                        </ActionsHeaderCell>
                                                    }
                
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(listVisits || []).map(row => {
                                                    return (
                                                        <StyledTableRow key={Math.random()}>
                                                            <TableCell>
                                                                {
                                                                    (page*rowsPerPage) + iteratorNumber++
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <LoginContent 
                                                                    to={`/user/${ row?.user?.id }`}
                                                                >
                                                                    {row?.user?.username}
                                                                </LoginContent>
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.language}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.os}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.browser}
                                                            </TableCell>
                                                            <TableCell>
                                                                <a  target='_blank' 
                                                                    rel="noreferrer"
                                                                    style={{color: '#3c8dbc'}}
                                                                    href={`https://ipinfo.io/${row.ip}`}
                                                                >
                                                                    {row.ip}
                                                                </a>
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.visitTime}
                                                            </TableCell>
                                                            
                                                            {accessedRoleView &&
                                                                <ActionsCell>
                                                                    <StickyBorder>
                                                                        <ActionsContent>    
                                                                            <Tooltip title='Details' placement="top">
                                                                                <ActionIcon 
                                                                                    onClick={() => {this.handleOpenInfoItem(row.id)}}
                                                                                >
                                                                                    <RemoveRedEyeIcon />
                                                                                </ActionIcon>
                                                                            </Tooltip>
                                                                        </ActionsContent>
                                                                    </StickyBorder>
                                                                </ActionsCell>
                                                            }
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>                               
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={ListCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }
    
                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.visitLogs.isLoading,
        ListCount: state.visitLogs.visitsLog['hydra:totalItems'],
        listVisits: state.visitLogs.visitsLog['hydra:member'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        usersVisitLogRequest,
    }),
)(UserOnBranchPage)
