import React from "react";
import {styled} from "@mui/material/styles";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Button} from '../../components/button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Structure from '../../components/structure';
import {InfoOutlineSvg} from "../../components/svg/info-outline-svg";
import {DateRangePicker} from '../../components/date-range-picker';
import {EmptyDataSvg} from '../../components/svg/empty-data-svg';
import {reportRequest, reportsDownload} from '../../actions/report';
import {getPaymentSystemsListRequest} from '../../actions/paymentSystems';
import {currencyListRequest} from '../../actions/currencyList';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {format} from 'date-fns';
import {Loader} from '../../components/loaders/loader';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import FilterList from './filter-dropdown-list';
import {CurrencyItem} from '../../reducers/currencyList'
import {ReportItem} from '../../reducers/report';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px 40px 20px 40px',
    background: theme.palette.background.paper,

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const ReportsPageHeader = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

const ReportsPageTitle = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    },

    '@media(max-width: 440px)' : {
        width: '100%',
    }
}));

const ReportsPageFirstRow = styled('div')({
    marginTop: '4px',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '16px',

    '@media(max-width: 1360px)' : {
        flexWrap: 'wrap',
        marginTop: '24px',
    },

    '@media(max-width: 768px)' : {
        flexDirection: 'column',
        marginTop: '0px',
    }
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    boxSizing: 'border-box',
    width: 'calc(100%/5 - 16px*4/5)',

    '& label.Mui-focused': {
        color: '#38AA72',
    },

    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },

    '&.active-filter .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '2px solid #38AA72',
        },
    },

    '@media(max-width: 1360px)' : {
        width: 'calc(50% - 8px)',
        marginTop: '0',
    },

    '@media(max-width: 768px)' : {
        width: '100%',
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    // border-bottom: none;
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
    
    position: sticky;
    top: 0;
    z-index: 10;

    & th {
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
    }   
`));

const ReportsPageInfoMessage = styled('div')({
    marginTop: '16px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
})

const WrapIconInfoMessage = styled('div')({
    width: '24px',
    aspectRatio: 1,
})

const ReportsPageInfoMessageText = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        paddingBottom: '20px',
    }
}));

const RangePickerWrapper = styled('div')({
    boxSizing: 'border-box',
    width: 'calc(100%/5 - 16px*4/5)',
    marginTop: '20px',

    '@media(max-width: 1360px)' : {
        width: 'calc(50% - 8px)',
        marginRight: '0px',
        marginTop: '0px',
    } ,

    '@media(max-width: 991px)' : {
        width: '100%',
    } ,

    '@media(max-width: 768px)' : {
        width: '100%',
    },

    '&.active-filter fieldset': {
        border: '2px solid #38AA72',
    }
});

const TableIconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

const TableIcon = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
});

const ButtonsWrapper = styled('div')({
    display: 'flex',
    marginTop: '20px',
    marginLeft: 'auto',
    marginBottom: '24px',

    '@media(max-width: 1360px)' : {
        // marginTop: '20px',
        // marginLeft: '0',
    },

    '@media(max-width: 768px)' : {
        marginTop: '20px'
    }
});

// Height for scroll table
const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 315px - 46px);
        @media(max-width: 1360px){
            max-height: calc(100vh - 415px - 56px);
        };
        @media(max-width: 991px){
            max-height: calc(100vh - 442px - 56px);
        }; 
        @media(max-width: 767px){
            max-height: 70vh;
        }; 
    }
`));

const TableCellStyled = styled(TableCell)(({ theme }) => (`
    padding: 0px;
    border: none !important;
`));

const TableContentTrNum = styled('div')(({ theme }) => (`
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    
    &.top{
        border-top: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    }

    &.left{
        border-left: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    }

    &.right{
        border-right: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    }

    &.bottom{
        border-bottom: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    }

    &.big-col{
        padding: 44px 16px;
        min-width: 190px;
    }

    &.small-coll{
        min-width: 190px;
    }
`));


const filtersNotification = [
    {title: 'All', value: ''},
    {title: 'Waiting', value: 0},
    {title: 'Error', value: 1},
    {title: 'Success', value: 2},
];

const filtersStatus = [
    {title: 'All', value: ''},
    {title: 'Created', value: 0},
    {title: 'Pending', value: 1},
    {title: 'Success', value: 2},
    {title: 'Timeout', value: 3},
    {title: 'Cancel', value: 4},
    {title: 'Error', value: 5},
    {title: 'Mispaid', value: 6},
    {title: 'Double spend', value: 7},
    {title: 'Voided', value: 8},
    {title: 'Refunded', value: 9},
    {title: 'Unconfirmed', value: 10},
    {title: 'Network error', value: 11},
    {title: 'Pending error', value: 12},
    {title: 'Undefined', value: -1},
    {title: 'Low Amount', value: 13},
]

type DataQuery = {
    notifyStatus?: string | number | Array<string | number>,
    'paymentSystem.id'?: string |number,
    currency?: string,
    status?: string | number | Array<string | number>,
    date?: string,
}

type PropsType = {
    isLoading: boolean,
    reports: Array<ReportItem>,
    paymentSystemsDropdownList: Array<any>,
    currencyDropdownList: Array<CurrencyItem>,
    activeNode: {id: number},
    reportRequest: (data: DataQuery) => void,
    getPaymentSystemsListRequest: () => void,
    reportsDownload: (data: DataQuery) => void,
    currencyListRequest: () => void,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: any,
}

type StateType = {
    anchorEl: null | Node,
    valuseSeatch: string,
    currentChangeSystem: string,
    currentChangeCurrency: string, 
    paymentSystem: string,
    currency: string,
    status: string | number | Array<string | number>,
    notifyStatus: string | number | Array<string | number>,
    date: string,
    startDateCreated: string,
    endDateCreated: string,
    clearDates: boolean,
}

export class ReportsPage extends React.Component<PropsType, StateType> {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            valuseSeatch: '',
            currentChangeSystem: 'Payment system',
            currentChangeCurrency: 'Currency', 
            paymentSystem: '',
            currency: '',
            status: 2,
            notifyStatus: '',
            date: '',
            //...
            startDateCreated: `${new Date(new Date().getFullYear(), new Date().getMonth(), 1)}`,
            endDateCreated: `${new Date()}`,
            clearDates: true,
        }
    };

    setDatesCreated = (startDate, endDate) => {
        this.setState({
            startDateCreated: startDate, 
            endDateCreated: endDate, 
            clearDates: false,
            date: startDate && endDate ? `${format(new Date(startDate), 'yyyy-MM-dd')} 00:00 - ${format(new Date(endDate), 'yyyy-MM-dd')} 23:59` : '',
        });
    };

    handleChangeFilters = (prop) => (event) => {
        //@ts-ignore
        this.setState({[prop]: event.target.value})
    };

    onResetSelectedDates = () => {
        this.setState({
            valuseSeatch: '',
            currentChangeSystem: 'Payment system',
            currentChangeCurrency: 'Currency', 
            paymentSystem: '',
            currency: '',
            status: '',
            notifyStatus: '',
            date: '',
            startDateCreated: '',
            endDateCreated: '',
            clearDates: true,
        }, () => {this.updateData()});
    };

    componentDidMount() {
        const {getPaymentSystemsListRequest, currencyListRequest, routsUser, userRoles, isSuperadmin} = this.props;
        if(routsUser.some(rout => rout.child.includes('/report/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            getPaymentSystemsListRequest();
            currencyListRequest();
            this.updateData();
        }
        // getPaymentSystemsListRequest();
        // currencyListRequest();
        // this.updateData();
    }

    handleShow = () => {
        this.updateData();
    }

    updateData = () => {
        const {
            notifyStatus,
            paymentSystem,
            currency,
            status,
            startDateCreated,
            endDateCreated,
        } = this.state;

        const {
            reportRequest,
        } = this.props;

        const dataQuery = {
            'notifyStatus': notifyStatus,
            'paymentSystem.id': paymentSystem,
            'currency': currency,
            'status': status,
            'date': startDateCreated && endDateCreated ? `${format(new Date(startDateCreated), 'yyyy-MM-dd')} 00:00 - ${format(new Date(endDateCreated), 'yyyy-MM-dd')} 23:59` : null,
        };

        Object.keys(dataQuery).forEach(key => {
            if(key === 'notifyStatus' || key === 'status'){
                if (dataQuery[key] === '') delete dataQuery[key];
            } else {
                if (!dataQuery[key]) delete dataQuery[key];
            }
        });

        reportRequest(dataQuery);
    }

    handleChangeValueFilterListPS = (item) => {
        this.setState({
            currentChangeSystem: item.name,
            paymentSystem: item.id,
        });
    }

    handleChangeValueFilterListCyrrency = (item) => {
        this.setState({
            currentChangeCurrency: item.name,
            currency: item.id,
        });
    }

    handleDownload = () => {
        const {
            notifyStatus,
            paymentSystem,
            currency,
            status,
            startDateCreated,
            endDateCreated,
        } = this.state;

        const {
            reportsDownload,
        } = this.props;

        const dataQuery = {
            'notifyStatus': notifyStatus,
            'paymentSystem.id': paymentSystem,
            'currency': currency,
            'status': status,
            'date': startDateCreated && endDateCreated ? `${format(new Date(startDateCreated), 'yyyy-MM-dd')} 00:00 - ${format(new Date(endDateCreated), 'yyyy-MM-dd')} 23:59` : null,
        };

        Object.keys(dataQuery).forEach(key => {
            if (!dataQuery[key]) delete dataQuery[key];
        });

        reportsDownload(dataQuery);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeNode.id !== this.props.activeNode.id) {
            this.updateData();
        };
    };

    render() {

        const {
            isLoading,
            reports,
            paymentSystemsDropdownList,
            currencyDropdownList,
            routsUser, 
            userRoles,
            isSuperadmin,
        } = this.props;
    
        const {
            currentChangeSystem,
            currentChangeCurrency,
            status, 
            notifyStatus,
            // startDateCreated,
            date,
            clearDates,
        } = this.state;
        
        const accessedRole = routsUser.some(rout => rout.child.includes('/report/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleDownload = routsUser.some(rout => rout.child === '/report/download') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole && 
                    <>
                        <ReportsPageHeader>
                            <ReportsPageTitle
                                data-testid="titleMainReportsPage"
                            >
                                Reports
                            </ReportsPageTitle>
                            <Structure/>
                        </ReportsPageHeader>

                        <ReportsPageInfoMessage>
                            <WrapIconInfoMessage>
                                <InfoOutlineSvg size="24px"/>
                            </WrapIconInfoMessage>
                            <ReportsPageInfoMessageText>
                                The online report is only available for a period of 6 months. Downloading reports are available for the entire period.
                            </ReportsPageInfoMessageText>
                        </ReportsPageInfoMessage>

                        <ReportsPageFirstRow>

                            <StyledFormControl size="small" sx={{marginTop: '20px'}}>
                                <FilterList 
                                    currentValue={currentChangeSystem}
                                    list={paymentSystemsDropdownList}
                                    changeCurrentValue={this.handleChangeValueFilterListPS}
                                    defaultText={'Payment system'}
                                    dateTestId='titleSelectPaymentSystem'
                                />
                            </StyledFormControl>

                            <StyledFormControl size="small" sx={{marginTop: '20px'}}>
                                <FilterList 
                                    currentValue={currentChangeCurrency}
                                    list={currencyDropdownList}
                                    changeCurrentValue={this.handleChangeValueFilterListCyrrency}
                                    defaultText={'Currency'}
                                    dateTestId='titleSelectCurrency'
                                />
                            </StyledFormControl>

                            <StyledFormControl 
                                size="small" 
                                sx={{marginTop: '20px'}}
                                className={status !== '' ? 'active-filter' : ''} 
                            >
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={status}
                                    label="Status"
                                    onChange={(e) => this.handleChangeFilters('status')(e)}
                                    id="titleLabelSelectStatus"
                                >
                                    {filtersStatus.map(option => {
                                        return <StyledMenuItem key={Math.random()} value={option.value}>{option.title}</StyledMenuItem>
                                    })}
                                </Select>
                            </StyledFormControl>

                            <StyledFormControl 
                                className={notifyStatus !== '' ? 'active-filter' : ''} 
                                size="small" 
                                sx={{marginTop: '20px'}}
                            >
                                <InputLabel>Notify status</InputLabel>
                                <Select
                                    value={notifyStatus}
                                    label="Notify status"
                                    onChange={(e) => this.handleChangeFilters('notifyStatus')(e)}
                                    id="titleLabelSelectNotifyStatus"
                                >
                                    {filtersNotification.map(option => {
                                        return <StyledMenuItem key={Math.random()} value={option.value}>{option.title}</StyledMenuItem>
                                    })}
                                </Select>
                            </StyledFormControl>

                            <RangePickerWrapper
                                className={date !== '' ? 'active-filter' : ''}
                            >
                                <DateRangePicker 
                                    label="Date created"
                                    setDates={this.setDatesCreated}
                                    clearDates={clearDates}
                                    defaultStartMonthDate={true}
                                />
                            </RangePickerWrapper>

                        </ReportsPageFirstRow>

                        <ButtonsWrapper>
                            <Button 
                                onClick={this.handleShow}
                                sx={{marginRight: '12px'}}
                                data-testid="buttonShowReports"
                            >
                                Show
                            </Button>

                            {accessedRoleDownload &&
                                <Button 
                                    variant="outlined"
                                    onClick={this.handleDownload}
                                    sx={{marginRight: '12px'}}
                                    data-testid="buttonDownloadReports"
                                >
                                    Download
                                </Button>
                            }
                            
                            <Button
                                variant="outlined"
                                onClick={this.onResetSelectedDates}
                                data-testid="buttonClearReports"
                            >
                                Clear
                            </Button>
                        </ButtonsWrapper>

                        <StyledPaperTable>
                            <StyledTableContainer>

                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >

                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>

                                                <TableCellStyled rowSpan={2} width="190px">
                                                    <TableContentTrNum
                                                        className="bottom big-col"
                                                    >
                                                        Currency
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                                <TableCellStyled align="center" colSpan={2}>
                                                    <TableContentTrNum
                                                        className="left bottom"
                                                    >
                                                        Transactions number
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                                <TableCellStyled align="center" colSpan={2}>
                                                    <TableContentTrNum
                                                        className="left bottom right"
                                                    >
                                                        Transactions amount
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                                <TableCellStyled rowSpan={2} width="190px">
                                                    <TableContentTrNum
                                                        className="bottom big-col"
                                                    >
                                                        Summary
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                            </TableRow>
                                            <TableRow>

                                                <TableCellStyled width="270px">
                                                    <TableContentTrNum
                                                        className="left bottom small-coll"
                                                    >
                                                        Deposits
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                                <TableCellStyled width="270px">
                                                    <TableContentTrNum
                                                        className="left bottom small-coll"
                                                    >
                                                        Withdraws
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                                <TableCellStyled width="270px">
                                                    <TableContentTrNum
                                                        className="left bottom small-coll"
                                                    >
                                                        Deposits
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                                <TableCellStyled width="270px">
                                                    <TableContentTrNum
                                                        className="left bottom right small-coll"
                                                    >
                                                        Withdraws
                                                    </TableContentTrNum>
                                                </TableCellStyled>

                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>

                                            {reports ? reports.map(report => {
                                                return (
                                                    <TableRow key={Math.random()}>
                                                        <TableCell>{(report.currency || '').toUpperCase()}</TableCell>
                                                        <TableCell>{report.deposits_summary}</TableCell>
                                                        <TableCell>{report.withdraws_summary}</TableCell>
                                                        <TableCell>{report.deposits_amount}</TableCell>
                                                        <TableCell>{report.withdraws_amount}</TableCell>
                                                        <TableCell>{report.summary}</TableCell>
                                                    </TableRow>
                                                )
                                            }) :
                                                <TableRow>
                                                    <TableCell colSpan={6}>
                                                        <TableIconWrapper>
                                                            <TableIcon>
                                                                <EmptyDataSvg height="80px"/>
                                                                No results found.
                                                            </TableIcon>
                                                        </TableIconWrapper>
                                                    </TableCell>
                                                </TableRow>
                                            }

                                        </TableBody>
                                    </Table>

                                </OverlayScrollbarsComponent>

                            </StyledTableContainer>
                        </StyledPaperTable>
                    </>
                }

                {isLoading && <Loader />}
                
            </PageContainer>
        )
    }
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.report.isLoading,
        reports: state.report.reportList['hydra:member'],
        paymentSystemsDropdownList: state.paymentSystems.paymentSystemsList['hydra:member'],
        currencyDropdownList: state.currencyList.currencyList['hydra:member'],
        activeNode: state.nodeManagement.activeNode,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        reportRequest,
        getPaymentSystemsListRequest,
        reportsDownload,
        currencyListRequest,
    })
)(ReportsPage);