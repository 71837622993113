import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/payment_systems';

const PaymentSystems = {
	getPaymentSystems: (params) => {
    const query = Object.keys(params).length
      ? `?${queryString.stringify(params)}`
      : '';

		return baseApi.get(`${path}${query}`)
	},
  getPaymentSystem: (id) => {
    return baseApi.get(`${path}/${id}`)
  },
  changePaymentSystem: (id, body) => {
    return baseApi.patch(`${path}/${id}`, body)
  },
  createPaymentSystem: (body) => {
    return baseApi.post(`${path}`, body)
  },
  getPaymentSystemsListFiltered: () => {
    return baseApi.get(`${path}/dropdown_list`)
  },
  getPaymentSystemsList: () => {
    return baseApi.get(`${path}/enumerations`)
  },
  getPaymentSystemsAmount: () => {
    return baseApi.get(`${path}/amount`)
  }
}

export default PaymentSystems;