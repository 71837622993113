export namespace actionTypes {
    export const SET_REQUEST_ELASTIC_SEARCH = 'SET_REQUEST_ELASTIC_SEARCH';
    export const SET_REQUEST_ELASTIC_SEARCH_LOADING = 'SET_REQUEST_ELASTIC_SEARCH_LOADING';
    export const SET_REQUEST_ELASTIC_SEARCH_TYPES = 'SET_REQUEST_ELASTIC_SEARCH_TYPES';
}

export type RequestTypesItem = {
    "@id": string,
    "@type": string,
    id: number,
    text: string,
}

export type ElasticListItem = {
    "@id": string,
    "@type": string,
    id: string,
    elkprefix: string,
    insertedAt: number,
    type: string,
    transaction_id: string,
    data: string,
    createdAt: string,
}

type ElasticList = {
	'@context': string;
	'@id': string;
	'hydra:member': Array<ElasticListItem>;
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

interface initialStateType {
	requestElasticSearch: ElasticList,
	isLoading: boolean,
	requesrTypes: {
		"@context": string,
		"@id": string,
		"@type": string,
		"hydra:totalItems": number,
		"hydra:member": Array<RequestTypesItem>,
		"hydra:search": any,
	},
};

const initialState: initialStateType = {
	requestElasticSearch: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	isLoading: false,
	requesrTypes: {
		"@context": '',
		"@id": '',
		"@type": '',
		"hydra:totalItems": 11,
		"hydra:member": [],
		"hydra:search": {},
	},
};

export const requestElasticSearch = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_REQUEST_ELASTIC_SEARCH_LOADING:
			return { 
				...state, 
				isLoading: true,
			};
		case actionTypes.SET_REQUEST_ELASTIC_SEARCH:
			return { 
				...state, 
				requestElasticSearch: payload,
				isLoading: false,
			};
		case actionTypes.SET_REQUEST_ELASTIC_SEARCH_TYPES:
			return { 
				...state, 
				requesrTypes: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default requestElasticSearch;