import {ActionTypes} from '../reducers/mappingCascades';
import API from '../services/api';

const requestStart = () => ({
    type: ActionTypes.FETCH_MAPPING_START
});

const getCascadeList = (data) => ({
    type: ActionTypes.FETCH_MAPPING_SUCCESS,
    payload: data
});

const requestFailure = (error) => ({
    type: ActionTypes.FETCH_MAPPING_FAILURE,
    payload: error
});

const setCascadeListSuccess = () => ({
    type: ActionTypes.SET_MAPPING_SUCCESS
});

export const getCascadeListRequest = () => dispatch => {
    dispatch(requestStart());
    return API.mappingCascades
        .getCascadeList()
        .then(response => dispatch(getCascadeList(response.data)))
        .catch(error => dispatch(requestFailure(error.message)))
};

export const setCascadeListRequest = body => dispatch => {
    return API.mappingCascades
        .setCascadeList(body)
        .then((resp) => {
            const sizeLimitMessage = resp.data['hydra:member']?.[0]?.[0]?.message;
            sizeLimitMessage ? dispatch(requestFailure(sizeLimitMessage)) : dispatch(setCascadeListSuccess());
        })
        .catch(error => dispatch(requestFailure(error.message)))
};