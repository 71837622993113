import React from 'react';

export const LoaderMini: React.FC = () => {
    return (
        <div className="lds-ring-mini">
            <div>
                </div>
                    <div>
                        </div><div>
                    </div>
                <div>
            </div>
        </div>
    )
}
