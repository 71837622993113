import React, {useEffect, useMemo, useCallback} from 'react';
import TreeModel from "tree-model-improved";

function findById(
    node: TreeModel.Node<any>,
    id: string
  ): TreeModel.Node<any> | undefined {
    return node.first((n: any) => n.model.id === id);
};

export const useTreeModel = (initialData) => {
    const [data, setData] = React.useState(initialData!);

    useEffect(() => {
        setData(initialData!);
    }, [initialData]);

    // debugger;
    const root = useMemo(() => new TreeModel().parse(data), [data]);
    const find = useCallback((id) => findById(root, id), [root]);

    const update = () => {
        setData({...root.model});
    };

    const onToggle = (id: string, isOpen: boolean) => {
        const node = find(id);
        if (node) {
          node.model.isOpen = isOpen;
          update();
        }
    };

    return {
        data: root.model,
        onToggle,
    }
};
