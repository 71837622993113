import React from "react";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from "@mui/material/styles";


const StyledLabelSwitched = styled(FormControlLabel)(({ theme, disabled }) => (`
    margin-left: 0px;       
    & .MuiTypography-root{
        font-size: 14px;
        color: ${theme.palette.text.primary};        
        line-height: 14px;
    }
    & .MuiSwitch-root{
        width: 55px;  
        height: 26px;
        & .MuiSwitch-switchBase{
            padding: 7px;
            transform: translateX(-3px);
            &.Mui-checked{
                color: #fff;
                filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
                transform: translateX(26px);
            }
        }
        & .MuiSwitch-track{
            opacity: 1;
            background-color: #95929C;
            position: relative;

            &:before{
                display: none;
            }
            &:after{
                display: flex;
            }
        }
        & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track{
            background-color: ${disabled ? 'rgba(0, 0, 0, 0.38)' : '#38AA72'};
            opacity: 1;

            &:after{
                display: none;
            }
            &:before{
                display: flex;
            }
        }
    }
`))

const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 2,
    '& .MuiSwitch-track': {
      borderRadius: '20px',      
      width: '100px',
      '&:before, &:after': {
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: '600',
        position: 'absolute',
        top: '50%',
        color: '#ffffff',
        transform: 'translateY(-50%)',
        width: 18,
        height: 18,
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
      },
      '&:before': {
        content: '"On"',
        left: '4px',
      },
      '&:after': {
        content:'"Off"',
        right: '11px',
      },
    },
    '& .MuiSwitch-thumb': {
        width: '18px',
        height: '18px',
        margin: '-3px 0px 0px 0px',
    },
}));

type SwitchLabel = {
    label: string,
    sx?: any,
    checked?: boolean,
    onChange?: (event) => void;
    disabled?: boolean;
}

export const SwitchComponent = (props: SwitchLabel) => {
    const {sx, label, checked, onChange, disabled = false} = props;
    return <StyledLabelSwitched
        control={
            <StyledSwitch 
                checked={checked} 
                onChange={onChange}
                disabled={disabled}
            />
        }  
        label={label}      
        sx={sx}
        disabled={disabled}
    />
}