import {ActionTypes} from '../reducers/blockchainConfig';
import API from '../services/api';

export const getBlockchainConfig = data => {
    return {
        type: ActionTypes.GET_BLOCKCHAIN_CONFIG,
        payload: data
    };
};

export const createBlockchainConfigSuccess = () => {
    return {
        type: ActionTypes.CREATE_BLOCKCHAIN_CONFIG_SUCCESS
    }
};

export const createBlockchainConfigError = (error) => {
    return {
        type: ActionTypes.CREATE_BLOCKCHAIN_CONFIG_ERROR,
        payload: error
    }
};

export const updateBlockchainConfigSuccess = () => {
    return {
        type: ActionTypes.UPDATE_BLOCKCHAIN_CONFIG_SUCCESS
    }
};

export const updateBlockchainConfigError = (error) => {
    return {
        type: ActionTypes.UPDATE_BLOCKCHAIN_CONFIG_ERROR,
        payload: error
    }
};

export const getBlockchainConfigRequest = (id) => dispatch => {
    return API.blockchainConfig
    .getBlockchainConfig(id)
    .then(response => {
        dispatch(getBlockchainConfig(response.data))
    })
    .catch(error => console.log(error))
};

export const createBlockchainConfigRequest = (data) => dispatch => {
    return API.blockchainConfig.createBlockchainConfig(data)
    .then(() => dispatch(createBlockchainConfigSuccess()))
    .catch((error) => dispatch(createBlockchainConfigError(error.message)));
};

export const updateBlockchainConfigRequest = (id, data) => dispatch => {
    return API.blockchainConfig
    .updateBlockchainConfig(id, data)
    .then(() => dispatch(updateBlockchainConfigSuccess()))
    .catch((error) => dispatch(updateBlockchainConfigError(error.message)));
};

export const setInitialConfig = () => dispatch => {
    return dispatch(
        {
            type: ActionTypes.SET_INITIAL_CONFIG
        }
    );
};
