import React from 'react';
import {Button as MaterialButton} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const StyledButton = styled(MaterialButton)(({ variant, size, theme }) => ({
    height: size === 'medium' ? (window.screen.width > 767 ? '40px' : '30px') : '30px',
    backgroundColor: variant === 'outlined' ? 'transparent' : '#38AA72',
    border: '1px solid #38AA72',
    color: variant === 'outlined' ? '#38AA72' : '#FFFFFF',
    fontSize: size === "medium" ? '14px' : '12px',
    minWidth: '30px',
    'path': {
        fill: variant === 'outlined' ? '#38AA72' : '#FFFFFF'
    },
    '&:hover': {
        backgroundColor: variant === 'outlined' ? 'transparent' : '#38AA72',
        border: '1px solid #38AA72',
    },
    '&:disabled':{
        border: variant === 'outlined' ? '1px solid #C0C0C0' : 'none',

        'path': {
            fill: '#C0C0C0'
        }
    },
    '@media(max-width: 768px)' : {
        fontSize: '12px',
        paddingLeft: '12px',
        paddingRight: '12px',
        lineHeight: '100%',
    },
}))

const StyledDeleteButton = styled(MaterialButton)(({ variant, theme }) => ({
    height: window.screen.width > 767 ? '40px' : '30px',
    backgroundColor: '#D63639',
    border: '1px solid #D63639',
    color: variant === 'outlined' ? '#38AA72' : '#FFFFFF',
    fontSize: '14px',
    paddingLeft: '12px',
    paddingRight: '12px',
    '&:hover': {
        backgroundColor: '#D63639',
        border: '1px solid #D63639',
    },
    '@media(max-width: 768px)' : {
        fontSize: '12px',
        paddingLeft: '12px',
        paddingRight: '12px',
        lineHeight: '100%',
    },
}))

type Props = {
    variant?: "text" | "contained" | "outlined" | undefined;
    sx?: Object;
    size?: 'small' | 'medium' | 'large';
    onClick?: (any) => void;
    children: React.ReactNode;
    startIcon?: React.ReactNode;
    isDeleteButton?: boolean;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    title?: string;
};

export const Button = (props: Props) => {
    const {
        variant = "contained",
        size = "medium",
        isDeleteButton,
        children,
        onClick,
        disabled,
        title,
        ...rest
    } = props;

    return (
        title 
            ? isDeleteButton 
                ? <Tooltip title={title} placement="top">
                        <span>
                            <StyledDeleteButton disabled={disabled} onClick={onClick} {...rest}>{children}</StyledDeleteButton>
                        </span>
                    </Tooltip>
                    : <Tooltip title={title} placement="top">
                        <span>
                            <StyledButton disabled={disabled} onClick={onClick} variant={variant} size={size} {...rest}>{children}</StyledButton>
                        </span>
                    </Tooltip>
            : isDeleteButton 
                ? <StyledDeleteButton disabled={disabled} onClick={onClick} {...rest}>{children}</StyledDeleteButton> 
                : <StyledButton disabled={disabled} onClick={onClick} variant={variant} size={size} {...rest}>{children}</StyledButton>
    )
};