import React from 'react';
import { styled } from "@mui/material/styles";
import { MappingPageOrderHeaderText } from '../index';
import { Button } from '../../../components/button';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../../store/configure-store';
import useResizeObserver from "use-resize-observer";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { InfoOutlineSvg } from "../../../components/svg/info-outline-svg";
import {OrderItemSelectElement} from './result-ordered-item'

const WrapList = styled('div')(({theme }) => ({
    boxSizing: 'border-box',
    paddingTop: '20px',
}));

const HeaderPaymentAccounts = styled('div')(({theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: '0px',
}));

const OrderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

const MethodsInfo = styled('div')({
    margin: '16px 0px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
});

const MethodsInfoText = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary
}));

const WrapMappingPageOrderHeaderText = styled('div')(({ theme }) => ({
    '@media(max-width: 420px)' : {
        width: '100%',
    }
}));

const ButtonStyled = styled(Button)({
    porition: 'relative', 
    right: '-30px',

    '@media(max-width: 420px)' : {
        right: '-30px',
        marginLeft: 'auto',
        marginBottom: '10px',
    }
})

type PropsType = {
    methods: any, 
    onDelete: any, 
    openDrag: any, 
    openEditor: any, 
    setEditListState: any,
    rerenderList: any,
    setRerenderList: any,
}


export const ResultAccountsListForEdit: React.FC<PropsType> = ({
    methods, 
    onDelete, 
    openDrag, 
    openEditor, 
    setEditListState,
    rerenderList,
    setRerenderList,
}) => {
    const { ref } = useResizeObserver<HTMLDivElement>();

    const handleOpenRulesAll = () => {
        openEditor({})
    }

    const handleDragEnd = ({ destination, source }) => {
        if (!destination) return;
        openDrag(prev => {
            const result = Array.from(prev);
            const [removed] = result.splice(source.index, 1);
            result.splice(destination.index, 0, removed);
            setRerenderList(prev => !prev);
            return result;
        });
        setTimeout(() => {setRerenderList(prev => !prev)}, 1)
    };

    const handleConfirm = (itemEl: any, indexEl: any, sum: any) => {
        setEditListState(prev => {
            const changedPrev = [...prev];
            changedPrev.forEach((item, index) => {
                if(indexEl === index){
                    item.rules.sum_limit = Number(sum.replace(/\s/g, ''));
                }
            })
            return changedPrev
        });
    };

    return(
        <>
            {rerenderList &&
                <WrapList>
                    {methods.length !== 0 && 
                        <HeaderPaymentAccounts>
                            <WrapMappingPageOrderHeaderText>
                                <MappingPageOrderHeaderText>
                                    Order of payment accounts
                                </MappingPageOrderHeaderText>
                                <MethodsInfo>
                                    <InfoOutlineSvg size="16px"/>
                                    <MethodsInfoText>
                                        Drag to reorder.
                                    </MethodsInfoText>
                                </MethodsInfo>
                            </WrapMappingPageOrderHeaderText>

                            <ButtonStyled 
                                variant='outlined' 
                                onClick={handleOpenRulesAll}
                            >
                                All Rules
                            </ButtonStyled>
                        </HeaderPaymentAccounts>
                    }

                    <div ref={ref} style={{width: '100%', maxHeight: '70vh'}}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable-order">
                                {(provided) => (
                                    <OrderContainer ref={provided.innerRef} {...provided.droppableProps}>
                                        {(methods || []).map((item, index) => {
                                            return (
                                                <Draggable
                                                    key={`${item.psname}_${item.accname}_${item.methname}_${index}`}
                                                    index={index}
                                                    draggableId={`${item.psname}_${item.accname}_${item.methname}_${index}`}
                                                >
                                                    {(provided) => (
                                                        <OrderItemSelectElement 
                                                            provided={provided}
                                                            itemEl={item}
                                                            index={index}
                                                            removeMethod={onDelete}
                                                            onSave={handleConfirm}
                                                        />
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </OrderContainer>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                </WrapList>
            }
        </>
    )
} 

export default compose(
    connect((state: RootState | any) => ({
        
    }), {})
)(ResultAccountsListForEdit);