import {ActionTypes} from '../reducers/requestCleaner';
import API from '../services/api';

export const requestSuccess = () => {
	return {
		type: ActionTypes.FETCH_REQUEST_CLEANER_SUCCESS,
	};
};

export const requestFailure = () => {
	return {
		type: ActionTypes.FETCH_REQUEST_CLEANER_FAILURE,
	}
};

export const requestCleanerRequest = data => dispatch => {
	return API.requestCleaner
		.requestCleaner(data)
		.then(() => {
			dispatch(requestSuccess());
		})
		.catch(() => dispatch(requestFailure()));
};
