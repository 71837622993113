import React from "react";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import { DefaultImage } from '../../components/default-image';
import { StatusActive } from '../../components/status-active';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {SerachTableHeader} from '../../components/search-table-header';
import {getPaymentSystemsRequest, getPaymentSystemRequest, createPaymentSystemRequest} from '../../actions/paymentSystems';
import {Loader} from '../../components/loaders/loader';
import {Link} from 'react-router-dom';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {Button} from '../../components/button';
import {CreateNewPaymentSystemGlobal} from './add-new-payment-system';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { AccessDeniedAlert } from "../../components/access-denied";


const PageContainer = styled('div')(({theme}) => (`
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    position: relative;
    
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const PSPageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    @media(max-width: 768px){
        flex-wrap: wrap;
        margin-bottom: 24px;
    }
    button{
        svg{
            margin-right: 8px;
        }
    }
`);

const PSPageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 24px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
        padding-bottom: 16px;
    }
`));

const PSPageHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 991px){
        width: 100%;
        margin-bottom: 16px;
    };
    @media(max-width: 768px){
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 0px;
        justify-content: space-between;
    }
`);

const TableCellContent = styled('div')(`
    display: flex;
    align-items: center;
`);

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default}; 
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
        border-bottom: none;
        box-sizing: border-box;
        box-shadow: none;
    }
    .MuiTablePagination-root{
        border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
        margin-top: -1px;
        position: relative;
        z-index: 10;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 185px - 56px);
        @media(max-width: 991px){
            max-height: calc(100vh - 200px - 56px);
        };
        @media(max-width: 767px){
            max-height: calc(100vh - 210px - 56px);
        };
    }
`));

const WrapLogoWidth = styled('div')(({ theme }) => (`
    & {
        width: 80px;
    }
`));

const StyledLink = styled(Link)(({ theme }) => (`
    text-decoration: none;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

const StyledModal = styled(Modal)(({ theme }) => (`
    display: flex;
    justify-content: center;
    align-items: center;
`));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

class PaymentSystemGlobalMainPage extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            rowsPerPage: 20,
            page: 0,
            name: '',
            loaded: [],
            openCreate: false,
        };
    };

    handleOpenCreate = () => this.setState({openCreate: true});
    handleCloseCreate = () => this.setState({openCreate: false});

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({page: newPage}, () => {this.updateData()});
    };
    
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0}, () => {this.updateData()});
    };

    handleSearchSubmit = (value) => {
        this.setState({name: value}, () => {this.updateData()});
    };

    handleCancelSearch = () => {
        if (this.state.name) {
            this.setState({name: ''}, () => {this.updateData()});
        };
    };

    updateData = () => {
        const {page, name} = this.state;
        const {getPaymentSystemsRequest} = this.props;

        const data = {
            page: page + 1,
            name: name
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        getPaymentSystemsRequest(data);
    };

    componentDidMount() {
        const {routsUser, userRoles, isSuperadmin} = this.props;
        if(routsUser.some(rout => rout.child.includes('/payment-system/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    };

    handleSave = (body) => {
        // const {createPaymentSystemRequest} = this.props;
        // createPaymentSystemRequest(body);
    }

    render() {
        const {
            page,
            name,
            rowsPerPage,
            openCreate,
        } = this.state;

        const {
            paymentSystems,
            paymentSystemsCount,
            isLoading,
            routsUser, 
            userRoles,
            isSuperadmin,
        } = this.props;

        const accessedRole = routsUser.some(rout => rout.child.includes('/payment-system/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleEdit = routsUser.some(rout => rout.child === '/payment-system/edit') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleCreate = routsUser.some(rout => rout.child === '/payment-system/add') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole && 
                    <>
                        <PSPageHeader>
                            <PSPageHeaderLeft>
                                <PSPageHeaderText>Global payment systems</PSPageHeaderText>
                            </PSPageHeaderLeft>
                            {accessedRoleCreate &&
                                <Button onClick={this.handleOpenCreate}>
                                    <AddBoxOutlinedIcon/> 
                                    add payment system
                                </Button>
                            }
                        </PSPageHeader>
                        <StyledTableContainer>
                            <TableContainer component={Paper}>
                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >
                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>
                                                <TableCell width='175px'>Logo</TableCell>

                                                <SerachTableHeader 
                                                    label='Name' 
                                                    handleSearchSubmit={this.handleSearchSubmit}
                                                    handleCancelSearch={this.handleCancelSearch}
                                                    isActiveFilter={Boolean(name)}
                                                />

                                                <TableCell>
                                                    <TableCellContent sx={{width: '120px'}}>
                                                        Use brands
                                                    </TableCellContent>
                                                </TableCell>
                                                <TableCell  width='120px' align="right">
                                                    <TableCellContent>
                                                            Active
                                                    </TableCellContent>
                                                </TableCell>

                                                {accessedRoleEdit &&
                                                    <TableCell width='75px' align="right">
                                                        Action
                                                    </TableCell>
                                                }

                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {paymentSystems.map(row => {
                                                return (
                                                    <TableRow key={row.id}>
                                                        <TableCell>
                                                            <WrapLogoWidth>
                                                                {this.state.loaded.includes(row.id) ? null : <DefaultImage sx={{maxWidth: '100%', height: '64px'}}/>}
                                                                <img
                                                                    src={row.logoAbsoluteUrl}
                                                                    style={this.state.loaded.includes(row.id) ? {} : {display: 'none'}}
                                                                    width='100%' alt={row.logo}
                                                                    onLoad={() => this.setState((state) => { return {loaded: [...state.loaded, row.id]} })}
                                                                />
                                                            </WrapLogoWidth>
                                                        </TableCell>
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.usageCount}</TableCell>
                                                        <TableCell>
                                                            <StatusActive active={row.active}/>
                                                        </TableCell>

                                                        {accessedRoleEdit &&
                                                            <TableCell align="right">
                                                                <StyledLink to={`/payment-systems-global/${row.id}`}>
                                                                    <IconButton aria-label="menu User" size="large">
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                </StyledLink>
                                                            </TableCell>
                                                        }

                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>    
                                </OverlayScrollbarsComponent>
                            </TableContainer>   
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={paymentSystemsCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />             
                        </StyledTableContainer>

                        <StyledModal
                            open={openCreate}
                            onClose={this.handleCloseCreate}
                        >
                            <StyledBoxPopup>
                                <CreateNewPaymentSystemGlobal 
                                    onClose={this.handleCloseCreate}
                                    onSave={this.handleSave}
                                />
                            </StyledBoxPopup>
                        </StyledModal>
                    </>
                }

                {isLoading && <Loader />}
            </PageContainer>
        );
    };
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.paymentSystems.isLoading,
        paymentSystems: state.paymentSystems.paymentSystems['hydra:member'],
        paymentSystemsCount: state.paymentSystems.paymentSystems['hydra:totalItems'],
        userRoles: state.currentUser.userData.roles,
        routsUser: state.currentUser.userData.routs,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        getPaymentSystemsRequest, 
        getPaymentSystemRequest, 
        createPaymentSystemRequest
    })
)(PaymentSystemGlobalMainPage);
