import React, {Component} from "react";
import {styled} from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import {DateRangePicker} from '../../components/date-range-picker';
import {SerachTableHeader} from '../../components/search-table-header';
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import Menu from '@mui/material/Menu';
import {TableFilter} from "../../components/table-filter";
import {historyTransStatusRequest} from '../../actions/historyTransStatus';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {format} from 'date-fns';
import {Loader} from '../../components/loaders/loader';
import {getStatusesListRequest} from '../../actions/transactions';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {PaginationPPS} from '../../components/pagination';
import { itemStatusType } from "../../reducers/historyTransStatus";
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => (`
    position: relative;
    padding: 40px 40px 0 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const QueuePageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 32px;
    @media(max-width: 768px){
        flex-wrap: wrap;
    }
    button{
        svg{
            margin-right: 8px;
        }
    }
`);

const QueuePageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 24px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
        padding-bottom: 16px;
    }
`));

const QueuePageHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 991px){
        width: 100%;
        // margin-bottom: 16px;
    }
    @media(max-width: 768px){
        flex-wrap: wrap;
        width: 100%;
        // margin-bottom: 12px;
        justify-content: space-between;
    }
`);

const TableCellContent = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
    widt: 25%;
`);

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default}; 
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const WithSortedTableCellContent = styled(TableCellContent)(({ theme }) => (`
    position: relative;
    // padding-right: 30px;
    min-width: 120px;
    widt: 25%;
    box-sizing: border-box;
    justify-content: flex-start;
`));

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const SortButtons = styled('div')(({ theme }) => (`
    position: absolute;
    right: 0;
    top: -10px;
`));

const SortButtonsSongleToLeft = styled(SortButtons)(({ theme }) => (`
    position: absolute;
    right: 0;
    top: -10px
`));

const SortButtonTop = styled('div')(({ theme }) => (`
    & svg{ 
        cursor: pointer;
        bottom: -7px;
        position: relative;
        width: 12px;
        height: 12px;
    }
`));

const SortButtonBottom = styled('div')(({ theme }) => (`
    & svg{ 
        cursor: pointer;
        position: relative;
        top: -5px;
        width: 12px;
        height: 12px;
    }
`));

// const StyledTableCellInfo = styled(TableCell)(({ theme }) => (`
//     // word-break: break-all;
// `));

const ReportsPageFirstRow = styled('div')({
    marginTop: '24px',
    marginBottom: '32px',
    display: 'flex',
    flexWrap: 'wrap',
    '@media(max-width: 768px)' : {
        marginTop: '0',
    }
});

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    // border-bottom: none;
`));

const RangePickerWrapper = styled('div')(`
    margin-right: 16px;
    width: 226px;
    @media(max-width: 768px){
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
        margin-bottom: 16px;
    }    
`);

const IconWrapper = styled('div')({
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const StyledTableCellContentJustify = styled(TableCellContent)(({ theme }) => (`
    justify-content: flex-start;
`));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}))

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 250px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 310px - 56px);
        }; 
    }
`));

interface PropsType {
    isLoading: boolean,
    historyTransStatus: itemStatusType[],
    statusesList: [],
    historyTransStatusRequest: (data: any) => void, 
    getStatusesListRequest: () => void,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

interface PropsState {
    page: number,
    rowsPerPage: number,
    sorting: string,
    id: string,
    transactionId: string,
    startDate: string,
    endDate: string,
    anchorElStatus: null | any,
    statuses: [],
    clearDates: boolean
}

export class HistoryTransactionsStatusesPage extends Component<PropsType, PropsState> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            sorting: 'desc',
            id: '',
            transactionId: '',
            startDate: '',
            endDate: '',
            anchorElStatus: null,
            statuses: [],
            clearDates: false
        }
    };

    handleChangePage = (newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget})
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleClickSortTopUpdate = () => {
        this.setState({sorting: 'asc'}, () => this.updateData())
    };

    handleClickSortBottomUpdate = () => {
      this.setState({sorting: 'desc'}, () => this.updateData())
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
       this.setState({[prop]: value}, () => {this.updateData()});
    };

    handleCancelSearch = (prop) => () => {
        if (this.state[prop]) {
            //@ts-ignore
            this.setState({[prop]: ''}, () => {this.updateData()});
        };
    };

    setDates = (startDate, endDate) => {
        this.setState({startDate: startDate, endDate: endDate, clearDates: false});

    };

    onConfirmSelectedDates = () => {
        this.updateData();
    };

    onResetSelectedDates = () => {
       this.setState({startDate: '', endDate: '', clearDates: true, page: 0}, () => {this.updateData()});
    };

    onChangeFilter = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
        this.handleStatusMenuClose()
    };

    componentDidMount() {
        const {
            getStatusesListRequest,
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/history-transaction-status/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
            getStatusesListRequest();
        }
    }

    updateData = () => {
        const {
            page,
            sorting,
            id,
            transactionId,
            startDate,
            endDate,
            statuses
        } = this.state;

        const {
            historyTransStatusRequest
        } = this.props;

        const data = {
            'page': page + 1,
            'order[updatedAt]': sorting,
            'updatedAt[after]': startDate ? format(new Date(startDate), 'yyyy-MM-dd') : null,
            'updatedAt[before]': endDate ? format(new Date(endDate), 'yyyy-MM-dd') : null,
            'id': id,
            'transaction.id': transactionId,
            'status[]': statuses

        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        historyTransStatusRequest(data);
    }

    render() {

        const {
            historyTransStatus,
            isLoading,
            statusesList,
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;
    
        const {
            id,
            transactionId,
            page,
            rowsPerPage,
            anchorElStatus,
            clearDates,
            statuses
        } = this.state;

        const isStatusFilterOpen = Boolean(anchorElStatus);

        const accessedRole = routsUser.some(rout => rout.child.includes('/history-transaction-status/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;    

        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole &&
                    <>
                        <QueuePageHeader>
                            <QueuePageHeaderLeft>
                                <QueuePageHeaderText>
                                    History of transactions statuses
                                </QueuePageHeaderText>
                            </QueuePageHeaderLeft>
                        </QueuePageHeader>  

                        <ReportsPageFirstRow>
                            <RangePickerWrapper sx={{width: '100%', maxWidth: '290px'}}>
                                <DateRangePicker label="Date range" setDates={this.setDates} clearDates={clearDates}/>
                            </RangePickerWrapper>
                            <Button sx={{marginRight: '12px'}} onClick={this.onConfirmSelectedDates}>confirm</Button>
                            <Button variant="outlined" onClick={this.onResetSelectedDates}>reset</Button>
                        </ReportsPageFirstRow>

                        <StyledTableContainer>
                            <StyledPaperTable>
                                <TableContainer component={Paper}>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>

                                                <WithSortedSerachTableHeader 
                                                    width='25%' 
                                                    label='ID' 
                                                    handleSearchSubmit={this.handleSearchSubmit('id')}
                                                    handleCancelSearch={this.handleCancelSearch('id')}
                                                    isActiveFilter={Boolean(id)}
                                                />

                                                <WithSortedSerachTableHeader 
                                                    width='25%'
                                                    label='Transaction ID'
                                                    handleSearchSubmit={this.handleSearchSubmit('transactionId')}
                                                    handleCancelSearch={this.handleCancelSearch('transactionId')}
                                                    isActiveFilter={Boolean(transactionId)}
                                                />

                                                <TableCellWithActiveClass
                                                    className={ statuses.length > 0 ? 'activeFilterClass' : ''}
                                                >
                                                    <StyledTableCellContentJustify>
                                                        Status
                                                        <IconWrapper>
                                                            <FilterListSvg size="16px" onClick={this.handleStatusMenuClick}/>
                                                        </IconWrapper>
                                                        <StyledMenu
                                                            MenuListProps={{
                                                            'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorElStatus}
                                                            open={isStatusFilterOpen}
                                                            onClose={this.handleStatusMenuClose}
                                                            PaperProps={{
                                                                style: {
                                                                    width: '210px',
                                                                },
                                                            }}
                                                        >
                                                            <TableFilter 
                                                                filterItems={Object.entries(statusesList).map(item => {
                                                                    return {title: item[1], value: item[0]};
                                                                })}
                                                                initialData={statuses}
                                                                onApplyFilter={this.onChangeFilter} 
                                                                onClearAll={this.onChangeFilter}
                                                            />
                                                        </StyledMenu>
                                                    </StyledTableCellContentJustify>
                                                </TableCellWithActiveClass>

                                                <TableCell>
                                                    <WithSortedTableCellContent>
                                                        Updated at
                                                        <SortButtonsSongleToLeft>
                                                            <SortButtonTop onClick={this.handleClickSortTopUpdate}>
                                                                <SvgIcon viewBox="0 0 9 2">
                                                                    <path d="M0.666504 4.66602L4.6665 0.666016L8.6665 4.66602H0.666504Z" fill="#979799"/>
                                                                </SvgIcon>
                                                            </SortButtonTop>
                                                            <SortButtonBottom onClick={this.handleClickSortBottomUpdate}>
                                                                <SvgIcon viewBox="0 0 9 9">
                                                                    <path d="M0.666504 0.666016L4.6665 4.66602L8.6665 0.666016H0.666504Z" fill="#979799"/>
                                                                </SvgIcon>
                                                            </SortButtonBottom>
                                                        </SortButtonsSongleToLeft>
                                                    </WithSortedTableCellContent>
                                                </TableCell>

                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {(historyTransStatus || []).map(row => {
                                                return (
                                                    <TableRow key={row.id}>
                                                        <TableCell>
                                                            {row.id}
                                                        </TableCell>                                        
                                                        <TableCell>
                                                            {row.transaction.split('/').pop()}
                                                        </TableCell>                                        
                                                        <TableCell>
                                                            {row.status}
                                                        </TableCell>                                        
                                                        <TableCell>
                                                            {format(new Date(row.updatedAt), 'yyyy-MM-dd hh:mm')}
                                                        </TableCell>                                       
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>

                                </OverlayScrollbarsComponent>

                                </TableContainer>

                                <PaginationPPS
                                    page={page}
                                    rows={rowsPerPage}
                                    onPageChange={this.handleChangePage}
                                />
                            </StyledPaperTable>      
                        </StyledTableContainer>  
                    </> 
                }  
            
            {isLoading && <Loader />}

        </PageContainer>
        )
    }
}

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.historyTransStatus.isLoading,
        historyTransStatus: state.historyTransStatus.historyTransStatuses['hydra:member'],
        statusesList: state.transactions.statusesList,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        historyTransStatusRequest, 
        getStatusesListRequest,
    })
)(HistoryTransactionsStatusesPage)
