import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import Structure from '../../components/structure';
import { InfoOutlineSvg } from "../../components/svg/info-outline-svg";
import {Input} from '../../components/input';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import {SearchSvg} from "../../components/svg/search-svg";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Button } from '../../components/button';
import { StatusOkSvg } from '../../components/svg/status-ok-svg';
import { StatusErrorSvg } from '../../components/svg/status-error-svg';
import { StatusWaitingSvg } from '../../components/svg/status-waiting-svg';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import EditMappingForm from './edit-mapping-form';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {setCascadeListRequest, getCascadeListRequest} from '../../actions/mappingCascades';
import {Loader} from '../../components/loaders/loader';
import {convertSecondsToTimeString, isJson} from '../../utils';
import Snackbar from '@mui/material/Snackbar';
import { InfoPopup } from "../../components/info-popup";
import useResizeObserver from "use-resize-observer";

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const MappingPageHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const MappingPageHeader = styled('div')({
    display: 'flex',
    marginBottom: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
    }
});

const MappingPageContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '484px',

    '@media(max-width: 768px)' : {
        width: '100%',
    }
});

const MappingPageInfoMessage = styled('div')({
    marginTop: '16px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center'
});

const MappingPageInfoMessageText = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary
}));

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const CurrencyItem = styled('div')(({ theme }) => ({
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 12px',
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    width: '100%',

    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
    }
}));

const CurrencyItemIsSetText = styled('div')({
    color: '#38AA72'
});

const StyledSearchInput = styled('input')(({ theme }) => ({
    width: '100%',
    marginRight: '20px',
    boxSizing: 'border-box',
    height: '36px',
    paddingLeft: '20px',
    paddingRight: '38px',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : '#C0C0C0'}`,
    borderRadius: '4px',
    background: 'transparent',
    color: theme.palette.text.primary,
    outline: 'none !important',
    '&::placeholder': {
        opacity: '1',
        color: theme.palette.text.primary,
    },
    '&:focus': {
        borderColor: '#38AA72',
    }
}));

const SearchInputWrapper = styled('div')({
    position: 'relative',
    margin: '8px 8px 8px 8px'
});

const SearchInputIcon = styled('div')({
    padding: '4px',
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
});

const ListContainer = styled('div')({
    maxHeight: '320px',
});

const MappingPageOrder = styled('div')({
    marginTop: '32px'
});

const MappingPageOrderHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
});

const MappingPageOrderHeaderText = styled('div')(({ theme }) => ({
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: '400',
    color: theme.palette.text.primary
}));

const MappingPageOrderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

const MappingPageOrderItem = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px',
    alignItems: 'center'
});

type OrderItemProps = {
    status: string;
}

const MappingPageOrderItemNumber = styled('div')<OrderItemProps>(({ status, theme }) => ({
    fontSize: '14px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    background: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#92CD91' : status === 'inactive' ? '#E3E8EC' : '#979799'
    : status === 'active' || status === 'waiting' ? '#38AA72' : status === 'inactive' ? theme.palette.background.default : '#65646B',
    borderRadius: '12px',
    marginRight: '16px'
}));

const MappingPageOrderItemInfo = styled('div')<OrderItemProps>(({ status, theme }) => ({
    background: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#F1F8ED' : status === 'inactive' ? '#F9FBFD' : '#F1F3F5'
    : status === 'inactive' ? theme.palette.background.paper : theme.palette.background.default,
    border: theme.palette.mode === 'light' ? 'none' : `1px solid #65646B`,
    borderRadius: '4px',
    height: '32px',
    width: 'calc(100% - 24px - 16px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 8px'
}));

const MappingPageOrderItemInfoNameText = styled('div')({
    width: '120px',
    fontWeight: '500',
    fontSize: '12px'
});

const MappingPageOrderItemInfoMethodText = styled('div')({
    width: '180px',
    fontWeight: '500',
    fontSize: '12px'
});

const MappingPageOrderItemInfoTextContainer = styled('div')<OrderItemProps>(({ status, theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#38AA72' : status === 'inactive' ? '#979799' : '#404653'
    : status === 'inactive' ? theme.palette.text.disabled : theme.palette.text.primary,
}));

const MappingPageOrderItemInfoCircle = styled('div')<OrderItemProps>(({ status, theme }) => ({
    width: '4px',
    height: '4px',
    borderRadius: '2px',
    background: theme.palette.mode === 'light' ?
    status === 'active' || status === 'waiting' ? '#38AA72' : status === 'inactive' ? '#979799' : '#404653'
    : status === 'inactive' ? theme.palette.text.disabled : theme.palette.text.primary,
    marginRight: '16px'
}));

const MappingPageOrderItemInfoStatus = styled('span')<OrderItemProps>(({ status }) => ({
    border: `1px solid ${status === 'active' ? '#6FC52D' : status === 'waiting' ? '#C0BDC9' : '#EDA32A'}`,
    color: status === 'active' ? '#6FC52D' : status === 'waiting' ? '#65626B' : '#EDA32A',
    borderRadius: '8px',
    fontSize: '12px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 6px',

    '@media(max-width: 768px)' : {
        padding: '0px',
    }

}));

const MappingPageOrderItemInfoStatusSvg = styled('div')({
    marginRight: '6px',
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 768px)' : {
        marginRight: '0px',
    }
});

const MappingPageOrderSelectedRule = styled('div')(({ theme }) => ({
    fontSize: '15px',
    fontWeight: '400',
    marginTop: '16px',
    color: theme.palette.text.primary
}));

const MappingPageAlert = styled('div')({
    margin: '32px 0px'
});

const MappingPageAlertHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const MappingPageAlertHeaderText = styled('div')(({ theme }) => ({
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: '400',
    color: theme.palette.text.primary
}));

// const MappingPageAlertSelectsContainer = styled('div')({
//     display: 'flex',
//     gap: '32px',

//     '@media(max-width: 768px)': {
//         flexWrap: 'wrap',
//         gap: '0px'
//     }
// });

const StyledFormControl = styled(FormControl)(({ theme }) => (`
    margin-top: 20px;
    width: 100%;
    & label.Mui-focused{
        color: #38AA72;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
                border-color: #38AA72;
        }
    }
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const MappingPageAlertButtons = styled('div')({
    marginTop: '24px',
    display: 'flex',
    gap: '12px'
});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const MappingPageWarningText = styled('div')({
    color: 'red',
    fontSize: '14px',
    fontWeight: '500',

});

const MappingPage = ({isLoading, cascadeList, getCascadeListRequest, setCascadeListRequest, activeNode, error, errorMessage}) => {
    const [cascadeListState, setCascadeListState] = useState(isJson(JSON.parse(cascadeList)) ? JSON.parse(JSON.parse(cascadeList)) : JSON.parse(cascadeList));
    const [isEdited, setIsEdited] = useState(false);

    const [anchorElCurrencySelect, setAnchorElCurrencySelect] = React.useState<null | HTMLElement>(null);
    const isCurrencySelectOpen = Boolean(anchorElCurrencySelect);
    const handleCurrencySelectOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElCurrencySelect(event.currentTarget);
    };
    const handleCurrencySelectClose = () => {
        setAnchorElCurrencySelect(null);
    };

    const [searchValue, setSearchValue] = useState('');
    const handleChangeSearchValue = event => {
        setSearchValue(event.target.value);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [way, setWay] = useState('deposit');
    const handleChangeWay = (event) => {
        setWay(event.target.value)
    };

    const handleChangeTelegram = (event) => {
        setCascadeListState(prev => {
            const changedState = {...prev};
            changedState.notification.telegram = event.target.value;
            return changedState;
        });
        setIsEdited(true);
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState({successText: '', errorText: ''});
    const handleOpenSnackbar = (successText, errorText) => {
        setSnackbarText({successText, errorText});
        setOpenSnackbar(true);
    };
    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const getCurrenciesList = () => {
        if (!cascadeListState || !cascadeListState[way]) {
            return [];
        };

        return Object.keys(cascadeListState[way]).map(key => {
            const rules = cascadeListState[way][key].rules;

            return ({
                currency: key,
                isSet: rules?.availability || rules?.sum_limit ? true : false
            });
        });
    };

    const [selectedCurrency, setSelectedCurrency] = useState('');
    const handleSelectCurrency = (currency) => {
        setSelectedCurrency(currency);
        handleCurrencySelectClose();
    };

    const [shouldDeleteRule, setShouldDeleteRule] = useState(false);

    useEffect(() => {
        getCascadeListRequest();
        setSearchValue('');
        setSelectedCurrency('');
    }, [activeNode, getCascadeListRequest]);

    useEffect(() => {
        setCascadeListState(isJson(JSON.parse(cascadeList)) ? JSON.parse(JSON.parse(cascadeList)) : JSON.parse(cascadeList));
    }, [cascadeList]);

    useEffect(() => {
        if (shouldDeleteRule) {
            setCascadeListRequest(JSON.stringify(cascadeListState)).then(() => handleOpenSnackbar('Rule deleted', 'Rule not deleted'));
            setShouldDeleteRule(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cascadeListState]);

    const handleCancel = () => {
        setCascadeListState(isJson(JSON.parse(cascadeList)) ? JSON.parse(JSON.parse(cascadeList)) : JSON.parse(cascadeList));
        setIsEdited(false);
    };

    const handleApply = () => {
        setCascadeListRequest(JSON.stringify(cascadeListState)).then(() => handleOpenSnackbar('Cascade list saved successfully','Cascade list not saved'));
        setIsEdited(false);
    };

    const handleDeleteRule = () => {
        setCascadeListState(prev => {
            const changedState = {...prev};
            changedState[way][selectedCurrency].rules = {
                availability: 0,
                delta_day: 0,
                delta_today: 0,
                delta_custom: 0,
                sum_limit: 0,
                notification: prev[way][selectedCurrency].rules.notification
            };

            changedState[way][selectedCurrency].methods = prev[way][selectedCurrency].methods.map(method => {
                method.status = '';
                return method;
            });

            return changedState;
        });

        setShouldDeleteRule(true);
    };

    const rules = cascadeListState[way]?.[selectedCurrency]?.rules;

    const { ref } = useResizeObserver<HTMLDivElement>();

    return (
        <PageContainer>
            <MappingPageHeader>
                <MappingPageHeaderText>Cascades</MappingPageHeaderText>
                <Structure/>
            </MappingPageHeader>
            <MappingPageContent>
                <MappingPageInfoMessage>
                    <InfoOutlineSvg size="16px"/>
                    <MappingPageInfoMessageText>
                        Select a way and a currency to manage mapping
                    </MappingPageInfoMessageText>
                </MappingPageInfoMessage>
                <StyledFormControl fullWidth>
                    <InputLabel size="small">Way</InputLabel>
                    <Select
                        value={way}
                        label="Way"
                        onChange={handleChangeWay}
                        size='small'
                        disabled={true}
                    >
                        <StyledMenuItem value="deposit">Deposit</StyledMenuItem>
                        <StyledMenuItem value="withdraw">Withdraw</StyledMenuItem>
                    </Select>
                </StyledFormControl>
                <Input
                    value={selectedCurrency}
                    label="Currency"
                    readOnly
                    sx={{marginTop: '24px', caretColor: 'transparent', input: {cursor: 'pointer'}, '& .MuiInputBase-root': {cursor: 'pointer'}, '& .MuiSvgIcon-root': {position: 'absolute', right: '8px'}}}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {anchorElCurrencySelect ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </InputAdornment>
                        )
                    }}
                    onClick={handleCurrencySelectOpen}
                    autoComplete="off"
                    disabled={true}
                />
                <StyledMenu
                    MenuListProps={{
                    'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorElCurrencySelect}
                    open={isCurrencySelectOpen}
                    onClose={handleCurrencySelectClose}
                    PaperProps={{
                        style: {
                            width: '484px',
                            paddingBottom: '0px'
                        },
                    }}
                >
                    <SearchInputWrapper>
                        <StyledSearchInput placeholder="Search" value={searchValue} onChange={handleChangeSearchValue}/>
                        <SearchInputIcon>
                            <SearchSvg size="22px"/>
                        </SearchInputIcon>
                    </SearchInputWrapper>
                    <ListContainer>
                        <List sx={{padding: '0px', maxHeight: '320px'}}>
                            <Scrollbars autoHeight>
                                {getCurrenciesList().filter(item => item.currency.includes(searchValue.toUpperCase())).map(item => {
                                    return (
                                        <ListItem sx={{padding: '0px'}} key={item.currency}>
                                            <CurrencyItem onClick={() => handleSelectCurrency(item.currency)}>
                                                <div>
                                                    {item.currency}
                                                </div>
                                                <CurrencyItemIsSetText>
                                                    {item.isSet && 'rule set'}
                                                </CurrencyItemIsSetText>
                                            </CurrencyItem>
                                        </ListItem>
                                    );
                                })}
                            </Scrollbars>
                        </List>
                    </ListContainer>
                </StyledMenu>
                <MappingPageOrder>
                    <MappingPageOrderHeader>
                        <MappingPageOrderHeaderText>
                            Order of payment methods
                        </MappingPageOrderHeaderText>
                        <Button 
                            variant='outlined' 
                            disabled={true} // Tmp
                            // disabled={!way || !selectedCurrency} 
                            onClick={handleOpen}
                        >
                            Edit
                        </Button>
                    </MappingPageOrderHeader>
                    <div ref={ref} style={{width: '100%', height: '308px'}}>
                        <Scrollbars>
                            <MappingPageOrderContainer>
                                {cascadeListState?.[way]?.[selectedCurrency]?.methods?.map((item, index) => {
                                    return (
                                        <MappingPageOrderItem key={index}>
                                            <MappingPageOrderItemNumber status={item?.status}>{index + 1}</MappingPageOrderItemNumber>
                                            <MappingPageOrderItemInfo status={item?.status}>
                                                <MappingPageOrderItemInfoTextContainer status={item?.status}>
                                                    <MappingPageOrderItemInfoNameText>{item?.name?.toUpperCase()}</MappingPageOrderItemInfoNameText>
                                                    <MappingPageOrderItemInfoCircle status={item?.status}/>
                                                    <MappingPageOrderItemInfoMethodText>{item?.method?.toUpperCase()}</MappingPageOrderItemInfoMethodText>
                                                </MappingPageOrderItemInfoTextContainer>
                                                {item?.status && (
                                                    <MappingPageOrderItemInfoStatus status={item?.status}>
                                                        <MappingPageOrderItemInfoStatusSvg>
                                                            {item?.status === 'active' ? <StatusOkSvg size="12px" /> : item?.status === 'inactive' ? <StatusErrorSvg size="12px" fill="#FA8C16" /> : <StatusWaitingSvg size="12px" />}
                                                        </MappingPageOrderItemInfoStatusSvg>
                                                        {window.screen.width > 767 && item?.status}
                                                    </MappingPageOrderItemInfoStatus>
                                                )}
                                            </MappingPageOrderItemInfo>
                                        </MappingPageOrderItem>
                                    );
                                })}
                            </MappingPageOrderContainer>
                        </Scrollbars>
                    </div>
                    <MappingPageOrderSelectedRule>
                        {rules?.availability ? <span>Rule <b>by Availability</b> – Period <b>{
                            rules.availability === 86400 ? "by Last 24 hour" :
                            rules.availability === 3600 ? "by Last hour" :
                            rules.availability === 300 ? "by Last 5 minutes" :
                            `by Last ${convertSecondsToTimeString(rules?.availability)}`}</b></span> :
                        rules?.sum_limit && !rules.delta_day && !rules?.delta_today && !rules?.delta_custom ? 
                        <span>Rule <b>Limits by sum</b> – Limits <b>{way === 'deposit' ? 'Pay in' : 'Pay out'}</b> - Sum <b>{Number(String(rules?.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' ')}</b></span> :
                        rules?.sum_limit && rules?.delta_today ?
                        <span>Rule <b>Limits by sum</b> – Limits - <b>Delta = pay-in – pay-out (start with 0.00)</b> - Sum <b>{Number(String(rules?.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' ')}</b></span> :
                        rules?.sum_limit && rules?.delta_day ?
                        <span>Rule <b>Limits by sum</b> – Limits - <b>Delta = pay-in – pay-out (last 24 hours)</b> - Sum <b>{Number(String(rules?.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' ')}</b></span> :
                        rules?.sum_limit && rules?.delta_custom ?
                        <span>Rule <b>Limits by sum</b> – Limits - <b>Delta = pay-in – pay-out (start custom time)</b> - Sum <b>{Number(String(rules?.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' ')}</b> - Time <b>{
                            convertSecondsToTimeString(rules?.delta_custom)
                        }</b></span> :
                        <span>Rule not set</span>}
                    </MappingPageOrderSelectedRule>
                </MappingPageOrder>
                <MappingPageAlert>
                    <MappingPageAlertHeader>
                        <MappingPageAlertHeaderText>
                            Alert about change methods
                        </MappingPageAlertHeaderText>
                    </MappingPageAlertHeader>
                    {/* <MappingPageAlertSelectsContainer>
                        <StyledFormControl fullWidth={window.screen.width > 767 ? false : true}>
                            <InputLabel size="small">Channel</InputLabel>
                            <Select
                                value={'telegram'}
                                label="Channel"
                                onChange={() => {}}
                                size='small'
                            >
                                <StyledMenuItem value="telegram">By telegram</StyledMenuItem>
                            </Select>
                        </StyledFormControl>
                        <StyledFormControl fullWidth={window.screen.width > 767 ? false : true}>
                            <InputLabel size="small">Trigger</InputLabel>
                            <Select
                                value={'availability'}
                                label="Trigger"
                                onChange={() => {}}
                                size='small'
                            >
                                <StyledMenuItem value="availability">By availability</StyledMenuItem>
                            </Select>
                        </StyledFormControl>
                    </MappingPageAlertSelectsContainer> */}
                    <Input
                        placeholder="Enter telegram"
                        label="Telegram"
                        sx={{marginTop: '24px'}}
                        onChange={handleChangeTelegram}
                        value={cascadeListState?.notification?.telegram || ''}
                        disabled={true}
                    />
                    {isEdited && (
                        <MappingPageInfoMessage>
                            <InfoOutlineSvg size="16px"/>
                            <MappingPageWarningText>
                                To save changes press APPLY button
                            </MappingPageWarningText>
                        </MappingPageInfoMessage>
                    )}
                    <MappingPageAlertButtons>
                        <Button disabled={!isEdited} onClick={handleApply}>Apply</Button>
                        <Button
                            variant='outlined'
                            disabled={isEdited || !(rules?.availability || rules?.delta_day || rules?.delta_today || rules?.delta_custom || rules?.sum_limit)}
                            onClick={handleDeleteRule}
                        >Delete Rule</Button>
                        <Button variant='outlined' disabled={!isEdited} onClick={handleCancel}>Cancel</Button>
                    </MappingPageAlertButtons>
                </MappingPageAlert>
            </MappingPageContent>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <StyledBoxPopup>
                    <EditMappingForm
                        cascadeListState={cascadeListState}
                        setCascadeListState={setCascadeListState}
                        way={way}
                        currency={selectedCurrency}
                        setIsEdited={setIsEdited}
                        onClose={handleClose}
                    />
                </StyledBoxPopup>
            </Modal>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                {error ?
                    <InfoPopup
                        severity="error"
                        headerText={snackbarText.errorText}
                        infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                    /> :
                    <InfoPopup
                        severity="success"
                        headerText={snackbarText.successText}
                    />
                }
            </Snackbar>
            {isLoading && <Loader />}
        </PageContainer>
    );
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.mappingCsacades.isLoading,
        cascadeList: state.mappingCsacades.cascadeList,
        activeNode: state.nodeManagement.activeNode,
        error: state.mappingCsacades.isError,
        errorMessage: state.mappingCsacades.errorMessage
    }), {
        getCascadeListRequest, 
        setCascadeListRequest,
    })
)(MappingPage);
