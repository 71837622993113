import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import { EditSvg } from '../../components/svg/edit-svg';
import Paper from '@mui/material/Paper';
import { EditUserForm } from './edit-role-form';
import Box from '@mui/material/Box';
import { NotifyDialog } from '../../components/notify-dialog';
import Dialog from '@mui/material/Dialog';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
// import Checkbox from '@mui/material/Checkbox';
// import {LinkToPermission} from './link-permission';
import {
    usersItemTreeRolesRequest,
    usersItemRolesRequest,
    userRoleEdit,
    userRoleDelete,
    assignPermissionForRole,
} from '../../actions/usersRoles';
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { Loader } from '../../components/loaders/loader';
import { FieldsList } from './tab-lists/fields-lists';
import { FieldsListPermissions } from './tab-lists/fields-lists-permission';
import {useParams, useNavigate} from 'react-router-dom';


const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px'
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UserDetailsHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px'

});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledDialogEdit = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        margin: 15px;
        margin-top:0;
        margin-bottom:0;
        max-height: 100%;
        border-radius: 0;
    }
`));

const StyledDialogChangePassword = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
    }
`));

const StyledPaperTabs = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    box-sizing: border-box;
    border-bottom: none;
    margin-top: 30px;
    box-shadow: none;
    border: none;
    @media screen and (max-width: 768px){
        margin-top: 10px;
    }
`));

const StyledTabPanel = styled(TabPanel)(`
    padding: 0px 0px 30px 30px;
`);

const StyledTab = styled(Tab)(({ theme }) => (`
    &.MuiTab-root{
        color: #979799;
    }
    &.Mui-selected{
        color: ${theme.palette.text.primary};
    }
`));

const StyledTabs = styled(Tabs)(`
    & .MuiTabs-indicator{
        background-color: #272F3D;
    },
`);

// const StyledUl = styled('ul')(`
//     &{
//         margin-left:0;
//         padding-left:0;
//     }
// `);

// const StyledLi = styled('li')(`
//     &{
//         list-style:none;
//         display: flex;
//         align-items: center;
//     }
// `);

// const TitleTab = styled('h3')(({ theme }) => (`
//     &{
//         color: ${theme.palette.text.primary};
//         font-size: 24px;
//         font-weight: 400;
//     }
// `));

const WrapTabContentColumns = styled('div')(({ theme }) => (`
    &{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }
`));

const WrapTabContentItem = styled('div')(({ theme }) => (`
    &{
        // width: 33.33%;
        padding-right: 30px;
        box-sizing: border-box;
        @media screen and (max-width: 991px){
            width: 100%;
        }
    }
`));


export const DetailRolesPage = (props) => {
    const {
        // view,
        // setView,
        usersItemRolesRequest,
        usersItemTreeRolesRequest,
        userRoleEdit,
        userRoleDelete,
        assignPermissionForRole,
        itemRole,
        itemRoleTree,
        isLoading,
    } = props;

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        usersItemRolesRequest(id);
        usersItemTreeRolesRequest(id)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        usersItemRolesRequest(id);
        usersItemTreeRolesRequest(id)
        // eslint-disable-next-line
    }, [id])

    const [tabIndex, setTabIndex] = React.useState('1');
    const handleChangeTab = (event: React.SyntheticEvent, index: string) => {
        setTabIndex(index);
    };

    const goBack = () =>  navigate(-1);

    const [open, setOpen] = React.useState(false);
    const handlEditeOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    }
    const handleDeleteModalClose = () => setDeleteModalOpen(false);

    const handleEditRole = (data) => {
        data.currentRole = itemRole.name;
        userRoleEdit(data)
    }

    const handleDeleteRole = () => {
        userRoleDelete(itemRole.name)
        goBack();
    }

    const [localitemRoleTree, setLocalitemRoleTree] = useState(itemRoleTree);
    useEffect(() => {
        setLocalitemRoleTree(itemRoleTree);
        // eslint-disable-next-line
    }, [itemRoleTree]);

    const createDateRole = (data: any) => {
        const dateSend = [];
        const searchDateTrue = (arr) => {
            arr.forEach((item, index, arrInit) => {
                item.checked && dateSend.push({'name': item['code']}) 
            })
        }
        searchDateTrue(localitemRoleTree.childRoles);
        searchDateTrue(localitemRoleTree.childPermissions.filter(item => !item.owner_child_role));
        return dateSend
    }

    const handleSaveListsPermissionForRole = (data) => {
        assignPermissionForRole(itemRole.name, createDateRole(data));
    }

    return (
        <PageContainer>
            <UserDetailsHeader>
                <UserDetailsHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer>
                            <ArrowBackSvg size="22px" onClick={goBack}/>
                        </ArrowContainer>
                        <UserDetailsHeaderText>
                            Permissions for role: {itemRole?.description}
                        </UserDetailsHeaderText>
                    </HeaderLeftContainer>
                </UserDetailsHeaderLeft>
                <UserDetailsHeaderRight>
                    <Button startIcon={ <EditSvg size="22px"/>} onClick={handlEditeOpen}>
                        EDIT
                    </Button>
                    <Button isDeleteButton={true} onClick={handleDeleteModalOpen}>
                        <DeleteIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        Delete
                    </Button>
                </UserDetailsHeaderRight>
            </UserDetailsHeader>

            <StyledPaperTabs>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <StyledTabs value={tabIndex} onChange={handleChangeTab} aria-label="lab API tabs example">
                                <StyledTab label="Child roles" value="1" />
                                <StyledTab label="Permissions" value="2" />
                            </StyledTabs>
                        </Box>

                        <StyledTabPanel value="1">   

                            <WrapTabContentColumns>
                                <WrapTabContentItem>
                                    <FieldsList 
                                        childFieldss={localitemRoleTree.childRoles} 
                                        onSaveFields={handleSaveListsPermissionForRole}
                                    />
                                </WrapTabContentItem>
                            </WrapTabContentColumns>

                        </StyledTabPanel>

                        <StyledTabPanel value="2">

                            <WrapTabContentColumns>
                                <WrapTabContentItem>
                                    <FieldsListPermissions 
                                        childFieldss={localitemRoleTree.childPermissions} 
                                        onSaveFields={handleSaveListsPermissionForRole}
                                    />
                                </WrapTabContentItem>
                            </WrapTabContentColumns>

                        </StyledTabPanel>
                    </TabContext>
                </Box>
            </StyledPaperTabs>

            <StyledDialogEdit
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledBoxPopup>
                    <EditUserForm 
                        onClose={handleClose}
                        initValue={{description: itemRole?.description, name: itemRole?.name}}
                        editRole={handleEditRole}
                    />
                </StyledBoxPopup>
            </StyledDialogEdit>

            <StyledDialogChangePassword
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NotifyDialog
                    title={`Delete role ${itemRole?.name}`}
                    confirmButtonText='YES, DELETE'
                    cancelButtonText='CANCEL'
                    onClose={handleDeleteModalClose}
                    width="400px"
                    isDeleteButton
                    onCancel={handleDeleteModalClose}
                    onConfirm={handleDeleteRole}
                >
                    Are you sure you want to delete this role {itemRole?.name}???
                </NotifyDialog>  
            </StyledDialogChangePassword>

            {isLoading && <Loader/>}

        </PageContainer>
    )
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.roles.isLoading,
        itemRole: state.roles.roleItem,
        itemRoleTree: state.roles.roleTree,
    }), {
        usersItemRolesRequest,
        userRoleEdit,
        userRoleDelete,
        usersItemTreeRolesRequest,
        assignPermissionForRole,
    }),
)(DetailRolesPage)