import React from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from "../svg/close-svg";
import {Button} from "../button";
import Box from '@mui/material/Box';


const NotifyModalWrapper = styled(Box)(({ width, theme }) => (`
& {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 40px;
    width: ${width ? width : '500px'};
    margin:0 auto;
    background: ${theme.palette.background.paper};
    color: ${theme.palette.text.primary};
    @media screen and (max-height: 660px){
        overflow-y: scroll;
    }
}
`));

const NotifyModalFirstRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
});

const NotifyModalTitle = styled('div')(({ theme } ) => ({
    fontSize: '24px',
    fontWeight: '500',
    color: theme.palette.text.primary
}));

const IconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
});

const NotifyModalButtons = styled('div')({
    marginTop: '24px'
});

type Props = {
    title: string;
    children: React.ReactNode;
    confirmButtonText: string;
    cancelButtonText: string;
    onClose: () => void;
    width?: string;
    isDeleteButton?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    testIdConfirm?: string;
    testIdCancel?: string;
};

export const NotifyModal = ({
    title, 
    children, 
    confirmButtonText, 
    cancelButtonText, 
    onClose, 
    width, 
    isDeleteButton, 
    onConfirm, 
    onCancel,
    testIdConfirm = '',
    testIdCancel = '',
}: Props) => {
    return (
        <NotifyModalWrapper width={width}>
            <NotifyModalFirstRow>
                <NotifyModalTitle>
                    {title}
                </NotifyModalTitle>
                <IconWrapper>
                    <CloseSvg size="14px" onClick={onClose}/>
                </IconWrapper>
            </NotifyModalFirstRow>
            {children}
            <NotifyModalButtons>
                <Button 
                    isDeleteButton={isDeleteButton} 
                    sx={{marginRight: '12px'}} 
                    onClick={onConfirm}
                    data-testid={testIdConfirm}
                >
                    {confirmButtonText}
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={onCancel}
                    data-testid={testIdCancel}
                >
                    {cancelButtonText}
                </Button>
            </NotifyModalButtons>
        </NotifyModalWrapper>
    )
}