import LoginAPI from './loginAPI';
import NodeManagement from './nodeManagementAPI';
import HistoryTransStatus from './historyTransStatusAPI';
import QueueAPI from './queueAPI';
import BuildApi from './buildAPI';
import RepeatQueue from './repeaQueueAPI';
import LogViewer from './logViewerAPI';
import Migration from './migrationAPI';
import Statements from './statementsAPI';
import SystemInfoBuyerLog from './systemInfoBuyerLogAPI';
import SystemInfoTemporiaryDepositLogs from './systemInfoTemporiaryDepositLinksAPI';
import Wallets from './walletsAPI';
import SystemInfoAdresses from './systemInfoAdressesAPI';
import SystemInfoHdbkRequestsTypes from './systemInfoHdbkRequestsTypesAPI';
import UserPaymentSystems from './userPaymentSystemsAPI';
import TelegramWebhook from './telegramWebhookAPI';
import NotificationSettings from './notificationSettingsApi';
import BlockchainConfig from './blockchainConfigAPI';
import SettingsPPS from './settingsPpsAPI';
import RequestsCleaner from './requestsCleanerAPI';
import RequestElasticSearch from './requestElasticSearchAPI';
import Merchants from './merchantsAPI';
import Transactions from './transactionsAPI';
import UsersActionLog from './useraActionLogAPI';
import UsersVisitLog from './useraVisitLogAPI';
import UsersAll from './usersAllAPI';
import PaymentSystems from './paymentSystemsAPI';
import CurrentUser from './currentUserAPI';
import Timezone from './timezoneAPI'
import MappingCascades from './mappingCascadesAPI';
import UsersOnTheNode from './usersOnTheNodeAPI';
import DashboardAPI from './dashboardAPI';
import IpAddressAPI from './ipAddressAPI';
import UsersPermissions from './useraPermissionsAPI';
import UsersPermissionGroups from './useraPermissionsGroupAPI';
import UsersRoles from './useraRolesAPI';
import MappingMultiaccounts from './mappingMultiaccountsAPI';
import Report from './reportsReportAPI';
import CurrencyListApi from './currencyListAPI';
import UsersOnTheBranch from './usersOnTheBranchAPI'
import ApiInfo from './ApiInfoAPI';

const API = {
    login: LoginAPI,
    nodeManagement: NodeManagement,
    historyTransStatus: HistoryTransStatus,
    queue: QueueAPI,
    wallets: Wallets,
    buildApi: BuildApi,
    repeatQueue: RepeatQueue,
    logViewer: LogViewer,
    migrations: Migration,
    statements: Statements,
    systemInfoBuyerLog: SystemInfoBuyerLog,
    systemInfoTemporiaryDepositLogs: SystemInfoTemporiaryDepositLogs,
    systemInfoAdresses: SystemInfoAdresses,
    systemInfoHdbkRequestsTypes: SystemInfoHdbkRequestsTypes,
    telegramWebhook: TelegramWebhook,
    userPaymentSystems: UserPaymentSystems,
    notificationSettings: NotificationSettings,
    blockchainConfig: BlockchainConfig,
    settingsPPS: SettingsPPS,
    requestElasticSearch: RequestElasticSearch,
    requestCleaner: RequestsCleaner,
    merchants: Merchants,
    transactions: Transactions,
    usersActionLog: UsersActionLog,
    usersVisitLog: UsersVisitLog,
    usersAll: UsersAll,
    paymentSystems: PaymentSystems,
    currentUser: CurrentUser,
    timeZone: Timezone,
    mappingCascades: MappingCascades,
    useresOnTheNode: UsersOnTheNode,
    dashboard: DashboardAPI,
    ipAddress: IpAddressAPI,
    usersPermissions: UsersPermissions,
    usersPermissionGroups: UsersPermissionGroups,
    usersRoles: UsersRoles,
    mappingMultiaccounts: MappingMultiaccounts,
    report: Report,
    currencyList: CurrencyListApi,
    usersOnTheBranch: UsersOnTheBranch,
    apiInfo: ApiInfo,
    // ...
}

export default API;