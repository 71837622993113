import React from "react";
import MainUsersPage from './visit-log-main';
import UserDetailsPage from './visit-log-details';

export const VisitLog = () => {
    const [view, setView] = React.useState({
        point: 'main',
        id: ''
    })

    return(
        <>
            { view.point === 'main' && <MainUsersPage setView={setView} />}
            { view.point === 'view' && <UserDetailsPage setView={setView} view={view} />}
        </>
    )
} 

export default VisitLog;