import {ActionTypes} from '../reducers/settingsPPS';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_SETTINGS_START,
});

export const requestSuccess = item => {
	return {
		type: ActionTypes.FETCH_SETTINGS_SUCCESS,
		payload: item
	};
};

export const requestItem = item => {
	return {
		type: ActionTypes.FETCH_SETTINGS_ITEM_REQUEST,
		payload: item,
	};
};

export const requestDelete = (id = '')=> {
	return {
		type: ActionTypes.FETCH_SETTINGS_DELETE,
		payload: id,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_SETTINGS_FAILURE,
		payload: error
	}
};

export const settingsPPSRequest = () => dispatch => {
	dispatch(requestStart());
	return API.settingsPPS
		.settingsList()
		.then(response => {
			dispatch(requestSuccess(response.data['hydra:member']));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const settingPPSItemRequest = (id) => dispatch => {
	dispatch(requestStart());
	return API.settingsPPS
		.settingsItem(id)
		.then(response => {
			dispatch(requestItem(response.data))
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const settingPPSItemRequestDelete = (id) => dispatch => {
	dispatch(requestStart());
	return API.settingsPPS
		.settingsItemDelete(id)
		.then(response => {
			dispatch(requestDelete());
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const settingPPSItemUpdate = (id, data) => dispatch => {
	dispatch(requestStart());
	return API.settingsPPS
		.settingsItemUpdate(id, data)
		.then(response => {
			dispatch(settingPPSItemRequest(id))
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const settingPPSItemCreate = (data) => dispatch => {
	dispatch(requestStart());
	return API.settingsPPS
		.settingCreate(data)
		.then(response => {
			console.log(response)
			dispatch(settingsPPSRequest())
		})
		.catch(error => dispatch(requestFailure(error.message)));
};