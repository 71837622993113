import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/temporary_deposit_links';

const SystemInfoTemporiaryDepositLogs = {
	getSystemInfoTemporiaryDepositLogs: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';

		return baseApi.get(`${path}${query}`)
	},
	getSystemInfoTemporiaryDepositLogItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
    // ...
}

export default SystemInfoTemporiaryDepositLogs;