import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/transaction_requests';
const pathOptionsRequest = 'api/hdbk_request_types/dropdown_list';

const RequestElasticSearch = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';

		return baseApi.get(`${path}${query}`)
	},
	getOptionsList: () => {
		return baseApi.get(`${pathOptionsRequest}`)
	}
    // ...
}

export default RequestElasticSearch;