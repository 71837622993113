export namespace ActionTypes {
    export const FETCH_STATEMENTS_START = 'FETCH_STATEMENTS_START';
    export const FETCH_STATEMENTS_SUCCESS = 'FETCH_STATEMENTS_SUCCESS';
    export const FETCH_STATEMENTS_DELETE = 'FETCH_STATEMENTS_DELETE';
    export const FETCH_STATEMENTS_FAILURE = 'FETCH_STATEMENTS_FAILURE';
    export const FETCH_STATEMENTS_ITEM_SUCCESS = 'FETCH_STATEMENTS_ITEM_SUCCESS';
};

export type StatementItem = {
	"@id": string,
	"@type": string,
	id: number,
	token: string,
	status: number,
	paymentSystems: string,
	name: string,
	link: string,
}

type StatementsList = {
	'@context': string;
	'@id': string;
	'hydra:member': StatementItem[];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

interface Statements {
	isError: boolean,
    isLoading: boolean,
	statements: StatementsList,
	errorMessage: string,
	itemStatement: any,
}

export const initialState: Statements = {
	isError: false,
    isLoading: false,
	statements: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	errorMessage: '',
	itemStatement: [],
};

export const statements = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_STATEMENTS_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_STATEMENTS_SUCCESS:
			return {
				...state,
				statements: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_STATEMENTS_ITEM_SUCCESS:
			return {
				...state,
				itemStatement: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_STATEMENTS_DELETE:
			return {
				...state,
			};			
		case ActionTypes.FETCH_STATEMENTS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
