import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import { Loader } from '../../components/loaders/loader'
import { 
    statementItemRequest,
    statementUpdate,
} from "../../actions/statements";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { Input } from '../../components/input';
import { TextArea } from '../../components/textarea'
import {Viev} from './index';
import {StatementItem} from '../../reducers/statements';


const PageContainer = styled('div')(({theme}) => (` 
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxEdit = styled(Box)(({theme}) => (`
    boeder: 1px solid red;
    padding: 24px;  
    margin-bottom: 50px;    
`));

const WrappBox = styled(Box)(({theme}) => (`
    padding-bottom: 40px;
`));

type DataUpdate = {
    status: number,
    paymentSystems: any,
    name: string,
    link: string,
}

type PropsType = {
    statementItemRequest: (id: number) => void,
    statementUpdate: (id: number, data: DataUpdate) => void,
    statementInfo: StatementItem,
    isLoading: boolean,
    setView: any, 
    view: Viev,
}

export const StatementsEdit: React.FC<PropsType> = (props) => {

    const {
        statementItemRequest,
        statementUpdate,
        statementInfo,
        isLoading,
        setView, 
        view,
    } = props

    const [values, setValues] = React.useState({
        token: statementInfo.token,
        name: statementInfo.name,
        link: statementInfo.link,
        paymentSystems: statementInfo.paymentSystems ? statementInfo.paymentSystems : '[]',
    });

    useEffect(() => {
        statementItemRequest(view.idCurrentStatement)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view.idCurrentStatement, statementItemRequest])

    const handleClickNavigation = (way) => () => {
        setView((prev) => ({...prev, point: way,}))
    }

    const handleChangeValue = (prop) => (e) => {
        setValues((prev) => ({...prev, [prop]: e.target.value}))
    }

    const handleClickSave = () => {
        const {paymentSystems, name, link} = values;
        const data = {
            "status": 1,
            "paymentSystems": paymentSystems,
            "name": name,
            "link": link,
        }

        statementUpdate(view.idCurrentStatement, data)
    }

    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation('view')}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            Update Statement {statementInfo.name ? statementInfo.name : `id: ${view.idCurrentStatement}`}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
                <ShortViewHeaderRight>
                    <Button onClick={handleClickSave}>
                        <SaveIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        save
                    </Button>
                    <Button 
                        onClick={handleClickNavigation('main')}
                        variant='outlined'
                    >
                        {/* <SaveIcon sx={{marginRight:'8px',fontSize: '20px'}} /> */}
                        close
                    </Button>
                </ShortViewHeaderRight>
            </ShortViewHeader>

            <WrappBox>
                <BoxEdit component={Paper} sx={{ typography: 'body1' }}>
                    <Input 
                        label="Token *" 
                        value={values.token} 
                        disabled 
                        sx={{marginBottom: '16px'}}
                    />
                    <TextArea 
                        label="Payment Systems *" 
                        value={values.paymentSystems ? values.paymentSystems : '[]'} 
                        disabled 
                        sx={{marginBottom: '16px'}}
                    />
                    <Input 
                        label="Name" 
                        value={values.name ? values.name : ''} 
                        sx={{marginBottom: '16px'}} 
                        onChange={handleChangeValue('name')}
                    />
                    <Input 
                        label="Link" 
                        value={values.link ? values.link : ''} 
                        onChange={handleChangeValue('link')}
                    />
                </BoxEdit>
            </WrappBox>

            {isLoading && <Loader/>}

        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        statementInfo: state.statements.itemStatement,
        isLoading: state.statements.isLoading, 
    }), {
        statementItemRequest,
        statementUpdate,
    }),
)(StatementsEdit)