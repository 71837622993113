import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/statements';

const Statements = {
	statementsList: (params = {}) => {
		const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';
		return baseApi.get(`${path}${query}`)
	},
	statementItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
	statementDelete: (id) => {
		return baseApi.delete(`${path}/${id}`)
	},
	statementCreate: (data) => {
		return baseApi.post(`${path}`, data)
	},
	statementUpdate: (id, data) => {
		return baseApi.patch(`${path}/${id}`, data)
	}
}

export default Statements;