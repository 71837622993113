import React, {useEffect} from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import { EditSvg } from '../../components/svg/edit-svg';
import Paper from '@mui/material/Paper';
import { EditUserForm } from './edit-permission-group-form';
import Box from '@mui/material/Box';
import { NotifyDialog } from '../../components/notify-dialog';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Loader } from '../../components/loaders/loader';
import {format} from 'date-fns';

import {
    usersPermissionItemRequest,
    usersPermissionItemDelete,
    usersPermissionItemUpdate,
} from '../../actions/usersPermissionGroups';
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px'
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UserDetailsHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px'

});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledDialogEdit = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        margin: 15px;
        margin-top:0;
        margin-bottom:0;
        max-height: 100%;
        border-radius: 0;
    }
`));

const StyledDialogChangePassword = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
    }
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
    margin-top: 30px;
`));

const TableHeadCell = styled(TableCell)(({ theme }) => (`
    font-weight: bold;
    width: 200px;
`));

export const DetailsPermissionGroupPage = (props) => {
    const {
        view,
        setView,
        isLoading,
        usersPermissionItemRequest,
        permissionGroupItem,
        usersPermissionItemDelete,
        usersPermissionItemUpdate,
    } = props;

    useEffect(() => {
        usersPermissionItemRequest(view.code);
        // eslint-disable-next-line
    }, [])

    const handleClickNavigation = (way) => () => {
        setView((prev) => ({ ...prev, point: way}))
    }

    const [open, setOpen] = React.useState(false);
    const handlEditeOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    }
    const handleDeleteModalClose = () => setDeleteModalOpen(false);

    const handleSubmitEdited = (body) => {
        usersPermissionItemUpdate(permissionGroupItem.code, body);
        /// after Edit
    }

    const handleDelete = () => {
        usersPermissionItemDelete(view.code);
        handleClickNavigation('main')();
    }

    return (
        <PageContainer>
            <UserDetailsHeader>
                <UserDetailsHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer>
                            <ArrowBackSvg size="22px" onClick={handleClickNavigation('main')}/>
                        </ArrowContainer>
                        <UserDetailsHeaderText>
                            Permission group: {permissionGroupItem.name}
                        </UserDetailsHeaderText>
                    </HeaderLeftContainer>
                </UserDetailsHeaderLeft>
                <UserDetailsHeaderRight>
                    <Button startIcon={ <EditSvg size="22px"/>} onClick={handlEditeOpen}>
                        EDIT
                    </Button>
                    <Button isDeleteButton={true} onClick={handleDeleteModalOpen}>
                        <DeleteIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        Delete
                    </Button>
                </UserDetailsHeaderRight>
            </UserDetailsHeader>

            <StyledPaperTable>
                <TableContainer component={'div'}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableHeadCell>Name</TableHeadCell>
                                <TableCell>{permissionGroupItem.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Code</TableHeadCell>
                                <TableCell>{permissionGroupItem.code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Created</TableHeadCell>
                                <TableCell>
                                    {
                                        format(new Date(permissionGroupItem.createdAt * 1000), 'yyyy-MM-dd HH:mm:ss')
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Updated</TableHeadCell>
                                <TableCell>
                                    {
                                        permissionGroupItem.hasOwnProperty('updatedAt') 
                                            ? format(new Date(permissionGroupItem.updatedAt * 1000), 'yyyy-MM-dd HH:mm:ss')
                                            : <span style={{color: '#c55', fontStyle: 'italic'}}>(No data)</span>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledPaperTable>

            <StyledDialogEdit
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledBoxPopup>
                    <EditUserForm 
                        onClose={handleClose}
                        initialState={{name: permissionGroupItem.name, code: permissionGroupItem.code}}
                        sendChange={handleSubmitEdited}
                    />
                </StyledBoxPopup>
            </StyledDialogEdit>

            <StyledDialogChangePassword
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NotifyDialog
                    title={`Delete pemission group ${permissionGroupItem.name}`}
                    confirmButtonText='YES, DELETE'
                    cancelButtonText='CANCEL'
                    onClose={handleDeleteModalClose}
                    width="400px"
                    isDeleteButton
                    onCancel={handleDeleteModalClose}
                    onConfirm={handleDelete}
                >
                    Are you sure you want to delete this permission group?
                </NotifyDialog>  
            </StyledDialogChangePassword>

            { isLoading && <Loader/> }

        </PageContainer>
    )
}

// export default UserDetails;

export default compose(
    connect((state: RootState) => ({
        isLoading: state.permissionGroups.isLoading, 
        permissionGroupItem: state.permissionGroups.permissionItemGroups,
    }), {
        usersPermissionItemRequest,
        usersPermissionItemDelete,
        usersPermissionItemUpdate,
    }),
)(DetailsPermissionGroupPage)