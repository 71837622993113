import baseApi from './axiosParams';
const path = 'api/pps_settings';

const SettingsPPS = {
	settingsList: () => {
		return baseApi.get(`${path}`)
	},	
	settingsItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
	settingCreate: (data) => {
		return baseApi.post(`${path}`, data)
	},
	settingsItemDelete: (id) => {
		return baseApi.delete(`${path}/${id}`)
	},
	settingsItemUpdate: (id, data) => {
		return baseApi.patch(`${path}/${id}`, data)
	}
}

export default SettingsPPS;