import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from '../../components/button';

import { connect } from 'react-redux';
import { compose } from "redux";
import { repeatQueuesRequest } from '../../actions/repeatQueue';
import { RootState } from '../../store/configure-store';
import { AccessDeniedAlert } from "../../components/access-denied";


const PageContainer = styled('div')(({theme}) => (`
    position: relative;
    min-height: 100% - 64px;
    box-sizing: border-box;
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const QueuePageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
    @media(max-width: 768px){
        flex-wrap: wrap;
    }
    button{
        margin-top: 12px;
        margin-right: 12px;
        svg{
            margin-right: 8px;
        }
    }
`);

const QueuePageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 20px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
        padding-bottom: 16px;
    }
`));

const QueuePageHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media(max-width: 991px){
        width: 100%;
        margin-bottom: 16px;
    }
    @media(max-width: 768px){
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 12px;
        justify-content: space-between;
    }
`);

const RepeatQueuesList = styled('div')(({theme}) => (`
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    max-width: 740px;
    padding: 9px 16px 9px;
    color: ${theme.palette.text.primary};
`));

type PropType = {
    queue: any,
    repeatQueuesRequest: (type: string) => void,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

 const ReapeatQueuesPage: React.FC<PropType> = ({
    queue, 
    repeatQueuesRequest,
    routsUser, 
    userRoles, 
    isSuperadmin,
}) => {

    const handleRepeatWithdrawQueue = () => { 
        repeatQueuesRequest('withdraw')
    };

    const handleRepeatNotifyQueue = () => { 
        repeatQueuesRequest('notify')
    };

    const handleRepeatUpdateQueue = () => { 
        repeatQueuesRequest('update')
    };

    const accessedRole = routsUser.some(rout => rout.child.includes('/site/repeat-queue-process')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    return (
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole &&
                <>
                    <QueuePageHeaderLeft>
                        <QueuePageHeaderText>Repeat queue process</QueuePageHeaderText>
                    </QueuePageHeaderLeft>
                    <QueuePageHeader>
                        <Button onClick={handleRepeatWithdrawQueue}>
                            Repeat withdraw-queue
                        </Button>
                        <Button onClick={handleRepeatNotifyQueue}>
                            Repeat notify-queue
                        </Button>
                        <Button onClick={handleRepeatUpdateQueue}>
                            Repeat update-queue
                        </Button>
                    </QueuePageHeader>  
                    <RepeatQueuesList>
                        {
                            queue.length > 0 ? JSON.stringify(queue) : ''
                        }
                    </RepeatQueuesList>
                </>
            }
        </PageContainer>
    )
}

export default compose(
    connect((state: RootState | any) => ({
        queue: state.repeatQueue.repeatQueues,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), 
    {
        repeatQueuesRequest
    }),
)(ReapeatQueuesPage)
