export namespace ActionCurrentUserTypes {
    export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
    export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
    export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
};

export const initialState = {
	isAuth: false,
	isError: false,
    isLoading: false,
	userData: {
		'@context': '',
		'@id': '',
		'@type': '',
		id: 1,
		username: '',
		fields: [
			{
				child: "",
				parent: '',
			}
		],
		permissions: [
			{
				description: '',
				name: '',
			}
		],
		roles: [
			{
				name: "",
			}
		],
		routs: [
			{
				child: '',
				parent: '',
			}
		],
		superadmin: 0,
		timezone: '(UTC+00:00) UTC'
	},
	errorMessage: ''
};

export const currentUser = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionCurrentUserTypes.FETCH_CURRENT_USER_START:
			return {
				...state,
				isAuth: false,
				isLoading: true
			};
		case ActionCurrentUserTypes.FETCH_CURRENT_USER_SUCCESS:
			return {
				...state,
				isAuth: true,
				isLoading: false,
				userData: payload,
				errorMessage: ''
			};
		case ActionCurrentUserTypes.FETCH_CURRENT_USER_FAILURE:
			return {
				...state,
				isAuth: false,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
