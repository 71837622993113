import React from 'react';
import { styled } from "@mui/material/styles";
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { MigrationInfoEl } from './migrationInfoEl'

import { connect } from 'react-redux';
import { compose } from "redux";
import { requestMigration, createMigration } from '../../actions/migration';
import { RootState } from '../../store/configure-store';
import { Loader } from '../../components/loaders/loader'
import { AccessDeniedAlert } from '../../components/access-denied';

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const MigrationsPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    '@media(max-width: 768px)' : {
        flexWrap: 'wrap',
        marginBottom: '4px'
    }
});

const MigrationsPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,
    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const MigrationPageButtonsRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 768px)' : {
        flexWrap: 'wrap',
        width: '100%',
    }
});

const LeftButtons = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    '@media(max-width: 1200px)' : {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    '@media(max-width: 768px)' : {
        flexWrap: 'wrap',
        width: '100%',
    }
});

const RightButtons = styled('div')({
    display: 'flex',
    '@media(max-width: 1200px)' : {
        paddingLeft: '8px',
    },
    '@media(max-width: 768px)' : {
        paddingLeft: '0px',
        width: '100%',
    }
});

const CreateMigrationRow = styled('div')({
    marginTop: '26px'
});

const ButtonStyled = styled(Button)(({ theme }) => ({
    marginRight: '12px', 
    marginTop: '16px',
    '@media(max-width: 1200px)' : {
        width: 'calc(50% - 32px/2)',
        lineHeight: '100%',
    },
    '@media(max-width: 767px)' : {
        width: '100%',
        marginRight: '0px', 
    }
}));

const ButtonRightStyled = styled(Button)(({ theme }) => ({
    marginTop: '16px',
    minWidth: '218px',
    '@media(max-width: 767px)' : {
        width: '100%',
    }
}));

const ButtonCreateStyled = styled(Button)(({ theme }) => ({
    marginLeft: '16px',
    '@media(max-width: 767px)' : {
        marginLeft: '0px',
        marginTop: '16px',
    }
}));

const InputCreateStyled = styled(Input)(({theme}) => ({
    marginTop: '0px', 
    marginBottom: '0px', 
    width: '350px', 
    maxWidth: '100%',
    '@media(max-width: 767px)' : {
        width: '100%',
    }
}))

interface Props {
    requestMigration: (type: string) => void, 
    createMigration: (type: string) => void,
    migration: string[], 
    isLoading: boolean,  
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

const MigrationsPage: React.FC<Props> = ({
    requestMigration, 
    createMigration,
    migration, 
    isLoading,
    routsUser, 
    userRoles, 
    isSuperadmin,
}) => {
    const [createMigrationOpen, setCreateMigrationOpen] = React.useState<boolean>(false);
    const [createInput, setCreateInput] = React.useState<string>('');

    const handleApplayMigration = () => {
        requestMigration('index')
        setCreateMigrationOpen(false);
    }

    const handleOpenCreateMigration = () => {
        setCreateMigrationOpen(true);
    };

    const handleSetMigrationHistory = () => {
        requestMigration('history')
        setCreateMigrationOpen(false);
    }

    const handleNewMigration = () => {
        requestMigration('up');
        setCreateMigrationOpen(false);
    }

    const handleRevertLastMigration = () => {
        requestMigration('down');
        setCreateMigrationOpen(false);        
    }

    const handleSetMigrationScaffold = () => {
        requestMigration('scaffold');
        setCreateMigrationOpen(false);        
    }
    
    const handleSubmitCreateMigration = () => {
        createMigration(createInput);
        setCreateInput('');
        setCreateMigrationOpen(false);
    }

    const handleChangeName = ({target:{value}}) => {
        setCreateInput(value)
    }
    
    const accessedRole = routsUser.some(rout => rout.child.includes('/migrations/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleCreate = routsUser.some(rout => rout.child === '/migrations/default/create') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleApply = routsUser.some(rout => rout.child === '/migrations/default/up') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleNew = routsUser.some(rout => rout.child === '/migrations/default/index') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleHistory = routsUser.some(rout => rout.child === '/migrations/default/history') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleScaffold = routsUser.some(rout => rout.child === '/migrations/default/scaffold') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleDown = routsUser.some(rout => rout.child === '/migrations/default/down') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    return (
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole &&
                <>
                    <MigrationsPageHeader>
                        <MigrationsPageHeaderText>
                            Migrations
                        </MigrationsPageHeaderText>
                    </MigrationsPageHeader>
                    <MigrationPageButtonsRow>
                        <LeftButtons>

                            {accessedRoleApply &&
                                <ButtonStyled onClick={handleApplayMigration}>Apply Migration</ButtonStyled>
                            }

                            {accessedRoleCreate &&
                                <ButtonStyled onClick={handleOpenCreateMigration}>Create Migration</ButtonStyled>
                            }

                            {accessedRoleNew &&
                                <ButtonStyled onClick={handleNewMigration} variant='outlined'>New Migrations</ButtonStyled>
                            }

                            {accessedRoleHistory &&
                                <ButtonStyled onClick={handleSetMigrationHistory} variant="outlined">Migration History</ButtonStyled>
                            }                            
                            
                            {accessedRoleScaffold &&
                                <ButtonStyled onClick={handleSetMigrationScaffold}>Scaffold</ButtonStyled>
                            }

                        </LeftButtons>
                        <RightButtons>
                            {accessedRoleDown &&
                                <ButtonRightStyled onClick={handleRevertLastMigration} variant='outlined'>Revert last migration</ButtonRightStyled>
                            }
                        </RightButtons>
                    </MigrationPageButtonsRow>
                    {createMigrationOpen && 
                        <CreateMigrationRow>
                            <InputCreateStyled 
                                value={createInput} 
                                size="small" 
                                label="Migration name *"
                                onChange={handleChangeName} 
                            />
                            <ButtonCreateStyled onClick={handleSubmitCreateMigration}>Create</ButtonCreateStyled>
                        </CreateMigrationRow>
                    }
                    {!createMigrationOpen &&
                        <MigrationInfoEl info={migration}/>
                    }
                </>    
            }

            {isLoading && <Loader/>}

        </PageContainer>
    )
}

export default compose(
    connect((state: RootState | any) => ({
        migration: state.migration.migration,
        isLoading: state.migration.isLoading,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
     }), {
        requestMigration, 
        createMigration
    }),
)(MigrationsPage)