import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/history_trans_statuses';

const HistoryTransStatus = {
	getHistoryTransStatus: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';

		return baseApi.get(`${path}${query}`)
	},
    // ...
}

export default HistoryTransStatus;