import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Button} from '../../components/button';
import {Input} from '../../components/input';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Box from '@mui/material/Box';

const ModalWrapper = styled(Box)(({ width, theme }) => (`
    & {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        padding: 40px;
        width: ${width ? width : '500px'};
        margin:0 auto;
        background: ${theme.palette.background.paper};
        color: ${theme.palette.text.primary};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));
    
const ModalFirstRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
});
    
const NotifyModalTitle = styled('div')(({ theme } ) => ({
    fontSize: '24px',
    fontWeight: '500',
    color: theme.palette.text.primary
}));
    
const IconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
});
    
const NotifyModalButtons = styled('div')({
    marginTop: '24px'
});

const StyledListUsersAssign = styled('div')(`
    & .overlay-scroll-users-list{
        height: 240px;
    }
`);

const ItemListAssigned = styled('div')(({ theme }) => ({
    fontSize: '14px',
    color: theme.palette.text.primary,
    opacity: '.7',
    transition: 'all .2s',
    padding: '4px 15px',
    cursor: 'pointer',

    '&:hover': {
        opacity: '1',
        paddingLeft: '20px',
    }
}));

const AssignedUsername = styled('div')(({ theme } ) => ({
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    padding: '10px 0',
}));

type Props = {
    onClose?: () => void;
    onSave?: (data: any) => void;
    assigmentList: Array<any>;
}

export const AssignmentUserForm = (props: Props) => {
    const {onClose, onSave, assigmentList} = props;

    const [filteredKey, setFilteredKey] = useState<string>('');

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const [userToAssign, setUserToAssign] = useState<any>({username: 'test', id: 777})

    const handleChangeFilter = (e) => {
        setFilteredKey(e.target.value);
    }

    const handleCheckUser = (item) => {
        setIsChecked(true);
        setUserToAssign(item);
        setFilteredKey('');
    }

    const handleClear = () => {
        setIsChecked(false);
        setUserToAssign({username: 'test', id: 777});
        setFilteredKey('');
    }

    const handleSave = () => {
        onSave(userToAssign.id);
    }
    
    return (
        <ModalWrapper>
            <ModalFirstRow>
                <NotifyModalTitle>
                    Assign user to node
                </NotifyModalTitle>
                <IconWrapper>
                    <CloseSvg size="14px" onClick={onClose}/>
                </IconWrapper>
            </ModalFirstRow>

            <Input 
                label="User" 
                size="small" 
                sx={{marginBottom: '20px'}} 
                value={filteredKey} 
                onChange={(e) => handleChangeFilter(e)} 
                autoComplete={'no'}
                InputProps={{
                    autoComplete: 'off',
                }}
            />

            {!isChecked &&
                <StyledListUsersAssign>
                    <OverlayScrollbarsComponent
                        className='overlay-scroll-users-list'
                        options={{
                            scrollbars: { visibility: 'visible' } 
                        }}
                    >
                        {
                            (assigmentList || []).filter(item => item.username.includes(filteredKey)).map((item, id) => {
                                return <ItemListAssigned 
                                        key={`${item?.username}+${item.id}`}
                                        onClick={() => handleCheckUser(item)}
                                    >
                                        {item?.username}
                                    </ItemListAssigned>
                            })
                        }
                    </OverlayScrollbarsComponent>
                </StyledListUsersAssign>
            }

            {isChecked && 
                <AssignedUsername>
                    {userToAssign.username} 
                    <span 
                        style={{
                            marginLeft: '12px',
                            cursor: 'pointer',
                        }}
                    >
                        <CloseSvg size="10px" fill={'#F5222D'} onClick={handleClear}/>
                    </span>
                </AssignedUsername>
            }

            <NotifyModalButtons>
                <Button 
                    data-testid={'testid-buttom-assign'}
                    onClick={handleSave}
                    disabled={!isChecked}
                >
                    ASSIGN
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={onClose}
                    data-testid={'testid-buttom-cancel'}
                    sx={{marginLeft: '16px'}}
                >
                    CANCEL
                </Button>
            </NotifyModalButtons>
        </ModalWrapper>
    );
};
