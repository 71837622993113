import React from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import FormControl from '@mui/material/FormControl';
import {Input} from '../../components/input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {SwitchComponent} from '../../components/switch'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {changePaymentSystemRequest, getPaymentSystemRequest} from '../../actions/paymentSystems';
import {useParams, useNavigate} from 'react-router-dom';
import {Loader} from '../../components/loaders/loader';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => (`    
    padding: 40px;
    position: relative;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const StyledTab = styled(Tab)(({ theme }) => (`
    &.MuiTab-root{
        color: #979799;
    }
    &.Mui-selected{
        color: ${theme.palette.text.primary};
    }
`));

const StyledTabs = styled(Tabs)(`
    & .MuiTabs-indicator{
        background-color: #272F3D;
    },
`);

const StyledTabPanel = styled(TabPanel)(`
    padding: 0px 0px 30px 0px;
    margin-top: 24px;
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const WrapConfigureFormContainer = styled('div')(({ theme }) => (`
    width: 100%;
    max-width: 470px;
`))

const WrapFields = styled('div')(({ theme }) => (``))

const StyledFormControl = styled(FormControl)(({ theme }) => (`
    margin-top: 20px;
    width: 100%;
    & label.Mui-focused{
        color: #38AA72;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
                border-color: #38AA72;
        }
    }   
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const WrapCurrencyTab = styled('div')(({theme}) => (`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    width: 100%;
    max-width: 500px;
`));

const ButtonText = styled(Button)(({theme}) => (`
    border: none !important;
    font-weight: 500;
    height: 30px;
    margin-right: 0px;
    padding-left: 5px;
    padding-right: 5px;
    &:disabled{        
    }
`));

const CurrencyTab = styled(ButtonText)(({theme}) => (`
    font-size: 14px;
    color: ${theme.palette.mode === 'dark' ? theme.palette.text.primary : '#797780'}; 
    margin: 6px 6px 0 0; 
    font-weight: 500;
    &.MuiButton-text{
        background: #F1F8ED;
        border-radius: 6px;
        color: #38AA72;
    }
`));

type PropsPaymentSystemsMain = {
    paymentSystem: any;
    changePaymentSystemRequest: (id: number, body: any) => void;
    getPaymentSystemRequest: (id: string) => void;
    isLoading: boolean;
    routsUser: Array<any>, 
    userRoles: Array<any>,
    isSuperadmin: any,
};

const PaymentSystemGlobalConfigurePage = ({
    paymentSystem, 
    changePaymentSystemRequest, 
    getPaymentSystemRequest, 
    isLoading,
    routsUser, 
    userRoles,
    isSuperadmin,
}: PropsPaymentSystemsMain) => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = React.useState<any>(0);

    const accessedRoleEdit = routsUser.some(rout => rout.child === '/payment-system/edit') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    React.useEffect(() => {
        if(accessedRoleEdit){
            getPaymentSystemRequest(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setFormModeModel(paymentSystem.form?.ModeModel);
        setFormModel(paymentSystem.form?.Model);
        setFormPaymentSystem(paymentSystem.form?.PaymentSystem);
    }, [paymentSystem]);

    const [tabValue, setTabValue] = React.useState('1');
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const [formModeModel, setFormModeModel] = React.useState(paymentSystem?.form?.ModeModel);
    const handleChangeFormModeModel = (componentType, id) => (event) => {
        setFormModeModel((prevState) => {
            const newState = prevState.map((item, index) => {
                if (index === id) {
                    return {...item, value: componentType === 'checkbox' ? String(Number(!Boolean(Number(prevState[id].value)))) : event.target.value}
                }
                return item;
            });

            const transformedData = {};

            newState.forEach(item => {
                transformedData[item.attribute] = item.value;
            });

            setFormPaymentSystem((prevState) => {
                return prevState.map(item => {
                    if (item.attribute === 'preset') {
                        return {...item, value: JSON.stringify(transformedData)};
                    };
                    return item;
                })
            });

            return newState;
        });
    };

    const [formModel, setFormModel] = React.useState(paymentSystem?.form?.Model);
    const handleChangeFormModel = (componentType, id) => (event) => {
        setFormModel((prevState) => {
            const newState = {...prevState};
            const selectedEntry = Object.entries(formModel)[selectedIndex];

            const updatedValues = (prevState[selectedEntry[0]]).map((item, index) => {
                if (index === id) {
                    return {...item, value: componentType === 'checkbox' ? String(Number(!Boolean(Number(prevState[selectedEntry[0]][id].value)))) : event.target.value};
                }
                return item;
            });

            newState[selectedEntry[0]] = updatedValues;

            const transformedData = Object.entries(newState).reduce((acc, [key, value]) => {
                const obj = {};
                (value as any).forEach(item => {
                    obj[item.attribute] = item.value
                });
                acc[key] = obj;
                return acc;
            }, {});

            setFormPaymentSystem((prevState) => {
                return prevState.map(item => {
                    if (item.attribute === 'preset') {
                        return {...item, value: JSON.stringify(transformedData)};
                    };
                    return item;
                })
            });

            return newState;
        });
    };

    const [formPaymentSystem, setFormPaymentSystem] = React.useState(paymentSystem?.form?.PaymentSystem);
    const handleChangeFormPaymentSystem = (prop) => (event) => {
        setFormPaymentSystem((prevState) => {
            return prevState.map(item => {
                if (item.attribute === prop) {
                    if (prop === 'transaction_lifetime') {
                        item.value = Number(event.target.value);
                    } else if (prop === 'active') {
                        item.value = Number(!Boolean(prevState.find(item => item.attribute === 'active').value))
                    } else {
                        item.value = event.target.value;
                    }
                };
                return item;
            });
        });
    };

    const handleSaveChanges = () => {
        let model, modeModel;
        
        if (formModel) {
            model = Object.entries(formModel).reduce((acc, [key, value]) => {
                const obj = {};
                (value as any).forEach(item => {
                    obj[item.attribute] = item.value
                });
                acc[key] = obj;
                return acc;
            }, {});
        } else {
            modeModel = {};
            formModeModel.forEach(item => {
                modeModel[item.attribute] = item.value;
            });
        };

        const ps = {};
        formPaymentSystem.forEach(item => {
            ps[item.attribute] = item.value;
        });

        const data = formModeModel ? {
            PaymentSystem: ps,
            ModeModel: modeModel,
            form : {
                ModeModel: formModeModel,
                PaymentSystem: formPaymentSystem
           }
        } : {
            PaymentSystem: ps,
            Model: model,
            form: {
                Model: formModel,
                PaymentSystem: formPaymentSystem
            } 
        };

        changePaymentSystemRequest(paymentSystem.PaymentSystem.id, data);
        navigate('/payment-systems-global');
    };

    const handleClickCurrencyTab = (index) => {
        setSelectedIndex(index);
    };

    return (
        <PageContainer>

            {!accessedRoleEdit && <AccessDeniedAlert/>}

            {accessedRoleEdit &&
                <>
                    <ShortViewHeader>
                        <ShortViewHeaderLeft>
                            <HeaderLeftContainer>
                                <ArrowContainer>
                                    <ArrowBackSvg size="22px" onClick={() => navigate(-1)}/>
                                </ArrowContainer>
                                <ShortViewHeaderText>
                                    {paymentSystem.length !== 0 ? paymentSystem.PaymentSystem.name : 'No data'}
                                </ShortViewHeaderText>
                            </HeaderLeftContainer>
                        </ShortViewHeaderLeft>
                        {paymentSystem.length !== 0 ?
                            <ShortViewHeaderRight>
                                <Button onClick={handleSaveChanges}>
                                    save
                                </Button>
                                <Button variant="outlined" onClick={() => navigate('/payment-systems-global')}>
                                    CANCEL
                                </Button>
                            </ShortViewHeaderRight>
                        : null}
                    </ShortViewHeader>
                    {paymentSystem.length !== 0 ?
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <StyledTabs value={tabValue} onChange={handleChangeTab} aria-label="lab API tabs example">
                                        <StyledTab label="INFO" value="1" />
                                        <StyledTab label="Default fields preset" value="2" />
                                    </StyledTabs>
                                </Box>
                                <StyledTabPanel value="1">                        
                                    <WrapConfigureFormContainer>
                                        <WrapFields>     
                                            <Input sx={{marginTop: '20px'}} label="Name *" value={formPaymentSystem ? formPaymentSystem?.find(item => item.attribute === 'name').value : ''} onChange={handleChangeFormPaymentSystem('name')} />  
                                            <Input sx={{marginTop: '20px'}} label="Payment system code *" value={formPaymentSystem ? formPaymentSystem?.find(item => item.attribute === 'code').value : ''} onChange={handleChangeFormPaymentSystem('code')} />
                                            <Input sx={{marginTop: '20px'}} label="Logo" value={formPaymentSystem ? formPaymentSystem?.find(item => item.attribute === 'logo').value : ''} onChange={handleChangeFormPaymentSystem('logo')} />
                                            <Input sx={{marginTop: '20px'}} label="Callback URL" value={formPaymentSystem ? formPaymentSystem?.find(item => item.attribute === 'callback_url').value : ''} onChange={handleChangeFormPaymentSystem('callback_url')} />
                                            <StyledFormControl fullWidth>
                                                <InputLabel size="small" id="demo-simple-select-label">Transaction lifetime</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formPaymentSystem ? String(formPaymentSystem.find(item => item.attribute === 'transaction_lifetime').value) : ''}
                                                    label="Transaction lifetime"
                                                    onChange={handleChangeFormPaymentSystem('transaction_lifetime')}
                                                    size='small'
                                                >
                                                    {Object.entries((formPaymentSystem?.find(item => item.attribute === 'transaction_lifetime').field.values) || {}).map((item: any) => {
                                                        return (
                                                            <StyledMenuItem key={item[0]} value={item[0]}>{item[1]}</StyledMenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </StyledFormControl>
                                            <SwitchComponent 
                                                checked={Boolean(formPaymentSystem?.find(item => item.attribute === 'active').value)}
                                                onChange={handleChangeFormPaymentSystem('active')}
                                                sx={{marginTop: '10px'}} 
                                                label="Active"
                                            />
                                        </WrapFields> 
                                    </WrapConfigureFormContainer>
                                </StyledTabPanel>
                                <StyledTabPanel value="2">
                                    {formModel ? 
                                    <>
                                        <WrapCurrencyTab>
                                            {
                                                Object.entries(formModel).map((item, index) => {
                                                    return <CurrencyTab 
                                                        variant={`${index === selectedIndex ? 'text' : 'outlined'}`}
                                                        key={index}
                                                        onClick={() => {
                                                            handleClickCurrencyTab(index)
                                                        }}
                                                        >
                                                        {item[0]}
                                                    </CurrencyTab>
                                                })
                                            }
                                        </WrapCurrencyTab>
                                        <WrapConfigureFormContainer>
                                            {(Object.entries(formModel)[selectedIndex][1] as any).map((item: any, id: any) => {
                                                if (item.field.type === 'text' || item.field.type === 'password') {
                                                    return (
                                                        <Input
                                                            sx={{marginTop: '20px'}}
                                                            label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                            value={item.value}
                                                            onChange={handleChangeFormModel('text', id)}
                                                            key={id}
                                                        />
                                                    );
                                                } else if (item.field.type === 'number') {
                                                    return (
                                                        <Input
                                                            sx={{marginTop: '20px'}}
                                                            label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                            value={item.value}
                                                            type="number"
                                                            onChange={handleChangeFormModel('number', id)}
                                                            key={id}
                                                        />
                                                    );
                                                }  else if (item.field.type === 'checkbox') {
                                                    return (
                                                        <SwitchComponent 
                                                            checked={Boolean(Number(item.value))}
                                                            onChange={handleChangeFormModel('checkbox', id)}
                                                            sx={{marginTop: '20px'}}
                                                            label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                            key={id}
                                                        />
                                                    );
                                                } else if (item.field.type === 'select' || item.field.type === 'list') {
                                                    return (
                                                        <StyledFormControl fullWidth>
                                                            <InputLabel size="small">{`${item.field.label}${item.field.required ? ' *' : ''}`}</InputLabel>
                                                            <Select
                                                                value={item.value}
                                                                label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                onChange={handleChangeFormModel('select', id)}
                                                                size='small'
                                                                key={id}
                                                            >
                                                                {Object.entries(item.field.values).map((i: any) => {
                                                                    return (
                                                                        <StyledMenuItem key={i[0]} value={i[0]}>{i[1]}</StyledMenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </StyledFormControl>
                                                    )
                                                } else if (item.field.type === 'text-area') {
                                                    return (
                                                        <Input
                                                            sx={{marginTop: '20px'}}
                                                            label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                            value={item.value}
                                                            onChange={handleChangeFormModel('text-area', id)}
                                                            key={id}
                                                            multiline
                                                            rows={6}
                                                        />
                                                    )
                                                } else {
                                                    return `${item.field.type} !!!`;
                                                };
                                            })}
                                        </WrapConfigureFormContainer>
                                    </>
                                    :
                                    <WrapConfigureFormContainer>
                                        {formModeModel?.map((item: any, id: any) => {
                                            if (item.field.type === 'text' || item.field.type === 'password') {
                                                return (
                                                    <Input
                                                        sx={{marginTop: '20px'}}
                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                        value={item.value}
                                                        onChange={handleChangeFormModeModel('text', id)}
                                                        key={id}
                                                    />
                                                );
                                            } else if (item.field.type === 'number') {
                                                return (
                                                    <Input
                                                        sx={{marginTop: '20px'}}
                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                        value={item.value}
                                                        type="number"
                                                        onChange={handleChangeFormModeModel('number', id)}
                                                        key={id}
                                                    />
                                                );
                                            }  else if (item.field.type === 'checkbox') {
                                                return (
                                                    <SwitchComponent 
                                                        checked={Boolean(Number(item.value))}
                                                        onChange={handleChangeFormModeModel('checkbox', id)}
                                                        sx={{marginTop: '20px'}}
                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                        key={id}
                                                    />
                                                );
                                            } else if (item.field.type === 'select' || item.field.type === 'list') {
                                                return (
                                                    <StyledFormControl fullWidth>
                                                        <InputLabel size="small">{`${item.field.label}${item.field.required ? ' *' : ''}`}</InputLabel>
                                                        <Select
                                                            value={item.value}
                                                            label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                            onChange={handleChangeFormModeModel('select', id)}
                                                            size='small'
                                                            key={id}
                                                        >
                                                            {Object.entries(item.field.values).map((i: any) => {
                                                                return (
                                                                    <StyledMenuItem key={i[0]} value={i[0]}>{i[1]}</StyledMenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </StyledFormControl>
                                                )
                                            } else if (item.field.type === 'text-area') {
                                                return (
                                                    <Input
                                                        sx={{marginTop: '20px'}}
                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                        value={item.value}
                                                        onChange={handleChangeFormModel('text-area', id)}
                                                        key={id}
                                                        multiline
                                                        rows={6}
                                                    />
                                                )
                                            } else {
                                                return `${item.field.type} !!!`;
                                            };
                                        })}
                                    </WrapConfigureFormContainer>}
                                </StyledTabPanel>
                            </TabContext>
                        </Box>
                    : null}
                </>
            }

            {isLoading && <Loader />}
        </PageContainer>
    );
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.paymentSystems.isLoading,
        paymentSystem: state.paymentSystems.paymentSystem,
        userRoles: state.currentUser.userData.roles,
        routsUser: state.currentUser.userData.routs,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        changePaymentSystemRequest, 
        getPaymentSystemRequest,
    })
)(PaymentSystemGlobalConfigurePage);
