export namespace ActionTypes {
    export const FETCH_REPORT_START = 'FETCH_REPORT_START';
    export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
    export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
    export const FETCH_REPORT_FINISHED = 'FETCH_REPORT_FINISHED';
};

export type ReportItem = {
	currency: string,
	deposits_amount: number,
	deposits_summary: number,
	withdraws_amount: number,
	withdraws_summary: number,
	summary: number,
}

interface Report {
	isError: boolean,
    isLoading: boolean,
	reportList: Array<ReportItem>,
	errorMessage: string,
}

export const initialState: Report = {
	isError: false,
    isLoading: false,
	reportList: [
        {
            currency: "",
            deposits_amount: 0,
            deposits_summary: 0,
            withdraws_amount: 0,
            withdraws_summary: 0,
            summary: 0,
        },
	],
	errorMessage: ''
};

export const report = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_REPORT_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_REPORT_SUCCESS:
			return {
				...state,
				reportList: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_REPORT_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
