import {ActionTypes} from '../reducers/usersAll';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_USERSALL_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_USERSALL_SUCCESS,
		payload: list
	};
};

export const requestItem = item => {
	return {
		type: ActionTypes.FETCH_ITEM_USERSALL_SUCCESS,
		payload: item,
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_USERSALL_FAILURE,
		payload: error
	}
};

export const changePasswordSuccess = () => {
	return {
		type: ActionTypes.FETCH_CHANGE_PASSWORD_SUCCESS
	}
};

export const changePasswordFailure = (error) => {
	return {
		type: ActionTypes.FETCH_CHANGE_PASSWORD_FAILURE,
		payload: error
	}
};

export const requestItemRoles = itemRoles => {
	return {
		type: ActionTypes.FETCH_USERSALL_ROLES_SUCCESS,
		payload: itemRoles,
	};
};

export const requestItemRolesThree = roles => {
	return {
		type: ActionTypes.FETCH_USERSALL_ASSIGMENTS_SUCCESS,
		payload: roles,
	};
};

export const requestItemRolesThreeFailure = err => {
	return {
		type: ActionTypes.FETCH_USERSALL_ASSIGMENTS_FAILURE,
		payload: err,
	};
};

export const userAssigmentRolesSuccess = () => {
	return {
		type: ActionTypes.FETCH_CHANGE_ASSIGMENTS_SUCCESS
	};
};

export const userAssigmentRolesFailure = err => {
	return {
		type: ActionTypes.FETCH_CHANGE_ASSIGMENTS_FAILURE,
		payload: err,
	};
};

export const usersAllgRequest = data => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.getList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const usersAllRequestItem = id => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.getItem(id)
		.then(response => {
			dispatch(requestItem(response.data));

		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const createItemRequest = (data) => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.createItem(data)
		.then(response => {
			dispatch(usersAllgRequest(''))
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const changePasswordRequest = (id, data) => dispatch => {
	return API.usersAll
	.changePassword(id, data)
	.then(() => dispatch(changePasswordSuccess()))
	.catch((error) => dispatch(changePasswordFailure(error.message)))
};

export const usersAllRequestItemRoles = id => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.getUserPermissions(id)
		.then(response => {
			dispatch(requestItemRoles(response.data));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const usersAllRequestItemThree = id => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.getUserPermissionsThree(id)
		.then(response => {
			dispatch(requestItemRolesThree(response.data));
		})
		.catch(error => dispatch(requestItemRolesThreeFailure(error.message)));
};

export const usersAssigmentRoles = (id, body) => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.assigmentUserRoles(id, body)
		.then(response => {
			dispatch(usersAllRequestItemThree(id));
		})
		.catch(error => dispatch(userAssigmentRolesFailure(error.message)));
};

export const usersDelete = (id) => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.deleteUser(id)
		.then(response => {
			// console.log('response', response)
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const userUpdate = (id, data) => dispatch => {
	dispatch(requestStart());
	return API.usersAll
		.updateUser(id, data)
		.then(response => {
			dispatch(usersAllRequestItem(id));
		})
		.catch(error => dispatch(requestFailure(error.message)));
};