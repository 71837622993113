import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const ArrowBackSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 22 22"
            onClick={onClick}
        >
            <path d="M21.6666 9.66683H5.43992L12.8933 2.2135L10.9999 0.333496L0.333252 11.0002L10.9999 21.6668L12.8799 19.7868L5.43992 12.3335H21.6666V9.66683Z" fill={fill}/>
        </WrapperTag>
    );
};

ArrowBackSvg.defaultProps = {
    fill: '#70747E',
};
