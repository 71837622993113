import React, {useEffect} from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../../components/svg/arrow-back-svg";
import {Button} from '../../../components/button';
import { EditSvg } from '../../../components/svg/edit-svg';
import {MoreSvg} from "../../../components/svg/more-svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { EditUserForm } from '../edit-user-form';
import Box from '@mui/material/Box';
import { NotifyDialog } from '../../../components/notify-dialog';
import Dialog from '@mui/material/Dialog';
import { Status } from '../../../components/status';
import { StatusActive } from '../../../components/status-active';
import {
    usersAllRequestItem,
    usersDelete,
    userUpdate,
} from "../../../actions/usersAll";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../../store/configure-store';
import { Loader } from '../../../components/loaders/loader';
// import {useNavigate} from "react-router-dom";
import {useParams, useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom';


const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px'
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UserDetailsHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px'

});

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
    margin-top: 30px;
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root': {
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
        }
    }
}));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledDialogEdit = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        margin: 15px;
        margin-top:0;
        margin-bottom:0;
        max-height: 100%;
        border-radius: 0;
    }
`));

const StyledDialogChangePassword = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
    }
`));

const TableHeadCell = styled(TableCell)(({ theme }) => (`
    font-weight: bold;
`));

const StyledMenu = styled(Menu)(({ theme }) => (`
    & > .MuiMenu-paper{
        border-radius: 0;
    }
`));

const LoginContent = styled(Link)(({ theme }) => (`
    text-decoration: none;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

export const UserDetails = (props) => {
    const {
        usersAllRequestItem,
        isLoading,
        user,
        usersDelete,
        userUpdate,
    } = props;

    const {id} = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        usersAllRequestItem(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goBack = () => navigate(-1)

    const [open, setOpen] = React.useState(false);
    const handlEditeOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
        handleMenuClose()
    }
    const handleDeleteModalClose = () => setDeleteModalOpen(false);

    const handleDeleteUser = () => {
        usersDelete(user.id);
        goBack();
    }

    const rolesUser = () => {
        const listRoles = (user.role || []).map(item => item.name);
        return listRoles.join(', ');
    }

    const handleSaveEditedData = (data) => {
        userUpdate(id, data);
    }

    return (
        <PageContainer>
            <UserDetailsHeader>
                <UserDetailsHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer>
                            <ArrowBackSvg size="22px" onClick={goBack}/>
                        </ArrowContainer>
                        <UserDetailsHeaderText>
                            User {user.username}
                        </UserDetailsHeaderText>
                    </HeaderLeftContainer>
                </UserDetailsHeaderLeft>
                <UserDetailsHeaderRight>
                    <Button startIcon={ <EditSvg size="22px"/>} onClick={handlEditeOpen}>
                        EDIT
                    </Button>
                    <Button variant="outlined" onClick={handleMenuClick}>
                        <MoreSvg size="22px" fill="#38AA72"/>
                    </Button>
                </UserDetailsHeaderRight>
            </UserDetailsHeader>
            <StyledPaperTable>
                <TableContainer component={'div'}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableHeadCell>ID</TableHeadCell>
                                <TableCell>{user.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Status</TableHeadCell>
                                <TableCell>
                                    <Status status={user.status}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Login</TableHeadCell>
                                <TableCell>{user.username}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Note</TableHeadCell>
                                <TableCell>{user.roleNote}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>E-mail</TableHeadCell>
                                <TableCell>
                                    {user.email ? user.email : ''}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>E-mail confirmed</TableHeadCell>
                                <TableCell>
                                    <StatusActive active={user.emailConfirmed} textOn='Yes' textOff='No'/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Roles</TableHeadCell>
                                <TableCell>
                                    {
                                        rolesUser()
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Bind to IP</TableHeadCell>
                                <TableCell>{user.bindToIp}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Registration IP</TableHeadCell>
                                <TableCell>{user.registrationIp}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Created</TableHeadCell>
                                <TableCell>{user.createdAt}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Updated</TableHeadCell>
                                <TableCell>{user.updatedAt}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Assignments</TableHeadCell>
                                <TableCell>
                                    {   
                                        user.nodes?.length > 0 &&
                                            user?.nodes?.map((item) => {
                                                return <div key={Math.random()}>{`[#${item['@id'].replace(/[^0-9]/g,"")}] ${item.name}`}</div>
                                            })
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledPaperTable>
            <StyledMenu
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        width: '200px',
                    },
                }}
            >
                <StyledMenuItem>
                    <LoginContent to={`/user/user-permissions/${user.id}`}>
                        Roles and permissions
                    </LoginContent>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDeleteModalOpen}>
                    Delete
                </StyledMenuItem>
            </StyledMenu>

            <StyledDialogEdit
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledBoxPopup>
                    <EditUserForm 
                        onClose={handleClose}
                        userInit={user}
                        onSave={handleSaveEditedData}
                    />
                </StyledBoxPopup>
            </StyledDialogEdit>

            <StyledDialogChangePassword
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NotifyDialog
                    title={`Delete user ${user.username}`}
                    confirmButtonText='YES, DELETE'
                    cancelButtonText='CANCEL'
                    onClose={handleDeleteModalClose}
                    width="400px"
                    isDeleteButton
                    onCancel={handleDeleteModalClose}
                    onConfirm={handleDeleteUser}
                >
                    Are you sure you want to delete this user?
                </NotifyDialog>  
            </StyledDialogChangePassword>

            { isLoading && <Loader/> }

        </PageContainer>
    )
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.usersAll.isLoading,
        user: state.usersAll.usersItem,
    }), {
        usersAllRequestItem,
        usersDelete,
        userUpdate,
    }),
)(UserDetails)