import React from "react";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Loader } from '../../components/loaders/loader';
import { 
    sysInfoAdressesRequest,
} from "../../actions/systemInfoAdresses";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Tooltip from '@mui/material/Tooltip';
import { AdressesListItem } from "../../reducers/systemInfoAddresses";
import { AccessDeniedAlert } from "../../components/access-denied";

const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '24px',
    }
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',
    height: '56.7px',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 185px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 195px - 56px);
        }; 
    }
`));

type DataRequest = {
    id?: number,
    userId?: string,
    'brand.id'?: number,
    page: number,
    'paymentSystem.name'?: string,
    address?: string,
}

interface PropType{
    isLoading: boolean,
    addressesList: Array<AdressesListItem>,
    adrdessesPageCount: number,
    sysInfoAdressesRequest: (data: DataRequest) => void,
    setView: any,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

type StateType = {
    page: number,
    rowsPerPage: number,
    id: number,
    userId: string,
    brandId: number,
    paymentSystemName: string,
    address: string,
}

export class SystemInfoHdbkRequestsTypes extends React.Component<PropType, StateType> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            id: 0,
            userId: '',
            brandId: 0,
            paymentSystemName: '',
            address: '',
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleChangeBuyer = (id) => {
        const {setView} = this.props;
        setView(prev => ({...prev, id, point: 'view'}))
    }

    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/user-address/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    updateData = () => {

        const {
            id,
            page,
            userId,
            brandId,
            address,
            paymentSystemName,
        } = this.state;

        const {
            sysInfoAdressesRequest,
        } = this.props;

        const data = {
            'id': id,
            'userId': userId,
            'brand.id': brandId,
            'page': page + 1,
            'paymentSystem.name': paymentSystemName,
            'address': address,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        sysInfoAdressesRequest(data);
    }

    render() {

        const {
            isLoading,
            addressesList,
            adrdessesPageCount, 
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props

        const {
            page,
            rowsPerPage,
            address,
            paymentSystemName,
            brandId,
            userId,
            id,
        } = this.state;
        
        const accessedRole = routsUser.some(rout => rout.child.includes('/user-address/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleView = routsUser.some(rout => rout.child === '/user-address/view') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole && 
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>User Addresses</DepositMainHeaderText>
                            </DepositMainHeader>
            
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <WithSortedSerachTableHeader 
                                                        label='Id' 
                                                        handleSearchSubmit={this.handleSearchSubmit('id')}
                                                        handleCancelSearch={this.handleCancelSearch('id')}
                                                        isActiveFilter={Boolean(id)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='User Id' 
                                                        handleSearchSubmit={this.handleSearchSubmit('userId')}
                                                        handleCancelSearch={this.handleCancelSearch('userId')}
                                                        isActiveFilter={Boolean(userId)}
                                                    />
                                                    
                                                    <WithSortedSerachTableHeader 
                                                        label='Brand Id' 
                                                        handleSearchSubmit={this.handleSearchSubmit('brandId')}
                                                        handleCancelSearch={this.handleCancelSearch('brandId')}
                                                        isActiveFilter={Boolean(brandId)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        label='Payment System' 
                                                        handleSearchSubmit={this.handleSearchSubmit('paymentSystemName')}
                                                        handleCancelSearch={this.handleCancelSearch('paymentSystemName')}
                                                        isActiveFilter={Boolean(paymentSystemName)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        label='Address' 
                                                        handleSearchSubmit={this.handleSearchSubmit('address')}
                                                        handleCancelSearch={this.handleCancelSearch('address')}
                                                        isActiveFilter={Boolean(address)}
                                                    />

                                                    {accessedRoleView &&
                                                        <ActionsHeaderCell>
                                                            <StickyBorder>Action</StickyBorder>
                                                        </ActionsHeaderCell>
                                                    }
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(addressesList || []).map(row => {
                                                    return (
                                                        <TableRow key={Math.random()}>
                                                            <TableCell>
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.userId}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.brand.id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.paymentSystem.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.address}
                                                            </TableCell>

                                                            {accessedRoleView &&
                                                                <ActionsCell>
                                                                    <StickyBorder>
                                                                        <ActionsContent> 
                                                                            <Tooltip title='View details' placement="top">   
                                                                                <ActionIcon 
                                                                                    onClick={() => {this.handleChangeBuyer(row.id)}}
                                                                                >
                                                                                    <RemoveRedEyeIcon />
                                                                                </ActionIcon>
                                                                            </Tooltip>
                                                                        </ActionsContent>
                                                                    </StickyBorder>
                                                                </ActionsCell>
                                                            }
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>                               
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={adrdessesPageCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }
    
                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.systemInfoAddresses.isLoading,
        addressesList: state.systemInfoAddresses.systemInfoAdresses['hydra:member'],
        adrdessesPageCount: state.systemInfoAddresses.systemInfoAdresses['hydra:totalItems'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        sysInfoAdressesRequest,
    }),
)(SystemInfoHdbkRequestsTypes);
