import React, {useState} from 'react';
import {HasChieldRoute} from './_has-chield-route';
import {
    // WrapSearchPanel, 
    ScrolledContainer
} from './permission-childs-lists';
// import {Input} from '../../../components/input';
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import { styled } from "@mui/material/styles";


const TitleRoleBox = styled('div')(({ theme }) => (`
    color: ${theme.palette.text.primary};
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
`));

type Props = {
    childFieldss: Array<any>;
    onChangeRoles: (data: any) => void;
}

export const FieldsListPermissions: React.FC<Props> = ({childFieldss, onChangeRoles}) => {

    const handleChange = (name) => {
        onChangeRoles(name)
    }

    const [
        filter,  
        // setFilter
    ] = useState<string>('');

    // const handleChangeFilter = (e) => {
    //     setFilter(e.target.value)
    // }

    const filteredFields = (key) => {
        return childFieldss.filter(el => el?.name?.toLowerCase().includes(key.toLowerCase()))
    }

    return <>
        {/* <WrapSearchPanel>
            <Input 
                placeholder='Filter'
                value={filter} 
                onChange={handleChangeFilter}
                sx={{
                    margin: '0px',
                }}
            />
        </WrapSearchPanel> */}
        
        <ScrolledContainer>
            <TitleRoleBox>Roles: </TitleRoleBox>
            <OverlayScrollbarsComponent
                className='overlay-scroll-list'
                options={{
                    scrollbars: { visibility: 'visible' } 
                }}
            >
                <HasChieldRoute 
                    chahgeElement={handleChange} 
                    items={filteredFields(filter)} 
                />
            </OverlayScrollbarsComponent>
        </ScrolledContainer>
    </>
}