import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { 
	setTimezone,
	temezoneListRequest, 
	setTimezoneRequest,
} from '../../actions/timezone';
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import {
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Menu,
	Button,
	IconButton,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { useAppSelector } from "../../app/hooks";

const StyledeList = styled('div')(({ theme }) => (` 
	& {		
		@media (max-width: 767px) {
			order: 5;
			width: 100%;
		}
		& > nav{
			background: none;
			margin-right: ${theme.spacing(5)};
			@media (max-width: 767px) {
				margin-right: 0px;
			}	
			& .MuiListItem-root{
				height: 36px;
				border-radius: 4px;	
				padding-right: ${theme.spacing(1)};
				background: ${theme.palette.secondary.main};
				color: ${theme.palette.text.primary};	
				& .MuiListItemText-root{
					margin-left: ${theme.spacing(2)};
					margin-right: ${theme.spacing(5)};
					font-size: 14px;
					& > .MuiTypography-root{
						font-size: 14px;
					}
				}
			}
		}
	} 
`))

const StyledMenu = styled(Menu)(({ theme }) => (` 
	& > .MuiPaper-root{
	 	border-radius: 0;
		width: 235px;
		padding-left: 10px;
		padding-right: 10px;
		color: ${theme.palette.text.primary};
	} 
`))

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`   
	&{
	 	padding-top: 8px;
	 	padding-bottom: 8px;
		font-size: 14px;
	} 
`))

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => (`  
	width: 235px;
	& > input{
		height: 5px;	
		font-size: 14px;
	}  
	&.Mui-focused fieldset{
		border: 1px solid #38AA72 !important;
	}  
`))

const StyledButton = styled(Button)(({ theme }) => (` 
	color: #ffffff;
	height: 32px;
	padding:0 12px;
`))

function TimezoneListMenu(props) {

	const {
		temezoneListRequest,
		setTimezoneRequest, 
		// setTimezone,
		listTimezone,
		currentZone,
	} = props;

	const userId = useAppSelector(state => state.currentUser.userData.id)
	
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [disabledSend, setDisabledSend] = React.useState<boolean>(true)
	const [searchZone, setSearchZone] = React.useState('')
	const [listZone, setListZone] = React.useState(listTimezone);
  	const open = Boolean(anchorEl);

	useEffect(() => {
		temezoneListRequest()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);		
	};

	const handleMenuItemClick = ( event: React.MouseEvent<HTMLElement>, index: number, ) => {
		setSearchZone(listZone[index])
		setListZone(listTimezone)
		setDisabledSend(false)
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSearchZone('');
		setListZone(listTimezone)
		setDisabledSend(true)
	};

	function handleChangeSearch(e){
		setSearchZone(e.target.value)
		setListZone(listTimezone.filter((item) => {
			return item.toLowerCase().includes(e.target.value.toLowerCase())
		}))
	}

	const handleSubmitTimezone = () => {
		handleClose()
		setTimezoneRequest(userId ,{'timezone': searchZone})
	}
	
  	return (
		<>
			<StyledeList>
				<List
					component="nav"
					aria-label="Timezone menu"
				>
					<ListItem
						button
						id="lock-button"
						aria-haspopup="listbox"
						aria-controls="lock-menu"
						aria-label=""
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClickListItem}
					>
						<AccessTimeIcon />
						<ListItemText primary={currentZone}/>
						<ArrowDropDownIcon />
					</ListItem>
				</List>
			</StyledeList>

		<StyledMenu
			id="lock-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
			'aria-labelledby': 'lock-button',
			role: 'listbox',
			}}
		>

			<FormControl variant="outlined">
			<StyledOutlinedInput
				type={'text'}
				value={searchZone}
				onChange={(e)=>{handleChangeSearch(e)}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label=""
							onClick={()=>{}}
							onMouseDown={()=>{}}
							edge="end"
						>
						<SearchIcon />
						</IconButton>
					</InputAdornment>
				}
				label={``}
				inputProps={{
					'data-testid': "inputSearchTimezone",
				}}
			/>
			</FormControl>
				<Scrollbars style={{ height: 185, margin: '10px 0', }}>
					{(listZone || []).map((value, index) => (
						<StyledMenuItem
							key={Math.random()}
							selected={index === 0}
							onClick={(event) => handleMenuItemClick(event, index)}
						>
							{value}
						</StyledMenuItem>
					))}
				</Scrollbars>
				<FormControl>	
					<StyledButton 
						disabled={disabledSend}
						variant="contained" 
						color="success"
						onClick={handleSubmitTimezone}
						data-testid="inputSubmitTimezone"
					>
						APPLY
					</StyledButton>
				</FormControl>	

		</StyledMenu>
		</>
  );
}

export default compose(
    connect((state: RootState) => ({
        selectedTimeZone: state.timeZone.currentTimeZone,
		listTimezone: state.timeZone.listTimezone,
		currentZone: state.currentUser.userData.timezone,
    }), {
        setTimezone,
		temezoneListRequest,
		setTimezoneRequest,
    }),
)(TimezoneListMenu)