import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#F1F3F5',
      paper: '#FFFFFF',
    },
    text: {      
      primary: '#404653',
    },
    secondary: {
      main: '#E3E8EC',
    },
    action: {
      selected: '#F1F8ED'
    }
  },
})