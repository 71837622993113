export namespace ActionTypes {
    export const FETCH_TRANSACTIONS_START = 'FETCH_TRANSACTIONS_START';
    export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
    export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';
    export const FETCH_SHORT_VIEW_START = 'FETCH_SHORT_VIEW_START';
    export const FETCH_SHORT_VIEW_SUCCESS = 'FETCH_SHORT_VIEW_SUCCESS';
    export const FETCH_SHORT_VIEW_FAILURE = 'FETCH_SHORT_VIEW_FAILURE';
    export const FETCH_VIEW_START = 'FETCH_VIEW_START';
    export const FETCH_VIEW_SUCCESS = 'FETCH_VIEW_SUCCESS';
    export const FETCH_VIEW_FAILURE = 'FETCH_VIEW_FAILURE';
    export const SET_NOTIFIES_SUCCESS = 'SET_NOTIFIES_SUCCESS';
    export const SET_NOTIFIES_FAILURE = 'SET_NOTIFIES_FAILURE';
    export const UPDATE_STATUSES_SUCCESS = 'UPDATE_STATUSES_SUCCESS';
    export const UPDATE_STATUSES_FAILURE = 'UPDATE_STATUSES_FAILURE';
    export const FETCH_TRANSACTION_REQUESTS_SUCCESS = 'FETCH_TRANSACTION_REQUESTS_SUCCESS';
    export const FETCH_TRANSACTION_REQUESTS_FAILURE = 'FETCH_TRANSACTION_REQUESTS_FAILURE';
    export const GET_SERVER_TIME = 'GET_SERVER_TIME';
    export const GET_STATUSES_LIST = 'GET_STATUSES_LIST';
    export const GET_NOTIFY_STATUSES_LIST = 'GET_NOTIFY_STATUSES_LIST';
    export const GET_PAYMENT_METHODS_LIST = 'GET_PAYMENT_METHODS_LIST';
    export const GET_TRANSACTIONS_AMOUNT = 'GET_TRANSACTIONS_AMOUNT';
};

export const initialState = {
	isError: false,
    isLoading: false,
	transactions: {'hydra:member': [], 'hydra:totalItems': 0},
	errorMessage: '',
    statuses: [],
    notifiesInfo: [],
    transactionRequests: {'hydra:member': [], 'hydra:totalItems': 0},
    view: {
        id: '',
        amount: 0,
        refund: 0,
        writeOff: 0
    },
    shortView: {
        id: '',
        amount: 0,
        refund: 0,
        writeOff: 0
    },
    notifyStatusesList: [],
    statusesList: {},
    paymentMethodsList: {'hydra:member': [{
        title: '',
        code: '',
    }]},
    transactionsAmount: 1,
};

export const transactions = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_TRANSACTIONS_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_TRANSACTIONS_SUCCESS:
			return {
				...state,
				transactions: payload,
				isLoading: false,
                errorMessage: ''
			};		
		case ActionTypes.FETCH_TRANSACTIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
        case ActionTypes.FETCH_SHORT_VIEW_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_SHORT_VIEW_SUCCESS:
			return {
				...state,
				shortView: payload,
				isLoading: false,
                errorMessage: ''
			};		
		case ActionTypes.FETCH_SHORT_VIEW_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
        case ActionTypes.FETCH_VIEW_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_VIEW_SUCCESS:
			return {
				...state,
				view: payload,
				isLoading: false,
                errorMessage: ''
			};		
		case ActionTypes.FETCH_VIEW_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
        case ActionTypes.SET_NOTIFIES_SUCCESS:
            return {
                ...state,
                isError: false,
                errorMessage: '',
                notifiesInfo: payload
            };
        case ActionTypes.SET_NOTIFIES_FAILURE:
            return {
                ...state,
                isError: true,
                errorMessage: payload
            };
        case ActionTypes.UPDATE_STATUSES_SUCCESS:
            return {
                ...state,
                isError: false,
                errorMessage: '',
                statuses: payload
            };
        case ActionTypes.UPDATE_STATUSES_FAILURE:
            return {
                ...state,
                isError: true,
                errorMessage: payload
            };
        case ActionTypes.FETCH_TRANSACTION_REQUESTS_SUCCESS:
            return {
                ...state,
                isError: false,
                errorMessage: '',
                transactionRequests: payload
            };
        case ActionTypes.FETCH_TRANSACTION_REQUESTS_FAILURE:
            return {
                ...state,
                isError: true,
                errorMessage: payload
            };
        case ActionTypes.GET_SERVER_TIME:
            return {
                ...state,
                serverTime: payload
            };
        case ActionTypes.GET_STATUSES_LIST:
            return {
                ...state,
                statusesList: payload
            };
        case ActionTypes.GET_NOTIFY_STATUSES_LIST:
            return {
                ...state,
                notifyStatusesList: payload
            }
        case ActionTypes.GET_PAYMENT_METHODS_LIST:
            return {
                ...state,
                paymentMethodsList: payload
            }    
        case ActionTypes.GET_TRANSACTIONS_AMOUNT:
            return {
                ...state,
                transactionsAmount: payload,
				isLoading: false,
            }
		default:
			return state;
	}
};
