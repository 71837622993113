import {actionTypes} from '../reducers/wallets';
import API from '../services/api';

const getWallets = (data) => {
    return {
        type: actionTypes.GET_WALLETS,
        payload: data
    };
};

const setLoading = () => ({
    type: actionTypes.SET_LOADING_WALLETS
});

const createWalletSuccess = (data) => ({
    type: actionTypes.CREATE_WALLET_SUCCESS,
    payload: data
});

const createWalletError = (error) => ({
    type: actionTypes.CREATE_WALLET_ERROR,
    payload: error
});

export const getWalletsRequest = (query) => dispatch => {
    dispatch(setLoading());
    return API.wallets
    .getWallets(query)
    .then(response => dispatch(getWallets(response.data)))
    .catch(error => console.log(error))
};

export const createWalletRequest = (data) => dispatch => {
    return API.wallets
    .createWallet(data)
    .then(response => response.data.message ? dispatch(createWalletError(response.data.message instanceof Object ? JSON.stringify(response.data.message) : response.data.message)) : dispatch(createWalletSuccess(response.data)))
    .then(() => dispatch(getWalletsRequest('')))
    .catch(() => dispatch(createWalletError('Something went wrong. Please try again later')))
};
