import {ActionTypes} from '../reducers/permissionGroups';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_PEMISSION_GROUPS_START,
});

export const requestListSuccess = list => {
	return {
		type: ActionTypes.FETCH_PEMISSION_GROUPS_SUCCESS,
		payload: list
	};
};

export const requestItemSuccess = item => {
	return {
		type: ActionTypes.FETCH_ITEM_PEMISSION_GROUPS_SUCCESS,
		payload: item
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_PEMISSION_GROUPS_FAILURE,
		payload: error
	}
};

export const usersPermissionGroupsRequest = data => dispatch => {
	dispatch(requestStart());
	return API.usersPermissionGroups
		.getList(data)
		.then(response => {
			dispatch(requestListSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersPermissionItemRequest = code => dispatch => {
	dispatch(requestStart());
	return API.usersPermissionGroups
		.getItem(code)
		.then(response => {
			dispatch(requestItemSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersPermissionItemCreate = body => dispatch => {
	dispatch(requestStart());
	return API.usersPermissionGroups
		.createItem(body)
		.then(response => {
			usersPermissionGroupsRequest('')(dispatch);
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersPermissionItemDelete = code => dispatch => {
	dispatch(requestStart());
	return API.usersPermissionGroups
		.deleteItem(code)
		.then(response => {
			usersPermissionGroupsRequest('');
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersPermissionItemUpdate = (code, body) => dispatch => {
	dispatch(requestStart());
	return API.usersPermissionGroups
		.editeItem(code, body)
		.then(response => {
			usersPermissionItemRequest(body.code)(dispatch);
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};