export namespace ActionTypes {
    export const FETCH_REPEAT_QUEUE_START = 'FETCH_REPEAT_QUEUE_START';
    export const FETCH_REPEAT_QUEUE_SUCCESS = 'FETCH_REPEAT_QUEUE_SUCCESS';
    export const FETCH_REPEAT_QUEUE_FAILURE = 'FETCH_REPEAT_QUEUE_FAILURE';
};

interface Queues {
	isError: boolean,
    isLoading: boolean,
	repeatQueues: any,
	errorMessage: string,
}

export const initialState: Queues = {
	isError: false,
    isLoading: false,
	repeatQueues: '',
	errorMessage: ''
};

export const repeatQueue = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_REPEAT_QUEUE_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_REPEAT_QUEUE_SUCCESS:
			return {
				...state,
				repeatQueues: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_REPEAT_QUEUE_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
