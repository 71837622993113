export const  defaultZones = [
    "(UTC-11:00) Midway Island",
    "(UTC-11:00) Samoa",
    "(UTC-10:00) Hawaii",
    "(UTC-09:00) Alaska",
    "(UTC-08:00) Pacific Time (US &amp; Canada)",
    "(UTC-08:00) Tijuana",
    "(UTC-07:00) Arizona",
    "(UTC-07:00) Chihuahua",
    "(UTC-07:00) La Paz",
    "(UTC-07:00) Mazatlan",
    "(UTC-07:00) Mountain Time (US &amp; Canada)",
    "(UTC-06:00) Central America",
    "(UTC-06:00) Central Time (US &amp; Canada)",
    "(UTC-06:00) Guadalajara",
    "(UTC-06:00) Mexico City",
    "(UTC-06:00) Monterrey",
    "(UTC-06:00) Saskatchewan",
    "(UTC-05:00) Bogota",
    "(UTC-05:00) Eastern Time (US &amp; Canada)",
    "(UTC-05:00) Indiana (East)",
    "(UTC-05:00) Lima",
    "(UTC-05:00) Quito",
    "(UTC-04:00) Atlantic Time (Canada)",
    "(UTC-04:30) Caracas",
    "(UTC-04:00) La Paz",
    "(UTC-04:00) Santiago",
    "(UTC-03:30) Newfoundland",
    "(UTC-03:00) Brasilia",
    "(UTC-03:00) Buenos Aires",
    "(UTC-03:00) Georgetown",
    "(UTC-03:00) Greenland",
    "(UTC-02:00) Mid-Atlantic",
    "(UTC-01:00) Azores",
    "(UTC-01:00) Cape Verde Is.",
    "(UTC+00:00) Casablanca",
    "(UTC+00:00) Edinburgh",
    "(UTC+00:00) Greenwich Mean Time : Dublin",
    "(UTC+00:00) Lisbon",
    "(UTC+00:00) London",
    "(UTC+00:00) Monrovia",
    "(UTC+00:00) UTC",
    "(UTC+01:00) Amsterdam",
    "(UTC+01:00) Belgrade",
    "(UTC+01:00) Berlin",
    "(UTC+01:00) Bern",
    "(UTC+01:00) Bratislava",
    "(UTC+01:00) Brussels",
    "(UTC+01:00) Budapest",
    "(UTC+01:00) Copenhagen",
    "(UTC+01:00) Ljubljana",
    "(UTC+01:00) Madrid",
    "(UTC+01:00) Paris",
    "(UTC+01:00) Prague",
    "(UTC+01:00) Rome",
    "(UTC+01:00) Sarajevo",
    "(UTC+01:00) Skopje",
    "(UTC+01:00) Stockholm",
    "(UTC+01:00) Vienna",
    "(UTC+01:00) Warsaw",
    "(UTC+01:00) West Central Africa",
    "(UTC+01:00) Zagreb",
    "(UTC+02:00) Athens",
    "(UTC+02:00) Bucharest",
    "(UTC+02:00) Cairo",
    "(UTC+02:00) Harare",
    "(UTC+02:00) Helsinki",
    "(UTC+02:00) Istanbul",
    "(UTC+02:00) Jerusalem",
    "(UTC+02:00) Kyiv",
    "(UTC+02:00) Pretoria",
    "(UTC+02:00) Riga",
    "(UTC+02:00) Sofia",
    "(UTC+02:00) Tallinn",
    "(UTC+02:00) Vilnius",
    "(UTC+03:00) Baghdad",
    "(UTC+03:00) Kuwait",
    "(UTC+03:00) Minsk",
    "(UTC+03:00) Nairobi",
    "(UTC+03:00) Riyadh",
    "(UTC+03:00) Volgograd",
    "(UTC+03:30) Tehran",
    "(UTC+04:00) Abu Dhabi",
    "(UTC+04:00) Baku",
    "(UTC+04:00) Moscow",
    "(UTC+04:00) Muscat",
    "(UTC+04:00) St. Petersburg",
    "(UTC+04:00) Tbilisi",
    "(UTC+04:00) Yerevan",
    "(UTC+04:30) Kabul",
    "(UTC+05:00) Islamabad",
    "(UTC+05:00) Karachi",
    "(UTC+05:00) Tashkent",
    "(UTC+05:30) Chennai",
    "(UTC+05:30) Kolkata",
    "(UTC+05:30) Mumbai",
    "(UTC+05:30) New Delhi",
    "(UTC+05:30) Sri Jayawardenepura",
    "(UTC+05:45) Kathmandu",
    "(UTC+06:00) Almaty",
    "(UTC+06:00) Astana",
    "(UTC+06:00) Dhaka",
    "(UTC+06:00) Ekaterinburg",
    "(UTC+06:30) Rangoon",
    "(UTC+07:00) Bangkok",
    "(UTC+07:00) Hanoi",
    "(UTC+07:00) Jakarta",
    "(UTC+07:00) Novosibirsk",
    "(UTC+08:00) Beijing",
    "(UTC+08:00) Chongqing",
    "(UTC+08:00) Hong Kong",
    "(UTC+08:00) Krasnoyarsk",
    "(UTC+08:00) Kuala Lumpur",
    "(UTC+08:00) Perth",
    "(UTC+08:00) Singapore",
    "(UTC+08:00) Taipei",
    "(UTC+08:00) Ulaan Bataar",
    "(UTC+08:00) Urumqi",
    "(UTC+09:00) Irkutsk",
    "(UTC+09:00) Osaka",
    "(UTC+09:00) Sapporo",
    "(UTC+09:00) Seoul",
    "(UTC+09:00) Tokyo",
    "(UTC+09:30) Adelaide",
    "(UTC+09:30) Darwin",
    "(UTC+10:00) Brisbane",
    "(UTC+10:00) Canberra",
    "(UTC+10:00) Guam",
    "(UTC+10:00) Hobart",
    "(UTC+10:00) Melbourne",
    "(UTC+10:00) Port Moresby",
    "(UTC+10:00) Sydney",
    "(UTC+10:00) Yakutsk",
    "(UTC+11:00) Vladivostok",
    "(UTC+12:00) Auckland",
    "(UTC+12:00) Fiji",
    "(UTC+12:00) International Date Line West",
    "(UTC+12:00) Kamchatka",
    "(UTC+12:00) Magadan",
    "(UTC+12:00) Marshall Is.",
    "(UTC+12:00) New Caledonia",
    "(UTC+12:00) Solomon Is.",
    "(UTC+12:00) Wellington",
    "(UTC+13:00) Nuku'alofa"
]