export namespace actionTypes {
    export const GENERATE_PUBLIC_KEY = 'GENERATE_PUBLIC_KEY';
    export const GENERATE_PRIVATE_KEY = 'GENERATE_PRIVATE_KEY';
	export const CLEAR_KEYS = 'CLEAR_KEYS';
}

const initialState = {
	publicKey: '',
	privateKey: '',
};

export const merchants = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GENERATE_PUBLIC_KEY:
			return { 
				...state, 
				publicKey: payload,
			};
		case actionTypes.GENERATE_PRIVATE_KEY:
			return { 
				...state, 
				privateKey: payload,
			};
		case actionTypes.CLEAR_KEYS:
			return {
				...state,
				publicKey: '',
				privateKey: ''
			}
		default:
			return state;
	}
};

export default merchants;