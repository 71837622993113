import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const AccessTimeSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 22 22"
            onClick={onClick}
        >
            <path d="M10.991 1.8335C5.931 1.8335 1.8335 5.94016 1.8335 11.0002C1.8335 16.0602 5.931 20.1668 10.991 20.1668C16.0602 20.1668 20.1668 16.0602 20.1668 11.0002C20.1668 5.94016 16.0602 1.8335 10.991 1.8335ZM11.0002 18.3335C6.9485 18.3335 3.66683 15.0518 3.66683 11.0002C3.66683 6.9485 6.9485 3.66683 11.0002 3.66683C15.0518 3.66683 18.3335 6.9485 18.3335 11.0002C18.3335 15.0518 15.0518 18.3335 11.0002 18.3335Z" fill={fill}/>
            <path d="M11.4585 6.4165H10.0835V11.9165L14.896 14.804L15.5835 13.6765L11.4585 11.229V6.4165Z" fill={fill}/>
        </WrapperTag>
    );
};

AccessTimeSvg.defaultProps = {
    fill: 'white',
};
