import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';

const EditUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

type DataRole = {
    name: string,
    description: string,
}

type Props = {
    onClose: () => void;
    editRole?: (data: DataRole) => void;
    initValue?: DataRole;
}

export const EditUserForm = (props: Props) => {
    const {
        onClose,
        initValue,
        editRole,
    } = props;

    const [editedRole, setEditedRole] = useState<DataRole>({name: initValue.name, description: initValue.description});

    const handleChange = (prop) => (e) => {
        let value = e.target.value;
        setEditedRole(prev => ({...prev, [prop]: value}));
    }

    const handleSave = () => {
        editRole(editedRole);
        onClose();
    }

    return (
        <EditUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Editing role: QA
                </Title>
                <Input 
                    value={editedRole.description} 
                    onChange={handleChange('description')}
                    label="Description *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                />
                <Input 
                    value={editedRole.name} 
                    onChange={handleChange('name')}
                    label="Code *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    disabled={true}
                />
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleSave}
                >
                    SAVE
                </Button>
                <Button variant="outlined" onClick={onClose}>CANCEL</Button>
            </ButtonsContainer>
        </EditUserFormContainer>
    );
};
