import {ActionTypes} from '../reducers/notificationSettings';
import API from '../services/api';

export const getNotifySetting = data => {
    return {
        type: ActionTypes.GET_NOTIFY_SETTING,
        payload: data
    };
};

export const createNotifySettingSuccess = () => {
    return {
        type: ActionTypes.CREATE_NOTIFY_SETTING_SUCCESS
    }
};

export const createNotifySettingError = (error) => {
    return {
        type: ActionTypes.CREATE_NOTIFY_SETTING_ERROR,
        payload: error
    }
};

export const updateNotifySettingSuccess = () => {
    return {
        type: ActionTypes.UPDATE_NOTIFY_SETTING_SUCCESS
    }
};

export const updateNotifySettingError = (error) => {
    return {
        type: ActionTypes.UPDATE_NOTIFY_SETTING_ERROR,
        payload: error
    }
};

export const getNotifySettingRequest = (id) => dispatch => {
    return API.notificationSettings
    .getNotifySetting(id)
    .then(response => {
        dispatch(getNotifySetting(response.data))
    })
    .catch(error => console.log(error))
};

export const createNotifySettingRequest = (data) => dispatch => {
    return API.notificationSettings.createNotifySetting(data)
    .then(() => dispatch(createNotifySettingSuccess()))
    .catch((error) => dispatch(createNotifySettingError(error.message)));
};

export const updateNotifySettingRequest = (id, data) => dispatch => {
    return API.notificationSettings
    .updateNotifySetting(id, data)
    .then(() => dispatch(updateNotifySettingSuccess()))
    .catch((error) => dispatch(updateNotifySettingError(error.message)));
};

export const setInitialSetting = () => dispatch => {
    return dispatch(
        {
            type: ActionTypes.SET_INITIAL_SETTING
        }
    );
};
