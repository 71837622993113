import React, {useState} from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';

const EditUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`))

// const StyledFormControl = styled(FormControl)(({ theme }) => ({
//     marginTop: '20px',
//     width: '100%',
//     '& label.Mui-focused': {
//         color: '#38AA72',
//       },
//       '& .MuiOutlinedInput-root': {
//         '&.Mui-focused fieldset': {
//             borderColor: '#38AA72',
//         },
//     },   
// }));

// const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
//     &:hover{
//         background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
//     }
//     &.Mui-selected{
//         background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
//         &:hover{
//             background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
//         };
//         &.Mui-focusVisible{
//             background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
//         };
//     }
// `));

type GroupCoteType = {
    '@id': string;
    '@type': string;
    code: string;
    name: string;
}

export type DataPermission = {
    name: string;
    description: string;
    groupCode?: string | GroupCoteType;
}

type Props = {
    onClose: () => void;
    initValue: any;
    editPermission: (data: DataPermission) => void;
    dropdownGroup: any
}

export const EditUserForm = (props: Props) => {
    const {
        onClose,
        initValue,
        editPermission,
        // dropdownGroup,
    } = props;

    const [editedStatePermission, setEditedStatePermission] = useState<DataPermission>({
        name: initValue.name,
        description: initValue.description,
        // groupCode: initValue.groupCode?.code ? initValue.groupCode?.code : "",
    });

    const handleChange = (prop) => (e) => {
        let value = e.target.value;
        setEditedStatePermission(prev => ({...prev, [prop]: value}));
    }

    const handleSave = () => {
        editPermission(editedStatePermission);
        onClose();
    }

    return (
        <EditUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Editing permission {initValue.description}
                </Title>
                <Input 
                    value={editedStatePermission.description} 
                    onChange={handleChange('description')}
                    label="Description" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                />
                <Input 
                    value={editedStatePermission.name} 
                    onChange={handleChange('name')}
                    label="Code *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    disabled={true}
                />            
                {/* <StyledFormControl fullWidth>
                    <InputLabel size='small'>Group</InputLabel>
                    <Select
                        value={editedStatePermission.groupCode}
                        label="Group"
                        onChange={handleChange('groupCode')}
                        size="small"
                    >
                        {
                            dropdownGroup.map((item)=>{
                                return <StyledMenuItem key={Math.random()} value={item.code}>{item.name}</StyledMenuItem>
                            })
                        }
                    </Select>
                </StyledFormControl> */}
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleSave}
                >
                    SAVE
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={onClose}
                >
                    CANCEL
                </Button>
            </ButtonsContainer>
        </EditUserFormContainer>
    );
};
