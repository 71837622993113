import {ActionTypes} from '../reducers/repeatQueue';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_REPEAT_QUEUE_START
});

export const requestSuccess = (queue) => {
	return {
		type: ActionTypes.FETCH_REPEAT_QUEUE_SUCCESS,
		payload: queue
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_REPEAT_QUEUE_FAILURE,
		payload: error
	}
};

export const repeatQueuesRequest = queue => dispatch => {
	dispatch(requestStart());

	return API.repeatQueue
		.repeatQueueList(queue)
		.then(res => {
			dispatch(requestSuccess(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};
