import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {getBlockchainConfigRequest, createBlockchainConfigRequest, updateBlockchainConfigRequest, setInitialConfig} from '../../actions/blockchainConfig';
import {getNodeRequest} from "../../actions/nodeManagement";

const AddFolderFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const TimeButton = styled('div')({
    color: '#38AA72',
    fontSize: '12px',
    fontWeight: '500',
    cursor: 'pointer',
    marginRight: '20px',
    marginTop: '12px',
    border: '1px solid #38AA72',
    borderRadius: '4px',
    padding: '0px 4px'
});

const TimeButtons = styled('div')({
    display: 'flex',
    marginTop: '6px',
    marginBottom: '4px'
});

const BlockchainConfigForm = ({onClose, getBlockchainConfigRequest, createBlockchainConfigRequest, updateBlockchainConfigRequest, setInitialConfig, node, getNodeRequest, blockchainConfig, handleOpenSnackbar}: any) => {
    const id = node.blockchainConfig?.split('/').pop();
    
    const [boundarySumMerch, setBoundarySumMerch] = useState(blockchainConfig.boundarySumMerch);
    const handleSetBoundarySumMerch = (event) => {
        setBoundarySumMerch(event.target.value)
    };

    const [boundarySumUser, setBoundarySumUser] = useState(blockchainConfig.boundarySumUser);
    const handleSetBoundarySumUser = (event) => {
        setBoundarySumUser(event.target.value)
    };

    const [boundaryTrxNumMerch, setBoundaryTrxNumMerch] = useState(blockchainConfig.boundaryTrxNumMerch);
    const handleSetBoundaryTrxNumMerch = (event) => {
        setBoundaryTrxNumMerch(event.target.value)
    };

    const [boundaryTrxNumUser, setBoundaryTrxNumUser] = useState(blockchainConfig.boundaryTrxNumUser);
    const handleSetBoundaryTrxNumUser = (event) => {
        setBoundaryTrxNumUser(event.target.value)
    };

    const [unconfMaxTime, setUnconfMaxTime] = useState(blockchainConfig.unconfMaxTime);
    const handleSetUnconfMaxTime = (event) => {
        setUnconfMaxTime(event.target.value)
    };

    const handleSetUnconMaxTimeByButton = (time) => {
        setUnconfMaxTime(time);
    };

    useEffect(() => {
        if (id) {
            getBlockchainConfigRequest(id);
        } else {
            setInitialConfig();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setBoundarySumMerch(blockchainConfig.boundarySumMerch);
        setBoundarySumUser(blockchainConfig.boundarySumUser);
        setBoundaryTrxNumMerch(blockchainConfig.boundaryTrxNumMerch);
        setBoundaryTrxNumUser(blockchainConfig.boundaryTrxNumUser);
        setUnconfMaxTime(blockchainConfig.unconfMaxTime);
    }, [blockchainConfig]);

    const handleSubmit = () => {
        const data = {
            node: `api/nodes/${node.id}`,
            boundarySumMerch: String(boundarySumMerch),
            boundarySumUser: String(boundarySumUser),
            boundaryTrxNumMerch: Number(boundaryTrxNumMerch),
            boundaryTrxNumUser: Number(boundaryTrxNumUser),
            unconfMaxTime: Number(unconfMaxTime)
        };

        id ? updateBlockchainConfigRequest(id, JSON.stringify(data)).then(() => handleOpenSnackbar('Blockchain config was updated', 'Blockchain config not updated'))
        : createBlockchainConfigRequest(JSON.stringify(data)).then(() => handleOpenSnackbar('Blockchain config was created', 'Blockchain config not created'));

        if (!id) {
            setTimeout(() => getNodeRequest(node.id), 1000);
        };

        onClose();
    };

    const isFormValid = () => {
        return id ? 
            ((boundarySumMerch !== blockchainConfig.boundarySumMerch) && boundarySumMerch) ||
            ((boundarySumUser !== blockchainConfig.boundarySumUser) && boundarySumUser) ||
            ((String(boundaryTrxNumMerch) !== String(blockchainConfig.boundaryTrxNumMerch)) && boundaryTrxNumMerch) ||
            ((String(boundaryTrxNumUser) !== String(blockchainConfig.boundaryTrxNumUser)) && boundaryTrxNumUser) ||
            (Number(unconfMaxTime) !== Number(blockchainConfig.unconfMaxTime) && unconfMaxTime)
            : (boundarySumMerch || boundarySumUser || boundaryTrxNumMerch || boundaryTrxNumUser || unconfMaxTime);
    };

    return (
        <AddFolderFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>Blockchain</Title>
                <Input label="Boundary sum for merchant, BTC *" sx={{marginTop: '20px'}} onChange={handleSetBoundarySumMerch} value={boundarySumMerch} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 0.01 }} type="number" placeholder="1,25"></Input>
                <Input label="Boundary sum for user, BTC *" sx={{marginTop: '20px'}} onChange={handleSetBoundarySumUser} value={boundarySumUser} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 0.01 }} type="number" placeholder="1,25"></Input>
                <Input label="Boundary number of transactions for merchant *" sx={{marginTop: '20px'}} onChange={handleSetBoundaryTrxNumMerch} value={boundaryTrxNumMerch} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} type="number" placeholder="10"></Input>
                <Input label="Boundary number of transactions for user *" sx={{marginTop: '20px'}} onChange={handleSetBoundaryTrxNumUser} value={boundaryTrxNumUser} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} type="number" placeholder="10"></Input>
                <TimeButtons>
                    <TimeButton onClick={() => handleSetUnconMaxTimeByButton(120)}>2 hours</TimeButton>
                    <TimeButton onClick={() => handleSetUnconMaxTimeByButton(360)}>6 hours</TimeButton>
                    <TimeButton onClick={() => handleSetUnconMaxTimeByButton(720)}>12 hours</TimeButton>
                    <TimeButton onClick={() => handleSetUnconMaxTimeByButton(1440)}>1 day</TimeButton>
                    <TimeButton onClick={() => handleSetUnconMaxTimeByButton(2880)}>2 days</TimeButton>
                    <TimeButton onClick={() => handleSetUnconMaxTimeByButton(5760)}>4 days</TimeButton>
                </TimeButtons>
                <Input label="Max time for unconfirmed transactions (minutes) *" onChange={handleSetUnconfMaxTime} value={unconfMaxTime} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 5 }} type="number" placeholder="60"></Input>
            </WrapFields>
            <ButtonsContainer>
                <Button sx={{marginRight: '12px'}} onClick={handleSubmit} disabled={!isFormValid()}>{id ? "Update" : "Create"}</Button>
                <Button variant="outlined" onClick={onClose}>CANCEL</Button>
            </ButtonsContainer>
        </AddFolderFormContainer>
    )
};

export default compose(
    connect((state: RootState | any) => ({
        node: state.nodeManagement.node,
        blockchainConfig: state.blockchainConfig.blockchainConfig
    }), {getBlockchainConfigRequest, createBlockchainConfigRequest, updateBlockchainConfigRequest, setInitialConfig, getNodeRequest})
)(BlockchainConfigForm);