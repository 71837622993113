export namespace ActionTypes {
    export const FETCH_CURRENCY_START = 'FETCH_CURRENCY_START';
    export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS';
    export const FETCH_CURRENCY_FAILURE = 'FETCH_CURRENCY_FAILURE';
};

export type CurrencyItem = {
	id: string,
	name: string,
}

interface CurrencyList {
	isError: boolean,
    isLoading: boolean,
	currencyList: Array<CurrencyItem>,
	errorMessage: string,
}

export const initialState: CurrencyList = {
	isError: false,
    isLoading: false,
	currencyList: [
		{
			id: '',
			name: '',
		}
	],
	errorMessage: ''
};

export const currencyList = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_CURRENCY_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_CURRENCY_SUCCESS:
			return {
				...state,
				currencyList: payload,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};
		case ActionTypes.FETCH_CURRENCY_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
