import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { NavLink } from 'react-router-dom';

import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TimelineIcon from '@mui/icons-material/Timeline';
import LayersIcon from '@mui/icons-material/Layers';
import InfoIcon from '@mui/icons-material/Info';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import LanguageIcon from '@mui/icons-material/Language';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApiIcon from '@mui/icons-material/Api';

// import styled from '@emotion/styled';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../app/hooks';

interface SidebarProps{
	sidebarCollapse: boolean,
	mobileMenuOpen?: boolean,
	setSidebarCollapse?: any,
	setMobileMenuOpen?: any
}

const WrapFlexCntainer = styled('div')(({ theme }) => (` 
	box-sizing: border-box;
	width: 210px;
	background: ${theme.palette.background.default};
	height: calc(100vh - 64px);
	transition: all 50ms;
	padding-top: 8px;	
	padding-bottom: 40px;
	position: relative;
	z-index: 1001;
	@media (max-width: 767px) {
		display: none;
		position: fixed;
		left:0;
		top:0;
		bottom:0;
		right:0;
		height: auto;
		width: 100%;
		background: none;
		&.collapsedMobileSidebar{
			display:block;
		}
		& > div{
			width: 210px !important;
			background: ${theme.palette.background.default};
		}
		&:before{
			content:"";
			position: absolute;
			left:210px;
			top:0;
			right:0;
			bottom:0;
			background: rgba(0,0,0, .2);
			z-index: 0;
		}
	}	
	& .track-horizontal,
	& .track-vertical{
		width: 0;
		right: 0;
		height: 0%;
		background: transparent;
		& > div{
			background-color: transparent;
		}
	}
	& .mainnLink{
		transition: all 50ms;
		overflow: hidden;
		width: calc(100% - 50px);
		word-break: keep-all;
		height: 19px;
		color: ${theme.palette.text.primary};
		&.bigLinl{
			height: 38px;
		}
	}
	& .MuiAccordionSummary-root{
		& .MuiAccordionSummary-content{
			& > div{
				width: 100%;
				position: relative;
				& > div{
					color: ${theme.palette.text.primary};
					width: calc(100% - 30px);
					transition: all 100ms;
					overflow: hidden;
					position: relative;
					height: 19px;
					word-break: keep-all;
				}
			}
		}
	}
	&.collapsedSidebar{
		width: 52px;		
		& .mainnLink{
			width: 0;
		}
		& .MuiAccordionSummary-root{		
			& .MuiAccordionSummary-content{
				& > div > div{
					width: 0;
				}
			}
			& .MuiAccordionSummary-expandIconWrapper{
				display: none;
			}
		}
		& .MuiCollapse-root{
			display: none;
		}
	}
`))

const StyledAccordion = styled(Accordion)(({ theme }) => (`
  margin: 0 0 0 0 !important;
	box-shadow: none;
	border-radius: 0;
	background: none;
	transition: background 222ms;
	&:hover{
		background: rgba(0,0,0, .05);
	}
	&::before{
		display: none;
	}
	& > .MuiAccordionSummary-root{
		padding: 0 0 0 16px;
		min-height: 46px !important;
		width: 200px;
		& > .MuiAccordionSummary-content{
			margin: 0;			
				& > div{
					&:hover{
						background: none !important;
					}
				}			
		}
		& > .MuiAccordionSummary-expandIconWrapper{
			margin-right: 16px;
			svg{
				font-size: 20px;
				transform-origin: center center !important;
			}			
		}
	}
	& > .MuiCollapse-root{
		& .MuiAccordionDetails-root{
			padding: 0 0 0 0;
		}
	}
`))

const MainLinkStyled = styled('div')(({ theme }) => (`
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	height: 46px;		
	transition: background 222ms;
	cursor: pointer;
	&:hover{
		// background: rgba(0,0,0, .05);
	}
	& > svg{
		font-size: 18px;
		color: ${theme.palette.text.primary};
	}
	a{
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 46px;		
		transition: background 222ms;
		cursor: pointer;	
		text-decoration: none;
		color: ${theme.palette.text.primary};
		padding-left: 16px;
		& > svg{
			font-size: 18px;
		}
		&:hover{
			background: rgba(0,0,0, .05); /*404653*/
			background: ${ theme.palette.mode === 'light' ? 'rgba(0,0,0, .05)' : '#404653' };
		}
		&.active{
			position: relative;
			background: ${ theme.palette.mode === 'light' ? 'rgba(0,0,0, .05)' : '#404653' };
			&:after{
				content:"";
				position: absolute;
				right:0;
				top:0;
				bottom:0;
				width: 4px;
				background: #38AA72;
			}
		}	
	}
`))

const MainLinkBigStyled = styled(MainLinkStyled)(({ theme }) => (`
	height: 68px;	
	a{
		height: 68px;
		svg{
			// border: 1px solid red;
			position: relative;
		}
	}
`))

const StyledTitle = styled('div')(({ theme }) => (`
	color: ${theme.palette.text.primary};	
	font-size: 14px;
	padding-left: 8px;
`))

const StyledSubTitle = styled('div')(({ theme }) => (`
	color: ${theme.palette.text.primary};
	font-size: 14px;
	padding-left: 26px;
	padding-right: 10px;
`))

const FooterSidebar = styled('div')(({ theme }) => (`
	height: 40px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	& > span{
		font-size: 13px;
		color: #979799;
		transform: translate(0, 0);
		transition: all 100ms;
		&.collapsedSidebar{
			poaition: absolute;
			transform: translate(-100px, 0);
		}
	}
	& > svg{
		width: 16px;
		transform: rotate(180deg);
		transition: all 222ms;
		color: #38AA72;
		cursor: pointer;
		position: absolute;
		right: 15px;
		&.collapsedSidebar{
			transform: rotate(0deg);
		}
	}
`))

const FooterSidebarMobile = styled('div')(({ theme }) => (`
	& {
		height: 40px;
		position: absolute;
		width: 210px;
		right: auto;
		left: 0;
		bottom: 0;
		padding-left: 0px;
		padding-right: 0px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-items: center;
	}	
	& > svg{
		width: 16px;
		transform: rotate(180deg);
		transition: all 222ms;
		color: #38AA72;
		cursor: pointer;
		position: absolute;
		right: 15px;
	}
`))

const SidebarBG = styled('div')(({ theme }) => (`
	position: fixed;
	left: 210px;
	right: 0;
	top: 0;
	bottom: 0;
	backdrop-filter: blur(5px);
	z-index: 30;
`))

export const Sidebar: React.FC<SidebarProps> = ({
		sidebarCollapse,
		mobileMenuOpen,
		setSidebarCollapse,
		setMobileMenuOpen
	}) => {

	const useUsernameTmpRouts = useAppSelector(state => state.currentUser.userData.username);
	const activeNodeType = useAppSelector(state => state.nodeManagement.activeNode.type);
	const useUserRoleTmpRouts = useAppSelector(state => state.currentUser.userData.roles);
	const currentRouts = useAppSelector(state => state.currentUser.userData.routs);
	const isSuperadmin = useAppSelector(state => state.currentUser.userData.superadmin);
	const accordionCollapsedOpen = (): void => { if(sidebarCollapse) setSidebarCollapse() }
	const [expanded, setExpanded] = useState<string | false>(false);
  	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    	setExpanded(newExpanded ? panel : false);
  	};

	const mobileMenuCollapsedOpen = (): void => { setMobileMenuOpen() }
	
	return (
		<WrapFlexCntainer
			className={`
				${sidebarCollapse ? "collapsedSidebar" : ""}
				${mobileMenuOpen ? "collapsedMobileSidebar" : ""}
			`}
		>
			<Scrollbars
				renderTrackVertical={props => <div {...props} className="track-vertical"/>}
				renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
			>
				{/* Dashboard */}
				<MainLinkStyled>
					<NavLink
						to='/dashboard'
					>
						<DashboardIcon />
						<StyledTitle 
							className='mainnLink'
							data-testid="titleLinkNavigationDashboard"
						>
							Dashboard
						</StyledTitle>
					</NavLink>
				</MainLinkStyled>

				{/* Node mahagement */}
				{ (currentRouts.some(rout => rout.child.includes('/node/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) && 
					<MainLinkStyled>
						<NavLink
							to='/node-management'
						>
							<BuildIcon />
							<StyledTitle className='mainnLink'>Node management</StyledTitle>
						</NavLink>
					</MainLinkStyled>
				}

				{/* Users management*/}
				{ (
					currentRouts.some(rout => rout.child.includes('/user-management/auth-item-group/')) 
					|| currentRouts.some(rout => rout.child.includes('/user-management/permission/')) 
					|| currentRouts.some(rout => rout.child.includes('/user-management/role/')) 
					|| currentRouts.some(rout => rout.child.includes('/user-management/user-action-log/')) 
					|| currentRouts.some(rout => rout.child.includes('/user-management/user-permission/')) 
					|| currentRouts.some(rout => rout.child.includes('/user-management/user-visit-log/')) 
					|| currentRouts.some(rout => rout.child.includes('/user-management/user/')) 
					|| currentRouts.some(rout => rout.child.includes('/front-user/')) 
					|| useUserRoleTmpRouts.some(role => role.name === 'Admin') 
					|| !!isSuperadmin
				) && 
					<StyledAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							onClick={() => {accordionCollapsedOpen()}}
							>
							<MainLinkStyled>
								<GroupIcon />
								<StyledTitle>Users</StyledTitle>
							</MainLinkStyled>
						</AccordionSummary>
						<AccordionDetails>
							{ (currentRouts.some(rout => rout.child === '/front-user/index') || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/on-selected-node'
									>
										<StyledSubTitle>On selected node</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child === '/front-user/on-branch') || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/on-branch'
									>
										<StyledSubTitle>On branch</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child.includes('/user-management/user/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/all-users'
									>
										<StyledSubTitle>Users</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child.includes('/user-management/role/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/roles'
									>
										<StyledSubTitle>Roles</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child.includes('/user-management/permission/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/permissions'
									>
										<StyledSubTitle>Permissions</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child.includes('/user-management/auth-item-group/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/permission-groups'
									>
										<StyledSubTitle>Permission groups</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>	
							}
							{ (currentRouts.some(rout => rout.child.includes('/user-management/user-visit-log/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/visit-log'
									>
										<StyledSubTitle>Visit log</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>	
							}
							{ (currentRouts.some(rout => rout.child.includes('/user-management/user-action-log/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/users/action-log'
									>
										<StyledSubTitle>Action log</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>	
							}
						</AccordionDetails>
					</StyledAccordion>
				}
				
				{/* Wallet */}
				{ ((currentRouts.some(rout => rout.child.includes('/wallet-service/')) && activeNodeType === 1) || (useUserRoleTmpRouts.some(role => role.name === 'Admin') && activeNodeType === 1) || (!!isSuperadmin && activeNodeType === 1)) && 
					<MainLinkStyled>
						<NavLink
							to='/wallet'
						>
							<AccountBalanceWalletIcon />
							<StyledTitle className='mainnLink'>Wallets</StyledTitle>
						</NavLink>
					</MainLinkStyled>
				}

				{/* User Payment Systems */}
				{ (currentRouts.some(rout => rout.child.includes('/user-payment-system/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) && 
					<MainLinkStyled>
						<NavLink
							to='/payment-systems'
						>
							<CreditCardIcon />
							<StyledTitle className='mainnLink'>Payment systems</StyledTitle>
						</NavLink>
					</MainLinkStyled>
				}
				
				{/* Payment Systems */}
				{ (currentRouts.some(rout => rout.child.includes('/payment-system/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) && 
					<MainLinkBigStyled>
						<NavLink
							to='/payment-systems-global'
						>
							<LanguageIcon />
							<StyledTitle className='mainnLink bigLinl'>Global payment systems</StyledTitle>
						</NavLink>
					</MainLinkBigStyled>
				}

				{/* Mapping/Cascads */}
				{ (useUserRoleTmpRouts.some(role => role.name === 'wallets01' || 'Client') || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
					<>
						{(useUsernameTmpRouts === 'camelot' || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
							<StyledAccordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
								<AccordionSummary
									data-testid="sectionSudebarMapping"
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel10a-content"
									id="panel10a-header"
									onClick={() => {accordionCollapsedOpen()}}
									>
									<MainLinkStyled>
										<ContentCopyIcon />
										<StyledTitle>Mapping</StyledTitle>
									</MainLinkStyled>
								</AccordionSummary>
								<AccordionDetails>
									<MainLinkStyled>
										<NavLink
											to='/mapping/multiaccounts'
										>
											<StyledSubTitle>Multiaccounts</StyledSubTitle>
										</NavLink>
									</MainLinkStyled>
								</AccordionDetails>
							</StyledAccordion>
						}
					</>
				}

				{/* Transactions */}
				{ (currentRouts.some(rout => rout.child.includes('/transaction/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
					<>
						{!(useUsernameTmpRouts === 'camelot') &&
							<StyledAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
								<AccordionSummary
									data-testid="sectionSudebarTransactions"
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
									onClick={() => {accordionCollapsedOpen()}}
									>
									<MainLinkStyled>
										<MonetizationOnIcon />
										<StyledTitle
											data-testid="titleHeaderNavigationTransactions"
										>
											Transactions
										</StyledTitle>
									</MainLinkStyled>
								</AccordionSummary>
								<AccordionDetails>
									{ (currentRouts.some(rout => rout.child === '/transaction/deposit') || useUserRoleTmpRouts.some(role => role.name === 'Admin') || currentRouts.some(rout => rout.child.includes('/transaction/view')) || !!isSuperadmin) &&
										<MainLinkStyled>
											<NavLink
												to='/transactions/deposit'
												data-testid="titleLinkNavigationTransactionsDeposit"
											>
												<StyledSubTitle>Deposit</StyledSubTitle>
											</NavLink>
										</MainLinkStyled>
									}
									{ (currentRouts.some(rout => rout.child === '/transaction/withdraw') || useUserRoleTmpRouts.some(role => role.name === 'Admin') || currentRouts.some(rout => rout.child.includes('/transaction/view')) || !!isSuperadmin) &&
										<MainLinkStyled>
											<NavLink
												to='/transactions/withdraw'
												data-testid="titleLinkNavigationTransactionsWithdraw"
											>
												<StyledSubTitle>Withdraw</StyledSubTitle>
											</NavLink>
										</MainLinkStyled>
									}
								</AccordionDetails>
							</StyledAccordion>
						}
					</>
				}

				{/* Statements */}
				{ (currentRouts.some(rout => rout.child.includes('/statement/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
					<MainLinkStyled>
						<NavLink
							to='/statements'
						>
							<AssignmentIcon />
							<StyledTitle className='mainnLink'>Statements</StyledTitle>
						</NavLink>
					</MainLinkStyled>
				}
				
				{/* Settings */}
				{ (currentRouts.some(rout => rout.child.includes('/settings/'))  || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) && 
					<StyledAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel4a-content"
							id="panel4a-header"
							onClick={() => {accordionCollapsedOpen()}}
							>
							<MainLinkStyled>
								<SettingsIcon />
								<StyledTitle>Settings</StyledTitle>
							</MainLinkStyled>
						</AccordionSummary>
						<AccordionDetails>
							{currentRouts.some(rout => rout.child.includes('/telegram/')) &&
								<MainLinkStyled>
									<NavLink
										to='/settings/telegram'
									>
										<StyledSubTitle>Telegram</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{currentRouts.some(rout => rout.child.includes('/settings/')) && 
								<MainLinkStyled>
									<NavLink
										to='/settings/pps-settings'
									>
										<StyledSubTitle>PPS Settings</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
						</AccordionDetails>
					</StyledAccordion>
				}

				{/* Requests/Elastic */}
				{ (currentRouts.some(rout => rout.child.includes('/requests/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) && 
					<StyledAccordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel6a-content"
							id="panel6a-header"
							onClick={() => {accordionCollapsedOpen()}}
							>
							<MainLinkStyled>
								<ImportExportIcon />
								<StyledTitle>Requests</StyledTitle>
							</MainLinkStyled>
						</AccordionSummary>
						<AccordionDetails>
							<MainLinkStyled>
								<NavLink
									to='/request/elastic-search'
								>
									<StyledSubTitle>Elastic</StyledSubTitle>
								</NavLink>
							</MainLinkStyled>
						</AccordionDetails>
					</StyledAccordion>
				}

				{/* Reports */}
				{ (currentRouts.some(rout => rout.child.includes('/report/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
					<StyledAccordion expanded={expanded === 'panelReports'} onChange={handleChange('panelReports')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panelReports-content"
							id="panelReports-header"
							onClick={() => {accordionCollapsedOpen()}}
							>
							<MainLinkStyled>
								<TimelineIcon />
								<StyledTitle
									data-testid="titleHeaderkNavigationReports"
								>
									Reports
								</StyledTitle>
							</MainLinkStyled>
						</AccordionSummary>
						<AccordionDetails>
							<MainLinkStyled>
								<NavLink
									to='/reports/report'
									data-testid="titleLinkkNavigationReport"
								>
									<StyledSubTitle>Report</StyledSubTitle>
								</NavLink>
							</MainLinkStyled>
						</AccordionDetails>
					</StyledAccordion>
				}

				{/* Test-Api */}
				{ (currentRouts.some(rout => rout.child.includes('/test-api/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) && 
					<MainLinkStyled>
						<NavLink
							to='/test-api'
						>
							<LayersIcon />
							<StyledTitle className='mainnLink'>Test API</StyledTitle>
						</NavLink>
					</MainLinkStyled>
				}

				{/* System-Info */}
				{ (
					currentRouts.some(rout => rout.child.includes('/buyer-log/'))
					|| currentRouts.some(rout => rout.child.includes('/temporary-deposit-link/'))
					|| currentRouts.some(rout => rout.child.includes('/user-address/'))
					|| currentRouts.some(rout => rout.child.includes('/hdbk-requests-type/'))
					|| useUserRoleTmpRouts.some(role => role.name === 'Admin') 
					|| !!isSuperadmin
				) && 
					<StyledAccordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel4a-content"
							id="panel4a-header"
							onClick={() => {accordionCollapsedOpen()}}
							>
							<MainLinkStyled>
								<InfoIcon />
								<StyledTitle>System Info</StyledTitle>
							</MainLinkStyled>
						</AccordionSummary>
						<AccordionDetails>
							{ (currentRouts.some(rout => rout.child.includes('/buyer-log/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/system-info/buyer-logs'
									>
										<StyledSubTitle>Buyer logs</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child.includes('/temporary-deposit-link/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/system-info/temporary-deposit-links'
									>
										<StyledSubTitle>Temporary Deposit Links</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child.includes('/user-address/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/system-info/user-addresses'
									>
										<StyledSubTitle>User Addresses</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{ (currentRouts.some(rout => rout.child.includes('/hdbk-requests-type/')) || useUserRoleTmpRouts.some(role => role.name === 'Admin') || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/system-info/hdbk-requests-types'
									>
										<StyledSubTitle>Hdbk Requests Types</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
						</AccordionDetails>
					</StyledAccordion>
				}

				{/* Dev-Junk */}
				{ (
					currentRouts.some(rout => rout.child.includes('/site/log'))
					|| currentRouts.some(rout => rout.child.includes('/migrations/'))
					|| currentRouts.some(rout => rout.child.includes('/site/test-redis')) // queues
					|| currentRouts.some(rout => rout.child.includes('/history-transaction-status/'))
					|| currentRouts.some(rout => rout.child.includes('/site/doc-build'))
					|| currentRouts.some(rout => rout.child.includes('/site/requests-cleaner')) // requests
					|| currentRouts.some(rout => rout.child.includes('/site/repeat-queue-process'))
					|| useUserRoleTmpRouts.some(role => role.name === 'Admin') 
					|| !!isSuperadmin
				) && 
					<StyledAccordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel4a-content"
							id="panel4a-header"
							onClick={() => {accordionCollapsedOpen()}}
						>
							<MainLinkStyled>
								<BatchPredictionIcon />
								<StyledTitle>Dev junk</StyledTitle>
							</MainLinkStyled>
						</AccordionSummary>
						<AccordionDetails>
							{(currentRouts.some(rout => rout.child.includes('/site/log')) || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/dev-junk/log-viewer'
									>
										<StyledSubTitle>Log Viewer</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{(currentRouts.some(rout => rout.child.includes('/migrations/')) || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/dev-junk/migrations'
									>
										<StyledSubTitle>Migrations</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{(currentRouts.some(rout => rout.child.includes('/site/test-redis')) || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/dev-junk/queues'
									>
										<StyledSubTitle>Queues</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{(currentRouts.some(rout => rout.child.includes('/history-transaction-status/')) || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/dev-junk/history-transactions-status'
									>
										<StyledSubTitle>History of transactions statuses</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{(currentRouts.some(rout => rout.child.includes('/site/doc-build')) || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/dev-junk/build-api-docs'
									>
										<StyledSubTitle>Build API Doc</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{(currentRouts.some(rout => rout.child.includes('/site/requests-cleaner')) || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/dev-junk/requests-cleaner'
									>
										<StyledSubTitle>Requests</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
							{(currentRouts.some(rout => rout.child.includes('/site/repeat-queue-process')) || currentRouts.some(rout => rout.child.includes('/telegram/')) || !!isSuperadmin) &&
								<MainLinkStyled>
									<NavLink
										to='/dev-junk/repeat-queue'
									>
										<StyledSubTitle>Repeat queue</StyledSubTitle>
									</NavLink>
								</MainLinkStyled>
							}
						</AccordionDetails>
					</StyledAccordion>
				}

				{/* API Info */} {/* IntCat */}
				{ ((useUserRoleTmpRouts.some(role => role.name === 'IntCat')) || (useUserRoleTmpRouts.some(role => role.name === 'Admin')) || !!isSuperadmin) &&
					<MainLinkStyled>
						<NavLink
							to='/api-info'
						>
							<ApiIcon />
							<StyledTitle className='mainnLink'>Integrations Catalog</StyledTitle>
						</NavLink>
					</MainLinkStyled>
				}
			</Scrollbars>

			{window.screen.width > 767
				?	<FooterSidebar>
						<span className={sidebarCollapse ? "collapsedSidebar" : ""}>Version 1.1.0</span>
						<DoubleArrowIcon
							onClick={() => {
								setSidebarCollapse()
								setExpanded(false)
							}}
							className={sidebarCollapse ? "collapsedSidebar" : ""}
						/>
					</FooterSidebar>
				: <FooterSidebarMobile>
					<DoubleArrowIcon
						onClick={() => {
							setExpanded(false)
							mobileMenuCollapsedOpen()
						}}
					/>
					<SidebarBG 
						onClick={() => {
							setExpanded(false)
							mobileMenuCollapsedOpen()
						}}
					/>
				</FooterSidebarMobile>
			}

		</WrapFlexCntainer>
	);
};
