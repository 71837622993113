import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/logstashes';

const LogViewer = {
	getLogs: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';

		return baseApi.get(`${path}${query}`)
	},
    // ...
}

export default LogViewer;