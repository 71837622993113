import React from "react";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Loader } from '../../components/loaders/loader';
import { 
    hdbkRequest,
    hdbkCreate,
} from "../../actions/systenInfoHdbkRequestTypes";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {SerachTableHeader} from '../../components/search-table-header';
import AddIcon from '@mui/icons-material/Add';
import {Button} from '../../components/button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {CreateHDBKTypeForm} from './form-create-hdbk-request-type';
import SvgIcon from '@mui/material/SvgIcon';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import {format} from 'date-fns';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Tooltip from '@mui/material/Tooltip';
import { hdbkListItem } from "../../reducers/hdbkRequestType";
import { AccessDeniedAlert } from "../../components/access-denied";

const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '15px',
    }
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',
    height: '56.7px',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const WithSortedSerachTableHeaderFirst = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 200px;
    box-sizing: border-box;
`));

const ActionIcon = styled('div')(({ theme }) => (`
    cursor: pointer;
    &:hover{
      svg{
        fill: #38AA72 !important;
      }
    }    
`));

const DepositMainSecondRowLeftButtons = styled('div')({
    '@media(max-width: 768px)' : {
        '& button': {
            boxSizing: 'border-box',
            fontSize: '12px',
            height: '30px',
            padding: '0 12px',
            marginRight: '11px',
            '&:last-child':{
                marginRight: '0px',
            }
        }
    }
});

const DepositMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',

    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap'
    }
});

const DepositMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {    
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100vh;
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const TableCellContent = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
    widt: 25%;
`);

const WithSortedTableCellContent = styled(TableCellContent)(({ theme }) => (`
    position: relative;
    // padding-right: 30px;
    min-width: 120px;
    widt: 25%;
    box-sizing: border-box;
    justify-content: flex-start;
`));

const SortButtons = styled('div')(({ theme }) => (`
    position: absolute;
    right: 0;
    top: -10px;
`));

const SortButtonsSongleToLeft = styled(SortButtons)(({ theme }) => (`
    position: absolute;
    right: 0;
    top: -10px
`));

const SortButtonTop = styled('div')(({ theme }) => (`
    & svg{ 
        cursor: pointer;
        bottom: -7px;
        position: relative;
        width: 12px;
        height: 12px;
    }
`));

const SortButtonBottom = styled('div')(({ theme }) => (`
    & svg{ 
        cursor: pointer;
        position: relative;
        top: -5px;
        width: 12px;
        height: 12px;
    }
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 255px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 240px - 56px);
        }; 
    }
`));

type dataRequest = {
    id?: number | string,
    page: number,
    text?: string,
    'order[createdAt]'?: string,
}

interface PropsType {
    isLoading: boolean,
    hdbkList: Array<hdbkListItem>,
    hdbkPageCount: number,
    error: boolean,
    errorMessage: string,
    hdbkRequest: (data: dataRequest) => void,
    hdbkCreate: (data: {id: number, text: string}) => any,
    setView: any,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

type StateType = {
    openWindowCreate: boolean,
    page: number,
    rowsPerPage: number,
    id: number | string,
    description: string,
    sorting: 'asc' | 'desc',
    openSnackbar: boolean,
}

export class SystemInfoAdressesList extends React.Component<PropsType, StateType> {
    constructor(props) {
        super(props);

        this.state = {
            openWindowCreate: false,
            page: 0,
            rowsPerPage: 20,
            id: '',
            description: '',
            sorting: 'asc',
            openSnackbar: false
        }
    };

    handleClickSortTopUpdate = () => {
        this.setState({sorting: 'asc'}, () => this.updateData())
    };

    handleClickSortBottomUpdate = () => {
      this.setState({sorting: 'desc'}, () => this.updateData())
    };

    handleOpenWindowCreate = () => {
        this.setState({openWindowCreate: true});
    }

    handleCloseWindowCreate = () => {
        this.setState({openWindowCreate: false});
    }

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleOpenSnackbar = () => {
        this.setState({openSnackbar: true});
    };

    handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {return}
        this.setState({openSnackbar: false});
    };

    handleChangeHdbk = (id) => {
        const {setView} = this.props;
        setView(prev => ({id, point: 'view'}))
    }

    handleCreate = (value) => {
        const {hdbkCreate} = this.props

        const data = {          
            'id': +value.id,
            'text': value.description,
        }
        this.handleCloseWindowCreate()
        hdbkCreate(data).then(() => this.handleOpenSnackbar())
    }

    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;
        
        if(routsUser.some(rout => rout.child.includes('/hdbk-requests-type/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    updateData = () => {

        const {
            id,
            page,
            description,
            sorting,
        } = this.state;

        const {
            hdbkRequest,
        } = this.props;

        const data = {
            'id': id,
            'page': page + 1,
            'text': description,
            'order[createdAt]': sorting,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });
        hdbkRequest(data);
    }

    render() {

        const {
            isLoading,
            hdbkList,
            hdbkPageCount,
            error,
            errorMessage,
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props

        const {
            id,
            page,
            rowsPerPage,
            openWindowCreate,
            openSnackbar,
            description,
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child.includes('/hdbk-requests-type/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleCreate = routsUser.some(rout => rout.child === '/hdbk-requests-type/create') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}
                    
                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>Hdbk Requests Types</DepositMainHeaderText>
                            </DepositMainHeader>

                            <DepositMainSecondRow>
                                <DepositMainSecondRowRightBlock>
                                    <DepositMainSecondRowLeftButtons>
                                        {accessedRoleCreate &&
                                            <Button sx={{marginRight: '16px'}} 
                                                onClick={this.handleOpenWindowCreate}
                                            >
                                                <AddIcon sx={{marginRight: '8px'}}/>
                                                Create Hdbk Requests Type
                                            </Button>
                                        }
                                    </DepositMainSecondRowLeftButtons>
                                </DepositMainSecondRowRightBlock>
                            </DepositMainSecondRow>

                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >

                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>
                                            
                                                <WithSortedSerachTableHeaderFirst 
                                                    label='Id' 
                                                    handleSearchSubmit={this.handleSearchSubmit('id')}
                                                    handleCancelSearch={this.handleCancelSearch('id')}
                                                    isActiveFilter={Boolean(id)}
                                                    width='200px'
                                                />
            
                                                <WithSortedSerachTableHeader 
                                                    label='Description' 
                                                    handleSearchSubmit={this.handleSearchSubmit('description')}
                                                    handleCancelSearch={this.handleCancelSearch('description')}
                                                    isActiveFilter={Boolean(description)}
                                                    width='100%'
                                                    widthMin='550px'
                                                />

                                                <TableCell
                                                    sx={{width: '252px'}}
                                                >
                                                    <WithSortedTableCellContent>
                                                        <div style={{width: '220px'}}>Created</div>
                                                        <SortButtonsSongleToLeft>
                                                            <SortButtonTop onClick={this.handleClickSortTopUpdate}>
                                                                <SvgIcon viewBox="0 0 9 2">
                                                                    <path d="M0.666504 4.66602L4.6665 0.666016L8.6665 4.66602H0.666504Z" fill="#979799"/>
                                                                </SvgIcon>
                                                            </SortButtonTop>
                                                            <SortButtonBottom onClick={this.handleClickSortBottomUpdate}>
                                                                <SvgIcon viewBox="0 0 9 9">
                                                                    <path d="M0.666504 0.666016L4.6665 4.66602L8.6665 0.666016H0.666504Z" fill="#979799"/>
                                                                </SvgIcon>
                                                            </SortButtonBottom>
                                                        </SortButtonsSongleToLeft>
                                                    </WithSortedTableCellContent>
                                                </TableCell>
            
                                                <ActionsHeaderCell
                                                    sx={{width: '114px'}}
                                                >
                                                    <StickyBorder>Action</StickyBorder>
                                                </ActionsHeaderCell>
            
                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {(hdbkList || []).map(row => {
                                                return (
                                                    <TableRow key={Math.random()}>
                                                        <TableCell>
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.text}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                format(Date.parse(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                                                            }
                                                        </TableCell>
                                                        <ActionsCell>
                                                            <StickyBorder>
                                                                <ActionsContent>  
                                                                    <Tooltip title='View details' placement="top">                                                  
                                                                        <ActionIcon 
                                                                            onClick={() => {this.handleChangeHdbk(row.id)}}
                                                                        >
                                                                            <RemoveRedEyeIcon />
                                                                        </ActionIcon>
                                                                    </Tooltip>
                                                                </ActionsContent>
                                                            </StickyBorder>
                                                        </ActionsCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>

                                </OverlayScrollbarsComponent>

                                </StyledTableContainer>                               
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={hdbkPageCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>

                            <Modal
                                open={openWindowCreate}
                                onClose={this.handleCloseWindowCreate}
                            >
                                <StyledBoxPopup>
                                    <CreateHDBKTypeForm 
                                        getChangeValue={this.handleCreate} 
                                        onClose={this.handleCloseWindowCreate}
                                    />
                                </StyledBoxPopup>
                            </Modal>

                            <Snackbar 
                                open={openSnackbar} 
                                autoHideDuration={6000} 
                                onClose={this.handleCloseSnackbar} 
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                            >
                                {error ?
                                <InfoPopup
                                    severity="error"
                                    headerText="Hdbk request type not created"
                                    infoText={errorMessage ? errorMessage : "Something went wrong. Please try again later"}
                                /> :
                                <InfoPopup
                                    severity="success"
                                    headerText="Hdbk request type was created"
                                />}
                            </Snackbar>
                        </>
                    }
    
                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.hdbkRequestType.isLoading,
        hdbkList: state.hdbkRequestType.hdbkList['hydra:member'],
        hdbkPageCount: state.hdbkRequestType.hdbkList['hydra:totalItems'],
        error: state.hdbkRequestType.isError,
        errorMessage: state.hdbkRequestType.errorMessage,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        hdbkRequest,
        hdbkCreate,
    }),
)(SystemInfoAdressesList);
