import React from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Structure from '../../components/structure';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CreateWalletForm from "./create-wallet-form";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import { TableFilter } from "../../components/table-filter";
import Menu from '@mui/material/Menu';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import { getWalletsRequest } from "../../actions/wallets";
import {format} from 'date-fns';
import TablePagination from '@mui/material/TablePagination';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import {Loader} from '../../components/loaders/loader';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { AccessDeniedAlert } from "../../components/access-denied";


const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    // height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const WalletsPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',
    }
});

const WalletsPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const WalletsPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 768px)': {
        width: '100%',
        marginBottom: '16px',
        justifyContent: 'space-between'
    }
});

const TableCellContent = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const IconWrapper = styled('div')({
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }
`));

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    // border-bottom: none;
`));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

// const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
//     .MuiPaper-root{
//         border-radius: 0;
//         box-sizing: border-box;
//         box-shadow: none;
//     }
// `));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}))

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 190px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

const filterItems = [{title: 'Not created', value: '0'}, {title: 'Created', value: '1'}];

class WalletsPage extends React.Component<any,any> {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchorElStatus: null,
            rowsPerPage: 20,
            page: 0,
            openSnackbar: false,
            statuses: []
        };
    };

    handleOpen = () => this.setState({open: true});
    handleClose = () => this.setState({open: false});

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget});
    };

    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({page: newPage}, () => this.updateData());
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10)});
    };

    handleOpenSnackbar = () => {
        this.setState({openSnackbar: true});
    };
    
    handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        };

        this.setState({openSnackbar: false});
    };

    onChangeFilter = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
        this.handleStatusMenuClose();
    };

    updateData = () => {
        const {page, statuses} = this.state;
        const {getWalletsRequest} = this.props;

        const data = {
            'page': page,
            'status[]': statuses
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        getWalletsRequest(data);
    };

    componentDidMount() {
        const {
            routsUser,
            userRoles,
            isSuperadmin,
            activeNodeType,
        } = this.props;

        if((routsUser.some(rout => rout.child.includes('/wallet-service/')) && activeNodeType === 1) || (userRoles.some(role => role.name === 'Admin') && activeNodeType === 1) || (!!isSuperadmin && activeNodeType === 1)){
            this.updateData();
        }
    }

    render() {
        const {
            anchorElStatus, 
            rowsPerPage, 
            page, 
            open, 
            openSnackbar, 
            statuses,
        } = this.state;

        const {
            wallets, 
            walletsCount, 
            error, 
            isLoading,
            routsUser,
            userRoles,
            isSuperadmin,
            activeNodeType,
        } = this.props;

        const isStatusFilterOpen = Boolean(anchorElStatus);

        const accessedRole = (routsUser.some(rout => rout.child.includes('/wallet-service/')) && activeNodeType === 1) || (userRoles.some(role => role.name === 'Admin') && activeNodeType === 1) || (!!isSuperadmin && activeNodeType === 1);
        const accessedRoleCreate = routsUser.some(rout => rout.child.includes('/wallet-service/create')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleHistory = routsUser.some(rout => rout.child.includes('/wallet-service/history')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole &&
                    <>
                        <WalletsPageHeader>
                            <WalletsPageHeaderLeft>
                                <WalletsPageHeaderText>Wallets</WalletsPageHeaderText>
                                <Structure/>
                            </WalletsPageHeaderLeft>

                            {accessedRoleCreate &&
                                <Button onClick={this.handleOpen}>CREATE WALLET</Button>
                            }

                        </WalletsPageHeader>
                        <StyledTableContainer>

                            {accessedRoleHistory &&
                                <StyledPaperTable>
                                    <TableContainer component={Paper}>

                                        <OverlayScrollbarsComponent
                                            className='overlay-scroll-table'
                                            options={{
                                                scrollbars: { visibility: 'visible' } 
                                            }}
                                        >

                                            <Table>
                                                <StyledTableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>
                                                            <TableCellContent sx={{minWidth: '180px'}}>
                                                                Payment system
                                                            </TableCellContent>
                                                        </TableCell>
                                                        <TableCell>User</TableCell>
                                                        <TableCell>Account</TableCell>

                                                        <TableCellWithActiveClass
                                                            className={ statuses.length > 0 ? 'activeFilterClass' : ''}
                                                        >
                                                            <TableCellContent>
                                                                    Status
                                                                    <IconWrapper>
                                                                        <FilterListSvg size="16px" onClick={this.handleStatusMenuClick}/>
                                                                    </IconWrapper>
                                                                    <StyledMenu
                                                                        MenuListProps={{
                                                                        'aria-labelledby': 'long-button',
                                                                        }}
                                                                        anchorEl={anchorElStatus}
                                                                        open={isStatusFilterOpen}
                                                                        onClose={this.handleStatusMenuClose}
                                                                        PaperProps={{
                                                                            style: {
                                                                                width: '210px',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <TableFilter
                                                                            filterItems={filterItems}
                                                                            initialData={statuses}
                                                                            onApplyFilter={this.onChangeFilter} 
                                                                            onClearAll={this.onChangeFilter}
                                                                        />
                                                                    </StyledMenu>
                                                                </TableCellContent>
                                                        </TableCellWithActiveClass>

                                                        <TableCell>
                                                            <div style={{width: '180px'}}>Created</div>
                                                        </TableCell>
                                                    </TableRow>
                                                </StyledTableHead>
                                                <TableBody>
                                                    {wallets.map(row => {
                                                        return (
                                                            <TableRow key={row.id}>
                                                                <TableCell>{row.id}</TableCell>
                                                                <TableCell>{row.ps}</TableCell>
                                                                <TableCell>{row.user}</TableCell>
                                                                <TableCell>{row.account}</TableCell>
                                                                <TableCell>{row.status ? 'Created' : 'Not created'}</TableCell>
                                                                <TableCell>{format(new Date(row.createdAt * 1000), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>

                                        </OverlayScrollbarsComponent>

                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={walletsCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    />
                                </StyledPaperTable>
                            }

                        </StyledTableContainer>               
                        <Modal
                            open={open}
                            onClose={this.handleClose}
                        >
                            <StyledBoxPopup>
                                <CreateWalletForm onClose={this.handleClose} handleOpenSnackbar={this.handleOpenSnackbar}/>
                            </StyledBoxPopup>
                        </Modal>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                            {error ?
                            <InfoPopup
                                severity="error"
                                headerText="Wallet not created"
                                infoText={error}
                            /> :
                            <InfoPopup
                                severity="success"
                                headerText="Wallet was successfully created"
                            />}
                        </Snackbar>
                    </>
                }

                {isLoading && <Loader />}
                
            </PageContainer>
        );
    }
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.wallets.isLoading,
        wallets: state.wallets.wallets['hydra:member'],
        walletsCount: state.wallets.wallets['hydra:totalItems'],
        error: state.wallets.error,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
        activeNodeType: state.nodeManagement.activeNode.type,
    }), {getWalletsRequest})
)(WalletsPage);
