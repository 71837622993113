import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#272F3D',
      paper: '#1C1F25',
    },
    text: {      
      primary: '#ffffff',
    },
    secondary: {
      main: 'rgba(151,	151,	153, .5)',
    },
    action: {
      selected: '#272F3D'
    }
  },
})