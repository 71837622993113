export namespace ActionTypes {
    export const GET_BLOCKCHAIN_CONFIG = 'GET_BLOCKCHAIN_CONFIG';
	export const CREATE_BLOCKCHAIN_CONFIG_SUCCESS = 'CREATE_BLOCKCHAIN_CONFIG_SUCCESS';
    export const CREATE_BLOCKCHAIN_CONFIG_ERROR = 'CREATE_BLOCKCHAIN_CONFIG_ERROR';
	export const UPDATE_BLOCKCHAIN_CONFIG_SUCCESS = 'UPDATE_BLOCKCHAIN_CONFIG_SUCCESS';
    export const UPDATE_BLOCKCHAIN_CONFIG_ERROR = 'UPDATE_BLOCKCHAIN_CONFIG_ERROR';
    export const SET_INITIAL_CONFIG = 'SET_INITIAL_CONFIG';
};

export const initialState = {
    error: false,
    blockchainConfig: {
        boundarySumMerch: '',
        boundarySumUser: '',
        boundaryTrxNumMerch: '',
        boundaryTrxNumUser: '',
        unconfMaxTime: ''
    },
	errorMessage: ''
};

export const blockchainConfig = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.GET_BLOCKCHAIN_CONFIG:
			return {
				...state,
				blockchainConfig: payload,
			};
		case ActionTypes.CREATE_BLOCKCHAIN_CONFIG_SUCCESS:
			return {
				...state,
				error: false,
				errorMessage: ''
			}
		case ActionTypes.CREATE_BLOCKCHAIN_CONFIG_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}
		case ActionTypes.UPDATE_BLOCKCHAIN_CONFIG_SUCCESS:
			return {
				...state,
				error: false,
				errorMessage: ''
			}
		case ActionTypes.UPDATE_BLOCKCHAIN_CONFIG_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}
        case ActionTypes.SET_INITIAL_CONFIG:
            return initialState;
		default:
			return state;
	}
};