import React, {useEffect} from "react";
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button} from '../../components/button';
import { Loader } from '../../components/loaders/loader';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Modal from '@mui/material/Modal';
import {SetWebHookForm} from './SetWebHookForm';
import { 
    telegramSetWebhook,
    telegramRequest,
    telegramDelete,
} from "../../actions/telegram";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import Dialog from '@mui/material/Dialog';
import { NotifyDialog } from '../../components/notify-dialog';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TelegramInfoType } from "../../reducers/telegram";
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => (`  
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxView = styled(Box)(({theme}) => (`
    // border: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;  
    .MuiTableContainer-root{
        border-radius: 0;
    }
    tr{
        &:last-child{
            td{
                border-bottom: none;
            }
        }
    }   
`));

const TableCellTitle = styled(TableCell)(({theme}) => (`
    font-weight: bold;
    width: 200px;
`));

const WrappBox = styled(Box)(({theme}) => (`
    margin-bottom: 40px;
    margin-top: 35px;
    overflow: auto;
    border: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
`));

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {    
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: auto;
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledModal = styled(Modal)(({ theme }) => (`
    & {    
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        & > .MuiBox-root{
            max-width: 700px;
            padding: 0 10px;
        }
    s}
`));

const LongText = styled('div')(`
    word-break: break-all;
`);

const StyledDialog = styled(Dialog)(({ theme }) => (`
    &{
        .MuiDialog-paper{
            border-radius: 0;
        }
    }
`));

type PropsType = {
    telegramSetWebhook: (data: {url: string}) => void,
    telegramRequest: () => void,
    telegramDelete: () => void,
    telegramItem: TelegramInfoType,
    isLoading: boolean,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: any,
}

export const TelegramPage: React.FC<PropsType> = (props) => {

    const [openWindowCreate, setOpenWindowCreate] = React.useState<boolean>(false);
    const [openWindowDelete, setOpenWindowDelete] = React.useState<boolean>(false);

    const {
        telegramSetWebhook,
        telegramRequest,
        telegramDelete,
        telegramItem,
        isLoading,
        routsUser,
        userRoles,
        isSuperadmin,
    } = props

    useEffect(() => {
        if(routsUser.some(rout => rout.child.includes('/telegram/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            telegramRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenWindowSetWebhook = () => {
        setOpenWindowCreate(true)
    }

    const handleCloseWindowSetWebhook = () => {
        setOpenWindowCreate(false)
    }
    
    const handleSetWebhook = (address) => {
        const data = {
            "url": address.webhooklink
        }
        telegramSetWebhook(data)
        handleCloseWindowSetWebhook()
    }

    const handleClickCloseWindowDelete = () => {
        setOpenWindowDelete(false)
    }

    const handleClickOpenWindowDelete = () => {
        setOpenWindowDelete(true)
    }

    const handleDeleteWebhook = () => {
        telegramDelete()
        setOpenWindowDelete(false)
    }

    const accessedRole = routsUser.some(rout => rout.child.includes('/telegram/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    return(
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole &&
                <>
                    <ShortViewHeader>
                        <ShortViewHeaderLeft>
                            <HeaderLeftContainer>
                                <ShortViewHeaderText>
                                    Telegram Webhook info
                                </ShortViewHeaderText>
                            </HeaderLeftContainer>
                        </ShortViewHeaderLeft>
                        <ShortViewHeaderRight>
                            <Button onClick={handleOpenWindowSetWebhook}>
                                <AssignmentTurnedInIcon sx={{marginRight:'8px',fontSize: '19px'}} />
                                Set Webhook
                            </Button>
                            <Button isDeleteButton={true} onClick={handleClickOpenWindowDelete}>
                                <DeleteIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                                Delete Webhook
                            </Button>
                        </ShortViewHeaderRight>
                    </ShortViewHeader>
                    <WrappBox>
                        <BoxView sx={{ width: '100%', typography: 'body1' }}>
                            <TableContainer component={Paper} sx={{minWidth: '600px'}}>
                                <Table>   
                                    <TableBody>
                                        <TableRow>
                                            <TableCellTitle>url</TableCellTitle>
                                            <TableCell>
                                                <LongText>{telegramItem.url}</LongText>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCellTitle>has_custom_certificate</TableCellTitle>
                                            <TableCell>
                                                {
                                                    telegramItem.has_custom_certificate
                                                        ? <CheckBoxIcon />
                                                        : <span style={{color: '#c55', fontStyle: 'italic'}}>(not set)</span>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCellTitle>pending_update_count</TableCellTitle>
                                            <TableCell>{telegramItem.pending_update_count}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCellTitle>max_connections</TableCellTitle>
                                            <TableCell>{telegramItem.max_connections}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCellTitle>ip_address</TableCellTitle>
                                            <TableCell>{telegramItem.ip_address}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </BoxView>
                    </WrappBox>
                    <StyledDialog
                        open={openWindowDelete}
                        onClose={handleClickCloseWindowDelete}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <NotifyDialog
                            title={`Telegram Webhook delete ?`}
                            confirmButtonText='YES, DELETE'
                            cancelButtonText='CANCEL'
                            onClose={handleClickCloseWindowDelete}
                            width="400px"
                            isDeleteButton
                            onCancel={handleClickCloseWindowDelete}
                            onConfirm={handleDeleteWebhook}
                        >
                            Are you sure you want to delete Webhook ?
                        </NotifyDialog>  
                    </StyledDialog>
                    <StyledModal
                        open={openWindowCreate}
                        onClose={handleCloseWindowSetWebhook}
                    >
                        <StyledBoxPopup>
                            <SetWebHookForm 
                                getChangeValue={handleSetWebhook} 
                                onClose={handleCloseWindowSetWebhook}
                            />
                        </StyledBoxPopup>
                    </StyledModal>
                </>
            }
                
            {isLoading && <Loader/>}
        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        isLoading: state.telegram.isLoading,
        telegramItem: state.telegram.telegramItem,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        telegramSetWebhook,
        telegramRequest,
        telegramDelete,
    }),
)(TelegramPage)
