import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const DoneSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 24 24"
            onClick={onClick}
        >
            <path d="M9.00039 16.2L4.80039 12L3.40039 13.4L9.00039 19L21.0004 7L19.6004 5.6L9.00039 16.2Z" fill={fill}/>
        </WrapperTag>
    );
};

DoneSvg.defaultProps = {
    fill: '#38AA72',
};
