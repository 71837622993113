import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const FilterListSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 16 16"
            onClick={onClick}
        >
            <path d="M2.83317 3.77332C2.89984 3.85999 6.65984 8.65999 6.65984 8.65999V12.6667C6.65984 13.0333 6.95984 13.3333 7.33317 13.3333H8.67317C9.03984 13.3333 9.3465 13.0333 9.3465 12.6667V8.65332C9.3465 8.65332 13.0065 3.97332 13.1798 3.75999C13.3532 3.54666 13.3332 3.33332 13.3332 3.33332C13.3332 2.96666 13.0332 2.66666 12.6598 2.66666H3.33984C2.93317 2.66666 2.6665 2.98666 2.6665 3.33332C2.6665 3.46666 2.7065 3.62666 2.83317 3.77332Z" fill={fill}/>
        </WrapperTag>
    );
};

FilterListSvg.defaultProps = {
    fill: '#979799',
};
