import React from "react";
import MainStatementsPage from './statements-main-list';
import StatementsView from './statements-view';
import StatementsEdit from './statements-edit'

export type Viev = {
    point: string, 
    idCurrentStatement: number
}

export const StatementsPage = () => {
    const [view, setView] = React.useState<Viev>({point: 'main', idCurrentStatement: 0})

    return(
        <>
            { view.point === 'main' && <MainStatementsPage setView={setView} />}
            { view.point === 'view' && <StatementsView setView={setView} view={view} />}
            { view.point === 'edit' && <StatementsEdit setView={setView} view={view} /> }
        </>
    )
} 

export default StatementsPage;