import React from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Structure from '../../components/structure';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import CreateNewPaymentSystem from "./add-new-payment-system";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import { TableFilter } from "../../components/table-filter";
import Menu from '@mui/material/Menu';
import TablePagination from '@mui/material/TablePagination';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { DefaultImage } from '../../components/default-image';
import { StatusActive } from '../../components/status-active';
import {SerachTableHeader} from '../../components/search-table-header';
import {getUserPaymentSystemsRequest, getUserPaymentSystemRequest} from '../../actions/userPaymentSystems';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {EmptyDataSvg} from '../../components/svg/empty-data-svg';
import {Loader} from '../../components/loaders/loader';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import {AlertPopUpForRootNodeContainer} from './alert-popup-for-root-node';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => (`
    padding: 40px 40px 0 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    position: relative;
    @media(max-width: 768px){
        padding: 16px 16px 0 16px;
        height: 100%;
    }
`));

const PSPageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    @media(max-width: 767px){
        flex-wrap: wrap;
        margin-bottom: 16px;
    }
    button{
        svg{
            margin-right: 8px;
        }
    }
`);

const PSPageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 24px;
    color: ${theme.palette.text.primary};
    @media(max-width: 767px){
        font-size: 20px;
        width: 100%;
        padding-bottom: 16px;
    }
`));

const PSPageHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 991px){
        width: 100%;
        margin-bottom: 16px;
    }
    @media(max-width: 768px){
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 16px;
        justify-content: space-between;
    }
`);

const TableCellContent = styled('div')(`
    display: flex;
    align-items: center;
`);

const IconWrapper = styled('div')(`
    margin-left: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
`);

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default}; 
    & th{
        border-left: ${ theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)' };
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const WrapLogoWidth = styled('div')(({ theme }) => (`
    & {
        width: 80px;
    }
`));

const StyledModal = styled(Modal)(({ theme }) => (`
    display: flex;
    justify-content: center;
    align-items: center;
`));

const TableIconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

const TableIcon = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
});

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}));

// Fix for scrolles
const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
        border-bottom: none;
        box-sizing: border-box;
        box-shadow: none;
    }
    .MuiTablePagination-root{
        border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
        margin-top: -1px;
        position: relative;
        z-index: 10;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 185px - 56px);
        @media(max-width: 991px){
            max-height: calc(100vh - 240px - 56px);
        };
        @media(max-width: 767px){
            max-height: calc(100vh - 285px - 56px);
        };
    }
`));

// const filterItemsActive = [
//     {title: 'On', value: 1}, 
//     {title: 'Moderation', value: 0}
// ];

const filterItemsStatus = [
    {title: 'Active', value: 1}, 
    {title: 'Disabled', value: 0}, 
];

type PropsPaymentSystemsMain = {
    getUserPaymentSystemsRequest: (data: {page: string}) => void;
    getUserPaymentSystemRequest: (id: any) => Promise<any>;
    userPaymentSystems: any;
    userPaymentSystemsCount: string;
    activeNode: any;
    isLoading: boolean;
    routsUser: Array<any>;
    userRoles: Array<any>;
    isSuperadmin: any;
};

class PaymentSystemsMain extends React.Component<PropsPaymentSystemsMain, any> {
    constructor(props){
        super(props);

        this.state = {
            open: false,
            rowsPerPage: 20,
            page: 0,
            anchorElActive: null,
            anchorElStatus: null,
            name: '',
            active: [],
            status: [],
            loaded: [],
            isOpenPopupAlert: false
        };
    }
    
    handleOpen = () => this.setState({open: true});
    
    handleClose = () => this.setState({open: false});

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({page: newPage}, () => this.updateData());
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0});
    };

    handleActiveMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElActive: event.currentTarget});
    };
    
    handleActiveMenuClose = () => {
        this.setState({anchorElActive: null});
    };

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget});
    };
    
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleSearchSubmit = (value) => {
        this.setState({name: value, page: 0,}, () => {this.updateData()});
    };

    handleCancelSearch = () => {
        if (this.state.name) {
            this.setState({name: ''}, () => {this.updateData()});
        };
    };

    onChangeActiveFilter = (data) => {
        this.setState({active: data}, () => {this.updateData()});
        this.handleActiveMenuClose();
    }

    onChangeStatusFilter = (data) => {
        this.setState({status: data}, () => {this.updateData()});
        this.handleStatusMenuClose();
    }

    updateData = () => {
        const {
            page, 
            name, 
            // active, 
            status
        } = this.state;
        const {getUserPaymentSystemsRequest} = this.props;

        const data = {
            'page': page + 1,
            'paymentSystem.name': name,
            'active': status,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        getUserPaymentSystemsRequest(data);
    };

    componentDidMount() {
        const {routsUser, userRoles, isSuperadmin} = this.props;
        if(routsUser.some(rout => rout.child.includes('/user-payment-system/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.activeNode.id !== this.props.activeNode.id) {
            this.setState({page: 0}, () => {this.updateData()});
        };
    };

    handleOpenAlertNodePopup = () => {
        this.setState({isOpenPopupAlert: true});
    }
    handleCloseAlertNodePopup = () => {
        this.setState({isOpenPopupAlert: false});
    }

    render() {
        const {
            // anchorElActive, 
            anchorElStatus, 
            rowsPerPage, 
            page, 
            open, 
            // active, 
            status, 
            name,
            isOpenPopupAlert,
        } = this.state;

        const {
            userPaymentSystems, 
            userPaymentSystemsCount, 
            isLoading,
            activeNode,
            routsUser,
            userRoles,
            isSuperadmin,
        } = this.props;

        // const isActiveFilterOpen = Boolean(anchorElActive);
        const isStatusFilterOpen = Boolean(anchorElStatus);

        const accessedRole = routsUser.some(rout => rout.child.includes('/user-payment-system/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        
        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole &&
                    <>
                        <PSPageHeader>
                            <PSPageHeaderLeft>
                                <PSPageHeaderText>Payment systems</PSPageHeaderText>
                                <Structure/>
                            </PSPageHeaderLeft>
                            {activeNode.type === 1 && 
                                <Button onClick={this.handleOpen}>
                                    <AddBoxOutlinedIcon/> 
                                    add payment system
                                </Button>
                            }
                        </PSPageHeader>  
            
                        <StyledTableContainer>
                            <TableContainer component={Paper}>

                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >

                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>
                                                <TableCell width='175px'>Logo</TableCell>
                
                                                <SerachTableHeader 
                                                    label='Name'
                                                    handleSearchSubmit={this.handleSearchSubmit}
                                                    handleCancelSearch={this.handleCancelSearch}
                                                    isActiveFilter={Boolean(name)}
                                                />
                                                
                                                <TableCellWithActiveClass 
                                                    width='120px' 
                                                    align="right"
                                                    className={ status.length > 0 ? 'activeFilterClass' : ''}
                                                >
                                                    <TableCellContent>
                                                        Status
                                                        <IconWrapper>
                                                            <FilterListSvg size="16px" onClick={this.handleStatusMenuClick}/>
                                                        </IconWrapper>
                                                        <StyledMenu
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorElStatus}
                                                            open={isStatusFilterOpen}
                                                            onClose={this.handleStatusMenuClose}
                                                            PaperProps={{
                                                                style: {
                                                                    width: '210px',
                                                                },
                                                            }}
                                                        >
                                                            <TableFilter
                                                                filterItems={filterItemsStatus}
                                                                initialData={status}
                                                                onApplyFilter={this.onChangeStatusFilter}
                                                                onClearAll={this.onChangeStatusFilter}
                                                            />
                                                        </StyledMenu>
                                                    </TableCellContent>
                                                </TableCellWithActiveClass>
                                                
                                                {/* <TableCellWithActiveClass
                                                    className={ active.length > 0 ? 'activeFilterClass' : ''}
                                                >
                                                    <TableCellContent>
                                                            Active
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleActiveMenuClick}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElActive}
                                                                open={isActiveFilterOpen}
                                                                onClose={this.handleActiveMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={filterItemsActive}
                                                                    initialData={active}
                                                                    onApplyFilter={this.onChangeActiveFilter}
                                                                    onClearAll={this.onChangeActiveFilter}
                                                                />
                                                            </StyledMenu>
                                                    </TableCellContent>
                                                </TableCellWithActiveClass> */}

                                                <TableCell align="left">
                                                    Active
                                                </TableCell>
                                                <TableCell width='40px' align="center">
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {userPaymentSystems.length ? userPaymentSystems.map(row => {
                                                return (
                                                    <TableRow key={row.id}>
                                                        <TableCell>
                                                            <WrapLogoWidth>
                                                                {this.state.loaded.includes(row.id) ? null : <DefaultImage sx={{maxWidth: '100%', height: '64px'}}/>}
                                                                <img
                                                                    src={row.paymentSystem.logoAbsoluteUrl}
                                                                    style={this.state.loaded.includes(row.id) ? {} : {display: 'none'}}
                                                                    width='100%' alt={row.logo}
                                                                    onLoad={() => this.setState((state) => { return {loaded: [...state.loaded, row.id]} })}
                                                                />
                                                            </WrapLogoWidth>
                                                        </TableCell>
                                                        <TableCell>{row.paymentSystem.name}</TableCell>
                                                        <TableCell>
                                                            {
                                                                row.active === 1 
                                                                    ? 'Active' 
                                                                        : row.active === 0 
                                                                        ? 'Disabled' : '---'
                                                            }
                                                        </TableCell>
                                                        <TableCell sx={{width: '175px', textAlign: 'left'}}>
                                                            {
                                                                row.paymentSystem.active 
                                                                    ? <StatusActive active={Boolean(row.paymentSystem.active)}/>
                                                                    : <span style={{color :'rgb(204, 85, 85)'}}>Moderation</span> 
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {
                                                                activeNode?.id !== 1
                                                                    ? row.paymentSystem.active
                                                                        ? <Tooltip title='Configure' placement="top">
                                                                            <Link to={`/payment-system/${row.id}`}>
                                                                                <IconButton 
                                                                                    aria-label="menu User" 
                                                                                    size="large" 
                                                                                >
                                                                                    <EditIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Link>
                                                                        </Tooltip>
                                                                        : <IconButton 
                                                                                aria-label="menu User" 
                                                                                size="large" 
                                                                                disabled={true}
                                                                            >
                                                                                <EditIcon fontSize="small" />
                                                                            </IconButton>
                                                                    : <IconButton 
                                                                            aria-label="menu User" 
                                                                            size="large" 
                                                                            disabled={false}
                                                                            onClick={this.handleOpenAlertNodePopup}
                                                                        >
                                                                            <EditIcon fontSize="small" />
                                                                        </IconButton>  
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }) :
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <TableIconWrapper>
                                                            <TableIcon>
                                                                <EmptyDataSvg height="80px"/>
                                                                No results found.
                                                            </TableIcon>
                                                        </TableIconWrapper>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>

                                </OverlayScrollbarsComponent>

                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={Number(userPaymentSystemsCount)}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />             
                        </StyledTableContainer>                
                        <StyledModal
                            open={open}
                            onClose={this.handleClose}
                        >
                            <StyledBoxPopup>
                                <CreateNewPaymentSystem onClose={this.handleClose}/>
                            </StyledBoxPopup>
                        </StyledModal>

                        <StyledModal
                            open={isOpenPopupAlert}
                            onClose={this.handleCloseAlertNodePopup}
                        >
                            <StyledBoxPopup>
                                <AlertPopUpForRootNodeContainer onClose={this.handleCloseAlertNodePopup}/>
                            </StyledBoxPopup>
                        </StyledModal>

                        {isLoading && <Loader />}
                    </>
                }
                
            </PageContainer>
        )
    }
};

export default compose(
    connect((state: RootState) => ({
        isLoading: state.userPaymentSystems.isLoading,
        userPaymentSystems: state.userPaymentSystems.userPaymentSystems['hydra:member'],
        userPaymentSystemsCount: state.userPaymentSystems.userPaymentSystems['hydra:totalItems'],
        activeNode: state.nodeManagement.activeNode,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        getUserPaymentSystemsRequest, 
        getUserPaymentSystemRequest
    })
)(PaymentSystemsMain);
