export namespace ActionTypes {
    export const FETCH_ACTION_LOG_START = 'FETCH_STATEMENTS_START';
    export const FETCH_ACTION_LOG_SUCCESS = 'FETCH_STATEMENTS_SUCCESS';
    export const FETCH_ACTION_LOG_FAILURE = 'FETCH_STATEMENTS_FAILURE';
};

type ActionItem = {
	'@id': string,
	'@type': string,
	action: string,
	ip: string,
	user: {
		'@id': string,
		'@type': string,
		id: number,
		username: string,
	},
	createdAt: string,
}

type ActionLogList = {
	'@context': string;
	'@id': string;
	'hydra:member': ActionItem[];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

interface ActionLog {
	isError: boolean,
    isLoading: boolean,
	actionsLog: ActionLogList,
	errorMessage: string,
}

export const initialState: ActionLog = {
	isError: false,
    isLoading: false,
	actionsLog: {
		'@context': '',
		'@id': '',
		'hydra:member': [
			{
				'@id': "",
				'@type': '',
				action: "Description",
				ip: "10.10.10.10",
				user: {
					'@id': '/api/users/1',
					'@type': 'User',
					id: 1,
					username: 'badalalam.dabad'
				},
				createdAt: '2000-01-31T14:02:05+00:00',
			}
		],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	errorMessage: '',
};

export const actionsLogs = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_ACTION_LOG_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_ACTION_LOG_SUCCESS:
			return {
				...state,
				actionsLog: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_ACTION_LOG_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
