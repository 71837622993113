import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from '../../components/button';

import { compose } from "redux";
import { connect } from 'react-redux';
import { buildApiRequest } from '../../actions/buildApi';
import { useAppSelector } from "../../app/hooks";
import { Loader } from '../../components/loaders/loader'
import { RootState } from '../../store/configure-store';
import { AccessDeniedAlert } from "../../components/access-denied";


const PageContainer = styled('div')(({theme}) => (`
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const BuildApiPageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    @media(max-width: 768px){
        flex-wrap: wrap;
    }
    button{
        svg{
            margin-right: 8px;
        }
    }
`);

const BuildApiPageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 24px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
        padding-bottom: 16px;
    }
`));

const BuildApiPageHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 991px){
        width: 100%;
        margin-bottom: 16px;
    }
    @media(max-width: 768px){
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 12px;
        justify-content: space-between;
    }
`);

const BuildApiMainInfoList = styled('div')(({theme}) => (`
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    max-width: 740px;
    box-shadow: inset 0px 1px 0px ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    padding: 9px 16px 9px;
`));

const BuildApiItem = styled('div')(({theme}) => (`
    // display: flex;
    // flex-wrap: wrap;
    padding: 2px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${theme.palette.text.primary};
`));

const DataApi = styled('div')(({theme}) => (`
    width: 70px;
    float: left;
`));

const InfoApi = styled('div')(({theme}) => (`
    word-break: break-all;
`));

type PropsType = {
    buildApiRequest: () => void;
    routsUser: Array<any>;
    userRoles: Array<any>;
    isSuperadmin: any;
}

const BuildAPIDocks: React.FC = (props: PropsType) => {
    const {
        buildApiRequest,
        routsUser, 
        userRoles, 
        isSuperadmin,
    } = props;
    const buildApiList = useAppSelector(state => state.buildApi.buildApiList)
    const isLoading = useAppSelector(state => state.queue.isLoading)

    const handleBuildAPIDoc = () => {
        buildApiRequest()
    };

    const accessedRole = routsUser.some(rout => rout.child.includes('/site/doc-build')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    return (
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole &&
                <>
                    <BuildApiPageHeader>
                        <BuildApiPageHeaderLeft>
                            <BuildApiPageHeaderText>Build API Doc</BuildApiPageHeaderText>
                        </BuildApiPageHeaderLeft>
                        <Button onClick={handleBuildAPIDoc}>
                            Build API Doc
                        </Button>
                    </BuildApiPageHeader>  

                    <BuildApiMainInfoList>
                        { buildApiList
                            ? buildApiList.map((item, i) => {
                                return <BuildApiItem key={Date.now()+Math.random()}>
                                    <DataApi>{item.time}</DataApi>
                                    <InfoApi>{item.message}</InfoApi>                            
                                </BuildApiItem>
                            })
                            : null
                        }
                    </BuildApiMainInfoList> 
                </>
            }   

            {isLoading && <Loader/>}         
        </PageContainer>
    )
}


export default compose(
    connect((state: RootState | any) => ({
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
     }), {
        buildApiRequest
    }),
)(BuildAPIDocks)