import React from 'react';
import LoginPage from './pages/login-page';
import { MainPage } from './pages/main-page';
import ThemeApp from './services/themes'
import {BrowserRouter as Router} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { RootState } from './store/configure-store';

function App() {
  const isLogin = useSelector((state: RootState) => state.authorization.isAuth)

  return (
    <ThemeApp>   
      <Router>
        { 
          isLogin 
            ? <MainPage /> 
            : <LoginPage /> 
        }  
      </Router> 
    </ThemeApp>   
  );
}

export default App;
