export namespace ActionTypes {
    export const FETCH_USERS_NODE_START = 'FETCH_USERS_NODE_START';
    export const FETCH_USERS_NODE_SUCCESS = 'FETCH_USERS_NODE_SUCCESS';
    export const FETCH_USERS_NODE_FAILURE = 'FETCH_USERS_NODE_FAILURE';
    export const FETCH_USER_NODE_ITEM_SUCCESS = 'FETCH_USER_NODE_ITEM_SUCCESS';
    export const FETCH_USER_NODE_ITEM_FAILURE = 'FETCH_USER_NODE_ITEM_FAILURE';
    export const FETCH_USER_NODE_DELETE = 'FETCH_USER_NODE_DELETE';
    export const FETCH_USER_NODE_EDIT = 'FETCH_USER_NODE_EDIT';
    export const FETCH_USER_NODE_DROPDOWN_LIST = 'FETCH_USER_NODE_DROPDOWN_LIST';
    export const FETCH_USER_ASSIGMENTS_SUCCESS = 'FETCH_USER_ASSIGMENTS_SUCCESS';
    export const FETCH_USERS_ASSIGMENTS_FAILURE = 'FETCH_USERS_ASSIGMENTS_FAILURE';

    export const FETCH_USERS_REVOKE_SUCCESS = 'FETCH_USERS_REVOKE_SUCCESS';
    export const FETCH_USERS_REVOKE_FAILURE = 'FETCH_USERS_REVOKE_FAILURE';
};

type UsersList = {
	'@context': string;
	'@id': string;
	'hydra:member': [];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

interface Users {
	isError: boolean,
    isLoading: boolean,
	users: UsersList,
	errorMessage: string,
	itemUser: any,
	dropdownAssign: [],
	userItemsAssigment: any,
}

export const initialState: Users = {
	isError: false,
    isLoading: false,
	users: {
		'@context': '',
		'@id': '',
		'hydra:member': [],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	errorMessage: '',
	itemUser: [],
	dropdownAssign: [],
	userItemsAssigment: {
		roles: [{
			name: '',
			code: '',
			checked: false
		}],
		permissions: [{
			name: '',
			code: '',
			checked: false
		}],
	},
};

export const usersOnTheNode = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_USERS_NODE_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_USERS_NODE_SUCCESS:
			return {
				...state,
				users: payload,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};
		case ActionTypes.FETCH_USERS_NODE_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		case ActionTypes.FETCH_USER_NODE_ITEM_SUCCESS:
			return {
				...state,
				itemUser: payload,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};
		case ActionTypes.FETCH_USER_NODE_DROPDOWN_LIST:
			return {
				...state,
				dropdownAssign: payload,
			};
		case ActionTypes.FETCH_USER_NODE_DELETE:
			return {
				...state,
			};		
		case ActionTypes.FETCH_USER_NODE_EDIT:
			return {
				...state,
				users: payload,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};

		case ActionTypes.FETCH_USER_ASSIGMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				userItemsAssigment: payload,
				isError: false,
				errorMessage: '',
			};	
		case ActionTypes.FETCH_USERS_ASSIGMENTS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload,
				userItemsAssigment: {
					roles: [{
						name: '',
						code: '',
						checked: false
					}],
					permissions: [{
						name: '',
						code: '',
						checked: false
					}],
				},
			};	
			
		case ActionTypes.FETCH_USERS_REVOKE_SUCCESS:
			return {
				...state,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};	
		case ActionTypes.FETCH_USERS_REVOKE_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};					
		default:
			return state;
	}
};
