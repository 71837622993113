import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material/styles";


const StyledTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },
}));


export const TextArea = (props: any) => {
    return (
        <StyledTextField
            variant="outlined"
            fullWidth
            type="text"
            margin="dense"
            size="small"
            label="Multiline"
            multiline
            maxRows={10}
            {...props}
        />
    )
}