import React, {useState} from 'react';
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';


type OrderItemProps = {
    status: string;
}

const SelectElPSystem = styled('div')(({theme }) => ({
    boxSizing: 'border-box',
    background: theme.palette.background.default,
    border: theme.palette.mode === 'light' ? 'none' : `1px solid #65646B`,
    borderRadius: '4px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 8px 0px 0px',
    marginBottom: '12px',
}));

const SelectElPSystemTitle = styled('div')(({theme }) => ({
    padding: '9px 8px',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    cursor: 'pointer',
    width: '100%',
    borderRadius: '4px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    fontSize: '12px',
    alignItems: 'center',
}));

const SelectElAccountsTitle = styled('div')(({theme }) => ({
    padding: '5px 8px',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontSize: '11px',
    background: theme.palette.mode === 'light' ? 'rgba(0,0,0, .05)' : 'rgba(255,255,255, .1)',
    borderRadius: '4px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const SelectElMethodsTitle = styled('div')(({theme }) => ({
    padding: '8px 8px 0px 0px',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontSize: '11px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
}));

const SelectElPSystemAccount = styled('div')(({theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    margin: '0 0px 8px 8px',
}));

const SelectElPSystemMethod = styled('div')<OrderItemProps>(({ status, theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    paddingLeft: '15px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
}));

const MappingPageOrderItemInfoCircle = styled('div')(({ theme }) => ({
    width: '4px',
    height: '4px',
    borderRadius: '2px',
    background: theme.palette.text.primary,
    marginRight: '8px',
    marginLeft: '8px',
}));

const TitleMethodsFlex = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

const AddElementToRight = styled('div')(({ theme }) => ({
    height: '20px',
}));



export const PaymentSystemItem = (props) => {
    const {pSystem, addResult, listException, currency} = props;
    const [openAccount, setOpenAccount] = useState(false);
    const handleOpenAccountsList = () => setOpenAccount(prev => !prev)

    return (
        <SelectElPSystem>
            <SelectElPSystemTitle
                onClick={handleOpenAccountsList}
            >
                <span>{pSystem?.psname}</span>
                <ArrowDropDownIcon />
            </SelectElPSystemTitle>

            {openAccount && pSystem.hasOwnProperty('accounts') && 
                pSystem.accounts.map((account, index) => {
                    return (
                        <ListAccounts 
                            key={index}
                            account={account}
                            addResult={addResult}
                            psName={pSystem?.psname}
                            listException={listException}
                            currency={currency}
                        />
                    )
                }) 
            }
        </SelectElPSystem>
    );
}

const ListAccounts = ({
    account, 
    addResult, 
    psName, 
    listException, 
    currency,
}) => {
    const [openMethods, setOpenMethods] = useState(false);
    const handleOpenMethodsList = () => setOpenMethods(prev => !prev)

    const handleAddListResultAccounts = (data) => {
        if(data.hasOwnProperty('methods')){delete data['methods']}
        data.currency = currency;
        data.psname = psName;
        addResult(data);
    }

    const handleAddListResultMethods = (accname, data) => {
        data.currency = currency;
        data.psname = psName;
        data.accname = accname;
        addResult(data);
    }
    
    return(
        <SelectElPSystemAccount 
            key={Math.random()}
        >
            <SelectElAccountsTitle
                sx={{'opacity': listException.includes(`${currency}${psName}${account.name}`) ? '.7' : '1'}}
            >
                <TitleMethodsFlex 
                    onClick={handleOpenMethodsList}
                    sx={{cursor: 'pointer'}}
                >
                    {account.name}
                    <ArrowDropDownIcon />
                </TitleMethodsFlex>
                {!listException.some(item => item.includes(`${currency}${psName}${account.name}`)) && 
                    <Tooltip title={`Add all methods ${account.name}`} placement="top">
                        <AddElementToRight 
                            onClick={() => handleAddListResultAccounts(account)}
                        >
                            <AddCircleOutlineIcon
                                fontSize='small'
                                sx={{color: '#38AA72', cursor: 'pointer'}}
                            />
                        </AddElementToRight>
                    </Tooltip>
                }
            </SelectElAccountsTitle>
            {openMethods && account.hasOwnProperty('methods') 
                ? account.methods.map(itemMethod => {
                    return(
                        <SelectElPSystemMethod 
                            key={Math.random()}
                            status={itemMethod.status}
                            sx={{'opacity': (listException.includes(`${currency}${psName}${account.name}${itemMethod.name}`) || listException.includes(`${currency}${psName}${account.name}`)) ? '.7' : '1'}}
                        >
                            <SelectElMethodsTitle>
                                <TitleMethodsFlex>
                                    <span>{account.name}</span>
                                    <MappingPageOrderItemInfoCircle/>
                                    <span>{itemMethod.name}</span>
                                </TitleMethodsFlex>
                                {!(listException.includes(`${currency}${psName}${account.name}${itemMethod.name}`) || listException.includes(`${currency}${psName}${account.name}`)) && 
                                    <Tooltip title={`Add Method ${itemMethod.name}`} placement="top">
                                        <AddElementToRight 
                                            onClick={() => handleAddListResultMethods(account.name, itemMethod)}
                                        >
                                            <AddCircleOutlineIcon
                                                fontSize='small'
                                                sx={{color: '#38AA72', cursor: 'pointer'}}
                                            />
                                        </AddElementToRight>
                                    </Tooltip>
                                }
                            </SelectElMethodsTitle>
                        </SelectElPSystemMethod>
                    )
                }) 
                : <></>
            }
        </SelectElPSystemAccount>
    )
}
