import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/hdbk_request_types';

const SystemInfoHdbkRequestsTypes = {
	hdbkList: (params = {}) => {
		const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';
		return baseApi.get(`${path}${query}`)
	},
	hdbkItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
	hdbkDelete: (id) => {
		return baseApi.delete(`${path}/${id}`)
	},
	hdbkCreate: (data) => {
		return baseApi.post(`${path}`, data)
	},
	hdbkUpdate: (id, data) => {
		return baseApi.patch(`${path}/${id}`, data)
	}
}

export default SystemInfoHdbkRequestsTypes;
