import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Button} from '../../components/button';
import {connect} from 'react-redux';
import {compose} from "redux";
import { InfoOutlineSvg } from "../../components/svg/info-outline-svg";
import {Input} from '../../components/input';
import { MoreSvg } from "../../components/svg/more-svg";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Scrollbars } from 'react-custom-scrollbars-2';
import useResizeObserver from "use-resize-observer";
import {getTotalSeconds, convertSecondsToTimeString} from '../../utils';

const EditMappingFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: '20px',
    width: '100%',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },   
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const MethodsRuleBlock = styled('div')({
    paddingBottom: '16px',
    borderBottom: '1px solid #C0BDC93D'
});

const MethodsTitle = styled('div')(({ theme }) => ({
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: '600',
    color: theme.palette.text.primary
}));

const MethodsInfo = styled('div')({
    margin: '16px 0px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center'
});

const MethodsInfoText = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary
}));

const MethodsOrderBlock = styled('div')({
    marginTop: '16px'
});

const OrderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

const OrderItem = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px',
    alignItems: 'center'
});

const OrderItemNumber = styled('div')(({ theme }) => ({
    fontSize: '14px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    background: theme.palette.mode === 'light' ? '#92CD91' : '#38AA72',
    borderRadius: '12px',
    marginRight: '16px',

    '@media(max-width: 768px)' : {
        minWidth: '24px'
    }
}));

const OrderItemInfo = styled('div')(({ theme }) => ({
    background: theme.palette.mode === 'light' ? '#F1F8ED' : theme.palette.background.default,
    border: theme.palette.mode === 'light' ? 'none' : `1px solid #65646B`,
    borderRadius: '4px',
    height: '32px',
    width: 'calc(100% - 24px - 16px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 8px'
}));

const OrderItemInfoTextContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.mode === 'light' ? '#38AA72' : theme.palette.text.primary,
}));

const OrderItemInfoNameText = styled('div')({
    width: '120px',
    fontWeight: '500',
    fontSize: '12px'
});

const OrderItemInfoMethodText = styled('div')({
    width: '180px',
    fontWeight: '500',
    fontSize: '12px'
});

const OrderItemInfoCircle = styled('div')(({ theme }) => ({
    width: '4px',
    height: '4px',
    borderRadius: '2px',
    background: theme.palette.mode === 'light' ? '#38AA72' : theme.palette.text.primary,
    marginRight: '16px'
}));

const timeRegExp = /^(?:(\d+d\s?)?(\d+h\s?)?(\d+m\s?)?(\d+s?)?)$/i;
const numberRegExp = /^[0-9]*$/;

const EditMappingForm = ({onClose, cascadeListState, setCascadeListState, way, currency, setIsEdited}: any) => {
    const rules = {...cascadeListState[way][currency].rules};

    const [rule, setRule] = useState(
        rules.availability ?
        'availability' : rules.sum_limit ?
        'limits-by-sum' : ''
        );
    const handleChangeRule = (event) => {
        setRule(event.target.value);
    };

    const [period, setPeriod] = useState(
        rules.availability === 86400 ?
        '86400' : rules.availability === 3600 ?
        '3600' : rules.availability === 300 ?
        '300' : rules.availability ?
        'custom' : ''
    );
    const handleChangePeriod = (event) => {
        setPeriod(event.target.value);
    };

    const [periodTime, setPeriodTime] = useState(period === 'custom' ? convertSecondsToTimeString(rules.availability) : '');
    const handleChangePeriodTime = (event) => {
        const value = event.target.value;
        const isValidFormat = timeRegExp.test(value);
        
        if (isValidFormat) {
            setPeriodTime(value);
        };
    };

    const [limits, setLimits] = useState(
        rules?.sum_limit && !rules?.delta_day && !rules?.delta_today && !rules?.delta_custom && way === 'deposit' ?
        'pay-in' : rules?.sum_limit && !rules?.delta_day && !rules?.delta_today && !rules?.delta_custom && way === 'withdraw' ?
        'pay-out' : rules?.delta_day && rules?.sum_limit ?
        'pay-in-pay-out-24h' : rules?.sum_limit && rules?.delta_today ?
        'pay-in-pay-out-0-sum' : rules?.delta_custom && rules?.sum_limit ?
        'pay-in-pay-out-custom-time' : ''
        );
    const handleChangeLimits = (event) => {
        setLimits(event.target.value);
    };

    const [time, setTime] = useState(limits === 'pay-in-pay-out-custom-time' ? convertSecondsToTimeString(rules.delta_period) : '');
    const handleChangeTime = (event) => {
        const value = event.target.value;
        const isValidFormat = timeRegExp.test(value);
        
        if (isValidFormat) {
            setTime(value);
        };
    };

    const [sum, setSum] = useState(
        limits ? Number(String(rules.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' ') : ''
    );
    const handleChangeSum = (event) => {
        const value = event.target.value;
        const isValidFormat = numberRegExp.test(value.replace(/\s/g, ''));

        if (isValidFormat) {
            setSum(Number(event.target.value.replace(/\s/g, '')).toLocaleString().replace(/,/g, ' '));
        }
    };

    const [use, setUse] = useState('');
    const handleChangeUse = (event) => {
        setUse(event.target.value);
    };

    const [methods, setMethods] = useState('');
    const handleChangeMethods = (event) => {
        setMethods(event.target.value);
    };

    const [card, setCard] = useState('');
    const handleChangeCard = (event) => {
        setCard(event.target.value);
    };

    const [wallet, setWallet] = useState('');
    const handleChangeWallet = (event) => {
        setWallet(event.target.value);
    };

    const [loyalityUse, setLoyalityUse] = useState('');
    const handleChangeLoyalityUse = (event) => {
        setLoyalityUse(event.target.value);
    };

    const [type, setType] = useState('');
    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const [level, setLevel] = useState('');
    const handleChangeLevel = (event) => {
        setLevel(event.target.value);
    };

    const { ref } = useResizeObserver<HTMLDivElement>();

    const [list, setList] = useState(cascadeListState[way][currency].methods as any);

    const handleDragEnd = ({ destination, source }) => {
        if (!destination) return;
    
        setList(prev => {
            const result = Array.from(prev);
            const [removed] = result.splice(source.index, 1);
            result.splice(destination.index, 0, removed);
  
            return result;
        });
    };

    const handleConfirm = () => {
        setCascadeListState(prev => {
            const changedState = {...prev};

            if (rule === 'availability') {
                rules.availability = period === '86400' ? 86400 : period === '3600' ? 3600 : period === '300' ? 300 : period === 'custom' ? getTotalSeconds(periodTime) : 0;
                rules.delta_day = 0;
                rules.delta_today = 0;
                rules.delta_custom = 0;
                rules.sum_limit = 0;

            } else if (rule === 'limits-by-sum') {
                if (limits === 'pay-in' || limits === 'pay-out') {
                    rules.availability = 0;
                    rules.delta_day = 0;
                    rules.delta_today = 0;
                    rules.delta_custom = 0;
                    rules.sum_limit = Number(sum.replace(/\s/g, ''));
                } else if (limits === 'pay-in-pay-out-24h') {
                    rules.availability = 0;
                    rules.delta_day = 1;
                    rules.delta_today = 0;
                    rules.delta_custom = 0;
                    rules.sum_limit = Number(sum.replace(/\s/g, ''));
                } else if (limits === 'pay-in-pay-out-0-sum') {
                    rules.availability = 0;
                    rules.delta_day = 0;
                    rules.delta_today = 1;
                    rules.delta_custom = 0;
                    rules.sum_limit = Number(sum.replace(/\s/g, ''));
                } else if (limits === 'pay-in-pay-out-custom-time') {
                    rules.availability = 0;
                    rules.delta_day = 0;
                    rules.delta_today = 0;
                    rules.delta_custom = getTotalSeconds(time);
                    rules.sum_limit = Number(sum.replace(/\s/g, ''));
                };
            };

            changedState[way][currency].methods = list;
            changedState[way][currency].rules = rules;

            return changedState;
        });

        setIsEdited(true);
        onClose();
    };

    const handleReset = () => {
        setList(cascadeListState[way][currency].methods);
        
        rules.availability = 0;
        rules.delta_day = 0;
        rules.delta_today = 0;
        rules.delta_custom = 0;
        rules.sum_limit = 0;

        setRule('');
        setPeriod('');
        setPeriodTime('');
        setLimits('');
        setTime('');
        setSum('');
    };

    const isValid = () => {
        if (!rule) {
            return true;
        };

        if (rule === 'availability') {
            if (period === '') {
                return true;
            };
            if (period === 'custom' && !periodTime) {
                return true;
            };
        };
    
        if (rule === 'limits-by-sum') {
            if (limits === '') {
                return true;
            }
            if (limits === 'pay-in-pay-out-custom-time' && !time) {
                return true;
            }
            if (sum === '') {
                return true;
            }
        };

        const initialValues = {
            rule: rules.availability ? 'availability' : rules.sum_limit ? 'limits-by-sum' : '',
            period: rules.availability === 86400 ? '86400' : rules.availability === 3600 ? '3600' : rules.availability === 300 ? '300' : rules.availability ? 'custom' : '',
            periodTime: period === 'custom' ? convertSecondsToTimeString(rules.availability) : '',
            limits: rules?.sum_limit && !rules?.delta_day && !rules?.delta_today && !rules?.delta_custom && way === 'deposit' ?
            'pay-in' : rules?.sum_limit && !rules?.delta_day && !rules?.delta_today && !rules?.delta_custom && way === 'withdraw' ?
            'pay-out' : rules?.delta_day && rules?.sum_limit ?
            'pay-in-pay-out-24h' : rules?.sum_limit && rules?.delta_today ?
            'pay-in-pay-out-0-sum' : rules?.delta_custom && rules?.sum_limit ?
            'pay-in-pay-out-custom-time' : '',
            time: limits === 'pay-in-pay-out-custom-time' ? convertSecondsToTimeString(rules.delta_period) : '',
            sum: limits ? Number(String(rules.sum_limit).replace(/\s/g, '')).toLocaleString().replace(/,/g, ' ') : ''
        };

        return !(rule !== initialValues.rule || initialValues.period !== period || initialValues.periodTime !== periodTime || limits !== initialValues.limits || time !== initialValues.time || sum !== initialValues.sum);
    };

    return (
        <EditMappingFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>Order of payment methods</Title>
                <MethodsRuleBlock>
                    <MethodsTitle>
                        Payment methods rule
                    </MethodsTitle>
                    <MethodsInfo>
                        <InfoOutlineSvg size="16px"/>
                        <MethodsInfoText>
                            Set the change Method Rule
                        </MethodsInfoText>
                    </MethodsInfo>
                    <StyledFormControl fullWidth>
                        <InputLabel size="small">Rule</InputLabel>
                        <Select
                            value={rule}
                            label="Rule"
                            onChange={handleChangeRule}
                            size="small"
                        >
                            <StyledMenuItem value="availability">Availability</StyledMenuItem>
                            <StyledMenuItem value="limits-by-sum">Limits by sum</StyledMenuItem>
                            {/* <StyledMenuItem value="frequency">Frequency</StyledMenuItem> */}
                            {/* <StyledMenuItem value="loyality">Loyality</StyledMenuItem> */}
                        </Select>
                    </StyledFormControl>
                    {rule === 'availability' &&
                        <>
                            <StyledFormControl fullWidth>
                                <InputLabel size="small">Period</InputLabel>
                                <Select
                                    value={period}
                                    label="Period"
                                    onChange={handleChangePeriod}
                                    size="small"
                                >
                                    <StyledMenuItem value="86400">Last 24 hours</StyledMenuItem>
                                    <StyledMenuItem value="3600">Last hour</StyledMenuItem>
                                    <StyledMenuItem value="300">Last 5 minutes</StyledMenuItem>
                                    <StyledMenuItem value="custom">Custom period</StyledMenuItem>
                                </Select>
                            </StyledFormControl>
                            {period === 'custom' &&
                                <Input
                                    label="Set time (in UTC)"
                                    value={periodTime}
                                    onChange={handleChangePeriodTime}
                                    placeholder="1d 5h 30m 20s"
                                    sx={{marginTop: '20px'}}
                                />
                            }
                        </>
                    }
                    {rule === 'limits-by-sum' &&
                        <>
                            <StyledFormControl fullWidth>
                                <InputLabel size="small">Limits</InputLabel>
                                <Select
                                    value={limits}
                                    label="Limits"
                                    onChange={handleChangeLimits}
                                    size="small"
                                >
                                    {way === 'deposit' ? <StyledMenuItem value="pay-in">Pay in</StyledMenuItem> : <StyledMenuItem value="pay-out">Pay out</StyledMenuItem>}
                                    <StyledMenuItem value="pay-in-pay-out-24h">Delta = pay-in – pay-out (last 24 hours)</StyledMenuItem>
                                    <StyledMenuItem value="pay-in-pay-out-0-sum">Delta = pay-in – pay-out (start with 0.00)</StyledMenuItem>
                                    <StyledMenuItem value="pay-in-pay-out-custom-time">Delta = pay-in – pay-out (start custom time)</StyledMenuItem>
                                </Select>
                            </StyledFormControl>
                            {limits === 'pay-in-pay-out-custom-time' &&
                                <Input
                                    label="Time (in UTC)"
                                    value={time}
                                    onChange={handleChangeTime}
                                    placeholder="1d 5h 30m 20s"
                                    sx={{marginTop: '20px'}}
                                />
                            }
                            {limits && 
                                <Input
                                    label="Sum"
                                    value={sum}
                                    onChange={handleChangeSum}
                                    sx={{marginTop: '20px'}}
                                />
                            }
                        </>
                    }
                    {rule === 'frequency' &&
                        <>
                            <StyledFormControl fullWidth>
                                <InputLabel size="small">Use</InputLabel>
                                <Select
                                    value={use}
                                    label="Use"
                                    onChange={handleChangeUse}
                                    size="small"
                                >
                                    <StyledMenuItem value="methods">Methods</StyledMenuItem>
                                    <StyledMenuItem value="card">That card</StyledMenuItem>
                                    <StyledMenuItem value="wallet">The wallet</StyledMenuItem>
                                </Select>
                            </StyledFormControl>
                            {use === 'methods' && 
                                <StyledFormControl fullWidth>
                                    <InputLabel size="small">Methods</InputLabel>
                                    <Select
                                        value={methods}
                                        label="Methods"
                                        onChange={handleChangeMethods}
                                        size="small"
                                    >
                                        <StyledMenuItem value="1-methods">1 methods</StyledMenuItem>
                                    </Select>
                                </StyledFormControl>
                            }
                            {use === 'card' && 
                                <StyledFormControl fullWidth>
                                    <InputLabel size="small">Card</InputLabel>
                                    <Select
                                        value={card}
                                        label="Card"
                                        onChange={handleChangeCard}
                                        size="small"
                                    >
                                        <StyledMenuItem value="1-card">1 card</StyledMenuItem>
                                    </Select>
                                </StyledFormControl>
                            }
                            {use === 'wallet' && 
                                <StyledFormControl fullWidth>
                                    <InputLabel size="small">Wallet</InputLabel>
                                    <Select
                                        value={wallet}
                                        label="Wallet"
                                        onChange={handleChangeWallet}
                                        size="small"
                                    >
                                        <StyledMenuItem value="1-wallet">1 wallet</StyledMenuItem>
                                    </Select>
                                </StyledFormControl>
                            }

                        </>
                    }
                    {rule === 'loyality' &&
                        <>
                            <StyledFormControl fullWidth>
                                <InputLabel size="small">Use</InputLabel>
                                <Select
                                    value={loyalityUse}
                                    label="Use"
                                    onChange={handleChangeLoyalityUse}
                                    size="small"
                                >
                                    <StyledMenuItem value="first-traffic">First traffic</StyledMenuItem>
                                    <StyledMenuItem value="second-traffic">Second traffic</StyledMenuItem>
                                    <StyledMenuItem value="per-loyal-type-user">Per loyal type user</StyledMenuItem>
                                    <StyledMenuItem value="loyalty-level">Loyalty level</StyledMenuItem>
                                </Select>
                            </StyledFormControl>
                            {loyalityUse === 'per-loyal-type-user' &&
                                <StyledFormControl fullWidth>
                                    <InputLabel size="small">Type</InputLabel>
                                    <Select
                                        value={type}
                                        label="Type"
                                        onChange={handleChangeType}
                                        size="small"
                                    >
                                        <StyledMenuItem value="revenue">Revenue</StyledMenuItem>
                                        <StyledMenuItem value="pay-in">Pay-in</StyledMenuItem>
                                        <StyledMenuItem value="pay-out">Pay-out</StyledMenuItem>
                                    </Select>
                                </StyledFormControl>
                            }
                            {loyalityUse === 'loyalty-level' &&
                                <StyledFormControl fullWidth>
                                    <InputLabel size="small">Level</InputLabel>
                                    <Select
                                        value={level}
                                        label="Level"
                                        onChange={handleChangeLevel}
                                        size="small"
                                    >
                                        <StyledMenuItem value="average">Average</StyledMenuItem>
                                        <StyledMenuItem value="vip">VIP</StyledMenuItem>
                                        <StyledMenuItem value="risk">Risk</StyledMenuItem>
                                    </Select>
                                </StyledFormControl>
                            }
                        </>
                    }
                </MethodsRuleBlock>
                <MethodsOrderBlock>
                    <MethodsTitle>
                        Customize Order of payment methods
                    </MethodsTitle>
                    <MethodsInfo>
                        <InfoOutlineSvg size="16px"/>
                        <MethodsInfoText>
                            Drag to reorder.
                        </MethodsInfoText>
                    </MethodsInfo>
                    <div ref={ref} style={{width: '100%', height: 'calc(100vh - 610px)'}}>
                        <Scrollbars>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable-order">
                                    {(provided) => (
                                        <OrderContainer ref={provided.innerRef} {...provided.droppableProps}>
                                            {list.map((item, index) => {
                                                return (
                                                    <Draggable
                                                        key={`${item.name}_${item.method}`}
                                                        index={index}
                                                        draggableId={`${item.name}_${item.method}`}
                                                    >
                                                        {(provided) => (
                                                            <OrderItem
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <OrderItemNumber>{index + 1}</OrderItemNumber>
                                                                <OrderItemInfo>
                                                                    <OrderItemInfoTextContainer>
                                                                        <OrderItemInfoNameText>{item.name.toUpperCase()}</OrderItemInfoNameText>
                                                                        <OrderItemInfoCircle/>
                                                                        <OrderItemInfoMethodText>{item.method.toUpperCase()}</OrderItemInfoMethodText>
                                                                    </OrderItemInfoTextContainer>
                                                                    <MoreSvg size="20px" fill="#38AA72"/>
                                                                </OrderItemInfo>
                                                            </OrderItem>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </OrderContainer>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Scrollbars>
                    </div>
                </MethodsOrderBlock>
            </WrapFields>
            <ButtonsContainer>
                <Button sx={{marginRight: '12px'}} variant="outlined" onClick={handleReset}>RESET DEFAULTS</Button>
                <Button onClick={handleConfirm} disabled={isValid()}>CONFIRM</Button>
            </ButtonsContainer>

        </EditMappingFormContainer>
    )
};

export default compose(
    connect(null, {})
)(EditMappingForm);
