import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/user_visit_logs';

const UsersVisitLog = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';
		return baseApi.get(`${path}${query}`)
	},
	getItem: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
}

export default UsersVisitLog;