export namespace ActionTypes {
    export const GET_NODES_TREE = 'GET_NODES_TREE';
	export const GET_NODE = 'GET_NODE';
	export const SET_LOADING = 'SET_LOADING';
	export const SET_ACTIVE_NODE = 'SET_ACTIVE_NODE';
	export const CREATE_NODE_SUCCESS = 'CREATE_NODE_SUCCESS';
	export const CREATE_NODE_ERROR = 'CREATE_NODE_ERROR';
	export const UPDATE_NODE_SUCCESS = 'UPDATE_NODE_SUCCESS';
	export const UPDATE_NODE_ERROR = 'UPDATE_NODE_ERROR';
	export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';
	export const DELETE_NODE_ERROR = 'DELETE_NODE_ERROR';
	export const VERIFY_DOMAIN_SUCCESS = 'VERIFY_DOMAIN_SUCCESS';
	export const VERIFY_DOMAIN_ERROR = 'VERIFY_DOMAIN_ERROR';
	export const SWITCH_REST_ACCESS = 'SWITCH_REST_ACCESS';
	export const GET_API_KEY = 'GET_API_KEY';

	export const GET_BRANDS = 'GET_BRANDS';
	export const GET_CLIENTS = 'GET_CLIENTS';
};

export const initialState = {
	nodesTree: [{}],
	isLoading: false,
	activeNode: {
		createdAt: '',
		domain: '',
		id: '',
		name: '',
		note: '',
		parentId: '',
		primaryEmail: '',
		type: '',
		users: [],
		verified: ''
	},
	errorMessage: '',
	clients: '',
	brands: '',
};

export const nodeManagement = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_LOADING:
			return {
				...state,
				isLoading: true
			}
		case ActionTypes.GET_NODES_TREE:
			return {
				...state,
				nodesTree: payload,
				isLoading: false
			};
		case ActionTypes.GET_NODE:
			return {
				...state,
				node: payload,
				isLoading: false
			};
		case ActionTypes.SET_ACTIVE_NODE:
			return {
				...state,
				activeNode: payload,
				isLoading: false
			}
		case ActionTypes.GET_API_KEY:
			return {
				...state,
				apiKey: payload,
				error: false
			}
		case ActionTypes.SWITCH_REST_ACCESS:
			return {
				...state,
				RESTAccessMessage: payload,
				error: false
			}
		case ActionTypes.DELETE_NODE_SUCCESS:
			return {
				...state,
				error: false,
				errorMessage: ''
			}
		case ActionTypes.DELETE_NODE_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}
		case ActionTypes.UPDATE_NODE_SUCCESS:
			return {
				...state,
				error: false,
				errorMessage: ''
			}
		case ActionTypes.UPDATE_NODE_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}
		case ActionTypes.CREATE_NODE_SUCCESS:
			return {
				...state,
				error: false,
				errorMessage: ''
			}
		case ActionTypes.CREATE_NODE_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}
		case ActionTypes.VERIFY_DOMAIN_SUCCESS:
			return {
				...state,
				error: false,
				domainVerificationStatus: payload,
				errorMessage: ''
			}
		case ActionTypes.VERIFY_DOMAIN_ERROR:
			return {
				...state,
				error: true,
				errorMessage: payload
			}



		case ActionTypes.GET_BRANDS:
			return {
				...state,
				brands: payload,
				isLoading: false
			};
		case ActionTypes.GET_CLIENTS:
			return {
				...state,
				clients: payload,
				isLoading: false
			};			
		default:
			return state;
	}
};