import {ActionTypes} from '../reducers/permission';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_MAPPING_PERMISSIONS_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_MAPPING_PERMISSIONS_SUCCESS,
		payload: list
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_MAPPING_PERMISSIONS_FAILURE,
		payload: error
	}
};

export const requestItemSuccess = item => {
	return {
		type: ActionTypes.FETCH_ITEM_PERMISSION_SUCCESS,
		payload: item
	};
};

export const requestItemThreeSuccess = item => {
	return {
		type: ActionTypes.FETCH_ITEM_PERMISSION_TREE_SUCCESS,
		payload: item
	};
};

export const usersPermissionsRequest = data => dispatch => {
	dispatch(requestStart());
	return API.usersPermissions
		.getList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const userPermissionCreate = data => dispatch => {
	dispatch(requestStart());
	return API.usersPermissions
		.createItem(data)
		.then((response) => {
			dispatch(usersPermissionsRequest(''));
		})
}

export const usersItemPermissionsRequest = name => dispatch => {
	dispatch(requestStart());
	return API.usersPermissions
		.getItem(name)
		.then(response => {
			dispatch(requestItemSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersItemPermissionsTreeRequest = name => dispatch => {
	dispatch(requestStart());
	return API.usersPermissions
		.getItemTree(name)
		.then(response => {
			dispatch(requestItemThreeSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const userPermissionsEdit = (name, data) => dispatch => {
	dispatch(requestStart());
	return API.usersPermissions
		.editItem(name, data)
		.then(response => {
			dispatch(usersItemPermissionsRequest(name));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const usersItemPermissionsDelete = name => dispatch => {
	dispatch(requestStart());
	return API.usersPermissions
		.deleteItem(name)
		.then(response => {
			dispatch(usersPermissionsRequest(''))
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};