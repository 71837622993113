import React, { useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Loader } from '../../components/loaders/loader';
import { 
    usersVisitLogRequestItem
} from "../../actions/usersVisitLog";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px'
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
    margin-top: 30px;
`));

const TableHeadCell = styled(TableCell)(({ theme }) => (`
    font-weight: bold;
    min-width: 200px;
`));


export const VisitLogDetails = (props) => {
    const {
        view,
        setView,
        isLoading,
        logInfo,
        usersVisitLogRequestItem,
    } = props;

    useEffect(() => {
        usersVisitLogRequestItem(view.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = (way) => () => {
        setView((prev) => {
            return {
                ...prev,
                point: way,
            }
        })
    }

    return (
        <PageContainer>
            <UserDetailsHeader>
                <UserDetailsHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer>
                            <ArrowBackSvg size="22px" onClick={handleClickNavigation('main')}/>
                        </ArrowContainer>
                        <UserDetailsHeaderText>
                            Log: {view.id}
                        </UserDetailsHeaderText>
                    </HeaderLeftContainer>
                </UserDetailsHeaderLeft>
            </UserDetailsHeader>
            <StyledPaperTable>
                <TableContainer component={'div'}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableHeadCell>User</TableHeadCell>
                                <TableCell>{logInfo.user.username}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>IP</TableHeadCell>
                                <TableCell>{logInfo.ip}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Language</TableHeadCell>
                                <TableCell>{logInfo.language}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>OS</TableHeadCell>
                                <TableCell>{logInfo.os}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Browser</TableHeadCell>
                                <TableCell>{logInfo.browser}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>User agent</TableHeadCell>
                                <TableCell>{logInfo.userAgent}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>Visit Time</TableHeadCell>
                                <TableCell>{logInfo.visitTime}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledPaperTable>

            {isLoading && <Loader/>}                

        </PageContainer>
    )
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.visitLogs.isLoading,
        logInfo: state.visitLogs.visitItem,
    }), {
        usersVisitLogRequestItem
    }),
)(VisitLogDetails)