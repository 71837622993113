import React from 'react';
import { styled } from "@mui/material/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import {LinkToPermission} from './link-permission';

const StyledUl = styled('ul')(`
    &{
        margin-top: 0;
        margin-left: 30px;
        padding-left: 0;
        width: calc(100%);
    }
`);

const StyledLi = styled('li')(`
    &{
        list-style:none;
        display: block;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }
`);

type ListData = {
    "@id": string;
    code: string;
    name: string | null;
    checked: boolean;
    cnt?: number;
	child?: Array<ListData>;
}

type PropsType = {
    items: Array<ListData>; 
    chahgeElement: (key: string) => void;
}

export const HasChieldRoute: React.FC<PropsType> = ({ items, chahgeElement }) => (
    <StyledUl>
        {
            items.map(item => {
                return <StyledLi 
                    key={Math.random()}
                    style={{paddingLeft: `${item.cnt ? (item.cnt * 30) : 0}px`}}
                >
                    <FormControlLabel 
                        control={
                            <Checkbox
                                checked={item.checked}
                                value={item.code}
                                sx={{
                                color: '#38AA72',
                                    '&.Mui-checked': {
                                        color: '#38AA72',
                                    },
                                }}
                                onClick={() => chahgeElement(item.code)}
                            />
                        } 
                        label={item.name || item.code} 
                        sx={{marginRight: '0px'}}
                    />
                    <Link to={`/users/role/${item.code}`}>
                        <LinkToPermission/>
                    </Link>
                </StyledLi>
            })
        }
    </StyledUl>
);