export namespace ActionTypes {
    export const FETCH_MAPPING_PERMISSIONS_START = 'FETCH_MAPPING_PERMISSIONS_START';
    export const FETCH_MAPPING_PERMISSIONS_SUCCESS = 'FETCH_MAPPING_PERMISSIONS_SUCCESS';
    export const FETCH_MAPPING_PERMISSIONS_FAILURE = 'FETCH_MAPPING_PERMISSIONS_FAILURE';
	export const FETCH_ITEM_PERMISSION_SUCCESS = 'FETCH_ITEM_PERMISSION_SUCCESS';
	export const FETCH_ITEM_PERMISSION_TREE_SUCCESS = 'FETCH_ITEM_PERMISSION_TREE_SUCCESS';
};

export type PermissionListItem = {
    "@id": string,
    "@type": string,
    "name": string,
    "description": string,
	"groupCode"?: {
        "@id": string,
        "@type":string,
        code: string,
        name: string,
    },
}

export type PermissionItemChildList = {
	'@id': string,
	'@type': string,
	child: Array<PermissionItemChildList | any>,
	name: string,
}

export type PermissionItem = {
	'@context': string,
	'@id': string,
	'@type': string,
	child: Array<PermissionItemChildList>,
	description: string,
	name: string,
}

export type ChildPermissions = {
    "@id": string,
    code: string,
    name: string | null,
    checked: boolean,
	owner_child_role?: boolean,
}

export type ChildRoutes = {
    "@id": string,
    code: string,
    name: string | null,
    checked: boolean,
	child: Array<ChildRoutes>
}

export type PermissionThree = {
	childPermissions: Array<ChildPermissions>,
	fields: Array<ChildPermissions>,
	routes: Array<ChildRoutes>,
}

interface permission {
	isError: boolean,
    isLoading: boolean,
	permissions: {
		'@context': string,
		'@id': string,
		'@type': string,
		'hydra:member': Array<PermissionListItem>,
		'hydra:search': {
			'@type': string, 
			'hydra:template': string, 
			'hydra:variableRepresentation': string, 
			'hydra:mapping': Array<any>,
		},
		'hydra:totalItems': number,
	},
	permissionItem: PermissionItem,
	permissionTree: PermissionThree,
	errorMessage: string,
}

export const initialState: permission = {
	isError: false,
    isLoading: false,
	permissions: {
		'@context': '',
		'@id': '',
		'@type': '',
		'hydra:member': [
			{
				"@id": '',
				"@type": '',
				"name": '',
				"description": '',
			}
		],
		'hydra:search': {
			'@type': '', 
			'hydra:template': '', 
			'hydra:variableRepresentation': '', 
			'hydra:mapping': [],
		},
		'hydra:totalItems': 1,
	},
	permissionItem: {
		'@context': '',
		'@id': '',
		'@type': '',
		child: [
			{
				'@id': '',
				'@type': '',
				child: [],
				name: '',
			}
		],
		description: '',
		name: '',
	},
	permissionTree: {
		childPermissions: [
			{
				"@id": '',
				code: '',
				name: '',
				checked: false,
			}
		],
		fields: [
			{
				"@id": '',
				code: '',
				name: '',
				checked: false,
			}
		],
		routes: [
			{
				"@id": '',
				code: '',
				name: '',
				checked: false,
				child: []
			}
		],
	},
	errorMessage: '',
};

export const permissions = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_MAPPING_PERMISSIONS_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_MAPPING_PERMISSIONS_SUCCESS:
			return {
				...state,
				permissions: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_ITEM_PERMISSION_SUCCESS:
			return {
				...state,
				permissionItem: payload,
				isLoading: false,
			};	
	case ActionTypes.FETCH_ITEM_PERMISSION_TREE_SUCCESS:
			return {
				...state,
				permissionTree: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_MAPPING_PERMISSIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
