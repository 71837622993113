import React from 'react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { styled } from "@mui/material/styles";

type Props = {
    permossion: string,
}

const StyledInsertLinkIcon = styled(InsertLinkIcon)(({ theme }) => (`
    color: #38AA72;
    width: 22px;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 7px;
`));

export const LinkToPermission: any = (props: Props) => {
    return  <StyledInsertLinkIcon />
}