import {ActionTypes} from '../reducers/systemInfoTemporiaryDepositLogs';
import API from '../services/api';

const fetchStart = () => ({
    type: ActionTypes.FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_LOADING
});

const fetchSuccess = (data) => ({
    type: ActionTypes.FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS,
    payload: data
});

const fetchSuccessItem = (data) => ({
    type: ActionTypes.FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_ITEM,
    payload: data
});

const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_SYS_INFO_TEMPORIARY_DEPOSIT_LOGS_FAILURE,
		payload: error
	}
};

export const sysInfoTempDepositLogsRequest = (params) => dispatch => {
    dispatch(fetchStart())
    return API.systemInfoTemporiaryDepositLogs.getSystemInfoTemporiaryDepositLogs(params)
    .then(response => {
        dispatch(fetchSuccess(response.data))
    })
    .catch(error => dispatch(requestFailure(error.toString())));
};

export const sysInfoTempDepositLogsItemRequest = (id) => dispatch => {
    dispatch(fetchStart())
    return API.systemInfoTemporiaryDepositLogs.getSystemInfoTemporiaryDepositLogItem(id)
    .then(response => {
        dispatch(fetchSuccessItem(response.data))
    })
    .catch(error => dispatch(requestFailure(error.toString())));
};
