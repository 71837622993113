import React from "react";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
    TextField,
    Popover,
    InputAdornment,
    IconButton
} from '@mui/material';
import DateRangeIcon from "@mui/icons-material//DateRange";
import { styled } from "@mui/material/styles";
import { Global, css } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const CalendarWrapper = styled('div')(({ theme }) => ({
    padding: '16px',
    '& .rdrCalendarWrapper': {
        backgroundColor: theme.palette.background.default,
        '& .rdrMonthAndYearPickers .rdrMonthPicker select, & .rdrMonthAndYearPickers .rdrYearPicker select': {
            color: theme.palette.text.primary,
            'option': {
                background: theme.palette.background.default,
            }
        },
        '.rdrDayNumber > span' : {
            color: theme.palette.mode === 'dark' ? "#ffffff" : theme.palette.text.primary
        }
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    position: 'relative',
    '& label.Mui-focused': {
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
        },
    },
}));

const TimePickersWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '352px'
}));

type Props = {
    label?: string;
    setDates?: (startDate: any, endDate: any) => void;
    clearDates?: boolean;
    withTimePicker?: boolean;
    disabled?: boolean;
    defaultStartMonthDate?: boolean;
}

export class DateRangePicker extends React.Component<Props> {
    dateFormat = this.props.withTimePicker ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";
  
    state = {
        displayCalendar: false,
        inputValue: this.props.defaultStartMonthDate ? `${new Date(new Date().getFullYear(), new Date().getMonth(), 1)} 00:00 - ${new Date()} 23:59` : '',
        anchorEl: null,
        fromDate: new Date(new Date().setHours(0, 0)),
        toDate: new Date(new Date().setHours(0, 0)),
        fromTime: new Date (new Date().setHours(0, 0)),
        toTime:  new Date(new Date().setHours(23, 59))
    };

    componentDidUpdate() {
        const {clearDates} = this.props;
        const {inputValue} = this.state;

        if (clearDates && inputValue !== '') {
            this.setState({fromDate: undefined, toDate: undefined, inputValue: '', fromTime: new Date (new Date().setHours(0, 0)), toTime:  new Date(new Date().setHours(0, 0))});
        }
    };
  
    onAdornmentClick = e => {
        this.setState({ displayCalendar: true, anchorEl: e.currentTarget });
    };
  
    onInputChange = e => {
        const inputValue = e.target.value;
        const { fromDate, toDate } = this.processInputValue(inputValue);
    
        this.setState({ inputValue, fromDate, toDate });
    };
  
    onPopoverClose = (e, reason) => {
        this.setState({ displayCalendar: false, anchorEl: null });
    };
  
    onSelectDateRanges = ({ selection }) => {
        let { startDate, endDate } = selection;
        const {setDates, withTimePicker} = this.props;
        const {fromTime, toTime} = this.state;
    
        startDate = moment(startDate);
        startDate = startDate.isValid() ? startDate.toDate() : undefined;
    
        endDate = moment(endDate);
        endDate = endDate.isValid() ? endDate.toDate() : undefined;

        if (withTimePicker) {
            startDate = startDate.setHours(fromTime.getHours(), fromTime.getMinutes())
            endDate = endDate.setHours(toTime.getHours(), toTime.getMinutes())
        };
    
        let inputValue = "";
        if (startDate) inputValue += moment(startDate).format(this.dateFormat);
        if (endDate) inputValue += " - " + moment(endDate).format(this.dateFormat);
    
        this.setState({ fromDate: startDate, toDate: endDate, inputValue });
        setDates(startDate, endDate);
    };
  
    processInputValue(value) {
        let [fromDate, toDate] = value.split("-").map(elm => elm.trim());
    
        fromDate = moment(fromDate, this.dateFormat);
        fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;
    
        toDate = moment(toDate, this.dateFormat);
        toDate = toDate.isValid() ? toDate.toDate() : undefined;
    
        return { fromDate, toDate };
    };

    onFromTimeChange = (newValue) => {
        const {fromDate, toDate} = this.state;
        const {setDates} = this.props;

        const startDate = new Date(new Date().setFullYear(new Date(fromDate).getFullYear(), new Date(fromDate).getMonth(), new Date(fromDate).getDate())).setHours(newValue.getHours(), newValue.getMinutes());
        const endDate = new Date(toDate);

        let inputValue = "";
        if (startDate) inputValue += moment(startDate).format(this.dateFormat);
        if (endDate) inputValue += " - " + moment(toDate).format(this.dateFormat);

        this.setState({fromTime: newValue, fromDate: startDate, inputValue});

        if (startDate && endDate) {
            setDates(startDate, endDate);
        };
    };

    onToTimeChange = (newValue) => {
        const {fromDate, toDate} = this.state;
        const {setDates} = this.props;

        const startDate = new Date(fromDate);
        const endDate = new Date(new Date().setFullYear(new Date(toDate).getFullYear(), new Date(toDate).getMonth(), new Date(toDate).getDate())).setHours(newValue.getHours(), newValue.getMinutes());
        
        let inputValue = "";
        if (startDate) inputValue += moment(fromDate).format(this.dateFormat);
        if (endDate) inputValue += " - " + moment(endDate).format(this.dateFormat);

        this.setState({toTime: newValue, toDate: endDate, inputValue});

        if (startDate && endDate) {
            setDates(startDate, endDate);
        };
    };
  
    render() {

        const {disabled, defaultStartMonthDate} = this.props;
  
        return (
            <>
                <StyledTextField
                    label={this.props.label}
                    fullWidth={true}
                    value={this.state.inputValue}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton 
                                    onClick={this.onAdornmentClick}
                                    data-testid={`button_${this.props.label.replace(/\s/g, '')}`}
                                >
                                    <DateRangeIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{
                        'data-testid': `input_${this.props.label.replace(/\s/g, '')}`
                    }}
                    onChange={this.onInputChange}
                    size="small"
                    disabled={disabled}
                />
                {!disabled && 
                    <Popover
                        open={this.state.displayCalendar}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        onClose={this.onPopoverClose}
                    >
                        <CalendarWrapper>
                            {this.props.withTimePicker && (
                                <TimePickersWrapper>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            ampm={false}
                                            label="Time from"
                                            value={this.state.fromTime}
                                            onChange={this.onFromTimeChange}
                                            renderInput={(params) => (
                                                <StyledTextField 
                                                    sx={{marginRight: '15px', width: '352px'}} 
                                                    size="small" {...params} 
                                                    inputProps={{
                                                        ...params.inputProps, 
                                                        readOnly: true,
                                                        'data-testid': `inputTimeFrom_${this.props.label.replace(/\s/g, '')}`
                                                    }}
                                                />
                                            )}
                                            PopperProps={{ sx: { '& .MuiClockPointer-root': {backgroundColor: '#38AA72'}, '& .MuiClock-pin': {backgroundColor: '#38AA72'}, '& .Mui-selected': {backgroundColor: '#38AA72'}, '& .MuiClockPointer-thumb': {border: '16px solid #38AA72'}}}}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            ampm={false}
                                            label="Time to"
                                            value={this.state.toTime}
                                            onChange={this.onToTimeChange}
                                            renderInput={(params) => (
                                                <StyledTextField 
                                                    sx={{marginRight: '15px', marginTop: '20px', marginBottom: '15px', width: '352px'}} 
                                                    size="small" {...params} 
                                                    inputProps={{
                                                        ...params.inputProps, 
                                                        readOnly: true,
                                                        'data-testid': `inputTimeTo_${this.props.label.replace(/\s/g, '')}`
                                                    }}
                                                />
                                            )}
                                            PopperProps={{ sx: { '& .MuiClockPointer-root': {backgroundColor: '#38AA72'}, '& .MuiClock-pin': {backgroundColor: '#38AA72'}, '& .Mui-selected': {backgroundColor: '#38AA72'}, '& .MuiClockPointer-thumb': {border: '16px solid #38AA72'}}}}
                                        />
                                    </LocalizationProvider>
                                </TimePickersWrapper>
                            )}
                            <DateRange
                                ranges={
                                    defaultStartMonthDate 
                                        ? [{
                                            startDate: this.state.fromDate ? new Date(this.state.fromDate) : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                                            endDate: this.state.toDate ? new Date (this.state.toDate) : new Date(),
                                            key: "selection"
                                        }]
                                        : [{
                                            startDate: this.state.fromDate ? new Date(this.state.fromDate) : null,
                                            endDate: this.state.toDate ? new Date (this.state.toDate) : null,
                                            key: "selection"
                                        }]
                                }
                                onChange={this.onSelectDateRanges}
                                staticRanges={undefined}
                                inputRanges={undefined}
                                showMonthAndYearPickers={true}
                                moveRangeOnFirstSelection={false}
                                showDateDisplay={false}
                            />
                        </CalendarWrapper>
                    </Popover>
                }
                <Global
                    styles={css`
                        .rdrInRange {
                        color: #38AA72 !important;
                        },
                        .rdrEndEdge {
                            color: #38AA72 !important;
                        },
                        .rdrStartEdge {
                            color: #38AA72 !important;
                        },
                        .rdrDayToday .rdrDayNumber span:after  {
                            background: #38AA72
                        }
                    `}
                />
            </>
        );
    }
};
