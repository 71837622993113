export namespace ActionTypes {
    export const FETCH_LOGIN_START = 'FETCH_LOGIN_START';
    export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
    export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';
    export const LOG_OUT = 'LOG_OUT';
};

export const initialState = {
	isAuth: false,
	isError: false,
    isLoading: false,
	userData: {
		token: '',
		user: {
			role: 'guest'
		}
	},
	errorMessage: ''
};

export const authorization = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_LOGIN_START:
			return {
				...state,
				isAuth: false,
				isLoading: true
			};
		case ActionTypes.FETCH_LOGIN_SUCCESS:
			return {
				...state,
				isAuth: true,
				isLoading: false,
				userData: payload,
				errorMessage: '',
				isError: false,
			};
		case ActionTypes.FETCH_LOGIN_FAILURE:
			return {
				...state,
				isAuth: false,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		case ActionTypes.LOG_OUT:
			localStorage.removeItem('token');

			return { 
				...initialState,
				isLoading: false
			 };
		default:
			return state;
	}
};
