import React from "react";
import SystemInfiTemporaryDepositList from './system-info-temporary-deposit-links-main';
import SystemInfiTemporaryDepositItem from './system-info-temporary-deposit-links-view';

export const SystemInfiBuyerLogs = () => {
    const [view, setView] = React.useState({id: 1, point: 'main'})
    
    return(
        <>
            { view.point === 'main' && <SystemInfiTemporaryDepositList setView={setView} />}
            { view.point === 'view' && <SystemInfiTemporaryDepositItem setView={setView} view={view} />}
        </>
    )
} 

export default SystemInfiBuyerLogs;