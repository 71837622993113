import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const SearchSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 22 22"
            onClick={onClick}
        >
            <circle cx="9.73088" cy="9.73088" r="6.19231" stroke={fill} strokeWidth="2" fill="none"/>
            <path d="M14.3848 14.3845L19.4617 19.4614" stroke={fill} strokeWidth="2"/>        
        </WrapperTag>
    );
};

SearchSvg.defaultProps = {
    fill: '#979799',
};
