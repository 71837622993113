import baseApi from './axiosParams';
import queryString from 'query-string';
import baseApiDownload from './axiosParamsDownload';

const path = 'api/transactions';

const Transactions = {
	getTransactions: (params = {}) => {
        const query = Object.keys(params).length
            ? `?${queryString.stringify(params)}`
            : '';

		return baseApi.get(`${path}${query}`)
	},	
	getShortView: (id) => {
		return baseApi.get(`${path}/short_view/${id}`)
	},
	getView: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
    updateStatus: (id) => {
        return baseApi.get(`${path}/update_status/${id}`)
    },
    setNotify: (id, body) => {
        return baseApi.post(`${path}/notify/${id}`, body)
    },
    getTransactionRequests: (params) => {
        const query = Object.keys(params).length
            ? `?${queryString.stringify(params)}`
            : '';

        return baseApi.get(`api/transaction_requests${query}`)
    },
    getServerTime: () => {
        return baseApi.get('api/external/date')
    },
    getStatusesList: () => {
        return baseApi.get(`${path}/status/dropdown_list`)
    },
    getNotifyStatusesList: () => {
        return baseApi.get(`${path}/notify_status/dropdown_list`)
    },
    getTransactionsAmount: () => {
		return baseApi.get(`${path}/amount`)
	},
    getPaymentMethodsList: () => {
        return baseApi.get(`${path}/payment_method/dropdown_list`)
    },
    downloadTable: (params) => {
		const query = Object.keys(params).length
		? `?${queryString.stringify(params)}`
		: '';
		return baseApiDownload.get(`${path}/download${query}`)
	}
}

export default Transactions;