import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import {Button} from '../../components/button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import { Loader } from '../../components/loaders/loader'
import { 
    hdbkItemRequest,
    hdbkUpdate,
} from "../../actions/systenInfoHdbkRequestTypes";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { Input } from '../../components/input';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import { hdbkItem } from "../../reducers/hdbkRequestType";

const PageContainer = styled('div')(({theme}) => (` 
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxEdit = styled(Box)(({theme}) => (`
    boeder: 1px solid red;
    padding: 24px;  
    margin-bottom: 50px;    
`));

const WrappBox = styled(Box)(({theme}) => (`
    padding-bottom: 40px;
`));

type valueType = {
    id: number, 
    text: string,
}

interface PropType {
    hdbkItemRequest: (id: number) => void,
    hdbkUpdate: (id: number, data: valueType) => any,
    hdbkItem: hdbkItem,
    isLoading: boolean,
    setView: any, 
    view: {id: number, point: string},
    error: boolean,
    errorMessage: string,
}

export const StatementsEdit = (props: PropType) => {

    const {
        hdbkItemRequest,
        hdbkUpdate,
        hdbkItem,
        isLoading,
        setView, 
        view,
        error,
        errorMessage
    } = props

    const [values, setValues] = React.useState<valueType>({
        id: hdbkItem.id,
        text: hdbkItem.text,
    });

    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);

    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {return}

        setOpenSnackbar(false);
    };

    useEffect(() => {
        hdbkItemRequest(view.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = (way) => () => {
        setView(prev => ({...prev, point: way}))
    }

    const handleChangeValue = (prop) => (e) => {
        setValues(prev => ({...prev, [prop]: e.target.value}))
    }

    const handleClickSave = () => {
        const {id, text} = values;
        const data = {
            "id": +id,  
            "text": text,
        }
        
        hdbkUpdate(view.id, data).then(() => {        
            setView((prev) => {return {...prev, id: id}})
            handleOpenSnackbar()
        })
    }

    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation('view')}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            Update Hdbk Requests Types: {hdbkItem.id}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
                <ShortViewHeaderRight>
                    <Button onClick={handleClickSave}>
                        <SaveIcon sx={{marginRight:'8px',fontSize: '20px'}} />
                        save
                    </Button>
                    <Button 
                        onClick={handleClickNavigation('main')}
                        variant='outlined'
                    >
                        {/* <SaveIcon sx={{marginRight:'8px',fontSize: '20px'}} /> */}
                        close
                    </Button>
                </ShortViewHeaderRight>
            </ShortViewHeader>

            <WrappBox>
                <BoxEdit component={Paper} sx={{ typography: 'body1' }}>
                    <Input 
                        label="Id" 
                        value={values.id ? values.id : ''} 
                        sx={{marginBottom: '16px'}} 
                        onChange={handleChangeValue('id')}
                    />
                    <Input 
                        label="Description" 
                        value={values.text ? values.text : ''} 
                        onChange={handleChangeValue('text')}
                    />
                </BoxEdit>
            </WrappBox>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                {error ?
                <InfoPopup
                    severity="error"
                    headerText="Hdbk request type not edited"
                    infoText={errorMessage ? errorMessage : "Something went wrong. Please try again later"}
                /> :
                <InfoPopup
                    severity="success"
                    headerText="Hdbk request type was edited"
                />}
            </Snackbar>

            {isLoading && <Loader/>}

        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        hdbkItem: state.hdbkRequestType.hdbkItem,
        isLoading: state.hdbkRequestType.isLoading,
        error: state.hdbkRequestType.isError,
        errorMessage: state.hdbkRequestType.errorMessage
    }), {
        hdbkItemRequest,
        hdbkUpdate,
    }),
)(StatementsEdit)