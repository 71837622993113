import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/external/wallet_services';

const Wallets = {
    getWallets: (params = {}) => {
        const query = Object.keys(params).length
            ? `?${queryString.stringify(params)}`
            : '';

        return baseApi.get(`${path}/history${query}`);
    },
    createWallet: (data) => {
        return baseApi.post(`${path}`, data);
    }
};

export default Wallets;