import React from "react";
import {useTheme} from "@mui/material/styles";
import {isJson} from '../../utils';
import ReactJson from 'react-json-view';

type PropsType = {
    data: string,
}

export const JsonDataComponent: React.FC<PropsType> = ({data}) => {
    const theme = useTheme();
    let bgJson = theme.palette.mode === 'dark' ? {backgroundColor: '#272930'} : null;
    const jsonTheme = theme.palette.mode === 'light' ? 'bright:inverted' : 'eighties';

    return (
        <>
            { isJson(data) && 
                <ReactJson 
                    src={ isJson(data) ? JSON.parse(data) : ['Invalid JSON']}
                    style={bgJson}
                    theme={jsonTheme}  
                    collapsed={true}     
                />
            }
            {
                !isJson(data) && `${data}`
            }
        </>

    )
}