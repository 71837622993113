export namespace ActionTypes {
    export const FETCH_USERSALL_START = 'FETCH_USERSALL_START';
    export const FETCH_USERSALL_SUCCESS = 'FETCH_USERSALL_SUCCESS';
    export const FETCH_USERSALL_FAILURE = 'FETCH_USERSALL_FAILURE';
	export const FETCH_ITEM_USERSALL_SUCCESS = 'FETCH_ITEM_USERSALL_SUCCESS';
	export const FETCH_CHANGE_PASSWORD_SUCCESS = 'FETCH_CHANGE_PASSWORD_SUCCESS';
	export const FETCH_CHANGE_PASSWORD_FAILURE = 'FETCH_CHANGE_PASSWORD_FAILURE';
    export const FETCH_USERSALL_ROLES_SUCCESS = 'FETCH_USERSALL_ROLES_SUCCESS';
    export const FETCH_USERSALL_ROLES_FAILURE = 'FETCH_USERSALL_ROLES_FAILURE';
    export const FETCH_USERSALL_ASSIGMENTS_SUCCESS = 'FETCH_USERSALL_ASSIGMENTS_SUCCESS';
    export const FETCH_USERSALL_ASSIGMENTS_FAILURE = 'FETCH_USERSALL_ASSIGMENTS_FAILURE';
	export const FETCH_CHANGE_ASSIGMENTS_SUCCESS = 'FETCH_USERSALL_ASSIGMENTS_SUCCESS';
    export const FETCH_CHANGE_ASSIGMENTS_FAILURE = 'FETCH_CHANGE_ASSIGMENTS_FAILURE';
};

type userItem = {
	'@id': string,
	'@type': string,
	emailConfirmed: number,
	id: number,
	registrationIp: string,
	roleNote: string,
	role: [],
	status: number,
	superadmin: number,
	username: string,
}

type usersList = {
	'@context': string;
	'@id': string;
	'@type': string,
	'hydra:member': userItem[];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

type usersItemDetails = {
	'@context': string,
	'@id': string,
	'@type': string,
	bindToIp: string,
	createdAt: string,
	updatedAt: string,
	emailConfirmed: number,
	id: number,
	roles: [],
	status: number,
	username: string,
}

interface UsersAll {
	isError: boolean,
    isLoading: boolean,
	usersList: usersList,
	errorMessage: string,
	usersItem: usersItemDetails,
	userRoles: any,
	userItemmAssigment: any,
}

export const initialState: UsersAll = {
	isError: false,
    isLoading: false,
	usersList: {
		'@context': '',
		'@id': '',
		'@type': '',
		'hydra:member': [
			{
				'@id': "",
				'@type': '',
				emailConfirmed: 0,
				id: 1,
				registrationIp: "195.201.145.251",
				roleNote: '',
				role: [],
				status: 1,
				superadmin: 0,
				username: 'badalalam.dabad',
			},
		],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	errorMessage: '',
	usersItem: {
		'@context': '',
		'@id': '',
		'@type': '',
		bindToIp: '10.10.10.10',
		createdAt: '2023-01-10 04:01:11',
		updatedAt: '2023-01-10 04:01:11',
		emailConfirmed: 0,
		id: 1,
		roles: [],
		status: 1,
		username: 'superadmin',
	},
	userRoles: {},
	userItemmAssigment: {
		roles: [{
			name: '',
			code: '',
			checked: false
		}],
		permissions: [{
			name: '',
			code: '',
			checked: false
		}],
	},
};

export const usersAll = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_USERSALL_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_USERSALL_SUCCESS:
			return {
				...state,
				usersList: payload,
				isLoading: false,
				errorMessage: '',
				isError: false
			};	
		case ActionTypes.FETCH_ITEM_USERSALL_SUCCESS:
			return {
				...state,
				usersItem: payload,
				isLoading: false,
				isError: false,
				errorMessage: ''
			};		
		case ActionTypes.FETCH_USERSALL_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		case ActionTypes.FETCH_CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				isError: false,
				errorMessage: ''
			};
		case ActionTypes.FETCH_CHANGE_PASSWORD_FAILURE:
			return {
				...state,
				isError: true,
				errorMessage: payload
			}
		case ActionTypes.FETCH_USERSALL_ROLES_SUCCESS:
			return {
				...state,
				userRoles: payload,
				isError: false,
				errorMessage: ''
			};
		case ActionTypes.FETCH_USERSALL_ROLES_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};	
		case ActionTypes.FETCH_USERSALL_ASSIGMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				userItemmAssigment: payload,
				isError: false,
				errorMessage: '',
			};	
		case ActionTypes.FETCH_USERSALL_ASSIGMENTS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload,
				userItemsAssigment: {
					roles: [{
						name: '',
						code: '',
						checked: false
					}],
					permissions: [{
						name: '',
						code: '',
						checked: false
					}],
				},
			};		
		case ActionTypes.FETCH_CHANGE_ASSIGMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};	
		case ActionTypes.FETCH_CHANGE_ASSIGMENTS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload,
			};	
		default:
			return state;
	}
};
