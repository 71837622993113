import React from "react";
import { styled } from "@mui/material/styles";
import {Button} from '../../components/button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Loader } from '../../components/loaders/loader';
import { 
    sysInfiBuyerLogRequest,
} from "../../actions/systemInfoBuyerLog";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import {format} from 'date-fns';
import {SerachTableHeader} from '../../components/search-table-header';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { buierItem } from "../../reducers/systemInfoBuierLog";
import { AccessDeniedAlert } from "../../components/access-denied";

const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '24px',
    }
});

const ActionsCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' ? '#272930' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '114px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const TableCellContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    minWidth: '130px'
});

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const WithSortedTableCellContent = styled(TableCellContent)(({ theme }) => (`
    position: relative;
    // padding-right: 30px;
    min-width: 120px;
    widt: 25%;
    box-sizing: border-box;
    justify-content: flex-start;
`));

const ButtonStyled = styled(Button)(({ theme }) => (`
    height: 30px;
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 185px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 195px - 56px);
        }; 
    }
`));

interface BuyerStateTypes{
    page: number,
    rowsPerPage: number,
    buyerId: string,
    remoteAddr: string,
    userAgent: string,
    browserHash: string,
    anchorElStatus: null | HTMLElement,
    openDeleteWindow: boolean,
    deleteId: number,
    statuses: string[] | number[],
}

type BuyerPropsType = {
    isLoading: boolean,
    buyerListCount: number,
    buyerList: buierItem[],
    sysInfiBuyerLogRequest: any,
    setView: any,
    routsUser: Array<any>, 
    userRoles: Array<any>, 
    isSuperadmin: any,
}

export class SystemInfiBuyerLogsMain extends React.Component<BuyerPropsType, BuyerStateTypes> {
    constructor(props: BuyerPropsType) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            buyerId: '',
            remoteAddr: '',
            userAgent: '',
            browserHash: '',
            anchorElStatus: null,
            openDeleteWindow: false,
            deleteId: 0,
            statuses: [],
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget})
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleClickOpenWindowDelete = () => {
        this.setState({openDeleteWindow: true})
    }
    handleClickCloseWindowDelete = () => {
        this.setState({openDeleteWindow: false})
    }
    handleOpenDeleteDialog = (id) => {
        this.setState({deleteId: id}, () => this.handleClickOpenWindowDelete())
    }

    onChangeFilter = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
    };

    handleChangeBuyer = (hash) => {
        const {setView} = this.props;
        setView((prev) => ({ ...prev, hash, point: 'view'}));
    }

    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/buyer-log/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    updateData = () => {

        const {
            page,
            buyerId,
            remoteAddr,
            userAgent,
            browserHash,
        } = this.state;

        const {
            sysInfiBuyerLogRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            'buyerId': buyerId,
            'remoteAddr': remoteAddr,
            'userAgent': userAgent,
            'browserHash': browserHash,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        sysInfiBuyerLogRequest(data);
    }

    render() {

        const {
            isLoading,
            buyerList, 
            buyerListCount, 
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props

        const {
            page,
            rowsPerPage,
            buyerId,
            remoteAddr,
            userAgent,
            browserHash,
        } = this.state;
        
        const accessedRole = routsUser.some(rout => rout.child.includes('/buyer-log/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleShowHash = routsUser.some(rout => rout.child === '/buyer-log/find-buyers-by-hash') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>Buyer logs</DepositMainHeaderText>
                            </DepositMainHeader>
            
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>
                                                
                                                    <WithSortedSerachTableHeader 
                                                        label='Buyer Id' 
                                                        handleSearchSubmit={this.handleSearchSubmit('buyerId')}
                                                        handleCancelSearch={this.handleCancelSearch('buyerId')}
                                                        isActiveFilter={Boolean(buyerId)}
                                                    />
                
                                                    <WithSortedSerachTableHeader 
                                                        label='Remote Addr' 
                                                        handleSearchSubmit={this.handleSearchSubmit('remoteAddr')}
                                                        handleCancelSearch={this.handleCancelSearch('remoteAddr')}
                                                        isActiveFilter={Boolean(remoteAddr)}
                                                    />

                                                    <WithSortedSerachTableHeader 
                                                        label='User Agent' 
                                                        handleSearchSubmit={this.handleSearchSubmit('userAgent')}
                                                        handleCancelSearch={this.handleCancelSearch('userAgent')}
                                                        isActiveFilter={Boolean(userAgent)}
                                                    />

                                                    {accessedRoleShowHash 
                                                        ? <WithSortedSerachTableHeader 
                                                            label='Browser Hash' 
                                                            handleSearchSubmit={this.handleSearchSubmit('browserHash')}
                                                            handleCancelSearch={this.handleCancelSearch('browserHash')}
                                                            isActiveFilter={Boolean(browserHash)}
                                                        />
                                                        : <TableCell>
                                                            Browser Hash
                                                        </TableCell>
                                                    }
                
                                                    <TableCell>
                                                        <WithSortedTableCellContent>
                                                            <div style={{width: '150px'}}>
                                                                Created At
                                                            </div>    
                                                        </WithSortedTableCellContent>
                                                    </TableCell>

                                                    {accessedRoleShowHash &&
                                                        <ActionsHeaderCell>
                                                            <StickyBorder>Action</StickyBorder>
                                                        </ActionsHeaderCell>
                                                    }
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(buyerList || []).map(row => {
                                                    return (
                                                        <TableRow key={Math.random()}>
                                                            <TableCell sx={{minWidth: '60px'}}>
                                                                {row.buyerId}
                                                            </TableCell>
                                                            <TableCell sx={{minWidth: '120px'}}>
                                                                {row.remoteAddr}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.userAgent}
                                                            </TableCell>
                                                            <TableCell sx={{minWidth: '120px'}}>
                                                                {row.browserHash 
                                                                    ? row.browserHash 
                                                                    : <span style={{color: '#c55', fontStyle: 'italic'}}>(not set)</span>
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {format(new Date(row.createdAt * 1000), 'yyyy-MM-dd HH:mm:ss')}
                                                            </TableCell>

                                                            {accessedRoleShowHash &&
                                                                <ActionsCell>
                                                                    <StickyBorder>
                                                                        <ActionsContent>                                                    
                                                                            <ButtonStyled
                                                                                size="small"
                                                                                onClick={() => this.handleChangeBuyer(row.browserHash)}
                                                                            >
                                                                                Buyer
                                                                            </ButtonStyled>
                                                                        </ActionsContent>
                                                                    </StickyBorder>
                                                                </ActionsCell>
                                                            }
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>                               
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={buyerListCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }
    
                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.systemInfoBuierLog.isLoading,
        buyerListCount: state.systemInfoBuierLog.systemInfoBuyerLog['hydra:totalItems'],
        buyerList: state.systemInfoBuierLog.systemInfoBuyerLog['hydra:member'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        sysInfiBuyerLogRequest,
    }),
)(SystemInfiBuyerLogsMain);
