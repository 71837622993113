import React from 'react';
import {routesMap} from '../../services/routers';
import {Routes, Route} from 'react-router-dom';
// import {NotFoundPage} from '../not-found-page';
import {MainWrap} from './main-wrap';
import {MainWrapNotFound} from './main-wrap-404';

export const MainPage: React.FC = () => {
    return (  
        <Routes>
            <Route path="/" element={<MainWrap />}>
                {
                    routesMap.map((route, index) => {
                        return (
                            <Route key={index} path={route.path} element={route.element} index={route.index} />
                        )
                    })
                }
            </Route>
            {/* <Route path="*" element={<NotFoundPage />}/> */}
            <Route path="*" element={<MainWrapNotFound />}/>
        </Routes>
    );
};
