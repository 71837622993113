import {ActionTypes} from '../reducers/queue';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_QUEUE_START
});

export const requestSuccess = (queueList) => {
	return {
		type: ActionTypes.FETCH_QUEUE_SUCCESS,
		payload: queueList
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_QUEUE_FAILURE,
		payload: error
	}
};

export const queuesRequest = data => dispatch => {
	dispatch(requestStart());
    
	return API.queue
		.queueList()
		.then(res => {
			dispatch(requestSuccess(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};
