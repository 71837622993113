export namespace ActionTypes {
    export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
    export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
    export const FETCH_SETTINGS_DELETE = 'FETCH_SETTINGS_DELETE';
    export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
    export const FETCH_SETTINGS_ITEM_REQUEST = 'FETCH_SETTINGS_ITEM_REQUEST';
};

export type SettingItem = {
    "@context": string,
    "@id": string,
    "@type": string,
    id: number,
    group: string,
    name: string,
    value: string,
    type: string,
    createdAt: number,
}

export type PPSSettingsItem = {
    "@id": string,
    "@type": string,
    id: number,
    group: string,
    name: string,
    value: string,
    type: string,
    createdAt: number,
    updatedAt: number
}

interface PPSSettings {
	isError: boolean,
    isLoading: boolean,
	settings: Array<PPSSettingsItem>,
	settingItem: SettingItem,
	errorMessage: string,
}

export const initialState: PPSSettings = {
	isError: false,
    isLoading: false,
	settings: [],
	settingItem: {
		"@context": "",
		"@id": "",
		"@type": "",
		id: 1,
		group: "",
		name: "",
		value: "",
		type: "",
		createdAt: 1671201987,
	},
	errorMessage: '',
};

export const settingsPPS = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_SETTINGS_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_SETTINGS_SUCCESS:
			return {
				...state,
				settings: payload,
				isLoading: false,
				errorMessage: false,
				isError: false
			};
		case ActionTypes.FETCH_SETTINGS_ITEM_REQUEST:
			return {
				...state,
				settingItem: payload,
				isLoading: false,
				errorMessage: false,
				isError: false
			};
		case ActionTypes.FETCH_SETTINGS_DELETE:
			return {
				...state,
			};			
		case ActionTypes.FETCH_SETTINGS_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
