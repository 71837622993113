export namespace ActionTypes {
    export const FETCH_VISIT_LOG_START = 'FETCH_VISIT_LOG_START';
    export const FETCH_VISIT_LOG_SUCCESS = 'FETCH_VISIT_LOG_SUCCESS';
    export const FETCH_VISIT_LOG_FAILURE = 'FETCH_VISIT_LOG_FAILURE';
	export const FETCH_ITEM_VISIT_LOG_SUCCESS = 'FETCH_ITEM_VISIT_LOG_SUCCESS';
};

type VisitItem = {
	'@id': string,
	'@type': string,
	browser: string,
	id: number,
	ip: string,
	language: string,
	os: string,
	user: {
		'@id': string,
		id: number,
		username: string,
	},
	createdAt: string,
}

type VisitLogList = {
	'@context': string;
	'@id': string;
	'hydra:member': VisitItem[];
	'hydra:search': any;
	'hydra:totalItems': number;
	'hydra:view': any;
}

type VisitItemDetails = {
	'@context': string;
	'@id': string;
	'@type': string;
	browser: string;
	ip: string;
	language: string;
	os: string;
	user: {
		'@id': string; 
		'@type': string; 
		username: string;
	}
	userAgent: string;
	visitTime: string;
}

interface VisitLog {
	isError: boolean,
    isLoading: boolean,
	visitsLog: VisitLogList,
	errorMessage: string,
	visitItem: VisitItemDetails,
}

export const initialState: VisitLog = {
	isError: false,
    isLoading: false,
	visitsLog: {
		'@context': '',
		'@id': '',
		'hydra:member': [
			{
				'@id': "",
				'@type': '',
				browser: "Chrome",
				id: 1,
				ip: "10.10.10.10",
				language: 'en',
				os: 'Windows',
				user: {
					'@id': '/api/users/1',
					id: 1,
					username: 'badalalam.dabad',
				},
				createdAt: '2038-01-19 03:01:14',
			},
		],
		'hydra:search': '',
		'hydra:totalItems': 123,
		'hydra:view': '',
	},
	errorMessage: '',
	visitItem: {
		'@context': '',
		'@id': '',
		'@type': '',
		browser: 'Webkit',
		ip: '10.10.10.10',
		language: 'en',
		os: 'Windows',
		user: {
			'@id': '1',
			'@type': 'User',
			username: 'badalalam.dabad'
		},
		userAgent: 'Webkit',
		visitTime: '2038-01-19 03:01:14',
	}
};

export const visitLogs = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_VISIT_LOG_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_VISIT_LOG_SUCCESS:
			return {
				...state,
				visitsLog: payload,
				isLoading: false,
			};	
		case ActionTypes.FETCH_ITEM_VISIT_LOG_SUCCESS:
			return {
				...state,
				visitItem: payload,
				isLoading: false,
			};		
		case ActionTypes.FETCH_VISIT_LOG_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
