import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import Structure from '../../components/structure';
import { InfoOutlineSvg } from "../../components/svg/info-outline-svg";
import {Input} from '../../components/input';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import {SearchSvg} from "../../components/svg/search-svg";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


import { Scrollbars } from 'react-custom-scrollbars-2';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";


import { Button } from '../../components/button';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {
    setCascadeListRequest, 
    getCascadeListRequest
} from '../../actions/mappingMultiaccounts';

import {Loader} from '../../components/loaders/loader';
import {isJson} from '../../utils';

import _ from 'lodash';

import Snackbar from '@mui/material/Snackbar';
import { InfoPopup } from "../../components/info-popup";
import EditMappingRules from './parts/edit-mapping-form-rules';
import { PaymentSystemItem } from  './parts/item-pyment-list';
import ResultAccountsListForEdit from './parts/result-accounts-list';
import Dialog from '@mui/material/Dialog';
import { NotifyDialog } from '../../components/notify-dialog';
import { NotifyDialogAlert } from '../../components/notify-dialog-alert';

import {convertTimeToSeconds, convertSecondsToTime} from '../../utils';
import { AccessDeniedAlert } from '../../components/access-denied';


const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const MappingPageHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const MappingPageHeader = styled('div')({
    display: 'flex',
    marginBottom: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    }
});

const WrapMappingPageContent = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
});

const MappingPageContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '484px',

    '@media(max-width: 768px)' : {
        width: '100%',
    }
});

const WrapMappingPageResultList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '484px',
    boxSizing: 'border-box',
    paddingRight: '30px',
    position: 'absolute',
    left: '520px',
    top: '0',

    '@media(max-width: 1260px)' : {
        position: 'static',
        left: '0',
        paddingTop: '20px',
    },

    '@media(max-width: 768px)' : {
        width: '100%',
    }
});

const WrapperLeftListPS = styled('div')({
    height: '308px',
    width: '100%',

    '@media(max-width: 1260px)' : {
        height: 'auto',
        maxHeight: '310px',
        overflow: 'auto',
    },
});

const MappingPageInfoMessage = styled('div')({
    marginTop: '16px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center'
});

const MappingPageInfoMessageText = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary
}));

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const CurrencyItem = styled('div')(({ theme }) => ({
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 12px',
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    width: '100%',

    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
    }
}));

const CurrencyItemIsSetText = styled('div')({
    color: '#38AA72'
});

const StyledSearchInput = styled('input')(({ theme }) => ({
    width: '100%',
    marginRight: '20px',
    boxSizing: 'border-box',
    height: '36px',
    paddingLeft: '20px',
    paddingRight: '38px',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : '#C0C0C0'}`,
    borderRadius: '4px',
    background: 'transparent',
    color: theme.palette.text.primary,
    outline: 'none !important',
    '&::placeholder': {
        opacity: '1',
        color: theme.palette.text.primary,
    },
    '&:focus': {
        borderColor: '#38AA72',
    }
}));

const SearchInputWrapper = styled('div')({
    position: 'relative',
    margin: '8px 8px 8px 8px'
});

const SearchInputIcon = styled('div')({
    padding: '4px',
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
});

const ListContainer = styled('div')({
    maxHeight: '320px',
});

const MappingPageOrder = styled('div')({
    marginTop: '32px'
});

const MappingPageOrderHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
});

export const MappingPageOrderHeaderText = styled('div')(({ theme }) => ({
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: '400',
    color: theme.palette.text.primary
}));

const MappingPageOrderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

const MappingPageAlert = styled('div')({
    margin: '32px 0px'
});

const StyledFormControl = styled(FormControl)(({ theme }) => (`
    margin-top: 20px;
    width: 100%;
    & label.Mui-focused{
        color: #38AA72;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
                border-color: #38AA72;
        }
    }
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const MappingPageAlertButtons = styled('div')({
    marginTop: '24px',
    display: 'flex',
    gap: '12px'
});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const MappingPageWarningText = styled('div')({
    color: '#ad3b3b',
    fontSize: '14px',
    fontWeight: '500',
});

const StyledDialog = styled(Dialog)(({ theme }) => (`
    &{
        .MuiDialog-paper{
            border-radius: 0;
        }
    }
`));

const StyleAlertString = styled('div')(({ theme }) => (`
    color: #ad3b3b;
    fint-size: 12px;
`));


const MappingPage = ({isLoading, cascadeList, getCascadeListRequest, setCascadeListRequest, activeNode, error, errorMessage, nameUser, userRoles, isSuperadmin}) => {
    const [cascadeListState, setCascadeListState] = useState(isJson(JSON.parse(cascadeList)) ? JSON.parse(JSON.parse(cascadeList)) : JSON.parse(cascadeList));
    // eslint-disable-next-line
    const [isEdited, setIsEdited] = useState(false);

    const [anchorElCurrencySelect, setAnchorElCurrencySelect] = React.useState<null | HTMLElement>(null);
    const isCurrencySelectOpen = Boolean(anchorElCurrencySelect);
    const handleCurrencySelectOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElCurrencySelect(event.currentTarget);
    };

    const handleCurrencySelectClose = () => {
        setAnchorElCurrencySelect(null);
    };

    const [searchValue, setSearchValue] = useState('');
    const handleChangeSearchValue = event => {
        setSearchValue(event.target.value);
    };

    const [openRules, setOpenRules] = useState<any>({item: {}, isOpen: false});
    const handleOpenRules = (item) => {
        setOpenRules(prev => ({item: item, isOpen: true}))
    };

    const handleCloseRules = () => setOpenRules(prev => ({item: {}, isOpen: false}));

    const [way, setWay] = useState('withdraw');
    const handleChangeWay = (event) => {
        setWay(event.target.value);
        setSelectedCurrency('');
        setListChangedEl([]);
        setListMethods([]);
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState({successText: '', errorText: ''});
    const handleOpenSnackbar = (successText, errorText) => {
        setSnackbarText({successText, errorText});
        setOpenSnackbar(true);
    };
    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const getCurrenciesList = () => {
        if (!cascadeListState || !cascadeListState[way]) {
            return [];
        };

        return Object.keys(cascadeListState[way]).map(key => {
            const rules = cascadeListState[way][key].rules;
            return ({
                currency: key,
                isSet: rules?.availability || rules?.sum_limit ? true : false
            });
        });
    };

    const [isChangeCurrencyCurrent, setIsChangeCurrencyCurrent] = useState(false);
    const handleCloseChangeCurrencyCurrentPopup = () => {
        setIsChangeCurrencyCurrent(false);
        handleCurrencySelectClose();
    }
    const handleOpenChangeCurrencyCurrentPopup = () => {
        setIsChangeCurrencyCurrent(true);
    }

    const handleConfirmedChangeCurrencyCurrentPopup = () => {
        setSelectedCurrency(selectedCurrencyPrev)
        handleCloseChangeCurrencyCurrentPopup();
        if( JSON.parse(cascadeList).current_cascade?.[way]?.[selectedCurrencyPrev]){
            const cacadesCurrent = JSON.parse(cascadeList).current_cascade?.[way]?.[selectedCurrencyPrev];
            setListMethods(cacadesCurrent);
            setListChangedEl(prev => {
                const listDisabledCascades = cacadesCurrent.map(data => {
                    if(data.hasOwnProperty('accname')) return `${data.currency}${data.psname}${data.accname}${data.name}`
                    return `${data.currency}${data.psname}${data.name}`
                });
                return listDisabledCascades;
            });
        } else {
            setListChangedEl([]);
            setListMethods([]);
        }
    }

    const [selectedCurrencyPrev, setSelectedCurrencyPrev] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const handleSelectCurrency = (currency) => {
        if(listMethods.length > 0){
            if( !_.isEqual(listMethods, JSON.parse(cascadeList).current_cascade[way][selectedCurrency]) ){
                // No equal
                handleOpenChangeCurrencyCurrentPopup();
                setSelectedCurrencyPrev(currency);
            }else{
                // Equal
                setSelectedCurrency(currency)
                handleCloseChangeCurrencyCurrentPopup();
                
                if( JSON.parse(cascadeList).current_cascade?.[way]?.[currency]){
                    const cacadesCurrent = JSON.parse(cascadeList).current_cascade?.[way]?.[currency];
                    setListMethods(cacadesCurrent);
                    setListChangedEl(prev => {
                        const listDisabledCascades = cacadesCurrent.map(data => {
                            if(data.hasOwnProperty('accname')) return `${data.currency}${data.psname}${data.accname}${data.name}`
                            return `${data.currency}${data.psname}${data.name}`
                        });
                        return listDisabledCascades;
                    });
                } else {
                    setListChangedEl([]);
                    setListMethods([]);
                }
            }
            // handleOpenChangeCurrencyCurrentPopup();
            // setSelectedCurrencyPrev(currency);
        }else{
            setSelectedCurrency(currency);
            handleCurrencySelectClose();

            if( JSON.parse(cascadeList).current_cascade?.[way]?.[currency]){
                const cacadesCurrent = JSON.parse(cascadeList).current_cascade?.[way]?.[currency];
                setListMethods(cacadesCurrent);
                setListChangedEl(prev => {
                    const listDisabledCascades = cacadesCurrent.map(data => {
                        if(data.hasOwnProperty('accname')) return `${data.currency}${data.psname}${data.accname}${data.name}`
                        return `${data.currency}${data.psname}${data.name}`
                    });
                    return listDisabledCascades;
                });
            } 
        }
    };

    const [shouldDeleteRule, setShouldDeleteRule] = useState(false);

    useEffect(() => {
        if(nameUser === 'wallets01' || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            getCascadeListRequest().then(() => handleCancel());
            setSearchValue('');
            setSelectedCurrency('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeNode, getCascadeListRequest]);

    useEffect(() => {
        setCascadeListState(isJson(JSON.parse(cascadeList)) ? JSON.parse(JSON.parse(cascadeList)) : JSON.parse(cascadeList));
    }, [cascadeList]);

    useEffect(() => {
        if (shouldDeleteRule) {
            // Disabled Rules Alert
            setCascadeListRequest(JSON.stringify(cascadeListState)).then(() => handleOpenSnackbar('Rule deleted', 'Rule not deleted'));
            setShouldDeleteRule(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cascadeListState]);

    const [isResetAll, setIsResetAll] = useState(false)
    const handleOpenAlertResetTable = () => {
        setIsResetAll(true)
    }

    const handleCloseAlertResetTable = () => {
        setIsResetAll(false)
    }

    const handleClearTable = () => {
        handleCancel();
        setCascadeListRequest({command: 'delete'}).then(() => handleOpenSnackbar('Clear','Not clear'));
        handleCloseAlertResetTable();
    }

    const [telegram, setTelegram] = useState(cascadeList.hasOwnProperty('data') ? 0 : cascadeListState?.notification?.telegram);
    const [isEditedTelegram, setSsEditedTelegram] = useState(false);
    const handleChangeTelegram = (event) => {
        const value = event.target.value;
        setTelegram(value);
        setSsEditedTelegram(true);
    };

    const timeRegExp = /^(\d+)?:?(\d+)?$/;
    const [time, setTime] = useState(cascadeList.hasOwnProperty('data') ? convertSecondsToTime(0) : convertSecondsToTime(cascadeListState?.reset_time));

    const [isValidTime, setIsValidTime] = useState(true);
    const [isEditedTime, setIsEditedTime] = useState(false);
    const handleChangeTime = (event) => {
        const value = event.target.value;
        const isValidFormat = timeRegExp.test(value); 
        if (isValidFormat) setTime(value);
    };
    const handleBlurTime = () => {
        const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
        setIsValidTime(regex.test(time));
        if(regex.test(time)){
            setIsEdited(true);
            setIsEditedTime(true)
        }else{
            setIsEdited(false);
        }
    };

    const [listChangedEl, setListChangedEl] = useState([])
    const [listMethods, setListMethods] = useState([]);

    const handleAddResult = (data) => {
        setListMethods(prev => [...prev, data])
        setListChangedEl(prev => {
            if(data.hasOwnProperty('accname')) return [...prev, `${data.currency}${data.psname}${data.accname}${data.name}`]
            return [...prev, `${data.currency}${data.psname}${data.name}`]
        });
    }

    const handleDeleteAddedItem = (indexDell, itemEl) => {
        setListMethods(prev => prev.filter((item, index) => index !== indexDell))
        setListChangedEl(prev => {
            if(itemEl.hasOwnProperty('accname')) return prev.filter(item => item !== `${itemEl.currency}${itemEl.psname}${itemEl.accname}${itemEl.name}`)
            return prev.filter(item => item !== `${itemEl.currency}${itemEl.psname}${itemEl.name}`)
        })
    }

    const handleClearStates = () => {
        setCascadeListState(isJson(JSON.parse(cascadeList)) ? JSON.parse(JSON.parse(cascadeList)) : JSON.parse(cascadeList));
        setIsEdited(false);
        setSsEditedTelegram(false);
        setTelegram(JSON.parse(cascadeList).hasOwnProperty('data') ? 0 : JSON.parse(cascadeList)?.notification?.telegram);
        setIsEditedTime(false);
        setTime(JSON.parse(cascadeList).hasOwnProperty('data') ? convertSecondsToTime(0) : convertSecondsToTime(JSON.parse(cascadeList)?.reset_time));
        setIsValidTime(true);
    }

    const handleCancel = () => {
        handleClearStates();
        setListChangedEl([]);
        setListMethods([]);
        setSelectedCurrency('')
    };

    const [onShowDragList, setOnShowDragList] = useState(true);
    const handleToggleList = () =>  setOnShowDragList(prev => !prev)
    const hendleSaveAllRules = (editedState) => {
        setListMethods(prev => {
            handleToggleList()
            return editedState
        })
        setTimeout(() => {handleToggleList()}, 1)
    }

    const checkPreApply = () => {
        if(Boolean(listChangedEl.length)){
            if(isValidTime){
                return false
            }            
        }
        if(isEditedTime){
            if(isValidTime){
                return false
            }
        }
        if(isEditedTelegram){
            if(isValidTime){
                return false
            }
        }
        return true
    }

    const isDisabledApplay = () => {
        return checkPreApply();
    }

    if(!_.isEqual(cascadeListState, JSON.parse(cascadeList))){
        if(!Boolean(selectedCurrency)){
            handleClearStates();
        }
    }

    const [isHasLimit, setIsHasLimit] = useState(false);
    const handleIsHasSumLimit = () => setIsHasLimit(false);

    const handleApply = () => {
        const listMethodsClear = JSON.parse(JSON.stringify(listMethods))
        listMethodsClear.forEach(item => {
            if(item.hasOwnProperty('methods')){delete item['methods']}
        });

        const data = {
            [way]: {[selectedCurrency]: listMethodsClear},
            notification: {"telegram": telegram,},
            reset_time: convertTimeToSeconds(time),
        }

        if(!selectedCurrency) {
            data.hasOwnProperty('withdraw') ? delete data['withdraw'] : delete data['deposit'];
        }

        if( listMethodsClear.every(item => item.rules?.sum_limit > 0) ){
            setCascadeListRequest(JSON.stringify(data)).then(() => {
                handleOpenSnackbar('Multiaccounts list saved successfully', 'Multiaccounts list not saved');
                getCascadeListRequest()
            });
        } else {
            setIsHasLimit(true);
        }
    };

    const accessedUserOnly = nameUser === 'camelot' || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
 
    return (
        <PageContainer>

            {!accessedUserOnly && <AccessDeniedAlert/>} 

            {accessedUserOnly && 
                <>
                    <MappingPageHeader>
                        <MappingPageHeaderText>Multiaccounts _ v2</MappingPageHeaderText>
                        <Structure/>
                    </MappingPageHeader>

                    <WrapMappingPageContent>
                        <MappingPageContent>
                            <MappingPageInfoMessage>
                                <InfoOutlineSvg size="16px"/>
                                <MappingPageInfoMessageText>
                                    Select a way and a currency to manage mapping
                                </MappingPageInfoMessageText>
                            </MappingPageInfoMessage>
                            <StyledFormControl fullWidth>
                                <InputLabel size="small">Way</InputLabel>
                                <Select
                                    value={way}
                                    label="Way"
                                    onChange={handleChangeWay}
                                    size='small'
                                    disabled={cascadeListState.hasOwnProperty('data')}
                                >
                                    <StyledMenuItem value="deposit">Deposit</StyledMenuItem>
                                    <StyledMenuItem value="withdraw">Withdraw</StyledMenuItem>
                                </Select>
                            </StyledFormControl>
                            <Input
                                value={selectedCurrency}
                                label="Currency"
                                readOnly
                                sx={{
                                    marginTop: '24px', 
                                    caretColor: 'transparent', 
                                    input: {cursor: 'pointer'}, 
                                    '& .MuiInputBase-root': {
                                        cursor: 'pointer',
                                    }, 
                                    '& .MuiSvgIcon-root': {
                                        position: 'absolute', 
                                        right: '8px',
                                        opacity: cascadeListState.hasOwnProperty('data') ? '.3' : '1',
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {anchorElCurrencySelect ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </InputAdornment>
                                    )
                                }}
                                onClick={handleCurrencySelectOpen}
                                autoComplete="off"
                                disabled={cascadeListState.hasOwnProperty('data')}
                            />
                            <StyledMenu
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorElCurrencySelect}
                                open={isCurrencySelectOpen}
                                onClose={handleCurrencySelectClose}
                                PaperProps={{
                                    style: {
                                        width: '484px',
                                        paddingBottom: '0px'
                                    },
                                }}
                            >
                                <SearchInputWrapper>
                                    <StyledSearchInput placeholder="Search" value={searchValue} onChange={handleChangeSearchValue}/>
                                    <SearchInputIcon>
                                        <SearchSvg size="22px"/>
                                    </SearchInputIcon>
                                </SearchInputWrapper>
                                <ListContainer>
                                    <List sx={{padding: '0px', maxHeight: '320px'}}>
                                        <Scrollbars autoHeight>
                                            {getCurrenciesList().filter(item => item.currency.includes(searchValue.toUpperCase())).map(item => {
                                                return (
                                                    <ListItem sx={{padding: '0px'}} key={item.currency}>
                                                        <CurrencyItem onClick={() => handleSelectCurrency(item.currency)}>
                                                            <div>
                                                                {item.currency}
                                                            </div>
                                                            <CurrencyItemIsSetText>
                                                                {item.isSet && 'rule set'}
                                                            </CurrencyItemIsSetText>
                                                        </CurrencyItem>
                                                    </ListItem>
                                                );
                                            })}
                                        </Scrollbars>
                                    </List>
                                </ListContainer>
                            </StyledMenu>

                            <MappingPageOrder>
                                <MappingPageOrderHeader>

                                    {cascadeListState.hasOwnProperty('data')
                                        ? <MappingPageOrderHeaderText> There is no multiaccounts data for this merchant. </MappingPageOrderHeaderText> 
                                        : <>
                                            <MappingPageOrderHeaderText>
                                                Payment systems
                                            </MappingPageOrderHeaderText>
                                        </>
                                    }

                                </MappingPageOrderHeader>
                                <WrapperLeftListPS >
                                    <MappingPageOrderContainer sx={{maxHeight: '300px'}}>
                                        <OverlayScrollbarsComponent
                                            className='overlay-scroll-table'
                                            options={{
                                                scrollbars: { visibility: 'visible' } 
                                            }}
                                        >
                                            {cascadeListState?.[way]?.[selectedCurrency]?.map((item, index) => {
                                                return (
                                                    <PaymentSystemItem 
                                                        addResult={handleAddResult}
                                                        key={Math.random()}
                                                        pSystem={item}
                                                        listException={listChangedEl}
                                                        currency={selectedCurrency}
                                                    />
                                                );
                                            })}
                                        </OverlayScrollbarsComponent>
                                    </MappingPageOrderContainer>
                                </WrapperLeftListPS>
                                
                                    <WrapMappingPageResultList>
                                        <ResultAccountsListForEdit
                                            openDrag={setListMethods} // !
                                            methods={listMethods}
                                            onDelete={handleDeleteAddedItem}
                                            openEditor={handleOpenRules}
                                            setEditListState={setListMethods}
                                            rerenderList={onShowDragList}
                                            setRerenderList={setOnShowDragList}
                                        />
                                    </WrapMappingPageResultList>
                            </MappingPageOrder>

                            <MappingPageAlert>

                                <MappingPageInfoMessage>
                                    <InfoOutlineSvg size="16px"/>
                                    <MappingPageInfoMessageText>
                                        Change date in format "00:00"
                                    </MappingPageInfoMessageText>
                                </MappingPageInfoMessage>

                                <Input
                                    label="Time to reset all cascades" 
                                    sx={{marginTop: '24px'}}
                                    onChange={handleChangeTime}
                                    onBlur={handleBlurTime}
                                    value={time}
                                    disabled={cascadeListState.hasOwnProperty('data')}
                                    placeholder="00:00"
                                    InputProps={{
                                        autoComplete: 'off',
                                        form: {
                                        autoComplete: 'off',
                                        },
                                    }}
                                />
                                {!isValidTime && <StyleAlertString>Incorrect time format</StyleAlertString>}

                                <Input
                                    placeholder="Enter telegram"
                                    label="Telegram"
                                    sx={{marginTop: '24px'}}
                                    onChange={handleChangeTelegram}
                                    value={telegram || ""}
                                    disabled={cascadeListState.hasOwnProperty('data')}
                                    InputProps={{
                                        autoComplete: 'off',
                                        form: {
                                        autoComplete: 'off',
                                        },
                                    }}
                                />
                                
                                {!isDisabledApplay() && (
                                    <MappingPageInfoMessage>
                                        <InfoOutlineSvg size="16px"/>
                                        <MappingPageWarningText>
                                            To save changes press APPLY button
                                        </MappingPageWarningText>
                                    </MappingPageInfoMessage>
                                )}
                                <MappingPageAlertButtons>
                                    <Button 
                                        onClick={handleApply}
                                        disabled={isDisabledApplay()}
                                    >
                                        Apply
                                    </Button>
                                    <Button 
                                        variant='outlined' 
                                        // disabled={isDisabledApplay()} 
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant='outlined' 
                                        sx={{marginLeft: 'auto'}} 
                                        onClick={handleOpenAlertResetTable}
                                    >
                                        Reset All
                                    </Button>
                                </MappingPageAlertButtons>
                            </MappingPageAlert>
                        </MappingPageContent>
                    </WrapMappingPageContent>

                    <StyledDialog
                        open={isHasLimit}
                        onClose={handleIsHasSumLimit}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <NotifyDialogAlert
                            title={``}
                            cancelButtonText='Ok'
                            onClose={handleIsHasSumLimit}
                            width="400px"
                            isDeleteButton={false}
                        >
                            All limit amounts must be greater than "0"
                        </NotifyDialogAlert>  
                    </StyledDialog>

                    <Modal
                        open={openRules.isOpen}
                        onClose={handleCloseRules}
                    >
                        <StyledBoxPopup>
                            <EditMappingRules
                                cascadeListState={cascadeListState}
                                setCascadeListState={setCascadeListState}
                                way={way}
                                currency={selectedCurrency}
                                setIsEdited={setIsEdited}
                                onClose={handleCloseRules}

                                methods={listMethods}
                                itemEl={openRules.item}
                                onSaveAll={hendleSaveAllRules}
                            />
                        </StyledBoxPopup>
                    </Modal>

                    <StyledDialog
                        open={isResetAll}
                        onClose={handleCloseAlertResetTable}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <NotifyDialog
                            title={`Clear cascades`}
                            confirmButtonText='Clear'
                            cancelButtonText='CANCEL'
                            onClose={handleCloseAlertResetTable}
                            width="400px"
                            isDeleteButton
                            onCancel={handleCloseAlertResetTable}
                            onConfirm={handleClearTable}
                        >
                            The list of payments, accounts and methods will be updated, but the current cascade will be completely reset. Are you sure?
                        </NotifyDialog>  
                    </StyledDialog>

                    <StyledDialog
                        open={isChangeCurrencyCurrent}
                        onClose={handleCloseChangeCurrencyCurrentPopup}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <NotifyDialog
                            title={`Confirm change currency`}
                            confirmButtonText='Change'
                            cancelButtonText='CANCEL'
                            onClose={handleCloseChangeCurrencyCurrentPopup}
                            width="400px"
                            isDeleteButton
                            onCancel={handleCloseChangeCurrencyCurrentPopup}
                            onConfirm={handleConfirmedChangeCurrencyCurrentPopup}
                        >
                            If you do not save changes for the current currency, they will be deleted
                        </NotifyDialog>  
                    </StyledDialog>
                    
                    <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                        {error ?
                            <InfoPopup
                                severity="error"
                                headerText={snackbarText.errorText}
                                infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                            /> :
                            <InfoPopup
                                severity="success"
                                headerText={snackbarText.successText}
                            />
                        }
                    </Snackbar>
                    {/* <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                        <InfoPopup
                            severity="success"
                            headerText={snackbarText.successText}
                        />
                    </Snackbar> */}
                </>
            }
            {isLoading && <Loader />}
        </PageContainer>
    );
};


export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.mappingMultiaccounts.isLoading,
        cascadeList: state.mappingMultiaccounts.cascadeList,
        activeNode: state.nodeManagement.activeNode,
        error: state.mappingMultiaccounts.isError,
        errorMessage: state.mappingMultiaccounts.errorMessage,
        nameUser: state.currentUser.userData.username,
        userRoles: state.currentUser.userData.roles,
	    isSuperadmin: state.currentUser.userData.superadmin,

    }), {
        getCascadeListRequest, 
        setCascadeListRequest
    })
)(MappingPage);
