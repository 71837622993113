import {defaultZones} from '../services/defaultTimezones';
export namespace actionTypes {
    export const SET_TIMEZONE = 'timezone.ts/SET_TIMEZONE'
    export const GET_TIMEZONES = 'timezone.ts/GET_TIMEZONES'
    export const FAILYRE_TIMEZONE = 'timezone.ts/GET_TIMEZONE'
}

const initialState = {
	isError: false,
    isLoading: false,
	currentTimeZone: '(UTC+00:00) UTC',
	listTimezone: defaultZones,
};

export const timeZone = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_TIMEZONE:
			return { 
				...state, 
				currentTimeZone: payload,
			};
		case actionTypes.GET_TIMEZONES:
			return { 
				...state, 
				listTimezone: payload 
			};
		case actionTypes.FAILYRE_TIMEZONE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};


