import axios from 'axios';
import { baseURL } from './_axiosBaseUrl';

const baseApiDownload = {
	get: async (path) => {
		return request({
			path,
			method: 'GET',
			body: undefined
		});
	},
};

const handleResponse = (response) => {
	return response
		.then(res => res)
		// .catch(error => {throw error});
		.catch(error => {
			console.log(error)
		});
};

const request = async ({ path, method, body }) => {
	const resp = axios({
		withCredentials: true,
		responseType: 'blob',
		timeout: 300000,
		method: method,
		url: `${baseURL}${path}`,
		data: method !== 'GET' ? body : undefined,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': method === 'PATCH' ? 'application/merge-patch+json' :
							method === 'POST'  ? 'application/ld+json' : 'application/json',
		},
	});
	return handleResponse(resp);
};

export default baseApiDownload;
