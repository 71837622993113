import React from 'react';
import { styled } from "@mui/material/styles";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Button} from '../../components/button';
import {Input} from '../../components/input';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import { AccessDeniedAlert } from '../../components/access-denied';

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const TestApiPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '40px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap',
        marginBottom: '0px'
    }
});

const TestApiPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '350px',
    marginRight: '12px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },

    '@media(max-width: 768px)' : {
        width: '100%',
        marginRight: '0px',
        marginTop: '20px'
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const TestApiPageFirstRow = styled('div')({
    marginBottom: '28px',
    display: 'flex',
    flexWrap: 'wrap'
});

const TestApiPageSecondRow = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '28px',

    '@media(max-width: 768px)' : {
        flexDirection: 'column',
        alignItems: 'flex-start'
    }
});

const TestApiPageThirdRow = styled('div')({
    display: 'flex',

    '@media(max-width: 768px)' : {
        flexDirection: 'column',
    }
});

const TestApiPageTransactionBlock = styled('div')({
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column'
});

const TransactionTitle = styled('div')(({ theme }) => ({
    fontSize: '22px',
    fontWeight: '500',
    color: theme.palette.text.primary,

}));

const ButtonContainer = styled('div')({
    '@media(max-width: 768px)' : {
       marginTop: '16px'
    }
});

const StyledInput = styled(Input)({
    marginRight: '16px',
    marginTop: '0px',
    marginBottom: '0px',

    '@media(max-width: 768px)' : {
        marginTop: '16px',
        marginRight: '0px',
    }
})

export const TestApiPage = ({        
    routsUser,
    userRoles,
    isSuperadmin,
}) => {

    const [merchant, setMerchant] = React.useState('');
    const handleChangeMerchant = (event: SelectChangeEvent) => {
        setMerchant(event.target.value as string);
    };

    const [paymentSystem, setPaymentSystem] = React.useState('');
    const handleChangePaymentSystem = (event: SelectChangeEvent) => {
        setPaymentSystem(event.target.value as string);
    };

    const [way, setWay] = React.useState('');
    const handleChangeWay = (event: SelectChangeEvent) => {
        setWay(event.target.value as string);
    };

    const [currency, setCurrency] = React.useState('');
    const handleChangeCurrency = (event: SelectChangeEvent) => {
        setCurrency(event.target.value as string);
    };

    const [paymentSystem2, setPaymentSystem2] = React.useState('');
    const handleChangePaymentSystem2 = (event: SelectChangeEvent) => {
        setPaymentSystem2(event.target.value as string);
    };

    const accessedRole = routsUser?.some(rout => rout.child.includes('/test-api/')) || userRoles?.some(role => role.name === 'Admin') || !!isSuperadmin;

    return (
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole &&
                <>
                    <TestApiPageHeader>
                        <TestApiPageHeaderText>
                            Test API
                        </TestApiPageHeaderText>
                    </TestApiPageHeader>
                    <TestApiPageFirstRow>
                        <StyledFormControl size="small">
                            <InputLabel>Merchant</InputLabel>
                            <Select
                                value={merchant}
                                label="Merchant"
                                onChange={handleChangeMerchant}
                            >
                                <StyledMenuItem value="CCC group">CCC group</StyledMenuItem>
                                <StyledMenuItem value="ABC company">ABC company</StyledMenuItem>
                            </Select>
                        </StyledFormControl>
                        <ButtonContainer>
                            <Button>GET KEYS</Button>
                            <Button sx={{marginLeft: '12px'}} variant="outlined">RESET</Button>
                        </ButtonContainer>
                    </TestApiPageFirstRow>
                    <TestApiPageSecondRow>
                        <StyledInput size="small" label="Public key"></StyledInput>
                        <StyledInput size="small" label="Private key"></StyledInput>
                        <StyledInput size="small" label="API URL"></StyledInput>
                        <ButtonContainer>
                            <Button variant='outlined'>SET</Button>
                        </ButtonContainer>
                    </TestApiPageSecondRow>
                    <TestApiPageThirdRow>
                        <StyledFormControl size="small" sx={{width: '350px'}}>
                            <InputLabel>Payment System</InputLabel>
                            <Select
                                value={paymentSystem}
                                label="Payment System"
                                onChange={handleChangePaymentSystem}
                            >
                                <StyledMenuItem value="CCC group">CCC group</StyledMenuItem>
                                <StyledMenuItem value="ABC company">ABC company</StyledMenuItem>
                            </Select>
                        </StyledFormControl>
                        <StyledFormControl size="small" sx={{width: '350px'}}>
                            <InputLabel>Way</InputLabel>
                            <Select
                                value={way}
                                label="Way"
                                onChange={handleChangeWay}
                            >
                                <StyledMenuItem value="CCC group">CCC group</StyledMenuItem>
                                <StyledMenuItem value="ABC company">ABC company</StyledMenuItem>
                            </Select>
                        </StyledFormControl>
                        <ButtonContainer>
                            <Button variant='outlined'>Select</Button>
                        </ButtonContainer>
                    </TestApiPageThirdRow>
                    <TestApiPageTransactionBlock>
                        <TransactionTitle>
                            Transaction:
                        </TransactionTitle>
                        <StyledFormControl size="small" sx={{width: '470px', marginTop: '20px'}}>
                            <InputLabel>Currency</InputLabel>
                            <Select
                                value={currency}
                                label="Currency"
                                onChange={handleChangeCurrency}
                            >
                                <StyledMenuItem value="CCC group">CCC group</StyledMenuItem>
                                <StyledMenuItem value="ABC company">ABC company</StyledMenuItem>
                            </Select>
                        </StyledFormControl>
                        <StyledFormControl size="small" sx={{width: '470px', marginTop: '20px'}}>
                            <InputLabel>Payment system</InputLabel>
                            <Select
                                value={paymentSystem2}
                                label="Payment system"
                                onChange={handleChangePaymentSystem2}
                            >
                                <StyledMenuItem value="CCC group">CCC group</StyledMenuItem>
                                <StyledMenuItem value="ABC company">ABC company</StyledMenuItem>
                            </Select>
                        </StyledFormControl>
                        <Input size="small" sx={{marginTop: '20px', marginBottom: '0px', width: '470px', maxWidth: '100%'}} label="Amount"></Input>
                        <Input size="small" sx={{marginTop: '20px', marginBottom: '0px', width: '470px', maxWidth: '100%'}} label="Email"></Input>
                        <Input size="small" sx={{marginTop: '20px', marginBottom: '0px', width: '470px', maxWidth: '100%'}} label="Number"></Input>
                        <Button sx={{width: '64px', marginTop: '20px'}}>SEND</Button>
                    </TestApiPageTransactionBlock>
                </>
            }
        </PageContainer>
    );
}


export default compose(
    connect((state: RootState) => ({
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {})
)(TestApiPage);