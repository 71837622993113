import React from 'react';
import { styled } from "@mui/material/styles";
import Structure from '../../components/structure';
import {Button} from '../../components/button';
import { DoneSvg } from '../../components/svg/done-svg';
import { PlusSvg } from '../../components/svg/plus-svg';
import { Status } from '../../components/status';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import { TableFilter } from "../../components/table-filter";
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import { CreateUserForm } from './create-user-form';
import Box from '@mui/material/Box';
import { NotifyModal } from '../../components/notify-modal';
import {SerachTableHeader} from '../../components/search-table-header';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loaders/loader';
import { 
    usersOnTheNodeRequest, 
    usersOnTheNodeCreate,
    usersOnTheNodeAssigne,
    usersOnTheNodeRevoke,
    usersDropdownAssignedRequest,
} from "../../actions/usersOnTheNode";
import {
    usersRolesRequest
} from '../../actions/usersRoles';
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { AccessDeniedAlert } from '../../components/access-denied';
import {AssignmentUserForm} from './assignmentForm';
import Snackbar from '@mui/material/Snackbar';
import { InfoPopup } from "../../components/info-popup";


const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%',
        flexWrap: 'wrap',
    }
}));

const UsersPageHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap'
    }
});

const UsersPageHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    },

    '@media(max-width: 540px)' : {
        width: '100%',
        display: 'block',
        marginBottom: '5px',
    },
}));

const UsersPageHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width:991px)': {
        width: '100%',
        marginBottom: '16px',
        justifyContent: 'space-between',
    },

    '@media(max-width: 540px)' : {
        flexWrap: 'wrap',
    },
});

const UsersPageHeaderRight = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
});

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }   
`));

const LoginContent = styled(Link)(({ theme }) => (`
    text-decoration: underline;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    ':hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'
    }
}));

const StyledTableCell30 = styled(TableCell)(({ theme }) => (`
    width: 50px;
`));

const StyledTableCell120 = styled(TableCell)(({ theme }) => (`
    // min-width: 120px;
`));

const IconWrapper = styled('div')({
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const TableCellContentWith200 = styled('div')(`
    display: flex;
    align-items: center;
    min-width: 225px;
`);

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const WithSortedSerachTableHeader = styled(SerachTableHeader)(({ theme }) => (`
    position: relative;
    padding-right: 30px;
    min-width: 120px;
    width: 25%;
    box-sizing: border-box;
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;

    & .overlay-scroll-table{
        max-height: calc(100vh - 190px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
        @media(max-width: 540px){
            max-height: calc(100vh - 250px - 56px - 35px);
        }; 
    }
`));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}));

const filterItems = [{title: 'Active', value: 1}, {title: 'Inactive', value: 0}, {title: 'Banned', value: -1}];

class UsersMainOnTheNode extends React.Component<any, any> {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            anchorElRole: null,
            anchorElStatus: null,
            anchorElCreate: null,
            anchorElAssignments: null,
            anchorElRevoke: null,
            statuses: [],
            roles: [],
            username: '',
            userAssign: '',
            revokeUser: '',
            openSnackbarRevoke: false,
            openSnackbarAssigned: false,
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => this.updateData());
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0});
    };

    handleSearchSubmit = (prop) => (value) => {
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleRoleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElRole: event.currentTarget});
    };
    handleRoleMenuClose = () => {
        this.setState({anchorElRole: null});
    };
    handleChangeFilterRoles = (data) => {
        this.setState({roles: data}, () => {this.updateData()});
        this.handleRoleMenuClose();
    };

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget});
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };
    handleChangeFilterStatuses = (data) => {
        this.setState({statuses: data}, () => {this.updateData()});
        this.handleStatusMenuClose();
    };

    handleCreateUserFormOpen = (event) => {
        this.setState({anchorElCreate: event.currentTarget});
    }
    handleCreateUserFormClose = () => {
        this.setState({anchorElCreate: null});
    }

    handleSaveUser = (data) => {
        const {usersOnTheNodeCreate} = this.props;
        usersOnTheNodeCreate(data);
    }






    handleOpenSnackbarAssignment = () => {
        this.setState({openSnackbarAssigned: true});
    };
    handleCloseSnackbarAssigned = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        this.setState({openSnackbarAssigned: false});
    };

    handleAssignModalOpen = (event) => {
        this.setState({anchorElAssignments: event.currentTarget});
    }    
    handleAssignModalClose = () => {
        this.setState({anchorElAssignments: null});
    }
    handleChangeAssignUser = (e) => {
        this.setState({userAssign: e.target.value}, () => {this.updateData()});
    }
    handleSaveAssignUser = (userId) => {
        const {usersOnTheNodeAssigne} = this.props;
        usersOnTheNodeAssigne(userId).then(() => this.handleOpenSnackbarAssignment());
        this.handleAssignModalClose();
    }






    handleOpenSnackbar = () => {
        this.setState({openSnackbarRevoke: true});
    };
    handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        this.setState({openSnackbarRevoke: false});
    };
    handleRewokeModalOpen = (event, item) => {
        this.setState({
            anchorElRevoke: event.currentTarget,
            revokeUser: item,
        });
    }
    handleRewokeModalClose = () => {
        this.setState({anchorElRevoke: null});
    }
    handleRevokeUser = () => {
        const {usersOnTheNodeRevoke} = this.props;
        const {revokeUser} = this.state;
        usersOnTheNodeRevoke(revokeUser.id).then(() => this.handleOpenSnackbar());
        this.handleRewokeModalClose();
    }

    componentDidMount() {
        const {
            usersDropdownAssignedRequest,
            usersRolesRequest,
            routsUser, 
            userRoles,
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child === '/front-user/index') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
            usersDropdownAssignedRequest();
            usersRolesRequest('');
        }
    }

    updateData = () => {

        const {
            page,
            username,
            statuses,
            roles,
        } = this.state;

        const {
            usersOnTheNodeRequest,
        } = this.props;

        const data = {
            'page': page + 1,
            'status[]': statuses,
            'username': username,
            'role[]': roles,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        usersOnTheNodeRequest(data);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeNode.id !== this.props.activeNode.id) {
            this.setState({page: 0}, () => {this.updateData()});
        };
    };

    render() {

        const {
            page,
            rowsPerPage,
            anchorElRole,
            anchorElStatus,
            statuses,
            username,
            roles,
            anchorElCreate,
            anchorElAssignments,
            anchorElRevoke,
            // userAssign,
            revokeUser,
            openSnackbarRevoke,
            openSnackbarAssigned,
        } = this.state;

        const {
            list,
            totalItems,
            isLoading,
            assigmentList,
            rolesList,
            routsUser, 
            userRoles,
            isSuperadmin,

            error,
            errorMessage,
        } = this.props;

        let iteratorNumber = 1;

        let rolesFilter = [];
        try {
            rolesFilter = rolesList.map((item) => {
                return {
                    title: item.description,
                    value: item.name,
                }
            })           
        } catch (error) {}
        
        const accessedRole = routsUser.some(rout => rout.child === '/front-user/index') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole &&
                    <>
                        <UsersPageHeader>
                            <UsersPageHeaderLeft>
                                <UsersPageHeaderText>Users on the node</UsersPageHeaderText>
                                <Structure/>
                            </UsersPageHeaderLeft>
                            <UsersPageHeaderRight>
                                <Button 
                                    startIcon={<PlusSvg width="22px" />} 
                                    onClick={this.handleCreateUserFormOpen}
                                >
                                    CREATE
                                </Button>
                                <Button 
                                    startIcon={<DoneSvg width="16px" />} 
                                    variant="outlined" 
                                    onClick={this.handleAssignModalOpen}
                                >
                                    ASSIGN
                                </Button>
                            </UsersPageHeaderRight>
                        </UsersPageHeader>
                        <StyledPaperTable>
                            <StyledTableContainer>

                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >

                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>
                                                <StyledTableCell30>№</StyledTableCell30>

                                                <WithSortedSerachTableHeader 
                                                    label='Login' 
                                                    handleSearchSubmit={this.handleSearchSubmit('username')}
                                                    handleCancelSearch={this.handleCancelSearch('username')}
                                                    isActiveFilter={Boolean(username)}
                                                />

                                                <TableCellWithActiveClass
                                                    className={ roles.length > 0 ? 'activeFilterClass' : ''}
                                                >
                                                    <TableCellContentWith200>
                                                        Roles
                                                        <IconWrapper>
                                                            <FilterListSvg size="16px" onClick={this.handleRoleMenuClick}/>
                                                        </IconWrapper>
                                                        <StyledMenu
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorElRole}
                                                            open={Boolean(anchorElRole)}
                                                            onClose={this.handleRoleMenuClose}
                                                            PaperProps={{
                                                                style: {
                                                                    width: '210px',
                                                                },
                                                            }}
                                                        >
                                                            <TableFilter 
                                                                filterItems={rolesFilter}
                                                                initialData={roles}
                                                                onApplyFilter={this.handleChangeFilterRoles} 
                                                                onClearAll={this.handleChangeFilterRoles}
                                                            />
                                                        </StyledMenu>
                                                    </TableCellContentWith200>
                                                </TableCellWithActiveClass>

                                                <TableCellWithActiveClass
                                                    className={ statuses.length > 0 ? 'activeFilterClass' : ''}
                                                >
                                                    <TableCellContentWith200>
                                                        Status
                                                        <IconWrapper>
                                                            <FilterListSvg size="16px" onClick={this.handleStatusMenuClick}/>
                                                        </IconWrapper>
                                                        <StyledMenu
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorElStatus}
                                                            open={Boolean(anchorElStatus)}
                                                            onClose={this.handleStatusMenuClose}
                                                            PaperProps={{
                                                                style: {
                                                                    width: '210px',
                                                                },
                                                            }}
                                                        >
                                                            <TableFilter 
                                                                filterItems={filterItems}
                                                                initialData={statuses}
                                                                onApplyFilter={this.handleChangeFilterStatuses} 
                                                                onClearAll={this.handleChangeFilterStatuses}
                                                            />
                                                        </StyledMenu>
                                                    </TableCellContentWith200>
                                                </TableCellWithActiveClass>

                                                <StyledTableCell120>Action</StyledTableCell120>
                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {(list || []).map(row => {
                                                return (
                                                    <StyledTableRow key={row['@id']}>
                                                        <TableCell>
                                                            {
                                                                (page*rowsPerPage) + iteratorNumber++
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <LoginContent 
                                                                to={`/user-on-selected-node/${row.id}`}
                                                            >
                                                                {row.username}
                                                            </LoginContent>
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                row.role?.length > 0 &&
                                                                    row.role?.map(item => {
                                                                        return <div key={Math.random()}>{`${item.name}  `}</div>
                                                                    })
                                                            }
                                                        </TableCell>
                                                        <TableCell>{<Status status={row.status}/>}</TableCell>
                                                        <TableCell align='left'>
                                                            <Button 
                                                                size='small' 
                                                                variant='outlined' 
                                                                onClick={(e) => this.handleRewokeModalOpen(e, row)}
                                                            >
                                                                REVOKE
                                                            </Button>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>

                                </OverlayScrollbarsComponent>

                            </StyledTableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={totalItems}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </StyledPaperTable>
                        <Modal
                            open={Boolean(anchorElCreate)}
                            onClose={this.handleCreateUserFormClose}
                        >
                            <StyledBoxPopup>
                                <CreateUserForm 
                                    onClose={this.handleCreateUserFormClose}
                                    onSave={this.handleSaveUser}
                                    rolesList={rolesList}
                                />
                            </StyledBoxPopup>
                        </Modal>
                        <Modal 
                            open={Boolean(anchorElAssignments)}
                            onClose={this.handleAssignModalClose}
                        >
                            <div>
                                <AssignmentUserForm 
                                    assigmentList={assigmentList}
                                    onClose={this.handleAssignModalClose}
                                    onSave={this.handleSaveAssignUser}
                                />
                            </div>
                        </Modal>
                        <Modal 
                            open={Boolean(anchorElRevoke)}
                            onClose={this.handleRewokeModalClose}
                        >
                            <div>
                                <NotifyModal
                                    title="Rewoke user"
                                    confirmButtonText='yes, Rewoke'
                                    cancelButtonText='CANCEL'
                                    onClose={this.handleRewokeModalClose}
                                    onCancel={this.handleRewokeModalClose}
                                    onConfirm={this.handleRevokeUser}
                                    width="400px"
                                >
                                    {`Are you sure you want to rewoke user ${revokeUser.username}?`}
                                </NotifyModal>
                            </div>
                        </Modal>

                        <Snackbar 
                            open={openSnackbarRevoke} 
                            autoHideDuration={3000} 
                            onClose={this.handleCloseSnackbar} 
                            anchorOrigin={{
                                vertical: "bottom", 
                                horizontal: "right"
                            }}
                        >
                            {error ?
                                <InfoPopup
                                    severity="error"
                                    headerText="User not revoked"
                                    infoText={errorMessage ? errorMessage : "Error"}
                                /> :
                                <InfoPopup
                                    severity="success"
                                    headerText={`User was revoked`}
                                />
                            }
                        </Snackbar>

                        <Snackbar 
                            open={openSnackbarAssigned} 
                            autoHideDuration={3000} 
                            onClose={this.handleCloseSnackbarAssigned} 
                            anchorOrigin={{
                                vertical: "bottom", 
                                horizontal: "right"
                            }}
                        >
                            {error ?
                                <InfoPopup
                                    severity="error"
                                    headerText="User not assigned"
                                    infoText={errorMessage ? errorMessage : "Error"}
                                /> :
                                <InfoPopup
                                    severity="success"
                                    headerText={`User was assigned`}
                                />
                            }
                        </Snackbar>
                    </>
                }

                { isLoading && <Loader/> }

            </PageContainer>
        )
    }
}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.usersOnTheNode.isLoading,
        list: state.usersOnTheNode.users['hydra:member'],
        totalItems: state.usersOnTheNode.users['hydra:totalItems'],
        assigmentList: state.usersOnTheNode.dropdownAssign['hydra:member'],
        rolesList: state.roles.roles['hydra:member'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
        activeNode: state.nodeManagement.activeNode,

        error: state.usersOnTheNode.isError,
        errorMessage: state.usersOnTheNode.errorMessage,
    }), {
        usersOnTheNodeRequest,
        usersOnTheNodeCreate,
        usersOnTheNodeAssigne,
        usersOnTheNodeRevoke,
        usersDropdownAssignedRequest,
        usersRolesRequest,
    }),
)(UsersMainOnTheNode);