import baseApi from './axiosParams';
import queryString from 'query-string';
const path = 'api/rbac/groups';

const UsersPermissionGroups = {
	getList: (params = {}) => {
		const query = Object.keys(params).length
			? `?${queryString.stringify(params)}`
			: '';
		return baseApi.get(`${path}${query}`)
	},
	getItem: (code) => {
		return baseApi.get(`${path}/${code}`)
	},
	createItem: (body) => {
		return baseApi.post(`${path}`, body)
	},
	deleteItem: (code) => {
		return baseApi.delete(`${path}/${code}`)
	},
	editeItem: (code, body) => {
		return baseApi.patch(`${path}/${code}`, body)
	},
}

export default UsersPermissionGroups;