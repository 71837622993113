import baseApi from './axiosParams';
const path = 'api/mapping/';

const Mapping = {
	getCascadeList: () => {
		return baseApi.get(`${path}get-cascade-list`)
	},
    setCascadeList: (body) => {
        return baseApi.post(`${path}set-cascade-list`, body)
    }
};

export default Mapping;