import baseApi from './axiosParams';
const path = 'api/telegram';

const TelegramWebhook = {
	telegramItem: () => {
		return baseApi.get(`${path}/index`)
	},	
	telegramSetWebhook: (data) => {
		return baseApi.post(`${path}/set-webhook`, data)
	},
	telegramDeleteWebhook: () => {
		return baseApi.delete(`${path}/delete-webhook`)
	},

}

export default TelegramWebhook;