import React from "react";
import { styled } from "@mui/material/styles";
import { List, ListItem, ListItemText, Menu, MenuItem, FormControl} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Scrollbars } from 'react-custom-scrollbars-2';



const StyledMenu = styled(Menu)(({ theme }) => (` 
	& > .MuiPaper-root{
	 	border-radius: 0;
		width: 427px;
		padding-left: 10px;
		padding-right: 10px;
		color: ${theme.palette.text.primary};

        @media(max-width: 768px){
            width: 320px;
        }

        @media(max-width: 380px){
            width: 280px;
        }
	} 
`));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => (`  
	width: 427px;

    @media(max-width: 768px){
        width: 320px;
    }

    @media(max-width: 380px){
        width: 280px;
    }

	& > input{
		height: 5px;	
		font-size: 14px;
	}  
	&.Mui-focused fieldset{
		border: 1px solid #38AA72 !important;
	}  
`));

const StyledeList = styled('div')(({ theme }) => (` 
	& {		
		@media (max-width: 767px) {
			order: 5;
			width: 100%;
		}

		& > nav{
            padding: 0;
			@media (max-width: 767px) {
				margin-right: 0px;
			}	
			& .MuiListItem-root{
                border: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(0, 0, 0, 0.23)'};
                padding: 3.5px 9px 3.5px 0px;
				border-radius: 4px;	
				color: ${theme.palette.text.primary};	
				& .MuiListItemText-root{
					margin-left: ${theme.spacing(2)};
					margin-right: ${theme.spacing(5)};
					font-size: 16px;
                    color: ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, 0.6)'};
					& > .MuiTypography-root{
						font-size: 16px;
					}
				}
			}
		}

        &.active-filter > nav .MuiListItem-root{
            border: 2px solid #38AA72 !important;
        }
	} 
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));


type ItemProp = {
    id: string | number, 
    text: string
}
type PropsType = {
    currentValue: string,
    defaultText: string,
    list: Array<ItemProp>,
    changeCurrentValue: (item: ItemProp) => void,
}

const FilterList: React.FC<PropsType> = ({currentValue, list, changeCurrentValue, defaultText}) => {
    const [stateComponent, setStateComponent] = React.useState<any>({
        anchorEl: null,
        valuseSeatch: '',
        paymentSystem: '',
    })

    const handleClickListItem = (e) => {
        setStateComponent(prev => ({...prev, anchorEl: e.currentTarget}));	
    }

    const handleChangeSearch = (e) => {
        setStateComponent(prev => ({...prev, valuseSeatch: e.target.value}));	
    }

    const handleClosePSFilter = (e) => {
        setStateComponent(prev => ({...prev, anchorEl: null, valuseSeatch: ''}));	
    }

    const handleChangePSystem = (item) => {
        setStateComponent(prev => ({
            ...prev,
            currentChangeSystem: item.text,
            paymentSystem: item.id,
            valuseSeatch: '',
            anchorEl: null,
        }));
        changeCurrentValue(item);
    }

    const handleChangePSDefault = () => {
        setStateComponent(prev => ({
            ...prev,
            paymentSystem: '',
            valuseSeatch: '',
            anchorEl: null,
        }));
        changeCurrentValue({id: 0, text: defaultText});
    }

    let paymentSystemsListFiltersed = (list || []).filter((item) => {
        return item.text.toLowerCase().includes(stateComponent.valuseSeatch.toLowerCase())
    });

    let open = Boolean(stateComponent.anchorEl);

    return (
        <>
            <StyledeList 
                className={currentValue !== defaultText ? 'active-filter' : ''}
                sx={{'cursor': 'pointer'}}
            >
                <List
                    component="nav"
                    aria-label="Payment systems"
                >
                    <ListItem
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        aria-label=""
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickListItem}
                    >
                        <ListItemText primary={currentValue}/>
                        <ArrowDropDownIcon />
                    </ListItem>
                </List>
            </StyledeList>

            <StyledMenu
                id="lock-menu"
                anchorEl={stateComponent.anchorEl}
                open={open}
                onClose={handleClosePSFilter}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                <FormControl variant="outlined">
                    <StyledOutlinedInput
                        type={'text'}
                        value={stateComponent.valuseSeatch}
                        onChange={(e)=>{handleChangeSearch(e)}}
                        label={``}
                    />
                </FormControl>
                <Scrollbars style={{ height: 185, margin: '10px 0', }}>
                    <StyledMenuItem
                        key={Math.random()}
                        onClick={handleChangePSDefault}
                    >
                        All
                    </StyledMenuItem>
                    {(paymentSystemsListFiltersed || []).map(item => (
                        <StyledMenuItem
                            key={Math.random()}
                            selected={item.id === stateComponent.paymentSystem}
                            onClick={() => handleChangePSystem(item)}
                        >
                            {item.text}
                        </StyledMenuItem>
                    ))}
                </Scrollbars>
            </StyledMenu>
        </>
    )
}

export default FilterList;