import React, {useEffect} from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Loader } from '../../components/loaders/loader';
import { 
    sysInfoTempDepositLogsItemRequest,
} from "../../actions/systemInfoTempDepositLogs";
import { connect } from 'react-redux';
import { compose } from "redux";
import { RootState } from '../../store/configure-store';
import {format} from 'date-fns';
import { itemTDLinks } from "../../reducers/systemInfoTemporiaryDepositLogs";

const PageContainer = styled('div')(({theme}) => (` 
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const BoxView = styled(Box)(({theme}) => (`
    // border: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;  
    .MuiTableContainer-root{
        border-radius: 0;
    }
    tr{
        &:last-child{
            td{
                border-bottom: none;
            }
        }
    }   
`));

const TableCellTitle = styled(TableCell)(({theme}) => (`
    font-weight: bold;
    min-width: 200px;
`));

const WrappBox = styled(Box)(({theme}) => (`
    margin-bottom: 40px;
    overflow: auto;
    border: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
`));

const StyledA = styled('a')(({theme}) => (`
    color: #38AA72;
    word-break: break-all;
`));

interface PtopType {
    isLoading: boolean,
    dataItem: itemTDLinks,
    sysInfoTempDepositLogsItemRequest: (id: number) => void,
    setView: any,
    view: {id: number, point: string},
}

export const SystemInfiTemporaryDepositItem = (props: PtopType) => {

    const {
        sysInfoTempDepositLogsItemRequest,
        dataItem,
        isLoading,
        setView, 
        view,
    } = props

    useEffect(() => {
        sysInfoTempDepositLogsItemRequest(view.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickNavigation = () => {
        setView((prev) => ({ ...prev, point: 'main'}));
    }
    
    return(
        <PageContainer>
            <ShortViewHeader>
                <ShortViewHeaderLeft>
                    <HeaderLeftContainer>
                        <ArrowContainer  onClick={handleClickNavigation}>
                            <ArrowBackSvg size="22px"/>
                        </ArrowContainer>
                        <ShortViewHeaderText>
                            {dataItem.id}
                        </ShortViewHeaderText>
                    </HeaderLeftContainer>
                </ShortViewHeaderLeft>
            </ShortViewHeader>

            <WrappBox>
                <BoxView sx={{ width: '100%', typography: 'body1' }}>
                    <TableContainer component={Paper} sx={{minWidth: '340px'}}>
                        <Table>   
                            <TableBody>
                                <TableRow>
                                    <TableCellTitle>Id</TableCellTitle>
                                    <TableCell>{dataItem.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Transaction Id</TableCellTitle>
                                    <TableCell>{dataItem?.transaction?.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Buyer Id</TableCellTitle>
                                    <TableCell>{dataItem.buyerId}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Slug</TableCellTitle>
                                    <TableCell>{dataItem.slug}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Method</TableCellTitle>
                                    <TableCell>{dataItem.method}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Url</TableCellTitle>
                                    <TableCell>
                                        <StyledA href={dataItem.url}>
                                            {dataItem.url}
                                        </StyledA>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Params</TableCellTitle>
                                    <TableCell 
                                        sx={{wordBreak: 'break-all'}}
                                    >
                                        {dataItem.params}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Done</TableCellTitle>
                                    <TableCell>
                                        {
                                            <span style={{
                                                    color: dataItem.transaction?.status === 'Cancel' ? '#c55' 
                                                            : dataItem.transaction?.status === 'Pending' ? 'White'
                                                            : '#38AA72', 
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                {dataItem.transaction?.status}
                                            </span>
                                        }   
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCellTitle>Created At</TableCellTitle>
                                    <TableCell>
                                        {format(new Date(dataItem.createdAt * 1000), 'yyyy-MM-dd HH:mm:ss')}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BoxView>
            </WrappBox>

            {isLoading && <Loader/>}

        </PageContainer>
    )
} 

export default compose(
    connect((state: RootState) => ({
        isLoading: state.systemInfoTempDepositLogs.isLoading,
        dataItem: state.systemInfoTempDepositLogs.systemInfoTempDepositLogsItem,
    }), {
        sysInfoTempDepositLogsItemRequest,
    }),
)(SystemInfiTemporaryDepositItem)