import React, {useRef} from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import { IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SimpleReactValidator from 'simple-react-validator';


const CreateUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
    '& .srv-validation-message': {
        color: theme.palette.text.primary,
        fontSize: '12px',
    }
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const WrapPasswordInput = styled('div')(({theme}) => (`
    position: relative;
    button{
        position: absolute;
        top: 8px;
        right: 5px;
    }
`));

export const ChangePasswordForm = ({onClose, id, changePasswordRequest, handleOpenSnackbar}) => {
    const simpleValidator = useRef(new SimpleReactValidator());

    const [visiblePasswordFirst, setVisiblePasswordFirst] = React.useState<boolean>(false);
    const [visiblePasswordSecond, setVisiblePasswordSecond] = React.useState<boolean>(false);

    const handleChangeVisibilityPasswordFirst = () => {
        setVisiblePasswordFirst((prevState) => {
            return !prevState
        })
    };

    const handleChangeVisibilityPasswordSecond = () => {
        setVisiblePasswordSecond((prevState) => {
            return !prevState
        })
    };

    const [password, setPassword] = React.useState('');

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const [repeatPassword, setRepeatPassword] = React.useState('');

    const handleChangeRepeatPassword = (event) => {
        setRepeatPassword(event.target.value);
    };

    const handleSubmit = () => {
        changePasswordRequest(id, JSON.stringify({password})).then(() => handleOpenSnackbar());
        onClose();
    };

    return (
        <CreateUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Change password
                </Title>
                <div style={{overflow: 'hidden', height: '0px', background: 'transparent'}} data-description="dummyPanel for Chrome auto-fill issue">
                    <input type="text" style={{height: '0', background: 'transparent', color: 'transparent', border: 'none'}} data-description="dummyUsername"></input>
                    <input type="password" style={{height: '0', background: 'transparent', color: 'transparent', border: 'none'}} data-description="dummyPassword"></input>
                </div>
                <WrapPasswordInput sx={{marginTop: '20px'}}>
                    <Input
                        type={visiblePasswordFirst ? 'text' : 'password'}
                        label="Password *" value={password}
                        onChange={handleChangePassword}
                        onBlur={() => simpleValidator.current.showMessageFor('password')}
                        autoComplete="off"
                    />
                    <IconButton
                        aria-label=""
                        onClick={handleChangeVisibilityPasswordFirst}
                        sx={{marginLeft: '5px'}}
                    >
                            {visiblePasswordFirst ? <VisibilityOffIcon /> : <VisibilityIcon /> } 
                    </IconButton>
                </WrapPasswordInput>
                {simpleValidator.current.message('password', password, 'required|min:8|max:256')}
                <WrapPasswordInput sx={{marginTop: '20px'}}>
                    <Input
                        type={visiblePasswordSecond ? 'text' : 'password'}
                        label="Repeat password *"
                        value={repeatPassword}
                        onChange={handleChangeRepeatPassword}
                        onBlur={() => simpleValidator.current.showMessageFor('repeatPassword')}
                        autoComplete="off"
                    />
                    <IconButton
                        aria-label=""
                        onClick={handleChangeVisibilityPasswordSecond}
                        sx={{marginLeft: '5px'}}
                    >
                            {visiblePasswordSecond ? <VisibilityOffIcon /> : <VisibilityIcon /> } 
                    </IconButton>
                </WrapPasswordInput>
                {simpleValidator.current.message('repeatPassword', repeatPassword, 'required|min:8|max:256')}
            </WrapFields>
            <ButtonsContainer>
                <Button onClick={handleSubmit} sx={{marginRight: '12px'}} disabled={password !== repeatPassword || !simpleValidator.current.allValid()}>CONFIRM</Button>
                <Button onClick={onClose} variant="outlined">CANCEL</Button>
            </ButtonsContainer>
        </CreateUserFormContainer>
    );
};
