import React from 'react';
import { styled } from "@mui/material/styles";
import {LinkToPermission} from './link-permission';
import { Link } from 'react-router-dom';

const StyledUl = styled('ul')(`
    &{
        margin-top: 0;
        margin-left: 30px;
        padding-left: 0;
        width: calc(100%);
    }
`);

const StyledLi = styled('li')(`
    &{
        list-style:none;
        display: block;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }
`);

const CheckedHasParentRole = styled('div')(`
    color: #38AA72;
    font-size: 55px;
    font-weight: 700;
    line-height: 100%;
    width: 42px;
    height: 42px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -6px;
`);

const WrapLabelPermission = styled('div')(({ theme }) => (`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`));

const LabelPermission = styled('div')(({ theme }) => (`
    color: ${theme.palette.text.primary};
`));

const WrapLink = styled('div')(({ theme }) => (`
    position: relative;
    top: -3px;
`));

type ListData = {
    "@id": string;
    code: string;
    name: string | null;
    checked: boolean;
    cnt?: number;
	child?: Array<ListData>;
    owner_child_role?: boolean;
}

type PropsType = {
    items: Array<ListData>; 
    chahgeElement: (key: string) => void;
}

export const HasChieldRoutePermission: React.FC<PropsType> = ({ items, chahgeElement }) => {
    return <StyledUl>
        {
            (items || []).filter(item => item?.checked).map(item => {
                return <StyledLi 
                    key={Math.random()}
                    style={{paddingLeft: `${item.cnt ? (item.cnt * 30) : 0}px`}}
                >
                    <WrapLabelPermission>
                        <CheckedHasParentRole>·</CheckedHasParentRole>
                        <LabelPermission>{item.name || item.code}</LabelPermission>
                        <Link to={`/users/permission/${item.code}`}>
                            <WrapLink>
                                <LinkToPermission/>
                            </WrapLink>
                        </Link>
                    </WrapLabelPermission>
                </StyledLi>
            })
        }
    </StyledUl>
};