export namespace ActionTypes {
    export const SET_LOADING = 'SET_LOADING';
    export const GET_USER_PAYMENT_SYSTEMS = 'GET_USER_PAYMENT_SYSTEMS';
    export const UPDATE_USER_PAYMENT_SYSTEMS = 'UPDATE_USER_PAYMENT_SYSTEMS';
    export const CREATE_USER_PAYMENT_SYSTEM = 'CREATE_USER_PAYMENT_SYSTEM';
    export const GET_USER_PAYMENT_SYSTEM = 'GET_USER_PAYMENT_SYSTEM';
	export const CHANGE_FIELD_CREDENTIALS_USER_PAYMENT_SYSTEM = 'CHANGE_FIELD_CREDENTIALS_USER_PAYMENT_SYSTEM';
	export const CHANGE_FIELD_CURRENCIES_USER_PAYMENT_SYSTEM = 'CHANGE_FIELD_CURRENCIES_USER_PAYMENT_SYSTEM';
	export const CHANGE_FIELD_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM = 'CHANGE_FIELD_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM';
	export const CHANGE_FIELD_ACTIVE_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM = 'CHANGE_FIELD_ACTIVE_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM';
	export const ERROR_USER_PAYMENT_SYSTEM = 'ERROR_USER_PAYMENT_SYSTEM';
};

export const initialState = {
    isLoading: false,
	userPaymentSystems: {
		'hydra:member': [],
		'@context':"/api/contexts/UserPaymentSystem",
		'@id':"/api/user_payment_systems",
		'@type':"hydra:Collection",
		'hydra:totalItems': 100,
		'hydra:view': {},
		'hydra:search': {}, 
	},
	userPaymentSystem: {
		ModeModel: [],
		UserPaymentSystem: {},
		credentials: [],
		currencies: [],
		model: {},
	},
	error: '',	
}

export const userPaymentSystems = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_LOADING:
			return {
				...state,
				isLoading: true,
				error: '',
			};
		case ActionTypes.GET_USER_PAYMENT_SYSTEMS:
			return {
				...state,
				userPaymentSystems: payload,
				isLoading: false,
			};
		case ActionTypes.UPDATE_USER_PAYMENT_SYSTEMS:
			return {
				...state,
				// todo
			};
        case ActionTypes.CREATE_USER_PAYMENT_SYSTEM:
            return {
                ...state,
                // userPaymentSystems: [st]
            }
        case ActionTypes.GET_USER_PAYMENT_SYSTEM:
            return {
                ...state,
                userPaymentSystem: payload,
				isLoading: false,
            }
		case ActionTypes.CHANGE_FIELD_CREDENTIALS_USER_PAYMENT_SYSTEM:
			return {
				...state,
				userPaymentSystem: {
					...state.userPaymentSystem, 
					credentials: [
						...state.userPaymentSystem.credentials.map((item) => {
							if(item.attribute === payload.attribute){
								item.value = payload.value
								return item
							}
							return item
						})

					]
				}
			}	
		case ActionTypes.CHANGE_FIELD_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM:
			return {
				...state,
				userPaymentSystem: {
					...state.userPaymentSystem, 
					credentials: {
						...state.userPaymentSystem.credentials,
						[payload.itemCurrency]: [
							...state.userPaymentSystem.credentials[payload.itemCurrency].map((item) => {
								if(item.attribute === payload.item.attribute){
									item.value = payload.val
								}
								return item;
							})
						]
					}
				}
			}	
		case ActionTypes.CHANGE_FIELD_ACTIVE_CREDENTIALS_MODE_MODAL_USER_PAYMENT_SYSTEM:
			return {
				...state,
				userPaymentSystem: {
					...state.userPaymentSystem, 
					credentials: {
						...state.userPaymentSystem.credentials,
						// eslint-disable-next-line
						[0]: {
							...state.userPaymentSystem.credentials[0],
							value: payload.val,
						}
					}
				}
			}		
		case ActionTypes.CHANGE_FIELD_CURRENCIES_USER_PAYMENT_SYSTEM:
			return {
				...state,
				userPaymentSystem: {
					...state.userPaymentSystem, 
					currencies: [
						...state.userPaymentSystem.currencies.map((item) => {
							if(item.field === payload.attribute){
								item.checked = payload.value
								return item
							}
							return item
						})

					]
				}
			}	
		case ActionTypes.ERROR_USER_PAYMENT_SYSTEM: 	            
			return {
				...state,
				error: payload,
				isLoading: false,
			}		
		default:
			return state;
	}
};
