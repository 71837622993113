export namespace ActionTypes {
    export const FETCH_BUILD_API_START = 'FETCH_BUILD_API_START';
    export const FETCH_BUILD_API_SUCCESS = 'FETCH_BUILD_API_SUCCESS';
    export const FETCH_BUILD_API_FAILURE = 'FETCH_BUILD_API_FAILURE';
};

interface BuildApiItem {
	message: string,
	time: string,
}

interface BuildApi {
	isError: boolean,
    isLoading: boolean,
	buildApiList: BuildApiItem[],
	errorMessage: string,
}

export const initialState: BuildApi = {
	isError: false,
    isLoading: false,
	buildApiList: [
		{
			message: '',
			time: '',
		}
	],
	errorMessage: ''
};

export const buildApi = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_BUILD_API_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_BUILD_API_SUCCESS:
			return {
				...state,
				buildApiList: payload,
				isLoading: false,
				isError: false,
				errorMessage: '',
			};
		case ActionTypes.FETCH_BUILD_API_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
