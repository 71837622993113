import React from "react";
import SystemInfoHdbkRequestsTypesList from './system-info-hdbk-requests-types';
import SystemInfoHdbkRequestsTypesItem from './system-info-hdbk-requests-types-view';
import SystemInfoHdbkRequestsTypesEdit from './system-info-hdbk-requests-types-edit'

export const SystemInfoAdresses = () => {
    const [view, setView] = React.useState({id: 0, point: 'main'})
    
    return(
        <>
            { view.point === 'main' && <SystemInfoHdbkRequestsTypesList setView={setView} />}
            { view.point === 'view' && <SystemInfoHdbkRequestsTypesItem setView={setView} view={view} />}
            { view.point === 'edit' && <SystemInfoHdbkRequestsTypesEdit setView={setView} view={view} /> }
        </>
    )
} 

export default SystemInfoAdresses;