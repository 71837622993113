import React from 'react';
import { styled } from "@mui/material/styles";
import PermMediaIcon from '@mui/icons-material/PermMedia';


const StyledDefaultImage = styled('div')(({ theme }) => (`
    background: #E3E8EC;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    svg{
        color: #979799;
    }
`));

export const DefaultImage = (props: any) => {
    return (
        <StyledDefaultImage {...props} >
            <PermMediaIcon />
        </StyledDefaultImage>
    )
}