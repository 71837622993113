export namespace ActionTypes {
    export const FETCH_TELEGRAM_START = 'FETCH_TELEGRAM_START';
    export const FETCH_TELEGRAM_SUCCESS = 'FETCH_TELEGRAM_SUCCESS';
    export const FETCH_TELEGRAM_DELETE = 'FETCH_TELEGRAM_DELETE';
    export const FETCH_TELEGRAM_FAILURE = 'FETCH_TELEGRAM_FAILURE';
};

export type TelegramInfoType = {
    url: string,
    has_custom_certificate: boolean,
    pending_update_count: number,
    max_connections: number,
    ip_address: string,
}

interface Telegram {
	isError: boolean,
    isLoading: boolean,
	telegramItem: TelegramInfoType,
	errorMessage: string,
}

export const initialState: Telegram = {
	isError: false,
    isLoading: false,
	telegramItem: {
		url: "",
		has_custom_certificate: false,
		pending_update_count: 0,
		max_connections: 1,
		ip_address: '',
	},
	errorMessage: '',
};

export const telegram = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_TELEGRAM_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_TELEGRAM_SUCCESS:
			return {
				...state,
				telegramItem: payload,
				isLoading: false,
			};
		case ActionTypes.FETCH_TELEGRAM_DELETE:
			return {
				...state,
			};			
		case ActionTypes.FETCH_TELEGRAM_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
