import React from "react";
import MainUsersPage from './all-users-main';
import UserPermissions from './details/user-permission';
export const UsersPageOnBranch = () => {
    const [view, setView] = React.useState({
        point: 'main',
        id: ''
    })

    return(
        <>
            { view.point === 'main' && <MainUsersPage setView={setView} />}
            { view.point === 'permission' && <UserPermissions setView={setView} view={view} /> }
        </>
    )
} 

export default UsersPageOnBranch;