import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const StatusOkSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 12 12"
            onClick={onClick}
        >
            <path d="M7.36 4.395L5.215 6.545L4.39 5.72C4.34518 5.66766 4.29002 5.62515 4.22799 5.59514C4.16595 5.56513 4.09839 5.54826 4.02953 5.5456C3.96067 5.54294 3.892 5.55454 3.82784 5.57968C3.76368 5.60482 3.7054 5.64295 3.65668 5.69167C3.60795 5.7404 3.56982 5.79868 3.54468 5.86284C3.51955 5.927 3.50794 5.99567 3.5106 6.06453C3.51326 6.13339 3.53013 6.20095 3.56014 6.26299C3.59015 6.32502 3.63266 6.38018 3.685 6.425L4.86 7.605C4.90672 7.65134 4.96213 7.688 5.02305 7.71289C5.08397 7.73777 5.1492 7.75038 5.215 7.75C5.34617 7.74945 5.47187 7.69737 5.565 7.605L8.065 5.105C8.11187 5.05852 8.14906 5.00322 8.17445 4.94229C8.19983 4.88136 8.2129 4.81601 8.2129 4.75C8.2129 4.68399 8.19983 4.61864 8.17445 4.55771C8.14906 4.49678 8.11187 4.44148 8.065 4.395C7.97132 4.30187 7.84459 4.2496 7.7125 4.2496C7.58041 4.2496 7.45368 4.30187 7.36 4.395ZM6 1C5.0111 1 4.0444 1.29324 3.22215 1.84265C2.39991 2.39206 1.75904 3.17295 1.3806 4.08658C1.00217 5.00021 0.90315 6.00555 1.09608 6.97545C1.289 7.94536 1.76521 8.83627 2.46447 9.53553C3.16373 10.2348 4.05465 10.711 5.02455 10.9039C5.99446 11.0969 6.99979 10.9978 7.91342 10.6194C8.82705 10.241 9.60794 9.6001 10.1573 8.77785C10.7068 7.95561 11 6.98891 11 6C11 5.34339 10.8707 4.69321 10.6194 4.08658C10.3681 3.47995 9.99983 2.92876 9.53553 2.46447C9.07124 2.00017 8.52005 1.63188 7.91342 1.3806C7.30679 1.12933 6.65661 1 6 1ZM6 10C5.20888 10 4.43552 9.7654 3.77772 9.32588C3.11992 8.88635 2.60723 8.26164 2.30448 7.53073C2.00173 6.79983 1.92252 5.99556 2.07686 5.21964C2.2312 4.44371 2.61216 3.73098 3.17157 3.17157C3.73098 2.61216 4.44372 2.2312 5.21964 2.07686C5.99556 1.92252 6.79983 2.00173 7.53074 2.30448C8.26164 2.60723 8.88635 3.11992 9.32588 3.77772C9.7654 4.43552 10 5.20887 10 6C10 7.06087 9.57857 8.07828 8.82843 8.82843C8.07828 9.57857 7.06087 10 6 10Z" fill={fill}/>
        </WrapperTag>
    );
};

StatusOkSvg.defaultProps = {
    fill: '#52C41A',
};
