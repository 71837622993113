import {actionTypes} from '../reducers/timezone';
import API from '../services/api';
import {currentUserGet} from './authorization'

export const setTimezone = (data) => ({
	type: actionTypes.SET_TIMEZONE,
	payload: data,
});

export const requestTimezones = data => {
	return {
		type: actionTypes.GET_TIMEZONES,
		payload: data,
	}
};

export const requestFailure = error => {
	return {
		type: actionTypes.FAILYRE_TIMEZONE,
		payload: error,
	}
};

export const temezoneListRequest = () => dispatch => {
	return API.timeZone
		.getTimezone()
		.then(res => {
			dispatch(requestTimezones(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

export const setTimezoneRequest = (id, data) => dispatch => {
	return API.timeZone
		.setTimezone(id, data)
		.then(res => {
			dispatch(currentUserGet(res.data))
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};
