import React, { useState, useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import { FieldsListPermissions } from './details/fields-lists-permission';


const CreateUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const AlertFormMessage = styled('div')(({ theme }) => (`
    color: ${theme.palette.text.primary};
`));

const WrapTabContentColumns = styled('div')(({ theme }) => (`
&{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
`));

const WrapTabContentItem = styled('div')(({ theme }) => (`
&{
    // padding-right: 30px;
    box-sizing: border-box;
    width: 100%;
    @media screen and (max-width: 991px){
        width: 100%;
    }
}
`));

type Props = {
    onClose: () => void;
    onSave: (data: any) => void;
    rolesList: Array<any>;
}

export const CreateUserForm = (props: Props) => {
    const {onClose, onSave, rolesList} = props;

    const [values, setValues] = React.useState({
        username: '',
        password: '',
        passwordRepeat: '',
        role: [],
        roleNote: '',
        roles: [],
    });

    const handleChangeFields = (key) => (e) => {
        setValues((prev) => ({...prev, [key]: e.target.value}));
    }

    const [stateRolesMod, setStateRolesMod] = useState([]);
    useEffect(() => {
        const childFieldsModCheck = rolesList.map(item => {
            item.checked = false;
            return item;
        });
        setStateRolesMod(childFieldsModCheck);
    }, [rolesList])

    const handleChangeRoles = (data) => {
        setStateRolesMod(prev => {
            let changedState = [...prev];
            changedState.forEach(item => {
                if(item.name === data){
                    item.checked = !item.checked
                }
            })
            return changedState
        });
    }

    const handleSave = () => {
        let data = {...values};
        let rolesTrue = []
        stateRolesMod.forEach(item => {
            if(item.checked) rolesTrue.push(item.name)
        })
        delete data.passwordRepeat;
        delete data.role;
        data.roles = rolesTrue;
        onSave(data);
        onClose();
    }
    
    return (
        <CreateUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Create user
                </Title>

                <Input 
                    label="Login *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    value={values.username} 
                    onChange={(e) => handleChangeFields('username')(e)} 
                    autoComplete={'no'}
                />

                <Input 
                    label="Password *" 
                    type="text" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    value={values.password} 
                    onChange={(e) => handleChangeFields('password')(e)} 
                    autoComplete={'no'}
                />

                <Input 
                    label="Repeat password *" 
                    type="text" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    value={values.passwordRepeat} 
                    onChange={(e) => handleChangeFields('passwordRepeat')(e)} 
                    autoComplete={'no'}
                />
                {values.passwordRepeat && values.passwordRepeat !== values.password && <AlertFormMessage>Passwords must mutch</AlertFormMessage>}
                
                <Input 
                    label="Note" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    value={values.roleNote} 
                    onChange={(e) => handleChangeFields('roleNote')(e)} 
                    autoComplete={'no'}
                />

                <WrapTabContentColumns>
                    <WrapTabContentItem>
                        <FieldsListPermissions 
                            childFieldss={stateRolesMod} 
                            onChangeRoles={handleChangeRoles}
                        />
                    </WrapTabContentItem>
                </WrapTabContentColumns>
                
            </WrapFields>
            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}} 
                    disabled={values.password !== values.passwordRepeat || !values.username || !values.password || !values.passwordRepeat}
                    onClick={handleSave}
                >
                    CREATE
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={onClose}
                >
                    CANCEL
                </Button>
            </ButtonsContainer>
        </CreateUserFormContainer>
    );
};
