import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    circleFill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const ErrorSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    circleFill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 148 148"
            onClick={onClick}
        >
            <g filter="url(#filter0_d_1284_29502)">
                <circle cx="74" cy="73" r="70" fill={circleFill}/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M40.1002 100.012C36.9174 103.174 36.9174 108.301 40.1002 111.463C43.283 114.625 48.4433 114.625 51.6261 111.463L79.9009 83.3717L92.8584 96.2452L100.726 104.061C101.7 105.03 102.35 106.273 102.588 107.622L102.942 109.639C103.083 110.443 103.599 111.134 104.333 111.501H112.695C114.162 111.501 115.568 110.922 116.604 109.891C120.197 106.318 120.196 100.527 116.601 96.9558L91.4145 71.9329L117.055 46.4582C120.376 43.1597 120.311 37.792 116.911 34.5738C115.397 33.1398 113.396 32.3222 111.304 32.2826L105.242 32.168L102.647 37.1928C102.171 38.1151 101.553 38.958 100.815 39.6911L92.2726 48.178L79.8885 60.4818L55.28 36.0329C51.3639 32.1422 45.0153 32.1401 41.0966 36.0282L36.6694 40.4209L68.3749 71.9206L40.1002 100.012Z" fill={fill}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M47.0168 34.5443C43.8483 31.3759 38.7113 31.3759 35.5428 34.5443C32.3744 37.7127 32.3744 42.8497 35.5428 46.0181L61.3596 71.8345L35.5432 97.6505C32.3748 100.819 32.3748 105.956 35.5432 109.124C38.7116 112.293 43.8487 112.293 47.0171 109.124L72.8335 83.3083L98.6494 109.124C101.818 112.292 106.955 112.292 110.123 109.124C113.292 105.955 113.292 100.819 110.123 97.6501L84.3074 71.8345L110.124 46.0185C113.292 42.8501 113.292 37.7131 110.124 34.5447C106.955 31.3763 101.818 31.3763 98.6498 34.5447L72.8335 60.3607L47.0168 34.5443Z" fill="url(#paint0_linear_1284_29502)"/>
            <defs>
                <filter id="filter0_d_1284_29502" x="0" y="0" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.255313 0 0 0 0 0.5375 0 0 0 0 0.358247 0 0 0 0.32 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1284_29502"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1284_29502" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_1284_29502" x1="150.04" y1="-1.8708" x2="97.4109" y2="90.2807" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#34AB74"/>
                    <stop offset="1" stopColor="#91CD90"/>
                </linearGradient>
            </defs>
        </WrapperTag>
    );
};

ErrorSvg.defaultProps = {
    fill: '#36AB74',
    size: '140px',
    circleFill: 'white'
};
