import baseApi from './axiosParams';
const path = 'api/nodes';

const NodeManagement = {
	getNodesTree: () => {
		return baseApi.get(`${path}/tree-new`)
		// return baseApi.get(`${path}/tree`)
	},
	getNode: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
	createNode: (type, data) => {
		return baseApi.post(`${path}/${type}`, data)
	},
	updateNode: (id, data) => {
		return baseApi.patch(`${path}/${id}`, data)
	},
	deleteNode: (id) => {
		return baseApi.delete(`${path}/${id}`)
	},
	setActiveNode: (id) => {
		return baseApi.get(`${path}/switch_node/${id}`)
	},
	verifyDomain: (id) => {
		return baseApi.get(`${path}/verify/${id}`)
	},
	switchRESTAccess: () => {
		return baseApi.get(`${path}/rest_access`)
	},
	getApiKey: () => {
		return baseApi.get(`${path}/api_key`)
	},
	
	getBrandsAmount: () => {
		return baseApi.get(`${path}/brand_amount`)
	},
	getClientsAmount: () => {
		return baseApi.get(`${path}/client_amount`)
	},
    // ...
}

export default NodeManagement;