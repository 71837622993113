import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';

const CreateUserFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
    marginTop: '16px'
}));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const FormatCodeDescriptopn = styled('div')(({ theme }) => ({
    fontSize: '12px',
    color: theme.palette.text.primary,
    opacity:'.7',
}));

type Props = {
    onClose: () => void;
    onCreate: (body: {}) => void;
}

export const CreatePermissionForm = (props: Props) => {
    const {onClose, onCreate} = props;

    const [value, setValue] = useState({name: '', code: ''});

    const handleChange = (key, e) => {
        setValue((prev) => {
            return {
                ...prev,
                [key]: e.target.value,
            }
        })
    }

    const handleCreate = () => {
        onCreate(value);
        onClose();
    }

    return (
        <CreateUserFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>
                    Creating permission group
                </Title>
                <Input 
                    value={value.name}
                    label="Name *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    onChange={(e) => handleChange('name', e)}
                />
                <Input 
                    value={value.code}
                    label="Code *" 
                    size="small" 
                    sx={{marginTop: '20px'}} 
                    onChange={(e) => handleChange('code', e)}
                />
                <FormatCodeDescriptopn>Fornat Code: aaBb, aa_bb, aa-bb</FormatCodeDescriptopn>
            </WrapFields>
            <ButtonsContainer>
                <Button onClick={handleCreate} sx={{marginRight: '12px'}}>CREATE</Button>
                <Button onClick={onClose} variant="outlined">CANCEL</Button>
            </ButtonsContainer>
        </CreateUserFormContainer>
    );
};
