import {ActionTypes} from '../reducers/usersActionLog';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_ACTION_LOG_START,
});

export const requestSuccess = list => {
	return {
		type: ActionTypes.FETCH_ACTION_LOG_SUCCESS,
		payload: list
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_ACTION_LOG_FAILURE,
		payload: error
	}
};

export const usersActionLogRequest = data => dispatch => {
	dispatch(requestStart());
	return API.usersActionLog
		.getList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};
