import React from 'react';
import { styled } from "@mui/material/styles";
import Dialog from '@mui/material/Dialog';
import { NotifyDialog } from '../../../components/notify-dialog';
import {Input} from '../../../components/input';


const StyledDialogChangePassword = styled(Dialog)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
    }
`));

export const ChangePasswordForm = ({isOpen, handleClose, userId, handleSavePassword}) => {
    const [password, setPassword] = React.useState('');
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const [repeatPassword, setRepeatPassword] = React.useState('');
    const handleChangeRepeatPassword = (event) => {
        setRepeatPassword(event.target.value);
    };

    const handleSave = () => {
        handleSavePassword(password);
        setTimeout(() => {
            setPassword('');
            setRepeatPassword('');
        }, 1000);
    }

    return <StyledDialogChangePassword
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <NotifyDialog
            title="Change password"
            confirmButtonText='UPDATE'
            cancelButtonText='CANCEL'
            onClose={handleClose}
            width="400px"
            onCancel={handleClose}
            onConfirm={handleSave}
            isDisabled={password !== repeatPassword || password === '' || repeatPassword === ''}
        >
            <Input 
                label="Password *" 
                type="text" 
                size="small"
                value={password}
                onChange={handleChangePassword}
            />
            <Input 
                label="Repeat password *" 
                type="text" 
                size="small"
                value={repeatPassword}
                onChange={handleChangeRepeatPassword}
            />
        </NotifyDialog>  
    </StyledDialogChangePassword>
}