import baseApi from './axiosParams';
const path = 'api/notify_settings';

const NotificationSettings = {
	getNotifySetting: (id) => {
		return baseApi.get(`${path}/${id}`)
	},
    createNotifySetting: (body) => {
		return baseApi.post(`${path}`, body)
    },
    updateNotifySetting: (id, body) => {
        return baseApi.patch(`${path}/${id}`, body)
    }
}

export default NotificationSettings;