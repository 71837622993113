import React from "react";
import { styled } from "@mui/material/styles";
import Structure from '../../components/structure';
import {Button} from '../../components/button';
import {NotificationsSvg} from '../../components/svg/notifications-svg';
import {CachedSvg} from "../../components/svg/cached-svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {MoreSvg} from "../../components/svg/more-svg";
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import {DateRangePicker} from '../../components/date-range-picker';
import {SerachTableHeader} from '../../components/search-table-header';
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import {TableFilter} from "../../components/table-filter";
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {
    getTransactionsRequest, 
    updateStatusesRequest, 
    setNotifiesRequest, 
    getStatusesListRequest, 
    getNotifyStatusesListRequest,
    getPaymentMethodsRequest,
    transactionsDownload,
} from '../../actions/transactions';
import {getPaymentSystemsListRequest} from '../../actions/paymentSystems';
import {currencyListRequest} from '../../actions/currencyList';
import {format} from 'date-fns';
import Modal from '@mui/material/Modal';
import { NotifyModal } from '../../components/notify-modal';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Input} from '../../components/input';
import { Menu as ReactMenu, MenuItem as ReactMenuItem, MenuButton } from '@szhsin/react-menu';
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import {menuSelector, menuItemSelector} from "@szhsin/react-menu/style-utils";
import {Loader} from '../../components/loaders/loader';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import {Link} from 'react-router-dom';
import {EmptyDataSvg} from '../../components/svg/empty-data-svg';
import Tooltip from '@mui/material/Tooltip';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import {PaginationPPS} from '../../components/pagination';
import { ColumnFilter } from '../../components/column-filter';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { AccessDeniedAlert } from "../../components/access-denied";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableViewIcon from '@mui/icons-material/TableView';

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const TransactionMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const TransactionMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
    }
});

const RangePickerWrapper = styled('div')({
    marginRight: '16px',
    width: '350px',

    '@media(max-width: 1260px)' : {
        marginBottom: '16px'
    },

    '@media(max-width: 860px)' : {
        marginRight: '0px',
        marginBottom: '16px',
        width: 'calc(50% - 8px)'
    },

    '@media(max-width: 560px)' : {
        width: '100%',
    }
});

const TransactionMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',

    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap'
    }
});

const TransactionMainSecondRowLeftBlock = styled('div')({
    display: 'flex',
    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap',
    },
    '@media(max-width: 860px)' : {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%',
    }
});

const TransactionMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

const TransactionMainThirdRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
})

type ActionsCellProps = {
    selected: boolean;
};

const ActionsCell = styled(TableCell)<ActionsCellProps>(({ theme, selected }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.mode === 'dark' && selected ? '#272F3D'
    : theme.palette.mode === 'dark' && !selected ? '#272930'
    : theme.palette.mode === 'light' && selected ? '#F0F8ED' : 'white',

    '@media(max-width: 768px)' : {
        position: 'relative',
    }
}));

const ActionsHeaderCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    right: '0',
    background: theme.palette.background.default,
    minWidth: '115px',
    width: '115px',
    boxSizing: 'border-box',

    '@media(max-width: 768px)' : {
        position: 'relative'
    }
}));

const StickyBorder = styled('div')(({ theme }) => (`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    border-left: 1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'
    };
`));

const ActionsContent = styled('div')({
    display: 'flex',
    gap: '8px'
});

const ActionIcon = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    'path': {
        fill: theme.palette.mode === 'light' ? "#575D68": 'white',
    },
    '&:hover':{
        'path': {
            fill: '#38AA72',
        },
    }
}));

const StyledCheckbox = styled(Checkbox)({
    color: '#979799',
    '&.Mui-checked': {
        color: '#38AA72',
    },
    '&.MuiCheckbox-indeterminate': {
        color: '#38AA72',
    }
});

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const TransactionMainSecondRowLeftButtons = styled('div')({
    '@media(max-width: 768px)' : {
        '& button': {
            boxSizing: 'border-box',
            fontSize: '12px',
            height: '30px',
            padding: '0 12px',
            marginRight: '11px',
            '&:last-child':{
                marginRight: '0px',
            }
        }
    }
});

const TransactionMainThirdRowButtons = styled('div')({
    '@media(max-width: 768px)' : {
        '& button': {
            boxSizing: 'border-box',
            fontSize: '12px',
            height: '30px',
            padding: '0 12px',
            marginRight: '0px',
            marginLeft: '11px',
            minWidth: '46px',
            '&::first-of-type':{
                marginLeft: '0px',
            }
        }
    }
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
    position: sticky;
    z-index: 10;
    top: 0;
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        };
        &:last-of-type{
            border-left: none;
        };
    }   
`));

const IconWrapper = styled('div')({
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const TableCellContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    minWidth: '130px'
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '500px',
    marginRight: '16px',
    marginTop: '20px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },

    '@media(max-width: 768px)' : {
        width: '100%',
        marginRight: '0px',
        marginTop: '20px'
    }
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
        color: '#38AA72'
    }
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const StyledMenuButton = styled(MenuButton)(({ theme }) => ({
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    padding: '0px',
    margin: '0px',
    'path': {
        fill: theme.palette.mode === 'light' ? "#575D68": 'white',
    }
}));

const StyledReactMenu = styled(ReactMenu)(({ theme }) => (`
    ${menuSelector.name} {
        background: ${theme.palette.background.paper};
        z-index: 10000;
    }

    ${menuItemSelector.name} {
        height: 28px;
        background: ${theme.palette.background.paper};
        color: ${theme.palette.text.primary};
        :hover {
            background: ${theme.palette.action.selected}
        }
    }
`));

const StatusInfoItem = styled('div')({
    marginBottom: '18px'
});

const StatusInfoContainer = styled('div')({
    'div': {
        '&:last-child': {
            marginBottom: '0px'
        }
    }
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        background: theme.palette.action.selected,
    }
}));

const StyledLink = styled(Link)(({ theme }) => (`
    text-decoration: none;
    cursor: pionter;
    color: ${theme.palette.text.primary};
`));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}))

const TableIconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

const TableIcon = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
});

const ColumnsFilter = styled('div')(`
    margin-bottom: 16px;
`)

// Fix for scrolles
const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    & .overlay-scroll-table{
        max-height: calc(100vh - 315px - 56px);
        @media(max-width: 1260px){
            max-height: calc(100vh - 370px - 56px);
        };
        @media(max-width: 560px){
            max-height: calc(100vh - 430px - 56px);
        }; 
    }
`));

const CheckboxTableCellContent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '58px',
    width: '58px'
});

const ElementExportItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    cursor: 'pointer',
    transition: 'all 200ms',
    '&:hover': {
        color: '#38AA72',
    }
});

const listColumns = [
    {title: 'Transaction ID', value: 'transaction_id'},
    {title: 'Merch.trans. ID', value: 'merchant_id'},
    {title: 'Currency', value: 'currency'},
    {title: 'Amount', value: 'amount'},
    {title: 'Payment system', value: 'payment_system'},
    {title: 'Payment method', value: 'payment_method'},
    {title: 'Status', value: 'status'},
    {title: 'Notify status', value: 'notify_status'},
    {title: 'Created', value: 'created'},
    {title: 'Updated', value: 'updated'},
]

class TransactionMain extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            anchorElExport: null,
            anchorElCurrency: null,
            anchorElPaymentSystem: null,
            anchorElStatus: null,
            anchorElPaymentMethod: null,
            anchorElNotifyStatus: null,
            startDateCreated: '',
            endDateCreated: '',
            startDateUpdated: '',
            endDateUpdated: '',
            clearDates: false,
            id: '',
            merchantTransactionId: '',
            transactionStatuses: [],
            notifyStatuses: [],
            paymentSystems: [],
            notifyModalOpen: false,
            newCallback: "",
            callbackUrl: '',
            checked: [],
            openNotifiesInfoSnackbar: false,
            openStatusesSnackbar: false,
            clearAll: false,
            anchorELColumnFilter: null,
            initValurColumns: [],
            currentValueColumns: [],
            showCheckbox: [],
            currency: [],
            paymentMethod: [],
        }
    };

    handleColumnFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorELColumnFilter: event.currentTarget})
    };
    handleColumnFilterMenuClose = () => {
        this.setState({anchorELColumnFilter: null});
    };
    onChangeColumnFilter = (data) => {
        localStorage.setItem('transactionColumns', JSON.stringify(data));
        this.setState({currentValueColumns: data})
        this.handleColumnFilterMenuClose();
    }

    handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null});
    };

    handleChangePage = (newPage: number) => {
        this.setState({page: newPage, checked: []}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    handlePaymentSystemMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentSystem: event.currentTarget})
    };

    handlePaymentSystemMenuClose = () => {
        this.setState({anchorElPaymentSystem: null});
    };

    handleCurrencyMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorECurrency: event.currentTarget})
    };

    handleCurrencyMenuClose = () => {
        this.setState({anchorECurrency: null});
    };

    handlePaymentMethodMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentMethod: event.currentTarget})
    };

    handlePaymentMethodMenuClose = () => {
        this.setState({anchorElPaymentMethod: null});
    };

    handleStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElStatus: event.currentTarget})
    };
    handleStatusMenuClose = () => {
        this.setState({anchorElStatus: null});
    };

    handleNotifyStatusMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElNotifyStatus: event.currentTarget})
    };
    handleNotifyStatusMenuClose = () => {
        this.setState({anchorElNotifyStatus: null});
    };

    handleNotifyModalOpen = (id) => {
        this.setState({notifyModalOpen: true, notifyId: id});
    };

    handleNotifyModalClose = () => {
        this.setState({notifyModalOpen: false});
    };

    setDatesCreated = (startDate, endDate) => {
        this.setState({startDateCreated: startDate, endDateCreated: endDate, clearDates: false});
    };

    setDatesUpdated = (startDate, endDate) => {
        this.setState({startDateUpdated: startDate, endDateUpdated: endDate, clearDates: false});
    };

    onResetAllFilters = () => {
        this.setState({
            startDateCreated: '',
            endDateCreated: '',
            startDateUpdated: '',
            endDateUpdated: '',
            clearDates: true,
            clearAll: true,
            transactionStatuses: [],
            notifyStatuses: [],
            paymentSystems: [],
            currency: [],
            paymentMethod: [],
            id: '',
            merchantTransactionId: '',
            page: 0
        }, () => {this.updateData()});
    };

    handleSearchSubmit = (prop) => (value) => {
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };

    handleCancelSearch = (prop) => () => {
        if (this.state[prop]) {
            this.setState({[prop]: ''}, () => {this.updateData()});
        };
    };

    onChangeStatusFilter = (data) => {
        this.setState({transactionStatuses: data, clearAll: false, page: 0}, () => {this.updateData()});
        this.handleStatusMenuClose();
    };

    onChangeNotifyStatusFilter = (data) => {
        this.setState({notifyStatuses: data, clearAll: false, page: 0}, () => {this.updateData()});
        this.handleNotifyStatusMenuClose();
    };

    onChangePaymentSystemFilter = (data) => {
        this.setState({paymentSystems: data, clearAll: false, page: 0}, () => {this.updateData()});
        this.handlePaymentSystemMenuClose();
    };

    onChangeCurrencyFilter = (data) => {
        this.setState({currency: data, clearAll: false, page: 0}, () => {this.updateData()});
        this.handleCurrencyMenuClose();
    };

    onChangePaymentMethodFilter = (data) => {
        this.setState({paymentMethod: data, clearAll: false, page: 0}, () => {this.updateData()});
        this.handlePaymentMethodMenuClose();
    };

    handleUpdateStatus = (id) => {
        const {updateStatusesRequest} = this.props;

        updateStatusesRequest(id).then(() => this.handleOpenStatusesSnackbar());
    };

    handleSetNotify = () => {
        const {notifyId, newCallback, callbackUrl, checked} = this.state;
        const {setNotifiesRequest} = this.props;

        const url = Boolean(newCallback) ? callbackUrl : null;
        const data = {
            "callbackUrl": url
        };
        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        if (notifyId) {
            setNotifiesRequest([{id: notifyId, data: JSON.stringify(data)}]).then(() => this.handleOpenNotifiesInfoSnackbar());
        } else {
            const requestData = checked.map(item => {
                return {id: item.id, data: JSON.stringify(data)}
            });

            setNotifiesRequest(requestData).then(() => this.handleOpenNotifiesInfoSnackbar());
        };

        this.handleNotifyModalClose();
    };

    handleChangeCallback = event => {
        this.setState({newCallback: event.target.value});
        
    };

    handleSetCallbackUrl = event => {
        this.setState({callbackUrl: event.target.value});
    };

    handleSetChecked = (item) => event => {
        if (event.target.checked) {
            this.setState((state) => ({checked: [...state.checked, item]}))
        } else {
            this.setState((state) => ({checked: [...state.checked.filter(i => i.id !== item.id)]}))
        }
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = this.props.transactions.map(item => ({id: item.id, urls: item.urls}));

            this.setState({checked: newSelected});
            return;
        } else {
            this.setState({checked: []})
        };
    };

    handleUpdateStatusesSelected = () => {
        const {updateStatusesRequest} = this.props;
        const {checked} = this.state;

        const ids = checked.map(item => item.id);

        updateStatusesRequest(ids).then(() => this.handleOpenStatusesSnackbar());
    };

    handleOpenNotifiesInfoSnackbar = () => {
        this.setState({openNotifiesInfoSnackbar: true});
    };

    handleCloseNotifiesInfoSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        this.setState({openNotifiesInfoSnackbar: false});
    };

    handleOpenStatusesSnackbar = () => {
        this.setState({openStatusesSnackbar: true});
    };

    handleCloseStatusesSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        this.setState({openStatusesSnackbar: false});
    };

    updateData = () => {
        const {
            page,
            startDateCreated,
            endDateCreated,
            startDateUpdated,
            endDateUpdated,
            id,
            merchantTransactionId,
            transactionStatuses,
            notifyStatuses,
            paymentSystems,
            currency,
            paymentMethod,
        } = this.state;

        const {
            getTransactionsRequest, 
            getPaymentMethodsRequest,
            way, 
            activeNode,
            routsUser,
        } = this.props;

        const data = {
            'page': page + 1,
            'way[]': way,
            'createdAt': startDateCreated && endDateCreated ? `${format(new Date(startDateCreated), 'yyyy-MM-dd HH:mm')} - ${format(new Date(endDateCreated), 'yyyy-MM-dd HH:mm')}` : null,
            'updatedAt': startDateUpdated && endDateUpdated ? `${format(new Date(startDateUpdated), 'yyyy-MM-dd HH:mm')} - ${format(new Date(endDateUpdated), 'yyyy-MM-dd HH:mm')}` : null,
            'id': id,
            'merchantTransactionId': merchantTransactionId,
            'status[]': transactionStatuses,
            'notifyStatus[]': notifyStatuses,
            'paymentSystem.id[]': paymentSystems,
            'currency[]': currency,
            'paymentMethod[]': paymentMethod,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        if(routsUser.some(rout => rout.child === '/transaction/deposit' || '/transaction/withdraw' || '/transaction/view')){
            getTransactionsRequest(data);
            if(activeNode.id !== 1) getPaymentMethodsRequest();
        }
    };

    handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElExport: event.currentTarget})
    };
    handleCloseExportMenu = () => {
        this.setState({anchorElExport: null});
    };

    hendleExportTable = () => {
        const {
            page,
            startDateCreated,
            endDateCreated,
            startDateUpdated,
            endDateUpdated,
            id,
            merchantTransactionId,
            transactionStatuses,
            notifyStatuses,
            paymentSystems,
            currency,
            paymentMethod,
        } = this.state;

        const {
            way, 
            transactionsDownload,
        } = this.props;

        const data = {
            'page': page + 1,
            'way[]': way,
            'createdAt': startDateCreated && endDateCreated ? `${format(new Date(startDateCreated), 'yyyy-MM-dd HH:mm')} - ${format(new Date(endDateCreated), 'yyyy-MM-dd HH:mm')}` : null,
            'updatedAt': startDateUpdated && endDateUpdated ? `${format(new Date(startDateUpdated), 'yyyy-MM-dd HH:mm')} - ${format(new Date(endDateUpdated), 'yyyy-MM-dd HH:mm')}` : null,
            'id': id,
            'merchantTransactionId': merchantTransactionId,
            'status[]': transactionStatuses,
            'notifyStatus[]': notifyStatuses,
            'paymentSystem.id[]': paymentSystems,
            'currency[]': currency,
            'paymentMethod[]': paymentMethod,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        transactionsDownload(data);
        this.handleCloseExportMenu()
    };

    setColumnLocalStorage = () => {
        this.setState({
            currentValueColumns: JSON.parse(localStorage.getItem('transactionColumns')),
            initValurColumns: JSON.parse(localStorage.getItem('transactionColumns')),
        })
    };

    handleCheckboxCellMouseEnter = (number) => () => {
        this.setState((state) =>  {
            return {showCheckbox: [...state.showCheckbox, number]}
        });
    };

    handleCheckboxCellMouseLeave = (number) => () => {
        this.setState((state) => {
            return {showCheckbox: state.showCheckbox.filter(item => item !== number)}
        });
    };

    componentDidMount() {
        const {
            getPaymentSystemsListRequest, 
            getStatusesListRequest, 
            getNotifyStatusesListRequest, 
            currencyListRequest,
            getPaymentMethodsRequest,
            activeNode,
            routsUser,
            isSuperadmin,
            userRoles,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/transaction/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
            getPaymentSystemsListRequest();
            getStatusesListRequest();
            getNotifyStatusesListRequest();
            currencyListRequest();

            if(activeNode.id !== 1) getPaymentMethodsRequest();

            if( localStorage.getItem('transactionColumns') ){
                this.setColumnLocalStorage()
            } else {
                localStorage.setItem('transactionColumns', JSON.stringify(listColumns.map(item => item.value)))
            }
        }

        // this.updateData();
        // getPaymentSystemsListRequest();
        // getStatusesListRequest();
        // getNotifyStatusesListRequest();
        // currencyListRequest();

        // if(activeNode.id !== 1) getPaymentMethodsRequest();

        // if( localStorage.getItem('transactionColumns') ){
        //     this.setColumnLocalStorage()
        // } else {
        //     localStorage.setItem('transactionColumns', JSON.stringify(listColumns.map(item => item.value)))
        // }
        
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeNode.id !== this.props.activeNode.id) {
            this.setState({page: 0}, () => {this.updateData()});
        };

        if (prevProps.timezoneCurrent !== this.props.timezoneCurrent){
            this.setState({page: 0}, () => {this.updateData()});
        };

        let localColumn = JSON.parse(localStorage.getItem('transactionColumns'));
        if(!_.isEqual( prevState.initValurColumns, localColumn)){
            this.setColumnLocalStorage()
        }
    };

    render() {
        const {
            id,
            merchantTransactionId,
            anchorElPaymentSystem,
            anchorElStatus,
            anchorElNotifyStatus,
            page,
            rowsPerPage,
            clearDates,
            transactionStatuses,
            notifyStatuses,
            notifyModalOpen,
            newCallback,
            callbackUrl,
            checked,
            openNotifiesInfoSnackbar,
            openStatusesSnackbar,
            paymentSystems,
            paymentMethod,
            clearAll,
            currency,
            anchorELColumnFilter,
            initValurColumns,
            currentValueColumns,
            showCheckbox,
            anchorECurrency,
            anchorElPaymentMethod,
            anchorElExport,
        } = this.state;

        const {
            transactions,
            isLoading,
            notifiesInfo,
            statuses,
            way,
            isError,
            paymentSystemsList,
            notifyStatusesList,
            statusesList,
            errorMessage,
            currencyDropdownList,
            paymentMethodDropdownList,
            activeNode,
            userRoles,
            routsUser,
            isSuperadmin,
        } = this.props;

        const isPaymentSystemFilterOpen = Boolean(anchorElPaymentSystem);
        const isStatusFilterOpen = Boolean(anchorElStatus);
        const isNotifyStatusFilterOpen = Boolean(anchorElNotifyStatus);
        const isColumnFilterOpen = Boolean(anchorELColumnFilter);
        const isCurrencyOpen = Boolean(anchorECurrency)
        const isPymentMethodOpen = Boolean(anchorElPaymentMethod);
        const isExportOpen = Boolean(anchorElExport);

        const accessedRole = routsUser.some(rout => rout.child.includes('/transaction/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleNotify = routsUser.some(rout => rout.child === '/transaction/notify') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleUpdateStatus = routsUser.some(rout => rout.child === '/transaction/update-status') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleView = routsUser.some(rout => rout.child === '/transaction/view') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        const accessedRoleShortView = routsUser.some(rout => rout.child === '/transaction/shortview') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        return (
            <PageContainer>

                {!accessedRole && <AccessDeniedAlert/>}

                {accessedRole &&
                    <>
                        <TransactionMainHeader>
                            <TransactionMainHeaderText
                                data-testid="titleTranactionsMainPage"
                            >
                                {way[0] === 'deposit' ? 'Deposit' : 'Withdraw'}
                            </TransactionMainHeaderText>
                            <Structure/>
                        </TransactionMainHeader>
                        
                        <TransactionMainSecondRow>
                            <TransactionMainSecondRowLeftBlock>
                                <RangePickerWrapper>
                                    <DateRangePicker
                                        label="Date created"
                                        setDates={this.setDatesCreated}
                                        clearDates={clearDates}
                                        withTimePicker
                                    />
                                </RangePickerWrapper>
                                <RangePickerWrapper>
                                    <DateRangePicker
                                        label="Date updated"
                                        setDates={this.setDatesUpdated}
                                        clearDates={clearDates}
                                        withTimePicker
                                    />
                                </RangePickerWrapper>
                            </TransactionMainSecondRowLeftBlock>
                            <TransactionMainSecondRowRightBlock>
                                <TransactionMainSecondRowLeftButtons>
                                    <Button 
                                        sx={{marginRight: '16px'}} 
                                        onClick={this.updateData}
                                        data-testid="buttonShowTranactionsMainPage"
                                    >
                                        SHOW
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        onClick={this.onResetAllFilters}
                                        data-testid="buttonClearAllTranactionsMainPage"
                                    >
                                        CLEAR ALL
                                    </Button>
                                </TransactionMainSecondRowLeftButtons>
                            </TransactionMainSecondRowRightBlock>
                        </TransactionMainSecondRow>

                        <TransactionMainThirdRow>
                            <ColumnsFilter>
                                <Button
                                    // variant="outlined"
                                    onClick={this.handleColumnFilterMenuClick}
                                    title='Selected columns'
                                >
                                    <ListAltIcon sx={{marginRight: '4px'}}/>
                                    <ExpandMoreIcon/>
                                </Button>
                                <StyledMenu
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorELColumnFilter}
                                    open={isColumnFilterOpen}
                                    onClose={this.handleColumnFilterMenuClose}
                                    PaperProps={{
                                        style: {
                                            width: '210px',
                                        },
                                    }}
                                >
                                    <ColumnFilter
                                        filterItems={listColumns.map(item => {
                                            return {title: item.title, value: item.value};
                                        })}
                                        initialData={initValurColumns}
                                        onApplyFilter={this.onChangeColumnFilter}
                                        onClearAll={this.onChangeColumnFilter}
                                    />
                                    
                                </StyledMenu>

                                <Button
                                    onClick={this.handleOpenExportMenu}
                                    title='Export table'
                                    sx={{marginLeft: '16px'}}
                                >
                                    <FileDownloadIcon sx={{marginRight: '4px'}}/>
                                    <span>Export</span>
                                    <ExpandMoreIcon/>
                                </Button>
                                <StyledMenu
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorElExport}
                                    open={isExportOpen}
                                    onClose={this.handleCloseExportMenu}
                                    PaperProps={{
                                        style: {
                                            width: '210px',
                                            padding: '16px 10px 0px 10px',
                                        },
                                    }}
                                >
                                    <ElementExportItem
                                        onClick={this.hendleExportTable}
                                    >
                                        <TableViewIcon sx={{marginRight: '4px'}}/>
                                        .CSV file
                                    </ElementExportItem>
                                </StyledMenu>
                            </ColumnsFilter>
                            <TransactionMainThirdRowButtons>
                                {accessedRoleNotify &&
                                    <Button 
                                        title='Notify'
                                        sx={{margin: '0 16px'}} 
                                        variant="outlined" 
                                        disabled={!checked.length} 
                                        onClick={() => this.handleNotifyModalOpen(null)}
                                        data-testid="buttonNotifyAllElements"
                                    >
                                        <NotificationsSvg size="22px"/>
                                    </Button>
                                }
                                {accessedRoleUpdateStatus &&
                                    <Button
                                        title='Update'
                                        variant="outlined"
                                        disabled={!checked.length}
                                        onClick={this.handleUpdateStatusesSelected}
                                        data-testid="buttonUpdateAllElements"
                                    >
                                        <CachedSvg size="22px"/>
                                    </Button>
                                }
                            </TransactionMainThirdRowButtons>
                        </TransactionMainThirdRow>

                        <StyledPaperTable>   
                            <StyledTableContainer>

                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >

                                    <Table>
                                        <StyledTableHead>
                                            <TableRow>
                                                <TableCell padding={"checkbox"} onMouseOver={this.handleCheckboxCellMouseEnter(0)} onMouseOut={this.handleCheckboxCellMouseLeave(0)}>
                                                    <CheckboxTableCellContent>
                                                        {showCheckbox.includes(0) || (Boolean(checked.length && checked.length < transactions.length)) || (Boolean(checked.length) && (checked.length === transactions.length)) ? 
                                                            <StyledCheckbox onChange={this.handleSelectAllClick} indeterminate={Boolean(checked.length && checked.length < transactions.length)} checked={Boolean(checked.length) && checked.length === transactions.length}/>
                                                        :
                                                            '№'
                                                        }
                                                    </CheckboxTableCellContent>
                                                </TableCell>
                                                
                                                {currentValueColumns.includes('transaction_id') &&
                                                    <SerachTableHeader 
                                                        label='Transaction ID'
                                                        handleSearchSubmit={this.handleSearchSubmit('id')}
                                                        handleCancelSearch={this.handleCancelSearch('id')}
                                                        isActiveFilter={Boolean(id)}
                                                    />
                                                }

                                                {currentValueColumns.includes('merchant_id') &&
                                                    <SerachTableHeader 
                                                        label='Merch.trans. ID'
                                                        handleSearchSubmit={this.handleSearchSubmit('merchantTransactionId')}
                                                        handleCancelSearch={this.handleCancelSearch('merchantTransactionId')}
                                                        isActiveFilter={Boolean(merchantTransactionId)}
                                                    />
                                                }

                                                {currentValueColumns.includes('currency') &&
                                                    <TableCellWithActiveClass
                                                        className={ currency.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            <span
                                                                data-testid="labelTitleCurrency"
                                                            >
                                                                Currency
                                                            </span>
                                                            <IconWrapper>
                                                                <FilterListSvg 
                                                                    size="16px" 
                                                                    onClick={this.handleCurrencyMenuClick}
                                                                    data-testid="buttonFilterTitleCurrency"
                                                                />
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorECurrency}
                                                                open={isCurrencyOpen}
                                                                onClose={this.handleCurrencyMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={(currencyDropdownList || []).map(item => ({
                                                                        title: item.id, value: item.id
                                                                    }))}
                                                                    initialData={currency}
                                                                    onApplyFilter={this.onChangeCurrencyFilter}
                                                                    onClearAll={this.onChangeCurrencyFilter}
                                                                    withSearch
                                                                    clearAll={clearAll}
                                                                    label='Currency'
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>
                                                }

                                                {currentValueColumns.includes('amount') &&
                                                    <TableCell>
                                                        <span
                                                            data-testid="labelTitleAmount"
                                                        >
                                                            Amount
                                                        </span>
                                                    </TableCell>
                                                }

                                                {currentValueColumns.includes('payment_system') &&
                                                    <TableCellWithActiveClass
                                                        className={ paymentSystems.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            <span
                                                                data-testid="labelTitlePaymentSystem"
                                                            >
                                                                Payment system
                                                            </span>
                                                            <IconWrapper>
                                                                <FilterListSvg 
                                                                    size="16px" 
                                                                    onClick={this.handlePaymentSystemMenuClick}
                                                                    data-testid="buttonFilterTitlePaymentSystem"
                                                                />
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElPaymentSystem}
                                                                open={isPaymentSystemFilterOpen}
                                                                onClose={this.handlePaymentSystemMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={paymentSystemsList.map(item => {
                                                                        return {title: item.name, value: item.id};
                                                                    })}
                                                                    initialData={paymentSystems}
                                                                    onApplyFilter={this.onChangePaymentSystemFilter}
                                                                    onClearAll={this.onChangePaymentSystemFilter}
                                                                    withSearch
                                                                    clearAll={clearAll}
                                                                    label='Payment system'

                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>
                                                }

                                                {currentValueColumns.includes('payment_method') &&
                                                    <TableCellWithActiveClass
                                                        className={ paymentMethod.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent sx={{whiteSpace: 'nowrap'}}>
                                                            <span
                                                                data-testid="labelTitlePaymentMethod"
                                                            >
                                                                Payment method
                                                            </span>
                                                            { (activeNode?.id !== 1) && 
                                                                <>
                                                                    <IconWrapper>
                                                                        <FilterListSvg size="16px" onClick={this.handlePaymentMethodMenuClick}/>
                                                                    </IconWrapper>
                                                                    <StyledMenu
                                                                        MenuListProps={{
                                                                        'aria-labelledby': 'long-button',
                                                                        }}
                                                                        anchorEl={anchorElPaymentMethod}
                                                                        open={isPymentMethodOpen}
                                                                        onClose={this.handlePaymentMethodMenuClose}
                                                                        PaperProps={{
                                                                            style: {
                                                                                width: '210px',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <TableFilter
                                                                            filterItems={(paymentMethodDropdownList || []).map(item => {
                                                                                return {title: item.title, value: item.code};
                                                                            })}
                                                                            initialData={paymentMethod}
                                                                            onApplyFilter={this.onChangePaymentMethodFilter}
                                                                            onClearAll={this.onChangePaymentMethodFilter}
                                                                            withSearch
                                                                            clearAll={clearAll}
                                                                            label='Payment method'
                                                                        />
                                                                    </StyledMenu> 
                                                                </>
                                                            }
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>
                                                }

                                                {currentValueColumns.includes('status') &&
                                                    <TableCellWithActiveClass
                                                        className={ transactionStatuses.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            <span
                                                                data-testid="labelTitleStatus"
                                                            >
                                                                Status
                                                            </span>
                                                            <IconWrapper>
                                                                <FilterListSvg 
                                                                    size="16px" 
                                                                    onClick={this.handleStatusMenuClick}
                                                                    data-testid="buttonFilterTitleStatus"
                                                                />
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElStatus}
                                                                open={isStatusFilterOpen}
                                                                onClose={this.handleStatusMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={Object.entries(statusesList).map(item => {
                                                                        return {title: item[1], value: item[0]};
                                                                    })}
                                                                    initialData={transactionStatuses}
                                                                    onApplyFilter={this.onChangeStatusFilter} 
                                                                    onClearAll={this.onChangeStatusFilter}
                                                                    clearAll={clearAll}
                                                                    label='Status'
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>
                                                }

                                                {currentValueColumns.includes('notify_status') &&
                                                    <TableCellWithActiveClass
                                                        className={ notifyStatuses.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            <span
                                                                data-testid="labelTitleNotifyStatus"
                                                            >
                                                                Notify status
                                                            </span>
                                                            <IconWrapper>
                                                                <FilterListSvg 
                                                                    size="16px" 
                                                                    onClick={this.handleNotifyStatusMenuClick}
                                                                    data-testid="buttonFilterTitleNotifyStatus"
                                                                />
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElNotifyStatus}
                                                                open={isNotifyStatusFilterOpen}
                                                                onClose={this.handleNotifyStatusMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={notifyStatusesList.map((item, index) => {
                                                                        return {title: item, value: index}
                                                                    })}
                                                                    initialData={notifyStatuses}
                                                                    onApplyFilter={this.onChangeNotifyStatusFilter} 
                                                                    onClearAll={this.onChangeNotifyStatusFilter}
                                                                    clearAll={clearAll}
                                                                    label='Notify status'
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>
                                                }

                                                {currentValueColumns.includes('created') &&
                                                    <TableCell>
                                                        <span
                                                            data-testid="labelTitleCreated"
                                                        >
                                                            Created
                                                        </span>
                                                    </TableCell>
                                                }

                                                {currentValueColumns.includes('updated') &&
                                                    <TableCell>
                                                        <span
                                                            data-testid="labelTitleUpdated"
                                                        >
                                                            Updated
                                                        </span>
                                                    </TableCell>
                                                }

                                                {(accessedRoleNotify || accessedRoleUpdateStatus || accessedRoleShortView || accessedRoleView) &&
                                                    <ActionsHeaderCell>
                                                        <StickyBorder>
                                                            <span
                                                                data-testid="labelTitleAction"
                                                            >
                                                                Action
                                                            </span>
                                                        </StickyBorder>
                                                    </ActionsHeaderCell>
                                                }

                                            </TableRow>
                                        </StyledTableHead>

                                        <TableBody>
                                            {transactions.length > 0 ? transactions.map((row, index) => {
                                                const number = index + 1 + page * rowsPerPage;
                                                return (
                                                    <StyledTableRow selected={checked.filter(i => i.id === row.id).length > 0} key={row.id}>

                                                        <TableCell padding="checkbox" onMouseEnter={this.handleCheckboxCellMouseEnter(number)} onMouseLeave={this.handleCheckboxCellMouseLeave(number)}>
                                                            <CheckboxTableCellContent>
                                                                {showCheckbox.includes(number) || checked.filter(i => i.id === row.id).length > 0 ? 
                                                                    <StyledCheckbox onChange={this.handleSetChecked({id: row.id, urls: row.urls})} checked={checked.filter(i => i.id === row.id).length > 0}/>
                                                                :
                                                                    number
                                                                }
                                                            </CheckboxTableCellContent>
                                                        </TableCell>


                                                        {currentValueColumns.includes('transaction_id') &&
                                                            <TableCell>{row.id}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('merchant_id') &&
                                                            <TableCell>{row.merchantTransactionId}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('currency') &&
                                                            <TableCell>{row.currency}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('amount') &&
                                                            <TableCell>{Number(row.amount)}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('payment_system') &&
                                                            <TableCell sx={{minWidth: '120px'}}>{row.paymentSystem.name}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('payment_method') &&
                                                            <TableCell sx={{minWidth: '120px'}}>{row.paymentMethod}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('status') &&
                                                            <TableCell>{row.status}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('notify_status') &&
                                                            <TableCell sx={{minWidth: '90px'}}>{row.notifyStatus}</TableCell>
                                                        }

                                                        {currentValueColumns.includes('created') &&
                                                            <TableCell sx={{minWidth: '140px'}}>
                                                                {
                                                                    row.createdAt
                                                                }
                                                            </TableCell>
                                                        }

                                                        {currentValueColumns.includes('updated') &&
                                                            <TableCell sx={{minWidth: '140px'}}>
                                                                {
                                                                    row.updatedAt
                                                                }
                                                            </TableCell>
                                                        }

                                                        {(accessedRoleNotify || accessedRoleUpdateStatus || accessedRoleShortView || accessedRoleView) &&
                                                            <ActionsCell selected={Boolean(checked.filter(i => i.id === row.id).length > 0)}>
                                                                <StickyBorder>
                                                                    <ActionsContent
                                                                            sx={{margin: !(accessedRoleNotify || accessedRoleUpdateStatus) ? '0 auto' : '0'}}
                                                                    > 

                                                                            {accessedRoleNotify &&
                                                                                <Tooltip title='Notify' placement="top">
                                                                                    <ActionIcon>
                                                                                        <NotificationsSvg size="22px" onClick={() => this.handleNotifyModalOpen(row.id)}/>
                                                                                    </ActionIcon>
                                                                                </Tooltip>
                                                                            }

                                                                            {accessedRoleUpdateStatus && 
                                                                                <Tooltip title='Update' placement="top">
                                                                                    <ActionIcon>
                                                                                        <CachedSvg size="22px" onClick={() => this.handleUpdateStatus([row.id])}/>
                                                                                    </ActionIcon>
                                                                                </Tooltip>
                                                                            }

                                                                            {(accessedRoleShortView || accessedRoleView) &&
                                                                                <StyledReactMenu
                                                                                    menuButton={
                                                                                        <Tooltip title='More' placement="top">
                                                                                            <StyledMenuButton>
                                                                                                <MoreSvg size="22px"/>
                                                                                            </StyledMenuButton>
                                                                                        </Tooltip>
                                                                                    }
                                                                                    portal={true}
                                                                                    align="end"
                                                                                >
                                                                                    {accessedRoleShortView && 
                                                                                        <StyledLink to={`/transactions/short-view/${row.id}`}>
                                                                                            <ReactMenuItem>Short view</ReactMenuItem>
                                                                                        </StyledLink>
                                                                                    }
                                                                                    
                                                                                    {accessedRoleView &&
                                                                                        <StyledLink to={`/transactions/view/${row.id}`}>
                                                                                            <ReactMenuItem>View</ReactMenuItem>
                                                                                        </StyledLink>
                                                                                    }
                                                                                </StyledReactMenu>
                                                                            }
                                                                    </ActionsContent>
                                                                </StickyBorder>
                                                            </ActionsCell>
                                                        }

                                                    </StyledTableRow>
                                                )
                                            })
                                            :   <StyledTableRow>
                                                    <TableCell 
                                                            colSpan={12} 
                                                            sx={{
                                                                borderBottom: 'none',
                                                                position: 'relative',
                                                                height: window.screen.width > 1260 
                                                                    ? 'calc(100vh - 315px - 56px - 92px)'
                                                                    : window.screen.width > 560   
                                                                        ? 'calc(100vh - 370px - 56px - 92px)'
                                                                        : 'calc(100vh - 430px - 56px - 92px)',
                                                        }}
                                                    >
                                                        <TableIconWrapper 
                                                            sx={{
                                                                position: 'absolute', 
                                                                top: '0', 
                                                                left: '0',
                                                                right: '0',
                                                                bottom: '0',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <TableIcon>
                                                                <EmptyDataSvg height="80px"/>
                                                                No results found.
                                                            </TableIcon>
                                                        </TableIconWrapper>
                                                    </TableCell>
                                                </StyledTableRow>
                                            }
                                        </TableBody>

                                    </Table>

                                </OverlayScrollbarsComponent>
                            </StyledTableContainer>
                            
                            <PaginationPPS
                                page={page}
                                rows={rowsPerPage}
                                onPageChange={this.handleChangePage}
                                isLastPage={!(transactions?.length === 20)}
                            />

                        </StyledPaperTable>

                        <Modal 
                            open={notifyModalOpen}
                            onClose={this.handleNotifyModalClose}
                        >
                            <div>
                                <NotifyModal
                                    title="Update status"
                                    confirmButtonText='SEND NOTIFY'
                                    cancelButtonText='CANCEL'
                                    onClose={this.handleNotifyModalClose}
                                    width="500px"
                                    onConfirm={this.handleSetNotify}
                                    onCancel={this.handleNotifyModalClose}
                                >
                                    To update status on the platform click on the “send notify” or insert a new Callback URL
                                    <StyledFormControl>
                                        <RadioGroup
                                            name="radio-buttons-group"
                                            onChange={this.handleChangeCallback}
                                            value={newCallback}
                                        >
                                            <StyledFormControlLabel value="" control={<StyledRadio />} label="Current Callback" />
                                            <StyledFormControlLabel value="true" control={<StyledRadio />} label="New Callback" />
                                        </RadioGroup>
                                    </StyledFormControl>
                                    {Boolean(newCallback) && <Input sx={{marginTop: '20px'}} onChange={this.handleSetCallbackUrl} value={callbackUrl} placeholder="New URL"></Input>}
                                </NotifyModal>
                            </div>    
                        </Modal>

                        <Snackbar open={openNotifiesInfoSnackbar} autoHideDuration={6000} onClose={this.handleCloseNotifiesInfoSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                            {!isError ? 
                            <InfoPopup
                                dataTestid='snakebarNotifyTransactions'
                                severity="info"
                                headerText="Notify info"
                            >
                                <StatusInfoContainer>
                                    {notifiesInfo.map((item) => {
                                        return <StatusInfoItem key={item.id}>{`ID: ${item.id} - ${item.status}`}</StatusInfoItem>
                                    })}
                                </StatusInfoContainer>
                            </InfoPopup> :
                            <InfoPopup
                                dataTestid='snakebarNotifyTransactions'
                                severity="error"
                                headerText="Server error"
                                infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                            />}
                        </Snackbar>

                        <Snackbar open={openStatusesSnackbar} autoHideDuration={6000} onClose={this.handleCloseStatusesSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                            {!isError ?
                            <InfoPopup
                                dataTestid='snakebarStstusesTransactions'
                                severity="info"
                                headerText="Notify info"
                            >
                                <StatusInfoContainer>
                                    {statuses.map((item) => {
                                        return <StatusInfoItem key={item.id}>{`ID: ${item.id} - ${item.status}`}</StatusInfoItem>
                                    })}
                                </StatusInfoContainer>
                            </InfoPopup> :
                            <InfoPopup
                                dataTestid='snakebarStstusesTransactions'
                                severity="error"
                                headerText="Server error"
                                infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                            />}
                        </Snackbar>
                    </>
                }

                {isLoading && <Loader />}
            </PageContainer>
        )
    }
};

export default compose(
    connect((state: RootState) => ({
        isLoading: state.transactions.isLoading,
        transactions: state.transactions.transactions['hydra:member'],
        transactionsCount: state.transactions.transactions['hydra:totalItems'],
        notifiesInfo: state.transactions.notifiesInfo,
        statuses: state.transactions.statuses,
        isError: state.transactions.isError,
        errorMessage: state.transactions.errorMessage,
        paymentSystemsList: state.paymentSystems.paymentSystemsList['hydra:member'],
        notifyStatusesList: state.transactions.notifyStatusesList,
        statusesList: state.transactions.statusesList,
        activeNode: state.nodeManagement.activeNode,
        timezoneCurrent: state.currentUser.userData.timezone,
        currencyDropdownList: state.currencyList.currencyList['hydra:member'],
        paymentMethodDropdownList: state.transactions.paymentMethodsList['hydra:member'],
        userRoles: state.currentUser.userData.roles,
        routsUser: state.currentUser.userData.routs,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        getTransactionsRequest, 
        updateStatusesRequest, 
        setNotifiesRequest, 
        getPaymentSystemsListRequest, 
        getStatusesListRequest, 
        getNotifyStatusesListRequest,
        currencyListRequest,
        getPaymentMethodsRequest,
        transactionsDownload,
    })
)(TransactionMain)
