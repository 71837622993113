import {ActionTypes} from '../reducers/buildApi';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_BUILD_API_START
});

export const requestSuccess = buildInfo => {
	return {
		type: ActionTypes.FETCH_BUILD_API_SUCCESS,
		payload: buildInfo
	};
};

export const requestFailure = error => {
	return {
		type: ActionTypes.FETCH_BUILD_API_FAILURE,
		payload: error
	}
};

export const buildApiRequest = data => dispatch => {
	dispatch(requestStart());
    
	return API.buildApi
		.buildApiStart()
		.then(res => {
			dispatch(requestSuccess(res.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};