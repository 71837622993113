import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {createNodeRequest, getNodesTreeRequest, updateNodeRequest, setActiveNodeRequest} from '../../actions/nodeManagement';

const AddFolderFormContainer = styled('div')(({ theme }) => ({
    height: '100%',
    maxWidth: '550px',
    width: '100%',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    position: 'relative',
    padding: '32px 32px 0px 32px',
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginBottom: '16px',
    color: theme.palette.text.primary,
}));

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const ButtonsContainer = styled('div')({
    padding: '20px 0 32px 0',
});

const TechnicalForm = ({onClose, createNodeRequest, activeNode, isEdit, updateNodeRequest, handleOpenSnackbar, setActiveNodeRequest}: any) => {    
    const [name, setName] = useState(isEdit ? activeNode.name : '');
    const handleSetName = (event) => {
        setName(event.target.value)
    };

    const [note, setNote] = useState(isEdit ? activeNode.note : '');
    const handleSetNote = (event) => {
        setNote(event.target.value)
    };

    const handleSubmit = () => {
        const data = {
            parentId: isEdit ? activeNode.parentId : activeNode.id,
            name,
            note
        };

        if (isEdit) {
            updateNodeRequest(activeNode.id, JSON.stringify(data)).then(() => handleOpenSnackbar('Technical was successfully updated', 'Technical not updated')).then(() => setActiveNodeRequest(activeNode.id));
        } else {
            createNodeRequest('technical', JSON.stringify(data)).then(() => handleOpenSnackbar('Technical was successfully created', 'Technical not created'));
        }

        onClose();
    };

    const isFormValid = () => {
        return isEdit ? 
            ((name !== activeNode.name) && name) ||
            (note !== activeNode.note)
            : name;
    };

    return (
        <AddFolderFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <WrapFields>
                <Title>{isEdit ? 'Edit technical' : 'Add technical'}</Title>
                {!isEdit && <Input label="Root folder *" sx={{marginTop: '20px'}} value={`${activeNode.id}. ${activeNode.name}`} disabled></Input>}
                <Input label="Name *" sx={{marginTop: '20px'}} onChange={handleSetName} value={name}></Input>
                <Input label="Note" sx={{marginTop: '20px'}} onChange={handleSetNote} value={note}></Input>
            </WrapFields>
            <ButtonsContainer>
                <Button sx={{marginRight: '12px'}} onClick={handleSubmit} disabled={!isFormValid()}>{isEdit ? 'UPDATE' : 'CREATE'}</Button>
                <Button variant="outlined" onClick={onClose}>CANCEL</Button>
            </ButtonsContainer>
        </AddFolderFormContainer>
    )
};

export default compose(
    connect((state: RootState | any) => ({
        activeNode: state.nodeManagement.activeNode,
    }), {createNodeRequest, getNodesTreeRequest, updateNodeRequest, setActiveNodeRequest})
)(TechnicalForm);