import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { compose } from "redux";
import { connect } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Button } from "../../components/button";
import {requestCleanerRequest} from '../../actions/requestsCleaner';
import { RootState } from '../../store/configure-store';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => (`
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    display: flex;
    flex-direction: column;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const RequestsCleanerPageHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    @media(max-width: 768px){
        flex-wrap: wrap;
    }
`);

const RequestsCleanerPageHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 20px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
        padding-bottom: 16px;
    }
`));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '500px',
    marginRight: '16px',
    marginTop: '20px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },

    '@media(max-width: 768px)' : {
        width: '100%',
        marginRight: '0px',
        marginTop: '20px'
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
        color: '#38AA72'
    }
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const cleanAfterList = [1,2,3,4,5,6,7,8,9,10,11,12,18,24,30,36,42,48,54,60];
const listTypesClean = [
    {value: 0,label: 'No clean'},
    {value: 1,label: 'Once clean'},
    {value: 2,label: 'Daily clean'},
];

type PropsType = {
    requestCleanerRequest: (data: string) => void;
    routsUser: Array<any>; 
    userRoles: Array<any>; 
    isSuperadmin: any;
}

const RequestCleaner: React.FC<PropsType> = ({
    requestCleanerRequest,
    routsUser, 
    userRoles, 
    isSuperadmin,
}) => {
    const [cleanAfter, setCleanAfter] = useState<string>('');
    const handleChangeCleanAfter = (event) => {
        setCleanAfter(event.target.value);
    };

    const [clean, setClean] = useState<string>('');
    const handleChangeClean = (event) => {
        setClean(event.target.value);
    };

    const handleSubmit = () => {
        const data = {
            cleanAfter: Number(cleanAfter),
            clean: Number(clean)
        };
        requestCleanerRequest(JSON.stringify(data));
    };

    const accessedRole = routsUser.some(rout => rout.child.includes('/site/requests-cleaner')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    return (
        <PageContainer>

            {!accessedRole && <AccessDeniedAlert/>}

            {accessedRole &&
                <>
                    <RequestsCleanerPageHeader>
                        <RequestsCleanerPageHeaderText>
                            Requests Cleaner
                        </RequestsCleanerPageHeaderText>
                    </RequestsCleanerPageHeader>
                    <StyledFormControl size="small">
                        <InputLabel>Save requests for (Months)</InputLabel>
                        <Select
                            value={cleanAfter}
                            label="Save requests for (Months)"
                            onChange={handleChangeCleanAfter}
                        >
                            {cleanAfterList.map(time => <StyledMenuItem key={time} value={time}>{time}</StyledMenuItem>)}
                        </Select>
                    </StyledFormControl>
                    <StyledFormControl>
                        <FormLabel>Clean type</FormLabel>
                        <RadioGroup
                            name="radio-buttons-group"
                            onChange={handleChangeClean}
                            value={clean}
                        >
                            {listTypesClean.map(item => <StyledFormControlLabel key={Math.random()} {...item} control={<StyledRadio />} />)}
                        </RadioGroup>
                    </StyledFormControl>
                    <Button sx={{marginTop: '20px', width: '100px'}} disabled={!clean || !cleanAfter} onClick={handleSubmit}>confirm</Button>
                </>
            }

        </PageContainer>
    );
};


export default compose(
    connect((state: RootState | any) => ({
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
     }), {
        requestCleanerRequest
    }),
)(RequestCleaner)
