import React from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import {Button} from '../../components/button';

const AlertPopUpForRootNode = styled('div')(({ theme }) => (`
    height: 100%;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    background: ${theme.palette.background.paper};
    position: relative;
    padding: 32px 32px 0px 32px;
    align-content: space-between;
    display: flex;
    flex-wrap: wrap;
`));

const CloseSvgWrapper = styled('div')(`
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
`);

const Title = styled('div')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ButtonsContainer = styled('div')(`
    padding: 20px 0 32px 0;
`)


export const AlertPopUpForRootNodeContainer = (props: any) => {
    const {onClose} = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <AlertPopUpForRootNode>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={handleClose}/>
            </CloseSvgWrapper>

            <Title>For viewing need to choose Node</Title>

            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    onClick={handleClose}
                >
                    Ok
                </Button>
            </ButtonsContainer>

        </AlertPopUpForRootNode>
    )
}
