import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
// import {Outlet} from 'react-router-dom;'
import Header from './header';
import {Sidebar} from './sidebar';
import {NotFoundPage} from '../not-found-page';


const WrapContentPages = styled('div')(({ theme }) => (`
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 210px);
    height: calc(100vh - 64px);
    overflow-y: auto;
    transition: all 50ms;
    background: ${theme.palette.background.paper};
    &.sidebar-collapse{
        width: calc(100% - 52px);
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        width: 100%;
        min-height: 100vh;
    }
`));

const WrapFlexContainer = styled('div')`
    box-sizing: border-box;
    min-height: calc(100vh - 64px);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
        min-height: calc(100vh - 111px);
    }
`;

if  ( !localStorage.getItem('sidebarCollapse') ) {
    localStorage.setItem("sidebarCollapse", "open")
};

export const MainWrapNotFound = () => {
    const [sidebarCollapse, setSidebarCollapse] = useState<boolean>(localStorage.getItem('sidebarCollapse') === 'open' ? false : true);
    const toggleCollapseSidebar = (): void => { 
        localStorage.setItem("sidebarCollapse", sidebarCollapse ? "open" : "close");
        setSidebarCollapse(!sidebarCollapse);
    }

    const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);
    const mobileMenuCollapsedOpen = (): void => { setMobileMenuOpen((prevState) => !prevState) };

    return (
        <>  
            <Header mobileMenuCollapsedOpen={mobileMenuCollapsedOpen} />     
            <WrapFlexContainer> 
                <Sidebar 
                    mobileMenuOpen={mobileMenuOpen}
                    sidebarCollapse={sidebarCollapse}  
                    setSidebarCollapse={toggleCollapseSidebar} 
                    setMobileMenuOpen={setMobileMenuOpen}
                />
                <WrapContentPages className={sidebarCollapse ? "sidebar-collapse" : ""}>
                    <NotFoundPage />
                </WrapContentPages>
            </WrapFlexContainer>
        </>
    );
};
