import * as React from 'react';

type SvgProps = {
    width?: string;
    height?: string;
    size?: string;
    fill?: string;
    useSymbol?: boolean;
    onClick?: React.MouseEventHandler;
};

export const PlusSvg: React.FC<SvgProps> = ({
    size,
    width,
    height,
    fill,
    useSymbol,
    onClick,
    ...commonProps
}) => {
    const WrapperTag = useSymbol ? 'symbol' : 'svg';

    return (
        <WrapperTag
            {...commonProps}
            width={width || size}
            height={height || size}
            viewBox="0 0 22 22"
            onClick={onClick}
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5835 1.83325H17.4168C18.1462 1.83325 18.8456 2.12298 19.3614 2.63871C19.8771 3.15443 20.1668 3.85391 20.1668 4.58325V17.4166C20.1668 18.1459 19.8771 18.8454 19.3614 19.3611C18.8456 19.8769 18.1462 20.1666 17.4168 20.1666H4.5835C3.85415 20.1666 3.15468 19.8769 2.63895 19.3611C2.12323 18.8454 1.8335 18.1459 1.8335 17.4166V4.58325C1.8335 3.85391 2.12323 3.15443 2.63895 2.63871C3.15468 2.12298 3.85415 1.83325 4.5835 1.83325ZM18.065 18.0648C18.2369 17.8929 18.3335 17.6597 18.3335 17.4166V4.58325C18.3335 4.34014 18.2369 4.10698 18.065 3.93507C17.8931 3.76316 17.6599 3.66659 17.4168 3.66659H4.5835C4.34038 3.66659 4.10722 3.76316 3.93532 3.93507C3.76341 4.10698 3.66683 4.34014 3.66683 4.58325V17.4166C3.66683 17.6597 3.76341 17.8929 3.93532 18.0648C4.10722 18.2367 4.34038 18.3333 4.5835 18.3333H17.4168C17.6599 18.3333 17.8931 18.2367 18.065 18.0648ZM11.9168 10.0833H14.6668C14.9099 10.0833 15.1431 10.1798 15.315 10.3517C15.4869 10.5236 15.5835 10.7568 15.5835 10.9999C15.5835 11.243 15.4869 11.4762 15.315 11.6481C15.1431 11.82 14.9099 11.9166 14.6668 11.9166H11.9168V14.6666C11.9168 14.9097 11.8203 15.1429 11.6483 15.3148C11.4764 15.4867 11.2433 15.5833 11.0002 15.5833C10.757 15.5833 10.5239 15.4867 10.352 15.3148C10.1801 15.1429 10.0835 14.9097 10.0835 14.6666V11.9166H7.3335C7.09038 11.9166 6.85722 11.82 6.68531 11.6481C6.51341 11.4762 6.41683 11.243 6.41683 10.9999C6.41683 10.7568 6.51341 10.5236 6.68531 10.3517C6.85722 10.1798 7.09038 10.0833 7.3335 10.0833H10.0835V7.33325C10.0835 7.09014 10.1801 6.85698 10.352 6.68507C10.5239 6.51316 10.757 6.41658 11.0002 6.41658C11.2433 6.41658 11.4764 6.51316 11.6483 6.68507C11.8203 6.85698 11.9168 7.09014 11.9168 7.33325V10.0833Z" fill={fill}/>
        </WrapperTag>
    );
};

PlusSvg.defaultProps = {
    fill: 'white',
};
