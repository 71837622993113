export namespace ActionTypes {
    export const FETCH_MIGRATION_START = 'FETCH_MIGRATION_START';
    export const FETCH_MIGRATION_SUCCESS = 'FETCH_MIGRATION_SUCCESS';
    export const FETCH_MIGRATION_FAILURE = 'FETCH_MIGRATION_FAILURE';
    export const FETCH_MIGRATION_CREATE = 'FETCH_MIGRATION_CREATE';
};

interface Migration {
	isError: boolean,
    isLoading: boolean,
	migration: string[],
	errorMessage: string,
}

export const initialState: Migration = {
	isError: false,
    isLoading: false,
	migration: [],
	errorMessage: ''
};

export const migration = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.FETCH_MIGRATION_START:
			return {
				...state,
				isLoading: true
			};
		case ActionTypes.FETCH_MIGRATION_SUCCESS:
			return {
				...state,
				migration: payload,
				isLoading: false,
			};
        case ActionTypes.FETCH_MIGRATION_CREATE:
            return {
                ...state,
                migration: payload,
                isLoading: false,
            };  
		case ActionTypes.FETCH_MIGRATION_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
