import React from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from "../svg/close-svg";
import {Button} from "../button";
import Box from '@mui/material/Box';


const NotifyModalWrapper = styled(Box)(({ width, theme }) => (`
    & {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        box-sizing: border-box;
        padding: 40px;
        width: ${width ? width : '500px'};
        margin:0 auto;
        background: ${theme.palette.background.paper};
        color: ${theme.palette.text.primary};
        border-radius: 0;
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const NotifyModalFirstRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    position: 'relative',
});

const NotifyModalTitle = styled('div')(({ theme } ) => ({
    fontSize: '24px',
    fontWeight: '500',
    color: theme.palette.text.primary
}));

const IconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
});

const NotifyModalButtons = styled('div')({
    marginTop: '24px'
});

type Props = {
    title?: string;
    children?: React.ReactNode;
    cancelButtonText?: string;
    onClose: () => void;
    width?: string;
    isDeleteButton?: boolean;
};

export const NotifyDialogAlert = ({title, children, cancelButtonText, onClose, width, isDeleteButton}: Props) => {
    return (
        <NotifyModalWrapper width={width}>
            <NotifyModalFirstRow>
                <NotifyModalTitle>
                    {title}
                </NotifyModalTitle>
                <IconWrapper>
                    <CloseSvg size="14px" onClick={onClose}/>
                </IconWrapper>
            </NotifyModalFirstRow>
            {children}
            <NotifyModalButtons>
                <Button isDeleteButton={isDeleteButton} variant="outlined" onClick={onClose}>{cancelButtonText}</Button>
            </NotifyModalButtons>
        </NotifyModalWrapper>
    )
}