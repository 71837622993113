import React, { useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Structure from '../../components/structure';
import {Button} from '../../components/button';
import {EditSvg} from '../../components/svg/edit-svg';
import Modal from '@mui/material/Modal';
import BrandForm from './brand-form';
import ClientForm from './client-form';
import TechnicalForm from './technical-form';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {format} from 'date-fns';
import {Loader} from '../../components/loaders/loader';
import {verifyDomainRequest, deleteNodeRequest, setActiveNodeRequest} from '../../actions/nodeManagement';
import { NotifyModal } from '../../components/notify-modal';
import {NotificationsSvg} from '../../components/svg/notifications-svg';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import NotificationSettingsForm from './notification-settings-form';
import { getNodeRequest } from "../../actions/nodeManagement";
import BlockchainConfigForm from './blockchain-config-form';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import {useNavigate} from 'react-router-dom';
import { AccessDeniedAlert } from "../../components/access-denied";

const PageContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const UserDetailsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 768px)' : {
        flexWrap: 'wrap'
    }
});

const UserDetailsHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '32px'
    }
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const UserDetailsHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const UserDetailsHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    marginLeft: '24px'

});

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        height: 100vh;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }
`));

const VerifyContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const StyledA = styled('a')({
    color: '#38AA72'
});

const NodeManagementDetails = ({
    activeNode,
    isLoading,
    verifyDomainRequest,
    deleteNodeRequest,
    getNodeRequest,
    nodeError,
    blockchainError,
    notificationError,
    domainVerificationStatus,
    setActiveNodeRequest,
    nodeErrorMessage,
    notificationErrorMessage,
    blockchainErrorMessage,
    routsUser,
    userRoles,
    isSuperadmin,
}) => {
    const navigate = useNavigate();

    const [openTechnicalForm, setOpenTechnicalForm] = React.useState(false);
    const handleOpenTechnicalForm = () => {
        setOpenTechnicalForm(true);
    };
    const handleCloseTechnicalForm = () => setOpenTechnicalForm(false);

    const [openClientForm, setOpenClientForm] = React.useState(false);
    const handleOpenClientForm = () => {
        setOpenClientForm(true);
    };
    const handleCloseClientForm = () => setOpenClientForm(false);

    const [openBrandForm, setOpenBrandForm] = React.useState(false);
    const handleOpenBrandForm = () => {
        setOpenBrandForm(true);
    };
    const handleCloseBrandForm = () => setOpenBrandForm(false);

    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const handleDeleteModalOpen = () => setDeleteModalOpen(true);
    const handleDeleteModalClose = () => setDeleteModalOpen(false);

    const [openNotificationSettingsForm, setOpenNotificationSettingsForm] = React.useState(false);
    const handleOpenNotificationSettingsForm = () => {
        setOpenNotificationSettingsForm(true);
    };
    const handleCloseNotificationSettingsForm = () => setOpenNotificationSettingsForm(false);

    const [openBlockchainConfigForm, setOpenBlockchainConfigForm] = React.useState(false);
    const handleOpenBlockchainConfigForm = () => {
        setOpenBlockchainConfigForm(true);
    };
    const handleCloseBlockchainConfigForm = () => setOpenBlockchainConfigForm(false);
    
    const [snackbarText, setSnackbarText] = React.useState({successText: '', errorText: ''});

    const [openNodeSnackbar, setOpenNodeSnackbar] = React.useState(false);
    const handleOpenNodeSnackbar = (successText, errorText) => {
        setSnackbarText({successText, errorText});
        setOpenNodeSnackbar(true);
    };

    const handleCloseNodeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenNodeSnackbar(false);
    };

    const [openVerificationSnackbar, setOpenVerificationSnackbar] = React.useState(false);
    const handleOpenVerificationSnackbar = (successText, errorText) => {
        setSnackbarText({successText, errorText});
        setOpenVerificationSnackbar(true);
    };

    const handleCloseVerificationSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenVerificationSnackbar(false);
    };

    const [openNotificationSnackbar, setOpenNotificationSnackbar] = React.useState(false);
    const handleOpenNotificationSnackbar = (successText, errorText) => {
        setSnackbarText({successText, errorText});
        setOpenNotificationSnackbar(true);
    };

    const handleCloseNotificationSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenNotificationSnackbar(false);
    };

    const [openBlockchainSnackbar, setOpenBlockchainSnackbar] = React.useState(false);
    const handleOpenBlockchainSnackbar = (successText, errorText) => {
        setSnackbarText({successText, errorText});
        setOpenBlockchainSnackbar(true);
    };

    const handleCloseBlockchainSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenBlockchainSnackbar(false);
    };

    const handleVerifyDomain = () => {
        verifyDomainRequest(activeNode.id)
        .then(() => handleOpenVerificationSnackbar('Success verification', 'Verification failed'))
        .then(() => setActiveNodeRequest(activeNode.id));
    };

    const handleOpenEditForm = () => {
        if (activeNode.type === 1) {
            handleOpenBrandForm();
        } else if (activeNode.type === 2) {
            handleOpenClientForm();
        } else if (activeNode.type === 3) {
            handleOpenTechnicalForm()
        }
    };

    const handleDeleteNode = () => {
        deleteNodeRequest(activeNode.id).then((error) => {
            error ? handleOpenNodeSnackbar('Node was deleted', 'Node not deleted') : navigate('/node-management')
        });
        handleDeleteModalClose();
    };

    useEffect(() => {
        getNodeRequest(activeNode.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeNode.id]);

    const accessedRoleUpdate = routsUser.some(rout => rout.child === '/node/update') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleDelete = routsUser.some(rout => rout.child === '/node/delete') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleView = routsUser.some(rout => rout.child === '/node/view') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    return (
        <PageContainer>

            {!accessedRoleView && <AccessDeniedAlert/>}

            {accessedRoleView &&
                <>
                    <UserDetailsHeader>
                        <UserDetailsHeaderLeft>
                            <HeaderLeftContainer>
                                <ArrowContainer>
                                    <ArrowBackSvg size="22px" onClick={() => navigate(-1)}/>
                                </ArrowContainer>
                                <UserDetailsHeaderText>
                                    Details
                                </UserDetailsHeaderText>
                            </HeaderLeftContainer>
                            <Structure/>
                        </UserDetailsHeaderLeft>
                        <UserDetailsHeaderRight>
                            {activeNode.type !== 100 &&
                                <>
                                    {activeNode.type === 1 &&
                                        <>
                                            {accessedRoleUpdate &&
                                                <Button startIcon={ <NotificationsSvg size="22px" fill="white"/>} onClick={handleOpenNotificationSettingsForm}>
                                                    notification
                                                </Button>
                                            }
                                            {accessedRoleUpdate &&
                                                <Button startIcon={ <CurrencyBitcoinIcon style={{fontSize: '22px'}} />} onClick={handleOpenBlockchainConfigForm}>
                                                    blockchain config
                                                </Button>
                                            }
                                        </>
                                    }
                                    {accessedRoleUpdate &&
                                        <Button startIcon={ <EditSvg size="22px"/>} onClick={handleOpenEditForm}>
                                            EDIT
                                        </Button>
                                    }
                                    {accessedRoleDelete &&
                                        <Button variant="outlined" onClick={handleDeleteModalOpen}>
                                            DELETE
                                        </Button>
                                    }
                                </>
                            }
                        </UserDetailsHeaderRight>
                    </UserDetailsHeader>
                    <StyledPaperTable>
                        <TableContainer component={'div'}>
                            <Table>
                                <StyledTableHead>
                                    <TableRow>
                                        <TableCell>Info</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </StyledTableHead>
                                <TableBody>
                                    {activeNode.type !== 1 ?
                                    <>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>{activeNode.type === 100 ? 'Root' : activeNode.type === 2 ? 'Client' : 'Technical'}</TableCell>
                                        </TableRow>
                                        {activeNode.type === 2 &&
                                        <TableRow>
                                            <TableCell>Primary email</TableCell>
                                            <TableCell><StyledA href={`mailto:${activeNode.primaryEmail}`}>{activeNode.primaryEmail}</StyledA></TableCell>
                                        </TableRow>}
                                        <TableRow>
                                            <TableCell>Created</TableCell>
                                            <TableCell>{format(new Date(activeNode.createdAt * 1000), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Assigned users</TableCell>
                                            <TableCell>
                                                {activeNode.users.map(user => <div key={Math.random()}>{user.username}</div>)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Note</TableCell>
                                            <TableCell>
                                                {activeNode.note}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                    :
                                    <>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Brand</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Created</TableCell>
                                            <TableCell>{format(new Date(activeNode.createdAt * 1000), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Domain</TableCell>
                                            <TableCell>
                                                <StyledA target="_blank" rel="noreferrer" href={activeNode.domain}>{activeNode.domain}</StyledA>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Callback URL Status</TableCell>
                                            <TableCell>
                                                {
                                                    activeNode.verified  
                                                    ? "Verified"
                                                    : <VerifyContainer>
                                                            Not verified
                                                            {accessedRoleUpdate &&
                                                                <Button variant='outlined' sx={{margin: '0px 16px'}} onClick={handleVerifyDomain}>VERIFY</Button>
                                                            }
                                                        </VerifyContainer>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Note</TableCell>
                                            <TableCell>{activeNode.note}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Assigned users</TableCell>
                                            <TableCell>
                                                {activeNode.users.map(user => <div key={Math.random()}>{user.username}</div>)}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </StyledPaperTable>
                    <Modal
                        open={openBrandForm}
                        onClose={handleCloseBrandForm}
                    >
                        <StyledBoxPopup>
                            <BrandForm onClose={handleCloseBrandForm} id={activeNode.id} isEdit={true} handleOpenSnackbar={handleOpenNodeSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Modal
                        open={openTechnicalForm}
                        onClose={handleCloseTechnicalForm}
                    >
                        <StyledBoxPopup>
                            <TechnicalForm onClose={handleCloseTechnicalForm} id={activeNode.id} isEdit={true} handleOpenSnackbar={handleOpenNodeSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Modal
                        open={openClientForm}
                        onClose={handleCloseClientForm}
                    >
                        <StyledBoxPopup>
                            <ClientForm onClose={handleCloseClientForm} id={activeNode.id} isEdit={true} handleOpenSnackbar={handleOpenNodeSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Modal 
                        open={deleteModalOpen}
                        onClose={handleDeleteModalClose}
                    >
                        <div>
                            <NotifyModal
                                title="Delete user"
                                confirmButtonText='YES, DELETE'
                                cancelButtonText='CANCEL'
                                onClose={handleDeleteModalClose}
                                width="400px"
                                isDeleteButton
                                onConfirm={handleDeleteNode}
                                onCancel={handleDeleteModalClose}
                            >
                                {`Are you sure you want to delete this ${activeNode.type === 1 ? 'brand' : activeNode.type === 2 ? 'client' : 'technical'}?`}
                            </NotifyModal>
                        </div>
                    </Modal>
                    <Modal
                        open={openNotificationSettingsForm}
                        onClose={handleCloseNotificationSettingsForm}
                    >
                        <StyledBoxPopup>
                            <NotificationSettingsForm onClose={handleCloseNotificationSettingsForm} handleOpenSnackbar={handleOpenNotificationSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Modal
                        open={openBlockchainConfigForm}
                        onClose={handleCloseBlockchainConfigForm}
                    >
                        <StyledBoxPopup>
                            <BlockchainConfigForm onClose={handleCloseBlockchainConfigForm} handleOpenSnackbar={handleOpenBlockchainSnackbar}/>
                        </StyledBoxPopup>
                    </Modal>
                    <Snackbar open={openNodeSnackbar} autoHideDuration={6000} onClose={handleCloseNodeSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                        {nodeError || domainVerificationStatus === 'FAILED'?
                        <InfoPopup
                            severity="error"
                            headerText={snackbarText.errorText}
                            infoText={nodeErrorMessage ? nodeErrorMessage : "Something went wrong. Please try again later"}
                        /> :
                        <InfoPopup
                            severity="success"
                            headerText={snackbarText.successText}
                        />}
                    </Snackbar>
                    <Snackbar open={openNotificationSnackbar} autoHideDuration={6000} onClose={handleCloseNotificationSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                        {notificationError ?
                        <InfoPopup
                            severity="error"
                            headerText={snackbarText.errorText}
                            infoText={notificationErrorMessage ? notificationErrorMessage : "Something went wrong. Please try again"}
                        /> :
                        <InfoPopup
                            severity="success"
                            headerText={snackbarText.successText}
                        />}
                    </Snackbar>
                    <Snackbar open={openBlockchainSnackbar} autoHideDuration={6000} onClose={handleCloseBlockchainSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                        {blockchainError ?
                        <InfoPopup
                            severity="error"
                            headerText={snackbarText.errorText}
                            infoText={blockchainErrorMessage ? blockchainErrorMessage : "Something went wrong. Please try again"}
                        /> :
                        <InfoPopup
                            severity="success"
                            headerText={snackbarText.successText}
                        />}
                    </Snackbar>
                    <Snackbar open={openVerificationSnackbar} autoHideDuration={6000} onClose={handleCloseVerificationSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                        {nodeError || domainVerificationStatus === 'FAILED'?
                        <InfoPopup
                            severity="error"
                            headerText={snackbarText.errorText}
                            infoText={nodeErrorMessage ? nodeErrorMessage : "Something went wrong. Please try again later"}
                        /> :
                        <InfoPopup
                            severity="success"
                            headerText={snackbarText.successText}
                        />}
                    </Snackbar>
                </>
            }
            {isLoading && <Loader />}
        </PageContainer>
        
    );
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.nodeManagement.isLoading,
        activeNode: state.nodeManagement.activeNode,
        nodeError: state.nodeManagement.error,
        nodeErrorMessage: state.nodeManagement.errorMessage,
        notificationError: state.notificationSettings.error,
        notificationErrorMessage: state.notificationSettings.errorMessage,
        blockchainError: state.blockchainConfig.error,
        blockchainErrorMessage: state.blockchainConfig.errorMessage,
        domainVerificationStatus: state.nodeManagement.domainVerificationStatus,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        verifyDomainRequest, 
        deleteNodeRequest, 
        getNodeRequest, 
        setActiveNodeRequest
    })
)(NodeManagementDetails);