// @ts-nocheck
import React, {useMemo} from 'react';
import { styled } from '@mui/material/styles';
import {CloseSvg} from '../../components/svg/close-svg';
import {SearchSvg} from "../../components/svg/search-svg";
import useResizeObserver from "use-resize-observer";
import {Tree} from 'react-arborist';
import {useTreeModel} from '../../hooks/useTreeModel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {FolderSvg} from '../../components/svg/folder-svg';
import {DoneSvg} from '../../components/svg/done-svg';
import {Button} from '../../components/button';
import {connect} from 'react-redux';
import {compose} from "redux";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { updateNodeRequest, getNodesTreeRequest } from '../../actions/nodeManagement';
import {filterFolders, getArrayWithBreadcrumbs} from '../../utils';

const TransferContainer = styled('div')(({ theme }) => ({
    height: '100%',
    width: '550px',
    background: theme.palette.background.paper,
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '0',
    transform: 'translate(0, -50%)',
    padding: '32px',

    '@media(max-width: 768px)' : {
        height: '100%',
        width: '85%',
    }
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '57px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginTop: '48px',
    marginBottom: '12px',
    color: theme.palette.text.primary,
}));

const InputWrapper = styled('div')({
    position: 'relative',
    marginBottom: '12px'
});

const StyledInput = styled('input')(({ theme }) => ({
    width: '100%',
    marginRight: '20px',
    boxSizing: 'border-box',
    height: '36px',
    paddingLeft: '20px',
    paddingRight: '38px',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#FFFFFF' : '#C0C0C0'}`,
    borderRadius: '4px',
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    ':focus': {
        outline: '#1C1F25'
    }
}));

const InputIcon = styled('div')({
    padding: '4px',
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)'
});

const TreeItemContainer = styled('div')(({ selected, theme }) => ({
    border: `1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'}`,
    borderBottom: 'none !important',
    paddingLeft: '40px',
    background: selected ? theme.palette.action.selected : 'transparent',
    color: theme.palette.text.primary,
    width: 'calc(100% - 44px) !important',

    ':last-child': {
        borderBottom: `1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'} !important`,
    }
}));

const TreeItemRowContents = styled('div')({
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const LeftBlock = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: "relative"
});

const TreeItemIcon = styled('div')({
    marginRight: '8px',
    marginLeft: '10px'
});

const TreeItemLabel = styled('div')({
    fontSize: '14px',
    fontWeight: '400'
});

const DoneIconContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translate(0, -50%)',
    display: 'flex',
    alignItems: 'center'

});

const TransferButtons = styled('div')({
    position: 'absolute',
    bottom: '64px',
});

const IconWrapper = styled('div')({
    position: 'absolute',
    left: '-18px'
});

const TransferSearchResultsContainer = styled('div')({
    paddingTop: '12px',
    overflow: 'scroll',
    height: '72%'
});

const BreadcrumbItem = styled('div')({
    color: '#979799',
    fontSize: '14px',
    fontWeight: '400'
});

const TransferSearchResultsItemContainer = styled('div')({
    marginTop: '24px',
    borderBottom: '1px solid #E3E8EC',
});

const TransferSearchResultsItem = styled('div')(({ theme, isSelected }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: isSelected ? '#F1F8ED' : 'transparent',
}));

const TransferSearchResultsItemIcon = styled('div')({
    marginRight: '8px'
});

const MaybeToggleButton = ({ toggle, isOpen, isFolder }: any) => {
    if (isFolder) {
        const Icon = isOpen ? ExpandMoreIcon : ChevronRightIcon;
        return (
            <IconWrapper>
                <Icon onClick={toggle}/>
            </IconWrapper>
        );
    } else {
        return <div className="spacer" />;
    }
};

export const Transfer = ({onClose, nodesTree, id, updateNodeRequest, handleOpenSnackbar, activeNode}) => {

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    const [searchValue, setSearchValue] = React.useState('');
    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);
    };

    const [selected, setSelected] = React.useState('');
    const setSelectedId = id => () => {
        setSelected(id);
    };

    const handleConfirm = () => {
        let data;
        
        if (activeNode.type === 1) {
            data = {
                parentId: selected,
                name: activeNode.name,
                domain: activeNode.domain,
                merchant: {
                    publicKey: activeNode.merchant.publicKey,
                    privateKey: activeNode.merchant.privateKey,
                    callbackUrl: activeNode.merchant.callbackUrl,
                    successUrl: activeNode.merchant.successUrl,
                    errorUrl: activeNode.merchant.errorUrl,
                    allowIp: activeNode.merchant.allowIp,
                    isCheck: activeNode.merchant.isCheck
                },
                note: activeNode.note

            };
        } else if (activeNode.type === 2) {
            data = {
                parentId: selected,
                name: activeNode.name,
                primaryEmail: activeNode.primaryEmail,
                note: activeNode.note
            };
        } else if (activeNode.type === 3) {
            data = {
                parentId: selected,
                name: activeNode.name,
                note: activeNode.note
            }
        };

        updateNodeRequest(id, JSON.stringify(data)).then(() => handleOpenSnackbar('Folder was successfully transferred', 'Transfer error'));
        onClose();
    };

    const {data, onToggle} = useTreeModel(filterFolders(nodesTree)[0]);

    const  itemsToShowInSearch = useMemo(() => {
        return getArrayWithBreadcrumbs(nodesTree).filter(item =>
            item.name.toUpperCase().includes(searchValue.toUpperCase()) || String(item.id).includes(searchValue));
    }, [searchValue, nodesTree]);

    return (
        <TransferContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={onClose}/>
            </CloseSvgWrapper>
            <Title>Select folder to transfer</Title>
            <InputWrapper>
                <StyledInput placeholder="Search" onChange={handleChangeSearchValue}/>
                <InputIcon>
                    <SearchSvg size="22px"/>
                </InputIcon>
            </InputWrapper>
            {searchValue ? 
            <TransferSearchResultsContainer>
                {itemsToShowInSearch.map(item => 
                    <TransferSearchResultsItemContainer onClick={setSelectedId(item.id)}>
                        <Breadcrumbs separator="›">
                            {item.breadcrumbs.map(item => (
                                <BreadcrumbItem>{item}</BreadcrumbItem>
                            ))}
                        </Breadcrumbs>
                        <TransferSearchResultsItem isSelected={item.id === selected}>
                            <TransferSearchResultsItemIcon>
                                <FolderSvg size="24px"/>
                            </TransferSearchResultsItemIcon>
                            {`${item.id}. ${item.name}`}
                            {item.id === selected && 
                            <DoneIconContainer>
                                <DoneSvg size="24px"/>
                            </DoneIconContainer>}
                        </TransferSearchResultsItem>
                    </TransferSearchResultsItemContainer>
                )}
            </TransferSearchResultsContainer> :
            <div ref={ref} style={{width: '100%', height: 'calc(100vh - 240px)'}}>
                <Tree
                    width={width}
                    height={height}
                    data={data}
                    onToggle={onToggle}
                    rowHeight={42}
                    indent={36}
                    openByDefault={false}
                >{({
                    innerRef,
                    data,
                    styles,
                    state,
                    handlers,
                    tree
                }) => {
                    const folder = data.children?.length;
                    const isOpen = !!state.isOpen;
                    const name = data.name;
                                    
                    return (
                        <TreeItemContainer style={styles.row} ref={innerRef} onClick={handlers.select} selected={selected === data.id}>
                                <TreeItemRowContents className="row-contents" style={styles.indent} onClick={setSelectedId(data.id)}>
                                    <LeftBlock>
                                        <MaybeToggleButton
                                            toggle={handlers.toggle}
                                            isOpen={isOpen}
                                            isFolder={folder}
                                            isSelected={selected === data.id}
                                        />
                                        <TreeItemIcon>
                                            <FolderSvg size="24px" />
                                        </TreeItemIcon>
                                        <TreeItemLabel>
                                            {`${data.id}. ${name}`}
                                        </TreeItemLabel>
                                    </LeftBlock>
                                    {selected === data.id && 
                                    <DoneIconContainer>
                                        <DoneSvg size="24px"/>
                                    </DoneIconContainer>}
                                </TreeItemRowContents>
                        </TreeItemContainer>
                    );
                }}</Tree>
            </div>}
            <TransferButtons>
                <Button sx={{marginRight: '12px'}} disabled={!selected} onClick={handleConfirm}>CONFIRM</Button>
                <Button variant="outlined" onClick={onClose}>CANCEL</Button>
            </TransferButtons>
        </TransferContainer>
    );
};

export default compose(
    connect((state: RootState | any) => ({
        activeNode: state.nodeManagement.activeNode,
        nodesTree: state.nodeManagement.nodesTree,
    }), {updateNodeRequest, getNodesTreeRequest})
)(Transfer);